import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

import { FormBecomeTeacher } from "components/auth/FormBecomeTeacher";
import { useAuth } from "hooks/useAuth";
import { BecomeTeacherDto } from "dto/user/becometeacher.dto";
import { CommonTools } from "tools/utils/commontools";

const TeacherSignUp: React.FC<PageComponentProps> = () => {
  const { user, registerTeacher } = useAuth();
  if (!user) return null;
  return (
    <FormBecomeTeacher
      defaultObject={
        new BecomeTeacherDto(CommonTools.processObjectField(user, ["id"]))
      }
      onSubmit={registerTeacher}
      loading={false}
    />
  );
};

export { TeacherSignUp };
