import React, { useState } from "react";
import { useResource } from "hooks/useResource";
import { LessonDto } from "dto/course/lesson.dto";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
  Stack,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { AttachmentList } from "components/system/attachment/AttachementList";
import { GalleryList } from "components/system/gallery/GalleryList";
import { VideoList } from "components/system/video/VideoList";

type CourseLessonsListItemProps = {
  obj: LessonDto;
};

const CourseLessonsListItem: React.FC<CourseLessonsListItemProps> = ({
  obj,
  ...props
}) => {
  const { LL } = useResource();
  const [expend, setExpend] = useState(false);
  const handleExpend = (event: React.SyntheticEvent, expanded: boolean) => {
    setExpend(expanded);
  };

  const processLessonDetails = () => {
    if (!expend) return null
    if (!obj) return null
    if (!obj.id) return null
    if (!obj.hasOwnProperty("typelesson")) return null
    if (!obj.typelesson) return null
    return (
      <Box>
        <Typography variant="h6">{LL("TypeLesson")}</Typography>
        <Typography>
          {ComponentCommonTools.processObjectFieldOfObject(
            obj,
            "typelesson",
            "name"
          )}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Box>
          <Typography variant="h6">{LL("Attachments")}</Typography>
          <Box>
            <AttachmentList idparent={obj.id} parent={"lesson"} />
          </Box>
          <Divider sx={{ my: 2 }} />
        </Box>
        <Box>
          <Typography variant="h6">{LL("Gallery")}</Typography>
          <Box>
            <GalleryList idparent={obj.id} parent={"lesson"} />
          </Box>
          <Divider sx={{ my: 2 }} />
        </Box>
        <Box>
          <Typography variant="h6">{LL("Video")}</Typography>
          <Box>
            <VideoList idparent={obj.id} parent={"lesson"} />
          </Box>
        </Box>
      </Box>
    );
  };

  const processLessonItem = () => {
    if (!obj) return null
    if (!obj.id) return null

    return (
      <Accordion
        className="courseContent"
        disableGutters
        expanded={expend}
        onChange={handleExpend}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ flexDirection: "row-reverse" }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Typography className="name" ml={1}>
              {ComponentCommonTools.processObjectField(obj, "name")}
            </Typography>
            <Typography className="time">
              <AccessTimeIcon sx={{ mr: 1 }} />
              {LL("LessonDuration")}{" "}
              {ComponentCommonTools.processObjectFieldTimeStamp(
                obj,
                "lessonduration"
              )}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>{processLessonDetails()}</AccordionDetails>
      </Accordion>
    );
  };

  return processLessonItem();
};

export { CourseLessonsListItem };
