import { Box } from "@mui/material";

import { useResource } from "hooks/useResource";
import React from "react";

import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useResponsive } from "hooks/useResponsive";

type Props = {
  open: boolean;
  handleClose: () => void;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ConfirmEmail: React.FC<Props> = ({ handleClose, open }) => {
  const { LL, CF } = useResource();
  const { downXS, downSM, downMD, downLG, downXL, matchesCustom2 } =
    useResponsive();

  let dialogWidth: "xs" | "sm" | "md" = "md";
  let imageHeight = 335;
  let imageWidth = 295;

  if (downXS) {
    dialogWidth = "xs";
  } else if (downSM) {
    dialogWidth = "sm";
    imageHeight = 268;
    imageWidth = 236;
  } else if (downMD) {
    dialogWidth = "md";
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth={true}
      maxWidth={dialogWidth}
      PaperProps={{
        sx: {
          minHeight: "400px",
        },
      }}
      aria-describedby="alert-dialog-slide-description">
      <DialogContent>
        <Box className="confirmEmailBox">
          <Box>
            {ComponentCommonTools.getFileBlock(
              CF("image_need_confirm_email"),
              imageHeight,
              imageWidth
            )}
          </Box>
          <Box className="textBoxEmailConf">
            <DialogContentText
              className="confirmTitleDialog"
              id="alert-dialog-slide-description">
              {LL("Please_confirm_your_email")}
            </DialogContentText>
            <DialogContentText className="confirmTextDialog" id="alert-dialog-slide-description">
              {LL("Please_confirm_your_email_description")}
            </DialogContentText>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export { ConfirmEmail };
