import { Avatar, Box, Skeleton, Stack, Typography } from "@mui/material";

import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";

import { NotificationParamsDto } from "dto/user/notificationparams.dto";
import { useList } from "hooks/useList";
import { CallbackType } from "interfaces/commontypes.interface";
import React from "react";
import { NotificationParamsService } from "services/user/notificationparams.service";
import { NotificationParamsIdentifier } from "tools/types/notificationparams";

type Props = {
  idNotification: string;
  timeComponent?: any;
};

const service = new NotificationParamsService();
const NotificationParamsList: React.FC<Props> = ({
  idNotification,
  timeComponent,
}) => {

  // const [loading, setLoading] = useState<boolean>(false);
  // const [objects, setObjects] = useState<Array<NotificationParamsDto>>([]);

  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    if (!idNotification) return;
    service.getList(cb, cbParams, req);
  };
  const [loading, objects] = useList<NotificationParamsDto>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("idnotification", [idNotification])],
      1,
      -1
    ),
    [idNotification],
    true,
    `notification_params_list_${idNotification}`
  );
  // const getList = () => {
  //   if (!idNotification) return;
  //   const reqList = new RequestListDTO();

  //   reqList.onpage = parseInt(CC("MaxOnPageNotificationParams", "999999"));
  //   reqList.page = 1;
  //   reqList.filters = [];
  //   reqList.sortcriteria = [];

  //   let f: RequestFilterDTO;

  //   f = new RequestFilterDTO();
  //   f.field = "idnotification";
  //   f.values = [idNotification];
  //   reqList.filters.push(f);

  //   setLoading(true);
  //   service.getList(loadObjects, {}, reqList);
  // };

  // const checkLoading = () => {
  //   let load = false;
  //   if (!objects) load = true;
  //   setLoading(load);
  // };

  // const loadObjects = (result: ResultListDTO) => {
  //   if (!result) return;
  //   if (!result.err) {
  //     const objects = result.objects ? result.objects : [];
  //     setObjects(objects);
  //   }
  // };

  // useEffect(() => {
  //   checkLoading();
  // }, [objects]);

  // useEffect(() => {
  //   getList();
  // }, [idNotification]);

  const processList = () => {
    if (loading) return <Skeleton variant="rectangular" />;
    if (!objects) return null;
    if (!objects.length) return null;
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Avatar
          src={NotificationParamsIdentifier.getParamImage(
            objects,
            NotificationParamsIdentifier.FILE
          )}
        />
        <Box>
          <Typography className="nt-name">
            {NotificationParamsIdentifier.getParamValue(
              objects,
              NotificationParamsIdentifier.SUBJECT
            )}
          </Typography>
          <Typography className="nt-desc">
            {NotificationParamsIdentifier.getParamValue(
              objects,
              NotificationParamsIdentifier.MESSAGE
            )}
          </Typography>
          <Typography className="data">
            {timeComponent && timeComponent()}
          </Typography>
        </Box>
      </Stack>
    );
  };

  return processList();
};

export { NotificationParamsList };
