import React from "react";
import { useResource } from "hooks/useResource";

import PageComponentProps from "interfaces/pagecomponentprops.interface";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import ForgotPasswordDto from "dto/auth/forgotpassword.dto";

import Container from "@mui/material/Container";
import { UserService } from "services/user/user.service";
import { AuthMethodsBlock } from "../../components/auth/AuthMethodsBlock";
import { FormForgotPassword } from "components/auth/FormForgotPassword";


const service = new UserService();

const ForgotPassword: React.FC<PageComponentProps> = () => {
  const { LL } = useResource();
  
  const onSubmit = (obj: ForgotPasswordDto) => {
    service.resetPasswordCode(obj);
  };

  return (
    <Box className="authMaineBox">
      <Container maxWidth="xs">
        <Box mt={5}>
          <Typography variant="h2" align="center" sx={{ mb: 5 }}>
            {LL("ForgotPassword")}
          </Typography>

          <FormForgotPassword onSubmit={onSubmit} />

          <Typography className="text-strike" my={4}>
            {LL("Remembered_password?")}
          </Typography>
          <AuthMethodsBlock isLoginPage={false} />
        </Box>
      </Container>
    </Box>
  );
};

export { ForgotPassword };
