import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";

interface SkeletonProps {
  lines?: number;
  width?: number;
}

const SkeletonCourseRequest: React.FC<SkeletonProps> = ({ lines }) => {
  const gridItem = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        // marginX: "auto",
        flexDirection: "column",
        width: "100%",
        gap: 2,
        ml: 3,
        mb: 5,
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          flexDirection: "row",
          gap: 2,
        }}>
        <Skeleton
          variant="text"
          sx={{
            fontSize: "1.25rem",
            width: { xs: "70%", md: "30%" },
            minWidth: "120px",
          }}
        />
        <Skeleton
          variant="rounded"
          sx={{ width: "10%", minWidth: "120px", height: "30px" }}
        />
      </Box>
      <Grid xs={9} container spacing={2}>
        <Grid xs={12} md={6} sx={{}}>
          <Box sx={{ mb: 2 }}>
            <Skeleton
              variant="text"
              sx={{ fontSize: "0.75rem", width: "20%", minWidth: "50px" }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem", width: "70%", minWidth: "170px" }}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Skeleton
              variant="text"
              sx={{ fontSize: "0.75rem", width: "25%", minWidth: "60px" }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem", width: "40%", minWidth: "120px" }}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Skeleton
              variant="text"
              sx={{ fontSize: "0.75rem", width: "30%", minWidth: "120px" }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem", width: "50%", minWidth: "120px" }}
            />
          </Box>
        </Grid>
        <Grid xs={12} md={3}>
          <Grid xs={12} md={6} sx={{}}>
            <Box sx={{ mb: 2 }}>
              <Skeleton
                variant="text"
                sx={{ fontSize: "0.75rem", width: "25%", minWidth: "50px" }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", width: "45%", minWidth: "170px" }}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Skeleton
                variant="text"
                sx={{ fontSize: "0.75rem", width: "25%", minWidth: "60px" }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", width: "40%", minWidth: "120px" }}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Skeleton
                variant="text"
                sx={{ fontSize: "0.75rem", width: "30%", minWidth: "120px" }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", width: "50%", minWidth: "120px" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Box sx={{ width: "100%", height: "auto" }} className="">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          alignContent: "center",
          //   margin: "auto",
          padding: "20px 0px 20px 0px",
        }}>
        <Grid
          container
          xs={12}
          sx={{ maxWidth: "1522px", width: "100%" }}
          spacing={2}>
          {Array(lines)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export { SkeletonCourseRequest };
