import { Box, Typography } from "@mui/material";
import MyTextField from "components/form/mytextfield";
import { SocialNewDto } from "dto/user/social.dto";
import { useResource } from "hooks/useResource";
import React from "react";
import { CommonTools } from "tools/utils/commontools";

type Props = {
  item: SocialNewDto;
  index: number;
  handleChange: (index: number, value: string) => void;
};

const FormSocialUserItem: React.FC<Props> = ({ item, index, handleChange }) => {
  const { LL } = useResource();
  const setObjField = (field: any, value: any) => {
    handleChange(index, value);
  };
  return (
    <Box key={index} sx={{ my: 2 }}>
      <Typography variant="h6">
        {CommonTools.processObjectField(item, ["typesocial", "name"])}
      </Typography>
      <MyTextField
        fullWidth
        id="link"
        name="link"
        variant="standard"
        className="form-input"
        color="primary"
        _label={LL("link")}
        size="small"
        value={item.link}
        setObj={setObjField}
        _vresults={undefined}
      />
    </Box>
  );
};

export { FormSocialUserItem };
