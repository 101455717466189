import React from "react";
import { HtmlTag } from "components/form/HtmlTag";
import { Box, Skeleton } from "@mui/material";
import { usePage } from "hooks/usePage";
import { CommonTools } from "tools/utils/commontools";

type Props = {
  idPage?: string;
  cacheKeyName: string;
};

const StaticInfoPage: React.FC<Props> = ({ idPage, cacheKeyName }) => {
  // const { saveCache, getCache } = useResource();
  const cacheKey = "static_info_page" + cacheKeyName;
  const { object, loading } = usePage(idPage ?? "", true, cacheKey);
  // const [isLoading, setIsLoading] = useState(true);
  // const [obj, setObject] = useState<PageDto | null>(null);

  // const loadObject = (_obj: any) => {
  //   setObject(_obj.obj);
  //   setIsLoading(false);
  //   saveCache(_obj.obj, cacheKey);
  // };

  // const getObject = async () => {
  //   const id = idPage ? idPage : "-1";

  //   const cd = getCache(cacheKey);
  //   if (cd !== false) {
  //     setObject(cd);
  //     setIsLoading(false);
  //     return;
  //   }

  //   setIsLoading(true);

  //   pageService.get(id, loadObject);
  // };

  // useEffect(() => {
  //   if (!idPage) return;
  //   getObject();
  // }, [idPage]);

  if (loading) return <Skeleton height={200} />;
  if (!object) return null;
  return (
    <Box
      sx={{
        fontSize: "20px",
        fontWeight: "500",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <HtmlTag content={CommonTools.processObjectField(object, ["content"])} />
    </Box>
  );
};

export { StaticInfoPage };
