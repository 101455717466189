import React, { useState } from "react";
import { useResource } from "hooks/useResource";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { MyButton } from "components/elements/button/MyButton";

import { EditCourseLessonList } from "../lesson/EditCourseLessonList";

type Props = {
  currentRoute: any;
};

const EditCourseLesson: React.FC<Props> = ({ currentRoute }) => {
  const { LL } = useResource();

  const id = currentRoute._paths !== undefined ? currentRoute._paths[2] : "";

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const processLessonList = () => {
    if (!id) return null;
    return (
      <EditCourseLessonList
        idCourse={id}
        setOpenDialogAdd={setOpenDialog}
        openDialogAdd={openDialog}
      />
    );
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  return (
    <Box>
      <Typography variant="h2" textAlign="center" mb={1}>
        {LL("Lectii curs")}
      </Typography>
      <Box textAlign="center" mb={4}>
        <MyButton className="btn btnAddlesson" cb={handleOpenDialog}>
          {LL("Bt_Add_Lesson")}
        </MyButton>
      </Box>
      {processLessonList()}
    </Box>
  );
};

export default EditCourseLesson;
