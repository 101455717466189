// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// import { PostGalleryDto } from "dto/gallery/gallery.dto";
// import ResultListDTO from "dto/app/resultlist.dto";
// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import {GalleryRepository} from "repositories/system/gallery.repository";
// import { Status } from "tools//types/status";
// import GeneralService from "services/general.service";

// export default class GalleryService extends GeneralService implements IService {
//   galleryRepository: GalleryRepository = new GalleryRepository();
//   constructor() {
//     super();
//     this.handleGetList = this.handleGetList.bind(this);
//     this.handleGet = this.handleGet.bind(this);
//   }
//   handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;

//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async update(
//     id: string,
//     cb?: any,
//     cbparameters?: any,
//     data?: any
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.galleryRepository.update(
//       id,
//       this.handleUpdate,
//       this.prepareObjToUpdate(data),
//       cbparameters
//     );
//   }

//   handleDelete(result?: any, cbparameters?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters);
//     }
//   }

//   async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.galleryRepository.delete(id, this.handleDelete, cbparameters);
//   }

//   handleGet(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;
//     this.populateObject(rez.obj, result.obj, [
//       { key: "status", type: "status" },
//       { key: "isdefault", type: "booleans" },
//     ]);
//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async get(
//     id: string,
//     cb?: any,
//     cbparameters?: any,
//     idLanguage?: string
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.galleryRepository.get(
//       id,
//       this.handleGet,
//       cbparameters,
//       idLanguage
//     );
//     if (cb == undefined) {
//       return this.handleGet(t);
//     }
//   }
//   handleGetList(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultListDTO();

//     rez = result;
//     if (result.err) return;

//     this.parseResultPopulate(result, rez, [
//       { key: "status", type: "status" },
//       { key: "isdefault", type: "booleans" },
//     ]);

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async getList(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.galleryRepository.getList(
//       this.handleGetList,
//       cbparameters,
//       data
//     );
//     if (cb == undefined) {
//       return this.handleGetList(t);
//     }
//   }

//   handleAdd(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   prepareObjToAdd(obj: any): PostGalleryDto {
//     const rez = new PostGalleryDto();
//     rez.idfile = null;
//     rez.idlanguage = obj.idlanguage;
//     rez.ordercriteria = obj.ordercriteria;
//     rez.name = obj.name;
//     rez.status = obj.status;
//     rez.idparent = obj.idparent;
//     rez.parent = obj.parent;
//     rez.isdefault = obj.isdefault;
//     rez.files = obj.files;
//     return rez;
//   }

//   prepareObjToUpdate(obj: any): PostGalleryDto {
//     const rez = new PostGalleryDto();
//     rez.idlanguage = obj.idlanguage;
//     rez.ordercriteria = obj.ordercriteria;
//     rez.name = obj.name;
//     rez.status = obj.status;
//     rez.parent = obj.parent;
//     rez.isdefault = obj.isdefault;
//     rez.idfile = obj.idfile;
//     // rez.idgallery = obj.idgallery;
//     return rez;
//   }

//   async add(cb?: any, cbparameters?: any, data?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.galleryRepository.add(
//       this.handleGetList,
//       cbparameters,
//       this.prepareObjToAdd(data)
//     );
//   }

//   async addForCourse(cb?: any, cbparameters?: any, data?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.galleryRepository.addForCourse(
//       this.handleGetList,
//       cbparameters,
//       this.prepareObjToAdd(data)
//     );
//   }

//   getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
//     if (
//       !obj.sortcriteria ||
//       !Array.isArray(obj.sortcriteria) ||
//       !obj.sortcriteria.length
//     ) {
//       var sobj = new RequestSortCriteriaDTO();
//       sobj.asc = true;
//       sobj.field = "name";

//       obj.sortcriteria = [];
//       obj.sortcriteria?.push(sobj);
//     }

//     return obj;
//   }
// }

import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import { GalleryRepository } from "repositories/system/gallery.repository";
import { PostGalleryDto } from "dto/system/gallery.dto";

export class GalleryService
  extends GeneralService<GalleryRepository>
  implements IService
{
  constructor() {
    super(
      new GalleryRepository(),
      [
        { key: "status", type: "status" },
        { key: "isdefault", type: "booleans" },
      ],
      "name"
    );
  }
   addForCourse = async (cb?: any, cbparameters?: any, data?: any): Promise<any> =>{
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    const t = await this.repository.addForCourse(
      this.handleGet,
      cbparameters,
      this.prepareObjToAdd(data)
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  }
  prepareObjToAdd(obj: any): PostGalleryDto {
    const rez = new PostGalleryDto();
    rez.idfile = null;
    rez.idlanguage = obj.idlanguage;
    rez.ordercriteria = obj.ordercriteria;
    rez.name = obj.name;
    rez.status = obj.status;
    rez.idparent = obj.idparent;
    rez.parent = obj.parent;
    rez.isdefault = obj.isdefault;
    rez.files = obj.files;
    return rez;
  }
  prepareObjToUpdate(obj: any): PostGalleryDto {
    const rez = new PostGalleryDto();
    rez.idlanguage = obj.idlanguage;
    rez.ordercriteria = obj.ordercriteria;
    rez.name = obj.name;
    rez.status = obj.status;
    rez.parent = obj.parent;
    rez.isdefault = obj.isdefault;
    rez.idfile = obj.idfile;
    // rez.idgallery = obj.idgallery;
    return rez;
  }
}
