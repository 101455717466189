import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";

interface SkeletonProps {
  lines?: number;
  width?: number;
}

const SkeletonNotification: React.FC<SkeletonProps> = ({ lines }) => {
  const gridItem = (
    <Box
      sx={{
        width: "100%",
        height: "60px",
        mb: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <Skeleton sx={{ width: "90%", height: "100%" }} variant="rectangular" />
    </Box>
  );

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
      }}>
      <Grid
        container
        xs={12}
        sx={{ maxWidth: "1522px", margin: "auto" }}
        spacing={2}>
        {Array(lines)
          .fill(null)
          .map((_, index) => (
            <React.Fragment key={index}>{gridItem}</React.Fragment>
          ))}
      </Grid>
    </Box>
  );
};

const SkeletonNotificationList: React.FC<SkeletonProps> = ({ lines }) => {
  const gridItem = (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        flexDirection: "row",
        gap: 2,
        mb: 2,
      }}>
      <Skeleton
        sx={{ width: "40px", height: "40px", minWidth: "40px" }}
        variant="circular"
      />
      <Box
        sx={{
          width: "60%",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          flexDirection: "column",
        }}>
        <Skeleton variant="text" sx={{ fontSize: "1rem", width: "30%" }} />
        <Skeleton variant="text" sx={{ fontSize: "1rem", width: "100%" }} />
      </Box>
      <Box
        sx={{
          width: "30%",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "end",
          flexDirection: "column",
        }}>
        <Skeleton variant="text" sx={{ fontSize: "1rem", width: "40%" }} />
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
      }}>
      <Grid
        container
        xs={12}
        sx={{ maxWidth: "1522px", margin: "auto" }}
        spacing={2}>
        {Array(lines)
          .fill(null)
          .map((_, index) => (
            <React.Fragment key={index}>{gridItem}</React.Fragment>
          ))}
      </Grid>
    </Box>
  );
};

export { SkeletonNotification, SkeletonNotificationList };
