import Idto from "interfaces/idto.interface";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

type FunGetObjects = () => CourseFilterDto[];
export class CourseFilterDto implements Idto {
  id?: string;
  value?: number | string;
  idfilter?: string;
  idfilterdictionary?: string;
  filter?: any;
  filterdictionary?: any;
  idcourse?: string;

  static funGetObjects: FunGetObjects | null = null;
  static setFunGetObjects = (fun: any) => {
    CourseFilterDto.funGetObjects = fun;
  };

  static handleFunGetObjects = (): CourseFilterDto[] => {
    if (CourseFilterDto.funGetObjects) {
      return CourseFilterDto.funGetObjects();
    }
    return [];
  };
  static getColumnsSpecial(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardObjectColumn(LL, "filter", "name"),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "filterdictionary",
        "name"
      ),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["delete"],
        []
      ),
    ];
  }
}

export class CourseFilterFormDto implements Idto {
  id?: string;
  idfilter?: string;
  idfilterdictionary?: string;
  idcourse?: string;

  constructor(
    id?: string,
    idfilter?: string,
    idfilterdictionary?: string,
    idcourse?: string
  ) {
    this.id = id || "";
    this.idfilter = idfilter || "";
    this.idfilterdictionary = idfilterdictionary || "";
    this.idcourse = idcourse || "";
  }

  static parseFromCourseFilter = (obj: CourseFilterFormDto) => {
    if (!obj) return new CourseFilterFormDto();
    return new CourseFilterFormDto(
      obj.id,
      obj.idfilter,
      obj.idfilterdictionary,
      obj.idcourse
    );
  };
}

export class PostCourseFilterDto implements Idto {
  value?: number;
  idfilter?: string;
  idfilterdictionary?: string;
  idcourse?: string;
  filter?: any;
  filterdictionary?: any;
}
