import React, { useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CommonTools } from "tools/utils/commontools";
import { Box, Stack, Typography, Grid } from "@mui/material";
import { TeacherDto } from "dto/user/teacher.dto";

import { useResource } from "hooks/useResource";
import { TeacherInfoBlock } from "./TeacherInfoBlock";
import UserSocialBlock from "components/user/user/social/UserSocialBlock";
import { Types } from "tools/types/types";
import { CourseListByTeacherDisplayBlock } from "components/course/course/CourseListByTeacherDisplayBlock";
import { NewsletterBlock } from "components/general/NewsletterBlock";

import { TeacherStatistic } from "./TeacherStatistic";
import { MyRatingReadOnly } from "components/elements/MyRating/MyRatingReadOnly";
import { TeacherTestimonials } from "components/user/testimonial/TeacherTestimonials";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

const PublicTeacherProfile: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { LL } = useResource();

  const idTeacher = CommonTools.getIdFromPath(currentRoute, "teacher");
  const [obj, setObj] = useState<TeacherDto | undefined>();

  const processTeacherInfo = () => {
    if (!idTeacher) return null;

    return (
      <Box className="aboutTeacher">
        <TeacherInfoBlock
          id={idTeacher}
          showSocials={false}
          setCurrentObject={setObj}
        />
      </Box>
    );
  };

  const processVideoPresentation = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("videopresentation")) return null;
    if (!obj.videopresentation) return null;
    return (
      <Box mb={2}>
        <Typography variant="h5" my={3}>
          {LL("VideoPresentation")}
        </Typography>
        {ComponentCommonTools.getFileBlockVideo(obj.videopresentation, "100%")}
      </Box>
    );
  };

  const processVideoLessonExample = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("videolessonexample")) return null;
    if (!obj.videolessonexample) return null;
    return (
      <Box>
        <Typography variant="h2" mb={3} mt={5}>
          {LL("VideoLessonExample")}
        </Typography>
        {ComponentCommonTools.getFileBlockVideo(obj.videolessonexample)}
      </Box>
    );
  };

  const processTeacherStatistic = () => {
    if (!obj) return null;
    if (!obj.id) return null;
    return (
      <>
        <Typography variant="h2" my={4} className="generalTextAlign">
          {LL("Coursant review teacher")}
        </Typography>
        <Stack
          direction={{ sm: "column", md: "row" }}
          spacing={2}
          alignItems="center">
          {processRatingForStatistic()}
          <Box sx={{ width: "100%" }}>
            <TeacherStatistic idTeacher={obj.id} />
          </Box>
        </Stack>
      </>
    );
  };

  const processUserSocials = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("iduser")) return null;
    if (!obj.iduser) return null;
    return (
      <UserSocialBlock
        idUser={obj.iduser}
        type={Types.TYPE_SOCIAL_DISPLAY_LONG_FORM}
      />
    );
  };

  const processTeacherCourses = () => {
    if (!idTeacher) return null;
    return (
      <Box>
        <Typography className="generalTextAlign" variant="h2" my={3}>
          {LL("Cursurile mele Teacher")}
        </Typography>
        <CourseListByTeacherDisplayBlock idTeacher={idTeacher} />
      </Box>
    );
  };

  const processRatingForStatistic = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("rating"))
      return <MyRatingReadOnly rating={0} showDetails={false} />;
    if (!obj.rating) return <MyRatingReadOnly rating={0} showDetails={false} />;
    return (
      <Stack direction="column">
        <Typography
          color="gold"
          className="ratingNumberSize"
          textAlign="center">
          {CommonTools.roundToTenth(obj.rating)}
        </Typography>
        <MyRatingReadOnly rating={obj.rating} showDetails={false} />
        <Typography textAlign="center" className="ratingName" mt={1}>
          {LL("Rating_Teacher")}
        </Typography>
      </Stack>
    );
  };

  const processTeacherTestimonial = () => {
    if (!obj) return null;
    if (!obj.id) return null;

    return <TeacherTestimonials idTeacher={obj.id} />;
  };

  return idTeacher ? (
    <Box>
      <Box className="container teacherProfile">
        <Grid container direction="row" py={5} spacing={2}>
          <Grid item sm={12} md={6}>
            {processTeacherInfo()}
          </Grid>
          <Grid item sm={12} md={1}></Grid>
          <Grid item sm={12} md={5} alignItems="center" alignContent="center">
            {processVideoPresentation()}
            {processUserSocials()}
          </Grid>
        </Grid>

        {processTeacherCourses()}
        <Grid container mb={5}>
          <Grid item sm={12} md={7}>
            {processTeacherStatistic()}
            {processTeacherTestimonial()}
            {processVideoLessonExample()}
          </Grid>
        </Grid>
      </Box>
      <NewsletterBlock currentRoute={currentRoute} />
    </Box>
  ) : null;
};

export { PublicTeacherProfile };
