import React from "react";

import {
  Link,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  IconButton,
} from "@mui/material";

import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";

import { CourseEnrollmentDto } from "dto/course/courseenrollment.dto";

import CommentIcon from "@mui/icons-material/Comment";

type Props = {
  obj: CourseEnrollmentDto;
  index: number;
};

const ClassroomStudentItem: React.FC<Props> = ({ obj, index }) => {
  const processUser = () => {
    if (!obj) return null
    if (!obj.hasOwnProperty("student")) return null
    if (!obj.student) return null
    if (!obj.student.hasOwnProperty("user")) return null
    if (!obj.student.user) return null
    let fullUrl = "";
    if (obj.student.user.hasOwnProperty("fullurl") && obj.student.user.fullurl)
      fullUrl = obj.student.user.fullurl;

    if (!fullUrl) {
      return (
        <ListItemButton>
          <ListItemAvatar>{processAvatar()}</ListItemAvatar>
          <ListItemText className="name" primary={processUserFullName()} />
        </ListItemButton>
      );
    }
    return (
      <ListItemButton
        component={Link}
        href={fullUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ListItemAvatar>{processAvatar()}</ListItemAvatar>
        <ListItemText className="name" primary={processUserFullName()} />
      </ListItemButton>
    );
  };

  const processUserFullName = () => {
    if (!obj) return "";
    if (!obj.hasOwnProperty("student")) return "";
    if (!obj.student) return "";
    if (!obj.student.hasOwnProperty("fullname")) return "";
    if (!obj.student.fullname) return "";

    return obj.student.fullname;
  };
  const processAvatar = () => {
    if (!obj) return null
    if (!obj.hasOwnProperty("student")) return null
    if (!obj.student) return null
    let name = "";
    let surname = "";
    if (obj.student.hasOwnProperty("name") && obj.student.name)
      name = obj.student.name;
    if (obj.student.hasOwnProperty("surname") && obj.student.surname)
      surname = obj.student.surname;

    return (
      <MyAvatar
        obj={obj.student}
        name={name}
        surname={surname}
        sx={{ mr: 1 }}
      />
    );
  };

  const processItem = () => {
    if (!obj) return null

    return (
      <ListItem
        key={index}
        secondaryAction={
          <IconButton edge="end" aria-label="comments">
            <CommentIcon />
          </IconButton>
        }
        disablePadding
      >
        {processUser()}
      </ListItem>
    );
  };

  return processItem();
};

export { ClassroomStudentItem };
