import React, { useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { logger } from "tools/utils/logger";
import { useResource } from "hooks/useResource";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import SelectOptions from "dto/app/selectoptions.dto";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import FormControl from "@mui/material/FormControl/FormControl";
import { NoResult } from "components/general/NoResult";

type MySelectProps = {
    options: Array<SelectOptions>;
    setValue: ((value: any) => void);
    value: any;
    _label: string,
    processOptionsCustom?: any;
    [key: string]: any; // for the rest of the props which we do not have types for
}

const MySelect: React.FC<MySelectProps> = ({
    options,
    setValue,
    value,
    _label,
    processOptionsCustom,
    ...props
}) => {
    const { LL } = useResource();

    const label = _label !== undefined ? LL(_label) : 'select_label'

    const handleChange = (event: SelectChangeEvent) => {
        setValue(event.target.value);
    };
    const processOptions = () => {
        if (!options) return <MenuItem value="" disabled>{LL('No_Data')}</MenuItem>;
        if (options.length === 0) return <MenuItem value="" disabled>{LL('No_Data')}</MenuItem>;
        return options.map((item, index) => (<MenuItem key={index} value={item.value}>{item.label}</MenuItem>))
    }



    return (
        <FormControl fullWidth>
            <InputLabel id="select-label">{label}</InputLabel>
            <Select
                labelId="select-label"
                id="simple-select"
                color="secondary"
                variant="filled"
                onChange={handleChange}
                value={value !== undefined && value !== 0 ? value : ''}
                label={label}

                {...props}
            >
                {processOptionsCustom === undefined ? processOptions() : processOptionsCustom()}
            </Select>
        </FormControl>
    );
};

export { MySelect };
