import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

import { InfoMenuLevel } from "./InfoMenuLevel";


const InfoMenuBlock: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  
  return <InfoMenuLevel currentRoute={currentRoute} level={1} idparent={""} />; 
};

export { InfoMenuBlock };
