import React, { useState, useEffect, useContext, useCallback } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { StudentCourseContext } from "context/studentcourse.context";
import { Box, Grid, Typography } from "@mui/material";
import { CommonTools } from "tools/utils/commontools";
import { StudentCoursePageTopBar } from "./StudentCoursePageTopBar";
import { StudentCourseTabBlock } from "./StudentCourseTabBlock";
import { StudentCourseToolBox } from "./StudentCourseToolBox";
import { useResource } from "hooks/useResource";
import { InteractionArea } from "./InteractionArea";
import { CircularLoading } from "components/elements/loading/CircularLoading";

export const StudentCoursePage: React.FC<PageComponentProps> = ({
  currentRoute,
}) => {
  const { LL } = useResource();
  const {
    state: {
      courseEnrollmentObject,
      course,
      idCourse,
      idCourseEnrollment,
      reloadCourseEnrollment,
      reloadLessonEnrollment,
      loadingCourseEnrollmentArea,
      lessonEnrollmentObject,
    },
    actions: {
      setCurrentPath,
      getCourse,
      getCourseEnrollment,
      getCurrentLesson,
      getLessonList,
      resetState,
      getCurrentLessonEnrollmentWhenIdCurrentLessonIsSet,
      getCurrentLessonAfterTest,
      setIdVideoSelected,
    },
  } = useContext(StudentCourseContext);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setCurrentPath(
      CommonTools.getIdFromPath(currentRoute, "courseenrollment", 1, 3),
      CommonTools.getIdFromPath(currentRoute, "courseenrollment", 1, 2)
    );

    setIdVideoSelected(CommonTools.getIdFromPath(currentRoute, "video", 4, 5));
    return () => {
      resetState();
    };
  }, []);

  const checkLoading = useCallback(() => {
    let loading = false;
    if (courseEnrollmentObject === null) loading = true;
    if (idCourseEnrollment === "") loading = true;
    if (idCourse === "") loading = true;
    if (course === null) loading = true;
    setLoading(loading);
  }, [courseEnrollmentObject, idCourse, course, idCourseEnrollment]);

  const getCourseObject = useCallback(() => {
    if (!idCourse) return;
    getCourse(idCourse);
  }, [idCourse]);

  const getCourseEnrollmentObject = useCallback(() => {
    if (!idCourseEnrollment) return;

    getCourseEnrollment(idCourseEnrollment);
  }, [idCourseEnrollment, reloadCourseEnrollment, lessonEnrollmentObject]);

  const getLessons = useCallback(() => {
    getLessonList();
  }, [idCourse]);

  useEffect(() => {
    getCourseEnrollmentObject();
  }, [getCourseEnrollmentObject]);

  useEffect(() => {
    getCurrentLessonAfterTest();
  }, [getCurrentLessonAfterTest]);

  useEffect(() => {
    getCourseObject();
  }, [getCourseObject]);

  useEffect(() => {
    getCurrentLesson();
  }, [getCurrentLesson]);

  useEffect(() => {
    getCurrentLessonEnrollmentWhenIdCurrentLessonIsSet();
  }, [
    getCurrentLessonEnrollmentWhenIdCurrentLessonIsSet,
    reloadLessonEnrollment,
  ]);

  useEffect(() => {
    getLessons();
  }, [getLessons]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  const processComponent = () => {
    if (loading || loadingCourseEnrollmentArea) return <CircularLoading />;
    if (!courseEnrollmentObject) return null;
    if (!course) return null;
    return (
      <Box className="student-course-detail">
        <StudentCoursePageTopBar />
        <Grid container className="container">
          <Grid item lg={9} xs={12}>
            <InteractionArea currentRoute={currentRoute} />
            <StudentCourseTabBlock currentRoute={currentRoute} />
          </Grid>
          <Grid item lg={3} xs={12} className="right-student-course">
            <Typography variant="h5" className="title-right">
              {LL("Continutul cursului")}
            </Typography>
            <StudentCourseToolBox currentRoute={currentRoute} />
          </Grid>
        </Grid>
      </Box>
    );
  };
  return processComponent();
};
