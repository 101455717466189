import React, { useState,  } from "react";

import RequestListDTO from "dto/app/requestlist.dto";

import { useResource } from "hooks/useResource";

import { TicketService } from "services/support/ticket.service";
import RequestFilterDTO from "dto/app/requestfilter.dto";


import { Box, List } from "@mui/material";

import { TicketDto } from "dto/support/ticket.dto";

import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

import { LoadMoreButton } from "components/elements/MyButton/LoadMoreButton";
import { SkeletonTicketAccordions } from "components/elements/Skeleton/SkeletonTicket";
import { TicketListMenuItem } from "./TicketListMenuItem";
import { CallbackType } from "interfaces/commontypes.interface";
import { CommonTools } from "tools/utils/commontools";
import { useList } from "hooks/useList";
import { NoResult } from "components/general/NoResult";

const service = new TicketService();

type Props = {
  userObject: any;
  userObjectField: string;
  userType: number;
  
};
const TicketListMenu: React.FC<Props> = ({
  userObject,
  userType,
  userObjectField,
}) => {
  const { CC } = useResource();

  const [onPage, setOnPage] = useState<number>(
    parseInt(CC("PAGINATION_ON_PAGE_Ticket", "10"))
  );

  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    if (!userObject) return;
    if (!userType) return;
    if (!userObjectField) return;
    service.getList(cb, cbParams, req);
  };

  const [loading, objects, total, , setObjects] = useList<TicketDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter(userObjectField, [
          CommonTools.processObjectField(userObject, ["id"]),
        ]),
        RequestFilterDTO.prepareFilter("type", [userType.toString()]),
      ],
      1,
      onPage,
      [RequestSortCriteriaDTO.prepareSortCriteria("date", false)]
    ),
    [userObject, onPage, userType, userObjectField]
  );

  const updateArray = (object: TicketDto) => {
    if (!objects) return;
    if (!object) return;
    const newObjects = [...objects];
    const id = CommonTools.processObjectField(object, ["id"]);
    const index = newObjects.findIndex((o) => o.id === id);
    if (index === -1) return;
    newObjects[index] = object;
    setObjects(newObjects);
  }
  // const getList = () => {
  //   if (!userObject) return;
  //   if (!userObject.hasOwnProperty("id")) return;
  //   if (!userObject.id) return;
  //   const reqList = new RequestListDTO();
  //   reqList.onpage = onPage;
  //   reqList.page = 1;
  //   reqList.filters = [];

  //   let f: RequestFilterDTO;

  //   f = new RequestFilterDTO();
  //   f.field = userObjectField;
  //   f.values = [userObject.id];
  //   reqList.filters.push(f);

  //   f = new RequestFilterDTO();
  //   f.field = "type";
  //   f.values = [userType.toString()];
  //   reqList.filters.push(f);

  //   const sort = new RequestSortCriteriaDTO();
  //   sort.field = "date";
  //   sort.asc = false;

  //   reqList.sortcriteria = [sort];

  //   setIsLoading(true);
  //   service.getList(loadObjects, {}, reqList);
  // };

  // const loadObjects = (result: ResultListDTO) => {
  //   if (!result) return;
  //   if (!result.err) {
  //     const objects = result.objects ? result.objects : [];
  //     const total = result.total !== undefined ? result.total : -1;

  //     setObjects(objects);
  //     setTotal(total);
  //   }
  // };

  // const checkLoading = useCallback(() => {
  //   let load = false;
  //   if (total === -1) return true;
  //   if (!objects) load = true;

  //   setIsLoading(load);
  // }, [total, objects]);

  // useEffect(() => {
  //   getList();
  // }, [userObject, onPage, userType, userObjectField]);

  // useEffect(() => {
  //   checkLoading();
  // }, [checkLoading]);

  const processList = () => {
    if (loading) return <SkeletonTicketAccordions lines={3} />;
    if (!objects) return null;
    if (!objects.length) return <NoResult identifier="ticket" />;;

    return (
      <List>
        {objects.map((item: TicketDto, i: number) => {
          return processItem(item, i);
        })}
      </List>
    );
  };
  const processLoadMoreObjects = () => {
    if (!objects || !objects.length) return null;
    return (
      <LoadMoreButton
        objects={objects}
        total={total}
        onPage={onPage}
        setOnPage={setOnPage}
        step={parseInt(CC("DefaultOnPageTicketList", "4"))}
        className="loadMorebtn"
      />
    );
  };

  const processItem = (obj: TicketDto, index: number) => {
    if (!obj) return null;
    return <TicketListMenuItem obj={obj} key={index} updateArray={updateArray} />;
  };

  const processComponent = () => {
    return (
      <Box>
        {processList()}
        {processLoadMoreObjects()}
      </Box>
    );
  };

  return processComponent();
};

export { TicketListMenu };
