import { TeacherDto } from "dto/user/teacher.dto";
import { useAuth } from "hooks/useAuth";
import useObject from "hooks/useObject";
import { CallbackType } from "interfaces/commontypes.interface";
import IProvider, { Action } from "interfaces/provider.interface";
import React, { useContext, useEffect, useReducer } from "react";

import { TeacherService } from "services/user/teacher.service";
import { CommonTools } from "tools/utils/commontools";

export type StateResource = {
  teacherInfo: TeacherDto | null;
};

const SET_TEACHER_INFO = "set_teacher_info";

const service = new TeacherService();

type ContextType = {
  teacherInfo: TeacherDto | null;
  loading: boolean;
  setTeacherInfo: (teacher: TeacherDto | null) => void;
}
export const TeacherInfoContext = React.createContext<ContextType>({
  teacherInfo: null,
  loading: false,
  setTeacherInfo: (teacher: TeacherDto | null) => {},
});

const reducer = (state: StateResource, action: Action) => {
  switch (action.type) {
    case SET_TEACHER_INFO: {
      return {
        ...state,
        teacherInfo: action.payload,
      };
    }
    default:
      return state;
  }
};

export const TeacherInfoProvider: React.FC<IProvider> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    teacherInfo: null,
  });
  const { user } = useAuth();

  const id = CommonTools.processObjectField(user, ["id"]);

  const get = (id: string, cb?: CallbackType, cbParams?: any) => {
    if (!id) return;
    service.getByUserId(id, cb, cbParams);
  };

  const [loading, obj] = useObject<TeacherDto>(get, id, [id]);

  const setTeacherInfo = (teacher: TeacherDto | null) => {
    dispatch({ type: SET_TEACHER_INFO, payload: teacher });
  };

  useEffect(() => {
    if (!obj) return;
    setTeacherInfo(obj);
  }, [obj]);

  return (
    <TeacherInfoContext.Provider value={{ ...state, loading, setTeacherInfo }}>
      {children}
    </TeacherInfoContext.Provider>
  );
};


export const useTeacherInfo = () => useContext(TeacherInfoContext);