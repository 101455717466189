import React from "react";
import { useResource } from "hooks/useResource";

import { TeacherDto } from "dto/user/teacher.dto";

import { MyNavBt } from "components/elements/button/MyNavBt";

import Box from "@mui/material/Box";

import Card from "@mui/material/Card";

import { ComponentCommonTools } from "tools/utils/componentcommontools";
import Typography from "@mui/material/Typography";

import { MyRatingReadOnly } from "components/elements/MyRating/MyRatingReadOnly";
import { Stack } from "@mui/system";
import { TeacherTypeIcon } from "./TeacherTypeIcon";
import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";

type Props = {
  obj: TeacherDto;
};

const TeacherListCard: React.FC<Props> = ({ obj }) => {
  const { LL } = useResource();

  const processRating = () => {
    if (!obj) return null;
    if (
      !obj.hasOwnProperty("rating") ||
      !obj.hasOwnProperty("numberoftestimonials")
    )
      return <MyRatingReadOnly rating={0} count={0} />;
    if (!obj.rating || !obj.numberoftestimonials)
      return <MyRatingReadOnly rating={0} count={0} />;
    return (
      <MyRatingReadOnly rating={obj.rating} count={obj.numberoftestimonials} />
    );
  };

  const processButton = () => {
    if (!obj) return null;
    if (!obj.id) return null;
    let fullurl = "";
    if (obj.hasOwnProperty("fullurl") && obj.fullurl) fullurl = obj.fullurl;
    return (
      <MyNavBt
        className="btn"
        href={fullurl}
        children={LL("BT_Show_Profile")}
      />
    );
  };

  const processAvatar = () => {
    if (!obj) return null;
    let name = "";
    let surname = "";
    if (obj.hasOwnProperty("name") && obj.name) name = obj.name;
    if (obj.hasOwnProperty("surname") && obj.surname) surname = obj.surname;
    // return <Avatar>{processAvatarName()}</Avatar>
    return (
      <MyAvatar
        obj={obj}
        name={name}
        surname={surname}
        className="badgeImg"
        sx={{ mr: 1 }}
      />
    );
  };

  const getNumberOfCourses = () => {
    if (!obj) return 0;
    if (!obj.hasOwnProperty("numberofcourses")) return 0;
    if (!obj.numberofcourses) return 0;

    return obj.numberofcourses;
  };

  const getNumberOfStudents = () => {
    if (!obj) return 0;
    if (!obj.hasOwnProperty("numberofstudents")) return 0;
    if (!obj.numberofstudents) return 0;
    return obj.numberofstudents;
  };
  const processNumberOfCourses = () => {
    if (!obj) return null;

    return (
      <Typography className="number courses">
        {LL("Number_of_courses: ")}
        <b>&nbsp;{getNumberOfCourses()}</b>
      </Typography>
    );
  };
  const processNumberOfStudents = () => {
    if (!obj) return null;

    return (
      <Typography className="number students">
        {LL("Number_of_students: ")}
        <b>&nbsp;{getNumberOfStudents()}</b>
      </Typography>
    );
  };

  const processTeacherStatus = () => {
    if (!obj) return null;
    return <TeacherTypeIcon obj={obj} />;
  };
  const processItem = () => {
    if (!obj) return null;

    return (
      <Card className="card" sx={{mb:1}}>
        <Box p={2} sx={{ display: "flex" }}>
          <Box>{processAvatar()}</Box>
          <Box>
            <Stack direction="row" spacing={1}>
              {processTeacherStatus()}
              <Typography className="name">
                {ComponentCommonTools.processObjectField(obj, "name")}
              </Typography>
              <Typography className="name">
                {ComponentCommonTools.processObjectField(obj, "surname")}
              </Typography>
            </Stack>
            <Typography className="proffesion">
              {ComponentCommonTools.processObjectFieldOfObject(
                obj,
                "typediscipline",
                "name"
              )}
            </Typography>
            <Typography>{processRating()}</Typography>
          </Box>
        </Box>
        <Box px={2} pb={2}>
          <Stack
            direction="row"
            spacing={2}
            sx={{ justifyContent: "space-between" }}
          >
            <Stack direction="column" spacing={1}>
              {processNumberOfStudents()}
              {processNumberOfCourses()}
            </Stack>
            <Box>{processButton()}</Box>
          </Stack>
        </Box>
      </Card>
    );
  };

  return processItem();
};

export { TeacherListCard };
