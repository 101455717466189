import React, { useContext } from "react";
import { Box } from "@mui/material";
import { StudentCourseContext } from "context/studentcourse.context";
import { TestimonialDialog } from "components/user/testimonial/TestimonialDialog";
import { Status } from "tools//types/status";

const PlaceTestimonial: React.FC = () => {
  const {
    state: { courseEnrollmentObject, course, courseEnrollmentStatus },
  } = useContext(StudentCourseContext);

  const processComponent = () => {
    if (!courseEnrollmentObject) return null;
    if (!course) return null;
    switch (courseEnrollmentStatus) {
      case Status.ENR_NEED_END_TEST: {
        return (
          <Box>
            <TestimonialDialog course={course} />
          </Box>
        );
      }
      case Status.ENR_COURSE_FINISHED: {
        return (
          <Box>
            <TestimonialDialog course={course} />
          </Box>
        );
      }
      case Status.ENR_NEED_CERTIFICATE: {
        return (
          <Box>
            <TestimonialDialog course={course} />
          </Box>
        );
      }
      case Status.ENR_ARCHIVED: {
        return (
          <Box>
            <TestimonialDialog course={course} />
          </Box>
        );
      }
      case Status.ENR_WITH_CERTIFICATE: {
        return (
          <Box>
            <TestimonialDialog course={course} />
          </Box>
        );
      }
      default: {
        return null;
      }
    }
  };
  return processComponent();
};

export { PlaceTestimonial };
