import React, { useEffect, useState } from "react";

import { useResource } from "hooks/useResource";

import Box from "@mui/material/Box";

import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import MyTextField from "components/form/mytextfield";
import { IconButton, Skeleton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { CommonTools } from "tools/utils/commontools";

import RequiredValidator from "validators/required.validator";
import ValidateTools from "tools/utils/validate.tools";

import ResultObjectDTO from "dto/app/resultobject.dto";
import { MyButton } from "components/elements/button/MyButton";

import MyCheckboxField from "components/form/mycheckboxfield";

import { AttachmentDto } from "dto/system/attachment.dto";
import { AttachmentService } from "services/system/attachment.service";

import { LanguageSelect } from "components/elements/Select/LanguageSelect";
import { Status } from "tools//types/status";
import { StatusSelect } from "components/elements/Select/StatusSelect";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

const service = new AttachmentService();

type Props = {
  id: string;
  setId: any;
  open: boolean;
  setOpen: any;
  idparent: string;
  setObjectWasChanged: any;
  parent: string;
};
const EditAttachmentDialog: React.FC<Props> = ({
  id,
  setId,
  open,
  setOpen,
  idparent,
  setObjectWasChanged,
  parent,
  ...props
}) => {
  const { LL } = useResource();

  const [loading, setLoading] = useState<boolean>(true);
  const [obj, setObj] = useState<AttachmentDto | undefined>();
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [files, setFiles] = useState(null);

  const [validators, setValidators] = useState(false);
  const [vresults, setVResults] = useState<any>(false);

  const validateAll = () => {
    if (!obj) return;
    // if (!validators) return;

    obj.name = obj.name !== undefined ? obj.name : "";
    obj.ordercriteria = obj.ordercriteria !== undefined ? obj.ordercriteria : 0;
    obj.idlanguage = obj.idlanguage !== undefined ? obj.idlanguage : "";
    obj.isdefault = obj.isdefault !== undefined ? obj.isdefault : false;
    obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;
    setObj(obj);
  };

  const validateField = (field: any, value: any, v?: any) => {
    v = v ? v : JSON.parse(JSON.stringify(vresults));
    if (v === false) v = {};
    v[field] = ValidateTools.validate(field, value, validators, vresults);
    setVResults(v);
    return v;
  };

  const processValidators = () => {
    let v: any = {
      name: [],
    };

    v.name.push(new RequiredValidator("", ""));

    setValidators(v);
  };

  const checkLoading = () => {
    if (!loading) return;
    if (!obj) return;
    if (!validators) return;
    validateAll();
    setLoading(false);
  };

  useEffect(() => {
    processValidators();
    validateAll();
  }, []);
  useEffect(() => {
    checkLoading();
  }, [obj, validators]);

  const loadObject = (obj: ResultObjectDTO) => {
    if (!obj) return;
    if (!obj.err) {
      const object = obj.obj ? obj.obj : {};
      setObj(object);
    }
  };

  const getObject = () => {
    if (!open) return;
    setLoading(true);

    if (id !== undefined) {
      service.get(id, loadObject, {});
    }
  };

  useEffect(() => {
    getObject();
  }, [id]);

  const checkIsFormDisabled = () => {
    setIsFormDisabled(false);
  };
  useEffect(() => {
    checkIsFormDisabled();
  }, [vresults]);
  const setObjField = (field: any, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    setObj(t);

    // validateField(field, value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (isFormDisabled) return false;

    if (!obj) return;

    if (!idparent) return;
    obj.idparent = idparent;
    obj.parent = parent;

    setLoading(true);
    service.update(id, handleResult, {}, obj);
  };

  const handleResult = (result: ResultObjectDTO, cbparams: any) => {
    if (!result) return;
    if (!result.err) {
      setObjectWasChanged(CommonTools.generateRandomString(5));
      setOpen(false);
      setLoading(false);
      setFiles(null);
      setObj(undefined);
      setId("");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const processFileBlock = () => {
    if (loading) return <Skeleton />;
    if (!obj) return null;
    if (!obj.fileInfoObj) return null;

    return (
      <Box mt={3} mb={3}>
        {ComponentCommonTools.processFileLink(obj.fileInfoObj)}
      </Box>
    );
  };

  const processLanguage = () => {
    if (loading) return <Skeleton variant={"rectangular"} height={50} />;
    if (!obj) return null;
    return (
      <LanguageSelect
        tabIndex={4}
        id="idlanguage"
        name="idlanguage"
        className="form-select"
        variant="standard"
        value={obj.idlanguage}
        field="idlanguage"
        setObj={setObjField}
        obj={obj}
        _defaultValue={obj.idlanguage}
      />
    );
  };
  const processForm = () => {
    if (loading) return <Skeleton variant={"rectangular"} height={200} />;
    if (!obj) return null;

    return (
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            tabIndex={0}
            fullWidth
            id="name"
            name="name"
            variant="standard"
            className="form-input"
            color="primary"
            _label={LL("Name")}
            size="small"
            value={obj.name}
            setObj={setObjField}
            _vresults={undefined}
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <MyTextField
            tabIndex={1}
            fullWidth
            id="ordercriteria"
            name="ordercriteria"
            variant="standard"
            className="form-input"
            color="primary"
            _label={LL("ordercriteria")}
            size="small"
            value={obj.ordercriteria}
            setObj={setObjField}
            _vresults={undefined}
          />
        </Box>
        <Box sx={{ mt: 3 }}>{processLanguage()}</Box>
        <Box sx={{ mt: 3 }}>
          <StatusSelect
            tabIndex={2}
            id="status"
            name="status"
            variant="standard"
            className="form-select"
            color="primary"
            value={obj.status}
            field="status"
            size="small"
            setObj={setObjField}
            _defaultValue={obj.status}
          />
        </Box>

        <Box sx={{ mt: 3 }}>
          <MyCheckboxField
            tabIndex={4}
            id="isdefault"
            name="isdefault"
            _label={LL("isdefault")}
            setObj={(value: any) =>
              CommonTools.handleCheckValue(obj, setObj, "isdefault", value)
            }
            checked={obj.isdefault}
            color="primary"
            size="small"
            _vresults={undefined}
          />
        </Box>
        <Box sx={{ mt: 3 }} textAlign="center">
          <MyButton
            tabIndex={1}
            disabled={isFormDisabled}
            color="primary"
            className="btn"
            variant="contained"
            size="large"
            type="submit"
            children={LL("Bt_Submit")}
          />
        </Box>
      </form>
    );
  };
  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>{LL("Attachments")}</DialogTitle>
        <IconButton
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {processFileBlock()}
          {processForm()}
        </DialogContent>
      </Dialog>
    </>
  );
};

export { EditAttachmentDialog };
