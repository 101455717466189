import React from "react";
import Select, { SelectChangeEvent, SelectProps } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import SelectOptions from "dto/app/selectoptions.dto";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import FormControl from "@mui/material/FormControl/FormControl";
import { useResource } from "hooks/useResource";

type Props = SelectProps & {
  options: Array<SelectOptions>;
  setObjectField: (field: string, value: any) => void;
  value: any;
  _label: string;
  field: string;
};

const NewMySelect: React.FC<Props> = ({
  options,
  setObjectField,
  value,
  _label,
  field,

  ...props
}) => {
  const { LL } = useResource();

  const label = _label !== undefined ? LL(_label) : LL("select_label");

  const handleChange = (event: SelectChangeEvent<any>) => {
    setObjectField(field, event.target.value);
  };
  const processOptions = () => {
    if (!options)
      return (
        <MenuItem value="" disabled>
          {LL("No_Data")}
        </MenuItem>
      );
    if (options.length === 0)
      return (
        <MenuItem value="" disabled>
          {LL("No_Data")}
        </MenuItem>
      );
    return options.map((item, index) => (
      <MenuItem key={index} value={item.value}>
        {item.label}
      </MenuItem>
    ));
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        labelId="select-label"
        id="simple-select"
        color="secondary"
        onChange={handleChange}
        value={value !== undefined && value !== 0 ? value : ""}
        label={label}
        {...props}
      >
        {processOptions()}
      </Select>
    </FormControl>
  );
};

export { NewMySelect };
