import { Box, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import MyCKEditor from "components/elements/ckeditor/MyCKEditor";
import MyTextField from "components/form/mytextfield";
import { NewLanguageSelect } from "components/static/language/NewLanguageSelect";
import { EditGeneralInfoCourseDto } from "dto/course/course.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useEffect } from "react";
import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObj: EditGeneralInfoCourseDto;
  onSubmit: (obj: EditGeneralInfoCourseDto) => void;
  loading: boolean;
};

const FormGeneralInfo: React.FC<Props> = ({
  defaultObj,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField, setObject] =
    useForm<EditGeneralInfoCourseDto>(
      defaultObj,
      RequiredValidator.getValidators(["name"])
    );

  useEffect(() => {
    if (!defaultObj) return;
    setObject(defaultObj);
  }, [defaultObj]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled || loading) return;
    onSubmit(obj);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <Typography variant="h6" mb={1}>
            {LL("Numele cursului")}
          </Typography>
          <MyTextField
            tabIndex={0}
            fullWidth
            id="name"
            name="name"
            variant="standard"
            color="primary"
            className="form-input"
            _label={LL("Name")}
            value={obj.name}
            setObj={setObjField}
            _vresults={undefined}
          />
        </Box>
        <Box mt={3}>
          <Typography variant="h6" mb={1}>
            {LL("Selecteaza limba cursului")}
          </Typography>
          <NewLanguageSelect
            value={obj.idlanguage}
            setObjectField={setObjField}
            label=""
          />
        </Box>
        <Box mt={3} className="myCk">
          <Typography variant="h6" mb={1}>
            {LL("Descrierea cursului")}
          </Typography>
          <MyCKEditor
            data={obj.description ? obj.description : ""}
            setObj={setObjField}
            name="description"
          />
        </Box>
        <Box mt={3}>
          <Typography variant="h6" mb={1}>
            {LL("requirementstext")}
          </Typography>
          <MyCKEditor
            data={obj.requirementstext ? obj.requirementstext : ""}
            setObj={setObjField}
            name="requirementstext"
          />
        </Box>
        <Box mt={3}>
          <Typography variant="h6" mb={1}>
            {LL("whatwillyoulearntext")}
          </Typography>
          <MyCKEditor
            data={obj.whatwillyoulearntext ? obj.whatwillyoulearntext : ""}
            setObj={setObjField}
            name="whatwillyoulearntext"
          />
        </Box>
        <Box mt={3}>
          <Typography variant="h6" mb={1}>
            {LL("targetaudiencetext")}
          </Typography>
          <MyCKEditor
            data={obj.targetaudiencetext ? obj.targetaudiencetext : ""}
            setObj={setObjField}
            name="targetaudiencetext"
          />
        </Box>
        <Box mt={3} className="myCk">
          <Typography variant="h6" mb={1}>
            {LL("Descriere scurta")}
          </Typography>
          <MyCKEditor
            data={obj.shortdescription ? obj.shortdescription : ""}
            setObj={setObjField}
            name="shortdescription"
          />
        </Box>

        <Box sx={{ mt: 3, mb: 3 }} textAlign="center">
          <MyButton
            tabIndex={5}
            disabled={disabled || loading}
            color="primary"
            variant="contained"
            size="large"
            className="btn"
            type="submit"
            children={LL("Bt_Submit")}
          />
        </Box>
      </form>
    </Box>
  );
};

export { FormGeneralInfo };
