import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";
import { useResponsive } from "hooks/useResponsive";

interface SkeletonProps {
  lines?: number;
  width?: number;
}

const SkeletonDescription: React.FC<SkeletonProps> = ({ lines }) => {
  const line = (
    <Box>
      <Skeleton
        variant="text"
        sx={{ fontSize: "1.25rem", width: { xs: "100%", sm: "80%" }, mt: 2 }}
      />
      <Skeleton
        variant="text"
        sx={{ fontSize: "1.25rem", width: { xs: "60%", sm: "40%" } }}
      />
    </Box>
  );

  const gridItem = (
    <Grid xs={12}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignContent: "center",
          // marginX: "auto",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Skeleton variant="text" sx={{ fontSize: "2rem", width: "30%" }} />
        {Array(lines)
          .fill(null)
          .map((_, index) => (
            <React.Fragment key={index}>{line}</React.Fragment>
          ))}
      </Box>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", height: "auto" }}>
      <Box
        sx={{
          width: "90%",
          display: "flex",
          justifyContent: "start",
          alignContent: "center",
          margin: "auto",
          padding: "20px 15px 20px 15px",
        }}
      >
        <Grid
          container
          xs={12}
          sx={{ maxWidth: "1522px", margin: "auto" }}
          spacing={2}
        >
          {Array(1)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

const SkeletonCourseLessons: React.FC<SkeletonProps> = ({ lines }) => {
  const courseDropDwn = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        width: { xs: "100%", sm: "80%" },
        mt: 2,
      }}
    >
      <Skeleton
        variant="text"
        sx={{
          fontSize: "1.25rem",
          width: { xs: "49%", sm: "33%" },
          minWidth: "100px",
        }}
      />
      <Skeleton
        variant="text"
        sx={{
          fontSize: "1.25rem",
          width: { xs: "25%", sm: "20%" },
          minWidth: "100px",
        }}
      />
    </Box>
  );

  const gridItem = (
    <Grid xs={12}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignContent: "center",
          // marginX: "auto",
          flexDirection: "column",
        }}
      >
        <Skeleton variant="text" sx={{ fontSize: "2rem", width: "30%" }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            flexDirection: "row",
            gap: 3,
          }}
        >
          <Skeleton
            variant="text"
            sx={{ fontSize: "1.25rem", width: "20%", minWidth: "100px" }}
          />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1.25rem", width: "20%", minWidth: "100px" }}
          />
        </Box>
        {Array(lines)
          .fill(null)
          .map((_, index) => (
            <React.Fragment key={index}>{courseDropDwn}</React.Fragment>
          ))}
      </Box>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", height: "auto" }}>
      <Box
        sx={{
          width: "90%",
          padding: "20px 15px 20px 15px",
          display: "flex",
          justifyContent: "start",
          alignContent: "center",
          margin: "auto",
        }}
      >
        <Grid
          container
          xs={12}
          sx={{ maxWidth: "1522px", margin: "auto" }}
          spacing={2}
        >
          {Array(1)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

const SkeletonScnReviewsVertical: React.FC<SkeletonProps> = ({ lines }) => {
  const { matchesSM, matchesMD } = useResponsive();

  let gridItemsCount;
  if (matchesMD) {
    gridItemsCount = 12;
  } else if (matchesSM) {
    gridItemsCount = 12;
  } else {
    gridItemsCount = 12;
  }

  const gridItem = (
    <Grid
      xs={gridItemsCount}
      sx={{
        mb: 4,
        maxWidth: "1000px",
        minWidth: { xs: "310px", sm: "250px" },
      }}
    >
      <Grid container spacing={4}>
        <Grid
          xs={12}
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "start" },
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "80%" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "90%" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "80%" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "90%" }} />
        </Grid>
        <Grid
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "40%" }} />
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Grid xs={1} sx={{ maxWidth: "60px", minWidth: "60px" }}>
            <Skeleton variant="circular" width={50} height={50} />
          </Grid>
          <Grid
            xs={5}
            sx={{
              width: "30%",
              display: "flex",
              justifyContent: "center",
              alignContent: "start",
              alignItems: "start",
              flexDirection: "column",
            }}
          >
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem", width: "70%", ml: 2 }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem", width: "90%", ml: 2 }}
            />
          </Grid>
          <Grid
            xs={6}
            sx={{
              width: "40%",
              display: "flex",
              justifyContent: "center",
              alignContent: "end",
              alignItems: "end",
              flexDirection: "column",
            }}
          >
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "70%" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "40%" }} />
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", height: "auto" }}>
      <Box
        sx={{
          width: "90%",
          height: "auto",
          padding: "20px 15px 20px 15px",
          display: "flex",
          justifyContent: { xs: "center", sm: "start" },
          alignItems: { xs: "center", sm: "start" },
          margin: "auto",
        }}
      >
        <Grid
          container
          xs={12}
          sx={{
            maxWidth: "1522px",
            margin: "auto",
            display: "flex",
            justifyContent: { xs: "center", sm: "start" },
            alignContent: "center",
          }}
          spacing={2}
        >
          {Array(lines)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

const SkeletonTeacherFAQ: React.FC<SkeletonProps> = ({ lines }) => {
  const { matchesSM, matchesMD } = useResponsive();

  let gridItemsCount;
  if (matchesMD) {
    gridItemsCount = 12;
  } else if (matchesSM) {
    gridItemsCount = 12;
  } else {
    gridItemsCount = 12;
  }
  const line = (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          flexDirection: "row",
          width: "100%",
          gap: 2,
        }}
      >
        <Skeleton
          variant="circular"
          width={50}
          height={50}
          sx={{ minWidth: "50px" }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
          }}
        >
          <Skeleton
            variant="text"
            sx={{ fontSize: "1.25rem", width: "30%", minWidth: "140px" }}
          />
          <Skeleton
            variant="text"
            sx={{ fontSize: "0.75rem", width: "20%", minWidth: "100px" }}
          />
        </Box>
      </Box>
      <Skeleton
        variant="text"
        sx={{ fontSize: "1rem", width: "66%", minWidth: "100px", mt: 1, ml: 8 }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          flexDirection: "row",
          width: "80%",
          gap: 2,
          mt: 2,
          ml: 8,
        }}
      >
        <Skeleton
          variant="circular"
          width={50}
          height={50}
          sx={{ minWidth: "50px" }}
        />
        <Box
          sx={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
          }}
        >
          <Skeleton
            variant="text"
            sx={{ fontSize: "1.25rem", width: "30%", minWidth: "140px" }}
          />
          <Skeleton
            variant="text"
            sx={{ fontSize: "0.75rem", width: "20%", minWidth: "100px" }}
          />
        </Box>
      </Box>
      <Skeleton
        variant="text"
        sx={{
          fontSize: "1rem",
          width: "60%",
          minWidth: "100px",
          mt: 1,
          ml: 16,
        }}
      />
    </>
  );

  const gridItem = (
    <Grid
      xs={gridItemsCount}
      sx={{
        mb: 3,
        maxWidth: "1000px",
        minWidth: { xs: "310px", sm: "250px" },
      }}
    >
      <Box>{line}</Box>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", height: "auto" }}>
      <Box
        sx={{
          width: "90%",
          height: "auto",
          padding: "20px 15px 20px 15px",
          display: "flex",
          justifyContent: { xs: "center", sm: "start" },
          alignItems: { xs: "center", sm: "start" },
          margin: "auto",
        }}
      >
        <Grid
          container
          xs={12}
          sx={{
            maxWidth: "1522px",
            margin: "auto",
            display: "flex",
            justifyContent: { xs: "center", sm: "start" },
            alignContent: "center",
          }}
          spacing={2}
        >
          {Array(lines)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

const SkeletonTeacherProfile: React.FC = () => {
  const { matchesSM, matchesMD } = useResponsive();

  let gridItemsCount;
  if (matchesMD) {
    gridItemsCount = 12;
  } else if (matchesSM) {
    gridItemsCount = 12;
  } else {
    gridItemsCount = 12;
  }
  const line = (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            flexDirection: "row",
            width: "100%",
            gap: 2,
          }}
        >
          <Skeleton
            variant="circular"
            width={92}
            height={92}
            sx={{ minWidth: "92px" }}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
            }}
          >
            <Skeleton
              variant="text"
              sx={{ fontSize: "1.5rem", width: "30%", minWidth: "140px" }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1.25em", width: "25%", minWidth: "100px" }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1.25rem", width: "25%", minWidth: "140px" }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            flexDirection: "row",
            gap: 2,
            mt: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
            }}
          >
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem", width: "20%", minWidth: "100px" }}
            />
            <Skeleton
              variant="text"
              sx={{
                fontSize: "1.5rem",
                width: "10%",
                minWidth: "100px",
                mt: 2,
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
            }}
          >
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem", width: "20%", minWidth: "100px" }}
            />
            <Skeleton
              variant="text"
              sx={{
                fontSize: "1.5rem",
                width: "10%",
                minWidth: "100px",
                mt: 2,
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
            }}
          >
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem", width: "20%", minWidth: "100px" }}
            />
            <Skeleton
              variant="text"
              sx={{
                fontSize: "1.5rem",
                width: "10%",
                minWidth: "100px",
                mt: 2,
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );

  const gridItem = (
    <Grid
      xs={gridItemsCount}
      sx={{ maxWidth: "1000px", minWidth: { xs: "310px", sm: "250px" } }}
    >
      <Box>{line}</Box>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", height: "auto" }}>
      <Box
        sx={{
          width: "90%",
          height: "auto",
          padding: "20px 15px 20px 15px",
          display: "flex",
          justifyContent: { xs: "center", sm: "start" },
          alignItems: { xs: "center", sm: "start" },
          margin: "auto",
        }}
      >
        <Grid
          container
          xs={12}
          sx={{
            maxWidth: "1522px",
            margin: "auto",
            display: "flex",
            justifyContent: { xs: "center", sm: "start" },
            alignContent: "center",
          }}
          spacing={2}
        >
          {Array(1)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export {
  SkeletonDescription,
  SkeletonCourseLessons,
  SkeletonScnReviewsVertical,
  SkeletonTeacherFAQ,
  SkeletonTeacherProfile,
};
