import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

import { StaticHTTPCodePage } from "components/general/StaticHTTPCodePage";

const Forbidden: React.FC<PageComponentProps> = () => {
  return <StaticHTTPCodePage code="403" label="Forbidden" />;
};

export { Forbidden };
