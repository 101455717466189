import React from "react";
import { useResource } from "hooks/useResource";
import { useAuth } from "hooks/useAuth";

import PageComponentProps from "interfaces/pagecomponentprops.interface";

import Container from "@mui/material/Container";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import { AuthMethodsBlock } from "components/auth/AuthMethodsBlock";
import { FormLogin } from "components/auth/FormLogin";

const Login: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
  const { signIn } = useAuth();

  const { LL } = useResource();

  return (
    <Box className="authMaineBox">
      <Container maxWidth="xs">
        <Box
          mt={5}
          sx={{
            alignItems: "center",
            alignContent: "center",
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
          }}>
          <Typography variant="h2" align="center" sx={{ mb: 5 }}>
            {LL("Autentificare în cont")}
          </Typography>

          <FormLogin onSubmit={signIn} />

          <Typography className="text-strike" my={4}>
            {LL("create_account?")}
          </Typography>
          <AuthMethodsBlock isLoginPage={true} />
        </Box>
      </Container>
    </Box>
  );
};

export { Login };
