import React, { useEffect, useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { logger } from "tools/utils/logger";
import { useResource } from "hooks/useResource";

import { MySelect } from "components/elements/Select/MySelect";
import { Types } from "tools/types/types"
import SelectOptions from "dto/app/selectoptions.dto";

type TypeSelectProps = {
    setValue: any;
    value: number;
    
    [key: string]: any; 
}
const TestTypeSelectFilter: React.FC<TypeSelectProps> = ({
    setValue,
    value,
    
    ...props
}) => {
    const { LL } = useResource();
    const label = "TestType";
    // const defaultValue = _defaultValue ? _defaultValue : '';
    const [options, setOptions] = useState<Array<SelectOptions>>(new Array<SelectOptions>());
    const [loading, setLoading] = useState<boolean>(true);
    // const [value, setValue] = useState<number | any>(defaultValue);
    const processOptions = () => {
        const options: Array<SelectOptions> = Types.GA('testtype');
        const defaultOption = new SelectOptions();
        defaultOption.label = LL('All');
        defaultOption.value = -1;
        options.unshift(defaultOption);
        setOptions(options);
    }

    useEffect(() => {
        processOptions()
    }, []);

    useEffect(() => {
        checkIsLoading();
    }, [options]);

    // useEffect(() => {
    //     if(loading) return;
        
    // }, [value]);


    const checkIsLoading = () => {
        let load: boolean = false;
        if (options === undefined) load = true;
        setLoading(load);
    };

    return !loading ? (
        <MySelect
            options={options}
            _label={label}
            setValue={setValue}
            value={value}
            {...props}
            fullWidth

            size='small'
            variant='standard'
            className="form-select"
        />
    ) : (<></>);
};

export { TestTypeSelectFilter };
