import React, { useState, useContext, useEffect } from "react";
import { LessonDto } from "dto/course/lesson.dto";
import {
  Accordion,
  Typography,
  Stack,
  AccordionSummary,
  Box,
  AccordionDetails,
  Collapse,
  Grid
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { MyButton } from "components/elements/button/MyButton";
import { useResource } from "hooks/useResource";
import { AttachmentListLessonStatic } from "components/system/attachment/AttachementListLessonStatic";
import { GalleryListLessonStatic } from "components/system/gallery/GalleryListLessonStatic";
import { StudentLessonDialogDescription } from "components/user/student/StudentLessonDialogDescription";
import { ClassroomContext } from "context/classroom.context";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import DoneIcon from "@mui/icons-material/Done";
import { VideoListLessonStaticClassroom } from "components/system/video/VideoListLessonStaticClassroom";

import {ClassroomPassLesson} from "./ClassroomPassLesson";

type Props = {
  obj: LessonDto;
  currentRoute: any;
};

export const ClassroomLessonItem: React.FC<Props> = ({
  obj,
  currentRoute,
}) => {
  const {
    state: { idSelectedLesson, selectedLessonObject },
    actions: { selectLesson },
  } = useContext(ClassroomContext);
  const { LL } = useResource();

  const processDefaultExpanded = () => {
    if (!idSelectedLesson) return false;
    if (!obj) return false;
    if (!obj.hasOwnProperty("id")) return false;
    if (!obj.id) return false;
    if (idSelectedLesson !== obj.id) return false;
    return true;
  };

  const [expanded, setExpanded] = useState<boolean>(processDefaultExpanded());
  const [openDescription, setOpenDescription] = useState<boolean>(false);
  const [openResource, setOpenResource] = useState<boolean>(false);

  useEffect(() => {
    if (!idSelectedLesson) return;
    setExpanded(processDefaultExpanded());
  }, [idSelectedLesson]);

  const processSelectedLesson = () => {
    if (!selectedLessonObject) return <></>;
    if (!selectedLessonObject.hasOwnProperty("id")) return <></>;
    if (!selectedLessonObject.id) return <></>;
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("id")) return <></>;
    if (!obj.id) return <></>;
    if (selectedLessonObject.id !== obj.id) return <></>;
    return (
      <Box>
        <PlayArrowIcon color="primary" />
      </Box>
    );
  };

  const handleOnClickLesson = () => {
    if (!obj) return;
    if (!obj.hasOwnProperty("id")) return;
    if (!obj.id) return;
    
    selectLesson(obj.id);
  };

  const processButtonPlay = () => {
    return (
      <>
        <MyButton className="playButton" fullWidth cb={handleOnClickLesson}>
          <PlayCircleOutlineOutlinedIcon />
          {LL("Play this lesson")}
        </MyButton>
      </>
    );
  };

  const handleOpenDescription = () => {
    setOpenDescription(true);
  };
  const processDescriptionButton = () => {
    return (
      <Box>
        <MyButton cb={handleOpenDescription} fullWidth sx={{display:"flex", flexDirection:'column',}}><i className="description-icon"></i>{LL("Description")}</MyButton>
      </Box>
    );
  };

  const handleOpenResource = () => {
    setOpenResource(!openResource);
  };

  const processResourceButton = () => {
    return (
      <Box>
        <MyButton cb={handleOpenResource} fullWidth sx={{display:"flex", flexDirection:'column',}}><i className="resource-icon"></i>{LL("Resource")}</MyButton>
      </Box>
    );
  };

  const processAttachmentList = () => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("id")) return <></>;
    if (!obj.id) return <></>;
    if (!obj.hasOwnProperty("attachment")) return <></>;
    if (!obj.attachment) return <></>;

    return (
      <AttachmentListLessonStatic objects={obj.attachment} disabled={false} />
    );
  };

  const processVideoSelectionEnabled = () => {
    if (!selectedLessonObject) return true;
    if (!selectedLessonObject.hasOwnProperty("id")) return true;
    if (!selectedLessonObject.id) return true;
    if (!obj) return true;
    if (!obj.hasOwnProperty("id")) return true;
    if (!obj.id) return true;
    if (selectedLessonObject.id !== obj.id) return true;
    return false;
  };
  const processVideoList = () => {
    console.log("processVideoList", currentRoute);
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("id")) return <></>;
    if (!obj.id) return <></>;
    if (!obj.hasOwnProperty("video")) return <></>;
    if (!obj.video) return <></>;

    return (
      <VideoListLessonStaticClassroom
        objects={obj.video}
        disabled={processVideoSelectionEnabled()}
        currentRoute={currentRoute}
      />
    );
  };

  const processGalleryList = () => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("id")) return <></>;
    if (!obj.id) return <></>;
    if (!obj.hasOwnProperty("gallery")) return <></>;
    if (!obj.gallery) return <></>;

    return <GalleryListLessonStatic objects={obj.gallery} disabled={false} />;
  };

  const processResourceMenu = () => {
    if (!openResource) return <></>;
    return (
      <Box>
        {processVideoList()}
        {processAttachmentList()}
        {processGalleryList()}
      </Box>
    );
  };

  const handleOnClick = () => {
    setExpanded(!expanded);
  };

  const processIconDone = () => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("ispassed")) return <></>;
    if (!obj.ispassed) return <></>;

    return (
      <Box>
        <DoneIcon color="success" />
      </Box>
    );
  };
  const processComponent = () => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("id")) return <></>;
    if (!obj.id) return <></>;
    return (
      <>
        <Accordion
          expanded={expanded}
          onChange={handleOnClick}
          className="accordionLessons"
          square
          elevation={0}
          disableGutters
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panelLessonContent"
            id={"panelLessonHeader" + obj.id}
          >
            <Stack direction="row" spacing={1} alignItems={"center"}>
              {processSelectedLesson()}
              {processIconDone()}
              <Box>
                <Typography>
                  {ComponentCommonTools.processObjectField(obj, "name")}
                </Typography>
              </Box>
            </Stack>
          </AccordionSummary>
          <AccordionDetails className="subAccordion">
            <Stack
              direction={"column"}
              alignContent={"center"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {processButtonPlay()}
              {<ClassroomPassLesson obj={obj}/>}
            </Stack>
            <Grid container className="zoneButtons">
              <Grid item xs={6} className="rightBorder">{processDescriptionButton()}</Grid>
              <Grid item xs={6}>{processResourceButton()}</Grid>
            </Grid>
            <Collapse in={openResource}>{processResourceMenu()}</Collapse>
          </AccordionDetails>
        </Accordion>
        <StudentLessonDialogDescription
          obj={obj}
          open={openDescription}
          setOpen={setOpenDescription}
        />
      </>
    );
  };

  return processComponent();
};
