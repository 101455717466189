import Idto from "interfaces/idto.interface";

export default class RequestFilterDTO implements Idto {
  field?: string;
  values?: string[];

  static prepareFilter(field: string, values: string[]): RequestFilterDTO {
    const filter = new RequestFilterDTO();
    filter.field = field;
    filter.values = values;
    return filter;
  }
}
