import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { Box } from "@mui/material";
import { useResource } from "hooks/useResource";

const TeacherDashboard: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { LL } = useResource();
  return <Box>{LL("TeacherDashboard")}</Box>;
};

export { TeacherDashboard };
