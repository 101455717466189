import React from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { logger } from "tools/utils/logger";
import { useResource } from "hooks/useResource";
import MenuItem from "@mui/material/MenuItem";
import SelectOptions from "dto/app/selectoptions.dto";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';

const options: SelectOptions[] = [
  { value: '1', label: '1 star' },
  { value: '2', label: '2 star' },
  { value: '3', label: '3 star' },
  { value: '4', label: '4 star' },
  { value: '5', label: '5 star' }
];

type RatingFilterSelectProps = {
  setValue: ((value: any) => void);
  valueFilter: Array<string>;
  _label: string;
  [key: string]: any;
};

const RatingFilterSelect: React.FC<RatingFilterSelectProps> = ({
  setValue,
  valueFilter,
  _label,
  ...props
}) => {
  const { LL } = useResource();
  const label = _label ? LL(_label) : 'select_label';

  const handleChange = (event: SelectChangeEvent<typeof valueFilter>) => {
    setValue(typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value);
  };

  const renderOptions = () => {
    if (!options || options.length === 0) return null;

    return options.map((item, index) => {
      if (!item || !item.value || typeof item.value !== 'string') return null;
      
      const localizedLabel = LL(item.label ?? "");
      return (
        <MenuItem key={index} value={item.value} >
          <Checkbox checked={valueFilter.includes(item.value)} />
          <ListItemText primary={localizedLabel} />
        </MenuItem>
      );
    });
  };

  const getRenderedValue = (selected: any[]) => {
    
    return selected.map(value => {
      const option = options.find(opt => opt.value === value);
      return option ? LL(option.label ?? "") : value;
    }).join(', ');
  };

  return (

    <FormControl fullWidth className="form-control">
      <InputLabel id="select-label" >{label}</InputLabel>
      <Select
        labelId="select-label"
        id="multiple-select"
        multiple
        onChange={handleChange}
        renderValue={getRenderedValue}
        value={valueFilter}
        label={label}
        input={<OutlinedInput label={label} />}
        {...props}
      >
        {renderOptions()}
      </Select>
    </FormControl>
  );
};

export { RatingFilterSelect };
