import { Delete, Save } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { MyFileField } from "components/form/MyFileField";

import { PostGalleryForCourseDto } from "dto/system/gallery.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useCallback, useEffect, useRef } from "react";
import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObj: PostGalleryForCourseDto;
  onSubmit: (obj: PostGalleryForCourseDto) => void;
  loading: boolean;
  onDelete: () => void;
  canDelete: boolean;
};

const FormCourseGallery: React.FC<Props> = ({
  defaultObj,
  onSubmit,
  loading,
  onDelete,
  canDelete,
}) => {
  const { LL } = useResource();
  const [obj, disabled, , setObject] = useForm<PostGalleryForCourseDto>(
    defaultObj,
    RequiredValidator.getValidators(["idparent"])
  );
  const inputRef = useRef<any>(null);
  const clearInput = useCallback(() => {
    if (!obj.files && inputRef.current) {
      inputRef.current.value = "";
    }
  }, [obj.files]);

  useEffect(() => {
    if (!defaultObj) return;
    setObject(defaultObj);
  }, [defaultObj]);

  useEffect(() => {
    clearInput();
  }, [clearInput]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled || loading) return;
    onSubmit(obj);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box sx={{ minWidth: "350px" }}>
          <Stack direction="row" alignItems={"center"} justifyContent="center">
            <Box>
              <MyFileField
                inputRef={inputRef}
                field="files"
                label={LL("files")}
                setObj={setObject}
                obj={obj}
                size="small"
                variant="standard"
                className="form-input"
                color="primary"
              />
            </Box>
            <Box>
              <MyButton
                disabled={disabled || loading || !obj.files}
                type="submit"
              >
                <Save />
              </MyButton>
            </Box>
            {canDelete && (
              <Box>
                <MyButton cb={onDelete}>
                  <Delete color="secondary" />
                </MyButton>
              </Box>
            )}
          </Stack>
        </Box>
      </form>
    </Box>
  );
};

export { FormCourseGallery };
