import React, { useContext } from "react";

import { StudentCourseContext } from "context/studentcourse.context";
import { Typography, Alert, Stack } from "@mui/material";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { useResource } from "hooks/useResource";
import { MyButton } from "components/elements/button/MyButton";

export const ShouldFinishLesson: React.FC = () => {
  const { LL } = useResource();
  const {
    state: {
      courseEnrollmentObject,
      lessonEnrollmentObject,
      isSelectedLessonTheCurrentLesson,
      currentLessonObject,
    },
    actions: { selectLesson },
  } = useContext(StudentCourseContext);

  const processLessonName = () => {
    if (!currentLessonObject) return null;
    return (
      <Typography>
        {ComponentCommonTools.processObjectField(currentLessonObject, "name")}
      </Typography>
    );
  };

  const handleGoToCurrentLesson = () => {
    if (!currentLessonObject) return;
    if (!currentLessonObject.hasOwnProperty("id")) return;
    if (!currentLessonObject.id) return;
    selectLesson(currentLessonObject.id);
  };

  const processComponent = () => {
    if (!lessonEnrollmentObject) return null;
    if (!courseEnrollmentObject) return null;
    if (isSelectedLessonTheCurrentLesson) return null;

    return (
      <Alert severity="warning">
        <Stack direction={"row"} spacing={2}>
          <Typography>{LL("YouShouldFinishCurrentLesson")}</Typography>
          {processLessonName()}
          <MyButton cb={handleGoToCurrentLesson} color="primary" sx={{ p: 0 }}>
            {LL("GoToCurrentLesson")}
          </MyButton>
        </Stack>
      </Alert>
    );
  };
  return processComponent();
};
