import React, { useContext } from "react";
import { useResource } from "hooks/useResource";
import { Alert, Box, Typography } from "@mui/material";
import { TestDialog } from "components/course/test/TestDialog";
import { Types } from "tools/types/types";
import { StudentCourseContext } from "context/studentcourse.context";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

import { SessionStorageTools } from "api/sessionstorage.api";
import { CommonTools } from "tools/utils/commontools";
import { NewTestDialog } from "components/course/test/new/NewTestDialog";
const StartTest: React.FC<PageComponentProps> = ({ currentRoute }) => {
  const object = SessionStorageTools.getObject("open_test");
  const open =
    CommonTools.processObjectField(object, ["action"]).toString() ===
    Types.TYPE_PARAM_FOR_TEST_OPEN_DEFAULT.toString()
      ? true
      : false;
  const { LL } = useResource();

  const {
    state: { idCourse, courseEnrollmentObject, course },
    actions: { triggerReloadCourseEnrollment },
  } = useContext(StudentCourseContext);

  
  const processComponent = () => {
    if (!courseEnrollmentObject) return null;
    if (!course) return null;
    return (
      <Box>
        <Alert severity="info" className="alertZone">
          <Typography mb={1}>
            {LL("To_buy_the_course_you_need_to_pass_the_initial_test")}
          </Typography>

          <NewTestDialog
            courseEnrollmentObject={courseEnrollmentObject}
            idCourse={idCourse}
            idLesson={""}
            type={Types.TYPE_TEST_BEFORE_COURSE}
            course={course}
            setCheckObjectWasChanged={triggerReloadCourseEnrollment}
            openDefault={open}
          />
        </Alert>
      </Box>
    );
  };
  return processComponent();
};

export { StartTest };
