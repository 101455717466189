import useMediaQuery from "@mui/material/useMediaQuery";
import { useResponsive } from "./useResponsive";
import { CommonTools } from "tools/utils/commontools";

type Props = {
  parseInt: any;
  objects: any[];
};

export const useSlidesToShow = ({ objects, parseInt }: Props) => {
  const { downXS, downSM, downMD, downLG, downXL, matchesCustom2 } =
    useResponsive();

  let slidesToShow;
  let testimonialSlidesToShow;
  if (downSM) {
    slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      1,
      objects.length
    );
    testimonialSlidesToShow =
      CommonTools.calculateNumberOfElementsShowOnCarousel(1, objects.length);
  } else if (downMD) {
    slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      2,
      objects.length
    );
    testimonialSlidesToShow =
      CommonTools.calculateNumberOfElementsShowOnCarousel(2, objects.length);
  } else if (downLG) {
    slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      2,
      objects.length
    );
    testimonialSlidesToShow =
      CommonTools.calculateNumberOfElementsShowOnCarousel(2, objects.length);
  } else if (downXL) {
    slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      3,
      objects.length
    );
    testimonialSlidesToShow =
      CommonTools.calculateNumberOfElementsShowOnCarousel(2, objects.length);
  } else {
    slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      parseInt,
      objects.length
    );
    testimonialSlidesToShow =
      CommonTools.calculateNumberOfElementsShowOnCarousel(
        parseInt,
        objects.length
      );
  }

  return { slidesToShow, testimonialSlidesToShow };
};
