import React, { useState } from "react";
import { useResource } from "hooks/useResource";
import { LessonDto } from "dto/course/lesson.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools//types/status";
import { LessonService } from "services/course/lesson.service";

import { Box, Skeleton } from "@mui/material";

import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

import { LoadMoreButton } from "components/elements/MyButton/LoadMoreButton";

import { CourseLessonsListItem } from "./CourseLessonListItem";
import { CallbackType } from "interfaces/commontypes.interface";
import { useList } from "hooks/useList";

type Props = {
  idCourse: string;
};

const service = new LessonService();

const CourseLessonsList: React.FC<Props> = ({ idCourse }) => {
  const { CC } = useResource();
  // const [isLoading, setIsLoading] = useState(true);
  // const [objects, setObjects] = useState<Array<LessonDto>>([]);
  // const [total, setTotal] = useState<number>(-1);
  // const [totalPage, setTotalPage] = useState<number>(-1);
  const [onPage, setOnPage] = useState<number>(
    parseInt(CC("DefaultOnPageCourseLessonsList", "10"))
  );

  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    if (!idCourse) return;
    service.getList(cb, cbParams, req);
  };

  const [loading, objects, total] = useList<LessonDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("idcourse", [idCourse]),
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
      ],
      1,
      onPage,
      [RequestSortCriteriaDTO.prepareSortCriteria("ordercriteria", true)]
    ),
    [idCourse, onPage]
  );

  const processLoadMoreObjects = () => {
    if (!objects || !objects.length) return null;
    return (
      <LoadMoreButton
        objects={objects}
        total={total}
        onPage={onPage}
        setOnPage={setOnPage}
        step={parseInt(CC("DefaultOnPageCourseLessonsList", "4"))}
        className="loadMorebtn"
      />
    );
  };
  // const getList = () => {
  //   setIsLoading(true);
  //   const reqList = new RequestListDTO();
  //   reqList.onpage = onPage;
  //   reqList.page = 1;
  //   reqList.filters = [];
  //   reqList.sortcriteria = [];

  //   const sortCriteria = new RequestSortCriteriaDTO();
  //   sortCriteria.field = "ordercriteria";
  //   sortCriteria.asc = true;
  //   reqList.sortcriteria.push(sortCriteria);

  //   let f: RequestFilterDTO;

  //   f = new RequestFilterDTO();
  //   f.field = "status";
  //   f.values = [];
  //   f.values.push(Status.ACTIVE.toString());
  //   reqList.filters.push(f);

  //   f = new RequestFilterDTO();
  //   f.field = "idcourse";
  //   f.values = [idCourse];
  //   reqList.filters.push(f);

  //   service.getList(loadObjects, {}, reqList);

  //   // setIsLoading(false);
  // };

  // const loadObjects = (result: ResultListDTO) => {
  //   if (!result) return;
  //   if (!result.err) {
  //     const objects = result.objects ? result.objects : [];
  //     const total = result.total !== undefined ? result.total : -1;
  //     const totalPage =
  //       result.totalpages !== undefined ? result.totalpages : -1;
  //     setObjects(objects);
  //     setTotal(total);
  //     setTotalPage(totalPage);
  //   }
  // };

  // const checkLoading = () => {
  //   let load = false;

  //   if (total === -1) return true;
  //   if (totalPage === -1) return true;
  //   if (!objects) load = true;
  //   setIsLoading(load);
  // };

  // useEffect(() => {
  //   getList();
  // }, [idCourse, onPage]);

  // useEffect(() => {
  //   checkLoading();
  // }, [objects]);

  const processLessonItem = (obj: LessonDto, index: number) => {
    if (!obj) return null;
    if (!obj.id) return null;

    return (
      <Box key={index}>
        <CourseLessonsListItem obj={obj} />
      </Box>
    );
  };

  const processLessonList = () => {
    if (!objects) return null;
    if (!objects.length) return null;

    return (
      <React.Fragment>
        {objects.map((item: LessonDto, i: number) => {
          return processLessonItem(item, i);
        })}
      </React.Fragment>
    );
  };

  return loading ? (
    <Skeleton />
  ) : (
    <Box>
      {processLessonList()}

      <Box mt={3}>{processLoadMoreObjects()}</Box>
    </Box>
  );
};

export { CourseLessonsList };
