import React, { useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

import Box from "@mui/material/Box";

import { TeacherService } from "services/user/teacher.service";
import { TeacherNotificationDto } from "dto/user/teacher.dto";
import { useAuth } from "hooks/useAuth";

import { CommonTools } from "tools/utils/commontools";
import { CircularLoading } from "components/elements/loading/CircularLoading";

import ResultObjectDTO from "dto/app/resultobject.dto";
import { FormTeacherNotification } from "./FormTeacherNotification";
import { useTeacherInfo } from "context/teacherinfo.context";

const service = new TeacherService();

const EditTeacherNotification: React.FC<PageComponentProps> = () => {
  const { user } = useAuth();
  const id = CommonTools.processObjectField(user, ["id"]);
  const [loadingAction, setLoadingAction] = useState<boolean>(false);

  // const get = (id: string, cb?: any, cbParams?: any) => {
  //   if (!id) return;
  //   service.getByUserId(id, cb, cbParams);
  // };
  // const [loading, obj, setObj, setLoading] = useObject<TeacherDto>(get, id, [
  //   id,
  // ]);
  const { teacherInfo, setTeacherInfo, loading } = useTeacherInfo();
  // const [loading, setLoading] = useState<boolean>(true);
  // const [obj, setObj] = useState<TeacherDto | undefined>();
  // const _id = user !== undefined ? user.id : '';
  // const [id, setId] = useState<string>(_id);
  // const [isFormDisabled, setIsFormDisabled] = useState(false);

  // const [validators, setValidators] = useState<any>();
  // const [vresults, setVResults] = useState<any>(false)

  // const validateField = (field: any, value: any, v?: any) => {

  //     v = (v) ? v : JSON.parse(JSON.stringify(vresults));
  //     if (v === false) v = {};
  //     v[field] = ValidateTools.validate(field, value, validators, vresults);

  //     setVResults(v);
  //     setIsFormDisabled(ValidateTools.vForm(v, field));
  //     return v;
  // };

  // const loadObject = (obj: any) => {
  //     if (!obj) return;
  //     if (!obj.err) {
  //         const object = obj.obj ? obj.obj : [];
  //         setObj(object);
  //     }
  // };

  // const getObject = () => {
  //     setLoading(true)
  //     if (id !== undefined && id !== '') teacherService.getByUserId(id, loadObject, {});
  // };

  // const checkIsLoading = () => {
  //     let load: boolean = false;
  //     if (obj === undefined) load = true;
  //     setLoading(load);
  // };

  // useEffect(() => {
  //     getObject();
  // }, [currentRoute]);

  // useEffect(() => {
  //     validateAll();
  //     checkIsLoading();
  // }, [obj]);

  // useEffect(() => {

  //     if (user !== undefined) {
  //         setId(user.id)
  //     }
  // }, [user])

  // const validateAll = () => {
  //     if (!obj) return;
  //     // if (!validators) return;
  //     // if (vresults) return;

  //     obj.notificationtype1 = obj.notificationtype1 !== undefined ? obj.notificationtype1 : false;
  //     obj.notificationtype2 = obj.notificationtype2 !== undefined? obj.notificationtype2 : false;
  //     obj.notificationtype3 = obj.notificationtype3 !== undefined ? obj.notificationtype3 : false;

  //     setObj(obj);
  // };

  // const setCheckNotificationType1 = (value: any) => {
  //     let t = JSON.parse(JSON.stringify(obj));
  //     t['notificationtype1'] = value;
  //     setObj(t);

  // };
  // const setCheckNotificationType2 = (value: any) => {
  //     let t = JSON.parse(JSON.stringify(obj));
  //     t['notificationtype2'] = value;
  //     setObj(t);

  // };
  // const setCheckNotificationType3 = (value: any) => {
  //     let t = JSON.parse(JSON.stringify(obj));
  //     t['notificationtype3'] = value;
  //     setObj(t);

  // };

  // const handleSubmit = (event: any) => {
  //     event.preventDefault();
  //     if (isFormDisabled) return false;
  //     if (id !== undefined && obj) {
  //         obj.iduser = id;
  //         setLoading(true);
  //         teacherService.addNotification(CommonTools.handleSuccessAddAndEditStayOnPage, { setLoading }, obj);
  //     }
  // };
  const onSubmit = (obj: TeacherNotificationDto) => {
    setLoadingAction(true);
    service.addNotification(handleResult, {}, obj);
  };

  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    setTeacherInfo(result.obj);
    setLoadingAction(false);
  };

  if (!user) return null;
  if (loading) return <CircularLoading />;
  if (!teacherInfo) return null;
  return (
    <Box
      p={3}
      maxWidth="sm"
      mx={"auto"}
      justifyContent="center"
      display="flex"
      flexDirection="column">
      <FormTeacherNotification
        onSubmit={onSubmit}
        loading={loading || loadingAction}
        defaultObject={TeacherNotificationDto.fromTeacherDto(id, teacherInfo)}
      />
    </Box>
  );

  // return user !== undefined && !loading && obj ? (
  //     <>

  //             <form onSubmit={handleSubmit} >
  //               <Box  p={3} maxWidth='md' mx={'auto'} justifyContent='center' display='flex' flexDirection='column'>
  //                 <Box sx={{ mt: 3 }}  className="likeCard">
  //                     <MyCheckboxField
  //                         tabIndex={0}
  //                         id="notificationtype1"
  //                         name="notificationtype1"
  //                         _label={LL("notificationtype1")}
  //                         setObj={setCheckNotificationType1}
  //                         checked={obj.notificationtype1}
  //                         color="primary"
  //                         size="small"
  //                         _vresults={undefined}
  //                     />
  //                 </Box>

  //                 <Box sx={{ mt: 3 }} className="likeCard">
  //                     <MyCheckboxField
  //                         tabIndex={1}
  //                         id="notificationtype2"
  //                         name="notificationtype2"
  //                         _label={LL("notificationtype2")}
  //                         setObj={setCheckNotificationType2}
  //                         checked={obj.notificationtype2}
  //                         color="primary"
  //                         size="small"
  //                         _vresults={undefined}
  //                     />
  //                 </Box>

  //                 <Box sx={{ mt: 3 }}  className="likeCard">
  //                     <MyCheckboxField
  //                         tabIndex={2}
  //                         id="notificationtype3"
  //                         name="notificationtype3"
  //                         _label={LL("notificationtype3")}
  //                         setObj={setCheckNotificationType3}
  //                         checked={obj.notificationtype3}
  //                         color="primary"
  //                         size="small"
  //                         _vresults={undefined}
  //                     />
  //                 </Box>
  //                 <Box textAlign='center' mt={4}>
  //                 <MyButton
  //                     tabIndex={3}
  //                     disabled={isFormDisabled}
  //                     color="primary"
  //                     className="btn"
  //                     variant="contained"
  //                     size="large"
  //                     type="submit"

  //                     children={LL("Bt_Submit")}
  //                 />

  //                 </Box></Box>
  //             </form>

  //     </>
  // ) : (
  //     <CircularLoading />
  // );
};

export { EditTeacherNotification };
