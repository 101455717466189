import React, { useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

import Box from "@mui/material/Box";

import { TeacherService } from "services/user/teacher.service";
import { TeacherDto } from "dto/user/teacher.dto";
import { useAuth } from "hooks/useAuth";

import { CommonTools } from "tools/utils/commontools";
import { CircularLoading } from "components/elements/loading/CircularLoading";

import ResultObjectDTO from "dto/app/resultobject.dto";
import { FormEditTeacher } from "./FormEditTeacher";
import useObject from "hooks/useObject";
import { useTeacherInfo } from "context/teacherinfo.context";

const service = new TeacherService();

const UserEditTeacher: React.FC<PageComponentProps> = () => {
  const { user } = useAuth();

  // const [loading, setLoading] = useState<boolean>(true);
  // const [obj, setObj] = useState<TeacherDto | undefined>();
  // const _id = user !== undefined ? user.id : "";
  // const [id, setId] = useState<string>(_id);
  // const [isFormDisabled, setIsFormDisabled] = useState(false);

  // const [validators, setValidators] = useState<any>();
  // const [vresults, setVResults] = useState<any>(false);

  // const validateField = (field: any, value: any, v?: any) => {
  //   v = v ? v : JSON.parse(JSON.stringify(vresults));
  //   if (v === false) v = {};
  //   v[field] = ValidateTools.validate(field, value, validators, vresults);

  //   setVResults(v);
  //   setIsFormDisabled(ValidateTools.vForm(v, field));
  //   return v;
  // };

  // const loadObject = (obj: any) => {
  //   if (!obj) return;
  //   if (!obj.err) {
  //     const object = obj.obj ? obj.obj : [];
  //     setObj(object);
  //   }
  // };

  // const getObject = () => {
  //   setLoading(true);
  //   if (id !== undefined && id !== "")
  //     teacherService.getByUserId(id, loadObject, {});
  // };

  // const checkIsLoading = () => {
  //   let load: boolean = false;
  //   if (obj === undefined) load = true;
  //   setLoading(load);
  // };

  // useEffect(() => {
  //   getObject();
  // }, [currentRoute]);

  // useEffect(() => {
  //   validateAll();
  //   checkIsLoading();
  // }, [obj]);

  // useEffect(() => {
  //   if (user !== undefined) {
  //     setId(user.id);
  //   }
  // }, [user]);

  // const validateAll = () => {
  //   if (!obj) return;
  //   // if (!validators) return;
  //   // if (vresults) return;

  //   obj.bio = obj.bio !== undefined ? obj.bio : "";
  //   obj.graduatedinstitution =
  //     obj.graduatedinstitution !== undefined ? obj.graduatedinstitution : "";
  //   obj.timetoteachperweek =
  //     obj.timetoteachperweek !== undefined ? obj.timetoteachperweek : "";
  //   obj.whyliveclass = obj.whyliveclass !== undefined ? obj.whyliveclass : "";
  //   obj.hasaudience = obj.hasaudience !== undefined ? obj.hasaudience : false;

  //   obj.status = obj.status !== undefined ? obj.status : 0;
  //   obj.idtypestudies =
  //     obj.idtypestudies !== undefined ? obj.idtypestudies : "";
  //   obj.idtypespecialty =
  //     obj.idtypespecialty !== undefined ? obj.idtypespecialty : "";
  //   obj.idtypetargetgroup =
  //     obj.idtypetargetgroup !== undefined ? obj.idtypetargetgroup : "";
  //   obj.idtypeinternetconnection =
  //     obj.idtypeinternetconnection !== undefined
  //       ? obj.idtypeinternetconnection
  //       : "";
  //   obj.idtypelesson = obj.idtypelesson !== undefined ? obj.idtypelesson : "";
  //   obj.idtypediscipline =
  //     obj.idtypediscipline !== undefined ? obj.idtypediscipline : "";
  //   obj.idtypeoccupation =
  //     obj.idtypeoccupation !== undefined ? obj.idtypeoccupation : "";
  //   obj.idtypeprofessionalismlevel =
  //     obj.idtypeprofessionalismlevel !== undefined
  //       ? obj.idtypeprofessionalismlevel
  //       : "";
  //   obj.idtypeexperience =
  //     obj.idtypeexperience !== undefined ? obj.idtypeexperience : "";

  //   setObj(obj);
  // };

  // const setObjField = (field: any, value: any) => {
  //   let t = JSON.parse(JSON.stringify(obj));
  //   t[field] = value;
  //   setObj(t);

  //   // validateField(field, value);
  // };
  // const setCheckFixed = (value: any) => {
  //   let t = JSON.parse(JSON.stringify(obj));

  //   t["hasaudience"] = value;
  //   setObj(t);
  // };
  // const handleSubmit = (event: any) => {
  //   event.preventDefault();
  //   if (isFormDisabled) return false;
  //   if (id !== undefined && obj) {
  //     obj.iduser = id;
  //     setLoading(true);
  //     teacherService.add(
  //       CommonTools.handleSuccessAddAndEditStayOnPage,
  //       { setLoading },
  //       obj
  //     );
  //   }
  // };

  // return user !== undefined && !loading && obj ? (
  //   <>
  //     <Box p={3}>
  //       <form onSubmit={handleSubmit}>
  //         <Grid container spacing={3}>
  //           <Grid item lg={6} xs={12}>
  //             <MyTextField
  //               tabIndex={0}
  //               fullWidth
  //               id="timetoteachperweek"
  //               name="timetoteachperweek"
  //               variant="standard"
  //               color="primary"
  //               className="form-input"
  //               _label={LL("timetoteachperweek")}
  //               size="small"
  //               value={obj.timetoteachperweek}
  //               setObj={setObjField}
  //               _vresults={
  //                 vresults && vresults.timetoteachperweek
  //                   ? vresults.timetoteachperweek
  //                   : false
  //               }
  //             />
  //           </Grid>
  //           <Grid item lg={6} xs={12}>
  //             <MyTextField
  //               tabIndex={4}
  //               fullWidth
  //               id="whyliveclass"
  //               name="whyliveclass"
  //               variant="standard"
  //               color="primary"
  //               className="form-input"
  //               _label={LL("whyliveclass")}
  //               size="small"
  //               value={obj.whyliveclass}
  //               setObj={setObjField}
  //               _vresults={
  //                 vresults && vresults.whyliveclass
  //                   ? vresults.whyliveclass
  //                   : false
  //               }
  //             />
  //           </Grid>
  //           <Grid item lg={6} xs={12}>
  //             <MyTextField
  //               tabIndex={5}
  //               fullWidth
  //               id="graduatedinstitution"
  //               name="graduatedinstitution"
  //               variant="standard"
  //               color="primary"
  //               className="form-input"
  //               _label={LL("graduatedinstitution")}
  //               size="small"
  //               value={obj.graduatedinstitution}
  //               setObj={setObjField}
  //               _vresults={
  //                 vresults && vresults.graduatedinstitution
  //                   ? vresults.graduatedinstitution
  //                   : false
  //               }
  //             />
  //           </Grid>

  //           <Grid item lg={12} xs={12}>
  //             <MyCheckboxField
  //               tabIndex={6}
  //               id="hasaudience"
  //               name="hasaudience"
  //               _label={LL("hasaudience")}
  //               setObj={setCheckFixed}
  //               checked={obj.hasaudience}
  //               color="primary"
  //               size="small"
  //               _vresults={undefined}
  //             />
  //           </Grid>
  //           <Grid item lg={6} xs={12}>
  //             <StudyTypeSelect
  //               value={obj.idtypestudies}
  //               field="idtypestudies"
  //               setObjectField={setObjField}
  //             />
  //           </Grid>
  //           <Grid item lg={6} xs={12}>
  //             <SpecialtyTypeSelect
  //               value={obj.idtypespecialty}
  //               field="idtypespecialty"
  //               setObjectField={setObjField}
  //             />
  //           </Grid>
  //           <Grid item lg={6} xs={12}>
  //             <TargetGroupTypeSelect
  //               value={obj.idtypetargetgroup}
  //               field="idtypetargetgroup"
  //               setObjectField={setObjField}
  //             />
  //           </Grid>
  //           <Grid item lg={6} xs={12}>
  //             <InternetConnectionTypeSelect
  //               value={obj.idtypeinternetconnection}
  //               field="idtypeinternetconnection"
  //               setObjectField={setObjField}
  //             />
  //           </Grid>
  //           <Grid item lg={6} xs={12}>
  //             <HardwareTypeSelect
  //               value={obj.idtypehardware}
  //               field="idtypehardware"
  //               setObjectField={setObjField}
  //             />
  //           </Grid>
  //           <Grid item lg={6} xs={12}>
  //             <LessonTypeSelect
  //               value={obj.idtypelesson}
  //               field="idtypelesson"
  //               setObjectField={setObjField}
  //             />
  //           </Grid>
  //           <Grid item lg={6} xs={12}>
  //             <OccupationTypeSelect
  //               value={obj.idtypeoccupation}
  //               field="idtypeoccupation"
  //               setObjectField={setObjField}
  //             />
  //           </Grid>
  //           <Grid item lg={6} xs={12}>
  //             <ProfessionalismLevelTypeSelect
  //               value={obj.idtypeprofessionalismlevel}
  //               field="idtypeprofessionalismlevel"
  //               setObjectField={setObjField}
  //             />
  //           </Grid>
  //           <Grid item lg={6} xs={12}>
  //             <ExperienceTypeSelect
  //               value={obj.idtypeexperience}
  //               field="idtypeexperience"
  //               setObjectField={setObjField}
  //             />
  //           </Grid>
  //           <Grid item lg={6} xs={12}>
  //             <DisciplineTypeSelect
  //               value={obj.idtypediscipline}
  //               field="idtypediscipline"
  //               setObjectField={setObjField}
  //             />
  //           </Grid>
  //           <Grid item lg={12} xs={12}>
  //             <MyCKEditor
  //               data={obj.bio ? obj.bio : ""}
  //               setObj={setObjField}
  //               name="bio"
  //             />
  //           </Grid>
  //           <Grid item lg={6} xs={12}>
  //             <MyButton
  //               tabIndex={4}
  //               disabled={isFormDisabled}
  //               color="primary"
  //               className="btn"
  //               variant="contained"
  //               size="large"
  //               type="submit"
  //               sx={{ mt: 4 }}
  //               children={LL("Bt_Submit")}
  //             />
  //           </Grid>
  //         </Grid>
  //       </form>
  //     </Box>
  //   </>
  // ) : (
  //   <CircularLoading />
  // );

  // const id = CommonTools.processObjectField(user, ["id"]);
  const [loadingAction, setLoadingAction] = useState<boolean>(false);
  // const get = (id: string, cb?: any, cbParams?: any) => {
  //   if (!id) return;
  //   service.getByUserId(id, cb, cbParams);
  // };
  // const [loading, obj, setObj] = useObject<TeacherDto>(get, id, [
  //   id,
  // ]);

  const { teacherInfo, setTeacherInfo, loading } = useTeacherInfo();
  const onSubmit = (obj: TeacherDto) => {
    setLoadingAction(true);
    service.update(
      CommonTools.processObjectField(obj, ["id"]),
      handleResult,
      {},
      obj
    );
  };

  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    setTeacherInfo(result.obj);
    setLoadingAction(false);
  };

  if (!user) return null;
  if (loading) return <CircularLoading />;
  if (!teacherInfo) return null;
  return (
    <Box maxWidth="sm" mx="auto" mt={3}>
      <FormEditTeacher
        defaultObject={teacherInfo}
        onSubmit={onSubmit}
        loading={loading || loadingAction}
      />
    </Box>
  );
};

export { UserEditTeacher };
