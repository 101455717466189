import React from "react";

import { Backdrop, CircularProgress } from "@mui/material";

type Props = {
  open: boolean;
};

const MyBackdrop: React.FC<Props> = ({ open }) => {
  return (
    <Backdrop
      sx={{ color: "#0072EF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export { MyBackdrop };
