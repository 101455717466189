import React, { useState } from "react";

import { PhoneService } from "services/user/phone.service";

import PhoneDto, { PhoneFormDto } from "dto/user/phone.dto";

import { useResource } from "hooks/useResource";


import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { useAuth } from "hooks/useAuth";

import { PhoneItem } from "./PhoneItem";
import { CommonTools } from "tools/utils/commontools";
import { CallbackType } from "interfaces/commontypes.interface";
import RequestListDTO from "dto/app/requestlist.dto";
import { useList } from "hooks/useList";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Skeleton } from "@mui/material";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { FormPhone } from "./FormPhone";

const service = new PhoneService();

type Props = {
  currentRoute: any;
};

const AddUserPhone: React.FC<Props> = ({ currentRoute, ...props }) => {
  const { LL } = useResource();
  const { user } = useAuth();
  const [loadingAdd, setLoadingAdd] = useState<boolean>(false);
  const [defaultObj, setDefaultObj] = useState<PhoneFormDto>(
    new PhoneFormDto()
  );

  const idUser = CommonTools.processObjectField(user, ["id"]);

  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    if (!idUser) return;
    service.getList(cb, cbParams, req);
  };

  const [loading, objects, , , setObjects] = useList<PhoneDto>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("iduser", [idUser])],
      1,
      -1
    ),
    [idUser]
  );

  const onSubmit = (obj: PhoneFormDto) => {
    const id = CommonTools.processObjectField(obj, ["id"]);
    obj.iduser = idUser;
    setLoadingAdd(true);
    if (!id) {
      service.add(handleResult, {}, obj);
    } else {
      service.update(id, handleResult, {}, obj);
    }
  };

  const handleResult = (result: ResultObjectDTO) => {
    if (!objects) return;
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    
    const id = CommonTools.processObjectField(result, ["obj", "id"]);
    const index = objects.findIndex((item: PhoneDto) => item.id === id);
    if (index > -1) {
      objects[index] = result.obj;
      setObjects([...objects]);
    } else {
      setObjects([...objects, result.obj]);
    }
    setDefaultObj(new PhoneFormDto());
    setLoadingAdd(false);
  };

  const handleUpdate = (obj: PhoneDto) => {
    setDefaultObj(PhoneFormDto.parseFromPhoneDto(obj));
  };

  const onDelete = (obj: PhoneDto) => {
    if (!obj) return;
    const id = CommonTools.processObjectField(obj, ["id"]);
    if (!id) return;
    setLoadingAdd(true);
    service.delete(id, handleDelete, { id });
  };

  const handleDelete = (result: ResultObjectDTO, cbParams?: any) => {
    if (!result) return;
    if (result.err) return;
    if (!cbParams) return;
    const id = CommonTools.processObjectField(cbParams, ["id"]);
    if (!id) return;
    if (!objects) return;
    setObjects(objects.filter((item: PhoneDto) => item.id !== id));
    setLoadingAdd(false);
  };
  const processItem = (obj: PhoneDto, index: number) => {
    if (!obj) return null;
    return (
      <PhoneItem
        obj={obj}
        key={index}
        handleDelete={onDelete}
        handleUpdate={handleUpdate}
      />
    );
  };
  const processList = () => {
    if (loading) return <Skeleton />;
    if (!objects) return null;
    if (!objects.length) return null;
    return (
      <React.Fragment>
        {objects.map((item: PhoneDto, index: number) =>
          processItem(item, index)
        )}
      </React.Fragment>
    );
  };

  if (!user) return null;
  return (
    <Box mt={3}>
      <Box>
        <FormPhone
        defaultObj={defaultObj}
        onSubmit={onSubmit}
        loading={loadingAdd || loading}
        />
      </Box>
      <Box p={2}>
        <Typography variant="h4" mt={4}>
          {LL("Phone numbers list user")}
        </Typography>
        {processList()}
      </Box>
    </Box>
  );

  // const [obj, setObj] = useState<Array<PhoneDto> | undefined>();
  // const [objPhone, setObjPhone] = useState<PhoneDto | undefined>(
  //   new PhoneDto()
  // );
  // const { user } = useAuth();
  // const _id = user !== undefined ? user.id : "";
  // const [id, setId] = useState<string>(_id);

  // const loadObject = (obj: any) => {
  //   if (!obj) return;
  //   if (!obj.err) {
  //     const object = obj.obj ? obj.obj : [];
  //     setObj(object);
  //   }
  // };

  // const getObject = () => {
  //   setLoading(true);
  //   if (id !== undefined) phoneService.getPhonesByUserId(id, loadObject, {});
  // };

  // const checkIsLoading = () => {
  //   let load: boolean = false;
  //   if (obj === undefined) load = true;
  //   setLoading(load);
  // };

  // useEffect(() => {
  //   getObject();
  // }, [currentRoute]);

  // useEffect(() => {
  //   checkIsLoading();
  // }, [obj]);

  // useEffect(() => {
  //   if (user !== undefined) {
  //     setId(user.id);
  //   }
  // }, [user]);

  // const [validators, setValidators] = useState<any>();
  // const [vresults, setVResults] = useState<any>(false);
  // const [isFormDisabled, setIsFormDisabled] = useState(false);

  // const validateAll = () => {
  //   if (!objPhone) return;
  //   if (!validators) return;
  //   // if (vresults) return;

  //   objPhone.countrycode =
  //     objPhone.countrycode !== undefined ? objPhone.countrycode : "";
  //   objPhone.phonenumber =
  //     objPhone.phonenumber !== undefined ? objPhone.phonenumber : "";

  //   objPhone.idtypephone =
  //     objPhone.idtypephone !== undefined ? objPhone.idtypephone : "";

  //   setObjPhone(objPhone);

  //   let t = validateField("countrycode", objPhone.countrycode);
  //   t = validateField("phonenumber", objPhone.phonenumber, t);
  // };

  // const validateField = (field: any, value: any, v?: any) => {
  //   v = v ? v : JSON.parse(JSON.stringify(vresults));
  //   if (v === false) v = {};
  //   v[field] = ValidateTools.validate(field, value, validators, vresults);

  //   setIsFormDisabled(ValidateTools.vForm(v, field));
  //   setVResults(v);
  //   return v;
  // };

  // const checkIsFormDisabled = () => {
  //   var rez = ValidateTools.vForm(vresults, "countrycode");
  //   rez = ValidateTools.vForm(vresults, "phonenumber", rez);

  //   setIsFormDisabled(rez);
  // };

  // useEffect(() => {
  //   checkIsFormDisabled();
  // }, [vresults]);

  // useEffect(() => {
  //   processValidators();
  // }, []);
  // useEffect(() => {
  //   validateAll();
  // }, [objPhone, validators]);

  // const processValidators = () => {
  //   let v: any = {
  //     countrycode: [],
  //     phonenumber: [],
  //   };

  //   v.countrycode.push(new RequiredValidator("", ""));
  //   v.phonenumber.push(new RequiredValidator("", ""));

  //   setValidators(v);
  // };

  // const setObjField = (field: any, value: any) => {
  //   let t = JSON.parse(JSON.stringify(objPhone));
  //   t[field] = value;
  //   setObjPhone(t);
  // };

  // const handleSubmit = (event: any) => {
  //   event.preventDefault();

  //   if (
  //     objPhone !== undefined &&
  //     id !== undefined &&
  //     objPhone.phonenumber !== undefined
  //   ) {
  //     objPhone.iduser = id;
  //     objPhone.status = Status.ACTIVE;

  //     if (!objPhone.hasOwnProperty("id"))
  //       phoneService.add(getObject, {}, objPhone);
  //     else {
  //       if (objPhone.id)
  //         phoneService.update(objPhone.id, getObject, {}, objPhone);
  //     }
  //   }

  //   setObjPhone(new PhoneDto());
  // };

  // const handleDelete = (obj: PhoneDto) => {
  //   if (obj.id) phoneService.delete(obj.id, getObject, undefined);
  // };

  // const processPhoneItem = (obj: PhoneDto, index: number) => {
  //   if (!obj) return null;
  //   return (
  //     <PhoneItem
  //       obj={obj}
  //       key={index}
  //       handleDelete={handleDelete}
  //       handleUpdate={setObjPhone}
  //     />
  //   );
  //   // return (
  //   //   <Box sx={{ flexGrow: 1 }} key={index}>
  //   //     <Grid container py={2} spacing={2}>
  //   //       <Grid item xs={6}>
  //   //         <Typography>
  //   //           {ComponentCommonTools.processObjectField(obj, "countrycode")}{" "}
  //   //           {ComponentCommonTools.processObjectField(obj, "phonenumber")}{" "}
  //   //         </Typography>
  //   //       </Grid>
  //   //       <Grid item xs={4}>
  //   //         <Typography>
  //   //           {ComponentCommonTools.processObjectFieldOfObject(
  //   //             obj,
  //   //             "typephone",
  //   //             "name"
  //   //           )}
  //   //         </Typography>
  //   //       </Grid>
  //   //       <Grid item xs={2} ml={0}>
  //   //         <MyButton
  //   //           type="submit"
  //   //           cb={() => {
  //   //             setObjPhone(obj);
  //   //           }}
  //   //           children={<EditIcon />}
  //   //           color="primary"
  //   //         />

  //   //         <MyButton
  //   //           color="secondary"
  //   //           type="submit"
  //   //           cb={() => {
  //   //             handleDelete(obj);
  //   //           }}
  //   //           children={<DeleteIcon />}
  //   //         />
  //   //       </Grid>
  //   //     </Grid>
  //   //     <Divider />
  //   //   </Box>
  //   // );
  // };

  // const processPhoneList = () => {
  //   if (!obj) return <></>;
  //   if (obj.length === 0) return <></>;
  //   return (
  //     <>
  //       {obj.map((item: PhoneDto, index: any) => processPhoneItem(item, index))}
  //     </>
  //   );
  // };
  // return !loading && obj && objPhone ? (
  //   <Box mt={3}>
  //     <form onSubmit={handleSubmit}>
  //       <Box sx={{ flexGrow: 1 }} maxWidth={"sm"} mx="auto">
  //         <Grid container spacing={2}>
  //           <Grid item xs={4}>
  //             <Typography variant="h6">{LL("countrycode")}</Typography>
  //             <CountryCodeSelect
  //               value={objPhone.countrycode}
  //               field="countrycode"
  //               size="small"
  //               variant="standard"
  //               color="primary"
  //               className="form-select"
  //               setObj={setObjField}
  //               obj={objPhone}
  //             />
  //           </Grid>
  //           <Grid item xs={8}>
  //             <Typography variant="h6">{LL("phonenumber")}</Typography>
  //             <MyTextField
  //               tabIndex={1}
  //               fullWidth
  //               id="phonenumber"
  //               name="phonenumber"
  //               variant="standard"
  //               className="form-input"
  //               _label={LL("phonenumber")}
  //               value={objPhone.phonenumber}
  //               setObj={setObjField}
  //               _vresults={
  //                 vresults && vresults.phonenumber
  //                   ? vresults.phonenumber
  //                   : false
  //               }
  //             />
  //           </Grid>
  //           <Grid item xs={12}>
  //             <Typography variant="h6">{LL("idtypephone")}</Typography>
  //             <PhoneTypeSelect
  //               value={objPhone.idtypephone}
  //               field="idtypephone"
  //               setObjectField={setObjField}
  //               label=""
  //             />
  //           </Grid>
  //         </Grid>
  //         <Box textAlign="center">
  //           <MyButton
  //             tabIndex={4}
  //             disabled={isFormDisabled}
  //             color="primary"
  //             variant="contained"
  //             size="large"
  //             type="submit"
  //             sx={{ mt: 4 }}
  //             children={LL("Bt_Submit")}
  //             className="btn"
  //           />
  //         </Box>
  //       </Box>
  //     </form>
  //     <Box p={2}>
  //       <Typography variant="h4" mt={4}>
  //         {LL("Phone numbers list user")}
  //       </Typography>
  //       {processPhoneList()}
  //     </Box>
  //   </Box>
  // ) : (
  //   <CircularLoading />
  // );
};

export default AddUserPhone;
