import React, { useState } from "react";

import { useResource } from "hooks/useResource";

import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";

import { IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import ResultObjectDTO from "dto/app/resultobject.dto";

import { LessonDto, LessonFormDto } from "dto/course/lesson.dto";
import { LessonService } from "services/course/lesson.service";

import { FormLesson } from "./FormLesson";

const service = new LessonService();

type Props = {
  open: boolean;
  setOpen: any;
  id: string;
  addToList: (obj: LessonDto) => void;
};
const AddLessonDialog: React.FC<Props> = ({ open, setOpen, id, addToList }) => {
  const { LL } = useResource();

  const [loading, setLoading] = useState<boolean>(false);
  const [defaultObj, setDefaultObj] = useState<LessonFormDto>(
    new LessonFormDto()
  );

  const onSubmit = (obj: LessonFormDto) => {
    if (!obj) return;

    if (!id) return;
    obj.idcourse = id;
    setLoading(true);
    service.addByTeacher(handleResult, {}, obj);
  };

  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    addToList(result.obj);
    setDefaultObj(new LessonFormDto());
    setOpen(false);
    setLoading(false);
  };
  // const [isFormDisabled, setIsFormDisabled] = useState(true);

  // const [validators, setValidators] = useState(false);
  // const [vresults, setVResults] = useState<any>(false);
  // const validateAll = () => {
  //   if (!obj) return;
  //   if (!validators) return;
  //   // if (vresults) return;

  //   obj.name = obj.name !== undefined ? obj.name : "";
  //   obj.description = obj.description !== undefined ? obj.description : "";
  //   obj.idtypelesson = obj.idtypelesson !== undefined ? obj.idtypelesson : "";
  //   obj.lessonduration =
  //     obj.lessonduration !== undefined ? obj.lessonduration : 0;
  //   obj.islive = obj.islive !== undefined ? obj.islive : false;
  //   obj.startdate = obj.startdate !== undefined ? obj.startdate : undefined;
  //   obj.enddate = obj.enddate !== undefined ? obj.enddate : undefined;
  //   setObj(obj);

  //   validateField("name", obj.name);
  // };

  // const validateField = (field: any, value: any, v?: any) => {
  //   v = v ? v : JSON.parse(JSON.stringify(vresults));
  //   if (v === false) v = {};
  //   v[field] = ValidateTools.validate(field, value, validators, vresults);
  //   setVResults(v);
  //   // setIsFormDisabled(ValidateTools.vForm(v, field));
  //   return v;
  // };

  // const processValidators = () => {
  //   let v: any = {
  //     name: [],
  //   };

  //   v.name.push(new RequiredValidator("", ""));

  //   setValidators(v);
  // };

  // const checkLoading = () => {
  //   if (!loading) return;
  //   if (!obj) return;
  //   if (!validators) return;
  //   validateAll();
  //   setLoading(false);
  // };

  // useEffect(() => {
  //   processValidators();
  // }, []);
  // useEffect(() => {
  //   checkLoading();
  // }, [obj, validators]);

  // useEffect(() => {
  //   checkIsFormDisabled();
  // }, [vresults, obj.startdate, obj.enddate]);

  // const setObjDateField = (field: any, value: any) => {
  //   let t = JSON.parse(JSON.stringify(obj));
  //   t[field] = DateTools.getDateTimeStamp(value);
  //   setObj(t);
  // };

  // const setObjField = (field: any, value: any) => {
  //   let t = JSON.parse(JSON.stringify(obj));
  //   t[field] = value;
  //   setObj(t);

  //   validateField(field, value);
  // };

  // const checkIsFormDisabled = () => {
  //   var rez = ValidateTools.vForm(vresults, "name");
  //   if (!obj || !obj.hasOwnProperty("idtypelesson")) rez = true;
  //   if (
  //     obj &&
  //     obj.hasOwnProperty("idtypelesson") &&
  //     obj.idtypelesson === process.env.REACT_APP_ID_LESSON_TYPE_ONLINE
  //   ) {
  //     if (obj && obj.startdate !== undefined && obj.enddate !== undefined) {
  //       if (obj.startdate > obj.enddate) {
  //         rez = true;
  //       }
  //     } else {
  //       rez = true;
  //     }
  //   }
  //   setIsFormDisabled(rez);
  // };

  // const handleSubmit = (event: any) => {
  //   event.preventDefault();
  //   if (isFormDisabled) return false;

  //   if (!obj) return;

  //   if (!id) return;
  //   obj.idcourse = id;
  //   setLoading(true);
  //   service.addByTeacher(handleResult, {}, obj);
  // };

  // const handleResult = (result: ResultObjectDTO, cbparams: any) => {
  //   if (!result) return;
  //   if (!result.err) {
  //     // setObjectWasChanged(CommonTools.generateRandomString(5));
  //     setOpen(false);
  //     setLoading(false);
  //     setObj(new PostLessonDto());
  //   }
  // };

  const handleClose = () => {
    setOpen(false);
  };

  // const processFieldsLessonOnline = () => {
  //   if (!obj) return <></>;
  //   if (!obj.hasOwnProperty("idtypelesson")) return <></>;
  //   if (!obj.idtypelesson) return <></>;
  //   if (obj.idtypelesson !== process.env.REACT_APP_ID_LESSON_TYPE_ONLINE)
  //     return <></>;
  //   return (
  //     <Box>
  //       <Box sx={{ mt: 3 }}>
  //         <MyCheckboxField
  //           tabIndex={1}
  //           id="islive"
  //           name="islive"
  //           _label={LL("islive")}
  //           setObj={(value: any) =>
  //             CommonTools.handleCheckValue(obj, setObj, "islive", value)
  //           }
  //           checked={obj.islive}
  //           color="primary"
  //           size="small"
  //           _vresults={undefined}
  //         />
  //       </Box>
  //       <Box sx={{ mt: 3 }}>
  //         <Typography variant="h6" mb={1}>
  //           {" "}
  //           {LL("Seteaza data si ora lectiei")}
  //         </Typography>
  //         <Stack direction="row" spacing={2}>
  //           <MyDateTimePicker
  //             disablePast
  //             className="form-picker"
  //             field="startdate"
  //             _defaultValue={DateTools.convertTimestampToDate(obj.startdate)}
  //             LL={LL}
  //             setObjField={setObjDateField}
  //           />

  //           <MyDateTimePicker
  //             disablePast
  //             className="form-picker"
  //             field="enddate"
  //             _defaultValue={DateTools.convertTimestampToDate(obj.enddate)}
  //             LL={LL}
  //             setObjField={setObjDateField}
  //           />
  //         </Stack>
  //       </Box>
  //     </Box>
  //   );
  // };

  // const processForm = () => {
  //   if (!obj) return <></>;
  //   if (loading) return <CircularLoading />;

  //   return (
  //     <form onSubmit={handleSubmit}>
  //       <Box>
  //         <Typography variant="h6" mb={1}>
  //           {" "}
  //           {LL("Tip Numele cursului")}
  //         </Typography>
  //         <MyTextField
  //           tabIndex={0}
  //           fullWidth
  //           id="name"
  //           name="name"
  //           variant="standard"
  //           color="primary"
  //           className="form-input"
  //           _label={LL("Name")}
  //           size="small"
  //           value={obj.name}
  //           setObj={setObjField}
  //           _vresults={vresults && vresults.name ? vresults.name : false}
  //         />
  //       </Box>

  //       <Box sx={{ mt: 3 }}>
  //         <Typography variant="h6" mb={1}>
  //           {LL("Tip lectie")}
  //         </Typography>
  //         <LessonTypeSelect
  //           value={obj.idtypelesson}
  //           field="idtypelesson"
  //           setObjectField={setObjField}
  //         />
  //       </Box>
  //       {processFieldsLessonOnline()}
  //       <Box sx={{ mt: 3 }}>
  //         <Typography variant="h6" mb={1}>
  //           {" "}
  //           {LL("Descriere lectie")}
  //         </Typography>
  //         <MyCKEditor
  //           data={obj.description ? obj.description : ""}
  //           setObj={setObjField}
  //           name="description"
  //         />
  //       </Box>
  //       <Box textAlign="center" my={4}>
  //         <MyButton
  //           tabIndex={1}
  //           disabled={isFormDisabled}
  //           color="primary"
  //           variant="contained"
  //           size="large"
  //           type="submit"
  //           className="btn"
  //           children={LL("Bt_Submit")}
  //         />
  //       </Box>
  //     </form>
  //   );
  // };
  return (
    <Dialog open={open} onClose={handleClose} fullWidth className="dialogAdd">
      <DialogTitle>{LL("Add_lesson")}</DialogTitle>
      <IconButton
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <FormLesson
          onSubmit={onSubmit}
          defaultObj={defaultObj}
          loading={loading}
        />
      </DialogContent>
    </Dialog>
  );
};

export { AddLessonDialog };
