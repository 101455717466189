import React, { useState, useEffect, useContext, useCallback } from "react";

import { List, Skeleton, Box } from "@mui/material";

import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { StudentCourseContext } from "context/studentcourse.context";
import { LessonDto } from "dto/course/lesson.dto";
import { ToolBoxLessonItem } from "./ToolBoxLessonItem";

export const StudentCourseToolBox: React.FC<PageComponentProps> = ({
  currentRoute,
}) => {
  const {
    state: { lessonObjects },
  } = useContext(StudentCourseContext);

  const [loading, setLoading] = useState<boolean>(true);

  const checkLoading = useCallback(() => {
    let loading = false;
    if (lessonObjects === null) loading = true;
    setLoading(loading);
  }, [lessonObjects]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  const processItem = (item: LessonDto, i: number) => {
    if (!item) return null;
    return (
      <Box key={i}>
        <ToolBoxLessonItem obj={item} currentRoute={currentRoute} />
      </Box>
    );
  };

  const processComponent = () => {
    if (loading) return <Skeleton variant="rectangular" height={"100%"} />;
    if (!lessonObjects) return null;
    if (lessonObjects.length === 0) return null;
    return (
      <List>
        {lessonObjects.map((item: LessonDto, i: number) => {
          return processItem(item, i);
        })}
      </List>
    );
  };

  return processComponent();
};
