import IRepository from "interfaces/irepository.interface";
import { GlobalGeneralRepository } from "repositories/globalgeneral.repository";

export class NotificationRepository
  extends GlobalGeneralRepository
  implements IRepository
{
  constructor() {
    super("notification");
  }

  async markAsRead(id: string, cb?: any, cbparameters?: any): Promise<any> {
    const url = `${this.mainUrl}/mark-as-read/${id}`;
    return await this.putAxios(url, cb, {}, cbparameters);
  }

  async markAllAsRead(
    idRecipient: string,
    typeRecipient: number,
    cb?: any,
    cbparameters?: any
  ): Promise<any> {
    const url = `${this.mainUrl}/mark-all-as-read/${idRecipient}?type=${typeRecipient}`;

    return await this.putAxios(url, cb, {}, cbparameters);
  }
}

// import RequestListDTO from "dto/app/requestlist.dto";
// import Idto from "interfaces/idto.interface";
// import IRepository from "interfaces/irepository.interface";
// import GeneralRepository from "repositories/general.repository";

// const { REACT_APP_API_SERVER, REACT_APP_URLPREFIX } = process.env;

// export default class NotificationRepository extends GeneralRepository implements IRepository {
//     async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + "/notification/" + id;
//         return await this.deleteAxios(url, cb, cbparameters);
//     }

//     async update(id: string, cb?: any, data?: Idto | undefined, cbparameters?: any): Promise<any> {
//         const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + "/notification/" + id;
//         return await this.putAxios(url, cb, data, cbparameters);
//     }

//     async markAsRead(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + "/notification/mark-as-read/" + id;
//         return await this.putAxios(url, cb, {}, cbparameters);
//     }

//     async markAllAsRead(
//         idRecipient: string,
//         typeRecipient: number,
//         cb?: any,
//         cbparameters?: any
//     ): Promise<any> {
//         const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') +
//             "/notification/mark-all-as-read/" + idRecipient + "?type=" + typeRecipient;
//         return await this.putAxios(url, cb, {}, cbparameters);
//     }

//     patch(id: string, cb?: any, data?: Idto | undefined, cbparameters?: any): Promise<any> {
//         throw new Error("Method not implemented.");
//     }

//     async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + `/notification/${id}`;
//         return await this._get(url, cb, cbparameters);
//     }

//     async getList(
//         cb?: any,
//         cbparameters?: any,
//         data?: RequestListDTO
//     ): Promise<any> {
//         const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + `/notification`;
//         return await this._getList(url, cb, cbparameters, data);
//     }

//     async add(cb?: any, cbparameters?: any, data?: Idto): Promise<any> {
//         const url = (REACT_APP_API_SERVER ?? '') + (REACT_APP_URLPREFIX ?? '') + `/notification`;

//         return await this.postAxios(url, cb, data, cbparameters);
//     }

// }
