import { PopulateTypeDto } from "dto/nomenclature/populatetype.dto";
import Idto from "interfaces/idto.interface";

export default class SocialDto implements Idto {
  id?: string;
  link?: string;
  idtypesocial?: string;
  typesocial?: any;
  iduser?: string;
}

export class SocialNewDto {
  id?: string;
  link?: string;
  idtypesocial?: string;
  typesocial?: PopulateTypeDto;
  iduser?: string;

  constructor(
    iduser?: string,
    idtypesocial?: string,
    link?: string,
    id?: string,
    typesocial?: PopulateTypeDto
  ) {
    this.id = id || "";
    this.link = link || "";
    this.idtypesocial = idtypesocial || "";
    this.iduser = iduser || "";
    this.typesocial = typesocial || {};
  }

  static parseObjectsForUpdates = (
    idUser: string,
    typeObjects: PopulateTypeDto[],
    objects: SocialNewDto[]
  ): SocialNewDto[] => {
    const result: SocialNewDto[] = [];

    typeObjects.forEach((item) => {
      const newObj = new SocialNewDto(idUser, item.id, "", "", item);
      const found = objects.find((obj) => obj.idtypesocial === item.id);
      if (found) {
        newObj.id = found.id;
        newObj.link = found.link;
      }
      result.push(newObj);
    })
    return result;
  };
}
