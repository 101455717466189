import React, { useEffect, useState } from "react";

import { useResource } from "hooks/useResource";
import { logger } from "tools/utils/logger";

import { IconButton } from '@mui/material';

import FlagIcon from '@mui/icons-material/Flag';

import { Status } from "tools//types/status";
import { TeacherDto } from "dto/user/teacher.dto";

type ReportButtonProps = {
    [x: string]: any
    status: number
    action: any
}


const ReportButton: React.FC<ReportButtonProps> = ({
    status,
    action,
    ...props
}) => {

    const processColor = (value: number) => {
        switch (value) {
            case Status.ACTIVE:
                return "inherit";
            case Status.REPORTED:
                return "error";
            case Status.INACTIVE:
                return "disabled";
            default:
                return "primary";
        }

    }

    const processDisabled = (value: number) => {
        switch (value) {
            case Status.ACTIVE:
                return false;
            case Status.REPORTED:
                return true;
            case Status.INACTIVE:
                return true;
            default:
                return true;
        }
    }

    const handleOnClick = (e:any) => {
        e.preventDefault();
        e.stopPropagation();
        action();
    }
    const processButton = () => {
        

        const color = processColor(status);
        const disabled = processDisabled(status);
        
        return (
            <IconButton
                onClick={handleOnClick}
                disabled={disabled}
                {...props}
            >
                <FlagIcon color={color}/>
            </IconButton>
            
        );
    }


    return (<>{processButton()}</>);
};

export { ReportButton };
