import { Box, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { StaticSelect } from "components/elements/Select/newSelect/StaticSelect";
import MyTextField from "components/form/mytextfield";
import { TestFormDto } from "dto/course/test.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useEffect } from "react";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import RequiredValidator from "validators/required.validator";
import { SelectLesson } from "components/course/lesson/SelectLesson";

type Props = {
  defaultObj: TestFormDto;
  onSubmit: (obj: TestFormDto) => void;
  loading: boolean;
};

const FormTest: React.FC<Props> = ({ defaultObj, onSubmit, loading }) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField, setObject] = useForm<TestFormDto>(
    defaultObj,
    RequiredValidator.getValidators(["name"])
  );

  useEffect(() => {
    if (!defaultObj) return;
    setObject(defaultObj);
  }, [defaultObj]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled || loading) return;
    onSubmit(obj);
  };


  const setType = (field: string, value: any) => {
    
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    t['idlesson'] = null;
    setObject(t);
  };

  const processSelect = () => {
    if (obj.type !== Types.TYPE_TEST_LESSON) return null;
    return (
      <Box>
        <Typography variant="h6" mb={1}>
          {LL("Lesson")}
        </Typography>
        <SelectLesson
          value={obj.idlesson ?? ""}
          setObjectField={setObjField}
          idCourse={obj.idcourse ?? ""}
          label=""
        />
      </Box>
    );
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <Typography variant="h6" mb={1}>
            {LL("Numele")}
          </Typography>
          <MyTextField
            tabIndex={0}
            fullWidth
            id="name"
            name="name"
            variant="standard"
            color="primary"
            className="form-input"
            _label={LL("Name")}
            size="small"
            value={obj.name}
            setObj={setObjField}
            _vresults={undefined}
          />
        </Box>
        <Box>
          <Typography variant="h6" mb={1}>
            {LL("Tip test")}
          </Typography>
          <StaticSelect
            setObjField={setType}
            value={obj.type}
            label=""
            type="testtype"
            service={Types}
          />
        </Box>
        <Box>
          <Typography variant="h6" mb={1}>
            {LL("Min test score")}
          </Typography>
          <MyTextField
            fullWidth
            id="mintestscore"
            name="mintestscore"
            variant="standard"
            color="primary"
            className="form-input"
            _label={LL("mintestscore")}
            size="small"
            value={obj.mintestscore}
            setObj={setObjField}
            _vresults={undefined}
          />
        </Box>
        <Box>
          <Typography variant="h6" mb={1}>
            {LL("Status")}
          </Typography>
          <StaticSelect
            setObjField={setObjField}
            service={Status}
            field="status"
            value={obj.status}
            type="clasificator"
            label=""
          />
        </Box>
        {processSelect()}
        <Box my={4} textAlign="center">
          <MyButton
            disabled={disabled || loading}
            color="primary"
            className="btn"
            variant="contained"
            size="large"
            type="submit"
            children={LL("Bt_Submit Modification")}
          />
        </Box>
      </form>
    </Box>
  );
};

export { FormTest };
