import { Box, Typography } from "@mui/material";
import { Loading } from "components/elements/loading/Loading";
import { useResource } from "hooks/useResource";
import IProvider from "interfaces/provider.interface";
import React, { useCallback, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Config } from "tools/utils/config";

export const DownloadProvider: React.FC<IProvider> = ({ children }) => {
  const [loading, setLoading] = useState(true);

  const {
    pageProgressLabel,
    pageProgressLabelTotal,
    pageProgressConfig,
    pageProgressConfigTotal,
    pageProgressConfigFile,
    pageProgressConfigFileTotal,
  } = useResource();

  const calculateProgress = () => {
    const total =
      pageProgressLabelTotal +
      pageProgressConfigTotal +
      pageProgressConfigFileTotal;

    const current =
      pageProgressLabel + pageProgressConfig + pageProgressConfigFile;
    const progress = current / total;
    if (isNaN(progress)) {
      return 0;
    }
    if (progress === Infinity) {
      return 0;
    }
    if (progress > 1) {
      return 1;
    }
    if (progress < 0) {
      return 0;
    }
    return progress;
  };

  const checkLoading = useCallback(() => {
    let loading = false;
    if (pageProgressLabelTotal !== pageProgressLabel) loading = true;
    if (pageProgressConfigTotal !== pageProgressConfig) loading = true;
    if (pageProgressConfigFileTotal !== pageProgressConfigFile) loading = true;

    setLoading(loading);
  }, [
    pageProgressLabelTotal,
    pageProgressLabel,
    pageProgressConfigTotal,
    pageProgressConfig,
    pageProgressConfigFileTotal,
    pageProgressConfigFile,
  ]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  if (loading && !Config.IS_DEV_MODE)
    return (
      <Box className="loadingPage">
        {/* <Loading /> */}
        <Box className="loadingPageTextBox">
          <Typography className="loadingPageText">
            Loading data from server
          </Typography>
          <CircularProgress
            variant="determinate"
            value={Math.round(calculateProgress() * 100)}
          />
          <Typography className="loadingPageTextPercent">
            {Math.round(calculateProgress() * 100)}%
          </Typography>
        </Box>
      </Box>
    );
  return <React.Fragment>{children}</React.Fragment>;
};
