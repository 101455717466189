import React, { useState, useContext, useEffect } from "react";
import { LessonDto } from "dto/course/lesson.dto";
import {
  Accordion,
  Typography,
  Stack,
  AccordionSummary,
  Box,
  AccordionDetails,
  Collapse,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { MyButton } from "components/elements/button/MyButton";
import { useResource } from "hooks/useResource";

import { AttachmentListLessonStatic } from "components/system/attachment/AttachementListLessonStatic";

import { GalleryListLessonStatic } from "components/system/gallery/GalleryListLessonStatic";
import { StudentLessonDialogDescription } from "components/user/student/StudentLessonDialogDescription";
import { StudentCourseContext } from "context/studentcourse.context";
import { StudentButtonFinishLesson } from "./StudentButtonFinishLesson";
import { VerifyLesson } from "./VerifyLesson";
import { VideoListLessonStatic } from "components/system/video/VideoListLessonStatic";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";

type Props = {
  obj: LessonDto;
  currentRoute: any;
};

export const ToolBoxLessonItem: React.FC<Props> = ({
  obj,
  currentRoute,
}) => {
  const {
    state: {
      courseEnrollmentObject,
      idCurrentLesson,
      lessonEnrollmentObject,
      selectedLessonObject,
    },
    actions: { processDisabledInteraction, selectLesson },
  } = useContext(StudentCourseContext);

  const { LL } = useResource();
  const processDefaultExpanded = () => {
    if (!idCurrentLesson) return false;
    if (!obj) return false;
    if (!obj.hasOwnProperty("id")) return false;
    if (!obj.id) return false;
    if (idCurrentLesson !== obj.id) return false;
    return true;
  };

  const [expanded, setExpanded] = useState<boolean>(processDefaultExpanded());
  const [openDescription, setOpenDescription] = useState<boolean>(false);
  const [openResource, setOpenResource] = useState<boolean>(false);

  const processSelectedLesson = () => {
    if (!selectedLessonObject) return null
    if (!selectedLessonObject.hasOwnProperty("id")) return null
    if (!selectedLessonObject.id) return null
    if (!obj) return null
    if (!obj.hasOwnProperty("id")) return null
    if (!obj.id) return null
    if (selectedLessonObject.id !== obj.id) return null
    return (
      <Box>
        <PlayArrowIcon color="primary" />
      </Box>
    );
  };
  //Test
  useEffect(() => {
    if (!lessonEnrollmentObject) return;
    setExpanded(processDefaultExpanded());
  }, [lessonEnrollmentObject]);

  const handleOnClick = () => {
    setExpanded(!expanded);
  };

  const handleOnClickLesson = () => {
    if (!courseEnrollmentObject) return;
    if (!obj) return;
    if (!obj.hasOwnProperty("id")) return;
    if (!obj.id) return;

    selectLesson(obj.id);
  };

  const processButtonPlay = () => {
    return (
      <>
        <MyButton className="playButton" fullWidth cb={handleOnClickLesson}>
          <PlayCircleOutlineOutlinedIcon />
          {LL("Play this lesson")}
        </MyButton>
      </>
    );
  };

  const processButtonFinish = () => {
    // logger("processButtonFinish", lessonEnrollmentObject, obj);
    if (!lessonEnrollmentObject) return null
    if (!lessonEnrollmentObject.hasOwnProperty("idlesson")) return null
    if (!lessonEnrollmentObject.idlesson) return null
    if (!obj) return null
    if (!obj.hasOwnProperty("id")) return null
    if (!obj.id) return null
    if (lessonEnrollmentObject.idlesson !== obj.id) return null

    return (
      <Box>
        <StudentButtonFinishLesson />
      </Box>
    );
  };

  const handleOpenDescription = () => {
    setOpenDescription(true);
  };
  const processDescriptionButton = () => {
    return (
      <Box>
        <MyButton
          cb={handleOpenDescription}
          fullWidth
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <i className="description-icon"></i>
          {LL("Description")}
        </MyButton>
      </Box>
    );
  };

  const handleOpenResource = () => {
    setOpenResource(!openResource);
  };

  const processResourceButton = () => {
    return (
      <Box>
        <MyButton
          cb={handleOpenResource}
          fullWidth
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <i className="resource-icon"></i>
          {LL("Resource")}
        </MyButton>
      </Box>
    );
  };
  const processAttachmentList = () => {
    if (!obj) return null
    if (!obj.hasOwnProperty("id")) return null
    if (!obj.id) return null
    if (!obj.hasOwnProperty("attachment")) return null
    if (!obj.attachment) return null

    return (
      <AttachmentListLessonStatic
        objects={obj.attachment}
        disabled={processDisabledInteraction()}
      />
    );
  };

  const processVideoSelectionEnabled = () => {
    if (processDisabledInteraction()) return true;
    if (!selectedLessonObject) return true;
    if (!selectedLessonObject.hasOwnProperty("id")) return true;
    if (!selectedLessonObject.id) return true;
    if (!obj) return true;
    if (!obj.hasOwnProperty("id")) return true;
    if (!obj.id) return true;
    if (selectedLessonObject.id !== obj.id) return true;
    return false;
  };
  const processVideoList = () => {
    console.log("processVideoList", currentRoute);
    if (!obj) return null
    if (!obj.hasOwnProperty("id")) return null
    if (!obj.id) return null
    if (!obj.hasOwnProperty("video")) return null
    if (!obj.video) return null

    return (
      <VideoListLessonStatic
        objects={obj.video}
        disabled={processVideoSelectionEnabled()}
        currentRoute={currentRoute}
      />
    );
  };

  const processGalleryList = () => {
    if (!obj) return null
    if (!obj.hasOwnProperty("id")) return null
    if (!obj.id) return null
    if (!obj.hasOwnProperty("gallery")) return null
    if (!obj.gallery) return null

    return (
      <GalleryListLessonStatic
        objects={obj.gallery}
        disabled={processDisabledInteraction()}
      />
    );
  };

  const processResourceMenu = () => {
    if (!openResource) return null
    return (
      <Box>
        {processVideoList()}
        {processAttachmentList()}
        {processGalleryList()}
      </Box>
    );
  };

  const processComponent = () => {
    if (!obj) return null
    if (!obj.hasOwnProperty("id")) return null
    if (!obj.id) return null

    return (
      <React.Fragment>
        <Accordion
          disabled={processDisabledInteraction()}
          expanded={expanded}
          onChange={handleOnClick}
          className="accordionLessons"
          square
          elevation={0}
          disableGutters
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panelLessonContent"
            id={"panelLessonHeader" + obj.id}
          >
            <Stack direction="row" spacing={1} alignItems={"center"}>
              {processSelectedLesson()}
              <Box>
                <VerifyLesson idLesson={obj.id} />
              </Box>
              <Box>
                <Typography>
                  {ComponentCommonTools.processObjectField(obj, "name")}
                </Typography>
              </Box>
            </Stack>
          </AccordionSummary>
          <AccordionDetails className="subAccordion">
            {processButtonPlay()}
            {processButtonFinish()}

            <Grid container className="zoneButtons">
              <Grid item xs={6} className="rightBorder">
                {processDescriptionButton()}
              </Grid>
              <Grid item xs={6}>
                {processResourceButton()}
              </Grid>
            </Grid>
            <Collapse in={openResource}>{processResourceMenu()}</Collapse>
          </AccordionDetails>
        </Accordion>
        <StudentLessonDialogDescription
          obj={obj}
          open={openDescription}
          setOpen={setOpenDescription}
        />
      </React.Fragment>
    );
  };
  return processComponent();
};
