import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

import { BottomMenuLevel } from "./BottomMenuLevel";

const BottomMenuBlock: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  
  return <BottomMenuLevel currentRoute={currentRoute} level={1} idparent={""} />; 
};

export { BottomMenuBlock };
