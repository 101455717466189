import React, { useCallback, useEffect, useState } from "react";
import { useResource } from "hooks/useResource";

import { CourseService } from "services/course/course.service";

import { CourseDto } from "dto/course/course.dto";
import { RouteTools } from "tools/utils/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools//types/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";

import Skeleton from "@mui/material/Skeleton";
import { Box, Typography, Grid } from "@mui/material";

import { ComponentCommonTools } from "tools/utils/componentcommontools";

import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { MyPagination } from "components/elements/MyPagination/MyPagination";
import { useCookies } from "react-cookie";

import { FilterBox } from "../filter/FilterBox";
import RequestCriteriaDTO from "dto/app/requestcriteria.dto";
import { MySortSelect } from "components/elements/Select/MySortSelect";

import { NewsletterBlock } from "components/general/NewsletterBlock";
import { CourseListCard } from "components/course/course/CourseListCard";
import { CourseCategoryDto } from "dto/course/coursecategory.dto";
import Alert from "@mui/material/Alert";
import { NoResult } from "components/general/NoResult";
import { SkeletonCoursesCtg } from "components/elements/Skeleton/SkeletonCategory";

const service = new CourseService();

type Props = {
  currentRoute: any;
  idCategory: string;
  categoryObject: CourseCategoryDto | undefined;
};

const CourseList: React.FC<Props> = ({
  currentRoute,
  idCategory,
  categoryObject,
  ...props
}) => {
  const { LL, CC } = useResource();

  const mainUrl =
    currentRoute && currentRoute.url ? currentRoute.url.split("?") : "";
  const [isLoading, setIsLoading] = useState(true);
  const [objects, setObjects] = useState<Array<CourseDto>>([]);
  const [cookies] = useCookies();
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(-1);
  const [totalPage, setTotalPage] = useState<number>(-1);

  const [filters, setFilters] = useState<Array<RequestCriteriaDTO>>(
    new Array<RequestCriteriaDTO>()
  );

  const [filtersRange, setFiltersRange] = useState<Array<RequestCriteriaDTO>>(
    new Array<RequestCriteriaDTO>()
  );

  const [sortCriteria, setSortCriteria] = useState<
    Array<RequestSortCriteriaDTO>
  >(new Array<RequestSortCriteriaDTO>());
  const [filterRating, setFilterRating] = useState<
    RequestFilterDTO | undefined
  >();

  const getList = useCallback(() => {
    if (!sortCriteria || !sortCriteria.length) return;

    const reqList = new RequestListDTO();
    reqList.onpage = parseInt(CC("CourseListOnPage", "10"));
    reqList.page = page;
    reqList.filters = [
      RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
      RequestFilterDTO.prepareFilter("idcoursecategory", [idCategory]),
    ];
    reqList.sortcriteria = sortCriteria;
    reqList.criteria = filters;
    reqList.range = filtersRange;

    if (filterRating) reqList.filters.push(filterRating);

    const urlPart = RouteTools.prepareListLocation(reqList);
    setIsLoading(true);
    RouteTools.setHistory(mainUrl[0] + urlPart, {});
    service.getList(loadObjects, {}, reqList);
  }, [page, filters, filtersRange, sortCriteria, filterRating, idCategory]);

  const defaultSortCriteria = () => {
    if (!sortCriteria || sortCriteria.length === 0) {
      const sortCriteria = new RequestSortCriteriaDTO();
      sortCriteria.field = "popularidentifier";
      sortCriteria.asc = false;
      setSortCriteria([sortCriteria]);
    }
  };

  useEffect(() => {
    const reqListRoute = RouteTools.prepareListRequest(currentRoute, cookies);
    if (reqListRoute.page) setPage(reqListRoute.page);
    if (reqListRoute.criteria && reqListRoute.criteria.length > 0) {
      setFilters(reqListRoute.criteria);
    }
    if (reqListRoute.range && reqListRoute.range.length > 0) {
      setFiltersRange(reqListRoute.range);
    }
    if (reqListRoute.filters && reqListRoute.filters.length > 0) {
      const existingFilter = reqListRoute.filters.find(
        (filter) => filter.field === "fromteacherrating"
      );
      setFilterRating(existingFilter);
    }
    if (reqListRoute.sortcriteria && reqListRoute.sortcriteria.length > 0) {
      setSortCriteria(reqListRoute.sortcriteria);
    } else {
      defaultSortCriteria();
    }
  }, []);

  const loadObjects = (result: ResultListDTO) => {
    if (!result) return;
    if (!result.err) {
      const objects = result.objects ? result.objects : [];
      const total = result.total !== undefined ? result.total : -1;
      const totalPage =
        result.totalpages !== undefined ? result.totalpages : -1;

      setObjects(objects);
      setTotal(total);
      setTotalPage(totalPage);
    }
  };

  const checkLoading = useCallback(() => {
    let load = false;

    if (total === -1) return true;
    if (totalPage === -1) return true;
    if (!objects) load = true;

    setIsLoading(load);
  }, [objects, total, totalPage]);
  useEffect(() => {
    getList();
  }, [getList]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  const processPagination = () => {
    if (totalPage === -1) return null;
    if (total === -1) return null;
    if (objects.length === 0) return null;

    return (
      <Box
        className="container"
        py={4}
        display="flex"
        sx={{ justifyContent: "center" }}>
        <MyPagination
          page={page}
          total={total}
          totalPage={totalPage}
          setPage={setPage}
        />
      </Box>
    );
  };

  const processSortSelect = () => {
    if (isLoading) return null;

    const objects = [
      "popularidentifier_asc",
      "popularidentifier_desc",
      "name_asc",
      "name_desc",
    ];
    return (
      <Box
        mb={4}
        sx={{
          "& label": {
            display: "none",
          },
          maxWidth: "300px",
        }}>
        <MySortSelect
          objects={objects}
          setSortCriteria={setSortCriteria}
          sortCriteria={sortCriteria}
          variant="standard"
          className="form-select"
        />
      </Box>
    );
  };

  const processCourseItem = (obj: CourseDto, index: number) => {
    if (!obj) return null;
    return (
      <Box sx={{ height: "100%" }} key={index}>
        <CourseListCard obj={obj} loading={isLoading} />
      </Box>
    );
  };

  const processNumberOfResults = () => {
    if (isLoading) return null;
    if (total === -1) return null;
    return (
      <Box>
        <Typography>
          {total} {LL("Results")}
        </Typography>
      </Box>
    );
  };

  const processCourseList = () => {
    if (!objects.length) return <NoResult identifier="course" />;

    return (
      <React.Fragment>
        {objects.map((item: CourseDto, i: number) => {
          return (
            <Grid item lg={12} sm={6} xs={12} sx={{}}>
              {processCourseItem(item, i)}
            </Grid>
          );
        })}
      </React.Fragment>
    );
  };

  const processFilterBox = () => {
    return (
      <FilterBox
        currentRoute={currentRoute}
        idCategory={idCategory}
        setFilters={setFilters}
        filters={filters}
        setFiltersRange={setFiltersRange}
        filtersRange={filtersRange}
        numberOfResults={total}
        setFilterRating={setFilterRating}
        filterRating={filterRating}
      />
    );
  };

  const processCourseCategoryName = () => {
    if (!objects) return null;
    if (!categoryObject) return null;
    return ComponentCommonTools.processObjectField(categoryObject, "name");
  };
  return (
    <Box className="courseLIST">
      <Box className="banner">
        <Box className="container">
          <Typography variant="h3" my={4}>
            {LL("gasestecursulpotrivit")}
          </Typography>
        </Box>
      </Box>
      <Box className="container" py={5}>
        <Typography variant="h2" mb={4}>
          {LL("ListCourseSubject")} {processCourseCategoryName()}
        </Typography>
        <Alert severity="info" className="alert">
          {LL("Nu esti sigur")}
        </Alert>
        <Grid container spacing={2} mt={4}>
          <Grid item sm={12} lg={3}>
            {processFilterBox()}
          </Grid>
          <Grid item sm={12} lg={9}>
            {processSortSelect()}
            {processNumberOfResults()}
            {isLoading ? (
              <SkeletonCoursesCtg lines={3} />
            ) : (
              <Grid
                container
                spacing={2}
                sx={{
                  py: 2,
                }}>
                {processCourseList()}
              </Grid>
            )}
            {isLoading ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}>
                <Skeleton sx={{ width: "40px" }} />
              </Box>
            ) : (
              processPagination()
            )}
          </Grid>
        </Grid>
      </Box>
      <Box>
        <NewsletterBlock currentRoute={currentRoute} />
      </Box>
    </Box>
  );
};

export { CourseList };
