import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hooks/useResource";

import { useAuth } from "hooks/useAuth";
import { Types } from "tools/types/types";
import { MyButton } from "components/elements/button/MyButton";
import { RouteTools } from "tools/utils/routetools";

const UserInterfaceBlock: React.FC<PageComponentProps> = () => {
  const { isTeacher } = useResource();

  const { user, userInterface, setUserInterface } = useAuth();

  const handleChangeInterface = () => {
    if (userInterface === Types.STUDENT_INTERFACE) {
      setUserInterface(Types.TEACHER_INTERFACE);
      RouteTools.setHistory("/teacherinterface", {});
    }
    if (userInterface === Types.TEACHER_INTERFACE) {
      setUserInterface(Types.STUDENT_INTERFACE);
      RouteTools.setHistory("/", {});
    }
  };
  const processUserInterface = () => {
    const interfaceLabel = Types.LL(userInterface);

    return (
      <MyButton
        className="typeInterfaceBtn"
        children={interfaceLabel}
        cb={handleChangeInterface}
      />
    );
  };
  return user && isTeacher() ? processUserInterface() : null;
};

export { UserInterfaceBlock };
