import React from "react";

import { FAQService } from "services/static/faq.service";

import RequestListDTO from "dto/app/requestlist.dto";
import { FAQDto } from "dto/static/faq.dto";
import { Loading } from "components/elements/loading/Loading";
import Box from "@mui/material/Box";

import { MyAccordion } from "components/elements/MyAccordion/MyAccordion";
import { useList } from "hooks/useList";
import { CallbackType } from "interfaces/commontypes.interface";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Status } from "tools/types/status";

const service = new FAQService();

interface Props {
  currentRoute: any;
}

const FAQPage: React.FC<Props> = () => {
  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };
  const [loading, objects] = useList<FAQDto>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()])],
      1,
      -1
    ),
    [],
    true,
    "faq_list"
  );

  // const [data, setData] = useState<ResultListDTO>(new ResultListDTO());
  // const [rows, setRows] = useState<Array<FAQDto>>(new Array<FAQDto>());

  // const [total, setTotal] = useState(0);
  // const [totalPage, setTotalPage] = useState(0);

  // const [responseParams, setResponseParams] = useState<RequestListDTO>(
  //   new RequestListDTO()
  // );

  // const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());

  // const [loading, setLoading] = useState<boolean>(true);

  // const loadObjects = (data: ResultListDTO) => {
  //   CommonTools.processListLoadObjects(
  //     data,
  //     setData,
  //     setRows,
  //     setTotal,
  //     setTotalPage,
  //     setResponseParams
  //   );
  // };
  // const getList = () => {
  //   setLoading(true);

  //   faqService.getList(loadObjects, {}, reqList);
  // };

  // const getReqList = () => {
  //   let reqList = faqService.getDefaultRequestListSelect(100);

  //   setReqList(reqList);
  // };

  // useEffect(() => {
  //   getReqList();
  // }, [currentRoute]);

  // useEffect(() => {
  //   if (CommonTools.atLeastOneFieldDefined(reqList)) {
  //     getList();
  //   }
  // }, [reqList]);

  // useEffect(() => {
  //   checkIsLoading();
  // }, [data, rows, reqList, total, totalPage]);

  // const checkIsLoading = () => {
  //   const load: boolean = CommonTools.checkIsLoading(
  //     reqList,
  //     responseParams,
  //     data,
  //     total,
  //     totalPage,
  //     rows
  //   );
  //   setLoading(load);
  // };

  const processFAQItem = (obj: FAQDto, index: number) => {
    if (!obj) return null;
    if (!obj.question || !obj.answer) return null;

    return (
      <MyAccordion question={obj.question} answer={obj.answer} key={index} />
    );
  };

  const processFAQList = () => {
    if (!objects) return null;
    if (!objects.length) return null;

    return (
      <Box>
        {objects.map((item: FAQDto, i: number) => {
          return processFAQItem(item, i);
        })}
      </Box>
    );
  };

  return loading ? <Loading /> : processFAQList();
};

export default FAQPage;
