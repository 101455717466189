import React from "react";

import { LinkCourseCategoryDto } from "dto/course/linkcoursecategory.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";

import { LinkCourseCategoryService } from "services/course/linkcoursecategory.service";

import { Box, Skeleton, Typography } from "@mui/material";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { useList } from "hooks/useList";
import { CallbackType } from "interfaces/commontypes.interface";

type Props = {
  idCourse: string;
};

const service = new LinkCourseCategoryService();

const LinkCourseCategoryList: React.FC<Props> = ({ idCourse }) => {
  //   const [isLoading, setIsLoading] = useState(true);
  //   const [objects, setObjects] = useState<Array<LinkCourseCategoryDto>>([]);
  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    if (!idCourse) return;
    service.getList(cb, cbParams, req);
  };

  const [loading, objects] = useList<LinkCourseCategoryDto>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("idcourse", [idCourse])],
      1,
      -1
    ),
    [idCourse]
  );
  //   const getList = () => {
  //     setIsLoading(true);
  //     const reqList = new RequestListDTO();
  //     reqList.onpage = 999999;
  //     reqList.page = 1;
  //     reqList.filters = [];

  //     let f: RequestFilterDTO;

  //     f = new RequestFilterDTO();
  //     f.field = "idcourse";
  //     f.values = [];
  //     f.values.push(idCourse);
  //     reqList.filters.push(f);

  //     service.getList(loadObjects, {}, reqList);

  //     // setIsLoading(false);
  //   };

  //   const loadObjects = (result: ResultListDTO) => {
  //     if (!result) return;
  //     if (!result.err) {
  //       const objects = result.objects ? result.objects : [];
  //       // const total = result.total !== undefined ? result.total : -1;
  //       // const totalPage = result.totalpages !== undefined ? result.totalpages : -1;
  //       // const reqInfo = result.requestinfo ? result.requestinfo : {};
  //       setObjects(objects);
  //       // setTotal(total);
  //       // setTotalPage(totalPage);
  //       // setResponseParams(reqInfo);
  //     }
  //   };

  //   const checkLoading = () => {
  //     let load = false;

  //     // if (total === -1) return true;
  //     // if (totalPage === -1) return true;
  //     if (!objects) load = true;
  //     // if (objects.length === 0) load = true;
  //     // if (Object.keys(responseParams).length === 0) return true;

  //     setIsLoading(load);
  //   };

  //   useEffect(() => {
  //     getList();
  //   }, [idCourse]);

  //   useEffect(() => {
  //     checkLoading();
  //   }, [objects]);

  const processLinkCourseCategoryItem = (
    obj: LinkCourseCategoryDto,
    index: number
  ) => {
    if (!obj) return null;
    if (!obj.id) return null;

    return (
      <Box key={index}>
        <Typography className="nameFilter">
          {ComponentCommonTools.processObjectFieldOfObject(
            obj,
            "coursecategory",
            "name"
          )}
        </Typography>
      </Box>
    );
  };

  const processLinkCourseCategoryList = () => {
    if (!objects) return null;
    if (!objects.length) return null;
    return (
      <Box>
        {objects.map((item: LinkCourseCategoryDto, i: number) => {
          return processLinkCourseCategoryItem(item, i);
        })}
      </Box>
    );
  };

  return loading ? <Skeleton /> : <Box>{processLinkCourseCategoryList()}</Box>;
};

export { LinkCourseCategoryList };
