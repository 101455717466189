import React from "react";

import { TestimonialDto } from "dto/user/testimonial.dto";

import { MyNavBt } from "components/elements/button/MyNavBt";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import Typography from "@mui/material/Typography";

import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";

type Props = {
  obj: TestimonialDto;
};

const BecomeTeacherTestimonialItem: React.FC<Props> = ({ obj }) => {
  const processTeacher = () => {
    if (!obj) return null;
    if (!obj.teacher) return null;
    if (!obj.teacher_fullname) return null;
    let fullUrl = "";
    if (obj.teacher.hasOwnProperty("fullurl") && obj.teacher.fullurl)
      fullUrl = obj.teacher.fullurl;
    if (!fullUrl) {
      return (
        <Typography>
          {ComponentCommonTools.processObjectField(obj, "teacher_fullname")}
        </Typography>
      );
    }
    return (
      <MyNavBt href={fullUrl}>
        <Typography>
          {ComponentCommonTools.processObjectField(obj, "teacher_fullname")}
        </Typography>
      </MyNavBt>
    );
  };

  const processCourse = () => {
    if (!obj) return null;
    if (!obj.course) return null;
    if (!obj.course.name) return null;
    return (
      <Typography>
        {ComponentCommonTools.processObjectFieldOfObject(obj, "course", "name")}
      </Typography>
    );
  };

  const processAvatar = () => {
    if (!obj) return null;
    let name = "";
    let surname = "";
    if (obj.hasOwnProperty("user_name") && obj.user_name) name = obj.user_name;
    if (obj.hasOwnProperty("user_surname") && obj.user_surname)
      surname = obj.user_surname;

    return <MyAvatar obj={obj} name={name} surname={surname} sx={{ mr: 1 }} />;
  };
  const processItem = () => {
    if (!obj) return null;

    return (
      <Box p={2} className="testimonials-home">
        <Card className="shadow-element">
          <CardContent sx={{ textAlign: "center" }}>
            <Box className="icon-quotes"></Box>
            <Box
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "150px",
              }}
            >
              <Typography my={2} className="message">
                {ComponentCommonTools.processObjectField(obj, "message")}
              </Typography>
            </Box>
          </CardContent>
        </Card>

        <Box sx={{ display: "flex" }} mt={3}>
          <Box mr={1}>{processAvatar()}</Box>
          <Box>
            <Typography className="data">{processCourse()}</Typography>
            <Typography className="teacher">{processTeacher()}</Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return processItem();
};

export { BecomeTeacherTestimonialItem };
