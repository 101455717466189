import React from "react";

import { Chip,ChipProps } from "@mui/material";

import { Status } from "tools//types/status";

type Props = ChipProps & {
  
  obj?: any;
};

const StatusChip: React.FC<Props> = ({ obj, ...props }) => {
  const processColor = (value: number) => {
    switch (value) {
      case Status.ACTIVE:
        return "success";
      case Status.INACTIVE:
        return "error";
      case Status.REQUEST_ACCEPTED:
        return "success";
      case Status.REQUEST_REJECTED:
        return "error";
      case Status.TEST_SUCCESS:
        return "success";
      default:
        return "primary";
    }
  };
  const processChip = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("status")) return null;
    if (!obj.status) return null;
    if (!obj.status_name) return null;
    const color = processColor(obj.status);

    return <Chip label={obj.status_name} {...props} color={color} />;
  };

  return processChip();
};

export { StatusChip };
