import Idto from "interfaces/idto.interface";
import { Status } from "tools//types/status";

export class TestimonialDto implements Idto {
  id?: string;
  iduser?: string;
  idteacher?: string;
  idcourse?: string;
  date?: number;
  rating?: number;
  status?: number;
  message?: string;

  date_name?: string;
  status_name?: string;
  user?: any;
  teacher?: any;
  course?: any;
  user_email?: any;
  usersettings?: any;
  user_name?: any;
  user_surname?: any;
  teacher_email?: any;
  teacher_fullname?: string;
  user_fullurl?: string;
  teacherresponse?: string;
  constructor() {
    this.status = Status.ACTIVE;
    this.rating = 5;
  }
}

export class PostTestimonialDto implements Idto {
  iduser?: string;
  idteacher?: string;
  idcourse?: string;
  date?: number;
  rating?: number;
  status?: number;
  message?: string;
  date_name?: string;
  status_name?: string;
  user?: any;
  teacher?: any;
  course?: any;
  user_email?: string;
  usersettings?: any;
  user_name?: string;
  user_surname?: string;
  teacher_email?: string;
  teacher_fullname?: string;
  teacherresponse?: string;
  constructor() {
    this.status = Status.ACTIVE;
    this.rating = 5;
  }
}

export class TestimonialResponseDto implements Idto {
  teacherresponse?: string;

  constructor(teacherresponse?: string, showoncourse?: boolean) {
    this.teacherresponse = teacherresponse || "";
  }
}
