import React from "react";

import Box from "@mui/material/Box";
import RequestListDTO from "dto/app/requestlist.dto";
import { TestimonialDto } from "dto/user/testimonial.dto";
import { TestimonialService } from "services/user/testimonial.service";
import { Status } from "tools//types/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { useResource } from "hooks/useResource";

import Skeleton from "@mui/material/Skeleton";
import { TestimonialCard } from "components/user/testimonial/TestimonialCard";
import { useList } from "hooks/useList";

const service = new TestimonialService();

type Props = {
  idTeacher: string;
};

const TeacherTestimonials: React.FC<Props> = ({ idTeacher }) => {
  const { CC } = useResource();

  const getList = (cb?: any, cbParams?: any, reqList?: RequestListDTO) => {
    if (!idTeacher) return;
    service.getList(cb, cbParams, reqList);
  };

  const [loading, objects] = useList<TestimonialDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
        RequestFilterDTO.prepareFilter("idteacher", [idTeacher]),
      ],
      1,
      parseInt(CC("TeacherTestimonial_OnPage_Show", "5"))
    ),
    [idTeacher],
    true,
    "testimonial_teacher_block"
  );

  //   const [isLoading, setIsLoading] = useState(true);
  //   const [objects, setObjects] = useState<Array<TestimonialDto>>([]);

  //   const getList = () => {
  //     const reqList = new RequestListDTO();
  //     reqList.onpage = parseInt(CC("TeacherTestimonial_OnPage_Show", "5"));
  //     reqList.page = 1;
  //     reqList.filters = [];

  //     let f: RequestFilterDTO;

  //     f = new RequestFilterDTO();
  //     f.field = "status";
  //     f.values = [];
  //     f.values.push(Status.ACTIVE.toString());
  //     reqList.filters.push(f);

  //     f = new RequestFilterDTO();
  //     f.field = "idteacher";
  //     f.values = [idTeacher];
  //     reqList.filters.push(f);

  //     setIsLoading(true);

  //     service.getList(loadObjects, {}, reqList);
  //   };
  //   const loadObjects = (result: ResultListDTO) => {
  //     if (!result) return;
  //     if (!result.err) {
  //       const objects = result.objects ? result.objects : [];
  //       // const total = result.total !== undefined ? result.total : -1;
  //       // const totalPage = result.totalpages !== undefined ? result.totalpages : -1;
  //       // const reqInfo = result.requestinfo ? result.requestinfo : {};
  //       setObjects(objects);
  //       // setTotal(total);
  //       // setTotalPage(totalPage);
  //       // setResponseParams(reqInfo);
  //     }
  //   };

  //   const checkLoading = () => {
  //     let load = false;

  //     // if (total === -1) return true;
  //     // if (totalPage === -1) return true;
  //     if (!objects) load = true;
  //     // if (objects.length === 0) load = true;
  //     // if (Object.keys(responseParams).length === 0) return true;

  //     setIsLoading(load);
  //   };

  //   useEffect(() => {
  //     if (!idTeacher) return;
  //     getList();
  //   }, [idTeacher]);

  //   useEffect(() => {
  //     checkLoading();
  //   }, [objects]);

  const processTestimonialItem = (obj: TestimonialDto, index: number) => {
    if (!obj) return null;

    return (
      <Box key={index}>
        <TestimonialCard obj={obj} />
      </Box>
    );
  };

  const processTestimonialList = () => {
    if (!objects) return null;
    if (!objects.length) return null;

    return (
      <Box>
        {objects.map((item: TestimonialDto, i: number) => {
          return processTestimonialItem(item, i);
        })}
      </Box>
    );
  };

  return loading ? <Skeleton /> : processTestimonialList();
};

export { TeacherTestimonials };
