import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import { CourseFilterRepository } from "repositories/course/coursefilter.repository";

export class CourseFilterService
  extends GeneralService<CourseFilterRepository>
  implements IService
{
  constructor() {
    super(new CourseFilterRepository(), [], "value");
  }
}
// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// // import {CourseFilterDto} from "dto/course/coursefilter.dto";

// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import {CourseFilterRepository} from "repositories/course/coursefilter.repository";
// import { Status } from "tools//types/status";
// import GeneralService from "services/general.service";
// import { CourseFilterDto } from "dto/course/coursefilter.dto";

// export default class CourseFilterService extends GeneralService implements IService {
//     courseFilterRepository: CourseFilterRepository = new CourseFilterRepository();
//     constructor() {
//         super();
//         this.handleGetList = this.handleGetList.bind(this);
//         this.handleGet = this.handleGet.bind(this);
//       }
//     handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;

//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     async update(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {

//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         this.courseFilterRepository.update(id, this.handleUpdate, data, cbparameters);
//     }

//     handleDelete(result?: any, cbparameters?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters);
//         }
//     }

//     async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.courseFilterRepository.delete(id, this.handleDelete, cbparameters);
//     }

//     handleGet(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//     return rez;
//     }

//     async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.courseFilterRepository.get(id, this.handleGet, cbparameters);
//         if (cb == undefined)
//         {
//             return this.handleGet(t);
//         }
//     }

//     handleGetList(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//         return rez;
//     }

//     async getList(
//         cb?: any,
//         cbparameters?: any,
//         data?: RequestListDTO
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.courseFilterRepository.getList(this.handleGetList, cbparameters, data);
//         if (cb == undefined)
//         {
//             return this.handleGetList(t);
//         }
//     }

//     handleAdd(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//      prepareObject  (obj: any)  {
//         const rez = new CourseFilterDto();
//         if(obj.hasOwnProperty('idfilter') && obj.idfilter) rez.idfilter = obj.idfilter;
//         if(obj.hasOwnProperty('idfilterdictionary') && obj.idfilterdictionary) rez.idfilterdictionary = obj.idfilterdictionary;
//         if(obj.hasOwnProperty('idcourse') && obj.idcourse) rez.idcourse = obj.idcourse;
//         if(obj.hasOwnProperty('value') && obj.value !== '') rez.value = obj.value;
//         return rez;
//     }

//     async add(
//         cb?: any,
//         cbparameters?: any,
//         data?: any
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.courseFilterRepository.add(this.handleGetList, cbparameters, this.prepareObject(data));
//     }

//     getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
//         if (
//             !obj.sortcriteria ||
//             !Array.isArray(obj.sortcriteria) ||
//             !obj.sortcriteria.length
//         ) {
//             var sobj = new RequestSortCriteriaDTO();
//             sobj.asc = true;
//             sobj.field = "value";

//             obj.sortcriteria = [];
//             obj.sortcriteria?.push(sobj);
//         }

//         return obj;
//     }
// }
