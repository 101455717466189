import Idto from 'interfaces/idto.interface';
import { PostTestResultAnswerDto } from './testresultanswer.dto';

export class TestResultQuestionDto implements Idto {

    id?: string;
    idquestion?: string;
    idtestresult?: string;
    maxquestionscore?: number;
    questiontotalscore?: number;
    question?: any;
    testresult?: any;
}

export class PostTestResultQuestionDto implements Idto {

    idquestion?: string;
    idtestresult?: string;
    maxquestionscore?: number;
    questiontotalscore?: number;
 
    constructor () {
        this.questiontotalscore = 0;
    }
}


export class PostTestResultQuestionAndAnswerDto implements Idto {
    question?: PostTestResultQuestionDto;
    answers?: PostTestResultAnswerDto[];
}


export class TestResultAnswerBodyDto implements Idto { 
    idanswer: string;
    idtestresult: string;

    constructor(idanswer: string, idtestresult: string) {
        this.idanswer = idanswer;
        this.idtestresult = idtestresult;
    }
}

export class TestResultQuestionBodyDto implements Idto {
    idquestion: string;
    idtestresult: string;
    maxquestionscore: number;
    constructor(
      idquestion: string,
      idtestresult: string,
      maxquestionscore: number,
    ) {
      this.idquestion = idquestion;
      this.idtestresult = idtestresult;
      this.maxquestionscore = maxquestionscore;
    }
  }
  
  export class TestResultQuestionAndAnswerBodyDto implements Idto {
    question: TestResultQuestionBodyDto;
    answers: TestResultAnswerBodyDto[];
    constructor(
      question: TestResultQuestionBodyDto,
      answers: TestResultAnswerBodyDto[],
    ) {
      this.question = question;
      this.answers = answers;
    }
  }
  