import Idto from "interfaces/idto.interface";
import { Types } from "tools/types/types";
import { Config } from "tools/utils/config";

export class RegisterUserDto implements Idto {
  email?: string;
  password?: string;
  name?: string;
  surname?: string;
  birthday?: Date;
  phonenumber?: string;
  countrycode?: string;
  idtypephone?: string;
  idlanguage?: string;
  notificationtype1?: boolean;

  constructor(
    idlanguage?: string,
    email?: string,
    password?: string,
    name?: string,
    surname?: string,
    birthday?: Date,
    phonenumber?: string,
    countrycode?: string,
    idtypephone?: string,
    notificationtype1?: boolean
  ) {
    this.email = email || "";
    this.password = password || "";
    this.name = name || "";
    this.surname = surname || "";
    this.birthday = birthday || undefined;
    this.phonenumber = phonenumber || "";
    this.countrycode = countrycode || Config.COUNTRY_CODE;
    this.idtypephone = idtypephone || "";
    this.idlanguage = idlanguage || "";
    this.notificationtype1 = notificationtype1 || false;
  }
}


export class RegisterDto implements Idto {
  email?: string;
  password?: string;
  name?: string;
  surname?: string;
  birthday?: Date;
  phonenumber?: string;
  countrycode?: string;
  idtypephone?: string;
  idlanguage?: string;
  notificationtype1?: boolean;
  
  typeaccount?: number;
  idtypestudies?: string;
  idtypespecialty?: string;

  constructor(
    idlanguage?: string,
    email?: string,
    password?: string,
    name?: string,
    surname?: string,
    birthday?: Date,
    phonenumber?: string,
    countrycode?: string,
    idtypephone?: string,
    notificationtype1?: boolean,
    typeaccount?: number,
    idtypestudies?: string,
    idtypespecialty?: string
  ) {
    this.email = email || "";
    this.password = password || "";
    this.name = name || "";
    this.surname = surname || "";
    this.birthday = birthday || undefined;
    this.phonenumber = phonenumber || "";
    this.countrycode = countrycode || Config.COUNTRY_CODE;
    this.idtypephone = idtypephone || "";
    this.idlanguage = idlanguage || "";
    this.notificationtype1 = notificationtype1 || false;
    this.typeaccount = typeaccount || Types.TYPE_ACCOUNT_STUDENT;
    this.idtypestudies = idtypestudies || "";
    this.idtypespecialty = idtypespecialty || "";
  }
}
