import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { StudentInterfaceTestimonialList } from "./StudentInterfaceTestimonialList";
import Typography from "@mui/material/Typography";
import { useResource } from "hooks/useResource";

const StudentInterfaceTestimonialBlock: React.FC<PageComponentProps> = ({
  currentRoute
}) => {
  const { LL } = useResource();
  return (
    <React.Fragment>
      <Typography variant="h3" textAlign="center" my={3}>
        {LL("Testimonials student")}
      </Typography>
      <StudentInterfaceTestimonialList currentRoute={currentRoute} />
    </React.Fragment>
  );
};

export { StudentInterfaceTestimonialBlock };
