import React, { useContext } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { TabPanel } from "components/elements/Tabs/TabPanel";
import { useResource } from "hooks/useResource";
import { GeneralInfoCourseTab } from "components/user/student/GeneralInfoCourseTab";
import { FAQTabBlock } from "components/user/student/FAQTabBlock";
import { ResourcesTabBlock } from "components/user/student/ResourcesTabBlock";
import { RouteTools } from "tools/utils/routetools";
import { CommonTools } from "tools/utils/commontools";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { StudentCourseContext } from "context/studentcourse.context";

const StudentCourseTabBlock: React.FC<PageComponentProps> = ({
  currentRoute,
}) => {
  const { LL } = useResource();

  const {
    state: { course },
  } = useContext(StudentCourseContext);

  const [value, setValue] = React.useState(
    CommonTools.getAnchor(currentRoute, "generalInfoCourse")
  );

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setValue(newValue);
    RouteTools.setAnchor(newValue);
  };

  const processComponent = () => {
    if (!course) return null;
    return (
      <Box>
        <Box className="generalTabs">
          <Tabs
            variant="scrollable"
            value={value}
            onChange={handleChange}
            orientation="horizontal"
            scrollButtons
            allowScrollButtonsMobile
            className="tabs">
            <Tab
              label={LL("General_Info_Course")}
              value={"generalInfoCourse"}
            />
            <Tab label={LL("FAQ")} value={"faq"} />
            <Tab label={LL("Resources")} value={"resources"} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={"generalInfoCourse"}>
          <GeneralInfoCourseTab obj={course} />
        </TabPanel>
        <TabPanel value={value} index={"faq"}>
          <Box className="contain">
            <FAQTabBlock obj={course} />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={"resources"}>
          <Box className="contain">
            <ResourcesTabBlock obj={course} />
          </Box>
        </TabPanel>
      </Box>
    );
  };

  return processComponent();
};

export { StudentCourseTabBlock };
