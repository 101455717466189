import React, { useState } from "react";

import { Menu, MenuItem, IconButton } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { CourseCategoryDto } from "dto/course/coursecategory.dto";
import { CommonTools } from "tools/utils/commontools";

type Props = {
  objects: CourseCategoryDto[];
  action: (object: CourseCategoryDto) => void;
};

const SubcategoryBlockMenu: React.FC<Props> = ({ objects, action }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {objects.map((object, index) => (
          <MenuItem key={index} onClick={action.bind(this, object)}>
            {CommonTools.processObjectField(object, ["name"])}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export { SubcategoryBlockMenu };
