import { NewMySelect } from "components/elements/Select/newSelect/NewMySelect";
import { LanguageDto } from "dto/static/language.dto";
import { useResource } from "hooks/useResource";
import React from "react";

type Props = {
  field?: string;
  value: any;
  setObjectField: (field: string, value: any) => void;
  label?: string;
};
const NewLanguageSelect: React.FC<Props> = ({
  field = "idlanguage",
  value,
  setObjectField,
  label = "Language",
}) => {
  const { langs } = useResource();

  if (!langs) return null;
  return (
    <NewMySelect
      options={LanguageDto.parseToSelectOptions(langs)}
      value={value}
      field={field}
      setObjectField={setObjectField}
      _label={label}
      size="small"
      variant="standard"
      color="primary"
      className="form-select"
      
    />
  );
};

export { NewLanguageSelect };
