import React, { useContext } from "react";
import { useResource } from "hooks/useResource";
import { Alert, Box, Typography } from "@mui/material";
import { TestDialog } from "components/course/test/TestDialog";
import { Types } from "tools/types/types";
import { StudentCourseContext } from "context/studentcourse.context";
import { NewTestDialog } from "components/course/test/new/NewTestDialog";

const FinalTest: React.FC = () => {
  const { LL } = useResource();

  const {
    state: { idCourse, courseEnrollmentObject, course },
    actions: { triggerReloadCourseEnrollment },
  } = useContext(StudentCourseContext);

  const processComponent = () => {
    if (!courseEnrollmentObject) return <></>;
    if (!course) return <></>;
    return (
      <Box>
        <Box>
          <Alert severity="info">
            <Typography>
              {LL("To_finish_the_course_you_need_to_pass_the_final_test")}
            </Typography>
          </Alert>
        </Box>
        <Box>
          <NewTestDialog
            courseEnrollmentObject={courseEnrollmentObject}
            idCourse={idCourse}
            idLesson={""}
            type={Types.TYPE_TEST_AFTER_COURSE}
            course={course}
            setCheckObjectWasChanged={triggerReloadCourseEnrollment}
          />
        </Box>
      </Box>
    );
  };
  return processComponent();
};

export { FinalTest };
