import React, { useEffect, useState } from "react";
import { Popover, Box, TextField, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import MyTextField from "components/form/mytextfield";
import { logger } from "tools/utils/logger";
import { useResource } from "hooks/useResource";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import IconButton from "@mui/material/IconButton";
import FilterTools from "tools/utils/filtertools";
import CloseIcon from "@mui/icons-material/Close";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { MySelect } from "../Select/MySelect";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Stack from '@mui/material/Stack';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

import SelectOptions from "dto/app/selectoptions.dto";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DateTools from "tools/utils/date.tools";

type TableToolBarProps = {
  setFilter?: any;
  filters?: RequestFilterDTO[] | null | undefined;
  tbFilterList?: TbFilterListDTO | null | undefined;
  tabIndex?: number;
};

const TableToolbar: React.FC<TableToolBarProps> = ({
  setFilter,
  filters,
  tbFilterList,
  tabIndex,
  ...props
}) => {
  const { LL } = useResource();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [_filters, setFilters] = React.useState<Array<RequestFilterDTO> | null>(
    []
  );
  const [_filtersLocal, setFiltersLocal] =
    React.useState<Array<RequestFilterDTO> | null>([]);
  const [_tbFilterList, setTbFilterList] = React.useState<Array<TbFilterDTO>>(
    []
  );

  useEffect(() => {
    if (
      tbFilterList != null &&
      tbFilterList !== undefined &&
      tbFilterList.filters != undefined
    ) {
      setTbFilterList(tbFilterList.filters);
    }
  }, [tbFilterList]);

  useEffect(() => {
    if (filters !== undefined) {
      setFilters(filters);
      setFiltersLocal(filters);
    }
  }, [filters]);

  // -----------------------------------------

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode == 13) {
      handleApply();
    }
  };

  const handleApply = () => {
    setFilters(_filtersLocal);
    setFilter(_filtersLocal);

    handleClose();
  };

  const handleClear = () => {
    setFilters(null);
    setFiltersLocal(null);
    setFilter(null);
    handleClose();
  };

  const updateFilterValue = (obj?: any, value?: any) => {
    value = value.toString();
    if (!value) {
      deleteFilterValue(obj.field);
      return;
    }
    const v = [];
    v.push(value);
    setFilterValue(obj.field, v);
  };

  const updateFilterValueOnIndex = (
    obj: any,
    pvalue?: string,
    pindex?: number
  ) => {
    const value = pvalue ?? "";
    const index = pindex ?? "";

    const _values = getFilterValue(obj.field);

    const v: Array<string> = [];
    for (let i in _values) {
      const numericIndex = parseInt(i, 10);
      v[numericIndex] =
        numericIndex == index ? value?.trim() : _values[i].trim();
    }

    const l: number = v.length;

    if (l <= index) {
      for (let i: number = l; i <= index; i++) {
        v[i] = i == index ? value?.trim() : "";
      }
    }

    let allempty: boolean = true;
    for (let i in v) {
      if (v[i] != "") {
        allempty = false;
      }
    }

    if (allempty) {
      deleteFilterValue(obj.field);
      return;
    }

    setFilterValue(obj.field, v);
  };

  const updateFilterArr = (obj?: any, value?: Array<any>) => {
    if (!value || !value.length) {
      deleteFilterValue(obj.field);
      return;
    }

    let isEmpty = false;

    let v: Array<string> = [];
    value.map(function (item) {
      v.push(item.toString());
      if (!item.toString()) {
        isEmpty = true;
      }
    });
    v = v.filter((n, i) => v.indexOf(n) === i);

    if (isEmpty) {
      deleteFilterValue(obj.field);
      return;
    }

    setFilterValue(obj.field, v);
  };

  const processOptions = (options?: Array<SelectOptions>) => {
    const rez: Array<SelectOptions> = [];

    if (!options) return rez;

    options.map(function (item: SelectOptions) {
      item.value = item.value?.toString();
      rez.push(item);
    });

    return rez;
  };

  // -----------------------------------------

  const getFilterValue = (field: string) => {
    if (!_filtersLocal) return [];
    if (_filtersLocal == undefined) return [];
    if (_filtersLocal == null) return [];
    if (!_filtersLocal.length) return [];

    var rez: string[] | undefined = [];

    _filtersLocal.map(function (item) {
      if (item.field != field) return;
      if (!item.values) return;
      if (item.values == undefined) return;
      rez = item.values;
    });

    return rez;
  };

  const setFilterValue = (field: string, values: Array<string>) => {
    const nfl: Array<RequestFilterDTO> = [];

    var exist: boolean = false;

    if (Array.isArray(_filtersLocal)) {
      _filtersLocal.map(function (item) {
        if (item.field != field) {
          nfl.push(item);
          return;
        }
        exist = true;
        item.values = values;
        nfl.push(item);
      });
    }

    if (!exist) {
      const t = new RequestFilterDTO();
      t.field = field;
      t.values = values;

      nfl.push(t);
    }

    setFiltersLocal(nfl);
  };

  const deleteFilterValue = (field: string) => {
    const nfl: Array<RequestFilterDTO> = [];

    if (Array.isArray(_filtersLocal)) {
      _filtersLocal.map(function (item) {
        if (item.field == field) {
          return;
        }
        nfl.push(item);
      });
    }

    setFiltersLocal(nfl);
  };

  // -----------------------------------------

  const processFiltersElement = (obj: TbFilterDTO, i: any) => {
    if (obj.type == FilterTools.fiterTypeText) {
      return processFiltersElement_FiterTypeText(obj, i);
    }

    if (obj.type == FilterTools.fiterTypeSelect) {
      return processFiltersElement_FiterTypeSelect(obj, i);
    }

    if (obj.type == FilterTools.fiterTypeSelectMultiple) {
      return processFiltersElement_FiterTypeSelectMultiple(obj, i);
    }

    if (obj.type == FilterTools.fiterTypeRange) {
      return processFiltersElement_FiterTypeRange(obj, i);
    }

    if (obj.type == FilterTools.fiterTypeDate) {
      return processFiltersElement_FiterTypeDate(obj, i);
    }

    if (obj.type == FilterTools.fiterTypeDateRange) {
      return processFiltersElement_FiterTypeDateRange(obj, i);
    }

    if (obj.type == FilterTools.fiterTypeDateTS) {
      return processFiltersElement_FiterTypeDateTS(obj, i);
    }

    if (obj.type == FilterTools.fiterTypeDateTSRange) {
      return processFiltersElement_FiterTypeDateTSRange(obj, i);
    }

    return <></>;
  };

  // ------------------------------------
  const processFiltersElement_FiterTypeText = (obj: any, i: any) => {


    const _f = obj.field != undefined && obj.field ? obj.field : "";
    if (!_f) return <></>;

    const _l = obj.label != undefined && obj.label ? obj.label : LL("FN_" + _f);
    const _values = getFilterValue(obj.field);
    const _v = _values.length ? _values[0] : "";
    const key = "filter_text" + i;
    
    return (
      <TextField
        key={key}
        type="text"
        name={_f}
        label={_l}
        autoFocus
        margin="dense"
        size="small"
        fullWidth
        value={_v}
        onChange={(e) => {
          updateFilterValue(obj, e.target.value);
        }}
        onKeyDown={handleKeyDown}
        variant="outlined"
      />
    );
  };
  // ------------------------------------

  // ------------------------------------
  const processFiltersElement_FiterTypeSelect = (obj: any, i: any) => {
    const _f = obj.field != undefined && obj.field ? obj.field : "";
    if (!_f) return <></>;

    const _l = obj.label != undefined && obj.label ? obj.label : LL("FN_" + _f);
    const _values = getFilterValue(obj.field);
    const _v = _values.length ? _values[0].toString() : "";

    const _ve = processOptions(obj.values);

    const key = "filter_select" + i;
    return (
      <Box className="filterBox" sx={{mt:2}}>
      <MySelect
        key={key}
        variant="outlined"
        options={_ve}
        _label={_l}
        setValue={(e) => {
          updateFilterValue(obj, e);
        }}
        value={_v}
      /></Box>
    );
  };
  // ------------------------------------

  // ------------------------------------
  const processFiltersElement_FiterTypeSelectMultiple = (obj: any, i: any) => {
    const _f = obj.field != undefined && obj.field ? obj.field : "";
    if (!_f) return <></>;

    const _l = obj.label != undefined && obj.label ? obj.label : LL("FN_" + _f);
    const _values = getFilterValue(obj.field);
    const _v = _values.length ? _values : [];

    const _ve = processOptions(obj.values);

    const key = "filter_select_multiple" + i;
    return (
      <Box className="filterBox" sx={{mt:2}}>
      <MySelect
        key={key}
        options={_ve}
        _label={_l}
        setValue={(e) => {
          updateFilterArr(obj, e);
        }}
        value={_v}
        multiple
        variant="outlined"
      /></Box>
    );
  };
  // ------------------------------------

  // ------------------------------------
  const processFiltersElement_FiterTypeRange = (obj: any, i: any) => {
    const _f = obj.field != undefined && obj.field ? obj.field : "";
    if (!_f) return <></>;

    const _l =
      obj.label != undefined && obj.label
        ? obj.label
        : LL("FN_" + _f + "_from");
    const _lto =
      obj.labelto != undefined && obj.labelto
        ? obj.labelto
        : LL("FN_" + _f + "_to");
    const _values = getFilterValue(obj.field);
    const _v = _values.length ? _values[0] : "";
    const _vto = _values.length > 1 ? _values[1] : "";

    const key = "filter_range" + i;
    const key2 = "filter_range" + i + 1;
    return (
      <>
        <Stack direction="row" sx={{ mt: 2 }} spacing={2}>
          <TextField
            key={key}
            type="text"
            name={_f}
            label={_l}
            autoFocus
            size="small"
            fullWidth
            value={_v}
            onChange={(e) => {
              updateFilterValueOnIndex(obj, e.target.value, 0);
            }}
            onKeyDown={handleKeyDown}
            variant="outlined"

          />
          <TextField
            key={key2}
            type="text"
            name={_f}
            label={_lto}
            autoFocus
            size="small"
            fullWidth
            value={_vto}
            onChange={(e) => {
              updateFilterValueOnIndex(obj, e.target.value, 1);
            }}
            onKeyDown={handleKeyDown}
            variant="outlined"

          />
        </Stack>
      </>
    );
  };
  // ------------------------------------

  // ------------------------------------
  const processFiltersElement_FiterTypeDate = (obj: any, i: any) => {
    const _f = obj.field != undefined && obj.field ? obj.field : "";
    if (!_f) return <></>;

    const _l = obj.label != undefined && obj.label ? obj.label : LL("FN_" + _f);
    const _values = getFilterValue(obj.field);
    const _v = _values.length ? DateTools.convertToDate(_values[0]) : null;

    const key = "filter_date" + i;
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          key={key}
          sx={{ mt: 1 }}
          label={_l}
          defaultValue={_v}
          onChange={(e: Date | null) => {
            updateFilterValue(obj, DateTools.getDateStr(e));
          }}
          format={DateTools.getDateFormat()}
        />
      </LocalizationProvider>
    );
  };
  // ------------------------------------

  // ------------------------------------
  const processFiltersElement_FiterTypeDateRange = (obj: any, i: any) => {
    const _f = obj.field != undefined && obj.field ? obj.field : "";
    if (!_f) return <></>;

    const _l =
      obj.label != undefined && obj.label
        ? obj.label
        : LL("FN_" + _f + "_from");
    const _lto =
      obj.labelto != undefined && obj.labelto
        ? obj.labelto
        : LL("FN_" + _f + "_to");
    const _values = getFilterValue(obj.field);
    const _v = _values.length ? DateTools.convertToDate(_values[0]) : null;
    const _vto =
      _values.length > 1 ? DateTools.convertToDate(_values[1]) : null;

    const key = "filter_date_range" + i;
    const key2 = "filter_date_range" + i + 1;
    return (

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DemoContainer sx={{ mt: 1 }} components={['DatePicker', 'DatePicker']}>
          <DatePicker
            key={key}
            label={_l}
            defaultValue={_v}
            onChange={(e: Date | null) => {
              updateFilterValueOnIndex(obj, DateTools.getDateStr(e), 0);
            }}
            format={DateTools.getDateFormat()}
          />
          <DatePicker
            key={key2}
            label={_lto}
            defaultValue={_vto}
            onChange={(e: Date | null) => {
              updateFilterValueOnIndex(obj, DateTools.getDateStr(e), 1);
            }}
            format={DateTools.getDateFormat()}
          />
        </DemoContainer>
      </LocalizationProvider>

    );
  };
  // ------------------------------------

  // ------------------------------------
  const processFiltersElement_FiterTypeDateTS = (obj: any, i: any) => {
    const _f = obj.field != undefined && obj.field ? obj.field : "";
    if (!_f) return <></>;

    const _l = obj.label != undefined && obj.label ? obj.label : LL("FN_" + _f);
    const _values = getFilterValue(obj.field);
    const _v = _values.length
      ? DateTools.convertTimestampToDate(parseInt(_values[0]))
      : null;
    const key = "filter_date_ts" + i;

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          key={key}
          sx={{ mt: 1 }}
          label={_l}
          defaultValue={_v}
          onChange={(e: Date | null) => {
            updateFilterValue(obj, DateTools.getDateTimeStamp(e)?.toString());
          }}
          format={DateTools.getDateFormat()}
        />
      </LocalizationProvider>
    );
  };
  // ------------------------------------

  // ------------------------------------
  const processFiltersElement_FiterTypeDateTSRange = (obj: any, i: any) => {
    const _f = obj.field != undefined && obj.field ? obj.field : "";
    if (!_f) return <></>;

    const _l =
      obj.label != undefined && obj.label
        ? obj.label
        : LL("FN_" + _f + "_from");
    const _lto =
      obj.labelto != undefined && obj.labelto
        ? obj.labelto
        : LL("FN_" + _f + "_to");
    const _values = getFilterValue(obj.field);
    const _v = _values.length
      ? DateTools.convertTimestampToDate(parseInt(_values[0]))
      : null;
    const _vto =
      _values.length > 1
        ? DateTools.convertTimestampToDate(parseInt(_values[1]))
        : null;

    const key = "filter_date_ts_range" + i;
    const key2 = "filter_date_ts_range" + i + 1;
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DemoContainer sx={{ mt: 1 }} components={['DatePicker', 'DatePicker']}>
          <DatePicker
            key={key}
            label={_l}
            defaultValue={_v}
            onChange={(e: Date | null) => {
              updateFilterValueOnIndex(
                obj,
                DateTools.getDateTimeStamp(e)?.toString(),
                0
              );
            }}
            format={DateTools.getDateFormat()}
          />
          <DatePicker
            key={key2}
            label={_lto}
            defaultValue={_vto}
            onChange={(e: Date | null) => {
              updateFilterValueOnIndex(
                obj,
                DateTools.getDateTimeStamp(e)?.toString(),
                1
              );
            }}
            format={DateTools.getDateFormat()}
          />
        </DemoContainer>
      </LocalizationProvider>
    );
  };
  // ------------------------------------

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box tabIndex={tabIndex}>
      <IconButton
        aria-label="filter"
        color="inherit"
        aria-describedby={id}
        onClick={handleClick}
        {...props}
      >
        <FilterAltOutlinedIcon fontSize="inherit" />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{ m: 2, width: '500px' }}>

          {_tbFilterList.map((obj: TbFilterDTO, i: any) => {
            return processFiltersElement(obj, i);
          })}

          <Stack direction="row" sx={{ mt: 2 }} spacing={1}>
            <MyButton
              tabIndex={2}
              cb={handleApply}
              color="primary"
              children={LL("bt_Apply")}
            />

            <MyButton
              tabIndex={2}
              cb={handleClear}
            >
              <IconButton aria-label="clear" size="small">
                <HighlightOffIcon sx={{ color: "white" }} />
              </IconButton>
            </MyButton>
          </Stack>
        </Box>
      </Popover>
    </Box>
  );
};

export { TableToolbar };
