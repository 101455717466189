
import { ServerSelect } from "components/elements/Select/newSelect/ServerSelect";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { FilterDto } from "dto/course/filter.dto";

import React from "react";
import { FilterService } from "services/course/filter.service";

import { Status } from "tools/types/status";
import { Types } from "tools/types/types";

type Props = {
  field?: string;
  value?: number | string;
  setObjectField: (field: string, value: any) => void;
};
const service = new FilterService();
const SelectFilter: React.FC<Props> = ({
  field = "idfilter",
  value,
  setObjectField,
}) => {
  const getList = (cb?: any, cbParams?: any, data?: RequestListDTO) => {
    if (!data) data = new RequestListDTO();
    data.filters = [
      RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
      RequestFilterDTO.prepareFilter("type", [
        Types.FILTER_TYPE_DICTIONARY.toString(),
      ]),
    ];
    service.getList(cb, cbParams, data);
  };

  return (
    <ServerSelect
      processOptions={FilterDto.parseToSelectOptions}
      getList={getList}
      field={field}
      setObjectField={setObjectField}
      value={value}
      label=""
    />
  );
};

export { SelectFilter };
