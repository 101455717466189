import { Close, PlayCircleOutline } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useResource } from "hooks/useResource";

import { VideoDto } from "dto/system/video.dto";
import { TeacherMediaVideoDto } from "dto/user/teacher.dto";
import { useAuth } from "hooks/useAuth";
import React, { useState } from "react";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { Config } from "tools/utils/config";
import { VideoTools } from "tools/utils/videotools";
import { FormTeacherVideo } from "./FormTeacherVideo";
import { useResponsive } from "hooks/useResponsive";
import PermMediaIcon from "@mui/icons-material/PermMedia";

type Props = {
  video: VideoDto | null;
  label: string;
  onSubmit: (obj: TeacherMediaVideoDto) => void;
  onDelete: () => void;
  loading: boolean;
};

const TeacherVideo: React.FC<Props> = ({
  video,
  label,
  onSubmit,
  onDelete,
  loading,
}) => {
  const { teacher } = useAuth();
  const { LL } = useResource();

  const idTeacher = CommonTools.processObjectField(teacher, ["id"]);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const processVideoDisplay = () => {
    if (!open) return null;
    if (!video) return null;

    return ComponentCommonTools.getFileBlockVideo(video, 550, 400, true);
  };

  const { downSM } = useResponsive();

  const processImageVideo = () => {
    if (!video) return null;
    let url = VideoTools.getVideoImageUrl(video);
    if (!url) url = Config.NO_IMAGE_URL;
    let videoHeight = "174px";
    if (downSM) videoHeight = "25vh";

    return (
      <Box
        sx={{ width: { sm: "284px" }, px: { sm: 1 } }}
        display="flex"
        flexDirection="column"
        alignItems="center">
        <Box height={videoHeight} width={"100%"} position="relative" mt={1}>
          <img
            src={url}
            height={"100%"}
            width={"100%"}
            alt="videoimage"
            style={{ position: "absolute", zIndex: 0, borderRadius: "5px" }}
          />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            zIndex={1}>
            <IconButton onClick={handleClickOpen} size="large">
              <PlayCircleOutline
                fontSize="large"
                sx={{
                  color: "white",
                  fontSize: "60px",
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <Typography variant="h6" mt={1} textAlign="left" minWidth={"100%"}>
          {ComponentCommonTools.processObjectField(video, "name")}
        </Typography>
      </Box>
    );
  };
  const processVideo = () => {
    if (!video) return null;
    let url = VideoTools.getVideoImageUrl(video);
    if (!url) url = Config.NO_IMAGE_URL;
    if (open)
      return (
        <Box sx={{ width: { sm: "284px" } }}>
          <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle>
              {CommonTools.processObjectField(video, ["name"])}
            </DialogTitle>
            <IconButton
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
              onClick={handleClose}>
              <Close />
            </IconButton>
            <DialogContent dividers>{processVideoDisplay()}</DialogContent>
          </Dialog>
        </Box>
      );
  };

  if (!idTeacher) return null;
  return (
    <Box className="videoTeacher">
      <Box sx={{ flexGrow: 1, mt: 1 }}>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <Typography variant="h6">{label}</Typography>
        </Box>
        <Box>
          <FormTeacherVideo
            onDelete={onDelete}
            onSubmit={onSubmit}
            loading={loading}
            defaultObj={
              new TeacherMediaVideoDto(
                idTeacher,
                CommonTools.processObjectField(video, ["videolocation"])
              )
            }
            canDelete={video !== null}
          />
        </Box>
      </Box>
      <Box>
        {video ? (
          processImageVideo()
        ) : (
          <Box className="fileLinkBox">
            <Box className="fileLinkBoxEmpty">
              <PermMediaIcon />
              {LL("no_video")}
            </Box>
          </Box>
        )}
        {processVideo()}
      </Box>
      <Box sx={{ display: { xs: "block", sm: "none" }, mb: 1 }}>
        <Typography variant="h6">{label}</Typography>
      </Box>
    </Box>
  );
};

export { TeacherVideo };
