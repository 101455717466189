import React, { useState } from "react";
import { useResource } from "hooks/useResource";
import { useAuth } from "hooks/useAuth";

import PageComponentProps from "interfaces/pagecomponentprops.interface";

import Container from "@mui/material/Container";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import { FormResetPassword } from "components/auth/FormResetPassword";
import { ResetPasswordDto } from "dto/user/user.dto";
import { CommonTools } from "tools/utils/commontools";
import { UserService } from "services/user/user.service";
import ResultSignInDTO from "dto/auth/resultsignin.dto";
import { MyBackdrop } from "components/elements/loading/MyBackdrop";
import { MyButton } from "components/elements/button/MyButton";
import ForgotPasswordDto from "dto/auth/forgotpassword.dto";

const service = new UserService();
const ResetPassword: React.FC<PageComponentProps> = ({ currentRoute }) => {
  const email = decodeURIComponent(
    CommonTools.getIdFromPath(currentRoute, "resetpassword")
  );
  const code = CommonTools.getIdFromPath(
    currentRoute,
    "resetpassword",
    undefined,
    2
  );
  const validInfo = email && code;
  const { handleResultAuth } = useAuth();

  const { LL } = useResource();

  const [defaultObject, setDefaultObject] = useState<ResetPasswordDto>(
    new ResetPasswordDto(email, code)
  );
  const [loading, setLoading] = useState<boolean>(false);
  const handleSubmit = (obj: ResetPasswordDto) => {
    setLoading(true);
    service.resetPassword(obj, handleResult, {});
  };
  const handleResult = (result: ResultSignInDTO) => {
    if (!result) {
      cleanForm();
      return;
    }
    if (result.err) {
      cleanForm();
      return;
    }
    handleResultAuth(result, { remember: true, defaultRoute: true });
  };

  const cleanForm = () => {
    setDefaultObject(new ResetPasswordDto(email, code));
    setLoading(false);
  };

  const onClick = () => {
    service.resetPasswordCode(new ForgotPasswordDto(email));
  };
  return (
    <Box className="authMaineBox">
      <Container maxWidth="xs">
        <Box
          mt={5}
          sx={{
            alignItems: "center",
            alignContent: "center",
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="h2" align="center" sx={{ mb: 5 }}>
            {LL("reset_password")}
          </Typography>

          {validInfo ? (
            <Box>
              <FormResetPassword
                onSubmit={handleSubmit}
                defaultObject={defaultObject}
                loading={loading}
              />
              <Box>
                <Typography>{LL("resend_code_info")}</Typography>
                <Box>
                  <MyButton cb={onClick} type="button">
                    {LL("resend_code")}
                  </MyButton>
                </Box>
              </Box>
            </Box>
          ) : (
            !loading && (
              <Box>
                <Typography variant="h6" color={"error"} align="center">
                  {LL("invalid_reset_link")}
                </Typography>
              </Box>
            )
          )}
        </Box>
      </Container>
      <MyBackdrop open={loading} />
    </Box>
  );
};

export { ResetPassword };
