import SelectOptions from "dto/app/selectoptions.dto";
import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { CommonTools } from "tools/utils/commontools";

export class PostAddressDto implements Idto {
  status?: number;
  street?: string;
  zipcode?: string;
  streetnumber?: number;
  idlocality?: string;
  iduser?: string;
  locality?: any;
}

export class AddressDto implements Idto {
  id?: string;
  status?: number;
  street?: string;
  zipcode?: string;
  streetnumber?: number;
  idlocality?: string;
  iduser?: string;
  locality?: any;

  static parseToSelectOptions(data: Array<AddressDto>): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(AddressDto.parseOption(element));
    });
    return result;
  }

  static parseOption = (obj: AddressDto): SelectOptions => {
    return new SelectOptions(
      CommonTools.processObjectField(obj, ["id"]),
      `${CommonTools.processObjectField(obj, [
        "locality",
        "name",
      ])},${CommonTools.processObjectField(obj, [
        "street",
      ])},${CommonTools.processObjectField(obj, ["streetnumber"])}`
    );
  };
}

export class AddressFormDto implements Idto {
  id?: string;
  status?: number;
  street?: string;
  zipcode?: string;
  streetnumber?: number;
  idlocality?: string;
  iduser?: string;
  locality?: any;

  constructor(
    id?: string,
    status?: number,
    street?: string,
    zipcode?: string,
    streetnumber?: number,
    idlocality?: string,
    iduser?: string,
    locality?: any
  ) {
    this.id = id || "";
    this.status = status || Status.ACTIVE;
    this.street = street || "";
    this.zipcode = zipcode || "";
    this.streetnumber = streetnumber || 0;
    this.idlocality = idlocality || "";
    this.iduser = iduser || "";
    this.locality = locality || null;
  }

  static parseFromAddressDto(obj?: AddressDto): AddressFormDto {
    if (!obj) return new AddressFormDto();

    return new AddressFormDto(
      obj.id,
      obj.status,
      obj.street,
      obj.zipcode,
      obj.streetnumber,
      obj.idlocality,
      obj.iduser,
      obj.locality
    );
  }
}
