import React, { useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

import Box from "@mui/material/Box";

import { TeacherService } from "services/user/teacher.service";
import { TeacherContractDto } from "dto/user/teacher.dto";
import { useAuth } from "hooks/useAuth";

import { CommonTools } from "tools/utils/commontools";
import { CircularLoading } from "components/elements/loading/CircularLoading";

import ResultObjectDTO from "dto/app/resultobject.dto";

import { useTeacherInfo } from "context/teacherinfo.context";
import { FormEditTeacherContract } from "./FormEditTeacherContract";
import { Stack, Typography } from "@mui/material";
import { useResource } from "hooks/useResource";
import { ValidationStatusChip } from "../teacherinterface/ValidationStatusChip";

const service = new TeacherService();

const EditTeacherContract: React.FC<PageComponentProps> = () => {
  const { user } = useAuth();
  const [loadingAction, setLoadingAction] = useState<boolean>(false);
  const { LL } = useResource();
  const { teacherInfo, setTeacherInfo, loading } = useTeacherInfo();
  const onSubmit = (obj: TeacherContractDto) => {
    setLoadingAction(true);
    service.updateContract(
      CommonTools.processObjectField(obj, ["id"]),
      handleResult,
      {},
      obj
    );
  };

  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    setTeacherInfo(result.obj);
    setLoadingAction(false);
  };

  if (!user) return null;
  if (loading) return <CircularLoading />;
  if (!teacherInfo) return null;
  return (
    <Box maxWidth="sm" mx="auto" mt={3}>
      <Box>
        <Stack direction="row" spacing={2} alignContent={"center"}>
          <Typography variant="h6">{LL("status_validation")}</Typography>

          <ValidationStatusChip
            value={parseInt(
              CommonTools.processObjectField(teacherInfo, ["statusvalidation"])
            )}
          />
        </Stack>
      </Box>
      <Box>
        <FormEditTeacherContract
          defaultObject={TeacherContractDto.parseFromTeacherDto(teacherInfo)}
          onSubmit={onSubmit}
          loading={loading || loadingAction}
        />
      </Box>
    </Box>
  );
};

export { EditTeacherContract };
