import { Box, Grid, Typography } from "@mui/material";
import { MyCheckboxField } from "components/form/MyCheckboxField";
import MyTextField from "components/form/mytextfield";

import { RegisterDto } from "dto/auth/registeruser.dto";

import { useForm } from "hooks/useForm";
import { useGoogleRecaptcha } from "hooks/useGoogleRecaptcha";
import { useResource } from "hooks/useResource";
import React, { useCallback, useEffect, useState } from "react";
import RequiredValidator from "validators/required.validator";
import { PasswordStrengthChecker } from "./PasswordStrengthChecker";
import { CountryCodeSelect } from "components/elements/Select/CountryCodeSelect";
import MyDatePicker from "components/elements/MyDatePicker/MyDatePicker";
import { NewLanguageSelect } from "components/static/language/NewLanguageSelect";
import { PasswordField } from "components/form/PasswordField";

import { MyButton } from "components/elements/button/MyButton";
import { StaticSelect } from "components/elements/Select/newSelect/StaticSelect";
import { Types } from "tools/types/types";
import { SpecialtyTypeSelect } from "components/elements/Select/SpecialtyTypeSelect";
import { StudyTypeSelect } from "components/elements/Select/StudyTypeSelect";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { SignUpInfoBlock } from "components/static/page/content/SignUpInfoBlock";

type Props = {
  onSubmit: (obj: RegisterDto) => void;
};

const FormSignUp: React.FC<Props> = ({ onSubmit }) => {
  const { LL, _idlanguage } = useResource();
  const { executeRecaptcha } = useGoogleRecaptcha();
  const [term, setTerm] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [disabledCheck, setDisabledCheck] = useState<boolean>(false);
  const [disabledPass, setDisabledPass] = useState<boolean>(false);
  const [obj, disabled, setObjField] = useForm<RegisterDto>(
    new RegisterDto(_idlanguage),
    RequiredValidator.getValidators(["email", "password"])
  );

  const steps = [LL("Step_1_register"), LL("Step_2_register")];
  const [activeStep, setActiveStep] = useState(0);

  const checkIsFormDisabled = useCallback(() => {
    let disabled = false;

    if (!term) {
      disabled = true;
    }
    setDisabledCheck(disabled);
  }, [term]);

  const checkIsFormDisabledPass = useCallback(() => {
    let disabled = false;
    if (obj.password !== confirmPassword) {
      disabled = true;
    }

    setDisabledPass(disabled);
  }, [confirmPassword, obj.password]);

  useEffect(() => {
    checkIsFormDisabledPass();
  }, [checkIsFormDisabledPass]);

  useEffect(() => {
    checkIsFormDisabled();
  }, [checkIsFormDisabled]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled || disabledCheck) return;
    await executeRecaptcha();
    onSubmit(obj);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <React.Fragment>
            <Box mb={4}>
              <Typography variant="h6">{LL("Surname")}</Typography>
              <MyTextField
                fullWidth
                id="surname"
                name="surname"
                className="form-input"
                variant="standard"
                _label={LL("SurnameExample")}
                value={obj.surname}
                setObj={setObjField}
                _vresults={undefined}
              />
            </Box>
            <Box mb={4}>
              <Typography variant="h6">{LL("Name")}</Typography>
              <MyTextField
                fullWidth
                id="name"
                name="name"
                className="form-input"
                variant="standard"
                _label={LL("NameExample")}
                value={obj.name}
                setObj={setObjField}
                _vresults={undefined}
              />
            </Box>
            <Box mb={4}>
              <Typography variant="h6">{LL("Email")}</Typography>
              <MyTextField
                fullWidth
                id="email"
                name="email"
                className="form-input"
                variant="standard"
                _label={LL("EmailExample")}
                value={obj.email}
                setObj={setObjField}
                _vresults={undefined}
              />
            </Box>
            <Box mb={1}>
              <Typography variant="h6">{LL("Password")}</Typography>
              <PasswordField
                fullWidth
                id="password"
                name="password"
                className="form-input"
                variant="standard"
                _label={LL("Password")}
                value={obj.password}
                setObj={setObjField}
                _vresults={undefined}
              />
            </Box>
            <Box mb={3}>
              <PasswordStrengthChecker password={obj.password} />
            </Box>
            <Box mb={3}>
              <Typography variant="h6">{LL("Confirm Password")}</Typography>
              <PasswordField
                fullWidth
                id="confirmPassword"
                name="confirmPassword"
                className="form-input"
                variant="standard"
                _label={LL("Confirm Password")}
                value={confirmPassword}
                setObj={(field: any, value: any) => setConfirmPassword(value)}
                _vresults={undefined}
              />
            </Box>
            <Box mt={2} className="checkTerm" mb={2}>
              <MyCheckboxField
                id="notificationtype1"
                name="notificationtype1"
                label={LL("notificationtype1")}
                field="notificationtype1"
                setObjectField={setObjField}
                checked={obj.notificationtype1}
                color="primary"
                size="small"
              />
            </Box>
          </React.Fragment>
        );
      case 1:
        return (
          <React.Fragment>
            <Box>
              <Typography variant="h6">{LL("phone number")}</Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <CountryCodeSelect
                    value={obj.countrycode}
                    field="countrycode"
                    size="small"
                    variant="standard"
                    color="primary"
                    className="form-select"
                    setObj={setObjField}
                    obj={obj}
                  />
                </Grid>
                <Grid item xs={8}>
                  <MyTextField
                    tabIndex={1}
                    fullWidth
                    id="phonenumber"
                    name="phonenumber"
                    className="form-input"
                    variant="standard"
                    _label={LL("phonenumber")}
                    value={obj.phonenumber}
                    setObj={setObjField}
                    _vresults={undefined}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt={2}>
              <Typography variant="h6">{LL("birthday")}</Typography>
              <MyDatePicker
                tabIndex={3}
                field="birthday"
                _defaultValue={obj.birthday}
                className="form-picker"
                LL={LL}
                setObjField={setObjField}
              />
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">{LL("language")}</Typography>
              <NewLanguageSelect
                value={obj.idlanguage}
                setObjectField={setObjField}
                label=""
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6">{LL("Type_account")}</Typography>
              <StaticSelect
                service={Types}
                value={obj.typeaccount}
                setObjField={setObjField}
                field="typeaccount"
                label=""
                type="typeaccount"
              />
            </Box>
            <Box mt={2}>
              {obj.typeaccount?.toString() ===
              Types.TYPE_ACCOUNT_STUDENT.toString() ? (
                <Box>
                  <Typography variant="h6">{LL("TypeStudies")}</Typography>
                  <StudyTypeSelect
                    value={obj.idtypestudies}
                    setObjectField={setObjField}
                    label=""
                  />
                </Box>
              ) : (
                <Box>
                  <Typography variant="h6">{LL("TypeSpecialty")}</Typography>
                  <SpecialtyTypeSelect
                    value={obj.idtypespecialty}
                    setObjectField={setObjField}
                    label=""
                  />
                </Box>
              )}
            </Box>
            <Box mt={2} className="checkTerm" mb={2}>
              <MyCheckboxField
                id="term"
                name="term"
                label={<SignUpInfoBlock />}
                field="term"
                setObjectField={(field: string, value: any) => {
                  setTerm(value);
                }}
                checked={term}
                color="primary"
                size="small"
              />
            </Box>
          </React.Fragment>
        );
      default:
        return null;
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ width: "100%" }}>
        <Box>{getStepContent(activeStep)}</Box>
        <Box>
          {activeStep === steps.length - 1 ? (
            <MyButton
              disabled={disabled || disabledCheck}
              color="primary"
              fullWidth
              variant="contained"
              type="submit"
              className="btn"
            >
              {LL("Bt_SignUp")}
            </MyButton>
          ) : (
            <MyButton
              disabled={disabled || disabledPass}
              color="primary"
              fullWidth
              variant="contained"
              type="button"
              className="btn"
              cb={handleNext}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {LL("Next")}
                <KeyboardDoubleArrowRightIcon sx={{ mt: "1px" }} />
              </Box>
            </MyButton>
          )}
        </Box>
      </Box>
    </form>
  );
};

export { FormSignUp };
