import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hooks/useResource";

import { RouteTools } from "tools/utils/routetools";
import RequestListDTO from "dto/app/requestlist.dto";

import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Box, Typography, Grid, Skeleton } from "@mui/material";

import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { MyPagination } from "components/elements/MyPagination/MyPagination";
import { useCookies } from "react-cookie";

import { MySortSelect } from "components/elements/Select/MySortSelect";
import { NewsletterBlock } from "components/general/NewsletterBlock";
import { TeacherDto } from "dto/user/teacher.dto";
import { TeacherService } from "services/user/teacher.service";

import { TeacherListCard } from "./TeacherListCard";
import { FilterBoxTeacher } from "components/course/filter/FilterBoxTeacher";
import { NoResult } from "components/general/NoResult";

const teacherService = new TeacherService();
const TeacherList: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { LL, CC } = useResource();
  const mainUrl =
    currentRoute && currentRoute.url ? currentRoute.url.split("?") : "";
  const [isLoading, setIsLoading] = useState(true);
  const [objects, setObjects] = useState<Array<TeacherDto>>([]);
  const [cookies, setCookies] = useCookies();
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(-1);
  const [totalPage, setTotalPage] = useState<number>(-1);
  const [responseParams, setResponseParams] = useState<any>({});
  const [onPage, setOnPage] = useState<number>(
    parseInt(CC("TeacherListOnPage", "10"))
  );
  const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());

  const [sortCriteria, setSortCriteria] = useState<
    Array<RequestSortCriteriaDTO>
  >(new Array<RequestSortCriteriaDTO>());
  const [filterRating, setFilterRating] = useState<
    RequestFilterDTO | undefined
  >();

  const [filterDisciplineValue, setFilterDisciplineValue] = useState<
    Array<string>
  >([]);
  const [
    filterTypeProfessionalismLevelValue,
    setFilterTypeProfessionalismLevelValue,
  ] = useState<Array<string>>([]);
  const [filterTypeTargetGroupValue, setFilterTypeTargetGroupValue] = useState<
    Array<string>
  >([]);
  const [filterTypeExperienceValue, setFilterTypeExperienceValue] = useState<
    Array<string>
  >([]);

  const getList = () => {
    setIsLoading(true);

    reqList.onpage = onPage;
    reqList.page = page;
    reqList.filters = [];
    reqList.sortcriteria = sortCriteria;

    let f: RequestFilterDTO;
    f = new RequestFilterDTO();
    f.field = "idtypediscipline";
    f.values = filterDisciplineValue;
    reqList.filters.push(f);

    f = new RequestFilterDTO();
    f.field = "idtypeprofessionalismlevel";
    f.values = filterTypeProfessionalismLevelValue;
    reqList.filters.push(f);

    f = new RequestFilterDTO();
    f.field = "idtypetargetgroup";
    f.values = filterTypeTargetGroupValue;
    reqList.filters.push(f);

    f = new RequestFilterDTO();
    f.field = "idtypeexperience";
    f.values = filterTypeExperienceValue;
    reqList.filters.push(f);

    if (filterRating) reqList.filters.push(filterRating);
    else {
      const filterRating = new RequestFilterDTO();
      filterRating.field = "fromteacherrating";
      filterRating.values = ["0"];
      setFilterRating(filterRating);
      reqList.filters.push(filterRating);
    }

    const urlPart = RouteTools.prepareListLocation(reqList);

    RouteTools.setHistory(mainUrl[0] + urlPart, {});
    teacherService.getList(loadObjects, {}, reqList);
  };

  const defaultSortCriteria = () => {
    if (!sortCriteria || sortCriteria.length === 0) {
      const sortCriteria = new RequestSortCriteriaDTO();
      sortCriteria.field = "rating";
      sortCriteria.asc = false;
      setSortCriteria([sortCriteria]);
    }
  };

  useEffect(() => {
    const reqListRoute = RouteTools.prepareListRequest(currentRoute, cookies);
    if (reqListRoute.page) setPage(reqListRoute.page);
    if (reqListRoute.filters && reqListRoute.filters.length > 0) {
      const existingFilter = reqListRoute.filters.find(
        (filter) => filter.field === "fromteacherrating"
      );
      setFilterRating(existingFilter);
      const existingFilterDiscipline = reqListRoute.filters.find(
        (filter) => filter.field === "idtypediscipline"
      );
      if (existingFilterDiscipline && existingFilterDiscipline.values)
        setFilterDisciplineValue(existingFilterDiscipline.values);
      const existingFilterTypeProfessionalismLevel = reqListRoute.filters.find(
        (filter) => filter.field === "idtypeprofessionalismlevel"
      );
      if (
        existingFilterTypeProfessionalismLevel &&
        existingFilterTypeProfessionalismLevel.values
      )
        setFilterTypeProfessionalismLevelValue(
          existingFilterTypeProfessionalismLevel.values
        );
      const existingFilterTypeTargetGroup = reqListRoute.filters.find(
        (filter) => filter.field === "idtypetargetgroup"
      );
      if (existingFilterTypeTargetGroup && existingFilterTypeTargetGroup.values)
        setFilterTypeTargetGroupValue(existingFilterTypeTargetGroup.values);
      const existingFilterTypeExperience = reqListRoute.filters.find(
        (filter) => filter.field === "idtypeexperience"
      );
      if (existingFilterTypeExperience && existingFilterTypeExperience.values)
        setFilterTypeExperienceValue(existingFilterTypeExperience.values);
    }
    if (reqListRoute.sortcriteria && reqListRoute.sortcriteria.length > 0) {
      setSortCriteria(reqListRoute.sortcriteria);
    } else {
      defaultSortCriteria();
    }
  }, []);

  const loadObjects = (result: ResultListDTO) => {
    if (!result) return;
    if (!result.err) {
      const objects = result.objects ? result.objects : [];
      const total = result.total !== undefined ? result.total : -1;
      const totalPage =
        result.totalpages !== undefined ? result.totalpages : -1;
      const reqInfo = result.requestinfo ? result.requestinfo : {};
      setObjects(objects);
      setTotal(total);
      setTotalPage(totalPage);
      setResponseParams(reqInfo);
    }
  };

  const checkLoading = () => {
    let load = false;

    if (total === -1) return true;
    if (totalPage === -1) return true;
    if (!objects) load = true;
    if (Object.keys(responseParams).length === 0) return true;

    setIsLoading(load);
  };
  useEffect(() => {
    if (sortCriteria && sortCriteria.length > 0) getList();
  }, [
    page,
    sortCriteria,
    filterDisciplineValue,
    filterRating,
    filterTypeProfessionalismLevelValue,
    filterTypeTargetGroupValue,
    filterTypeExperienceValue,
  ]);

  useEffect(() => {
    checkLoading();
  }, [objects]);

  const processPagination = () => {
    if (totalPage === -1) return null
    if (total === -1) return null
    if (objects.length === 0) return null

    return (
      <Box
        className="container"
        py={4}
        display="flex"
        sx={{ justifyContent: "center" }}
      >
        <MyPagination
          page={page}
          total={total}
          totalPage={totalPage}
          setPage={setPage}
        />
      </Box>
    );
  };

  const processSortSelect = () => {
    if (isLoading) return null

    const objects = ["rating_asc", "rating_desc"];
    return (
      <Box
        mb={4}
        sx={{
          "& label": {
            display: "none",
          },
          maxWidth: "300px",
        }}
      >
        <MySortSelect
          objects={objects}
          setSortCriteria={setSortCriteria}
          sortCriteria={sortCriteria}
          variant="standard"
          className="form-select"
        />
      </Box>
    );
  };

  const processNumberOfResults = () => {
    if (isLoading) return null
    if (total === -1) return null
    return (
      <Box>
        <Typography>
          {total} {LL("Results")}
        </Typography>
      </Box>
    );
  };

  const processFilterBox = () => {
    return (
      <FilterBoxTeacher
        currentRoute={currentRoute}
        numberOfResults={total}
        setFilterRating={setFilterRating}
        filtersRating={filterRating}
        filterDisciplineValue={filterDisciplineValue}
        setFilterDisciplineValue={setFilterDisciplineValue}
        filterTypeProfessionalismLevelValue={
          filterTypeProfessionalismLevelValue
        }
        setFilterTypeProfessionalismLevelValue={
          setFilterTypeProfessionalismLevelValue
        }
        filterTypeTargetGroupValue={filterTypeTargetGroupValue}
        setFilterTypeTargetGroupValue={setFilterTypeTargetGroupValue}
        filterTypeExperienceValue={filterTypeExperienceValue}
        setFilterTypeExperienceValue={setFilterTypeExperienceValue}
      />
    );
  };

  const processTeacherItem = (obj: TeacherDto, index: number) => {
    if (!obj) return null
    return (
      <Box key={index}>
        <TeacherListCard obj={obj} />
      </Box>
    );
  };

  const processTeacherList = () => {
    if (!objects.length) return <NoResult identifier="teacher" />;

    return (
      <>
        {objects.map((item: TeacherDto, i: number) => {
          return processTeacherItem(item, i);
        })}
      </>
    );
  };

  return (
    <Box>
      <Box className="container" py={5}>
        <Typography variant="h2" mb={4}>
          {" "}
          {LL("ListTeacher")}
        </Typography>
        <Grid container spacing={2} mt={4}>
          <Grid item sm={12} lg={3}>
            {processFilterBox()}
          </Grid>
          <Grid item sm={12} lg={9}>
            {processSortSelect()}
            {processNumberOfResults()}
            {isLoading ? <Skeleton /> : processTeacherList()}
            {isLoading ? <Skeleton /> : processPagination()}
          </Grid>
        </Grid>
      </Box>
      <Box>
        <NewsletterBlock currentRoute={currentRoute} />
      </Box>
    </Box>
  );
};

export { TeacherList };
