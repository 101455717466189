import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import { TestResultQuestionRepository } from "repositories/course/testresultquestion.repository";
import RequestListDTO from "dto/app/requestlist.dto";

export class TestResultQuestionService
  extends GeneralService<TestResultQuestionRepository>
  implements IService
{
  constructor() {
    super(new TestResultQuestionRepository(), [], "_id");
  }

  getListForTeacher = async (
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getListForTeacher(
      this.handleGetList,
      cbparameters,
      data
    );
    if (cb === undefined) {
      return this.handleGetList(t);
    }
  };

  addWithAnswers = async (
    cb?: any,
    cbparameters?: any,
    data?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    const t = await this.repository.addWithAnswers(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  addWithAnswersNew = async (
    cb?: any,
    cbparameters?: any,
    data?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    const t = await this.repository.addWithAnswersNew(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  
}
// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// // import {TestResultQuestionDto} from "dto/course/testresultquestion.dto";
// import ResultListDTO from "dto/app/resultlist.dto";
// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import {TestResultQuestionRepository} from "repositories/course/testresultquestion.repository";
// import { Status } from "tools//types/status";
// import GeneralService from "services/general.service";

// export default class TestResultQuestionService extends GeneralService implements IService {
//     testResultQuestionRepository: TestResultQuestionRepository = new TestResultQuestionRepository();
//     constructor() {
//         super();
//         this.handleGetList = this.handleGetList.bind(this);
//         this.handleGet = this.handleGet.bind(this);
//     }
//     handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;

//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     async update(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {

//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         this.testResultQuestionRepository.update(id, this.handleUpdate, data, cbparameters);
//     }

//     handleDelete(result?: any, cbparameters?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters);
//         }
//     }

//     async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.testResultQuestionRepository.delete(id, this.handleDelete, cbparameters);
//     }

//     handleGet(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//     return rez;
//     }

//     async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.testResultQuestionRepository.get(id, this.handleGet, cbparameters);
//         if (cb == undefined)
//         {
//             return this.handleGet(t);
//         }
//     }

//     handleGetList(result?: any, cbparameters?: any, data?: any): any {

//         let rez = new ResultListDTO();

//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//         return rez;
//     }

//     async getList(
//         cb?: any,
//         cbparameters?: any,
//         data?: RequestListDTO
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.testResultQuestionRepository.getList(this.handleGetList, cbparameters, data);
//         if (cb == undefined)
//         {
//             return this.handleGetList(t);
//         }
//     }

//     async getListForTeacher(
//         cb?: any,
//         cbparameters?: any,
//         data?: RequestListDTO
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.testResultQuestionRepository.getListForTeacher(this.handleGetList, cbparameters, data);
//         if (cb == undefined)
//         {
//             return this.handleGetList(t);
//         }
//     }

//     handleAdd(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     async add(
//         cb?: any,
//         cbparameters?: any,
//         data?: any
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.testResultQuestionRepository.add(this.handleGetList, cbparameters, data);
//     }

//     async addWithAnswers(
//         cb?: any,
//         cbparameters?: any,
//         data?: any
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.testResultQuestionRepository.addWithAnswers(this.handleAdd, cbparameters, data);
//     }

//     getDefaultRequestList(onPage?: number): RequestListDTO {
//         onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
//         if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
//         var defaultRequestList = new RequestListDTO();
//         defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
//         defaultRequestList.onpage = onPage;
//         // var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
//         // defaultRequestSortCriteria.asc = true;
//         // defaultRequestSortCriteria.field = 'value';
//         // defaultRequestList.sortcriteria = []
//         // defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
//         return defaultRequestList;
//     }
// }
