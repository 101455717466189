import React from "react";

import { CourseEnrollmentDto } from "dto/course/courseenrollment.dto";
import { Status } from "tools//types/status";
import { Box, Typography } from "@mui/material";
import { useResource } from "hooks/useResource";
type Props = {
  obj: CourseEnrollmentDto;
};

const CourseEnrollmentStatus: React.FC<Props> = ({
  obj,
}) => {
  const { LL } = useResource();

  const processItem = () => {
    if (
      obj.paymentstatus === Status.PAYMENT_UNPAID &&
      obj.status === Status.ENR_NEED_CHECKOUT
    ) {
      return (
        <Box>
          <Typography>
            {LL("The_payment_was_not_finalized_do_it_now")}
          </Typography>
        </Box>
      );
    }

    if (
      obj.paymentstatus === Status.PAYMENT_UNPAID &&
      obj.status === Status.ENR_NEED_START_TEST
    ) {
      return (
        <Box>
          <Typography>
            {LL("You_should_pass_the_test_before_buying_the_course")}
          </Typography>
        </Box>
      );
    }

    if (obj.paymentstatus === Status.PAYMENT_REFUND) {
      return (
        <Box>
          <Typography>{LL("The_payment_was_refunded")}</Typography>
        </Box>
      );
    }

    return null;
  };
  return processItem();
};

export { CourseEnrollmentStatus };
