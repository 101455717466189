import { Edit, Delete } from "@mui/icons-material";
import { Box, Divider, Grid, Typography } from "@mui/material";

import { MyButton } from "components/elements/button/MyButton";
import PhoneDto from "dto/user/phone.dto";
import React from "react";
import { CommonTools } from "tools/utils/commontools";

type Props = {
  obj: PhoneDto;
  handleUpdate: (obj: PhoneDto) => void;
  handleDelete: (obj: PhoneDto) => void;
};

const PhoneItem: React.FC<Props> = ({ obj, handleUpdate, handleDelete }) => {
  if (!obj) return null;
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container py={2} spacing={2}>
        <Grid item xs={10}>
          <Typography>
            {CommonTools.processObjectField(obj, ["countrycode"])}{" "}
            {CommonTools.processObjectField(obj, ["phonenumber"])}{" "}
          </Typography>
        </Grid>
        {/* <Grid item xs={4}>
          <Typography>
            {CommonTools.processObjectField(obj, ["typephone", "name"])}
          </Typography>
        </Grid> */}
        <Grid item xs={2} ml={0}>
          <MyButton
            type="button"
            cb={handleUpdate.bind(this, obj)}
            color="primary"
          >
            <Edit />
          </MyButton>

          <MyButton
            color="secondary"
            type="button"
            cb={handleDelete.bind(this, obj)}
          >
            <Delete />
          </MyButton>
        </Grid>
      </Grid>
      <Divider />
    </Box>
  );
};

export { PhoneItem };
