import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { TeacherNotificationList } from "./TeacherNotificationList";

const TeacherNotificationBlock: React.FC<PageComponentProps> = ({
  currentRoute,
}) => {
  return <TeacherNotificationList currentRoute={currentRoute} />;
};

export { TeacherNotificationBlock };
