import {
  Box,
  DialogContent,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { QuestionDto } from "dto/course/question.dto";
import { TestResultDto } from "dto/course/testresult.dto";
import {
  TestResultAnswerBodyDto,
  TestResultQuestionAndAnswerBodyDto,
  TestResultQuestionBodyDto,
} from "dto/course/testresultquestion.dto";
import { useList } from "hooks/useList";
import { useResource } from "hooks/useResource";
import { CallbackType } from "interfaces/commontypes.interface";
import React, { useState } from "react";
import { QuestionService } from "services/course/question.service";
import { TestResultService } from "services/course/testresult.service";
import { TestResultQuestionService } from "services/course/testresultquestion.service";
import { Status } from "tools/types/status";
import { NewTestQuestionItem } from "./NewTestQuestionItem";
import { CommonTools } from "tools/utils/commontools";

type Props = {
  idTest: string;
  setStatusTest: (value: number) => void;
  testResult?: TestResultDto;
  setTestResult: (value: TestResultDto) => void;
};

const service = new QuestionService();
const testResultQuestion = new TestResultQuestionService();
const testResultService = new TestResultService();

const NewTestQuestionList: React.FC<Props> = ({
  idTest,
  setStatusTest,
  testResult,
  setTestResult,
}) => {
  const { LL } = useResource();
  const [index, setIndex] = useState<number>(0);

  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    if (!idTest) return;
    service.getList(cb, cbParams, req);
  };

  const [loading, objects, , , , setLoading] = useList<QuestionDto>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("idtest", [idTest])],
      1,
      -1,
      [RequestSortCriteriaDTO.prepareSortCriteria("ordercriteria", true)]
    ),
    [idTest]
  );

  const [questionResult, setQuestionResult] = useState<
    TestResultQuestionBodyDto | undefined
  >();
  const [answersResult, setAnswersResult] = useState<
    Array<TestResultAnswerBodyDto>
  >([]);

  const addTestResultQuestion = () => {
    if (!questionResult || !answersResult || !testResult) return;

    setAnswersResult([]);
    setQuestionResult(undefined);

    testResultQuestion.addWithAnswersNew(
      handleAddResult,
      {},
      new TestResultQuestionAndAnswerBodyDto(questionResult, answersResult)
    );
    handleNext();
  };

  const addLastTestResultQuestion = () => {
    if (!questionResult || !answersResult || !testResult) return;

    setAnswersResult([]);
    setQuestionResult(undefined);

    testResultQuestion.addWithAnswersNew(
      handleAddLastResult,
      {},
      new TestResultQuestionAndAnswerBodyDto(questionResult, answersResult)
    );
  };

  const handleAddResult = (result: ResultObjectDTO) => {
    if (!result) return;
  };

  const handleAddLastResult = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    handleFinish();
  };

  const handleNext = () => {
    if (!objects) return;
    if (index >= objects.length - 1) return;
    const newIndex = index + 1;
    setIndex(newIndex);
  };

  const handleFinish = () => {
    if (!objects) return;
    if (!objects.length) return;
    if (!testResult) return;
    if (!testResult.id) return;
    const data = new TestResultDto();
    data.status = Status.TEST_FINISHED;
    data.id = testResult.id;
    setLoading(true);
    testResultService.submitResult(handleResultFinish, {}, data);
  };

  const handleResultFinish = (result: ResultObjectDTO<TestResultDto>) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    setTestResult(result.obj);
    setStatusTest(Status.TEST_FINISHED);
    setLoading(false);
  };

  const processFinishButton = () => {
    if (!objects) return null;
    if (index === objects.length - 1) {
      return (
        <MyButton
          cb={addLastTestResultQuestion}
          variant="contained"
          className="btn"
        >
          {LL("Bt_Continua")}
        </MyButton>
      );
    }
    return null;
  };

  const processNextButton = () => {
    if (!objects) return null;
    if (index >= objects.length - 1) return null;
    return (
      <MyButton
        cb={addTestResultQuestion}
        className="btn"
        color="primary"
        variant="contained"
      >
        {LL("Next")}
      </MyButton>
    );
  };

  const processCount = () => {
    if (!objects) return null;
    if (!objects.length) return null;
    const currentIndex = index + 1;
    return (
      <Stack direction={"row"} spacing={1}>
        <Typography variant="h6">{LL("Question")}</Typography>
        <Typography variant="h6">{currentIndex}</Typography>
        <Typography variant="h6">{LL("from")}</Typography>
        <Typography variant="h6">{objects.length}</Typography>
      </Stack>
    );
  };

  const processProgressBar = () => {
    if (!objects) return null;
    if (!objects.length) return null;
    const progress = ((index + 1) / objects.length) * 100;
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
    );
  };

  const processQuestionItem = (obj: QuestionDto) => {
    if (!obj) return null;
    if (!testResult) return null;
    return (
      <NewTestQuestionItem
        obj={obj}
        setQuestionResult={setQuestionResult}
        setAnswersResult={setAnswersResult}
        testResult={testResult}
        key={CommonTools.processObjectField(obj, ["id"])}
      />
    );
  };
  const processToolbar = () => {
    if (!objects) return null;
    if (!objects.length) return null;
    return (
      <React.Fragment>
        {processCount()}
        {processNextButton()}
        {processFinishButton()}
      </React.Fragment>
    );
  };

  if (loading) return <Skeleton variant="rectangular" height={300} />
  if (!objects) return null;
  if (!objects.length) return null;
  if (index > objects.length) return null;
  const obj = objects[index];
  return (
    <React.Fragment>
      {processProgressBar()}
      <DialogContent className="dialogContent alignDialog">
        {processQuestionItem(obj)}
      </DialogContent>
      <Box
        className="footerContent"
        p={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        {processToolbar()}
      </Box>
    </React.Fragment>
  );
};

export { NewTestQuestionList };
