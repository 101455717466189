import React from "react";
import { useResource } from "hooks/useResource";

import { TeacherService } from "services/user/teacher.service";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { TeacherDto } from "dto/user/teacher.dto";

import RequestListDTO from "dto/app/requestlist.dto";

import { Box, Typography } from "@mui/material";

import Grid from "@mui/material/Grid";
import { MyNavBt } from "components/elements/button/MyNavBt";
import { TeacherCard } from "components/user/teacher/TeacherCard";
import ArrowRightAltSharpIcon from "@mui/icons-material/ArrowRightAltSharp";
import { SkeletonScnTeachers } from "../../elements/Skeleton/SkeletonSection";
import { useList } from "hooks/useList";

const service = new TeacherService();
const HomeTopTeacherBlock: React.FC<PageComponentProps> = () => {
  const { LL, CC } = useResource();

  // const [isLoading, setIsLoading] = useState(true);
  // const [objects, setObjects] = useState<Array<TeacherDto>>([]);

  // const getList = async () => {
  //   const reqList = new RequestListDTO();
  //   reqList.onpage = parseInt(CC("Top_Teacher_Block_On_Page", "12"));
  //   reqList.page = 1;

  //   const cd = getCache(JSON.stringify(reqList));

  //   if (cd !== false) {
  //     setObjects(cd);
  //     setIsLoading(false);
  //     return;
  //   }

  //   setIsLoading(true);

  //   const data = await teacherService.getListByRating(undefined, {}, reqList);

  //   const rez = !data || !data.objects ? [] : data.objects;

  //   setObjects(rez);
  //   setIsLoading(false);

  //   saveCache(rez, JSON.stringify(reqList));
  // };

  // useEffect(() => {
  //   getList();
  // }, []);

  const getList = (cb?: any, cbParams?: any, reqList?: RequestListDTO) => {
    service.getListByRating(cb, cbParams, reqList);
  };

  const [loading, objects] = useList<TeacherDto>(
    getList,
    new RequestListDTO(
      [],
      1,
      parseInt(CC("Top_Teacher_Block_On_Page", "12")),
      []
    ),
    [],
    true,
    "home_teacher_top_block"
  );

  const processTeacherItem = (obj: TeacherDto, index: number) => {
    if (!obj) return null;
    return (
      <Grid item sm={6} lg={3} md={4} key={index}>
        <TeacherCard obj={obj} />
      </Grid>
    );
  };

  const processTeacherList = () => {
    if (!objects) return null;
    if (!objects.length) return null;

    return (
      <Box className="container topTeacherHome" py={5}>
        <Typography className="generalTextAlign" variant="h2" my={4}>
          {LL("Top_Teachers")}
        </Typography>
        <Box>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {objects.map((item: TeacherDto, i: number) => {
              return processTeacherItem(item, i);
            })}
          </Grid>
        </Box>
        <Box>
          <MyNavBt className="btn" sx={{ my: 4 }} href="/teacher">
            {LL("BT_Show_All_Teachers")}
            <ArrowRightAltSharpIcon sx={{ ml: 1 }} />
          </MyNavBt>
        </Box>
      </Box>
    );
  };

  return loading ? <SkeletonScnTeachers /> : processTeacherList();
};

export { HomeTopTeacherBlock };
