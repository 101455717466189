import React, { useEffect, useState, useContext } from "react";

import { Skeleton, List } from "@mui/material";

import { ClassroomStudentItem } from "../ClassroomStudentItem";
import { ClassroomContext } from "context/classroom.context";
import { CourseEnrollmentDto } from "dto/course/courseenrollment.dto";

const StudentList: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const {
    state: { studentObjects },
  } = useContext(ClassroomContext);

  const checkLoading = () => {
    let loading = false;
    if (studentObjects == null) loading = true;
    setLoading(loading);
  };

  useEffect(() => {
    checkLoading();
  }, [studentObjects]);

  const processItem = (obj: CourseEnrollmentDto, index: number) => {
    if (!obj) return null;
    return <ClassroomStudentItem obj={obj} index={index} />;
  };

  const processList = () => {
    if (loading) return <Skeleton variant="rectangular" />;
    if (!studentObjects) return null;
    if (!studentObjects.length) return null;
    return (
      <List>
        {studentObjects.map((item: CourseEnrollmentDto, i: number) => {
          return processItem(item, i);
        })}
      </List>
    );
  };

  return processList();
};

export { StudentList };
