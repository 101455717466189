import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import React from "react";
import { Types } from "tools/types/types";
import { DetailGridText } from "./DetailGridText";

type Props = {
  object?: Idto;
  fields: Array<DetailObject>;
};
const DetailGrid: React.FC<Props> = ({ object, fields }) => {
  if (!object) return null;
  if (!fields.length) return null;

  return (
    <React.Fragment>
      {fields.map((item, index) => processItem(object, item, index))}
    </React.Fragment>
  );
};

const processItem = (
  obj: Idto,
  item: DetailObject,
  index: number
): React.JSX.Element | null => {
  if (!obj) return null;
  if (!item) return null;
  switch (item.typeField) {
    case Types.FIELD_TYPE_TEXT:
      return (
        <DetailGridText object={obj} item={item} index={index} key={index} />
      );
    
    default:
      return null;
  }
};

export { DetailGrid };
