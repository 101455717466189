import { AnswerDto } from "dto/course/answer.dto";
import { QuestionDto } from "dto/course/question.dto";

import React, { useState, useEffect } from "react";

import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@mui/material";

type Props = {
  obj: QuestionDto;
  objects: Array<AnswerDto>;
  answerSelected: Array<string>;
  setAnswerSelected: any;
};

const AnswerMultipleQuestionOption: React.FC<Props> = ({
  obj,
  objects,
  answerSelected,
  setAnswerSelected,
}) => {
  const [value, setValue] = useState<string[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const optionValue = (event.target as HTMLInputElement).value;
    setValue((prevValue) => {
      if (prevValue.includes(optionValue)) {
        return prevValue.filter((item) => item !== optionValue);
      } else {
        return [...prevValue, optionValue];
      }
    });
  };

  useEffect(() => {
    setAnswerSelected(value);
  }, [value]);

  const processAnswerItem = (obj: AnswerDto, index: number) => {
    if (!obj || !obj.id || !obj.name) return null;

    return (
      <FormControlLabel
        className="answerElement"
        control={
          <Checkbox
            checked={value.includes(obj.id)}
            onChange={handleChange}
            value={obj.id}
          />
        }
        label={obj.name}
        key={index}
      />
    );
  };

  const processAnswerList = () => {
    if (!objects.length) return null;

    return (
      <FormControl component="fieldset">
        <FormGroup row>
          {objects.map((obj, index) => {
            return processAnswerItem(obj, index);
          })}
        </FormGroup>
      </FormControl>
    );
  };

  return processAnswerList();
};

export { AnswerMultipleQuestionOption };
