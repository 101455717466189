import { Box } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { MyFileField } from "components/form/MyFileField";
import { TeacherMediaFilesDto } from "dto/user/teacher.dto";
import { useForm } from "hooks/useForm";
import { useMessage } from "hooks/useMessage";
import { useResource } from "hooks/useResource";
import React from "react";
import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: TeacherMediaFilesDto;
  onSubmit: (data: TeacherMediaFilesDto) => void;
  loading: boolean;
  onDelete: () => void;
  canDelete?: boolean;
  messageTitle: string;
  messageContent: string;
};

const FormTeacherAttachment: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  onDelete,
  canDelete = false,
  messageTitle,
  messageContent,
}) => {
  const { LL } = useResource();
  const { registerDialog } = useMessage();
  const [obj, disabled, , setObject, , key, resetInput] =
    useForm<TeacherMediaFilesDto>(
      defaultObject,
      RequiredValidator.getValidators(["idteacher", "files"])
    );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    onSubmit(obj);
    resetInput();
    setObject(defaultObject);
  };

  const handleOnDelete = () => {
    onDelete();
  };

  const handleDelete = () => {
    if (!canDelete) return;
    registerDialog(messageTitle, messageContent, {
      cb: handleOnDelete,
    });
  };

  return (
    <Box
      sx={{
        mb: 2,
        display: "flex",
        gap: 4,
        width: "100%",
        flexDirection: { xs: "column", sm: "column" },
      }}>
      <form onSubmit={handleSubmit}>
        <Box sx={{ width: "100%" }}>
          <MyFileField
            className="form-input"
            sx={{ width: "100%" }}
            field="files"
            label={""}
            setObj={setObject}
            obj={obj}
            key={key}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
            justifyContent: "cener",
            alignItems: "center",
            mt: 3,
          }}>
          {canDelete ? (
            <MyButton
              sx={{ width: "100%" }}
              disabled={loading}
              color="error"
              className="btn"
              variant="outlined"
              size="large"
              type="button"
              cb={handleDelete}>
              {LL("Bt_Delete")}
            </MyButton>
          ) : null}

          <MyButton
            sx={{ width: "100%" }}
            disabled={disabled || loading}
            color="primary"
            className="btn"
            variant="outlined"
            size="large"
            type="submit">
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormTeacherAttachment };
