import React from "react";
import { useResource } from "hooks/useResource";

import { Box, Typography, Stack } from "@mui/material";
import { TestResultAnswerDto } from "dto/course/testresultanswer.dto";
import { CommonTools } from "tools/utils/commontools";

type Props = {
  obj: TestResultAnswerDto;
};

const TestResultAnswerItem: React.FC<Props> = ({ obj }) => {
  const { LL } = useResource();

  const processAnswerInfo = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("answer")) return null;
    if (!obj.answer) return null;

    return (
      <Box>
        <Stack direction="row" spacing={2}>
          <Typography sx={{ fontWeight: "bold" }}>{LL("Answer")}</Typography>
          <Typography>
            {CommonTools.processObjectField(
              obj,
            [  "answer",
              "name"]
            )}
          </Typography>
        </Stack>
      </Box>
    );
  };

  const processAnswerResultInfo = () => {
    if (!obj) return null;

    return (
      <Box>
        <Stack direction="row" spacing={2}>
          <Typography sx={{ fontWeight: "bold" }}>{LL("score")}</Typography>
          <Typography>
            {CommonTools.processObjectField(obj, ["score"])}
          </Typography>
        </Stack>
      </Box>
    );
  };
  const processItem = () => {
    if (!obj) return null;
    let color = "#FFD6D5";
    if (obj.hasOwnProperty("iscorrect") && obj.iscorrect) color = "lightgreen";

    return (
      <Box sx={{ bgcolor: color }} width={"100%"}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          {processAnswerInfo()}
          {processAnswerResultInfo()}
        </Stack>
      </Box>
    );
  };

  return processItem();
};

export { TestResultAnswerItem };
