import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

const FooterPrint: React.FC<PageComponentProps> = ({
  currentRoute,
}) => {
  return null;
};

export { FooterPrint };
