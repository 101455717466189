import React, { useState, useEffect } from 'react';

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DateTools from "tools/utils/date.tools";



interface MyDatePickerProps {
    [key: string]: any; 
    setObjField: ((field: any, value: any) => void),
    LL: any,
    field: string,
    _defaultValue?: any,
    _label?: string,
}

const MyDatePicker: React.FC<MyDatePickerProps> = ({
    setObjField,
    LL,
    field,
    _defaultValue,
    _label,
    ...props
}) => {


    const label = _label !== undefined ? _label : LL("FN_" + field);
    const defaultValue = _defaultValue !== undefined ? DateTools.convertToDate(_defaultValue) : DateTools.convertToDate(Date.now());

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    
                    label={label}
                    defaultValue={defaultValue}
                    onChange={(e: Date | null) => {
                        if (e !== null) {
                            setObjField(field, e);
                        }

                    }}
                    format={DateTools.getDateFormat()}
                    {...props}
                />
            </LocalizationProvider>

        </>
    )

};

export default MyDatePicker;