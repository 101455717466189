// import RequestListDTO from "dto/app/requestlist.dto";
// import Idto from "interfaces/idto.interface";
// import IRepository from "interfaces/irepository.interface";
// import GeneralRepository from "repositories/general.repository";

// const { REACT_APP_API_SERVER, REACT_APP_URLPREFIX } = process.env;

// export default class LessonEnrollmentRepository extends GeneralRepository implements IRepository {
//     async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         const url = REACT_APP_API_SERVER + "/lessonenrollment/" + id;
//         return await this.deleteAxios(url, cb, cbparameters);
//     }

//     async update(id: string, cb?: any, data?: Idto | undefined, cbparameters?: any): Promise<any> {
//         const url = REACT_APP_API_SERVER + "/lessonenrollment/" + id;
//         return await this.putAxios(url, cb, data, cbparameters);
//     }

//     async finishLesson(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         const url = REACT_APP_API_SERVER + "/lessonenrollment/finish-lesson/" + id;
//         return await this.putAxios(url, cb, {}, cbparameters);
//     }

//     async finishLessonAndGetNext(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         const url = REACT_APP_API_SERVER + "/lessonenrollment/finish-lesson-get-next/" + id;
//         return await this.putAxios(url, cb, {}, cbparameters);
//     }

//     patch(id: string, cb?: any, data?: Idto | undefined, cbparameters?: any): Promise<any> {
//         throw new Error("Method not implemented.");
//     }

//     async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         const url = `${REACT_APP_API_SERVER}/lessonenrollment/${id}`;
//         return await this._get(url, cb, cbparameters);
//     }

//     async getCurrentLesson(
//         idstudent: string,
//         idcourse: string,
//         idcourseenrollment: string,
//         cb?: any,
//         cbparameters?: any
//     ): Promise<any> {
//         const url = `${REACT_APP_API_SERVER}/lessonenrollment/first-lesson?idstudent=${idstudent}&idcourse=${idcourse}&idcourseenrollment=${idcourseenrollment}`;
//         return await this._get(url, cb, cbparameters);
//     }

//     async getByIdLesson(
//         idlesson: string,
//         idstudent: string,
//         idcourse: string,
//         idcourseenrollment: string,
//         cb?: any,
//         cbparameters?: any
//     ): Promise<any> {
//         const url = `${REACT_APP_API_SERVER}/lessonenrollment/by-lesson/${idlesson}?idstudent=${idstudent}&idcourse=${idcourse}&idcourseenrollment=${idcourseenrollment}`;
//         return await this._get(url, cb, cbparameters);
//     }

//     async verifyLessonFinished(
//         idlesson: string,
//         idstudent: string,
//         idcourse: string,
//         idcourseenrollment: string,
//         cb?: any,
//         cbparameters?: any
//     ): Promise<any> {
//         const url = `${REACT_APP_API_SERVER}/lessonenrollment/verify-lesson-finished/${idlesson}?idstudent=${idstudent}&idcourse=${idcourse}&idcourseenrollment=${idcourseenrollment}`;
//         return await this._get(url, cb, cbparameters);
//     }

//     async getList(
//         cb?: any,
//         cbparameters?: any,
//         data?: RequestListDTO
//     ): Promise<any> {
//         const url = `${REACT_APP_API_SERVER}/lessonenrollment`;
//         return await this._getList(url, cb, cbparameters, data);
//     }

//     async add(cb?: any, cbparameters?: any, data?: Idto): Promise<any> {
//         const url = `${REACT_APP_API_SERVER}/lessonenrollment`;

//         return await this.postAxios(url, cb, data, cbparameters);
//     }
// }

import IRepository from "interfaces/irepository.interface";
import { GlobalGeneralRepository } from "repositories/globalgeneral.repository";

export class LessonEnrollmentRepository
  extends GlobalGeneralRepository
  implements IRepository
{
  constructor() {
    super("lessonenrollment");
  }

  async finishLesson(id: string, cb?: any, cbparameters?: any): Promise<any> {
    const url = `${this.mainUrl}/finish-lesson/${id}`;
    return await this.putAxios(url, cb, {}, cbparameters);
  }

  async finishLessonAndGetNext(
    id: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> {
    const url = `${this.mainUrl}/finish-lesson-get-next/${id}`;
    return await this.putAxios(url, cb, {}, cbparameters);
  }

  async getCurrentLesson(
    idstudent: string,
    idcourse: string,
    idcourseenrollment: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> {
    const url = `${this.mainUrl}/first-lesson?idstudent=${idstudent}&idcourse=${idcourse}&idcourseenrollment=${idcourseenrollment}`;
    return await this._get(url, cb, cbparameters);
  }

  async getByIdLesson(
    idlesson: string,
    idstudent: string,
    idcourse: string,
    idcourseenrollment: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> {
    const url = `${this.mainUrl}/by-lesson/${idlesson}?idstudent=${idstudent}&idcourse=${idcourse}&idcourseenrollment=${idcourseenrollment}`;
    return await this._get(url, cb, cbparameters);
  }

  async verifyLessonFinished(
    idlesson: string,
    idstudent: string,
    idcourse: string,
    idcourseenrollment: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> {
    const url = `${this.mainUrl}/verify-lesson-finished/${idlesson}?idstudent=${idstudent}&idcourse=${idcourse}&idcourseenrollment=${idcourseenrollment}`;
    return await this._get(url, cb, cbparameters);
  }
}
