import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import { LessonEnrollmentRepository } from "repositories/course/lessonenrollment.repository";

export class LessonEnrollmentService
  extends GeneralService<LessonEnrollmentRepository>
  implements IService
{
  constructor() {
    super(
      new LessonEnrollmentRepository(),
      [
        { key: "status", type: "status" },
        { key: "data", type: "timestamp" },
      ],
      "data"
    );
  }
  finishLesson = async (
    id: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.finishLesson(
      id,
      this.handleGet,
      cbparameters
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  finishLessonAndGetNext = async (
    id: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.finishLessonAndGetNext(
      id,
      this.handleGet,
      cbparameters
    );
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  getCurrentLesson = async (
    idStudent: string,
    idCourse: string,
    idCourseEnrollment: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getCurrentLesson(
      idStudent,
      idCourse,
      idCourseEnrollment,
      this.handleGet,
      cbparameters
    );
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  getByIdLesson = async (
    idLesson: string,
    idStudent: string,
    idCourse: string,
    idCourseEnrollment: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getByIdLesson(
      idLesson,
      idStudent,
      idCourse,
      idCourseEnrollment,
      this.handleGet,
      cbparameters
    );
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  verifyLessonFinished = async (
    idLesson: string,
    idStudent: string,
    idCourse: string,
    idCourseEnrollment: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.verifyLessonFinished(
      idLesson,
      idStudent,
      idCourse,
      idCourseEnrollment,
      this.handleGet,
      cbparameters
    );
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };
}

// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// // import {LessonEnrollmentDto} from "dto/lesson/lessonenrollment.dto";
// import ResultListDTO from "dto/app/resultlist.dto";
// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import {LessonEnrollmentRepository} from "repositories/course/lessonenrollment.repository";
// import GeneralService from "services/general.service";
// import { Status } from "tools//types/status";

// export default class LessonEnrollmentService extends GeneralService implements IService {
//     lessonEnrollmentRepository: LessonEnrollmentRepository = new LessonEnrollmentRepository();
//     constructor() {
//         super();
//         this.handleGetList = this.handleGetList.bind(this);
//         this.handleGet = this.handleGet.bind(this);
//     }
//     handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;

//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     async update(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {

//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         this.lessonEnrollmentRepository.update(id, this.handleUpdate, data, cbparameters);
//     }

//     async finishLesson(id: string, cb?: any, cbparameters?: any): Promise<any> {

//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         this.lessonEnrollmentRepository.finishLesson(id, this.handleUpdate, cbparameters);
//     }

//     async finishLessonAndGetNext(id: string, cb?: any, cbparameters?: any): Promise<any> {

//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         this.lessonEnrollmentRepository.finishLessonAndGetNext(id, this.handleUpdate, cbparameters);
//     }

//     handleDelete(result?: any, cbparameters?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters);
//         }
//     }

//     async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.lessonEnrollmentRepository.delete(id, this.handleDelete, cbparameters);
//     }

//     handleGet(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;
//         this.populateObject(rez.obj, result.obj, [
//             { key: 'status', type: 'status' },
//             { key: 'data', type: 'timestamp' },
//         ]);
//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//         return rez;
//     }

//     async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.lessonEnrollmentRepository.get(id, this.handleGet, cbparameters);
//         if (cb == undefined) {
//             return this.handleGet(t);
//         }
//     }

//     async getCurrentLesson(
//         idStudent: string,
//         idCourse: string,
//         idCourseEnrollment: string,
//         cb?: any,
//         cbparameters?: any
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.lessonEnrollmentRepository.getCurrentLesson(
//             idStudent,
//             idCourse,
//             idCourseEnrollment,
//             this.handleGet,
//             cbparameters
//         );
//         if (cb == undefined) {
//             return this.handleGet(t);
//         }
//     }

//     async getByIdLesson(
//         idLesson: string,
//         idStudent: string,
//         idCourse: string,
//         idCourseEnrollment: string,
//         cb?: any,
//         cbparameters?: any
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.lessonEnrollmentRepository.getByIdLesson(
//             idLesson,
//             idStudent,
//             idCourse,
//             idCourseEnrollment,
//             this.handleGet,
//             cbparameters
//         );
//         if (cb == undefined) {
//             return this.handleGet(t);
//         }
//     }

//     handleGetVerifyFinished(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//         return rez;
//     }

//     async verifyLessonFinished(
//         idLesson: string,
//         idStudent: string,
//         idCourse: string,
//         idCourseEnrollment: string,
//         cb?: any,
//         cbparameters?: any
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.lessonEnrollmentRepository.verifyLessonFinished(
//             idLesson,
//             idStudent,
//             idCourse,
//             idCourseEnrollment,
//             this.handleGetVerifyFinished,
//             cbparameters
//         );
//         if (cb == undefined) {
//             return this.handleGetVerifyFinished(t);
//         }
//     }

//     handleGetList(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultListDTO();

//         rez = result;
//         if (result.err) return;

//         this.parseResultPopulate(result, rez, [
//             { key: 'status', type: 'status' },
//             { key: 'data', type: 'timestamp' },
//         ]);

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//         return rez;
//     }

//     async getList(
//         cb?: any,
//         cbparameters?: any,
//         data?: RequestListDTO
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.lessonEnrollmentRepository.getList(this.handleGetList, cbparameters, data);
//         if (cb == undefined) {
//             return this.handleGetList(t);
//         }
//     }

//     handleAdd(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     async add(
//         cb?: any,
//         cbparameters?: any,
//         data?: any
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.lessonEnrollmentRepository.add(this.handleGetList, cbparameters, data);
//     }

//     getDefaultRequestList(onPage?: number): RequestListDTO {
//         onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
//         if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
//         var defaultRequestList = new RequestListDTO();
//         defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
//         defaultRequestList.onpage = onPage;
//         // var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
//         // defaultRequestSortCriteria.asc = true;
//         // defaultRequestSortCriteria.field = 'value';
//         // defaultRequestList.sortcriteria = []
//         // defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
//         return defaultRequestList;
//     }
// }
