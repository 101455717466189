import Idto from "interfaces/idto.interface";
import MessageDto from "dto/app/message.dto";
import UserDto from "dto/user/user.dto";
import { AccessTokenDto } from "dto/auth/accesstoken.dto";
import UserSettingsDto from "dto/user/usersettings.dto";
import { TeacherDto } from "dto/user/teacher.dto";
import { StudentDto } from "dto/user/student.dto";
import { JWTDto, TokenDto } from "dto/system/jwt.dto";

export default class ResultSignInDTO implements Idto {
  err?: boolean;
  mustchangepassword?: boolean;
  accesstoken?: AccessTokenDto;
  messages?: MessageDto[];
  obj?: UserDto;
  roles?: string[];
  usersettings?: UserSettingsDto;
  teacher?: TeacherDto;
  student?: StudentDto;
  remembertoken?: string;
  jwttoken?: TokenDto
}
