import React from "react";

import { TestDto } from "dto/course/test.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";

import { TestService } from "services/course/test.service";

import { Box, Skeleton } from "@mui/material";

import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

import { EditCourseTestItem } from "./EditCourseTestItem";
import { CallbackType } from "interfaces/commontypes.interface";
import { useList } from "hooks/useList";
import { CommonTools } from "tools/utils/commontools";
import { AddTestDialog } from "./inuse/AddTestDialog";

type Props = {
  idCourse: string;
  setOpenDialog: (value: boolean) => void;
  openDialog: boolean;
};

const service = new TestService();

const EditCourseTestList: React.FC<Props> = ({
  idCourse,

  setOpenDialog,
  openDialog,
}) => {
  // const [isLoading, setIsLoading] = useState(true);
  // const [objects, setObjects] = useState<Array<TestDto>>([]);

  // const getList = () => {
  //   setIsLoading(true);
  //   const reqList = new RequestListDTO();
  //   reqList.onpage = parseInt(CC("DefaultOnPageEditCourseTestList", "200"));
  //   reqList.page = 1;
  //   reqList.filters = [];
  //   reqList.sortcriteria = [];

  //   const sortCriteria = new RequestSortCriteriaDTO();
  //   sortCriteria.field = "ordercriteria";
  //   sortCriteria.asc = true;
  //   reqList.sortcriteria.push(sortCriteria);

  //   let f: RequestFilterDTO;

  //   f = new RequestFilterDTO();
  //   f.field = "idcourse";
  //   f.values = [idCourse];
  //   reqList.filters.push(f);

  //   service.getList(loadObjects, {}, reqList);
  // };

  // const loadObjects = (result: ResultListDTO) => {
  //   if (!result) return;
  //   if (!result.err) {
  //     const objects = result.objects ? result.objects : [];
  //     setObjects(objects);
  //   }
  // };

  // const checkLoading = () => {
  //   let load = false;
  //   if (!objects) load = true;

  //   setIsLoading(load);
  // };

  // useEffect(() => {
  //   getList();
  // }, [idCourse, objectHasChanged]);

  // useEffect(() => {
  //   checkLoading();
  // }, [objects]);

  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    if (!idCourse) return;
    service.getList(cb, cbParams, req);
  };

  const [loading, objects, , , setObjects] = useList<TestDto>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("idcourse", [idCourse])],
      1,
      -1,
      [RequestSortCriteriaDTO.prepareSortCriteria("ordercriteria", true)]
    ),
    [idCourse]
  );

  const updateList = (obj: TestDto) => {
    if (!objects) return;
    const arr = [...objects];
    const id = CommonTools.processObjectField(obj, ["id"]);
    const index = objects.findIndex((item) => item.id === id);
    if (index === -1) return;
    arr[index] = obj;
    setObjects(arr);
  };

  const deleteFromList = (id: string) => {
    if (!objects) return;
    const arr = objects.filter((item) => item.id !== id);
    setObjects(arr);
  };

  const addToList = (obj: TestDto) => {
    if (!objects) return;
    const arr = [...objects, obj];

    setObjects(arr);
  };

  const processTestItem = (obj: TestDto, index: number) => {
    if (!obj) return null;
    if (!obj.id) return null;

    return (
      <Box key={index}>
        <EditCourseTestItem
          idCourse={idCourse}
          objectFromList={obj}
          updateList={updateList}
          deleteFromList={deleteFromList}
        />
      </Box>
    );
  };

  const processTestList = () => {
    if (!objects) return null;
    if (!objects.length) return null;

    return (
      <React.Fragment>
        {objects.map((item: TestDto, i: number) => {
          return processTestItem(item, i);
        })}
      </React.Fragment>
    );
  };

  return (
    <Box>
      {loading ? <Skeleton /> : processTestList()}
      <AddTestDialog
        id={idCourse}
        open={openDialog}
        setOpen={setOpenDialog}
        addToList={addToList}
      />
    </Box>
  );
};

export { EditCourseTestList };
