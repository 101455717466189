import { GeneralAutocomplete } from "components/elements/Autocomplete/new/GeneralAutocomplete";

import RequestListDTO from "dto/app/requestlist.dto";
import SelectOptions from "dto/app/selectoptions.dto";
import { CourseCategoryDto } from "dto/course/coursecategory.dto";

import React from "react";
import { CourseCategoryService } from "services/course/coursecategory.service";

type Props = {
  setObjField: (field: string, value: any) => void;
  defaultObject?: CourseCategoryDto | null;
  value?: string | number;
  label? :string
};

const service = new CourseCategoryService();
const AutocompleteCourseCategory: React.FC<Props> = ({
  setObjField,
  defaultObject,
  value,
  label = " "
}) => {
  
  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };
  let defaultObj: SelectOptions | null = null;
  if (!defaultObject) defaultObj = null;
  else defaultObj = CourseCategoryDto.parseOption(defaultObject);

  return (
    <GeneralAutocomplete
      field="idcoursecategory"
      label={label}
      defaultObject={defaultObj}
      setObjectField={setObjField}
      parseOptions={CourseCategoryDto.parseToSelectOptions}
      getListFunction={getList}
      mainValue={value}
      filterField="searchvalue"
    />
  );
};

export { AutocompleteCourseCategory };
