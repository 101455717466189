import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import { CourseEnrollmentRepository } from "repositories/course/courseenrollment.repository";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

export class CourseEnrollmentService
  extends GeneralService<CourseEnrollmentRepository>
  implements IService
{
  constructor() {
    super(
      new CourseEnrollmentRepository(),
      [
        { key: "status", type: "status" },
        { key: "paymentstatus", type: "status" },
        { key: "data", type: "timestamp" },
      ],
      "data"
    );
  }
  requestCertificate = async (
    id: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.requestCertificate(
      id,
      this.handleGet,
      cbparameters
    );
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  getProgress = async (
    id: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getProgress(
      id,
      this.handleGet,
      cbparameters
    );
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  checkStudentHasCourse = async (
    idcourse: string,
    idstudent: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.checkStudentHasCourse(
      idcourse,
      idstudent,
      this.handleGet,
      cbparameters
    );
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  getListForTeacher = async (
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getListForTeacher(
      this.handleGetList,
      cbparameters,
      data
    );
    if (cb === undefined) {
      return this.handleGetList(t);
    }
  };

  getListForClassroom = async (
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getListForClassroom(
      this.handleGetList,
      cbparameters,
      data
    );
    if (cb === undefined) {
      return this.handleGetList(t);
    }
  };

  addForTest = async (
    cb?: any,
    cbparameters?: any,
    data?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    const t = await this.repository.addForTest(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  addForCheckout = async (
    cb?: any,
    cbparameters?: any,
    data?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    const t = await this.repository.addForCheckout(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  getDefaultRequestListSelect = (onPage?: number): RequestListDTO => {
    onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    var defaultRequestList = new RequestListDTO();
    defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    defaultRequestList.onpage = onPage;
    var defaultRequestSortCriteria = new RequestSortCriteriaDTO();
    defaultRequestSortCriteria.asc = true;
    defaultRequestSortCriteria.field = "data";
    defaultRequestList.sortcriteria = [];
    defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
    return defaultRequestList;
  };
}
// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// // import {CourseEnrollmentDto} from "dto/course/courseenrollment.dto";
// import ResultListDTO from "dto/app/resultlist.dto";
// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import {CourseEnrollmentRepository} from "repositories/course/courseenrollment.repository";
// import GeneralService from "services/general.service";
// import { Status } from "tools//types/status";

// export default class CourseEnrollmentService extends GeneralService implements IService {
//     courseEnrollmentRepository: CourseEnrollmentRepository = new CourseEnrollmentRepository();
//     constructor() {
//         super();
//         this.handleGetList = this.handleGetList.bind(this);
//         this.handleGet = this.handleGet.bind(this);
//       }
//     handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;

//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     async update(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {

//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         this.courseEnrollmentRepository.update(id, this.handleUpdate, data, cbparameters);
//     }

//     async requestCertificate(id: string, cb?: any, cbparameters?: any): Promise<any> {

//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         this.courseEnrollmentRepository.requestCertificate(id, this.handleUpdate, cbparameters);
//     }

//     handleDelete(result?: any, cbparameters?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters);
//         }
//     }

//     async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.courseEnrollmentRepository.delete(id, this.handleDelete, cbparameters);
//     }

//     handleGet(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;
//         this.populateObject(rez.obj, result.obj, [
//             { key: 'status', type: 'status' },
//             { key: 'paymentstatus', type: 'status' },

//             { key: 'data', type: 'timestamp' },
//         ]);
//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//     return rez;
//     }

//     handleGetCheck(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//     return rez;
//     }

//     async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.courseEnrollmentRepository.get(id, this.handleGet, cbparameters);
//         if (cb == undefined)
//         {
//             return this.handleGet(t);
//         }
//     }

//     async getProgress(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.courseEnrollmentRepository.getProgress(id, this.handleGet, cbparameters);
//         if (cb == undefined)
//         {
//             return this.handleGet(t);
//         }
//     }

//     async checkStudentHasCourse(idcourse: string, idstudent:string,cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.courseEnrollmentRepository.checkStudentHasCourse(idcourse,
//             idstudent ,this.handleGetCheck, cbparameters);
//         if (cb == undefined)
//         {
//             return this.handleGet(t);
//         }
//     }

//     handleGetList(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultListDTO();

//         rez = result;
//         if (result.err) return;

//         this.parseResultPopulate(result, rez, [
//             { key: 'status', type: 'status' },
//             { key: 'data', type: 'timestamp' },
//             { key: 'paymentstatus', type: 'status' },
//           ]);

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//         return rez;
//     }

//     async getList(
//         cb?: any,
//         cbparameters?: any,
//         data?: RequestListDTO
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.courseEnrollmentRepository.getList(this.handleGetList, cbparameters, data);
//         if (cb == undefined)
//         {
//             return this.handleGetList(t);
//         }
//     }

//     async getListForTeacher(
//         cb?: any,
//         cbparameters?: any,
//         data?: RequestListDTO
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.courseEnrollmentRepository.getListForTeacher(this.handleGetList, cbparameters, data);
//         if (cb == undefined)
//         {
//             return this.handleGetList(t);
//         }
//     }

//     async getListForClassroom(
//         cb?: any,
//         cbparameters?: any,
//         data?: RequestListDTO
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.courseEnrollmentRepository.getListForClassroom(this.handleGetList, cbparameters, data);
//         if (cb == undefined)
//         {
//             return this.handleGetList(t);
//         }
//     }

//     handleAdd(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     async add(
//         cb?: any,
//         cbparameters?: any,
//         data?: any
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.courseEnrollmentRepository.add(this.handleGetList, cbparameters, data);
//     }

//     async addForTest(
//         cb?: any,
//         cbparameters?: any,
//         data?: any
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.courseEnrollmentRepository.addForTest(this.handleAdd, cbparameters, data);
//     }

//     async addForCheckout(
//         cb?: any,
//         cbparameters?: any,
//         data?: any
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.courseEnrollmentRepository.addForCheckout(this.handleAdd, cbparameters, data);
//     }
//     getDefaultRequestListSelect(onPage?: number): RequestListDTO {
//         onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
//         if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
//         var defaultRequestList = new RequestListDTO();
//         defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
//         defaultRequestList.onpage = onPage;
//         var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
//         defaultRequestSortCriteria.asc = true;
//         defaultRequestSortCriteria.field = 'data';
//         defaultRequestList.sortcriteria = []
//         defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
//         return defaultRequestList;
//     }

//     getDefaultRequestList(onPage?: number): RequestListDTO {
//         onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
//         if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
//         var defaultRequestList = new RequestListDTO();
//         defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
//         defaultRequestList.onpage = onPage;
//         // var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
//         // defaultRequestSortCriteria.asc = true;
//         // defaultRequestSortCriteria.field = 'value';
//         // defaultRequestList.sortcriteria = []
//         // defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
//         return defaultRequestList;
//     }
// }
