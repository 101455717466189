import { Box, Stack } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";

import MyTextField from "components/form/mytextfield";
import { TestimonialResponseDto } from "dto/user/testimonial.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useEffect } from "react";
import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObj: TestimonialResponseDto;
  onSubmit: (obj: TestimonialResponseDto) => void;
  loading: boolean;
  handleClose: () => void;
};

const FormTeacherInterfaceEditTestimonial: React.FC<Props> = ({
  defaultObj,
  onSubmit,
  loading,
  handleClose,
}) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField, setObject] =
    useForm<TestimonialResponseDto>(
      defaultObj,
      RequiredValidator.getValidators(["teacherresponse"])
    );

  useEffect(() => {
    if (!defaultObj) return;
    setObject(defaultObj);
  }, [defaultObj]);

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    if (!e) return;
    e.preventDefault();
    e.stopPropagation();

    if (disabled) return;
    onSubmit(obj);
  };
  return (
    <Box>
      <form>
        <Box className="shadow-box" sx={{ mt: 3 }}>
          <MyTextField
            tabIndex={0}
            fullWidth
            multiline
            minRows={4}
            p={2}
            id="teacherresponse"
            name="teacherresponse"
            variant="standard"
            className="form-input"
            color="primary"
            _label={LL("teacherresponse")}
            size="small"
            value={obj.teacherresponse}
            setObj={setObjField}
            _vresults={undefined}
          />
        </Box>

        <Stack direction="row" spacing={2} mt={2}>
          <MyButton
            tabIndex={3}
            cb={handleSubmit}
            disabled={disabled || loading}
            color="primary"
            variant="contained"
            type="submit"
            children={LL("Bt_save response")}
          />
          <MyButton
            disabled={loading}
            cb={handleClose}
            className="cancel-response"
          >
            {LL("Bt_Cancel response")}
          </MyButton>
        </Stack>
      </form>
    </Box>
  );
};

export { FormTeacherInterfaceEditTestimonial };
