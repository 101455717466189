import React from "react";

import SelectOptions from "dto/app/selectoptions.dto";
import { NewMySelect } from "./NewMySelect";

type Service = {
  GA: (type: string) => Array<SelectOptions>;
};
type Props = {
  type?: string;
  label?: string;
  setObjField: (field: string, value: any) => void;
  value: string | number | undefined;
  field?: string;
  service: Service;
  disabled?: boolean;
};
const StaticSelect: React.FC<Props> = ({
  type = "general",
  label = "select",
  setObjField,
  value,
  field = "type",
  service,
  disabled = false,
}) => {
  return (
    <NewMySelect
      options={service.GA(type)}
      _label={label}
      setObjectField={setObjField}
      value={value}
      field={field}
      variant="standard"
      className="form-select"
      size="small"
      disabled={disabled}
    />
  );
};

export { StaticSelect };
