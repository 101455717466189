import { Box, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { CourseTypeSelect } from "components/elements/Select/CourseTypeSelect";
import MyTextField from "components/form/mytextfield";
import { CourseSettingsFormDto } from "dto/course/coursesettings.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useEffect } from "react";

type Props = {
  defaultObj: CourseSettingsFormDto;
  onSubmit: (obj: CourseSettingsFormDto) => void;
  loading: boolean;
};

const FormCourseSettings: React.FC<Props> = ({
  defaultObj,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField, setObject] =
    useForm<CourseSettingsFormDto>(defaultObj, []);

  useEffect(() => {
    if (!defaultObj) return;
    setObject(CourseSettingsFormDto.fromCourseSettingsDto(defaultObj));
  }, [defaultObj]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled || loading) return;
    onSubmit(obj);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box sx={{ mt: 3, ".MuiFormLabel-root": { display: "none" } }}>
          <Typography variant="h6" mb={1}>
            {LL("Tip curs")}
          </Typography>
          <CourseTypeSelect
            value={obj.idtypecourse}
            field="idtypecourse"
            setObjectField={setObjField}
            label=""
          />
        </Box>
        <Box mt={3}>
          <Typography variant="h6" mb={1}>
            {LL("Parola curs")}
          </Typography>
          <MyTextField
            tabIndex={1}
            fullWidth
            id="password"
            name="password"
            variant="standard"
            className="form-input"
            color="primary"
            _label={LL("password")}
            size="small"
            value={obj.password}
            setObj={setObjField}
            _vresults={undefined}
          />
        </Box>
        <Box mt={3}>
          <Typography variant="h6" mb={1}>
            {LL("Numar maxim de studenti")}
          </Typography>
          <MyTextField
            tabIndex={1}
            fullWidth
            id="maxnumberofstudents"
            name="maxnumberofstudents"
            variant="standard"
            className="form-input"
            color="primary"
            _label={LL("Max_number_of_students")}
            size="small"
            value={obj.maxnumberofstudents}
            setObj={setObjField}
            _vresults={undefined}
          />
        </Box>
        <Box mt={4} textAlign="center">
          <MyButton
            tabIndex={2}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
            className="btn"
            children={LL("Bt_Submit")}
          />
        </Box>
      </form>
    </Box>
  );
};

export { FormCourseSettings };
