import ResultObjectDTO from "dto/app/resultobject.dto";
import { useResource } from "hooks/useResource";
import React, { useState, useEffect, useCallback } from "react";

import { useAuth } from "hooks/useAuth";
import { CommonTools } from "tools/utils/commontools";

import { MyButton } from "components/elements/button/MyButton";
import { Status } from "tools//types/status";

import { CourseService } from "services/course/course.service";
import { CourseDto } from "dto/course/course.dto";
import { useMessage } from "hooks/useMessage";


type Props = {
  [x: string]: any;
  currentRoute: any;
  obj: CourseDto;
  setObj: any;
};

const service = new CourseService();
const CoursePublishButton: React.FC<Props> = ({
  currentRoute,
  obj,
  setObj,
  ...props
}) => {
  const { LL } = useResource();
  const { teacher } = useAuth();
  const { registerDialog } = useMessage();

  const idCourse = CommonTools.getIdFromPath(currentRoute, "editcourse", 1, 2);
  const [disabled, setDisabled] = useState<boolean>(true);

  const checkIsDisabled = useCallback(() => {
    if (!obj) return;
    if (!obj.hasOwnProperty("status")) return;
    if (!obj.status) return;
    if (
      obj.status === Status.ACTIVE ||
      obj.status === Status.COURSE_ARCHIVED ||
      obj.status === Status.COURSE_DELETED
    )
      return;
    if (!teacher) return;
    if (!teacher.hasOwnProperty("status")) return;
    if (!teacher.status) return;
    if (teacher.status === Status.TEACHER_UNVERIFIED) return;
    setDisabled(false);
  }, [obj, teacher]);

  useEffect(() => {
    checkIsDisabled();
  }, [checkIsDisabled]);

  const handleSubmit = () => {
    if (!idCourse) return;

    registerDialog(
      LL("Confirm_publish_course"),
      LL("Are_you_sure_you_want_to_publish_this_course"),
      { cb: handleConfirm },
      null
    );
  };

  const handleConfirm = () => {
    if (idCourse) {
      setDisabled(true);
      service.publishByTeacher(idCourse, handleResult, {});
    }
  };

  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    if (!result.err) {
      const obj: CourseDto = result.obj ? result.obj : {};
      setObj(obj);
    }
  };
  const processComponent = () => {
    if (!teacher) return null;
    if(!idCourse) return null;
    return (
      <MyButton {...props} disabled={disabled} cb={handleSubmit}>
        {LL("publish_course_btn")}
      </MyButton>
    );
  };
  return processComponent();
};

export { CoursePublishButton };
