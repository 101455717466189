import React from "react";
import { Dialog, IconButton, DialogProps } from "@mui/material";
import { Close } from "@mui/icons-material";

type Props = DialogProps & {
  open: boolean;
  children: React.ReactNode;
  handleClose: () => void;
};

const GeneralDialog: React.FC<Props> = ({
  children,
  open,
  handleClose,

  ...props
}) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="general-dialog-title"
      aria-describedby="general-dialog-description"
      onClose={handleClose}
      className="dialogGeneral"
      {...props}
    >
      <IconButton
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
        className="closeButton"
        onClick={handleClose}
      >
        <Close />
      </IconButton>
      {children}
    </Dialog>
  );
};

export { GeneralDialog };
