import React, { useCallback, useEffect, useState } from "react";
import { alpha } from "@mui/system";

import { useResource } from "hooks/useResource";
import { LinearProgress, Typography } from "@mui/material";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

type PasswordStrengthCheckerProps = {
  password: string | undefined;
};
const PasswordStrengthChecker: React.FC<PasswordStrengthCheckerProps> = ({
  password,
}) => {
  const [strength, setStrength] = useState(0);
  const { LL } = useResource();
  const [label, setLabel] = useState<string>("");

  const calculateStrength = useCallback(() => {
    let currentStrength = 0;
    if (!password) return currentStrength;
    if (password.length === 0) return currentStrength;
    if (password.length > 8) {
      currentStrength += 10;
    }
    if (password.match(/[a-z]/)) currentStrength += 15;
    if (password.match(/[A-Z]/)) currentStrength += 24;
    if (password.match(/[0-9]/)) currentStrength += 26;
    if (password.match(/[!@#$%^&*(),.?":{}|<>]/)) currentStrength += 25;

    return currentStrength;
  }, [password]);

  const processCalculation = useCallback(() => {
    const newStrength = calculateStrength();
    setStrength(newStrength);

    if (newStrength === 100) {
      setLabel("Strong");
    }
    if (newStrength >= 50 && newStrength < 100) {
      setLabel("Moderate");
    }
    if (newStrength < 50) {
      setLabel("Weak");
    }
  }, [calculateStrength]);

  useEffect(() => {
    processCalculation();
  }, [processCalculation]);

  const progressColors = ["#FF7070", "#FEDF70", "#AEFD70", "#47F163"];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} sx={{ alignItems: "center" }}>
        <Grid item xs={8}>
          {/* <LinearProgress
            variant="determinate"
            value={strength}
            color={color}
          /> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexDirection: "row",
              height: 4,
              gap: 1,
            }}
          >
            <Box sx={{ width: "60px", height: "4px" }}>
              <LinearProgress
                variant="determinate"
                value={strength >= 25 ? 100 : strength * 4}
                // color={strength >= 25 ? color : "error"}
                // color="primary"
                sx={{
                  borderRadius: "4px",
                  backgroundColor: alpha(progressColors[0], 0.5),
                  "& .MuiLinearProgress-barColorPrimary": {
                    backgroundColor: progressColors[0],
                  },
                }}
              />
            </Box>
            <Box sx={{ width: "60px", height: "4px" }}>
              <LinearProgress
                variant="determinate"
                value={
                  strength >= 50
                    ? 100
                    : strength >= 25
                    ? (strength - 25) * 4
                    : 0
                }
                // color={strength >= 50 ? color : "error"}
                sx={{
                  borderRadius: "4px",
                  backgroundColor: alpha(progressColors[1], 0.5),
                  "& .MuiLinearProgress-barColorPrimary": {
                    backgroundColor: progressColors[1],
                  },
                }}
              />
            </Box>
            <Box sx={{ width: "60px", height: "4px" }}>
              <LinearProgress
                variant="determinate"
                value={
                  strength >= 75
                    ? 100
                    : strength >= 50
                    ? (strength - 50) * 4
                    : 0
                }
                // color={strength >= 75 ? color : "error"}
                // color="success"
                sx={{
                  borderRadius: "4px",
                  backgroundColor: alpha(progressColors[2], 0.5),
                  "& .MuiLinearProgress-barColorPrimary": {
                    backgroundColor: progressColors[2],
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                width: "60px",
                height: "4px",
              }}
            >
              <LinearProgress
                variant="determinate"
                value={
                  strength === 100
                    ? 100
                    : strength >= 75
                    ? (strength - 75) * 4
                    : 0
                }
                // color={strength === 100 ? color : "error"}
                sx={{
                  borderRadius: "4px",
                  backgroundColor: alpha(progressColors[3], 0.5),
                  "& .MuiLinearProgress-barColorPrimary": {
                    backgroundColor: progressColors[3],
                  },
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" color="textPrimary" align="right">
            {LL(label + "_password")}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export { PasswordStrengthChecker };
