import React, { useContext } from "react";
import { StudentCourseContext } from "context/studentcourse.context";
import { Status } from "tools//types/status";
import { useResource } from "hooks/useResource";
import { CourseEnrollmentDto } from "dto/course/courseenrollment.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CourseEnrollmentService } from "services/course/courseenrollment.service";
import { MyButton } from "components/elements/button/MyButton";
import { CommonTools } from "tools/utils/commontools";
import { Skeleton } from "@mui/material";
const service = new CourseEnrollmentService();

const RequestCertificate: React.FC = () => {
  const {
    state: { courseEnrollmentObject, courseEnrollmentStatus },
    actions: { setCourseEnrollmentObject, triggerReloadCourseEnrollment },
  } = useContext(StudentCourseContext);
  const { LL } = useResource();
  const [loading, setLoading] = React.useState<boolean>(false);
  const handleClick = () => {
    if (!courseEnrollmentObject) return;
    if (!courseEnrollmentObject.hasOwnProperty("id")) return;
    if (!courseEnrollmentObject.id) return;
    setLoading(true);
    const id = courseEnrollmentObject.id;
    service.requestCertificate(id, handleResult, {});
  };

  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    if (!result.err) {
      const obj = result.obj as CourseEnrollmentDto;
      if (obj) {
        setCourseEnrollmentObject(obj);
        triggerReloadCourseEnrollment(CommonTools.generateRandomString(6));
        setLoading(false);
      }
    }
  };

  const processComponent = () => {
    if (loading) return <Skeleton variant="rectangular" height={50} />;
    if (!courseEnrollmentObject) return null;
    if (courseEnrollmentStatus === Status.ENR_COURSE_FINISHED) {
      return (
        <MyButton cb={handleClick}>{LL("BT_RequestCertificate")}</MyButton>
      );
    }
    return null;
  };
  return processComponent();
};

export { RequestCertificate };
