import React from "react";

import { CourseFilterDto } from "dto/course/coursefilter.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { CourseFilterService } from "services/course/coursefilter.service";

import { Box, List, Skeleton, Typography, Stack } from "@mui/material";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { useList } from "hooks/useList";

type Props = {
  idCourse: string;
};

const service = new CourseFilterService();

const CourseFilterList: React.FC<Props> = ({ idCourse, ...props }) => {
  // const [loading, setIsLoading] = useState(true);
  // const [objects, setObjects] = useState<Array<CourseFilterDto>>([]);

  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };

  const [loading, objects] = useList<CourseFilterDto>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("idcourse", [idCourse])],
      1,
      -1
    ),
    [idCourse]
  );

  // const getList = () => {
  //   setIsLoading(true);
  //   const reqList = new RequestListDTO();
  //   reqList.onpage = 999999;
  //   reqList.page = 1;
  //   reqList.filters = [];

  //   let f: RequestFilterDTO;

  //   f = new RequestFilterDTO();
  //   f.field = "idcourse";
  //   f.values = [];
  //   f.values.push(idCourse);
  //   reqList.filters.push(f);

  //   service.getList(loadObjects, {}, reqList);

  //   // setIsLoading(false);
  // };

  // const loadObjects = (result: ResultListDTO) => {
  //   if (!result) return;
  //   if (!result.err) {
  //     const objects = result.objects ? result.objects : [];
  //     // const total = result.total !== undefined ? result.total : -1;
  //     // const totalPage = result.totalpages !== undefined ? result.totalpages : -1;
  //     // const reqInfo = result.requestinfo ? result.requestinfo : {};
  //     setObjects(objects);
  //     // setTotal(total);
  //     // setTotalPage(totalPage);
  //     // setResponseParams(reqInfo);
  //   }
  // };

  // const checkLoading = () => {
  //   let load = false;

  //   // if (total === -1) return true;
  //   // if (totalPage === -1) return true;
  //   if (!objects) load = true;
  //   // if (objects.length === 0) load = true;
  //   // if (Object.keys(responseParams).length === 0) return true;

  //   setIsLoading(load);
  // };

  // useEffect(() => {
  //   getList();
  // }, [idCourse]);

  // useEffect(() => {
  //   checkLoading();
  // }, [objects]);

  const processCourseFilterValue = (obj: CourseFilterDto) => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("filterdictionary")) {
      return ComponentCommonTools.processObjectField(obj, "value");
    }
    return ComponentCommonTools.processObjectFieldOfObject(
      obj,
      "filterdictionary",
      "name"
    );
  };

  const processCourseFilterItem = (obj: CourseFilterDto, index: number) => {
    if (!obj) return null;
    if (!obj.id) return null;

    return (
      <Box key={index}>
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Typography>
            {ComponentCommonTools.processObjectFieldOfObject(
              obj,
              "filter",
              "name"
            )}
          </Typography>

          <Typography className="nameFilter">
            {processCourseFilterValue(obj)}
          </Typography>
        </Stack>
      </Box>
    );
  };

  const processCourseFilterList = () => {
    if (!objects) return null;
    if (!objects.length) return null;
    return (
      <List>
        {objects.map((item: CourseFilterDto, i: number) => {
          return processCourseFilterItem(item, i);
        })}
      </List>
    );
  };

  return loading ? <Skeleton /> : <Box>{processCourseFilterList()}</Box>;
};

export { CourseFilterList };
