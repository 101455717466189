import React, { useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { Stepper, Step, StepLabel, Typography, Box } from "@mui/material";
import { useResource } from "hooks/useResource";
import { BecomeTeacherStepPage } from "components/static/page/block/BecomeTeacherStepPage";
import { Config } from "tools/utils/config";

const StepperBecomeTeacher: React.FC<PageComponentProps> = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { LL } = useResource();

  const steps = [
    LL("Create_and_public_course"),
    LL("Students_select_the_course"),
    LL("Go_live_to_teach"),
    LL("Get_paid"),
  ];

  const getStepIcon = (step: number) => {
    switch (step) {
      case 0:
        return <div className="step1 stepp"></div>;
      case 1:
        return <div className="step2 stepp"></div>;
      case 2:
        return <div className="step3 stepp"></div>;
      case 3:
        return <div className="step4 stepp"></div>;
      default:
        return null;
    }
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <BecomeTeacherStepPage idParam={Config.ID_PAGE_1} index={0} />;
      case 1:
        return <BecomeTeacherStepPage idParam={Config.ID_PAGE_2} index={1} />;
      case 2:
        return <BecomeTeacherStepPage idParam={Config.ID_PAGE_3} index={2} />;
      case 3:
        return <BecomeTeacherStepPage idParam={Config.ID_PAGE_4} index={3} />;
      default:
        return "Unknown step";
    }
  };

  const processStepper = () => {
    return (
      <Box className="container becomeSteps" my={5}>
        <Typography variant="h2" textAlign="center" mb={4}>
          {LL("Cum ajungi să faci bani din pasiunea ta")}
        </Typography>
        <Stepper
          nonLinear
          activeStep={activeStep}
          sx={{ alignItems: "flex-start" }}
        >
          {steps.map((label, index) => (
            <Step
              key={label}
              onClick={() => setActiveStep(index)}
              className="step"
            >
              <StepLabel icon={getStepIcon(index)}>
                <Typography className="stepText">{label}</Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box my={5} width={"100%"} className="detailsContentBecome">
          {getStepContent(activeStep)}
        </Box>
      </Box>
    );
  };
  return processStepper();
};

export { StepperBecomeTeacher };
