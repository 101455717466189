import React from "react";

import { Types } from "tools/types/types";
import { StaticInfoPage } from "components/static/page/block/StaticInfoPage";
import { Config } from "tools/utils/config";

type Props = {
  type: number;
};
const TestStaticContent: React.FC<Props> = ({ type }) => {
  const processComponent = () => {
    switch (type) {
      case Types.TYPE_TEST_BEFORE_COURSE: {
        return (
          <StaticInfoPage
            idPage={Config.ID_PAGE_TEST_BEFORE_COURSE}
            cacheKeyName="TYPE_TEST_BEFORE_COURSE"
          />
        );
      }
      case Types.TYPE_TEST_LESSON: {
        return (
          <StaticInfoPage
            idPage={Config.ID_PAGE_TEST_LESSON}
            cacheKeyName="TYPE_TEST_LESSON"
          />
        );
      }
      case Types.TYPE_TEST_AFTER_COURSE: {
        return (
          <StaticInfoPage
            idPage={Config.ID_PAGE_TEST_AFTER_COURSE}
            cacheKeyName="TYPE_TEST_AFTER_COURSE"
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  return processComponent();
};

export { TestStaticContent };
