import React, { useState } from "react";
import { TopLogoBlock } from "./TopLogoBlock";

import { TopMenuBlock } from "components/static/menu/TopMenuBlock";
import { SignUpTeacherBlock } from "components/user/user/header/SignUpTeacherBlock";
import { SignUpBlock } from "components/user/user/header/SignUpBlock";
import { SignInBlock } from "components/user/user/header/SignInBlock";
import { FavoriteBlock } from "components/user/user/header/FavoriteBlock";
import { SearchBlock } from "components/general/SearchBlock";
import { TopBar } from "components/general/TopBar";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useResource } from "hooks/useResource";
import { useAuth } from "hooks/useAuth";
import { UserInterfaceBlock } from "components/user/user/header/UserInterfaceBlock";
import { InfoCourseCategoryBlock } from "components/course/coursecategory/InfoCourseCategoryBlock";

import { MainDrawer } from "components/elements/Drawer/MainDrawer";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { HeaderTeacher } from "components/user/teacher/HeaderTeacher";
import { Types } from "tools/types/types";

import { CommonTools } from "tools/utils/commontools";
import { NotificationTopMenu } from "components/notification/NotificationTopMenu";

type HeaderProps = {
  currentRoute: any;
  showLogo: boolean;
};

const Header: React.FC<HeaderProps> = ({
  currentRoute,
  showLogo,
  ...props
}) => {
  const { user, userInterface } = useAuth();
  const { isTeacher } = useResource();

  const [openDrawer, setOpenDrawer] = useState(false);
  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const processTeacherHeader = () => {
    return <HeaderTeacher currentRoute={currentRoute} />;
  };
  return (
    <Grid container className="row headerStyle">
      <Box className="el1">
        <IconButton onClick={handleOpenDrawer}>
          <MenuIcon />
        </IconButton>
        <MainDrawer
          open={openDrawer}
          setOpen={setOpenDrawer}
          currentRoute={currentRoute}
        />
      </Box>
      {showLogo && (
        <Box className="el2">
          <TopLogoBlock currentRoute={currentRoute} />
        </Box>
      )}
      <Box className="el3">
        {!CommonTools.isUserInterface(user, userInterface) ? (
          <TopMenuBlock currentRoute={currentRoute} />
        ) : null}
      </Box>
      <Box className="el4">
        {showLogo ? (
          <InfoCourseCategoryBlock currentRoute={currentRoute} />
        ) : null}
      </Box>
      <Box className="el5">
        <Box className="el5_1">
          {!isTeacher() ? (
            <SignUpTeacherBlock currentRoute={currentRoute} />
          ) : null}
        </Box>
        <Box className="el5_2">
          {!CommonTools.isUserInterface(user, userInterface) ? (
            <SearchBlock currentRoute={currentRoute} />
          ) : null}
        </Box>
        <Box className="el5_3">
          {!CommonTools.isUserInterface(user, userInterface) ? (
            <FavoriteBlock currentRoute={currentRoute} />
          ) : null}
        </Box>
        {user ? (
          <Box className="el5_4">
            {!CommonTools.isUserInterface(user, userInterface) ? (
              <NotificationTopMenu
                currentRoute={currentRoute}
                typeRecipient={Types.TYPE_NOTIFICATION_RECIPIENT_STUDENT}
              />
            ) : null}
          </Box>
        ) : null}
        <Box className="el5_5">
          {!user ? (
            <>
              <SignUpBlock currentRoute={currentRoute} />
              <SignInBlock currentRoute={currentRoute} />
            </>
          ) : (
            <>
              {!CommonTools.isUserInterface(user, userInterface) ? (
                <>
                  <UserInterfaceBlock currentRoute={currentRoute} />
                  <TopBar currentRoute={currentRoute} />
                </>
              ) : (
                processTeacherHeader()
              )}
            </>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export { Header };
