import Idto from 'interfaces/idto.interface';

export class BecomeTeacherDto implements Idto {
    
    iduser?: string;
    idtypespecialty?: string;
    idtypetargetgroup?: string;
    idtypeinternetconnection?: string;
    idtypehardware?: string;
    idtypelesson?: string;
    idtypediscipline?: string;
    idtypeoccupation?: string;
    idtypestudies?: string;
    idtypeprofessionalismlevel?: string;
    idtypeexperience?: string;
    bio?: string;
    timetoteachperweek?: string;
    hasaudience?: boolean;
    graduatedinstitution?: string;
    whyliveclass?: string;
    notificationtype1?: boolean;
    notificationtype2?: boolean;
    notificationtype3?: boolean;

    constructor(
        iduser?: string,
        idtypespecialty?: string,
        idtypetargetgroup?: string,
        idtypeinternetconnection?: string,
        idtypehardware?: string,
        idtypelesson?: string,
        idtypediscipline?: string,
        idtypeoccupation?: string,
        idtypestudies?: string,
        idtypeprofessionalismlevel?: string,
        idtypeexperience?: string,
        bio?: string,
        timetoteachperweek?: string,
        hasaudience?: boolean,
        graduatedinstitution?: string,
        whyliveclass?: string,
        notificationtype1?: boolean,
        notificationtype2?: boolean,
        notificationtype3?: boolean
    ) {
        this.iduser = iduser || '';
        this.idtypespecialty = idtypespecialty || '';
        this.idtypetargetgroup = idtypetargetgroup || '';
        this.idtypeinternetconnection = idtypeinternetconnection || '';
        this.idtypehardware = idtypehardware || '';
        this.idtypelesson = idtypelesson || '';
        this.idtypediscipline = idtypediscipline || '';
        this.idtypeoccupation = idtypeoccupation || '';
        this.idtypestudies = idtypestudies || '';
        this.idtypeprofessionalismlevel = idtypeprofessionalismlevel || '';
        this.idtypeexperience = idtypeexperience || '';
        this.bio = bio || '';
        this.timetoteachperweek = timetoteachperweek || '';
        this.hasaudience = hasaudience || false;
        this.graduatedinstitution = graduatedinstitution || '';
        this.whyliveclass = whyliveclass || '';
        this.notificationtype1 = notificationtype1 || false;
        this.notificationtype2 = notificationtype2 || false;
        this.notificationtype3 = notificationtype3 || false;
    }
    
}
