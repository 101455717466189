import React, { useState } from "react";
import { useResource } from "hooks/useResource";

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { MyButton } from "components/elements/button/MyButton";
import { TestResultQuestionList } from "./TestResultQuestionList";
import { Widget } from "ckeditor5";

type Props = {
  idTestResult: string;
};
const TestResultQuestionDialog: React.FC<Props> = ({ idTestResult }) => {
  const { LL } = useResource();

  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const processList = () => {
    if (!idTestResult) return null;
    if (!open) return null;
    return <TestResultQuestionList idTestResult={idTestResult} />;
  };

  return (
    <Box>
      <MyButton
        cb={handleOpen}
        sx={{ width: { xs: "100%", sm: "auto" } }}
        className="color-black-pearl">
        {LL("Review_question_answer")}
      </MyButton>
      <Dialog open={open} onClose={handleClose} fullWidth className="dialogAdd">
        <DialogTitle>{LL("TestResultQuestion")}</DialogTitle>
        <IconButton
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
          onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>{processList()}</DialogContent>
      </Dialog>
    </Box>
  );
};
export { TestResultQuestionDialog };
