import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hooks/useResource";

import { MyNavBt } from "components/elements/button/MyNavBt";
import { useAuth } from "hooks/useAuth";

const SignUpBlock: React.FC<PageComponentProps> = ({
  currentRoute,
}) => {
  const { LL } = useResource();

  const { user } = useAuth();
  return user ? null : (
    <MyNavBt href={"/signup"} className="btnTop btnSignUpTop">
      {LL("BT_SignUpTop")}
    </MyNavBt>
  );
};

export { SignUpBlock };
