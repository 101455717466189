import React from "react";
import { useResource } from "hooks/useResource";

import { CourseService } from "services/course/course.service";

import { CourseDto } from "dto/course/course.dto";

import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools//types/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";

import { Box } from "@mui/material";

import { CourseCard } from "components/course/course/CourseCard";
import { Carousel } from "components/elements/Carousel/Carousel";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { CommonTools } from "tools/utils/commontools";
import { SkeletonCourseBlock } from "components/elements/Skeleton/Skeleton";
import { CallbackType } from "interfaces/commontypes.interface";
import { useList } from "hooks/useList";
import { useSlidesToShow } from "hooks/useSlidesToShow";
import { parse } from "path";

const service = new CourseService();

type Props = {
  currentRoute: any;
  // id: string;
};

const PopularCourses: React.FC<Props> = () => {
  // const [isLoading, setIsLoading] = useState(true);
  // const [objects, setObjects] = useState<Array<CourseDto>>([]);
  const { CC } = useResource();

  const defSlides = parseInt(
    CC("DefaultValueShowPopularCourseListByValueCarousel", "3")
  );

  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    service.getListPopular(cb, cbParams, req);
  };

  const [loading, objects] = useList<CourseDto>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()])],
      1,
      parseInt(CC("course_list_popular", "10")),
      [RequestSortCriteriaDTO.prepareSortCriteria("popularidentifier", false)]
    ),
    [],
    true,
    "course_list_popular"
  );

  const { slidesToShow } = useSlidesToShow({
    objects: objects || [],
    parseInt: defSlides,
  });
  // const getList = () => {
  //   const reqList = new RequestListDTO();
  //   reqList.onpage = 100;
  //   reqList.page = 1;
  //   reqList.filters = [];
  //   reqList.sortcriteria = [];
  //   let sortCriteria = new RequestSortCriteriaDTO();
  //   sortCriteria.field = "popularidentifier";
  //   sortCriteria.asc = false;
  //   reqList.sortcriteria.push(sortCriteria);

  //   let f: RequestFilterDTO;

  //   f = new RequestFilterDTO();
  //   f.field = "status";
  //   f.values = [];
  //   f.values.push(Status.ACTIVE.toString());
  //   reqList.filters.push(f);

  //   setIsLoading(true);

  //   service.getListPopular(loadObjects, {}, reqList);

  //   // setIsLoading(false);
  // };

  // const loadObjects = (result: ResultListDTO) => {
  //   if (!result) return;
  //   if (!result.err) {
  //     const objects = result.objects ? result.objects : [];
  //     setObjects(objects);
  //   }
  // };

  // const checkLoading = () => {
  //   let load = false;

  //   if (!objects) load = true;
  //   if (objects.length === 0) load = true;

  //   setIsLoading(load);
  // };
  // useEffect(() => {
  //   getList();
  // }, []);

  // useEffect(() => {
  //   checkLoading();
  // }, [objects]);

  const processCourseItem = (obj: CourseDto, index: number) => {
    return (
      <Box key={index}>
        <CourseCard obj={obj} loading={loading} />
      </Box>
    );
  };

  const processCourseList = () => {
    if (!objects) return null;
    if (!objects.length) return null;

    return (
      <Carousel _slidesToShow={slidesToShow}>
        {objects.map((item: CourseDto, i: number) => {
          return processCourseItem(item, i);
        })}
      </Carousel>
    );
  };

  return loading ? <SkeletonCourseBlock /> : <Box>{processCourseList()}</Box>;
};

export { PopularCourses };
