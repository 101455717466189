import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { Box } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { useResource } from "hooks/useResource";

const HeaderPrint: React.FC<PageComponentProps> = ({ currentRoute }) => {
  const { LL } = useResource();
  return (
    <Box>
      <Box>
        <MyButton cb={() => {}}>{LL("Print")}</MyButton>
      </Box>
      <Box>
        <MyButton cb={() => {}}>{LL("Download")}</MyButton>
      </Box>
    </Box>
  );
};

export { HeaderPrint };
