import React, { useEffect, useState } from "react";
import { SiteSocialDto } from "dto/static/sitesocial.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { CommonTools } from "tools/utils/commontools";

type Props = {
  obj: SiteSocialDto;
  width: number;
  height: number;
};

const SocialItemIcon: React.FC<Props> = ({
  obj,
  width,
  height,
  ...props
}) => {
  const [imgUrl, setImgUrl] = useState<string>("");
  const [fullUrl, setFullUrl] = useState<string>("");

  const prepareGallery = () => {
    if (!obj) return;
    if (!obj.typesocial) return;
    if (!obj.typesocial.hasOwnProperty("gallery")) return;
    if (!obj.typesocial.gallery) return;
    if (obj.typesocial.gallery.length === 0) return;
    const galleryObj = CommonTools.getGalleryObjectLogo(obj.typesocial.gallery);
    if (!galleryObj) return;
    if (!width) width = 50;
    if (!height) height = 50;
    CommonTools.setFileUrlsGallery(
      galleryObj,
      setImgUrl,
      setFullUrl,
      width,
      height
    );
  };

  useEffect(() => {
    prepareGallery();
  }, [obj]);

  const processIcon = () => {
    if (!obj) return null;
    if (!obj.link) return null;
    if (!obj.typesocial) return null;

    return <>{ComponentCommonTools.getFileBlock(imgUrl, 50, 50)}</>;
  };

  return processIcon();
};

export { SocialItemIcon };
