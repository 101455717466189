import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { HtmlTag } from "components/form/HtmlTag";
import { Config } from "tools/utils/config";
import { usePage } from "hooks/usePage";
import { Skeleton } from "@mui/material";
import { CommonTools } from "tools/utils/commontools";

const StudentNoCourseYet: React.FC<PageComponentProps> = () => {
  const id = Config.ID_PAGE_STUDENT_NO_COURSE_YET;
  const { object, loading } = usePage(id, true, "student_no_course_yet");
  // const { saveCache, getCache } = useResource();

  // const [isLoading, setIsLoading] = useState(true);
  // const [obj, setObject] = useState<PageDto | null>(null);

  // const loadObject = (_obj: any) => {

  //     setObject(_obj.obj);
  //     setIsLoading(false);
  //     saveCache(_obj.obj, "StudentNoCourseYet");
  // }

  // const getObject = async () => {
  //     const id = process.env.REACT_APP_ID_PAGE_STUDENT_NO_COURSE_YET ?? "-1";

  //     const cd = getCache("StudentNoCourseYet");
  //     if (cd !== false) {
  //         setObject(cd);
  //         setIsLoading(false);
  //         return;
  //     }

  //     setIsLoading(true);

  //     pageService.get(id, loadObject);
  // };

  // useEffect(() => {
  //     getObject();
  // }, []);

  if (loading) return <Skeleton />;
  if (!object) return null;
  return (
    <HtmlTag content={CommonTools.processObjectField(object, ["content"])} />
  );
};

export { StudentNoCourseYet };
