import { Chip, ChipProps } from "@mui/material";

type Service = {
  LL: (key: any) => string;
};

type Props = ChipProps & {
  value: number | string;
  processColor: (
    value: number
  ) => "warning" | "error" | "success" | "primary" | "secondary";
  service: Service;
};

const GeneralChip: React.FC<Props> = ({
  value,
  processColor,
  service,
  ...props
}) => {
  if (!value) return null;
  if (typeof value === "string") value = parseInt(value);
  return (
    <Chip
      label={service.LL(value)}
      {...props}
      size="small"
      color={processColor(value)}
    />
  );
};

export { GeneralChip };
