import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CommonTools } from "tools/utils/commontools";
import { Box, Stack, Typography, Grid, Skeleton } from "@mui/material";
import { StudentDto } from "dto/user/student.dto";

import { StudentService } from "services/user/student.service";
import { HtmlTag } from "components/form/HtmlTag";
import { useResource } from "hooks/useResource";

import UserSocialBlock from "components/user/user/social/UserSocialBlock";
import { Types } from "tools/types/types";
import { CourseListByStudentDisplayBlock } from "components/course/course/CourseListByStudentDisplayBlock";
import { NewsletterBlock } from "components/general/NewsletterBlock";

import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";
import { RecommendedCourseForStudent } from "components/course/course/RecommendedCourseForStudent";
import { CallbackType } from "interfaces/commontypes.interface";
import useObject from "hooks/useObject";

const service = new StudentService();

const PublicStudentProfile: React.FC<PageComponentProps> = ({
  currentRoute,
}) => {
  const { LL } = useResource();
  // const [idStudent, setIdStudent] = useState("");
  // const [obj, setObj] = useState<StudentDto | undefined>();
  // const [loading, setLoading] = useState<boolean>(true);
  const id = CommonTools.getIdFromPath(currentRoute, "student");
  // useEffect(() => {
  //   setIdStudent(CommonTools.getIdFromPath(currentRoute, "student"));
  // }, [currentRoute]);

  const getObject = (id: string, cb?: CallbackType, cbParams?: any) => {
    if (!id) return;
    service.getByIdOrIdUser(id, cb, cbParams);
  };

  const [loading, obj] = useObject<StudentDto>(getObject, id, [id]);

  // const loadObject = (obj: ResultObjectDTO) => {
  //   if (!obj) return;
  //   if (!obj.err) {
  //     const object = obj.obj ? obj.obj : {};
  //     setObj(object);
  //   }
  // };

  // const checkIsLoading = useCallback(() => {
  //   let load: boolean = false;
  //   if (obj === undefined) load = true;
  //   setLoading(load);
  // }, [obj]);

  // useEffect(() => {
  //   if (!idStudent) return;
  //   getObject();
  // }, [idStudent]);

  // useEffect(() => {
  //   checkIsLoading();
  // }, [checkIsLoading]);

  const processFullName = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("name") && !obj.name) return null;
    if (!obj.hasOwnProperty("surname") && !obj.surname) return null;
    return (
      <Stack direction="row" spacing={1}>
        <Typography variant="h3">
          {ComponentCommonTools.processObjectField(obj, "name")}
        </Typography>
        <Typography variant="h3">
          {ComponentCommonTools.processObjectField(obj, "surname")}
        </Typography>
      </Stack>
    );
  };

  const processAvatar = () => {
    if (!obj) return null;
    let name = "";
    let surname = "";
    if (obj.hasOwnProperty("name") && obj.name) name = obj.name;
    if (obj.hasOwnProperty("surname") && obj.surname) surname = obj.surname;

    return (
      <MyAvatar
        className="avatar"
        obj={obj}
        name={name}
        surname={surname}
        sx={{ mr: 1 }}
      />
    );
  };

  const processTypeStudies = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("typestudies")) return null;
    if (!obj.typestudies) return null;
    return (
      <Typography className="discipline">
        {ComponentCommonTools.processObjectFieldOfObject(
          obj,
          "typestudies",
          "name"
        )}
      </Typography>
    );
  };
  const processInstitution = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("institution")) return null;
    if (!obj.institution) return null;
    return (
      <Typography>
        {ComponentCommonTools.processObjectField(obj, "institution")}
      </Typography>
    );
  };

  const processUserSocials = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("iduser")) return null;
    if (!obj.iduser) return null;
    return (
      <Box className="socials">
        <UserSocialBlock
          idUser={obj.iduser}
          type={Types.TYPE_SOCIAL_DISPLAY_SHORT_FORM}
        />
      </Box>
    );
  };

  const processStudentCourses = () => {
    if(!obj) return null;
    if (!id) return null;
    return (
      <Box mt={5}>
        <Typography variant="h2" mb={2}>
          {LL("StudentCourses")}
        </Typography>
        <CourseListByStudentDisplayBlock idStudent={CommonTools.processObjectField(obj,["id"])} />
      </Box>
    );
  };

  const processBio = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("bio")) return null;
    if (!obj.bio) return null;
    return (
      <Box>
        <Typography variant="h5" mt={5} mb={2}>
          {LL("About_me")}
        </Typography>
        <HtmlTag content={obj.bio} />
      </Box>
    );
  };

  const processRecommendedCourses = () => {
    if (!obj) return null;
    if (!id) return null;

    return (
      <Box>
        <Typography variant="h5" my={2}>
          {LL("Recommended_courses")}
        </Typography>
        <RecommendedCourseForStudent idStudent={CommonTools.processObjectField(obj,["id"])} />
      </Box>
    );
  };

  const processStudentInfo = () => {
    if (!obj) return null;
    return (
      <Stack direction="row" spacing={2} alignItems="center">
        {processAvatar()}
        <Stack direction="column">
          {processFullName()}
          <Stack sx={{ my: 1 }} direction="row" spacing={1} divider={<>,</>}>
            {processTypeStudies()}
            {processInstitution()}
          </Stack>
          {processUserSocials()}
        </Stack>
      </Stack>
    );
  };

  if (loading) return <Skeleton />;
  if (!obj) return null;
  return id && !loading ? (
    <React.Fragment>
      <Box className="container studentProfile" pb={3}>
        <Grid container direction="row" py={5} spacing={2}>
          <Grid item sm={12} md={6} className="aboutStudent">
            {processStudentInfo()}
            {processBio()}
          </Grid>
          <Grid item sm={12} md={1}></Grid>
          <Grid item sm={12} md={5}>
            {processRecommendedCourses()}
          </Grid>
        </Grid>
        {processStudentCourses()}
      </Box>
      <NewsletterBlock currentRoute={currentRoute} />
    </React.Fragment>
  ) : null;
};

export { PublicStudentProfile };
