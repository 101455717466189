import React, { useEffect, useState } from "react";

import { useResource } from "hooks/useResource";

import { MySelect } from "components/elements/Select/MySelect";

import SelectOptions from "dto/app/selectoptions.dto";

import Locale from "react-phone-number-input/locale/en.json";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import { Skeleton } from "@mui/material";

type CountryCodeSelectProps = {
  setObj: (field: any, value: any) => void;
  field: any;
  obj: any;
  _defaultValue?: any;
  [key: string]: any; // for the rest of the props which we do not have types for
};

const CountryCodeSelect: React.FC<CountryCodeSelectProps> = ({
  setObj,
  field,
  obj,
  _defaultValue,
  ...props
}) => {
  const { LL } = useResource();

  const label = "";
  // process.env.REACT_APP_COUNTRY_CODE
  const defaultCountryCode = process.env.REACT_APP_COUNTRY_CODE ?? "+40";
  const defaultValue = _defaultValue ? _defaultValue : "";
  const [options, setOptions] = useState<Array<SelectOptions>>(
    new Array<SelectOptions>()
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [value, setValue] = useState<string>();
  const [data, setData] = useState<Array<any> | undefined>();

  const country = getCountries();

  const getList = () => {
    setLoading(true);
    const objects = country.map((item) => ({
      name: Locale[item],
      countryCode: getCountryCallingCode(item),
      code: item,
    }));
    setData(objects);
  };

  const processOptions = () => {
    if (data !== undefined) {
      const options: Array<SelectOptions> = data.map((item) => {
        const obj = {
          label: "+" + item.countryCode + " " + item.name,
          value: "+" + item.countryCode,
          otherData: item.code,
        };
        return obj;
      });
      setOptions(options);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (data !== undefined) processOptions();
  }, [data]);

  useEffect(() => {
    checkIsLoading();
  }, [options]);

  useEffect(() => {
    if (loading) return;

    setObj(field, value);
  }, [value]);

  const processOptionsCustom = () => {
    if (!options) return <></>;
    if (options.length === 0) return <></>;
    return options.map((item, index) => (
      <MenuItem key={index} value={item.value}>
        {getUnicodeFlagIcon(item.otherData)} {item.label}
      </MenuItem>
    ));
  };

  const checkIsLoading = () => {
    let load: boolean = false;
    if (data === undefined) load = true;
    if (data !== undefined && data.length === 0) load = true;
    if (options === undefined) load = true;
    if (options.length === 0) load = true;
    // if (value === undefined) load = true;
    setLoading(load);
    if (!load) setValue(defaultCountryCode);
  };

  return !loading && options.length > 0 ? (
    <MySelect
      options={options}
      _label={label}
      setValue={setValue}
      value={value}
      processOptionsCustom={processOptionsCustom}
      {...props}
    />
  ) : (
    <Skeleton variant="rectangular" height={50} />
  );
};

export { CountryCodeSelect };
