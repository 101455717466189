import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

import { TopMenuLevel } from "./TopMenuLevel";

const TopMenuBlock: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  
  return <TopMenuLevel currentRoute={currentRoute} level={1} idparent={""} />; 
};

export { TopMenuBlock };
