import { Skeleton } from "@mui/material";

import ResultObjectDTO from "dto/app/resultobject.dto";

import { LessonDto } from "dto/course/lesson.dto";

import { useResource } from "hooks/useResource";
import React, { useState, useContext } from "react";
import { LessonService } from "services/course/lesson.service";
import { MyButton } from "components/elements/button/MyButton";
import { ClassroomContext } from "context/classroom.context";

type Props = {
  obj: LessonDto;
};

const service = new LessonService();
const ClassroomPassLesson: React.FC<Props> = ({ obj, ...props }) => {
  const { LL } = useResource();
  const {
    actions: { updateLessonObject },
  } = useContext(ClassroomContext);

  const [loading, setLoading] = useState<boolean>(false);
  const handleAction = () => {
    if (!obj) return;
    if (!obj.hasOwnProperty("id")) return;
    if (!obj.id) return;
    setLoading(true);
    service.updatePassed(obj.id, handleActionCb, {});
  };

  const handleActionCb = (result: ResultObjectDTO) => {
    if (!result) return;
    if (!result.err) {
      const obj = result.obj as LessonDto;
      if (obj) {
        updateLessonObject(obj);
      }
      setLoading(false);
    }
  };

  const processComponent = () => {
    if (loading) return <Skeleton variant="rectangular" />;
    if (!obj) return null;
    if (obj.hasOwnProperty("ispassed") && obj.ispassed) return null;

    return (
      <MyButton cb={handleAction} {...props}>
        {LL("BT_PassLesson")}
      </MyButton>
    );
  };

  return processComponent();
};

export { ClassroomPassLesson };
