import { Edit, Delete } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { AddressDto } from "dto/nomenclature/address.dto";
import React from "react";
import { CommonTools } from "tools/utils/commontools";

type Props = {
  obj: AddressDto;
  handleUpdate: (obj: AddressDto) => void;
  handleDelete: (obj: AddressDto) => void;
};

const AddressItem: React.FC<Props> = ({ obj, handleDelete, handleUpdate }) => {
  if (!obj) return null;
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container py={2} spacing={2}>
        <Grid item xs={10}>
          {CommonTools.processObjectField(obj, ["street"])}{" "}
          {CommonTools.processObjectField(obj, ["streetnumber"])}{" "}
          {CommonTools.processObjectField(obj, ["zipcode"])}{" "}
          {CommonTools.processObjectField(obj, ["locality", "name"])}
        </Grid>
      </Grid>
      <Grid item xs={2} ml={0}>
        <MyButton
          type="button"
          cb={handleUpdate.bind(this, obj)}
          color="primary"
        >
          <Edit />
        </MyButton>

        <MyButton
          color="secondary"
          type="button"
          cb={handleDelete.bind(this, obj)}
        >
          <Delete />
        </MyButton>
      </Grid>
    </Box>
  );
};

export { AddressItem };
