import { Box, Typography } from "@mui/material";
import { FormCreateCourseFirstStep } from "components/course/courseteacherinterface/FormCreateCourseFirstStep";
import { CircularLoading } from "components/elements/loading/CircularLoading";
import { CreateCourseFirstStepDto } from "dto/course/course.dto";
import { useAuth } from "hooks/useAuth";
import { useResource } from "hooks/useResource";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import React, { useState } from "react";
import { CourseService } from "services/course/course.service";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

const service = new CourseService();

const CreateCourseFirstStep: React.FC<PageComponentProps> = () => {
  const { LL } = useResource();
  const { teacher } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = (obj: CreateCourseFirstStepDto) => {
    setLoading(true);
    service.addFirstStep(
      ComponentCommonTools.handleSuccessAndCreateRouteWithId,
      {
        errorRoute: "/teacherinterface/course",
        route: "/teacherinterface/editcourse",
        cb: cb,
      },
      obj
    );
  };

  const cb = () => {
    setLoading(false);
  };
  if (!teacher) return null;
  return !loading ? (
    <Box className="container" maxWidth="md">
      <Typography variant="h2" textAlign="center" my={4}>
        {LL("AddBlock")}
      </Typography>
      <Box>
        <FormCreateCourseFirstStep
          defaultObject={
            new CreateCourseFirstStepDto(
              CommonTools.processObjectField(teacher, ["id"])
            )
          }
          onSubmit={onSubmit}
          loading={loading}
        />
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
      }}
    >
      <CircularLoading />
    </Box>
  );
};

export { CreateCourseFirstStep };
