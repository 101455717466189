import { Box, Typography } from "@mui/material";
import { CircularLoading } from "components/elements/loading/CircularLoading";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { AnswerDto } from "dto/course/answer.dto";
import { QuestionDto } from "dto/course/question.dto";
import { TestResultDto } from "dto/course/testresult.dto";
import {
  TestResultAnswerBodyDto,
  TestResultQuestionBodyDto,
} from "dto/course/testresultquestion.dto";
import { useList } from "hooks/useList";
import { CallbackType } from "interfaces/commontypes.interface";

import React, { useCallback, useEffect, useState } from "react";
import { AnswerService } from "services/course/answer.service";
import { Types } from "tools/types/types";
import { CommonTools } from "tools/utils/commontools";
import { AnswerSingleQuestionOption } from "../AnswerSingleQuestionOption";
import { AnswerMultipleQuestionOption } from "../AnswerMultipleQuestionOption";

type Props = {
  obj: QuestionDto;
  setQuestionResult: (value: TestResultQuestionBodyDto) => void;
  setAnswersResult: (value: Array<TestResultAnswerBodyDto>) => void;
  testResult: TestResultDto;
};

const service = new AnswerService();
const NewTestQuestionItem: React.FC<Props> = ({
  obj,
  setQuestionResult,
  setAnswersResult,
  testResult,
}) => {
  const id = CommonTools.processObjectField(obj, ["id"]);
  const [answerSelected, setAnswerSelected] = useState<Array<string>>([]);

  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    if (!id) return;
    service.getListUserTest(cb, cbParams, req);
  };

  const [loading, objects] = useList<AnswerDto>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("idquestion", [id])],
      1,
      -1
    ),
    [id]
  );

  const processQuestionResult = useCallback(() => {
    if (!obj || !testResult) return;
    if (!obj.id || !testResult.id) return;
    setQuestionResult(
      new TestResultQuestionBodyDto(obj.id, testResult.id, obj.totalscore ?? 0)
    );
  }, [obj, testResult]);

  const processAnswerResult = useCallback(() => {
    if (!testResult || !objects) return;
    if (!testResult.id) return;
    if (!answerSelected.length || !objects.length) {
      setAnswersResult([]);
      return;
    }

    const resultAnswers = answerSelected.map((answer) => {
      return new TestResultAnswerBodyDto(
        answer,
        CommonTools.processObjectField(testResult, ["id"])
      );
    });

    setAnswersResult(resultAnswers);
  }, [answerSelected, objects, testResult]);

  useEffect(() => {
    processQuestionResult();
  }, [processQuestionResult]);

  useEffect(() => {
    processAnswerResult();
  }, [processAnswerResult]);

  const processAnswerList = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("type")) return null;
    if (!obj.type) return null;
    if (!objects) return null;
    if (!objects.length) return null;

    if (obj.type === Types.TYPE_QUESTION_SINGLE_CHOICE) {
      return (
        <Box>
          <AnswerSingleQuestionOption
            obj={obj}
            objects={objects}
            answerSelected={answerSelected}
            setAnswerSelected={setAnswerSelected}
          />
        </Box>
      );
    }
    if (obj.type === Types.TYPE_QUESTION_MULTIPLE_CHOICE) {
      return (
        <Box>
          <AnswerMultipleQuestionOption
            obj={obj}
            objects={objects}
            answerSelected={answerSelected}
            setAnswerSelected={setAnswerSelected}
          />
        </Box>
      );
    }
  };

  if (!id) return null;
  if (!obj) return null;
  if (loading) return <CircularLoading />;
  return (
    <Box>
      <Typography variant="h4" textAlign="center" mb={3}>
        {CommonTools.processObjectField(obj, ["name"])}
      </Typography>
      {processAnswerList()}
    </Box>
  );
};

export { NewTestQuestionItem };
