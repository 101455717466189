import { TestDto } from "dto/course/newtest.dto";
import React from "react";
import { EditTest } from "./EditTest";

type Props = {
  defaultObj: TestDto;
  updateList: (obj: TestDto) => void;
  deleteFromList: (id: string) => void;
  idCourse?: string;
  idLesson?: string;
  allowEdit?: boolean;
  allowDelete?: boolean;
};

const BlockTest: React.FC<Props> = ({
  defaultObj,
  updateList,
  deleteFromList,
  idCourse = "",
  idLesson = "",
  allowEdit = false,
  allowDelete = false,
}) => {
  const handleUpdate = (obj: TestDto | null) => {
    if (!obj) return;
    updateList(obj);
  };

  return (
    <EditTest
      object={defaultObj}
      setObject={handleUpdate}
      idCourse={idCourse}
      idLesson={idLesson}
      allowEdit={allowEdit}
      allowDelete={allowDelete}
      deleteFromList={deleteFromList}
    />
  );
};

export { BlockTest };
