import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import { TicketDto } from "./ticket.dto";

export class TicketMessageDto implements Idto {
  id?: string;
  status?: number;
  idoperator?: string;
  idstudent?: string;
  idteacher?: string;
  typesender?: number;
  idticket?: string;
  message?: string;
}

export class PostTicketMessageDto implements Idto {
  typesender?: number;
  status?: number;
  idoperator?: string;
  idstudent?: string;
  idteacher?: string;
  idticket?: string;
  message?: string;
}

export class ResponseTicketStudentDto implements Idto {
  idticket?: string;
  message?: string;
  idstudent?: string;
  typesender?: number;
  status?: number;

  constructor(idticket?: string, idstudent?: string, message?: string) {
    this.idticket = idticket || "";
    this.message = message || "";
    this.idstudent = idstudent || "";
    this.typesender = Types.TYPE_SENDER_STUDENT;
    this.status = Status.UNREAD;
  }
}

export class ResponseTicketTeacherDto implements Idto {
  idticket?: string;
  message?: string;
  idteacher?: string;
  typesender?: number;
  status?: number;

  constructor(idticket?: string, idteacher?: string, message?: string) {
    this.idticket = idticket || "";
    this.message = message || "";
    this.idteacher = idteacher || "";
    this.typesender = Types.TYPE_SENDER_TEACHER;
    this.status = Status.UNREAD;
  }
}


export class ResponseTicketDto implements Idto {
  ticket: TicketDto;
  ticketmessage: TicketMessageDto;
  constructor(ticket: TicketDto, ticketmessage: TicketMessageDto) {
    this.ticket = ticket;
    this.ticketmessage = ticketmessage;
  }
}