import { Box, Grid, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import MyCKEditor from "components/elements/ckeditor/MyCKEditor";
import { DisciplineTypeSelect } from "components/elements/Select/DisciplineTypeSelect";
import { ExperienceTypeSelect } from "components/elements/Select/ExperienceTypeSelect";
import { HardwareTypeSelect } from "components/elements/Select/HardwareTypeSelect";
import { InternetConnectionTypeSelect } from "components/elements/Select/InternetConnectionTypeSelect";
import { LessonTypeSelect } from "components/elements/Select/LessonTypeSelect";
import { OccupationTypeSelect } from "components/elements/Select/OccupationTypeSelect";
import { ProfessionalismLevelTypeSelect } from "components/elements/Select/ProfessionalismLevelTypeSelect";
import { SpecialtyTypeSelect } from "components/elements/Select/SpecialtyTypeSelect";
import { StudyTypeSelect } from "components/elements/Select/StudyTypeSelect";
import { TargetGroupTypeSelect } from "components/elements/Select/TargetGroupTypeSelect";
import { MyCheckboxField } from "components/form/MyCheckboxField";
import MyTextField from "components/form/mytextfield";
import { TeacherDto } from "dto/user/teacher.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React from "react";
import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: TeacherDto;
  onSubmit: (obj: TeacherDto) => void;
  loading: boolean;
};

const FormEditTeacher: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField] = useForm<TeacherDto>(
    defaultObject,
    RequiredValidator.getValidators(["iduser"])
  );
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    onSubmit(obj);
  };

  return (
    <Box sx={{ flexGrow: 1 }} className="general-form">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item lg={12} xs={12}>
            <Typography variant="h6">{LL("timetoteachperweek")}</Typography>
            <MyTextField
              tabIndex={0}
              fullWidth
              id="timetoteachperweek"
              name="timetoteachperweek"
              variant="standard"
              color="primary"
              className="form-input"
              _label={LL("timetoteachperweek")}
              size="small"
              value={obj.timetoteachperweek}
              setObj={setObjField}
              _vresults={undefined}
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <Typography variant="h6">{LL("whyliveclass")}</Typography>
            <MyTextField
              tabIndex={4}
              fullWidth
              id="whyliveclass"
              name="whyliveclass"
              variant="standard"
              color="primary"
              className="form-input"
              _label={LL("whyliveclass")}
              size="small"
              value={obj.whyliveclass}
              setObj={setObjField}
              _vresults={undefined}
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <Typography variant="h6">{LL("graduatedinstitution")}</Typography>
            <MyTextField
              tabIndex={5}
              fullWidth
              id="graduatedinstitution"
              name="graduatedinstitution"
              variant="standard"
              color="primary"
              className="form-input"
              _label={LL("graduatedinstitution")}
              size="small"
              value={obj.graduatedinstitution}
              setObj={setObjField}
              _vresults={undefined}
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <Typography variant="h6">{LL("hasaudience")}</Typography>
            <MyCheckboxField
              checked={obj.hasaudience}
              setObjectField={setObjField}
              field="hasaudience"
              name="hasaudience"
              label={LL("hasaudience")}
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <Typography variant="h6">{LL("TypeStudies")}</Typography>
            <StudyTypeSelect
              value={obj.idtypestudies}
              field="idtypestudies"
              setObjectField={setObjField}
              label=""
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <Typography variant="h6">{LL("TypeSpecialty")}</Typography>
            <SpecialtyTypeSelect
              value={obj.idtypespecialty}
              field="idtypespecialty"
              setObjectField={setObjField}
              label=""
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <Typography variant="h6">{LL("TypeTargetGroup")}</Typography>
            <TargetGroupTypeSelect
              value={obj.idtypetargetgroup}
              field="idtypetargetgroup"
              setObjectField={setObjField}
              label=""
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <Typography variant="h6">{LL("TypeInternetConnection")}</Typography>
            <InternetConnectionTypeSelect
              value={obj.idtypeinternetconnection}
              field="idtypeinternetconnection"
              setObjectField={setObjField}
              label=""
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <Typography variant="h6">{LL("TypeHardware")}</Typography>
            <HardwareTypeSelect
              value={obj.idtypehardware}
              field="idtypehardware"
              setObjectField={setObjField}
              label=""
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <Typography variant="h6">{LL("TypeLesson")}</Typography>
            <LessonTypeSelect
              value={obj.idtypelesson}
              field="idtypelesson"
              setObjectField={setObjField}
              label=""
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <Typography variant="h6">{LL("TypeOccupation")}</Typography>
            <OccupationTypeSelect
              value={obj.idtypeoccupation}
              field="idtypeoccupation"
              setObjectField={setObjField}
              label=""
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <Typography variant="h6">
              {LL("TypeProfessionalismLevel")}
            </Typography>
            <ProfessionalismLevelTypeSelect
              value={obj.idtypeprofessionalismlevel}
              field="idtypeprofessionalismlevel"
              setObjectField={setObjField}
              label=""
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <Typography variant="h6">{LL("TypeExperience")}</Typography>
            <ExperienceTypeSelect
              value={obj.idtypeexperience}
              field="idtypeexperience"
              setObjectField={setObjField}
              label=""
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <Typography variant="h6">{LL("TypeDiscipline")}</Typography>
            <DisciplineTypeSelect
              value={obj.idtypediscipline}
              field="idtypediscipline"
              setObjectField={setObjField}
              label=""
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <Typography variant="h6">{LL("bio")}</Typography>
            <MyCKEditor
              data={obj.bio ? obj.bio : ""}
              setObj={setObjField}
              name="bio"
              field="bio"
            />
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
            item
            xs={12}>
            <MyButton
              sx={{ mt: 4 }}
              tabIndex={4}
              disabled={disabled || loading}
              color="primary"
              className="btn"
              variant="contained"
              size="large"
              type="submit">
              {LL("Bt_Submit")}
            </MyButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export { FormEditTeacher };
