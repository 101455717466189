import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { StaticInfoPage } from "components/static/page/block/StaticInfoPage";
import Box from "@mui/material/Box";
import { Config } from "tools/utils/config";

const NotFound: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
  return (
    <Box
      sx={{
        width: "100%",
        // height: contentHeight,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: `calc(100vh - ${Config.HEADER_HEIGHT}px - ${Config.FOOTER_HEIGHT}px)`,
        minHeight: "400px",
      }}>
      <StaticInfoPage
        idPage={process.env.REACT_APP_ID_PAGE_404}
        cacheKeyName="page_404"
      />
    </Box>
  );
};

export { NotFound };
