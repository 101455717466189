import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hooks/useResource";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";
import { useAuth } from "hooks/useAuth";
import { Divider } from "@mui/material";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabPanel } from "components/elements/Tabs/TabPanel";

import { RouteTools } from "tools/utils/routetools";

import { CommonTools } from "tools/utils/commontools";

import { ChangePasswordForm } from "components/auth/ChangePasswordForm";

import AddUserPhone from "components/user/user/phone/AddUserPhone";
import AddUserAddress from "components/user/user/address/AddUserAddress";
import { UserEditStudent } from "components/user/student/UserEditStudent";

import { EditStudentNotification } from "components/user/student/EditStudentNotification";
import { EditSocialUser } from "components/static/social/EditSocialUser";
import EditUserSettingsInfo from "./EditUserSettingsInfo";

const EditUserProfile: React.FC<PageComponentProps> = ({ currentRoute }) => {
  const { LL } = useResource();
  const { user } = useAuth();

  // const [loading, setLoading] = useState<boolean>(true);
  // const [obj, setObj] = useState<UserDto | undefined>(user);
  // const _id = user !== undefined ? user.id : "";
  // const [id, setId] = useState<string>(_id);

  // useEffect(() => {
  //   if (user !== undefined) {
  //     setId(user.id);
  //     setLoading(false);
  //   }
  // }, [user]);

  const [value, setValue] = React.useState(
    CommonTools.getAnchor(currentRoute, "generalInfo")
  );

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setValue(newValue);
    RouteTools.setAnchor(newValue);
  };

  if (!user) return null;
  return (
    <Box>
      <Box className="container" textAlign="center">
        <Typography variant="h3" py={3}>
          {LL("Edit_personal_cabinet")}
        </Typography>
      </Box>

      <Divider />
      <Box sx={{ flexGrow: 1 }} className="container" mt={2}>
        <Grid container spacing={2}>
          <Grid item alignItems="center" justifyContent="center" display="flex">
            <Tabs
              variant="scrollable"
              value={value}
              onChange={handleChange}
              orientation="horizontal"
              scrollButtons
              sx={{ maxWidth: "calc(100vw - 32px)", paddingLeft: 0 }}
              allowScrollButtonsMobile
              className="tabVertical profileTeacher">
              <Tab label={LL("General_Info")} value={"generalInfo"} />
              <Tab label={LL("Change_Password")} value={"changePassword"} />
              <Tab label={LL("User_Social")} value={"socialLink"} />
              <Tab label={LL("User_Phone")} value={"phone"} />
              <Tab label={LL("User_Address")} value={"address"} />
              <Tab label={LL("Student_Info")} value={"studentInfo"} />
              <Tab label={LL("Notification")} value={"notification"} />
            </Tabs>
          </Grid>
          <Grid item>
            <Box>
              <TabPanel value={value} index={"generalInfo"}>
                <EditUserSettingsInfo currentRoute={currentRoute} />
              </TabPanel>
              <TabPanel value={value} index={"changePassword"}>
                <ChangePasswordForm currentRoute={currentRoute} />
              </TabPanel>
              <TabPanel value={value} index={"socialLink"}>
                <EditSocialUser currentRoute={currentRoute} />
              </TabPanel>
              <TabPanel value={value} index={"phone"}>
                <AddUserPhone currentRoute={currentRoute} />
              </TabPanel>
              <TabPanel value={value} index={"address"}>
                <AddUserAddress currentRoute={currentRoute} />
              </TabPanel>
              <TabPanel value={value} index={"studentInfo"}>
                <UserEditStudent currentRoute={currentRoute} />
              </TabPanel>
              <TabPanel value={value} index={"notification"}>
                <EditStudentNotification currentRoute={currentRoute} />
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export { EditUserProfile };
