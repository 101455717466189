import React, { useState } from "react";

import { useResource } from "hooks/useResource";

import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";

import { IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import ResultObjectDTO from "dto/app/resultobject.dto";

import { TestDto, TestFormDto } from "dto/course/test.dto";
import { TestService } from "services/course/test.service";

import { FormTest } from "./FormTest";
import { Types } from "tools/types/types";

const service = new TestService();

type Props = {
  open: boolean;
  setOpen: any;
  id: string;
  idLesson?: string;
  addToList?: (obj: TestDto) => void;
};
const AddTestDialog: React.FC<Props> = ({
  open,
  setOpen,
  id,
  idLesson,
  addToList,
}) => {
  const { LL } = useResource();

  const [loading, setLoading] = useState<boolean>(false);
  const [defaultObj, setDefaultObj] = useState<TestFormDto>(
    new TestFormDto(
      id,
      undefined,
      idLesson,
      undefined,
      idLesson ? Types.TYPE_TEST_LESSON : undefined
    )
  );

  const onSubmit = (obj: TestFormDto) => {
    setLoading(true);
    service.add(handleResult, {}, obj);
  };

  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    if (addToList) addToList(result.obj);
    setDefaultObj(
      new TestFormDto(
        id,
        undefined,
        idLesson,
        undefined,
        idLesson ? Types.TYPE_TEST_LESSON : undefined
      )
    );
    setLoading(false);
    setOpen(false);
  };
  // const [isFormDisabled, setIsFormDisabled] = useState(true);

  // const [validators, setValidators] = useState(false);
  // const [vresults, setVResults] = useState<any>(false);
  // const validateAll = () => {
  //   setLoading(true);
  //   if (!obj) return;
  //   if (!validators) return;
  //   // if (vresults) return;

  //   obj.name = obj.name !== undefined ? obj.name : "";
  //   obj.mintestscore = obj.mintestscore !== undefined ? obj.mintestscore : 0;
  //   obj.idlesson = obj.idlesson ? obj.idlesson : null;
  //   processIdLesson();
  //   setObj(obj);

  //   let t = validateField("name", obj.name);
  // };

  // const processIdLesson = () => {
  //   if (!idLesson) return;
  //   obj.idlesson = idLesson;
  //   obj.type = Types.TYPE_TEST_LESSON;
  // };

  // const validateField = (field: any, value: any, v?: any) => {
  //   v = v ? v : JSON.parse(JSON.stringify(vresults));
  //   if (v === false) v = {};
  //   v[field] = ValidateTools.validate(field, value, validators, vresults);
  //   setVResults(v);
  //   return v;
  // };

  // const processValidators = () => {
  //   let v: any = {
  //     name: [],
  //     mintestscore: [],
  //   };

  //   v.name.push(new RequiredValidator("", ""));
  //   v.mintestscore.push(new RequiredValidator("", ""));

  //   setValidators(v);
  // };

  // const checkLoading = () => {
  //   if (!obj) return;
  //   if (!validators) return;

  //   setLoading(false);
  // };

  // useEffect(() => {
  //   processValidators();
  //   validateAll();
  // }, [open]);

  // useEffect(() => {
  //   checkLoading();
  // }, [obj, validators]);

  // useEffect(() => {
  //   checkIsFormDisabled();
  // }, [vresults, obj.type]);

  // const setObjField = (field: any, value: any) => {
  //   let t = JSON.parse(JSON.stringify(obj));
  //   t[field] = value;
  //   setObj(t);

  //   validateField(field, value);
  // };

  // const checkIsFormDisabled = () => {
  //   let rez = ValidateTools.vForm(vresults, "name");

  //   if (!obj.type) rez = true;
  //   setIsFormDisabled(rez);
  // };

  // const handleSubmit = (event: any) => {
  //   event.preventDefault();
  //   if (isFormDisabled) return false;

  //   if (!obj) return;

  //   if (!id) return;
  //   obj.idcourse = id;
  //   setLoading(true);
  //   service.add(handleResult, {}, obj);
  // };

  // const handleResult = (result: ResultObjectDTO, cbparams: any) => {
  //   if (!result) return;
  //   if (!result.err) {
  //     // setObjectWasChanged(CommonTools.generateRandomString(5));
  //     setOpen(false);
  //     setLoading(false);
  //     setObj(new PostTestDto());
  //   }
  // };

  const handleClose = () => {
    setOpen(false);
  };

  // const processLessonSelect = () => {
  //   if (!obj) return <></>;
  //   if (!obj.type) return <></>;
  //   if (obj.type !== Types.TYPE_TEST_LESSON) return <></>;
  //   const defaultValue = idLesson ? idLesson : "";
  //   return (
  //     <Box>
  //       <Typography variant="h6" mb={1}>
  //         {LL("Lesson")}
  //       </Typography>
  //       <LessonSelect
  //         id="idlesson"
  //         name="idlesson"
  //         variant="standard"
  //         className="form-select"
  //         color="primary"
  //         setObj={setObjField}
  //         field="idlesson"
  //         obj={obj}
  //         idCourse={id}
  //         _defaultValue={defaultValue}
  //       />
  //     </Box>
  //   );
  // };

  // const processForm = () => {
  //   if (!obj) return <></>;
  //   if (loading) return <CircularLoading />;

  //   return (
  //     <form onSubmit={handleSubmit}>
  //       <Box>
  //         <Typography variant="h6" mb={1}>
  //           {" "}
  //           {LL("Numele")}
  //         </Typography>
  //         <MyTextField
  //           tabIndex={0}
  //           fullWidth
  //           id="name"
  //           name="name"
  //           variant="standard"
  //           color="primary"
  //           className="form-input"
  //           _label={LL("Name")}
  //           size="small"
  //           value={obj.name}
  //           setObj={setObjField}
  //           _vresults={vresults && vresults.name ? vresults.name : false}
  //         />
  //       </Box>
  //       <Box>
  //         <Typography variant="h6" mb={1}>
  //           {LL("Tip test")}
  //         </Typography>
  //         <TestTypeSelect
  //           id="type"
  //           name="type"
  //           variant="standard"
  //           className="form-select"
  //           color="primary"
  //           setObj={setObjField}
  //           field="type"
  //           value={obj.type}
  //         />
  //       </Box>
  //       <Box>
  //         <Typography variant="h6" mb={1}>
  //           {" "}
  //           {LL("Min test score")}
  //         </Typography>
  //         <MyTextField
  //           tabIndex={0}
  //           fullWidth
  //           id="mintestscore"
  //           name="mintestscore"
  //           variant="standard"
  //           color="primary"
  //           className="form-input"
  //           _label={LL("mintestscore")}
  //           size="small"
  //           value={obj.mintestscore}
  //           setObj={setObjField}
  //           _vresults={undefined}
  //         />
  //       </Box>
  //       {processLessonSelect()}
  //       <Box textAlign="center" my={4}>
  //         <MyButton
  //           tabIndex={1}
  //           disabled={isFormDisabled}
  //           color="primary"
  //           variant="contained"
  //           size="large"
  //           type="submit"
  //           className="btn"
  //           children={LL("Bt_Submit")}
  //         />
  //       </Box>
  //     </form>
  //   );
  // };
  return (
    <Dialog open={open} onClose={handleClose} fullWidth className="dialogAdd">
      <DialogTitle>{LL("Add_test")}</DialogTitle>
      <IconButton
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <FormTest
          defaultObj={defaultObj}
          onSubmit={onSubmit}
          loading={loading}
        />
      </DialogContent>
    </Dialog>
  );
};

export { AddTestDialog };
