import React from "react";

import { Box, Typography, Grid, Stack } from "@mui/material";
import { CourseDto } from "dto/course/course.dto";

import { HtmlTag } from "components/form/HtmlTag";
import { useResource } from "hooks/useResource";

import { TeacherInfoBlock } from "components/user/teacher/TeacherInfoBlock";
import { CourseVideo } from "components/course/course/CourseVideo";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

type Props = {
  obj: CourseDto;
};
const GeneralInfoCourseTab: React.FC<Props> = ({
  obj
}) => {
  const { LL } = useResource();

  const processDescription = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("description")) return null;
    if (!obj.description) return null;
    return (
      <Box className="detailsGeneral" pt={5}>
        <Typography variant="h3">{LL("Description")}</Typography>
        <HtmlTag content={obj.description} />
      </Box>
    );
  };

  const processRequirementsText = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("requirementstext")) return null;
    if (!obj.requirementstext) return null;
    return (
      <Box className="detailsGeneralround" mt={5}>
        <Typography variant="h3">{LL("requirementstext")}</Typography>
        <HtmlTag content={obj.requirementstext} />
      </Box>
    );
  };

  const processWhatWillYouLearnText = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("whatwillyoulearntext")) return null;
    if (!obj.whatwillyoulearntext) return null;
    return (
      <Box className="detailsGeneral" pt={5}>
        <Typography variant="h3">{LL("whatwillyoulearntext")}</Typography>
        <HtmlTag content={obj.whatwillyoulearntext} />
      </Box>
    );
  };

  const processTargetAudienceText = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("targetaudiencetext")) return null;
    if (!obj.targetaudiencetext) return null;
    return (
      <Box className="detailsGeneralround " mt={5}>
        <Typography variant="h3">{LL("targetaudiencetext")}</Typography>
        <HtmlTag content={obj.targetaudiencetext} />
      </Box>
    );
  };

  const processTeacherInfo = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("idteacher")) return null;
    if (!obj.idteacher) return null;
    return (
      <Box mt={5}>
        <Typography variant="h3" mb={3}>
          {LL("TeacherInfo")}
        </Typography>
        <TeacherInfoBlock id={obj.idteacher} />
      </Box>
    );
  };

  const processCourseVideo = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("video")) return null;
    if (!obj.video) return null;
    if (!obj.video.length) return null;

    return (
      <Box mt={5}>
        <Typography variant="h3" mb={3}>
          {LL("CourseVideo")}
        </Typography>
        <Stack
          direction={{ sm: "column", md: "row" }}
          spacing={2}
          alignItems="center"
        >
          <CourseVideo obj={obj} width={420} height={235} controls={true} />
          <Box className="courseVideoInfo">
            <InfoOutlinedIcon color="primary" sx={{ fontSize: "30px" }} />
            <Typography>{LL("CourseVideo description alert video")}</Typography>
          </Box>
        </Stack>
      </Box>
    );
  };

  return obj ? (
    <Box className="detailCourse">
      <Box className="container">
        <Grid container>
          <Grid item>
            {processWhatWillYouLearnText()}
            {processDescription()}
            {processRequirementsText()}
            {processTargetAudienceText()}
            {processCourseVideo()}
            {processTeacherInfo()}
          </Grid>
        </Grid>
      </Box>
    </Box>
  ) : null;
};

export { GeneralInfoCourseTab };
