import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CommonTools } from "tools/utils/commontools";
import { CertificateService } from "services/course/certificate.service";
import { CertificateDto } from "dto/course/certificate.dto";

import { Box, Skeleton, Typography } from "@mui/material";
import { useResource } from "hooks/useResource";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { CallbackType } from "interfaces/commontypes.interface";
import useObject from "hooks/useObject";

const service = new CertificateService();
const Certificate: React.FC<PageComponentProps> = ({ currentRoute }) => {
  const { LLL } = useResource();
  // const [hashCertificate, setHashCertificate] = useState<string>("");
  // const [loading, setLoading] = useState<boolean>(true);
  // const [obj, setObj] = useState<CertificateDto | undefined>();
  const hashCertificate = CommonTools.getIdFromPath(
    currentRoute,
    "certificate"
  );

  const get = (id: string, cb?: CallbackType, cbParams?: any) => {
    if (!id) return;
    service.getByHash(id, cb, cbParams);
  };

  const [loading, obj] = useObject<CertificateDto>(get, hashCertificate, [
    hashCertificate,
  ]);
  // useEffect(() => {
  //   setHashCertificate(CommonTools.getIdFromPath(currentRoute, "certificate"));
  // }, [currentRoute]);

  // const getObject = () => {
  //   if (!hashCertificate) return;
  //   setLoading(true);
  //   service.getByHash(hashCertificate, loadObject, {});
  // };

  // const loadObject = (obj: ResultObjectDTO) => {
  //   if (!obj) return;
  //   if (!obj.err) {
  //     const object: CertificateDto = obj.obj ? obj.obj : {};
  //     setObj(object);
  //   }
  // };

  // useEffect(() => {
  //   getObject();
  // }, [hashCertificate]);

  // const checkIsLoading = () => {
  //   let load: boolean = false;
  //   if (obj === undefined) load = true;
  //   setLoading(load);
  // };

  // useEffect(() => {
  //   checkIsLoading();
  // }, [obj]);

  const processCertificate = () => {
    if (loading) return <Skeleton variant="rectangular" />;
    if (!obj) return null;
    if (!obj.hasOwnProperty("idlanguage")) return null;
    if (!obj.idlanguage) return null;

    return (
      <Box>
        <Typography>{LLL("Certificate_title", obj.idlanguage)}</Typography>
        <Typography>
          <span>{LLL("This_Certificate_certifies_that", obj.idlanguage)}</span>

          <span>{CommonTools.processObjectField(obj, ["studentname"])}</span>

          <span>{LLL("successfully_complete", obj.idlanguage)}</span>

          <span>{LLL("number_of_lessons", obj.idlanguage)}</span>

          <span>
            {CommonTools.processObjectField(obj, ["numberoflessons"])}
          </span>

          <span>{LLL("number_of_hours", obj.idlanguage)}</span>

          <span>
            {ComponentCommonTools.processObjectFieldTimeStamp(
              obj,
              "numberofhours"
            )}
          </span>

          <span>{LLL("of", obj.idlanguage)}</span>
          <span>{CommonTools.processObjectField(obj, ["coursename"])}</span>

          <span>{LLL("by", obj.idlanguage)}</span>

          <span>{CommonTools.processObjectField(obj, ["teachername"])}</span>

          <span>{LLL("course_on", obj.idlanguage)}</span>

          <span>{CommonTools.processObjectField(obj, ["date_name"])}</span>
        </Typography>
      </Box>
    );
  };

  return processCertificate();
};

export { Certificate };
