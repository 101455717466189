import React from "react";

import { useResource } from "hooks/useResource";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { CourseVerifyDialog } from "../courseaction/CourseVerifyDialog";
import { CourseUnpublishDialog } from "../courseaction/CourseUnpublishDialog";
import { CoursePublishButton } from "../courseaction/CoursePublishButton";
import { CourseDto } from "dto/course/course.dto";

type Props = {
  currentRoute: any;
  course: CourseDto;
  setCourse: any;
};

const CourseActions: React.FC<Props> = ({
  currentRoute,
  course,
  setCourse
}) => {
  const { LL } = useResource();

  return (
    <Box>
      <Typography>{LL("CourseActions")}</Typography>
      <Box>
        <CourseVerifyDialog currentRoute={currentRoute} course={course} />
      </Box>
      <Box>
        <CoursePublishButton
          currentRoute={currentRoute}
          obj={course}
          setObj={setCourse}
        />
      </Box>
      <Box>
        <CourseUnpublishDialog currentRoute={currentRoute} course={course} />
      </Box>
    </Box>
  );
};

export default CourseActions;
