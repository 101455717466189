import React from "react";
import { useResource } from "hooks/useResource";

import { CourseCategoryService } from "services/course/coursecategory.service";

import { CourseCategoryDto } from "dto/course/coursecategory.dto";

import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools//types/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
// import ResultListDTO from "dto/app/resultlist.dto";

import { Box, Typography } from "@mui/material";
// import ResultObjectDTO from "dto/app/resultobject.dto";

import { InfoCourseCategoryCard } from "components/course/coursecategory/InfoCourseCategoryCard";

import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { Carousel } from "components/elements/Carousel/Carousel";
import { CommonTools } from "tools/utils/commontools";
import { SkeletonSlider } from "components/elements/Skeleton/SkeletonCategory";
import { CallbackType } from "interfaces/commontypes.interface";

import { useList } from "hooks/useList";

const service = new CourseCategoryService();

type Props = {
  currentRoute: any;
  idCategory: string;
};

const PopularCategory: React.FC<Props> = ({ idCategory }) => {
  // const [isLoading, setIsLoading] = useState(true);
  // const [objects, setObjects] = useState<Array<CourseCategoryDto>>([]);
  // const [obj, setObj] = useState<CourseCategoryDto | undefined>();

  const { LL, CC } = useResource();

  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    if (!idCategory) return;
    service.getList(cb, cbParams, req);
  };

  const [loading, objects] = useList<CourseCategoryDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
        RequestFilterDTO.prepareFilter("idparent", [idCategory]),
      ],
      1,
      parseInt(CC("popular_category", "10")),
      [RequestSortCriteriaDTO.prepareSortCriteria("popularidentifier", false)]
    ),
    [idCategory],
    true,`popular_category_${idCategory}`
  );
  
  // const loadObject = (obj: ResultObjectDTO) => {
  //   if (!obj) return;
  //   if (!obj.err) {
  //     const object = obj.obj ? obj.obj : undefined;
  //     setObj(object);
  //   }
  // };

  // const getObject = () => {
  //   setIsLoading(true);
  //   if (idCategory !== "") {
  //     service.get(idCategory, loadObject, {});
  //   }
  // };

  // const getList = () => {
  //   const reqList = new RequestListDTO();
  //   reqList.onpage = 10;
  //   reqList.page = 1;
  //   reqList.filters = [];
  //   reqList.sortcriteria = [];
  //   let sortCriteria = new RequestSortCriteriaDTO();
  //   sortCriteria.field = "popularidentifier";
  //   sortCriteria.asc = false;
  //   reqList.sortcriteria.push(sortCriteria);

  //   let f: RequestFilterDTO;

  //   f = new RequestFilterDTO();
  //   f.field = "status";
  //   f.values = [];
  //   f.values.push(Status.ACTIVE.toString());
  //   reqList.filters.push(f);

  //   f = new RequestFilterDTO();
  //   f.field = "idparent";
  //   f.values = [];
  //   f.values.push(idCategory);
  //   reqList.filters.push(f);

  //   setIsLoading(true);

  //   service.getList(loadObjects, {}, reqList);

  //   // setIsLoading(false);
  // };

  // const loadObjects = (result: ResultListDTO) => {
  //   if (!result) return;
  //   if (!result.err) {
  //     const objects = result.objects ? result.objects : [];
  //     setObjects(objects);
  //   }
  // };

  // const checkLoading = () => {
  //   let load = false;
  //   if (!obj) load = true;
  //   if (!objects) load = true;
  //   if (objects.length === 0) load = true;

  //   setIsLoading(load);
  // };
  // useEffect(() => {
  //   if (idCategory !== "") {
  //     getObject();
  //     getList();
  //   }
  // }, [idCategory]);

  // useEffect(() => {
  //   checkLoading();
  // }, [obj, objects]);

  const processCourseCategoryItem = (obj: CourseCategoryDto, index: number) => {
    if (!obj) return null;
    return (
      <Box p={1} key={index}>
        <InfoCourseCategoryCard obj={obj} withImage={false} />
      </Box>
    );
  };

  const processCourseCategoryList = () => {
    if (!objects) return null;
    if (!objects.length) return null;
    const slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      parseInt(CC("DefaultValueShowPopularCourseCategoryListCarousel", "5")),
      objects.length
    );
    return (
      <Carousel _slidesToShow={slidesToShow}>
        {objects.map((item: CourseCategoryDto, i: number) => {
          return processCourseCategoryItem(item, i);
        })}
      </Carousel>
    );
  };

  if (!idCategory) return null;
  return loading ? (
    <SkeletonSlider />
  ) : (
    <Box className="container populaCategories" py={5}>
      <Typography variant="h2" my={4}>
        {LL("Subiecte populare")}
      </Typography>
      {processCourseCategoryList()}
    </Box>
  );
};

export { PopularCategory };
