import React from "react";
import Rating from "@mui/material/Rating";
import { Stack, Typography } from "@mui/material";
import { CommonTools } from "tools/utils/commontools";
import { styled } from "@mui/material/styles";
import StarIcon from "@mui/icons-material/Star";

type MyRatingReadOnlyProps = {
  rating: number;
  count?: number;
  showDetails?: boolean;
};

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#FFD102",
  },
  "& .MuiRating-iconHover": {
    color: "#FFD102",
  },
});

const MyRatingReadOnly: React.FC<MyRatingReadOnlyProps> = ({
  rating,
  count,
  showDetails = true,
  ...props
}) => {
  const processRatingValue = () => {
    if (rating === undefined) return null;
    if (!showDetails) return null;

    return (
      <Typography color="gold" sx={{ fontSize: "12px" }}>
        {CommonTools.roundToTenth(rating)}
      </Typography>
    );
  };
  const processRatingView = () => {
    if (rating === undefined) return null;
    return (
      <StyledRating
        name="read-only"
        value={rating}
        readOnly
        precision={0.1}
        emptyIcon={<StarIcon style={{ color: "#d2d2d2" }} fontSize="inherit" />}
      />
    );
  };

  const processRatingCount = () => {
    if (count === undefined) return null;
    if (!showDetails) return null;
    return <Typography sx={{ fontSize: "12px" }}>({count})</Typography>;
  };

  const processRating = () => {
    if (rating === undefined) return null;
    if (showDetails === true) {
      return (
        <Stack
          direction="row"
          spacing={1}
          className="stars"
          sx={{ alignItems: "center" }}
        >
          {processRatingValue()}
          {processRatingView()}
          {processRatingCount()}
        </Stack>
      );
    }

    return processRatingView();
  };
  return processRating();
};

export { MyRatingReadOnly };
