import React, { useState } from "react";

import { useResource } from "hooks/useResource";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { EditCourseTestList } from "components/course/test/EditCourseTestList";
import { MyButton } from "components/elements/button/MyButton";

type Props = {
  currentRoute: any;
};

const EditCourseTest: React.FC<Props> = ({ currentRoute }) => {
  const { LL } = useResource();

  const id = currentRoute._paths !== undefined ? currentRoute._paths[2] : "";

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const processList = () => {
    if (!id) return null;
    return (
      <EditCourseTestList
        idCourse={id}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
    );
  };

  return (
    <React.Fragment>
      <Box>
        <Typography variant="h2" textAlign="center" mb={1}>
          {LL("Test curs")}
        </Typography>
        <Box textAlign="center" mb={4}>
          <MyButton className="btn btnAddlesson" cb={handleOpenDialog}>
            {LL("Bt_Add_Test")}
          </MyButton>
        </Box>
        {processList()}
      </Box>
    </React.Fragment>
  );
};

export default EditCourseTest;
