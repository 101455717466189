import Idto from "interfaces/idto.interface";
import { CourseEnrollmentDto } from "./courseenrollment.dto";
import { CommonTools } from "tools/utils/commontools";

export class CourseDto implements Idto {
  id?: string;
  name?: string;
  description?: string;
  status?: number;
  idgallery?: string;
  idattachment?: string;
  idvideo?: string;
  idcoursesettings?: string;
  idlanguage?: string;
  idslesson?: string[];
  idscoursecategory?: string[];
  idscoursefilter?: string[];
  idteacher?: string;
  courseduration?: number;
  requirementstext?: string;
  whatwillyoulearntext?: string;
  targetaudiencetext?: string;
  teacher?: any;
  language?: any;
  courseprice?: any;
  coursecategory?: Array<any>;
  coursefilter?: any;
  attachment?: any;
  video?: any;
  gallery?: any;
  numberoflessons?: number;
  fullurl?: string;
  coursefiltermain?: Array<any>;
  coursesettings?: any;
  linkcoursecategory?: Array<any>;
  shortdescription?: string;
  status_name?: string;
  nextlessonstartdate?: number;
  numberofstudents?: number;
  numberoflessonsremaining?: number;
  courseenrollments?: CourseEnrollmentDto;
}

export class PostCourseDto implements Idto {
  name?: string;
  description?: string;
  status?: number;
  idgallery?: string;
  idattachment?: string;
  idvideo?: string;
  idcoursesettings?: string;
  idlanguage?: string;
  idslesson?: string[];
  idscoursecategory?: string[];
  idscoursefilter?: string[];
  idteacher?: string;
  courseduration?: number;
  requirementstext?: string;
  whatwillyoulearntext?: string;
  targetaudiencetext?: string;
  numberoflessons?: number;
  fullurl?: string;
  coursesettings?: any;
  linkcoursecategory?: Array<any>;
  shortdescription?: string;
}

export class CreateCourseFirstStepDto implements Idto {
  name?: string;
  idscoursecategory?: Array<string>;
  idteacher?: string;
  idtypecourse?: string;

  constructor(
    idteacher?: string,
    idtypecourse?: string,
    name?: string,
    idscoursecategory?: Array<string>
  ) {
    this.idteacher = idteacher || "";
    this.idtypecourse = idtypecourse || "";
    this.name = name || "";
    this.idscoursecategory = idscoursecategory || [];
  }
}

export class EditGeneralInfoCourseDto implements Idto {
  id?: string;
  name?: string;
  description?: string;
  idlanguage?: string;
  shortdescription?: string;
  requirementstext?: string;
  whatwillyoulearntext?: string;
  targetaudiencetext?: string;

  constructor(
    id?: string,
    name?: string,
    description?: string,
    idlanguage?: string,
    shortdescription?: string,
    requirementstext?: string,
    whatwillyoulearntext?: string,
    targetaudiencetext?: string
  ) {
    this.id = id || "";
    this.name = name || "";
    this.description = description || "";
    this.idlanguage = idlanguage || "";
    this.shortdescription = shortdescription || "";
    this.requirementstext = requirementstext || "";
    this.whatwillyoulearntext = whatwillyoulearntext || "";
    this.targetaudiencetext = targetaudiencetext || "";
  }

  static fromCourseDto(object: CourseDto): EditGeneralInfoCourseDto {
    return new EditGeneralInfoCourseDto(
      CommonTools.processObjectField(object, ["id"]),
      CommonTools.processObjectField(object, ["name"]),
      CommonTools.processObjectField(object, ["description"]),
      CommonTools.processObjectField(object, ["idlanguage"]),
      CommonTools.processObjectField(object, ["shortdescription"]),
      CommonTools.processObjectField(object, ["requirementstext"]),
      CommonTools.processObjectField(object, ["whatwillyoulearntext"]),
      CommonTools.processObjectField(object, ["targetaudiencetext"])
    );
  }
}
