import React from "react";
import {  
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { useResource } from "hooks/useResource";

type WithoutResponseFilterProps = {
  onChange: (event: any) => void;
  value: string;
};

const WithoutResponseFilter: React.FC<WithoutResponseFilterProps> = ({
  onChange,
  value,
  ...props
}) => {
  const { LL } = useResource();
  return (
    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">
        {LL("withoutresponse")}
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={value}
        onChange={onChange}
      >
        <FormControlLabel value="" control={<Radio />} label={LL("All")} />
        <FormControlLabel
          value="false"
          control={<Radio />}
          label={LL("withoutresponse")}
        />
        <FormControlLabel
          value="true"
          control={<Radio />}
          label={LL("withresponse")}
        />
      </RadioGroup>
    </FormControl>
  );
};

export { WithoutResponseFilter };
