import React, { useEffect, useState } from "react";
import { useResource } from "hooks/useResource";
import { useAuth } from "hooks/useAuth";

import PageComponentProps from "interfaces/pagecomponentprops.interface";

import Container from "@mui/material/Container";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import { ActivateEmailDto } from "dto/user/user.dto";

import { UserService } from "services/user/user.service";
import ResultSignInDTO from "dto/auth/resultsignin.dto";
import { MyBackdrop } from "components/elements/loading/MyBackdrop";
import { CommonTools } from "tools/utils/commontools";
import { RouteTools } from "tools/utils/routetools";

const service = new UserService();
const ActivateEmail: React.FC<PageComponentProps> = ({ currentRoute }) => {
  const email = decodeURIComponent(
    CommonTools.getIdFromPath(currentRoute, "activateemail")
  );
  const code = CommonTools.getIdFromPath(
    currentRoute,
    "activateemail",
    undefined,
    2
  );
  const { handleResultAuth } = useAuth();

  const { LL } = useResource();

  const [loading, setLoading] = useState<boolean>(false);
  const activate = () => {
    if (!email || !code) return;
    setLoading(true);
    service.activateEmail(new ActivateEmailDto(email, code), handleResult, {});
  };

  useEffect(() => {
    activate();
  }, []);

  const handleResult = (result: ResultSignInDTO) => {
    setLoading(false);
    RouteTools.setHistory("/", {});
    if (!result) return;
    if (result.err) return;

    handleResultAuth(result, { remember: true, defaultRoute: true });
  };

  return (
    <Box className="authMaineBox">
      <Container maxWidth="xs">
        <Box
          mt={5}
          sx={{
            alignItems: "center",
            alignContent: "center",
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="h3" align="center" sx={{ mb: 5 }}>
            {loading ? LL("activating_email") : LL("activate_email")}
          </Typography>
        </Box>
      </Container>
      <MyBackdrop open={loading} />
    </Box>
  );
};

export { ActivateEmail };
