import { Box, Button } from "@mui/material";
import { MyCheckboxField } from "components/form/MyCheckboxField";
import MyTextField from "components/form/mytextfield";
import { MyNavBt } from "components/elements/button/MyNavBt";
import SignInDto from "dto/auth/signin.dto";
import { useForm } from "hooks/useForm";
import { useGoogleRecaptcha } from "hooks/useGoogleRecaptcha";
import { useResource } from "hooks/useResource";
import React, { useState } from "react";
import RequiredValidator from "validators/required.validator";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { PasswordField } from "components/form/PasswordField";

type Props = {
  onSubmit: (obj: SignInDto, remember: boolean) => void;
};

const FormLogin: React.FC<Props> = ({ onSubmit }) => {
  const { LL } = useResource();
  const { executeRecaptcha } = useGoogleRecaptcha();
  const [remember, setRemember] = useState<boolean>(false);
  const [obj, disabled, setObjField] = useForm<SignInDto>(
    new SignInDto(),
    RequiredValidator.getValidators(["identifier", "password"])
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    await executeRecaptcha();
    onSubmit(obj, remember);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ mb: 4 }} className="box-form-input-icon">
        <MailOutlineIcon />
        <MyTextField
          fullWidth
          id="identifier"
          name="identifier"
          className="form-input"
          variant="standard"
          _label={
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
              }}>
              {/* <MailOutlineIcon /> */}
              {LL("Identifier Login")}
            </Box>
          }
          value={obj.identifier}
          setObj={setObjField}
          _vresults={undefined}
          _applyclass={true}
        />
      </Box>
      <Box sx={{ mb: 2 }} className="box-form-input-icon">
        <LockOutlinedIcon />

        <PasswordField
          fullWidth
          
          id="password"
          name="password"
          className="form-input"
          variant="standard"
          _label={
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
              }}>
              
              {LL("Password")}
            </Box>
          }
          value={obj.password}
          setObj={setObjField}
          _vresults={undefined}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <MyCheckboxField
          sx={{ fontSize: "14px" }}
          id="remember"
          name="remember"
          label={LL("remember")}
          field="remember"
          setObjectField={(field: string, value: any) => {
            setRemember(value);
          }}
          checked={remember}
          color="primary"
          size="medium"
        />
        <MyNavBt
          className="forgotPassword"
          href={"/forgotpassword"}
          children={LL("forgot_password?")}
          size="small"
          _hstate={{}}
        />
      </Box>
      <Box mt={2}>
        <Button
          disabled={disabled}
          color="primary"
          fullWidth
          variant="contained"
          type="submit"
          className="btn">
          {LL("Bt Login")}
        </Button>
      </Box>
    </form>
  );
};

export { FormLogin };
