import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";


import Box from "@mui/material/Box";

import { StudentService } from "services/user/student.service";
import { StudentDto } from "dto/user/student.dto";
import { useAuth } from "hooks/useAuth";
import { CommonTools } from "tools/utils/commontools";
import { CircularLoading } from "components/elements/loading/CircularLoading";
import useObject from "hooks/useObject";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { FormEditStudent } from "./FormEditStudent";

const service = new StudentService();

const UserEditStudent: React.FC<PageComponentProps> = () => {
  const { user } = useAuth();

  // const [loading, setLoading] = useState<boolean>(true);
  // const [obj, setObj] = useState<StudentDto | undefined>();
  // const _id = user !== undefined ? user.id : "";
  // const [id, setId] = useState<string>(_id);
  // const [isFormDisabled, setIsFormDisabled] = useState(false);

  // const [validators, setValidators] = useState<any>();
  // const [vresults, setVResults] = useState<any>(false);

  // const validateField = (field: any, value: any, v?: any) => {
  //   v = v ? v : JSON.parse(JSON.stringify(vresults));
  //   if (v === false) v = {};
  //   v[field] = ValidateTools.validate(field, value, validators, vresults);

  //   setVResults(v);
  //   setIsFormDisabled(ValidateTools.vForm(v, field));
  //   return v;
  // };

  // const loadObject = (obj: any) => {
  //   if (!obj) return;
  //   if (!obj.err) {
  //     const object = obj.obj ? obj.obj : [];
  //     setObj(object);
  //   }
  // };

  // const getObject = () => {
  //   setLoading(true);
  //   if (id !== undefined && id !== "") service.getByUserId(id, loadObject, {});
  // };

  // const checkIsLoading = () => {
  //   let load: boolean = false;
  //   if (obj === undefined) load = true;
  //   setLoading(load);
  // };

  // useEffect(() => {
  //   getObject();
  // }, [currentRoute]);

  // useEffect(() => {
  //   validateAll();
  //   checkIsLoading();
  // }, [obj]);

  // useEffect(() => {
  //   if (user !== undefined) {
  //     setId(user.id);
  //   }
  // }, [user]);

  // const validateAll = () => {
  //   if (!obj) return;
  //   // if (!validators) return;
  //   // if (vresults) return;

  //   obj.bio = obj.bio !== undefined ? obj.bio : "";
  //   obj.institution = obj.institution !== undefined ? obj.institution : "";
  //   obj.idtypestudies =
  //     obj.idtypestudies !== undefined ? obj.idtypestudies : "";

  //   setObj(obj);
  // };

  // const setObjField = (field: any, value: any) => {
  //   let t = JSON.parse(JSON.stringify(obj));
  //   t[field] = value;
  //   setObj(t);

  //   // validateField(field, value);
  // };

  // const handleSubmit = (event: any) => {
  //   event.preventDefault();
  //   if (isFormDisabled) return false;
  //   if (id !== undefined && obj) {
  //     obj.iduser = id;
  //     setLoading(true);
  //     service.add(
  //       CommonTools.handleSuccessAddAndEditStayOnPage,
  //       { setLoading },
  //       obj
  //     );
  //   }
  // };

  const id = CommonTools.processObjectField(user, ["id"]);

  const get = (id: string, cb?: any, cbParams?: any) => {
    if (!id) return;
    service.getByUserId(id, cb, cbParams);
  };
  const [loading, obj, setObj, setLoading] = useObject<StudentDto>(get, id, [
    id,
  ]);

  const onSubmit = (obj: StudentDto) => {
    setLoading(true);
    service.add(handleResult, {}, obj);
  };

  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    setObj(result.obj);
    setLoading(false);
  };

  if (!user) return null;
  if (loading) return <CircularLoading />;
  if (!obj) return null;
  return (
    <Box p={3}>
      <FormEditStudent
        defaultObject={obj}
        onSubmit={onSubmit}
        loading={loading}
      />
    </Box>
  );
};

export { UserEditStudent };
