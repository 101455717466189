import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  Skeleton,
  Slide,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { SessionStorageTools } from "api/sessionstorage.api";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CourseDto } from "dto/course/course.dto";
import { CourseEnrollmentDto } from "dto/course/courseenrollment.dto";
import { LessonDto } from "dto/course/lesson.dto";
import { LessonEnrollmentDto } from "dto/course/lessonenrollment.dto";
import { TestDto } from "dto/course/test.dto";
import { PostTestResultDto, TestResultDto } from "dto/course/testresult.dto";
import { useAuth } from "hooks/useAuth";
import { useResource } from "hooks/useResource";
import React, { useCallback, useEffect, useState } from "react";
import { TestService } from "services/course/test.service";
import { TestResultService } from "services/course/testresult.service";
import { Status } from "tools/types/status";
import { CommonTools } from "tools/utils/commontools";
import { TestStaticContent } from "../TestStaticContent";
import { MyButton } from "components/elements/button/MyButton";
import { TransitionProps } from "@mui/material/transitions";
import { LogoComp } from "components/general/LogoComp";
import { Close } from "@mui/icons-material";

import { NewTestQuestionList } from "./NewTestQuestionList";
import { NewTestResult } from "./NewTestResult";

type Props = {
  courseEnrollmentObject: CourseEnrollmentDto;
  idCourse: string;
  idLesson?: string;
  type: number;
  course?: CourseDto;
  setCheckObjectWasChanged?: (value: string) => void;
  lesson?: LessonDto;
  showTitleLesson?: boolean;
  lessonEnrollment?: LessonEnrollmentDto;
  setVerifyLessonEnrChanged?: (value: string) => void;
  openDefault?: boolean;
};

const service = new TestService();
const testResultService = new TestResultService();

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewTestDialog: React.FC<Props> = ({
  courseEnrollmentObject,
  idCourse,
  idLesson,
  type,
  course,
  setCheckObjectWasChanged,
  lesson,
  showTitleLesson,
  lessonEnrollment,
  setVerifyLessonEnrChanged,
  openDefault,
}) => {
  const _openDefault = openDefault ? openDefault : false;

  const { student } = useAuth();
  const { LL } = useResource();
  const [open, setOpen] = useState<boolean>(_openDefault);
  const [loading, setLoading] = useState<boolean>(false);
  const [obj, setObj] = useState<TestDto | undefined | null>();
  const [testResult, setTestResult] = useState<TestResultDto | undefined>();
  const [status, setStatus] = useState<number>(Status.TEST_DID_NOT_START);

  const createTest = () => {
    if (!courseEnrollmentObject || !idCourse || !student || !course || !obj)
      return;
    if (
      !courseEnrollmentObject.id ||
      !student.id ||
      !course.idteacher ||
      !obj.id
    )
      return;

    const postObj = new PostTestResultDto();
    postObj.idcourseenrollment = courseEnrollmentObject.id;
    if (
      lessonEnrollment &&
      lessonEnrollment.hasOwnProperty("id") &&
      lessonEnrollment.id
    ) {
      postObj.idlessonenrollment = lessonEnrollment.id;
    }
    postObj.idcourse = idCourse;
    postObj.idstudent = student.id;
    postObj.idteacher = course.idteacher;
    postObj.idtest = obj.id;
    postObj.maxscore = obj.maxtestscore;
    postObj.minscore = obj.mintestscore;

    setLoading(true);
    testResultService.add(handleResultAddTestResult, {}, postObj);
  };
  const handleResultAddTestResult = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    setTestResult(result.obj);
    setLoading(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTestResult(undefined);
    setStatus(Status.TEST_DID_NOT_START);
    if (setCheckObjectWasChanged)
      setCheckObjectWasChanged(CommonTools.generateRandomString(6));
    if (setVerifyLessonEnrChanged)
      setVerifyLessonEnrChanged(CommonTools.generateRandomString(5));
    SessionStorageTools.remove("open_test");
  };

  const processDialogTitle = () => {
    if (!course) return <Typography>{LL("Test")}</Typography>;
    if (!showTitleLesson) {
      return (
        <Stack
          direction={"row"}
          spacing={2}
          textAlign="center"
          justifyContent="center"
        >
          <span>{LL("Test")}</span>
          <span>{CommonTools.processObjectField(course, ["name"])}</span>
        </Stack>
      );
    } else if (lesson) {
      return (
        <Stack
          direction={"row"}
          spacing={2}
          textAlign="center"
          justifyContent="center"
        >
          <span>{LL("Test")}</span>
          <span>{CommonTools.processObjectField(lesson, ["name"])}</span>
        </Stack>
      );
    }
  };

  const getList = useCallback(() => {
    if (!idCourse) return;
    const reqList = new RequestListDTO();
    reqList.onpage = 1;
    reqList.page = 1;
    reqList.filters = [
      RequestFilterDTO.prepareFilter("idcourse", [idCourse]),
      RequestFilterDTO.prepareFilter("type", [type.toString()]),
    ];
    if (idLesson) {
      reqList.filters.push(
        RequestFilterDTO.prepareFilter("idlesson", [idLesson])
      );
    }
    service.getList(loadObjects, {}, reqList);
  }, [idCourse, idLesson, type]);

  const loadObjects = (result: ResultListDTO) => {
    if (!result) return;
    if (result.err) return;
    const objects = result.objects ? result.objects : [];
    if (objects.length > 0) setObj(objects[0]);
    else setObj(null);
  };

  const checkLoading = useCallback(() => {
    let load = false;
    if (!obj) load = true;
    setLoading(load);
  }, [obj]);

  useEffect(() => {
    getList();
  }, [getList]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  const processTestDetail = () => {
    if (!obj) return null;
    return (
      <Stack
        direction="column"
        alignItems="center"
        spacing={1}
        textAlign="center"
      >
        <Stack direction="row" spacing={1} divider={<>:</>} textAlign="center">
          <Typography variant="h6">{LL("Min_Test_Score_To_Pass")}</Typography>
          <Typography>
            {CommonTools.processObjectField(obj, ["mintestscore"])}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} divider={<>:</>} textAlign="center">
          <Typography variant="h6">{LL("Max_Test_Score")}</Typography>
          <Typography>
            {CommonTools.processObjectField(obj, ["maxtestscore"])}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const handleStartTest = () => {
    createTest();
    setStatus(Status.TEST_STARTED);
  };

  const processContent = () => {
    if (!idCourse) return null;
    if (loading) return <Skeleton variant="rectangular" height={400} />;
    if (!obj) return null;

    return (
      <React.Fragment>
        <DialogContent className="dialogContent alignDialog">
          <Typography variant="h2" textAlign="center">
            {CommonTools.processObjectField(obj, ["name"])}
          </Typography>
          <TestStaticContent type={type} />
          {processTestDetail()}
        </DialogContent>
        <Box
          className="footerContent"
          p={2}
          display="flex"
          justifyContent="end"
        >
          <MyButton
            cb={handleStartTest}
            className="btn"
            color="primary"
            variant="contained"
            px={2}
          >
            {LL("Start_Test")}
          </MyButton>
        </Box>
      </React.Fragment>
    );
  };

  const processByStatusTest = () => {
    if (!idCourse) return null;
    if (loading) return <Skeleton variant="rectangular" height={400} />;
    if (!obj) return null;
    if (!obj.id) return null;
    switch (status) {
      case Status.TEST_DID_NOT_START: {
        return processContent();
      }
      case Status.TEST_STARTED: {
        return (
          <NewTestQuestionList
            idTest={obj.id}
            setStatusTest={setStatus}
            testResult={testResult}
            setTestResult={setTestResult}
          />
        );
      }
      case Status.TEST_FINISHED: {
        if(!testResult) return null;
        return (
          <DialogContent className="dialogContent alignDialog">
            <NewTestResult obj={testResult} />
          </DialogContent>
        );
      }
      default: {
        return null;
      }
    }
  };

  const processDialog = () => {
    if (!open) return null;
    return (
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className="appTextForm"
      >
        <AppBar sx={{ position: "relative", bgcolor: "#fff" }}>
          <Toolbar>
            <LogoComp />
            <Typography
              sx={{ mx: "auto", flex: 1, bgColor: "red" }}
              variant="h4"
              className="h-variant"
              textAlign="center"
            >
              {processDialogTitle()}
            </Typography>
            <Button onClick={handleClose} aria-label="close" className="btn-x">
              <Close />
              {LL("Iesire")}
            </Button>
          </Toolbar>
        </AppBar>

        {processByStatusTest()}
      </Dialog>
    );
  };

  return (
    <Box>
      <MyButton onClick={handleClickOpen} className="btnAlert" my={1}>
        {LL("Take_Test")}
      </MyButton>
      {processDialog()}
    </Box>
  );
};

export { NewTestDialog };
