import React, { useEffect, useState } from "react";
import { useResource } from "hooks/useResource";



import { AdvertisementDto } from "dto/static/advertisement.dto";

import { ComponentCommonTools } from "tools/utils/componentcommontools";

import { Box } from "@mui/material";

import { MyNavBt } from "components/elements/button/MyNavBt";
import { CommonTools } from "tools/utils/commontools";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";



type BannerItemSpecialHomeSection1Props = {
  obj: AdvertisementDto;
};
const AdvertisementBannerItemSpecialHomeSection1: React.FC<
  BannerItemSpecialHomeSection1Props
> = ({ obj, ...props }) => {
  const [imgUrl, setImgUrl] = useState<string>("");
  const [fullUrl, setFullUrl] = useState<string>("");
  const width = 200;
  const height = 200;

  const { LL } = useResource();

  const prepareGallery = () => {
    if (!obj) return;

    if (!obj.hasOwnProperty("gallery")) return;
    if (!obj.gallery) return;
    if (obj.gallery.length === 0) return;
    const galleryObj = CommonTools.getGalleryObjectLogo(obj.gallery);
    if (!galleryObj) return;
    CommonTools.setFileUrlsGallery(
      galleryObj,
      setImgUrl,
      setFullUrl,
      width,
      height
    );
  };

  useEffect(() => {
    prepareGallery();
  }, [obj]);

  const processImage = () => {
    if (!imgUrl) return <></>;
    return <>{ComponentCommonTools.getFileBlock(imgUrl, width, height)}</>;
  };

  // if (obj.type == Types.ADVERTISEMENT_TYPE_SPECIAL) {
  //     return (
  //         <Box className="container">
  //             <Grid container spacing={2}>
  //                 <Grid item md={4} sm={12}>{processImage()}</Grid>
  //                 <Grid item md={6} sm={12}>
  //                     {processDetail(obj)}
  //                     {processButton()}
  //                 </Grid>
  //             </Grid>
  //         </Box>
  //     );
  // }

  const processItem = () => {
    if (!obj) return <></>;
    // if (!obj.fullurl) return <></>;
    const label = obj.clabt ? LL(obj.clabt) : "";

    if (!imgUrl) {
      return (
        <Box>
          <Box>
            <Typography variant="h1">
              {ComponentCommonTools.processObjectField(obj, "name")}
            </Typography>
            <Box>
              {ComponentCommonTools.processObjectField(obj, "description")}
            </Box>
          </Box>
          <MyNavBt href={obj.fullurl}>{label}</MyNavBt>
        </Box>
      );
    }

    return (
      <Box
        className="recomandBanner"
        sx={{ backgroundImage: `url(${imgUrl})` }}
        my={5}
      >
        <Box className="container">
          <Grid container sx={{ justifyContent: "center", display: "flex" }}>
            <Grid item md={8} sm={12} sx={{ textAlign: "center" }}>
              <Typography className="title">
                {ComponentCommonTools.processObjectField(obj, "name")}
              </Typography>
              <Typography className="description" my={4}>
                {ComponentCommonTools.processObjectField(obj, "description")}
              </Typography>
              <MyNavBt href={obj.fullurl} className="btn">
                {label}
              </MyNavBt>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  return processItem();
};

export { AdvertisementBannerItemSpecialHomeSection1 };
