import React, { useEffect, useState } from "react";

import { useResource } from "hooks/useResource";

import Box from "@mui/material/Box";

import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";

import { IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { CommonTools } from "tools/utils/commontools";

import { GalleryDto } from "dto/system/gallery.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { GalleryService } from "services/system/gallery.service";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { Status } from "tools//types/status";
import TbFilterDTO from "dto/app/tbfilter.dto";
import FilterTools from "tools/utils/filtertools";
import MyTable from "components/elements/TabelTools/MyTable";
import { MyButton } from "components/elements/button/MyButton";

import { EditGalleryDialog } from "components/system/gallery/EditGalleryDialog";
import { AddGalleryDialog } from "components/system/gallery/AddGalleryDialog";

const galleryService = new GalleryService();
type GalleryDialogProps = {
  open: boolean;
  setOpen: any;
  idparent: string;
  parent: string;
};
const GalleryDialog: React.FC<GalleryDialogProps> = ({
  open,
  setOpen,
  idparent,
  parent,
  ...props
}) => {
  const { LL, CC } = useResource();
  const [loading, setLoading] = useState<boolean>(false);

  const [data, setData] = useState<ResultListDTO>(new ResultListDTO());
  const [rows, setRows] = useState<Array<GalleryDto>>(new Array<GalleryDto>());
  const [total, setTotal] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [objectHasChanged, setObjectHasChanged] = useState<string>("");
  const [responseParams, setResponseParams] = useState<RequestListDTO>(
    new RequestListDTO()
  );

  const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());
  const [tbFilterList, setTbFilterList] = useState<TbFilterListDTO>(
    new TbFilterListDTO()
  );
  const [deleteOneObject, setDeleteOneObject] = useState<any>(undefined);
  const deleteUser = (obj: any) => {
    setDeleteOneObject(obj);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [];
  columns.push(ComponentCommonTools.columns_GetCheck());
  columns.push(ComponentCommonTools.columns_ImageColumn(LL, "idfile"));
  columns.push(ComponentCommonTools.columns_StandardColumn(LL, "name"));
  columns.push(ComponentCommonTools.columns_ConstantColumn(LL));
  columns.push(
    ComponentCommonTools.columns_StandardColumn(LL, "ordercriteria")
  );
  columns.push(
    ComponentCommonTools.columns_StandardColumn(
      LL,
      "isdefault",
      undefined,
      true,
      true,
      "boolean"
    )
  );
  columns.push(
    ComponentCommonTools.columns_ActionsColumn(
      LL,
      parent,
      deleteUser,
      ["delete"],
      ["delete"],
      undefined,
      "gallery"
    )
  );

  const loadObjects = (data: ResultListDTO) => {
    CommonTools.processListLoadObjects(
      data,
      setData,
      setRows,
      setTotal,
      setTotalPage,
      setResponseParams
    );
  };

  const getList = () => {
    if (!open) return;
    if (!reqList) return;
    if (!reqList.filters) return;
    if (!reqList.filters.length) return;

    setLoading(true);

    galleryService.getList(loadObjects, {}, reqList);
  };

  const getReqList = () => {
    const localReq = new RequestListDTO();

    const t = new RequestFilterDTO();
    t.field = "idparent";
    t.values = [idparent];

    localReq.page = 1;
    localReq.onpage = parseInt(CC("DEFAULT_ONPAGE_ATTACHEMENT", "10"));
    localReq.filters = [t];
    const sortCriteria = new RequestSortCriteriaDTO();
    sortCriteria.field = "ordercriteria";
    sortCriteria.asc = true;
    reqList.sortcriteria = [sortCriteria];

    setReqList(localReq);
  };
  const checkIsLoading = () => {
    const load: boolean = CommonTools.checkIsLoading(
      reqList,
      responseParams,
      data,
      total,
      totalPage,
      rows
    );
    setLoading(load);
  };

  const processTbFilterList = () => {
    const _tobj = new TbFilterListDTO();

    var t: TbFilterDTO;

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    _tobj.addFilter(t);

    setTbFilterList(_tobj);
  };

  useEffect(() => {
    processTbFilterList();
  }, []);

  useEffect(() => {
    getReqList();
  }, [idparent]);

  useEffect(() => {
    if (CommonTools.atLeastOneFieldDefined(reqList)) {
      getList();
    }
  }, [reqList, objectHasChanged]);

  useEffect(() => {
    checkIsLoading();
  }, [data, rows, reqList, total, totalPage]);

  const handleDelete = (item: any) => {
    galleryService.delete(item.id, getList, undefined);
  };
  const [openDialogAddGallery, setOpenDialogAddGallery] = useState(false);
  const handleOpenDialogAddGallery = () => {
    setOpenDialogAddGallery(true);
  };

  const [openDialogEditGallery, setOpenDialogEditGallery] = useState(false);
  const [idCurrent, setIdCurrent] = useState<string>("");

  const handleOpenDialogEditGallery = (value: any) => {
    setIdCurrent(value.id);
    setOpenDialogEditGallery(true);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>{LL("Gallery")}</DialogTitle>
        <IconButton
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box>
            <Box textAlign="center" mb={2}>
              <MyButton
                variant="outlined"
                sx={{ bgcolor: "#fff" }}
                cb={handleOpenDialogAddGallery}
              >
                {LL("Bt_Add")}
              </MyButton>
            </Box>
            <AddGalleryDialog
              open={openDialogAddGallery}
              setOpen={setOpenDialogAddGallery}
              idparent={idparent}
              setObjectWasChanged={setObjectHasChanged}
              parent={parent}
            />
            <EditGalleryDialog
              open={openDialogEditGallery}
              setOpen={setOpenDialogEditGallery}
              id={idCurrent}
              setObjectWasChanged={setObjectHasChanged}
              idparent={idparent}
              setId={setIdCurrent}
              parent={parent}
            />
          </Box>

          <MyTable
            _columns={columns}
            _data={rows}
            _total={total}
            _totalPage={totalPage}
            _reqList={reqList}
            _tbFilterList={tbFilterList}
            setReqList={setReqList}
            loading={loading}
            currentRoute={parent}
            onDelete={handleDelete}
            mainObject={parent}
            fieldToShowOnDelete="name"
            deleteOneObject={deleteOneObject}
            setDeleteOneObject={setDeleteOneObject}
            onRowClickCb={handleOpenDialogEditGallery}
            {...props}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export { GalleryDialog };
