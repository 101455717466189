import React, { useCallback, useEffect, useState } from "react";

import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { CommonTools } from "tools/utils/commontools";
import { CourseDto } from "dto/course/course.dto";
import { VideoDto } from "dto/system/video.dto";
import { logger } from "tools/utils/logger";
type Props = {
  obj: CourseDto;
  width: number;
  height: number;
  controls: boolean;
};

const CourseVideo: React.FC<Props> = ({ obj, width, height, controls }) => {
  const [video, setVideo] = useState<VideoDto | undefined>();

  const prepareVideo = useCallback(() => {
    if (!obj) return;

    if (!obj.hasOwnProperty("video")) return;
    if (!obj.video) return;
    if (obj.video.length === 0) return;
    const videoObj = CommonTools.getMediaDefault(obj.video);
    if (!videoObj) return;
    setVideo(videoObj);
  }, [obj]);

  useEffect(() => {
    prepareVideo();
  }, [prepareVideo]);

  const processVideo = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("video")) return null;
    if (!obj.video) return null;
    if (obj.video.length === 0) return null;
    if (!video) return null;

    if (!width) width = 640;
    if (!height) height = 360;
    if (!controls) controls = true;

    return ComponentCommonTools.getFileBlockVideo(
      video,
      width,
      height,
      controls
    );
  };

  return processVideo();
};

export { CourseVideo };
