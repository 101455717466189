import React, { useEffect, useState } from "react";
import { useResource } from "hooks/useResource";

import { MenuService } from "services/static/menu.service";
import MenuLevelProps from "interfaces/menulevelprops.interface";
import { MenuDto } from "dto/static/menu.dto";

import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools//types/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";

import { Types } from "tools/types/types";
import { BottomMenuItem } from "./BottomMenuItem";

import Grid from "@mui/material/Grid";

import { SkeletonFooter } from "components/elements/Skeleton/SkeletonFooter";
const menuService = new MenuService();

const BottomMenuLevel: React.FC<MenuLevelProps> = ({
  currentRoute,
  level,
  idparent,
  ...props
}) => {
  const { saveCache, getCache, _idlanguage } = useResource();

  const [isLoading, setIsLoading] = useState(true);
  const [objects, setObjects] = useState<Array<MenuDto>>([]);

  const getList = async () => {
    const reqList = new RequestListDTO();
    reqList.onpage = 100;
    reqList.page = 1;
    reqList.filters = [];

    let f: RequestFilterDTO;

    f = new RequestFilterDTO();
    f.field = "section";
    f.values = [];
    f.values.push(Types.SECTION_BOTTOM.toString());
    reqList.filters.push(f);

    f = new RequestFilterDTO();
    f.field = "status";
    f.values = [];
    f.values.push(Status.ACTIVE.toString());
    reqList.filters.push(f);

    if (_idlanguage) {
      f = new RequestFilterDTO();
      f.field = "idlanguage";
      f.values = [_idlanguage];
      reqList.filters.push(f);
    }

    f = new RequestFilterDTO();
    f.field = "idparent";
    f.values = [];
    f.values.push(idparent);
    reqList.filters.push(f);

    const cd = getCache(JSON.stringify(reqList));
    if (cd !== false) {
      setObjects(cd);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const data = await menuService.getList(undefined, {}, reqList);
    const rez = !data || !data.objects ? [] : data.objects;

    setObjects(rez);
    setIsLoading(false);

    saveCache(rez, JSON.stringify(reqList));
  };

  useEffect(() => {
    getList();
  }, [idparent, _idlanguage]);

  const processMenuItem = (obj: MenuDto, index: number) => {
    const hasChildren =
      obj.hasOwnProperty("haschildren") && obj.haschildren !== undefined
        ? obj.haschildren
        : false;
    return (
      <BottomMenuItem
        currentRoute={currentRoute}
        level={level}
        obj={obj}
        hasChildren={hasChildren}
        key={index}
      />
    );
  };

  const processMenuList = () => {
    if (!objects.length) return null

    return (
      <Grid container className="bottomMenu">
        {objects.map((item: MenuDto, i: number) => {
          return processMenuItem(item, i);
        })}
      </Grid>
    );
  };

  return isLoading ? <SkeletonFooter /> : processMenuList();
};

export { BottomMenuLevel };
