import React, { useEffect } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { HomeSearchBlock } from "components/static/homepage/HomeSearchBlock";
import { HomeCourseBlock } from "components/static/homepage/HomeCourseBlock";
import { HomeTopTeacherBlock } from "components/static/homepage/HomeTopTeacherBlock";
import { HomeTestimonial } from "components/static/homepage/HomeTestimonial";
import { HomeCategoryBlock } from "components/static/homepage/HomeCategoryBlock";
import { HomeBannersBottomBlock } from "components/static/homepage/HomeBannersBottomBlock";
import { AdvertisementHomeBannerSection1 } from "components/static/advertisement/AdvertisementHomeBannerSection1";
import { AdvertisementHomeBannerSection2 } from "components/static/advertisement/AdvertisementHomeBannerSection2";
import { AdvertisementHomeBannerBenefits } from "components/static/advertisement/AdvertisementHomeBannerBenefits";
import { AdvertisementHomeBannerSuggestions } from "components/static/advertisement/AdvertisementHomeBannerSuggestions";
import { NewsletterBlock } from "../../components/general/NewsletterBlock";
import { PageService } from "services/static/page.service";
import { Box } from "@mui/material";

const service = new PageService();
const HomePage: React.FC<PageComponentProps> = ({ currentRoute }) => {
  const getHomeSEO = () => {
    service.getHomeSEO();
  };

  useEffect(() => {
    getHomeSEO();
  }, []);

  return (
    <Box>
      {/* Top section */}
      <HomeSearchBlock currentRoute={currentRoute} />
      <AdvertisementHomeBannerBenefits currentRoute={currentRoute} />
      {/* Top section */}

      {/* Info section */}
      <HomeCourseBlock currentRoute={currentRoute} />
      <AdvertisementHomeBannerSuggestions currentRoute={currentRoute} />
      <HomeCategoryBlock currentRoute={currentRoute} />
      <AdvertisementHomeBannerSection1 currentRoute={currentRoute} />
      <AdvertisementHomeBannerSection2 currentRoute={currentRoute} />
      <HomeTopTeacherBlock currentRoute={currentRoute} />
      {/* Info section */}

      {/* Bottom section */}
      <HomeTestimonial currentRoute={currentRoute} />
      <HomeBannersBottomBlock currentRoute={currentRoute} />
      <NewsletterBlock currentRoute={currentRoute} />
      {/* Bottom section */}
    </Box>
  );
};

export { HomePage };
