import React from "react";

import { CourseSettingsService } from "services/course/coursesettings.service";

import { CourseSettingsDto } from "dto/course/coursesettings.dto";

import { useResource } from "hooks/useResource";

import { ComponentCommonTools } from "tools/utils/componentcommontools";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { Skeleton, Stack } from "@mui/material";
import { CallbackType } from "interfaces/commontypes.interface";
import useObject from "hooks/useObject";

const service = new CourseSettingsService();

type Props = {
  idCourse: string;
};

const CourseSettingsInfo: React.FC<Props> = ({ idCourse }) => {
  const { LL } = useResource();

  // const [loading, setLoading] = useState<boolean>(true);
  // const [obj, setObj] = useState<CourseSettingsDto | undefined | null>();

  // const loadObject = (obj: any) => {
  //   if (!obj) return;
  //   if (!obj.err) {
  //     const object = obj.obj ? obj.obj : [];
  //     setObj(object);
  //   }
  // };
  const get = (id: string, cb?: CallbackType, cbParams?: any) => {
    if (!id) return;
    service.getCourseSettingsByCourseId(id, cb, cbParams);
  };

  const [loading, obj] = useObject<CourseSettingsDto>(get, idCourse, [
    idCourse,
  ]);
  // const getObject = () => {
  //   if (idCourse !== "")
  //     service.getCourseSettingsByCourseId(
  //       idCourse,
  //       loadObject,
  //       {}
  //     );
  // };

  // const checkIsLoading = () => {
  //   let load: boolean = false;
  //   if (obj === undefined) load = true;
  //   setLoading(load);
  // };

  // useEffect(() => {
  //   getObject();
  // }, [idCourse]);

  // useEffect(() => {
  //   checkIsLoading();
  // }, [obj]);

  const processHasTest = () => {
    if (!obj) return null;

    if (!obj.hasOwnProperty("hastest")) {
      return (
        <Stack
          direction="row"
          spacing={1}
          className="categories"
          justifyContent="space-between"
        >
          <Typography>{LL("HasTest")}</Typography>
          <Typography className="nameFilter">{LL("False")}</Typography>
        </Stack>
      );
    }
    return (
      <Stack
        direction="row"
        spacing={1}
        className="categories"
        justifyContent="space-between"
      >
        <Typography>{LL("HasTest")}</Typography>
        <Typography className="nameFilter">
          {ComponentCommonTools.processObjectField(obj, "hastest_name")}
        </Typography>
      </Stack>
    );
  };

  const processCourseSettings = () => {
    if (!obj) return null;
    return (
      <Box>
        <Stack
          direction="row"
          spacing={1}
          className="categories"
          justifyContent="space-between"
        >
          <Typography> {LL("typecourse")}</Typography>
          <Typography className="nameFilter">
            {ComponentCommonTools.processObjectFieldOfObject(
              obj,
              "typecourse",
              "name"
            )}
          </Typography>
        </Stack>
        {processHasTest()}
      </Box>
    );
  };
  return loading ? <Skeleton /> : <Box>{processCourseSettings()}</Box>;
};

export { CourseSettingsInfo };
