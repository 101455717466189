import { Box } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { MyCheckboxField } from "components/form/MyCheckboxField";
import { TeacherPrivacyDto } from "dto/user/teacher.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React from "react";
import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: TeacherPrivacyDto;
  onSubmit: (obj: TeacherPrivacyDto) => void;
  loading: boolean;
};

const FormTeacherPrivacy: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField] = useForm<TeacherPrivacyDto>(
    defaultObject,
    RequiredValidator.getValidators(["id", "iduser"])
  );
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    onSubmit(obj);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box className="likeCard" mt={3}>
          <MyCheckboxField
            label={LL("showcourses")}
            checked={obj.showcourses}
            setObjectField={setObjField}
            field="showcourses"
            color="primary"
            size="small"
          />
        </Box>
        <Box className="likeCard" mt={3}>
          <MyCheckboxField
            label={LL("showprofile")}
            checked={obj.showprofile}
            setObjectField={setObjField}
            field="showprofile"
            color="primary"
            size="small"
          />
        </Box>

        <Box textAlign="center" mt={4}>
          <MyButton
            disabled={disabled || loading}
            color="primary"
            className="btn"
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormTeacherPrivacy };
