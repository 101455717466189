import React from "react";
import { CourseDto } from "dto/course/course.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { useResource } from "hooks/useResource";
import { Box, Stack, Typography } from "@mui/material";


type Props = {
  obj: CourseDto;
};

const CourseCardPriceContent: React.FC<Props> = ({
  obj
}) => {
  const { LL } = useResource();

  const processItem = () => {
    if (!obj) return null
    if (!obj.hasOwnProperty("courseprice")) return null
    if (!obj.courseprice) return null
    if (!obj.courseprice.hasOwnProperty("totalprice")) return null
    if (
      obj.courseprice.totalprice === undefined ||
      obj.courseprice.totalprice === null
    )
      return null
    else if (obj.courseprice.totalprice === 0) return processItemWithoutPrice();
    if (!obj.courseprice.hasOwnProperty("priceperlesson")) return null
    if (!obj.courseprice.priceperlesson) return null
    if (!obj.courseprice.hasOwnProperty("currency_identifier")) return null
    if (!obj.courseprice.currency_identifier) return null

    return processItemWithPrice(obj);
  };

  const processItemWithPrice = (obj: CourseDto) => {
    return (
      <Stack direction="column">
        <Stack direction="row" spacing={1}>
          <Typography className="priceTotal">
            <span>
              {ComponentCommonTools.processObjectFieldOfObject(
                obj,
                "courseprice",
                "totalprice"
              )}
            </span>
            {ComponentCommonTools.processObjectFieldOfObject(
              obj,
              "courseprice",
              "currency_identifier"
            )}
          </Typography>
        </Stack>

        <Stack direction="row" className="pricePer">
          <Typography component="span">
            <span>
              {ComponentCommonTools.processObjectFieldOfObject(
                obj,
                "courseprice",
                "priceperlesson"
              )}
            </span>
            {ComponentCommonTools.processObjectFieldOfObject(
              obj,
              "courseprice",
              "currency_identifier"
            )}
          </Typography>
          <Typography component="span">/</Typography>
          <Typography component="span">{LL("per_lesson")}</Typography>
        </Stack>
      </Stack>
    );
  };

  const processItemWithoutPrice = () => {
    return (
      <Box className="pricePer">
        <Typography component="span">{LL("Free")}</Typography>
      </Box>
    );
  };

  return processItem();
};

export { CourseCardPriceContent };
