import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

import { useAuth } from "hooks/useAuth";
import { WishlistIcon } from "components/user/wishlist/WishlistIcon";

const FavoriteBlock: React.FC<PageComponentProps> = ({ currentRoute }) => {
  const { user } = useAuth();

  return user ? <WishlistIcon currentRoute={currentRoute} /> : null;
};

export { FavoriteBlock };
