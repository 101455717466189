import React, { useState } from "react";
import { useResource } from "hooks/useResource";

import { TestimonialDto } from "dto/user/testimonial.dto";

import Box from "@mui/material/Box";

import Divider from "@mui/material/Divider";
import { MyButton } from "components/elements/button/MyButton";

import { ComponentCommonTools } from "tools/utils/componentcommontools";
import Typography from "@mui/material/Typography";

import { Collapse, Link, Skeleton, Stack } from "@mui/material";
import { MyRatingReadOnly } from "components/elements/MyRating/MyRatingReadOnly";
import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";
import { TeacherTypeIcon } from "components/user/teacher/TeacherTypeIcon";

import { TeacherInterfaceEditTestimonial } from "./TeacherInterfaceEditTestimonial";
import { ReportButton } from "components/elements/MyButton/ReportButton";
import { TestimonialService } from "services/user/testimonial.service";
import { MessageTypes } from "tools/types/messagetypes";
import { useMessage } from "hooks/useMessage";
import { CommonTools } from "tools/utils/commontools";
import { Status } from "tools//types/status";
import EditIcon from "@mui/icons-material/Edit";
const service = new TestimonialService();

type Props = {
  obj: TestimonialDto;
  itemIndex: number;
  setObjects: any;
  objects: Array<TestimonialDto>;
};

const TeacherInterfaceTestimonialCard: React.FC<Props> = ({
  obj,
  itemIndex,
  setObjects,
  objects,
  ...props
}) => {
  const { LL } = useResource();

  const { RegisterMessage } = useMessage();

  const processUserName = () => {
    if (!obj) return null;
    let fullUrl = "";
    if (obj.hasOwnProperty("user_fullurl") && obj.user_fullurl)
      fullUrl = obj.user_fullurl;

    if (!fullUrl) {
      return <Typography className="name">{processUserFullName()}</Typography>;
    }
    return (
      <Link href={fullUrl} target="_blank" rel="noopener noreferrer">
        <Typography className="name">{processUserFullName()}</Typography>
      </Link>
    );
  };
  const processRating = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("rating")) return null;
    if (!obj.rating) return null;
    return (
      <MyRatingReadOnly rating={obj.rating} count={0} showDetails={false} />
    );
  };

  const processUserFullName = () => {
    if (!obj) return "";
    if (!obj.user_name) return "";
    if (!obj.user_surname) return obj.user_name;

    return obj.user_name + " " + obj.user_surname;
  };
  const processAvatar = () => {
    if (!obj) return null;
    let name = "";
    let surname = "";
    if (obj.hasOwnProperty("user_name") && obj.user_name) name = obj.user_name;
    if (obj.hasOwnProperty("user_surname") && obj.user_surname)
      surname = obj.user_surname;

    return <MyAvatar obj={obj} name={name} surname={surname} sx={{ mr: 1 }} />;
  };

  const processUserCard = () => {
    if (!obj) return null;
    return (
      
        <Box p={2} className="user-message">
          <Box display="flex" alignItems="center">
            {processAvatar()}
            <Box>
              <Typography className="student-name" variant="h6" component={"div"}>
                {processUserName()}
              </Typography>
              <Stack direction="row" spacing={2} alignItems="center">
                {processRating()}
                <Typography className="data">
                  {ComponentCommonTools.processObjectField(obj, "date_name")}
                </Typography>
              </Stack>
            </Box>
          </Box>
          <Typography className="message" mt={2} sx={{ ml: 6 }}>
            {ComponentCommonTools.processObjectField(obj, "message")}
          </Typography>
        </Box>
      
    );
  };

  const processTeacherAvatar = () => {
    if (!obj) return null;
    if (!obj.teacher) return null;
    let name = "";
    let surname = "";
    if (obj.teacher.hasOwnProperty("name") && obj.teacher.name)
      name = obj.teacher.name;
    if (obj.teacher.hasOwnProperty("surname") && obj.teacher.name)
      surname = obj.teacher.surname;

    return (
      <MyAvatar
        obj={obj.teacher}
        name={name}
        surname={surname}
        sx={{ mr: 1 }}
      />
    );
  };

  const processTeacherStatus = () => {
    if (!obj) return null;
    if (!obj.teacher) return null;
    return <TeacherTypeIcon obj={obj.teacher} />;
  };

  const processTeacher = () => {
    if (!obj) return null;
    if (!obj.teacher) return null;
    if (!obj.teacher_fullname) return null;

    return (
      <Box display="flex" alignItems="center" alignContent="center">
        <Box mr={1}>{processTeacherStatus()}</Box>
        <Typography variant="h6">
          {ComponentCommonTools.processObjectField(obj, "teacher_fullname")}
        </Typography>
      </Box>
    );
  };
  const [extended, setExtended] = useState<boolean>(false);

  const processResponse = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("teacherresponse")) return null;
    if (!obj.teacherresponse) return null;
    return (
      <Box>
        <Box display="flex" alignItems="center">
          <Box>{processTeacherAvatar()}</Box>
          <Box>
            <Typography component={"div"}>{processTeacher()}</Typography>
          </Box>
        </Box>
        <Box>
          <Typography>
            {ComponentCommonTools.processObjectField(obj, "teacherresponse")}
          </Typography>
        </Box>
      </Box>
    );
  };

  const handleResponse = () => {
    setExtended(!extended);
  };
  const processTeacherCard = () => {
    if (!obj) return null;
    return (
      <Box className="teacher-response" px={2} mr={2} ml={8}>
        <Box my={1}>{processResponse()}</Box>
        <MyButton
          cb={handleResponse}
          className="response-btn"
          startIcon={<EditIcon />}
        >
          {LL("Response")}
        </MyButton>
        <Collapse in={extended} timeout="auto" unmountOnExit>
          {processFormContent()}
        </Collapse>
      </Box>
    );
  };
  const processFormContent = () => {
    if (!obj) return null;
    if (!extended) return null;
    return (
      <TeacherInterfaceEditTestimonial
        _obj={obj}
        extended={extended}
        setExtended={setExtended}
        itemIndex={itemIndex}
        setObjects={setObjects}
        objects={objects}
      />
    );
  };
  const processCourseImage = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("course")) return null;
    if (!obj.course) return null;
    if (!obj.course.hasOwnProperty("gallery")) return null;
    if (!obj.course.gallery) return null;
    if (!obj.course.gallery.length) return null;
    if (!obj.course.gallery[0]) return null;
    if (!obj.course.gallery[0].hasOwnProperty("fileInfoObj")) return null;
    if (!obj.course.gallery[0].fileInfoObj) return null;
    return ComponentCommonTools.getFileBlockImage(
      obj.course.gallery[0].fileInfoObj,
      50,
      50,
      "",
      false,
      { borderRadius: 50 }
    );
  };
  const processCourseName = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("course")) return null;
    if (!obj.course) return null;
    let fullUrl = "";
    if (obj.course.hasOwnProperty("fullurl") && obj.course.fullurl)
      fullUrl = obj.course.fullurl;
    if (!fullUrl) {
      return ComponentCommonTools.processObjectFieldOfObject(
        obj,
        "course",
        "name"
      );
    } else {
      return (
        <Link href={fullUrl} target="_blank" rel="noopener noreferrer">
          {ComponentCommonTools.processObjectFieldOfObject(
            obj,
            "course",
            "name"
          )}
        </Link>
      );
    }
  };
  const processCourseInfo = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("course")) return null;
    if (!obj.course) return null;
    return (
      <Box display="flex" sx={{ alignItems: "center" }}>
        {processCourseImage()}
        <Typography variant="h6" ml={2} className="course-name" component={"div"}>
          {processCourseName()}
        </Typography>
      </Box>
    );
  };

  const [loading, setLoading] = useState<boolean>(false);
  const handleReport = () => {
    if (!obj) return;
    if (!obj.hasOwnProperty("id")) return;
    if (!obj.id) return;
    setLoading(true);
    service.report(obj.id, handleReportResult, {});
  };

  const handleReportResult = (result: any) => {
    if (!result) return;
    if (!result.err) {
      updateObjectAtIndex(itemIndex);
      RegisterMessage(
        CommonTools.createMessage(
          LL("Testimonial_was_reported"),
          MessageTypes.MESSAGE_SUCCESS,
          " "
        )
      );
      setLoading(false);
    }
  };

  const updateObjectAtIndex = (index: number) => {
    const updatedArray = [...objects];
    obj.status = Status.REPORTED;
    updatedArray.splice(index, 1, { ...updatedArray[index], ...obj });

    setObjects(updatedArray);
  };
  const processReportButton = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("status")) return null;
    if (!obj.status) return null;
    if (loading) return <Skeleton variant="circular" height={40} width={40} />;
    return <ReportButton status={obj.status} action={handleReport} />;
  };
  const processItem = () => {
    if (!obj) return null;

    return (
      <Box className="box-faq-message" my={2} pb={2}>
        <Box
          display="flex"
          p={2}
          justifyContent="space-between"
          alignItems="center"
        >
          {processCourseInfo()}
          {processReportButton()}
        </Box>
        <Divider />
        {processUserCard()}

        {processTeacherCard()}
      </Box>
    );
  };

  return processItem();
};

export { TeacherInterfaceTestimonialCard };
