import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { TeacherInterfaceTestimonialList } from "components/user/teacherinterface/TeacherInterfaceTestimonialList";
import Typography from "@mui/material/Typography";
import { useResource } from "hooks/useResource";

const TeacherInterfaceTestimonialBlock: React.FC<PageComponentProps> = ({
  currentRoute
}) => {
  const { LL } = useResource();
  return (
    <React.Fragment>
      <Typography variant="h3" textAlign="center" my={3}>
        {LL("Testimonials profesor")}
      </Typography>
      <TeacherInterfaceTestimonialList currentRoute={currentRoute} />
    </React.Fragment>
  );
};

export { TeacherInterfaceTestimonialBlock };
