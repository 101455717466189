import React from "react";
import { useResource } from "hooks/useResource";

import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { AdvertisementDto } from "dto/static/advertisement.dto";

import { Types } from "tools/types/types";

import { Box, Grid, Typography } from "@mui/material";

import { SkeletonCards } from "components/elements/Skeleton/SkeletonCards";

import { AdvertisementBannerItem } from "./AdvertisementBannerItem";
import { useAdvertisementList } from "hooks/useAdvertisementList";

const AdvertisementBecomeTeacherSection2: React.FC<PageComponentProps> = () => {
  // const { saveCache, getCache } = useResource();
  const { LL } = useResource();
  // const [isLoading, setIsLoading] = useState(true);
  // const [objects, setObjects] = useState<Array<AdvertisementDto>>([]);

  // const getList = async () => {
  //   const reqList = new RequestListDTO();
  //   reqList.onpage = 100;
  //   reqList.page = 1;
  //   reqList.filters = [];

  //   let f: RequestFilterDTO;

  //   f = new RequestFilterDTO();
  //   f.field = "status";
  //   f.values = [];
  //   f.values.push(Status.ACTIVE.toString());
  //   reqList.filters.push(f);

  //   f = new RequestFilterDTO();
  //   f.field = "type";
  //   f.values = [];
  //   f.values.push(Types.ADVERTISEMENT_TYPE_BECOME_TEACHER.toString());
  //   reqList.filters.push(f);

  //   f = new RequestFilterDTO();
  //   f.field = "section";
  //   f.values = [];
  //   f.values.push(Types.SECTION_BECOME_TEACHER_2.toString());
  //   reqList.filters.push(f);

  //   const cd = getCache(JSON.stringify(reqList));

  //   if (cd !== false) {
  //     setObjects(cd);
  //     setIsLoading(false);
  //     return;
  //   }

  //   setIsLoading(true);

  //   const data = await advertisementService.getList(undefined, {}, reqList);

  //   const rez = !data || !data.objects ? [] : data.objects;

  //   setObjects(rez);
  //   setIsLoading(false);

  //   saveCache(rez, JSON.stringify(reqList));
  // };

  // useEffect(() => {
  //   getList();
  // }, []);

  const { objects, loading } = useAdvertisementList(
    Types.SECTION_BECOME_TEACHER_2,
    Types.ADVERTISEMENT_TYPE_BECOME_TEACHER,
    true,
    "advertisement_become_teacher_section2"
  );

  const processAdvertisementItem = (obj: AdvertisementDto, index: number) => {
    if (!obj) return null;
    return (
      <Grid item xs={12} md={4} key={index}>
        <Box className="item">
          <AdvertisementBannerItem obj={obj} />
        </Box>
      </Grid>
    );
  };

  const processAdvertisementList = () => {
    if (!objects) return null;
    if (!objects.length) return null;

    return (
      <Box className="container SectionSecond" my={5}>
        <Typography variant="h2" textAlign="center" mb={4}>
          {LL("Descoperă-ți potențialul")}
        </Typography>
        <Grid container spacing={2}>
          {objects.map((item: AdvertisementDto, i: number) => {
            return processAdvertisementItem(item, i);
          })}
        </Grid>
      </Box>
    );
  };

  return loading ? <SkeletonCards /> : processAdvertisementList();
};

export { AdvertisementBecomeTeacherSection2 };
