import React, { useEffect, useState } from "react";

import { useResource } from "hooks/useResource";

import { LanguageDto } from "dto/static/language.dto";
import Button from "@mui/material/Button";
import LanguageIcon from "@mui/icons-material/Language";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
interface Props {
  toggleDetail: any;
  isdetailopened: any;
}

const LanguageBlockBt: React.FC<Props> = ({
  toggleDetail,
  isdetailopened,
  ...props
}) => {
  const { langs, _idlanguage } = useResource();

  const [currentLang, setCurrentLang] = useState<LanguageDto>(
    new LanguageDto()
  );

  const processCurrentLang = () => {
    if (!Array.isArray(langs)) return;
    var cl: LanguageDto = new LanguageDto();
    for (let lang of langs) {
      if (lang.id != _idlanguage) continue;
      cl = lang;
      break;
    }
    if (cl.id == undefined || cl.id == currentLang.id) {
      return;
    }
    setCurrentLang(cl);
  };

  useEffect(() => {
    processCurrentLang();
  }, [langs, _idlanguage]);

  return currentLang ? (
    <Button
      id="basic-button"
      aria-controls={isdetailopened ? "basic-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={isdetailopened ? "true" : undefined}
      className="languageBottom"
      startIcon={<LanguageIcon />}
      onClick={(event: any) => {
        toggleDetail(event);
      }}
    >
      {currentLang.name}
      <KeyboardArrowDownIcon fontSize="small" sx={{ mx: 0, px: 0 }} />
    </Button>
  ) : null;
};

export { LanguageBlockBt };
