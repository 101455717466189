import { NoResult } from "components/general/NoResult";
import { CourseSearchContext } from "context/coursesearch.context";
import { CourseDto } from "dto/course/course.dto";
import React, { useContext } from "react";
import { CourseListCard } from "../course/CourseListCard";
import { Box } from "@mui/material";

const CourseSearchList: React.FC = () => {
  const {
    state: { objects, loading },
  } = useContext(CourseSearchContext);
  const processCourseItem = (obj: CourseDto, index: number) => {
    if (!obj) return null
    return (
      <Box key={index}>
        <CourseListCard obj={obj} loading={loading} />
      </Box>
    );
  };
  if (!objects) return null;
  if (!objects.length) return <NoResult identifier="course_search" />;
  return (
    <>
      {objects.map((item: CourseDto, i: number) => processCourseItem(item, i))}
    </>
  );
};

export { CourseSearchList };
