import React, { useContext } from "react";

import { List, Box } from "@mui/material";

import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { ClassroomContext } from "context/classroom.context";
import { LessonDto } from "dto/course/lesson.dto";
import { ClassroomLessonItem } from "./ClassroomLessonItem";

export const ClassroomLessonList: React.FC<PageComponentProps> = ({
  currentRoute,
}) => {
  const {
    state: { lessonObjects },
  } = useContext(ClassroomContext);

  const processItem = (item: LessonDto, i: number) => {
    if (!item) return null;
    return (
      <Box key={i}>
        <ClassroomLessonItem obj={item} currentRoute={currentRoute} />
      </Box>
    );
  };

  const processComponent = () => {
    if (!lessonObjects) return null;
    if (lessonObjects.length === 0) return null;
    return (
      <List>
        {lessonObjects.map((item: LessonDto, i: number) => {
          return processItem(item, i);
        })}
      </List>
    );
  };

  return processComponent();
};
