import React, { useState, useEffect } from "react";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";

import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { Area } from "react-easy-crop/types";
import { Stack, IconButton, Skeleton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { MyButton } from "components/elements/button/MyButton";

import { useResource } from "hooks/useResource";
import { FileUploader } from "react-drag-drop-files";
import { FileTools } from "tools/utils/filetools";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

interface Crop {
  x: number;
  y: number;
}

type AvatarCropperProps = {
  setFile: any;
  avatar?: any;
};

const AvatarCropper: React.FC<AvatarCropperProps> = ({ setFile, avatar }) => {
  const { LL } = useResource();
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState<Crop>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [imageSrcAfterConfirm, setImageSrcAfterConfirm] = useState<
    string | null
  >(null);
  const [croppedFile, setCroppedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const onCropComplete = async (_: Area, croppedAreaPixels: Area) => {
    const croppedImage = await getCroppedImg(imageSrc!, croppedAreaPixels);
    setCroppedImage(croppedImage);
    setImageSrcAfterConfirm(croppedImage);
    const blob = await fetch(croppedImage).then((r) => r.blob());
    const croppedFile = new File([blob], "avatar.jpg", { type: "image/jpeg" });
    setCroppedFile(croppedFile);
  };

  useEffect(() => {
    setLoading(true);
    processDefaultAvatar();
    setLoading(false);
  }, [avatar]);

  const processDefaultAvatar = () => {
    if (!avatar) return;
    if (!avatar.hasOwnProperty("_id")) return;
    if (!avatar.hasOwnProperty("name")) return;
    if (!avatar._id || !avatar.name) return;
    const url = FileTools.getFileUrl(avatar._id, avatar.name);
    if (!url) return;
    setImageSrcAfterConfirm(url);
  };

  const onFileChange = (e: any) => {
    const file = e;
    if (!file.type.startsWith("image/")) {
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageSrc(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const onConfirm = () => {
    if (!croppedFile) return;

    setFile(croppedFile);

    setImageSrc(null);
  };

  const handleDecline = () => {
    setImageSrc(null);
    if (!avatar) {
      setImageSrcAfterConfirm(null);
    } else {
      processDefaultAvatar();
    }
    setFile(null);
  };

  const fileTypes = ["jpeg", "png", "jpg"];

  return !loading ? (
    <Box>
      {imageSrc ? (
        <Stack direction="row" spacing={2}>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              justifyContent: "center",
              alignItems: "end",
              flexDirection: "column",
              gap: 2,
            }}>
            <Avatar
              src={croppedImage || undefined}
              alt="Avatar Preview"
              sx={{ width: 100, height: 100 }}
            />
            <Avatar
              src={croppedImage || undefined}
              alt="Avatar Preview"
              sx={{ width: 75, height: 75 }}
            />
            <Avatar
              src={croppedImage || undefined}
              alt="Avatar Preview"
              sx={{ width: 50, height: 50 }}
            />
          </Box>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: 244,
              background: "#D2D2D2",
            }}>
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              cropShape="round"
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignContent: "center",
                height: "calc(100% - 48px )",
                pt: 3,
                pb: 2,
              }}>
              <Slider
                sx={{ py: "0px", height: "100%" }}
                orientation="vertical"
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                onChange={(e, newValue) => setZoom(newValue as number)}
              />
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
              }}>
              <MyButton
                className="saveCropperButton"
                sx={{ mt: 1 }}
                onClick={onConfirm}>
                {LL("Save")}
              </MyButton>
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: "5px",
                right: "10px",
                backgroundL: "red",
              }}>
              <IconButton
                className="deleteButtonCropper"
                sx={{ mt: 1 }}
                onClick={handleDecline}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        </Stack>
      ) : (
        <Stack direction="row" sx={{ width: "100%", gap: { xs: 0, sm: 2 } }}>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              justifyContent: "center",
              alignItems: "end",
              flexDirection: "column",
              gap: 2,
            }}>
            <Avatar
              src={imageSrcAfterConfirm || undefined}
              alt="Avatar Preview"
              sx={{ width: 100, height: 100 }}
            />
            <Avatar
              src={imageSrcAfterConfirm || undefined}
              alt="Avatar Preview"
              sx={{ width: 75, height: 75 }}
            />
            <Avatar
              src={imageSrcAfterConfirm || undefined}
              alt="Avatar Preview"
              sx={{ width: 50, height: 50 }}
            />
          </Box>
          {/* <TextField
                        type="file"
                        size="small"
                        variant="outlined"
                        onChange={onFileChange}
                    /> */}
          <Box className="boxUploader">
            <FileUploader
              handleChange={onFileChange}
              name="file"
              types={fileTypes}>
              <Box sx={{ py: { xs: "26px", sm: "auto" } }} className="drag">
                <Box>
                  <CloudUploadOutlinedIcon className="icon" />
                </Box>
                <Typography
                  sx={{ display: { xs: "none", sm: "block" } }}
                  variant="h3">
                  {LL("Drag_and_Drop_a_new_image")}
                </Typography>
                <Typography
                  sx={{ display: { xs: "none", sm: "block" } }}
                  my={2}
                  className="alto">
                  {LL("or")}
                </Typography>
                <Box>
                  <Typography className="btnUp">
                    {LL("Click_here_to_select_a_file")}
                  </Typography>
                </Box>
              </Box>
            </FileUploader>
          </Box>
        </Stack>
      )}
    </Box>
  ) : (
    <Skeleton />
  );
};

// Helper function to get cropped image
const createImage = (url: string) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

async function getCroppedImg(
  imageSrc: string,
  pixelCrop: Area
): Promise<string> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d")!;

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  return new Promise<string>((resolve) => {
    canvas.toBlob((file) => {
      if (!file) {
        return;
      }
      resolve(URL.createObjectURL(file));
    }, "image/jpeg");
  });
}

export default AvatarCropper;
