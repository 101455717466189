import React from "react";
import { Status } from "tools//types/status";
import { GeneralChip } from "components/elements/Chip/GeneralChip";

type Props = {
  value: number;
};

const ValidationStatusChip: React.FC<Props> = ({ value }) => {
  const processColor = (value: number) => {
    switch (value) {
      case Status.NEED_VALIDATION:
        return "warning";
      case Status.VALIDATION_REJECTED:
        return "error";
      case Status.VALIDATED:
        return "success";
      default:
        return "primary";
    }
  };

  return (
    <GeneralChip value={value} processColor={processColor} service={Status} />
  );
};

export { ValidationStatusChip };
