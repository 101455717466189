
import { GoogleOAuthProvider } from "@react-oauth/google";
import IProvider from "interfaces/provider.interface";
import { Config } from "tools/utils/config";

export const MyGoogleOAuthProvider:React.FC<IProvider> = ({ children }) => {
   
  const clientId: string = Config.GOOGLE_CONSOLE_CLIENT_ID;
  return (
    <GoogleOAuthProvider clientId={clientId}>{children}</GoogleOAuthProvider>
  );
};
