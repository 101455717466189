import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";

interface SkeletonProps {
  lines?: number;
  width?: number;
}

const SkeletonReviewTest: React.FC<SkeletonProps> = ({ lines }) => {
  const gridItem = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        // marginX: "auto",
        flexDirection: "row",
        width: "100%",
        gap: 2,
      }}>
      <Grid xs={12} container spacing={2} mb={5}>
        <Grid xs={12} md={3} sx={{}}>
          <Skeleton
            variant="text"
            sx={{ fontSize: "1.25rem", width: "62%", minWidth: "120px" }}
          />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem", width: "38%", minWidth: "120px" }}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              mt: 1,
              gap: 2,
            }}>
            <Box
              sx={{ width: "auto", display: "flex", flexDirection: "column" }}>
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", width: "50%", minWidth: "80px" }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", width: "20%", minWidth: "40px" }}
              />
            </Box>
            <Box
              sx={{ width: "auto", display: "flex", flexDirection: "column" }}>
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", width: "50%", minWidth: "80px" }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", width: "20%", minWidth: "40px" }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} md={4}>
          <Skeleton
            variant="text"
            sx={{ fontSize: "1.25rem", width: "70%", minWidth: "120px" }}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              gap: 2,
              mt: 2,
            }}>
            <Skeleton
              variant="circular"
              width={50}
              height={50}
              sx={{ minWidth: "50px" }}
            />
            <Box
              sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", width: "20%", minWidth: "120px" }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", width: "50%", minWidth: "120px" }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid
          xs={12}
          md={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            flexDirection: "column",
          }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              gap: 2,
            }}>
            <Box
              sx={{ width: "50%", display: "flex", flexDirection: "column" }}>
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", width: "30%", minWidth: "40px" }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", width: "60%", minWidth: "100px" }}
              />
            </Box>
            <Box
              sx={{ width: "50%", display: "flex", flexDirection: "column" }}>
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", width: "70%", minWidth: "120px" }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", width: "30%", minWidth: "40px" }}
              />
            </Box>
          </Box>
          <Skeleton
            variant="rounded"
            width="70%"
            height="30px"
            sx={{ mt: 2 }}
          />
        </Grid>
        <Grid xs={12} md={2}>
          <Skeleton
            variant="text"
            sx={{ fontSize: "1.25rem", width: "50%", minWidth: "120px" }}
          />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1.25rem", width: "30%", minWidth: "60px" }}
          />
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Box sx={{ width: "100%", height: "auto" }} className="">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          alignContent: "center",
          //   margin: "auto",
          padding: "20px 0px 20px 0px",
        }}>
        <Grid
          container
          xs={12}
          sx={{ maxWidth: "1522px", width: "100%" }}
          spacing={2}>
          {Array(lines)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export { SkeletonReviewTest };
