import { AnswerDto } from "dto/course/answer.dto";
import { QuestionDto } from "dto/course/question.dto";

import React, { useState } from "react";

import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

type Props = {
  obj: QuestionDto;
  objects: Array<AnswerDto>;
  answerSelected: Array<string>;
  setAnswerSelected: any;
};

const AnswerSingleQuestionOption: React.FC<Props> = ({
  obj,
  objects,
  answerSelected,
  setAnswerSelected,
  ...props
}) => {
  const [value, setValue] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    setAnswerSelected([(event.target as HTMLInputElement).value]);
  };

  const processAnswerItem = (obj: AnswerDto, index: number) => {
    if (!obj) return null;
    if (!obj.id) return null;
    if (!obj.name) return null;

    return (
      <FormControlLabel
        value={obj.id}
        control={<Radio />}
        label={obj.name}
        className="answerElement"
        key={index}
      />
    );
  };

  const processAnswerList = () => {
    if (!objects.length) return null;
    return (
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          value={value}
          onChange={handleChange}
          row
        >
          {objects.map((obj, index) => {
            return processAnswerItem(obj, index);
          })}
        </RadioGroup>
      </FormControl>
    );
  };

  return processAnswerList();
};

export { AnswerSingleQuestionOption };
