import React, { useState, useEffect } from "react";
import { useResource } from "hooks/useResource";

import { Box, Skeleton, Typography, Divider, Stack } from "@mui/material";

import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { BlogDto } from "dto/static/blog.dto";
import { BlogService } from "services/static/blog.service";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { HtmlTag } from "components/form/HtmlTag";
import { MainObjectImage } from "components/elements/media/MainObjectImage";
// import { ObjectGallery } from "components/elements/media/ObjectGallery";
// import { ObjectAttachment } from "components/elements/media/ObjectAttachemnt";
import { MyBreadcrumbsCourse } from "components/elements/MyBreadcrumbs/MyBreadcrumbsCourse";
import BreadcrumbChildrenDto from "dto/app/breadcrumbchildren.dto";

type BlogStaticProps = {
  currentRoute: any;
};

const blogService = new BlogService();

const BlogStatic: React.FC<BlogStaticProps> = ({ currentRoute, ...props }) => {
  const { LL } = useResource();
  const [isLoading, setIsLoading] = useState(true);

  const [idObject, setIdObject] = useState<string>("");
  const [obj, setObj] = useState<BlogDto>();

  const getIdObj = () => {
    let id =
      currentRoute &&
      currentRoute.processedData &&
      currentRoute.processedData.idobj
        ? currentRoute.processedData.idobj
        : "";

    if (!id) {
      id =
        currentRoute &&
        currentRoute.processedData &&
        currentRoute.processedData._obj &&
        currentRoute.processedData._obj.id
          ? currentRoute.processedData._obj.id
          : id;
    }

    if (!id) {
      id =
        currentRoute && currentRoute._getVars && currentRoute._getVars.id
          ? currentRoute._getVars.id
          : id;
    }

    let _obj =
      currentRoute &&
      currentRoute.processedData &&
      currentRoute.processedData._obj
        ? currentRoute.processedData._obj
        : undefined;

    // logger("getObj", id, _obj);
    setIdObject(id);
    setObj(_obj);
  };

  useEffect(() => {
    getIdObj();
  }, [currentRoute]);

  const getObj = () => {
    if (idObject == undefined || idObject == "" || idObject == null) return;

    if (obj != undefined && obj.id != undefined && obj.id == idObject) {
      return;
    }
    // logger("getObj", idObject);
    blogService.get(idObject, loadObjects);
  };

  useEffect(() => {
    getObj();
  }, [idObject]);

  const loadObjects = (result: ResultObjectDTO) => {
    if (!result) return;
    if (!result.err) {
      setObj(result.obj);
    }
  };

  const processBreadcrumbChildren = (): BreadcrumbChildrenDto[] => {
    const breadcrumbArray: BreadcrumbChildrenDto[] = [];
    let breadcrumb = new BreadcrumbChildrenDto();
    breadcrumb.url = "/";
    breadcrumb.label = LL("crumb_homepage");
    breadcrumbArray.push(breadcrumb);

    breadcrumb = new BreadcrumbChildrenDto();
    breadcrumb.url = "/blog";
    breadcrumb.label = LL("crumb_Blog");
    breadcrumbArray.push(breadcrumb);

    if (obj && obj.hasOwnProperty("name") && obj.name) {
      const breadcrumbChildrenItem = new BreadcrumbChildrenDto();
      breadcrumbChildrenItem.url = "";
      breadcrumbChildrenItem.label = obj.name;
      breadcrumbArray.push(breadcrumbChildrenItem);
    }

    return breadcrumbArray;
  };

  const processBreadcrumb = () => {
    if (!obj) return <></>;
    const breadcrumbChildren = processBreadcrumbChildren();
    return (
      <Box className="breadcrumbs">
        <MyBreadcrumbsCourse childs={breadcrumbChildren} />
      </Box>
    );
  };

  const checkLoading = () => {
    let load = false;

    if (!obj || obj == undefined || obj == null) load = true;

    setIsLoading(load);
  };

  useEffect(() => {
    checkLoading();
  }, [idObject, obj]);

  const processMainImage = () => {
    if (!obj) return <></>;
    // logger("processMainImage", obj);
    const _gallery = obj.gallery ? obj.gallery : undefined;
    return (
      <Box className="image">
        <MainObjectImage gallery={_gallery} />
      </Box>
    );
  };

  const processHead = () => {
    if (!obj) return <></>;

    return (
      <Typography variant="h2">
        {ComponentCommonTools.processObjectField(obj, "name")}
      </Typography>
    );
  };

  const processContent = () => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("content")) return <></>;
    if (!obj.content) return <></>;
    return <HtmlTag content={obj.content} />;
  };

  // const processGallery = () => {
  //   if (!obj) return <></>;

  //   const _gallery = obj.gallery ? obj.gallery : undefined;
  //   return <ObjectGallery serverGallery={_gallery} nonMain={true} />;
  // };

  // const processAttachments = () => {
  //   // if (!objects.length) return <></>;
  //   if (!obj) return <></>;
  //   if (!obj.hasOwnProperty("attachment")) return <></>;
  //   if (!obj.attachment) return <></>;

  //   return (
  //     <ObjectAttachment serverAttachment={obj.attachment} nonMain={true} />
  //   );
  // };

  // const processVideos = () => {
  //   // if (!objects.length) return <></>;
  //   return <>TEMPORAR</>;
  // };

  return isLoading ? (
    <Skeleton />
  ) : (
    <Box className="pageGeneral blogDetail">
      <Box className="onlyBread" py={3}>
        <Box className="container">{processBreadcrumb()}</Box>
      </Box>

      <Box className="container blogSize" py={4}>
        <Stack
          direction={{ md: "row", sm: "column" }}
          spacing={2}
          alignItems="center"
        >
          {processHead()}
          {processMainImage()}
        </Stack>
      </Box>
      <Divider />
      <Box className="container blogSize">{processContent()}</Box>
      {/*<Box><Box className="container blogSize">{processGallery()}</Box>
      {processAttachments()}</Box>
      <Box>{processVideos()}</Box> */}
    </Box>
  );
};

export { BlogStatic };
