import { Box, Typography } from "@mui/material";
import React, { useState } from "react";

import { useResource } from "hooks/useResource";
import { useAuth } from "hooks/useAuth";
import { PostTeacherFAQFormDto } from "dto/user/teacherfaq.dto";

import { TeacherFAQService } from "services/user/teacherfaq.service";
import { useMessage } from "hooks/useMessage";
import { CommonTools } from "tools/utils/commontools";
import { MessageTypes } from "tools/types/messagetypes";
import { TeacherFAQForm } from "./TeacherFAQForm";

type Props = {
  idCourse: string;
  idTeacher: string;
};

const service = new TeacherFAQService();

const AddTeacherFAQForm: React.FC<Props> = ({ idCourse, idTeacher }) => {
  const { LL } = useResource();
  const { user } = useAuth();
  const { RegisterMessage } = useMessage();
  // const [obj, setObj] = useState<PostTeacherFAQDto>(new PostTeacherFAQDto());
  // const [isFormDisabled, setIsFormDisabled] = useState(false);

  // const setObjField = (field: any, value: any) => {
  //   let t = JSON.parse(JSON.stringify(obj));
  //   t[field] = value;
  //   setObj(t);
  // };

  // const checkIsFormDisabled = () => {
  //   let rez = false;
  //   if (!obj) rez = true;
  //   if (!obj.message) rez = true;
  //   setIsFormDisabled(rez);
  // };
  // useEffect(() => {
  //   checkIsFormDisabled();
  // }, [obj.message]);

  const [loading, setLoading] = useState(false);
  const [obj, setObj] = useState<PostTeacherFAQFormDto>(
    new PostTeacherFAQFormDto(
      CommonTools.processObjectField(user, ["id"]),
      idTeacher,
      idCourse
    )
  );

  const onSubmit = (obj: PostTeacherFAQFormDto) => {
    setLoading(true);

    service.add(handleResult, {}, obj);
  };

  const handleResult = (result: any) => {
    if (!result) return;
    if (!result.err) {
      RegisterMessage(
        CommonTools.createMessage(
          LL("Your_question_was_placed"),
          MessageTypes.MESSAGE_SUCCESS,
          " "
        )
      );
      setObj(
        new PostTeacherFAQFormDto(
          CommonTools.processObjectField(user, ["id"]),
          idTeacher,
          idCourse
        )
      );
      setLoading(false);
    }
  };

  // const processForm = () => {
  //   if (!user) return <></>;
  //   if (!idCourse) return <></>;
  //   if (!idTeacher) return <></>;
  //   if (loading) return <Skeleton variant="rectangular" height={150} />;

  //   return (
  //     <form>
  //       <MyTextField
  //         multiline
  //         minRows={4}
  //         fullWidth
  //         id="message"
  //         name="message"
  //         variant="standard"
  //         color="primary"
  //         className="form-input"
  //         _label={LL("Place_you_question ...")}
  //         size="small"
  //         value={obj.message}
  //         setObj={setObjField}
  //         _vresults={undefined}
  //       />
  //       <Stack
  //         direction="row"
  //         spacing={2}
  //         mt={2}
  //         alignItems="center"
  //         justifyContent="space-between"
  //       >
  //         <Alert severity="info">
  //           <Stack direction="row" spacing={1}>
  //             <Typography>{LL("Nu uita sa faci cunostinta cu")}</Typography>
  //             <Link
  //               href={CC("term_page", "/termeni")}
  //               target="_blank"
  //               rel="noopener"
  //             >
  //               <Typography>{LL("Regulile de comunicare")}</Typography>
  //             </Link>
  //             <Typography>{LL("pe LiveClass")}</Typography>
  //           </Stack>
  //         </Alert>
  //         <MyButton
  //           cb={handleSubmit}
  //           tabIndex={4}
  //           disabled={isFormDisabled}
  //           color="primary"
  //           className="btn"
  //           variant="contained"
  //           size="small"
  //           type="submit"
  //           sx={{ mt: 4 }}
  //           children={LL("Bt_Send_Question")}
  //         />
  //       </Stack>
  //     </form>
  //   );
  // };
  if (!user) return null;
  return (
    <Box>
      <Typography variant="h5" mb={3} mt={4}>
        {LL("Ask_Teacher_a_question")}
      </Typography>
      <TeacherFAQForm defaultObj={obj} onSubmit={onSubmit} loading={loading} />
    </Box>
  );
};

export { AddTeacherFAQForm };
