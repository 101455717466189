import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { Box, Typography } from "@mui/material";
import { useResource } from "hooks/useResource";

import { CourseListSuggestions } from "components/course/course/CourseListSuggestions";
import { StudentNoCourseYet } from "components/static/page/block/StudentNoCourseYet";

const StudentNoCoursePage: React.FC<PageComponentProps> = ({
  currentRoute,
}) => {
  const { LL } = useResource();
  const processSection = () => {
    return (
      <Box className="container">
        <StudentNoCourseYet currentRoute={currentRoute} />
      </Box>
    );
  };

  const processBottom = () => {
    return (
      <Box className="container" mt={5}>
        <Typography className="preText" textAlign="center" mb={4}>
          {LL("Recommended_courses")}
        </Typography>
        <Box>
          <CourseListSuggestions currentRoute={currentRoute} />
        </Box>
      </Box>
    );
  };
  return (
    <Box className="withoutCourse">
      {processSection()}
      {processBottom()}
    </Box>
  );
};

export { StudentNoCoursePage };
