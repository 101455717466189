import React, { useState, useEffect, useCallback } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Drawer, IconButton, Stack, Divider } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { CourseCategoryMainDrawer } from "components/course/coursecategory/CourseCategoryMainDrawer";

import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { CourseCategoryDto } from "dto/course/coursecategory.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CourseCategoryService } from "services/course/coursecategory.service";

const drawerWidth = 300;
const service = new CourseCategoryService();
type Props = {
  open: boolean;
  setOpen: any;
  setOpenMainDrawer: any;
  setCourseCategoryId: any;
  courseCategoryId: string;
  setCourseCategoryObject: any;
  courseCategoryObject: any;
  setOpenUserDrawer: any;
};
const SecondDrawer: React.FC<Props> = ({
  open,
  setOpen,
  setOpenMainDrawer,
  setCourseCategoryId,
  courseCategoryId,
  setCourseCategoryObject,
  courseCategoryObject,
  setOpenUserDrawer,
}) => {
  const [currentObject, setCurrentObject] = useState<CourseCategoryDto | any>(
    null
  );
  const [loading, setLoading] = useState<boolean>(true);
  const getObject = () => {
    setLoading(true);
    if (courseCategoryId) {
      service.get(courseCategoryId, loadObject, {});
    }
  };

  const loadObject = (obj: ResultObjectDTO) => {
    if (!obj) return;
    if (!obj.err) {
      const object = obj.obj ? obj.obj : {};
      setCurrentObject(object);
    }
  };

  useEffect(() => {
    if (courseCategoryObject) {
      setCurrentObject(courseCategoryObject);
      setLoading(false);
    } else {
      getObject();
    }
  }, [courseCategoryObject]);
  
  const checkIsLoading = useCallback(() => {
    let load: boolean = false;
    if (currentObject === undefined) load = true;
    setLoading(load);
  }, [currentObject]);

  useEffect(() => {
    checkIsLoading();
  }, [checkIsLoading]);

  const handleClose = () => {
    setOpenMainDrawer(false);
    setOpenUserDrawer(false);
    setOpen(false);
  };

  const handleCloseSecondDrawer = () => {
    setOpen(false);
  };

  const processCloseButton = () => {
    return (
      <IconButton onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    );
  };

  const processCloseButtonSecondDrawer = () => {
    return (
      <IconButton onClick={handleCloseSecondDrawer}>
        <ArrowBackIosNewIcon />
      </IconButton>
    );
  };

  const processIdCourseCategory = () => {
    if (!courseCategoryId) {
      setOpen(false);
      return "";
    }
    return courseCategoryId;
  };

  const processCourseCategoryList = () => {
    return (
      <Box role="presentation" sx={{ width: drawerWidth }}>
        <CourseCategoryMainDrawer
          handleClose={handleClose}
          setCourseCategoryId={setCourseCategoryId}
          setCourseCategoryObject={setCourseCategoryObject}
          setOpenSecondDrawer={setOpen}
          idparent={processIdCourseCategory()}
        />
      </Box>
    );
  };

  const handleGoBackCategory = () => {
    if (!currentObject) return;
    if (!currentObject.hasOwnProperty("idparent")) return;
    if (!currentObject.idparent) {
      setCourseCategoryId("");
      setCourseCategoryObject(null);
      setOpen(false);
    }
    setCourseCategoryId(currentObject.idparent);
    setCourseCategoryObject(null);
  };

  const processIconGoBackCategory = () => {
    if (loading) return null;
    if (!courseCategoryObject) return null;
    if (!courseCategoryObject.hasOwnProperty("idparent")) return null;
    if (!courseCategoryObject.idparent) return null;
    return (
      <IconButton onClick={handleGoBackCategory} sx={{ m: 0, p: 0 }}>
        <ArrowBackIosNewIcon sx={{ m: 0, p: 0, pr: 1 }} className="iconRight" />
      </IconButton>
    );
  };
  const processCurrentCourseCategory = () => {
    if (loading) return null;
    if (!currentObject) return null;

    return (
      <Box sx={{ width: drawerWidth }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          p={2}
        >
          <Box m={0}>{processIconGoBackCategory()}</Box>
          <Box className="nameTopC">
            {ComponentCommonTools.processObjectField(currentObject, "name")}
          </Box>
        </Stack>
        <Divider />
      </Box>
    );
  };

  return (
    <Box>
      <Drawer open={open} onClose={handleClose}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          py={2}
        >
          {processCloseButtonSecondDrawer()}
          {processCloseButton()}
        </Stack>
        <Divider />
        {processCurrentCourseCategory()}
        {processCourseCategoryList()}
      </Drawer>
    </Box>
  );
};

export { SecondDrawer };
