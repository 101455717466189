import ResultObjectDTO from "dto/app/resultobject.dto";
import { CourseTeacherRequestProcessedDto } from "dto/course/courseteacherrequest.dto";
import { useResource } from "hooks/useResource";
import React, { useState, useEffect } from "react";

import { CourseTeacherRequestService } from "services/course/courseteacherrequest.service";
import { useAuth } from "hooks/useAuth";

import { MyButton } from "components/elements/button/MyButton";
import { Status } from "tools//types/status";

import { Types } from "tools/types/types";

import { CourseDto } from "dto/course/course.dto";

type Props = {
  [x: string]: any;
  objectWasChanged: string;
  idCourse: string;
  course: CourseDto;
};

const service = new CourseTeacherRequestService();
const CourseUnpublishButton: React.FC<Props> = ({
  objectWasChanged,
  idCourse,
  course,
  ...props
}) => {
  const { LL, CC } = useResource();
  const { teacher } = useAuth();
  const [obj, setObj] = useState<
    CourseTeacherRequestProcessedDto | undefined
  >();
  const [loading, setLoading] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(true);

  const getObject = () => {
    if (!teacher) return;
    if (!teacher.hasOwnProperty("id")) return;
    if (!teacher.id) return;
    if (!course) return;
    if (!course.hasOwnProperty("status")) return;
    if (!course.status) return;
    if (course.status !== Status.ACTIVE) return;

    setLoading(true);
    service.verifyProcessed(
      teacher.id,
      Types.TYPE_REQUEST_TEACHER_COURSE_UNPUBLISH,
      idCourse,
      loadObject,
      {}
    );
  };
  const loadObject = (obj: ResultObjectDTO) => {
    if (!obj) return;
    if (!obj.err) {
      const object: CourseTeacherRequestProcessedDto = obj.obj ? obj.obj : {};
      setObj(object);
      if (object.processed) setDisabled(false);
      else setDisabled(true);
    }
  };
  useEffect(() => {
    getObject();
  }, [teacher, objectWasChanged, idCourse]);

  const checkIsLoading = () => {
    let load: boolean = false;
    if (obj === undefined) load = true;
    setLoading(load);
  };

  useEffect(() => {
    checkIsLoading();
  }, [obj]);

  const processComponent = () => {
    if (!teacher) return <></>;
    if (!teacher.hasOwnProperty("id")) return <></>;
    if (!teacher.id) return <></>;

    return (
      <MyButton {...props} disabled={disabled}>
        {LL("unpublish_course_btn")}
      </MyButton>
    );
  };
  return processComponent();
};

export { CourseUnpublishButton };
