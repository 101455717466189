
import { createTheme } from "@mui/material/styles";

const theme = createTheme(
  {
    palette: {
      mode: 'light',
      primary: {
        main: '#0072EF',
        dark: '#0055B2',
        light:'#051324',
      },
      secondary: {
        main: '#ff002a',
      },
      error: {
        main: '#FF3434',
      },
      success:
      {
        main:'#01E65C',
      },
      background: {
        default: '#FAFEFF',
        paper: '#ffffff',
      },
      warning:
      {
        main:'#FFD102',
      },
      text: {
        primary: 'rgba(5, 19, 36, 0.9)',
        secondary: 'rgba(5, 19, 36, 0.6)',
        disabled: 'rgba(5, 19, 36, 0.4)',
      },
    },

    typography: {
      fontFamily: 'Open Sans',
      fontSize: 13,
      htmlFontSize: 16,
      h1: {
        fontSize: '2.125rem',
        fontWeight: 700,
      },
      h2: {
        fontSize: '1.875rem',
        fontWeight: 700,
      },
      h3: {
        fontSize: '1.625rem',
        fontWeight: 600,
      },
      h4: {
        fontSize: '1.375rem',
        fontWeight: 600,
      },
      h5: {
        fontSize: '1.125rem',
        fontWeight: 600,
      },
      h6: {
        fontSize: '1rem',
        fontWeight: 600,
      },
      caption: {fontSize:12, textTransform:'uppercase', fontWeight:500, letterSpacing: '1 px'},

      overline:{fontSize:12}
      
    },
  },
);

export default theme;