// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// import { PostVideoDto } from "dto/video/video.dto";
// import ResultListDTO from "dto/app/resultlist.dto";
// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";

// import { Status } from "tools/types/status";
// import GeneralService from "services/general.service";
// import { VideoRepository } from "repositories/system/video.repository";

// export default class VideoService extends GeneralService implements IService {
//   videoRepository = new VideoRepository();
//   constructor() {
//     super();
//     this.handleGetList = this.handleGetList.bind(this);
//     this.handleGet = this.handleGet.bind(this);
//   }
//   handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;

//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async update(
//     id: string,
//     cb?: any,
//     cbparameters?: any,
//     data?: any
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.videoRepository.update(id, this.handleUpdate, data, cbparameters);
//   }

//   handleDelete(result?: any, cbparameters?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters);
//     }
//   }

//   async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.videoRepository.delete(id, this.handleDelete, cbparameters);
//   }

//   handleGet(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;
//     this.populateObject(rez.obj, result.obj, [
//       { key: "status", type: "status" },
//       { key: "isdefault", type: "booleans" },
//     ]);
//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   // async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//   //   cbparameters = cbparameters ? cbparameters : {};
//   //   cbparameters._cb = cb;
//   //   const t = await this.videoRepository.get(id, this.handleGet, cbparameters);
//   // if (cb == undefined)
//   // {
//   //     return this.handleGet(t);
//   // }
//   // }

//   async get(
//     id: string,
//     cb?: any,
//     cbparameters?: any,
//     idLanguage?: string
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.videoRepository.get(
//       id,
//       this.handleGet,
//       cbparameters,
//       idLanguage
//     );
//     if (cb == undefined) {
//       return this.handleGet(t);
//     }
//   }

//   handleGetList(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultListDTO();

//     rez = result;
//     if (result.err) return;

//     this.parseResultPopulate(result, rez, [
//       { key: "status", type: "status" },
//       { key: "isdefault", type: "booleans" },
//     ]);

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async getList(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.videoRepository.getList(
//       this.handleGetList,
//       cbparameters,
//       data
//     );
//     if (cb == undefined) {
//       return this.handleGetList(t);
//     }
//   }

//   handleAdd(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async add(cb?: any, cbparameters?: any, data?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.videoRepository.add(this.handleAdd, cbparameters, data);
//   }

//   async addForCourse(cb?: any, cbparameters?: any, data?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.videoRepository.addForCourse(this.handleAdd, cbparameters, data);
//   }

//   // getDefaultRequestList(onPage?: number): RequestListDTO {
//   //   onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
//   //   if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
//   //   var defaultRequestList = new RequestListDTO();
//   //   defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
//   //   defaultRequestList.onpage = onPage;
//   //   var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
//   //   defaultRequestSortCriteria.asc = true;
//   //   defaultRequestSortCriteria.field = 'name';
//   //   defaultRequestList.sortcriteria = []
//   //   defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
//   //   return defaultRequestList;
//   // }

//   getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
//     if (
//       !obj.sortcriteria ||
//       !Array.isArray(obj.sortcriteria) ||
//       !obj.sortcriteria.length
//     ) {
//       var sobj = new RequestSortCriteriaDTO();
//       sobj.asc = true;
//       sobj.field = "name";

//       obj.sortcriteria = [];
//       obj.sortcriteria?.push(sobj);
//     }

//     return obj;
//   }
// }

import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import { VideoRepository } from "repositories/system/video.repository";
import { PostVideoDto } from "dto/system/video.dto";

export class VideoService
  extends GeneralService<VideoRepository>
  implements IService
{
  constructor() {
    super(
      new VideoRepository(),
      [
        { key: "status", type: "status" },
        { key: "isdefault", type: "booleans" },
      ],
      "name"
    );
  }
  prepareObjToUpdate(obj: any): PostVideoDto {
    const rez = new PostVideoDto();
    rez.idfile = null;
    rez.idlanguage = obj.idlanguage;
    rez.ordercriteria = obj.ordercriteria;
    rez.name = obj.name;
    rez.status = obj.status;
    rez.parent = obj.parent;
    rez.isdefault = obj.isdefault;
    rez.idfile = obj.idfile;
    rez.videolocation = obj.videolocation;
    rez.videoserver = obj.videoserver;
    return rez;
  }

  addForCourse = async (
    cb?: any,
    cbparameters?: any,
    data?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.addForCourse(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };
}
