import React, {  useEffect,  useState } from "react";
import { useResource } from "hooks/useResource";



import { AdvertisementDto } from "dto/static/advertisement.dto";

import { ComponentCommonTools } from "tools/utils/componentcommontools";

import { Box } from "@mui/material";

import { MyNavBt } from "components/elements/button/MyNavBt";
import { CommonTools } from "tools/utils/commontools";

import Typography from "@mui/material/Typography";



type BannerItemSpecialHomeSection1Props = {
  obj: AdvertisementDto;
};
const AdvertisementBannerItemBecomeTeacherSection5: React.FC<
  BannerItemSpecialHomeSection1Props
> = ({ obj, ...props }) => {
  const [imgUrl, setImgUrl] = useState<string>("");
  const [fullUrl, setFullUrl] = useState<string>("");
  const width = 200;
  const height = 200;

  const { LL } = useResource();

  const prepareGallery = () => {
    if (!obj) return;

    if (!obj.hasOwnProperty("gallery")) return;
    if (!obj.gallery) return;
    if (obj.gallery.length === 0) return;
    const galleryObj = CommonTools.getGalleryObjectLogo(obj.gallery);
    if (!galleryObj) return;
    CommonTools.setFileUrlsGallery(
      galleryObj,
      setImgUrl,
      setFullUrl,
      width,
      height
    );

    // logger('galleryObj', galleryObj,imgUrl);
  };

  useEffect(() => {
    prepareGallery();
  }, [obj]);

  const processImage = () => {
    if (!imgUrl) return <></>;
    return <>{ComponentCommonTools.getFileBlock(imgUrl, width, height)}</>;
  };

  const processItem = () => {
    if (!obj) return <></>;
    // if (!obj.fullurl) return <></>;
    const label = obj.clabt ? LL(obj.clabt) : "";

    // logger('AdvertisementBannerItemBecomeTeacherSection5', obj, imgUrl,fullUrl);
    if (!imgUrl) {
      return (
        <Box>
          <Box>
            <Typography variant="h1">
              {ComponentCommonTools.processObjectField(obj, "name")}
            </Typography>
            <Box>
              {ComponentCommonTools.processObjectField(obj, "description")}
            </Box>
          </Box>
          <MyNavBt href={obj.fullurl}>{label}</MyNavBt>
        </Box>
      );
    }

    return (
      <Box
        className="sectionFive"
        py={10}
        sx={{ backgroundImage: `url(${fullUrl})` }}
      >
        <Box className="container" textAlign="center">
          <Typography className="name" variant="h2">
            {ComponentCommonTools.processObjectField(obj, "name")}
          </Typography>
          <Typography className="description" my={4} mb={5}>
            {ComponentCommonTools.processObjectField(obj, "description")}
          </Typography>
          <MyNavBt href={obj.fullurl} className="btn">
            {label}
          </MyNavBt>
        </Box>
      </Box>
    );
  };

  return processItem();
};

export { AdvertisementBannerItemBecomeTeacherSection5 };
