import { useContext } from "react";
import { SocketContext } from "../providers/SocketProvider";

const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return context.socket; // Ensure socket is returned from the context
};

export { useSocket};
