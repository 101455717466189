import React from "react";

import { TeacherFAQDto } from "dto/user/teacherfaq.dto";
import Box from "@mui/material/Box";

import { ComponentCommonTools } from "tools/utils/componentcommontools";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import { Link } from "@mui/material";

import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";
import { TeacherTypeIcon } from "components/user/teacher/TeacherTypeIcon";
import { CommonTools } from "tools/utils/commontools";

type Props = {
  obj: TeacherFAQDto;
};

const StudentInterfaceFAQCard: React.FC<Props> = ({ obj }) => {
  const processTeacher = () => {
    if (!obj) return null;
    if (!obj.teacher) return null;
    if (!obj.teacher_fullname) return null;

    return (
      <Typography variant="h6" display="flex" alignItems="center" component={"div"}>
        <Box mr={1}>{processTeacherStatus()}</Box>
        {CommonTools.processObjectField(obj, ["teacher_fullname"])}
      </Typography>
    );
  };

  const processTeacherAvatar = () => {
    if (!obj) return null;
    if (!obj.teacher) return null;
    let name = "";
    let surname = "";
    if (obj.teacher.hasOwnProperty("name") && obj.teacher.name)
      name = obj.teacher.name;
    if (obj.teacher.hasOwnProperty("surname") && obj.teacher.name)
      surname = obj.teacher.surname;

    return (
      <MyAvatar
        obj={obj.teacher}
        name={name}
        surname={surname}
        sx={{ mr: 1 }}
      />
    );
  };

  const processTeacherStatus = () => {
    if (!obj) return null;
    if (!obj.teacher) return null;
    return <TeacherTypeIcon obj={obj.teacher} />;
  };

  const processResponse = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("teacherresponse")) return null;
    if (!obj.teacherresponse) return null;
    return (
      <Box className="teacher-response" px={2} mx={2}>
        <Box display="flex" alignItems="center">
          <Box mr={1}>{processTeacherAvatar()}</Box>
          <Box>
            <Typography component={"div"}>{processTeacher()}</Typography>
          </Box>
        </Box>
        <Typography className="message-teacher">
          {CommonTools.processObjectField(obj, ["teacherresponse"])}
        </Typography>
      </Box>
    );
  };

  const processTeacherCard = () => {
    if (!obj) return null;
    return <Box>{processResponse()}</Box>;
  };

  const processCourseImage = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("course")) return null;
    if (!obj.course) return null;
    if (!obj.course.hasOwnProperty("gallery")) return null;
    if (!obj.course.gallery) return null;
    if (!obj.course.gallery.length) return null;
    if (!obj.course.gallery[0]) return null;
    if (!obj.course.gallery[0].hasOwnProperty("fileInfoObj")) return null;
    if (!obj.course.gallery[0].fileInfoObj) return null;
    return ComponentCommonTools.getFileBlockImage(
      obj.course.gallery[0].fileInfoObj,
      50,
      50,
      "",
      false,
      { borderRadius: 50 }
    );
  };
  const processCourseName = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("course")) return null;
    if (!obj.course) return null;
    let fullUrl = "";
    if (obj.course.hasOwnProperty("fullurl") && obj.course.fullurl)
      fullUrl = obj.course.fullurl;
    if (!fullUrl) {
      return CommonTools.processObjectField(obj, ["course", "name"]);
    } else {
      return (
        <Link href={fullUrl} target="_blank" rel="noopener noreferrer">
          {CommonTools.processObjectField(obj, ["course", "name"])}
        </Link>
      );
    }
  };
  const processUserCard = () => {
    if (!obj) return null;
    return (
      <Box className="user-message" p={2}>
        <Typography className="data">
          {CommonTools.processObjectField(obj, ["date_name"])}
        </Typography>
        <Typography className="message">
          {CommonTools.processObjectField(obj, ["message"])}
        </Typography>
      </Box>
    );
  };
  const processCourseInfo = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("course")) return null;
    if (!obj.course) return null;
    return (
      <Box display="flex" p={2} sx={{ alignItems: "center" }}>
        {processCourseImage()}
        <Typography variant="h6" ml={2} className="course-name" component={"div"}>
          {processCourseName()}
        </Typography>
      </Box>
    );
  };
  const processItem = () => {
    if (!obj) return null;

    return (
      <Box className="box-faq-message" my={2} pb={2}>
        {processCourseInfo()}
        <Divider />
        {processUserCard()}
        {processTeacherCard()}
      </Box>
    );
  };

  return processItem();
};

export { StudentInterfaceFAQCard };
