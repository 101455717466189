import Idto from 'interfaces/idto.interface';
import { Status } from 'tools//types/status';

export class TeacherRequestDto implements Idto {


    id?: string;
    idteacher?: string;
    idmanager?: string;
    date?: number;
    status?: number;
    changedate?: number;
    type?: number;
    managermessage?: string;
    teachermessage?: string;
    
    manager?: any;
    teacher?: any;

    constructor() {
        this.status = Status.ACTIVE;   
    }
}

export class PostTeacherRequestDto implements Idto {
    idteacher?: string;
    teachermessage?: string;

    constructor(
        idteacher?: string,
        teachermessage?: string
    ) {
        this.idteacher = idteacher || "";
        this.teachermessage = teachermessage || "";
    }
    
}



export class TeacherRequestProcessedProDto implements Idto {

    processed?: boolean;

}