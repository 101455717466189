import { useContext } from "react";

import { UserContext } from "providers/UserProvider";
import { Status } from "tools/types/status";

export function useAuth() {
  const state = useContext(UserContext);
  const { user } = state;
  const needConfirmEmail =
    user && user.statusemail === Status.INACTIVE ? true : false;
  return { ...state, needConfirmEmail };
}
