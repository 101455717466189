import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

const SkeletonPagination: React.FC = () => {
  const gridItem = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        gap: 2,
        mb: 5,
      }}>
      <Skeleton variant="rounded" sx={{ width: "12px", height: "14px" }} />
      <Skeleton variant="rounded" sx={{ width: "12px", height: "14px" }} />
      <Skeleton variant="circular" sx={{ width: "32px", height: "32px" }} />
      <Skeleton variant="rounded" sx={{ width: "12px", height: "14px" }} />
      <Skeleton variant="rounded" sx={{ width: "12px", height: "14px" }} />
    </Box>
  );
  return (
    <Box sx={{ width: "100%" }} className="">
      <Box
        sx={{
          width: "100%",
          display: { xs: "flex" },
          justifyContent: "center",
          alignContent: "center",
          margin: "auto",
          flexDirection: "column",
        }}>
        {Array(1)
          .fill(null)
          .map((_, index) => (
            <React.Fragment key={index}>{gridItem}</React.Fragment>
          ))}
      </Box>
    </Box>
  );
};

export { SkeletonPagination };
