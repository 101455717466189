import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";

export class TestDto implements Idto {
    id?: string;
  
    mintestscore?: number;
    maxtestscore?: number;
    name?: string;
    type?: number;
    status?: number;
    idcourse?: string;
    idlesson?: string | null;
  
    constructor(
      idcourse?: string,
      idlesson?: string | null,
      name?: string,
      mintestscore?: number,
      maxtestscore?: number,
      type?: number,
      status?: number
    ) {
      this.idcourse = idcourse || "";
      this.idlesson = idlesson || null;
      this.name = name || "";
      this.mintestscore = mintestscore || 0;
      this.maxtestscore = maxtestscore || 0;
      this.type = type || Types.TYPE_TEST_BEFORE_COURSE;
      this.status = status || Status.ACTIVE;
    }

    static getDetailFields(): Array<DetailObject> {
        return [
          {
            typeField: Types.FIELD_TYPE_TEXT,
            label: "Name",
            value: ["name"],
          },
    
          {
            typeField: Types.FIELD_TYPE_TEXT,
            label: "Status",
            value: ["status_name"],
          },
          {
            typeField: Types.FIELD_TYPE_TEXT,
            label: "type",
            value: ["type_name"],
          },
          {
            typeField: Types.FIELD_TYPE_TEXT,
            label: "mintestscore",
            value: ["mintestscore"],
          },
          {
            typeField: Types.FIELD_TYPE_TEXT,
            label: "maxtestscore",
            value: ["maxtestscore"],
          },
        ];
      }
}


export class AnswerTestDto implements Idto {
    id?: string;
    name?: string;
    status?: number;
    type?: number;
    ordercriteria?: number;
    score?: number;
    idquestion?: string;
  
    constructor(
      name?: string,
      status?: number,
      type?: number,
      ordercriteria?: number,
      score?: number,
      idquestion?: string
    ) {
      this.name = name || "";
      this.status = status || Status.ACTIVE;
      this.type = type || Types.FALSE_ANSWER;
      this.ordercriteria = ordercriteria || 0;
      this.score = score || 0;
      this.idquestion = idquestion || "";
    }
  }
  
  export class QuestionTestDto implements Idto {
    id?: string;
    name?: string;
    status?: number;
    ordercriteria?: number;
    type?: number;
    idtest?: string;
    totalscore?: number;
  
    constructor(
      idtest?: string,
      name?: string,
      status?: number,
      ordercriteria?: number,
      type?: number,
      totalscore?: number
    ) {
      this.name = name || "";
      this.status = status || Status.ACTIVE;
      this.ordercriteria = ordercriteria || 0;
      this.type = type || Types.TYPE_QUESTION_SINGLE_CHOICE;
      this.idtest = idtest || "";
      this.totalscore = totalscore || 0;
    }
  }
  
  export class QuestionWithAnswerTestDto implements Idto {
    question: QuestionTestDto;
    answers: AnswerTestDto[];
    deleteanswerids: string[];
  
    constructor(
      question?: QuestionTestDto,
      answers?: AnswerTestDto[],
      deleteanswerids?: string[]
    ) {
      this.question = question || new QuestionTestDto();
      this.answers = answers || [];
      this.deleteanswerids = deleteanswerids || [];
    }
  }
  
  export class QuestionWithAnswerListTestDto implements Idto {
    question: QuestionTestDto;
    answers: AnswerTestDto[];
    test?: TestDto;
  
    constructor(question?: QuestionTestDto, answers?: AnswerTestDto[]) {
      this.question = question || new QuestionTestDto();
      this.answers = answers || [];
    }
  }