import React from "react";
import { useResource } from "hooks/useResource"
import { ComponentCommonTools } from "tools/utils/componentcommontools";

const LogoComp = () => {
  const { CF } = useResource();
  return <div className="logo">
    {ComponentCommonTools.getFileBlock(CF("logo"), 145, 60)}
  </div>;
};

export { LogoComp };
