import Idto from "interfaces/idto.interface";
import { Status } from "tools//types/status";

export class TeacherFAQDto implements Idto {
  id?: string;
  iduser?: string;
  idteacher?: string;
  idcourse?: string;
  date?: number;
  teacherresponsedate?: number;
  status?: number;
  message?: string;
  teacherresponse?: string;
  showoncourse?: boolean;

  date_name?: string;
  status_name?: string;
  user?: any;
  teacher?: any;
  course?: any;

  user_email?: any;
  usersettings?: any;
  user_name?: any;
  user_surname?: any;
  teacher_email?: any;
  teacher_fullname?: string;
  user_fullurl?: string;
  constructor() {
    this.status = Status.ACTIVE;
    this.showoncourse = false;
  }
}

export class PostTeacherFAQDto implements Idto {
  iduser?: string;
  idteacher?: string;
  idcourse?: string;
  date?: number;

  status?: number;
  message?: string;
  teacherresponse?: string;
  showoncourse?: boolean;

  date_name?: string;
  status_name?: string;
  user?: any;
  teacher?: any;
  course?: any;

  user_email?: any;
  usersettings?: any;
  user_name?: any;
  user_surname?: any;
  teacher_email?: any;
  teacher_fullname?: string;
  user_fullurl?: string;

  constructor() {
    this.status = Status.ACTIVE;
    this.showoncourse = false;
  }
}

export class PostTeacherFAQFormDto implements Idto {
  iduser?: string;
  idteacher?: string;
  idcourse?: string;
  message?: string;

  constructor(
    iduser?: string,
    idteacher?: string,
    idcourse?: string,
    message?: string
  ) {
    this.iduser = iduser || "";
    this.idteacher = idteacher || "";
    this.idcourse = idcourse || "";
    this.message = message || "";
  }
}

export class TeacherFAQResponseDto implements Idto {
  teacherresponse?: string;
  showoncourse?: boolean;

  constructor(teacherresponse?: string, showoncourse?: boolean) {
    this.teacherresponse = teacherresponse || "";
    this.showoncourse = showoncourse || false;
  }
}
