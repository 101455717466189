import React from "react";

import { useResource } from "hooks/useResource";

import { IconButton, Dialog, DialogContent, DialogTitle } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { LessonDto } from "dto/course/lesson.dto";
import { HtmlTag } from "components/form/HtmlTag";

type Props = {
  open: boolean;
  setOpen: any;
  obj: LessonDto;
};
const StudentLessonDialogDescription: React.FC<Props> = ({
  open,
  setOpen,
  obj,
}) => {
  const { LL } = useResource();

  const handleClose = () => {
    setOpen(false);
  };

  const processDescription = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("description")) return null;
    if (!obj.description) return null;
    return <HtmlTag content={obj.description} />;
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{LL("Description")}</DialogTitle>
      <IconButton
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>{processDescription()}</DialogContent>
    </Dialog>
  );
};

export { StudentLessonDialogDescription };
