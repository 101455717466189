import React from "react";

import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { AdvertisementDto } from "dto/static/advertisement.dto";

import { Types } from "tools/types/types";

import { Box, Skeleton } from "@mui/material";

import { AdvertisementSearchItem } from "./AdvertisementSearchItem";
import { useAdvertisementList } from "hooks/useAdvertisementList";

const AdvertisementSearch: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  // const { saveCache, getCache } = useResource();
  // const { LL } = useResource();
  // const [isLoading, setIsLoading] = useState(true);
  // const [objects, setObjects] = useState<Array<AdvertisementDto>>([]);

  // const getList = async () => {
  //   const reqList = new RequestListDTO();
  //   reqList.onpage = 1;
  //   reqList.page = 1;
  //   reqList.filters = [];

  //   let f: RequestFilterDTO;

  //   f = new RequestFilterDTO();
  //   f.field = "status";
  //   f.values = [];
  //   f.values.push(Status.ACTIVE.toString());
  //   reqList.filters.push(f);

  //   f = new RequestFilterDTO();
  //   f.field = "type";
  //   f.values = [];
  //   f.values.push(Types.ADVERTISEMENT_TYPE_SEARCH.toString());
  //   reqList.filters.push(f);

  //   f = new RequestFilterDTO();
  //   f.field = "section";
  //   f.values = [];
  //   f.values.push(Types.SECTION_TOP_ADVERTISEMENT.toString());
  //   reqList.filters.push(f);

  //   const cd = getCache(JSON.stringify(reqList));

  //   if (cd !== false) {
  //     setObjects(cd);
  //     setIsLoading(false);
  //     return;
  //   }

  //   setIsLoading(true);

  //   const data = await advertisementService.getList(undefined, {}, reqList);

  //   const rez = !data || !data.objects ? [] : data.objects;

  //   setObjects(rez);
  //   setIsLoading(false);

  //   saveCache(rez, JSON.stringify(reqList));
  // };

  // useEffect(() => {
  //   getList();
  // }, []);

  const { objects, loading } = useAdvertisementList(
    Types.SECTION_TOP_ADVERTISEMENT,
    Types.ADVERTISEMENT_TYPE_SEARCH,
    true,
    "advertisement_home_banner_top_search"
  );
  const processAdvertisementItem = (obj: AdvertisementDto, index: number) => {
    if (!obj) return null;

    return (
      <Box key={index}>
        <AdvertisementSearchItem obj={obj} />
      </Box>
    );
  };

  const processAdvertisementList = () => {
    if (loading) return <Skeleton variant="rectangular" height={150} />;
    if (!objects) return null;
    if (!objects.length) return null;

    return (
      <React.Fragment>
        {objects.map((item: AdvertisementDto, i: number) => {
          return processAdvertisementItem(item, i);
        })}
      </React.Fragment>
    );
  };

  return processAdvertisementList();
};

export { AdvertisementSearch };
