import UserDto from "dto/user/user.dto";
import Idto from "interfaces/idto.interface";

export class StudentDto implements Idto {
  id?: string;
  iduser?: string;
  status?: number;
  idssociallink?: string[];
  bio?: string;
  idtypestudies?: string;
  institution?: string;
  notificationtype1?: boolean;
  notificationtype2?: boolean;
  notificationtype3?: boolean;
  showprofile?: boolean;
  showcourses?: boolean;
  email?: string;
  status_name?: string;
  name?: string;
  surname?: string;
  typestudies?: any;
  numberofcourses?: number;
  user?: UserDto;
  fullname?: string;
  fullurl?: string;
}

export class PostStudentDto implements Idto {
  iduser?: string;
  status?: number;
  idssociallink?: string[];
  bio?: string;
  idtypestudies?: string;
  institution?: string;
  notificationtype1?: boolean;
  notificationtype2?: boolean;
  notificationtype3?: boolean;
  showprofile?: boolean;
  showcourses?: boolean;
  numberofcourses?: number;
}

export class StudentNotificationDto implements Idto {
  iduser?: string;
  notificationtype1?: boolean;
  notificationtype2?: boolean;
  notificationtype3?: boolean;

  constructor(
    iduser?: string,
    notificationtype1?: boolean,
    notificationtype2?: boolean,
    notificationtype3?: boolean
  ) {
    this.iduser = iduser || "";
    this.notificationtype1 = notificationtype1 || false;
    this.notificationtype2 = notificationtype2 || false;
    this.notificationtype3 = notificationtype3 || false;
  }

  static fromStudentDto(
    idUser?: string,
    obj?: StudentDto
  ): StudentNotificationDto {
    if (!obj) return new StudentNotificationDto(idUser);
    return new StudentNotificationDto(
      obj.iduser ?? idUser,
      obj.notificationtype1 ?? false,
      obj.notificationtype2 ?? false,
      obj.notificationtype3 ?? false
    );
  }
}
