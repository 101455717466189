import React, { useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { Box, Typography, Grid } from "@mui/material";
import MyTextField from "components/form/mytextfield";
import { MyButton } from "../elements/button/MyButton";
import { useResource } from "hooks/useResource";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { NewsletterService } from "services/user/newsletter.service";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { NewsletterDto } from "dto/user/newsletter.dto";
import { useMessage } from "hooks/useMessage";
import MessageDto from "dto/app/message.dto";
import { MessageTypes } from "tools/types/messagetypes";

const service = new NewsletterService();
const NewsletterBlock: React.FC<PageComponentProps> = () => {
  const { LL } = useResource();
  const { RegisterMessage } = useMessage();
  const [value, setValue] = useState("");

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (!value) return;
    if (!value.includes("@")) return;
    service.add(handleResult, {}, new NewsletterDto(value));
  };

  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    RegisterMessage(
      MessageDto.prepareMessage(
        "200",
        LL("Newsletter_Subscription_Successful"),
        MessageTypes.MESSAGE_SUCCESS
      )
    );
    setValue("");
  };
  return (
    <Box className="newsletterBlock">
      <Grid container className="container" sx={{ justifyContent: "center" }}>
        <Grid item sm={12} md={5} mx={0}>
          <Typography className="title">
            {LL("Subscribe_to_Newsletter")}
          </Typography>

          <Typography my={3} className="description">
            {LL("Subscribe_to_Newsletter_Detail_Information")}
          </Typography>

          <form onSubmit={handleSubmit}>
            <Box className="form">
              <MailOutlineIcon />
              <MyTextField
                _containerClass="input"
                fullWidth
                variant="outlined"
                id="search"
                name="email"
                className="input"
                sx={{
                  width: "100%",
                  "& label.MuiFormLabel-root": {
                    color: "#fff",
                  },
                  "& label.Mui-focused": {
                    display: "none",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                      borderColor: "none",
                      borderWidth: "0px",
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": {
                      border: "none",
                    },
                  },
                }}
                _label={LL("Enter_the_personal_email")}
                value={value}
                setObj={(field: any, value: any) => {
                  setValue(value);
                }}
                _vresults={undefined}
              />
              <MyButton
                cb={handleSubmit}
                type={"submit"}
                variant="contained"
                className="btn"
              >
                {LL("Bt_Subscribe_news")}
              </MyButton>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

export { NewsletterBlock };
