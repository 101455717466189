import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";
import { useResponsive } from "hooks/useResponsive";

interface SkeletonProps {
  lines?: number;
  width?: number;
}

const SkeletonSlider: React.FC = () => {
  const { matchesSM } = useResponsive();

  let gridItemsCount;
  if (matchesSM) {
    gridItemsCount = 2;
  } else {
    gridItemsCount = 2;
  }
  const skeletonHeight = matchesSM ? "130px" : "130px";

  const gridItem = (
    <Grid xs={gridItemsCount} sx={{ minWidth: "140px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          margin: "auto",
        }}>
        <Skeleton
          variant="text"
          sx={{
            fontSize: "1.5rem",
            mt: 4,
            width: "40%",
            margin: "auto",
            minWidth: "117px",
          }}
        />
      </Box>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", height: skeletonHeight }} className="">
      <Box
        sx={{
          width: "90%",
          padding: "40px 15px 40px 15px",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          margin: "auto",
        }}>
        <Grid
          container
          xs={12}
          sx={{
            maxWidth: "1522px",
            margin: "auto",
            overflow: "hidden",
            flexWrap: "nowrap",
          }}
          spacing={2}>
          {Array(6)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

const SkeletonCoursesCtg: React.FC<SkeletonProps> = ({ lines }) => {
  const { matchesCustom } = useResponsive();

  let gridItemsCount;
  if (matchesCustom) {
    gridItemsCount = 4;
  } else {
    gridItemsCount = 12;
  }
  // const skeletonHeight = matchesCustom ? "600px" : "1700px";

  const infoLine = (
    <Skeleton
      variant="text"
      sx={{
        fontSize: "1rem",
        width: "100%",
      }}
    />
  );

  const infoSkeleton = (
    <Grid xs={6} sx={{ width: "40%" }}>
      {infoLine}
      {infoLine}
      {infoLine}
    </Grid>
  );

  const gridItem = (
    <Grid xs={12} sx={{}}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignContent: "center",
          margin: "auto",
          flexDirection: "column",
          py: 1,
        }}>
        <Grid container xs={12} spacing={2}>
          <Grid xs={gridItemsCount}>
            <Skeleton
              sx={{
                width: { xs: "100%", md: "80%" },
                height: "180px",
                minWidth: { xs: "310px", sm: "initial" },
                margin: "auto",
              }}
              variant="rectangular"
            />
          </Grid>
          <Grid
            xs={gridItemsCount}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              flexDirection: "column",
            }}>
            <Skeleton
              variant="text"
              sx={{
                fontSize: "1.5rem",
                width: "70%",
                minWidth: "204px",
                mt: 2,
              }}
            />
            <Skeleton
              variant="text"
              sx={{
                fontSize: "1rem",
                width: "55%",
              }}
            />
            <Skeleton
              variant="text"
              sx={{
                fontSize: "1rem",
                width: "55%",
              }}
            />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "start",
                mt: 4,
              }}>
              <Skeleton
                variant="rectangular"
                sx={{
                  width: "40%",
                  height: "50px",
                  minWidth: "117px",
                  maxHeight: "150px",
                }}
              />
              <Box>
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "1.25rem",
                    width: "40%",
                    margin: "auto",
                    minWidth: "117px",
                  }}
                />
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "1rem",
                    width: "40%",
                    margin: "auto",
                    minWidth: "117px",
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid xs={gridItemsCount} sx={{ mt: 2 }}>
            <Grid container xs={12} spacing={2}>
              {infoSkeleton}
              {infoSkeleton}
              {infoSkeleton}
              {infoSkeleton}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="">
      <Box
        sx={{
          width: "98%",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}>
        <Grid
          container
          xs={12}
          sx={{ maxWidth: "1522px", margin: "auto" }}
          spacing={2}>
          {Array(lines)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export { SkeletonSlider, SkeletonCoursesCtg };
