import React, { useState, useEffect, useCallback } from "react";
import { useResource } from "hooks/useResource";

import RequestFilterDTO from "dto/app/requestfilter.dto";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
  Stack,
  Drawer,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { CommonTools } from "tools/utils/commontools";
import ClearIcon from "@mui/icons-material/Clear";

import FilterListIcon from "@mui/icons-material/FilterList";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FilterRating } from "./FilterRating";
import { StaticNomenclatureFilter } from "./StaticNomenclatureFilter";
import { TypeDisciplineService } from "services/nomenclature/typediscipline.service";
import { TypeProfessionalismLevelService } from "services/nomenclature/typeprofessionalismlevel.service";
import { TypeExperienceService } from "services/nomenclature/typeexperience.service";
import { TypeTargetGroupService } from "services/nomenclature/typetargetgroup.service";

const typeDisciplineService = new TypeDisciplineService();
const typeProfessionalismLevelService = new TypeProfessionalismLevelService();
const typeTargetGroupService = new TypeTargetGroupService();
const typeExperienceService = new TypeExperienceService();

type FilterBoxTeacherProps = {
  currentRoute: any;
  numberOfResults: number;
  setFilterRating: any;
  filtersRating?: RequestFilterDTO;

  filterDisciplineValue: Array<string>;
  setFilterDisciplineValue: any;

  filterTypeProfessionalismLevelValue: Array<string>;
  setFilterTypeProfessionalismLevelValue: any;
  filterTypeTargetGroupValue: Array<string>;
  setFilterTypeTargetGroupValue: any;
  filterTypeExperienceValue: Array<string>;
  setFilterTypeExperienceValue: any;

  window?: () => Window;
};

const drawerWidth = 240;

const FilterBoxTeacher: React.FC<FilterBoxTeacherProps> = ({
  currentRoute,
  filterDisciplineValue,
  setFilterDisciplineValue,
  numberOfResults,
  setFilterRating,
  filtersRating,
  filterTypeProfessionalismLevelValue,
  setFilterTypeProfessionalismLevelValue,
  filterTypeTargetGroupValue,
  setFilterTypeTargetGroupValue,
  filterTypeExperienceValue,
  setFilterTypeExperienceValue,
  window,
}) => {
  const { LL } = useResource();

  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("md"));

  const container =
    typeof window !== "undefined" && window
      ? window().document.body
      : undefined;

  const [expanded, setExpanded] = useState<boolean>(false);
  const [rendered, setRendered] = useState<boolean>(false);

  const handleAccordionChange = (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  useEffect(() => {
    if (expanded && !rendered) {
      setRendered(true);
    }
  }, [expanded, rendered]);

  const clearFilters = () => {
    if (setFilterRating) setFilterRating(CommonTools.addFilterRating(0));
    if (setFilterDisciplineValue) setFilterDisciplineValue([]);
    if (setFilterTypeProfessionalismLevelValue)
      setFilterTypeProfessionalismLevelValue([]);
    if (setFilterTypeTargetGroupValue) setFilterTypeTargetGroupValue([]);
    if (setFilterTypeExperienceValue) setFilterTypeExperienceValue([]);
  };

  const countActiveFilters = (...filterArrays: any) => {
    let count = 0;
    if (!filterArrays) return count;
    filterArrays.forEach((filterArray: any) => {
      if (filterArray && filterArray.length > 0) {
        count++;
      }
    });

    return count;
  };

  const processFilterRatingIsSet = useCallback(() => {
    if (!filtersRating) return 0;
    if (!filtersRating.values) return 0;
    if (filtersRating.values.length === 0) return 0;
    if (!filtersRating.values[0]) return 0;
    if (parseFloat(filtersRating.values[0]) === 0) return 0;
    return 1;
  }, [filtersRating]);

  const processCountTotal = useCallback(() => {
    const count =
      countActiveFilters(
        filterDisciplineValue,
        filterTypeProfessionalismLevelValue,
        filterTypeTargetGroupValue,
        filterTypeExperienceValue
      ) + processFilterRatingIsSet();
    return count;
  }, [
    filterDisciplineValue,
    filterTypeProfessionalismLevelValue,
    filterTypeTargetGroupValue,
    filterTypeExperienceValue,
    processFilterRatingIsSet,
  ]);

  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(processCountTotal);
  }, [processCountTotal]);

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    if (!isSmUp) {
      setMobileOpen(!mobileOpen);
    }
  };

  const processFilterToolBox = () => {
    if (!filtersRating)
      return (
        <Box onClick={handleDrawerToggle}>
          <Typography component="div" className="btn">
            <FilterListIcon sx={{ mr: 1 }} />
            {LL("Filter")}
          </Typography>
        </Box>
      );
    const counter = count;
    if (!counter)
      return (
        <Box onClick={handleDrawerToggle}>
          <Typography component="div" className="btn">
            <FilterListIcon sx={{ mr: 1 }} />
            {LL("Filter")}
          </Typography>
        </Box>
      );
    return (
      <Stack direction="row" spacing={1}>
        <Box onClick={handleDrawerToggle}>
          <Typography component="div" className="btn">
            <FilterListIcon sx={{ mr: 1 }} />
            {LL("Filter")} ({counter})
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={clearFilters}>
            <ClearIcon />
          </IconButton>
          {LL("Clear Filters")}
        </Box>
      </Stack>
    );
  };

  const processNumberOfResults = () => {
    if (numberOfResults === -1) return null;
    return (
      <Box>
        <Typography>
          {numberOfResults} {LL("Results")}
        </Typography>
      </Box>
    );
  };

  const processFilterToolBoxDrawer = () => {
    if (!filtersRating) return null;
    const counter = count;
    if (!counter) return null;
    return (
      <Box>
        {processNumberOfResults()}
        <IconButton onClick={clearFilters}>
          <ClearIcon />
        </IconButton>
        {LL("Clear Filters")}
      </Box>
    );
  };

  const processFilterRating = () => {
    return (
      <Box>
        <Accordion className="accordion" onChange={handleAccordionChange}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="summary">
            <Typography variant="h5">{LL("Teacher_Rating")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FilterRating
              setFilters={setFilterRating}
              filters={filtersRating}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  };

  const processFilterDiscipline = () => {
    if (!filterDisciplineValue) return null;

    return (
      <StaticNomenclatureFilter
        filterName="typediscipline"
        filterValue={filterDisciplineValue}
        setFilterValue={setFilterDisciplineValue}
        service={typeDisciplineService}
      />
    );
  };

  const processFilterTypeProfessionalismLevel = () => {
    if (!filterTypeProfessionalismLevelValue) return null;

    return (
      <StaticNomenclatureFilter
        filterName="typeprofessionalismlevel"
        filterValue={filterTypeProfessionalismLevelValue}
        setFilterValue={setFilterTypeProfessionalismLevelValue}
        service={typeProfessionalismLevelService}
      />
    );
  };

  const processFilterTypeTargetGroup = () => {
    if (!filterTypeTargetGroupValue) return null;

    return (
      <StaticNomenclatureFilter
        filterName="typetargetgroup"
        filterValue={filterTypeTargetGroupValue}
        setFilterValue={setFilterTypeTargetGroupValue}
        service={typeTargetGroupService}
      />
    );
  };

  const processFilterTypeExperience = () => {
    if (!filterTypeExperienceValue) return null;

    return (
      <StaticNomenclatureFilter
        filterName="typeexperience"
        filterValue={filterTypeExperienceValue}
        setFilterValue={setFilterTypeExperienceValue}
        service={typeExperienceService}
      />
    );
  };

  const processFilterList = () => {
    return (
      <React.Fragment>
        {processFilterRating()}
        {processFilterDiscipline()}
        {processFilterTypeProfessionalismLevel()}
        {processFilterTypeTargetGroup()}
        {processFilterTypeExperience()}
      </React.Fragment>
    );
  };

  const processFilterListContent = () => (
    <Box mt={4}>{processFilterList()}</Box>
  );

  return (
    <Box className="filterLeft">
      {processFilterToolBox()}
      <Drawer
        container={container}
        variant="temporary"
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "none",
            lg: "none",
            xl: "none",
          },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
        open={mobileOpen}
        anchor="right"
        onClose={handleDrawerToggle}>
        {processFilterToolBoxDrawer()}
        {processFilterListContent()}
      </Drawer>
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
            xl: "block",
          },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}>
        {processFilterListContent()}
      </Box>
    </Box>
  );
};

export { FilterBoxTeacher };
