import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

import { Box } from "@mui/material";
import { TicketListMenu } from "./TicketListMenu";
import { useAuth } from "hooks/useAuth";
import { Types } from "tools/types/types";


const SupportBlockTeacher: React.FC<PageComponentProps> = () => {

  const { teacher } = useAuth();

  const processComponent = () => {
    if (!teacher) return null
    

    return (
      <Box sx={{ width: "auto", px: 6 }}>
        <TicketListMenu
          userObject={teacher}
          userType={Types.TYPE_TICKET_TEACHER}
          userObjectField="idteacher"
          
        />
      </Box>
    );
  };
  return processComponent();
};

export { SupportBlockTeacher };
