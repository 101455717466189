import Idto from "interfaces/idto.interface";

export default class SignInDto implements Idto {
  identifier?: string;
  password?: string;

  constructor(identifier?: string, password?: string) {
    this.identifier = identifier || "";
    this.password = password || "";
  }
}
