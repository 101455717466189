import SelectOptions from 'dto/app/selectoptions.dto';
import Idto from 'interfaces/idto.interface';
import { CommonTools } from 'tools/utils/commontools';

export class FilterDictionaryDto implements Idto {

    id?: string;
    idlanguage?: string;
    status?: number;
    ordercriteria?: number;
    name?: string;
    idfilter?: string;
    idfilterdictionary?: string;

    static parseToSelectOptions(data: Array<FilterDictionaryDto>): Array<SelectOptions> {
        if (!data) return [];
        if (!Array.isArray(data)) return [];
        if (!data.length) return [];
        let result: Array<SelectOptions> = [];
        data.forEach((element) => {
          result.push(FilterDictionaryDto.parseOption(element));
        });
        return result;
      }
    
      static parseOption = (obj: FilterDictionaryDto): SelectOptions => {
        return new SelectOptions(
          CommonTools.processObjectField(obj, ["id"]),
          CommonTools.processObjectField(obj, ["name"])
        );
      };
    
}

export class PostFilterDictionaryDto implements Idto {

    idlanguage?: string;
    status?: number;
    ordercriteria?: number;
    name?: string;
    idfilter?: string;
    idfilterdictionary?: string;
}