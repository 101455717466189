import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import { MenuItem } from "dto/app/teacherdrawer.dto";

export const StudentDrawerMenuItems: MenuItem[] = [
  {
    name: "Amg_Courses",
    icon: <OndemandVideoIcon />,
    isSelected: false,
    options: [
      {
        name: "MyCourses",
        icon: <></>,
        href: "/profile/course",
        currentItemMenu: "course",
      },
    ],
  },
  {
    name: "Amg_Communications",
    icon: <ChatOutlinedIcon />,
    isSelected: false,
    options: [
      {
        name: "FAQ",
        icon: <></>,
        href: "/profile/faq",
        currentItemMenu: "teacherfaq",
      },
      //   { name: 'Messages', icon: <MailIcon />, href: '/', currentItemMenu: 'messages' },
      {
        name: "Testimonials",
        icon: <></>,
        href: "/profile/testimonial",
        currentItemMenu: "testimonial",
      },
      {
        name: "Ticket",
        icon: <></>,
        href: "/profile/ticket",
        currentItemMenu: "ticket",
      },
    ],
  },
  {
    name: "Amg_Profile_and_Settings",
    icon: <SettingsOutlinedIcon />,
    isSelected: false,
    options: [
      // {
      //     name: 'Personal_cabinet',
      //     icon: <></>,
      //     href: '/profile',
      //     currentItemMenu: 'profile',
      // },
      {
        name: "EditProfile",
        icon: <></>,
        href: "/profile/edit",
        currentItemMenu: "editprofile",
      },
      {
        name: "Notifications",
        icon: <></>,
        href: "/profile/notification",
        currentItemMenu: "notification",
      },
    ],
  },
];
