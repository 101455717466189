import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";
import { useResponsive } from "hooks/useResponsive";

const SkeletonCards: React.FC = () => {
  const { matchesSM } = useResponsive();

  let gridItemsCount;
  if (matchesSM) {
    gridItemsCount = 4;
  } else {
    gridItemsCount = 12;
  }
  const skeletonHeight = matchesSM ? "307px" : "660px";

  const gridItem = (
    <Grid xs={gridItemsCount}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          margin: "auto",
        }}>
        <Skeleton
          sx={{
            width: "80%",
            height: "180px",
            minWidth: { xs: "310px", sm: "initial" },
            margin: "auto",
          }}
          variant="rectangular"
        />
      </Box>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", height: skeletonHeight }} >
      <Box
        sx={{
          width: "90%",
          padding: "40px 15px 40px 15px",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          margin: "auto",
        }}>
        <Grid
          container
          xs={12}
          sx={{ maxWidth: "1522px", margin: "auto" }}
          spacing={2}>
          {Array(3)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

const SkeletonCardForth: React.FC = () => {
  const { matchesSM, matchesLG } = useResponsive();

  let gridItemsCount;
  if (matchesLG) {
    gridItemsCount = 3;
  } else if (matchesSM) {
    gridItemsCount = 6;
  } else {
    gridItemsCount = 12;
  }
  const skeletonHeight = matchesLG ? "1071px" : matchesSM ? "1760px" : "3400px";

  const gridItem = (
    <Grid xs={gridItemsCount}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          margin: "auto",
          flexDirection: "column",
          height: "458px",
        }}>
        <Skeleton
          sx={{
            width: "80%",
            height: "458px",
            minWidth: { xs: "310px", sm: "initial" },
            margin: "auto",
          }}
          variant="rectangular"
        />
        <Skeleton
          variant="text"
          sx={{
            fontSize: "1rem",
            mt: 4,
            width: "40%",
            margin: "auto",
            minWidth: "117px",
          }}
        />
      </Box>
    </Grid>
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: skeletonHeight,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      >
      <Box
        sx={{
          width: "98%",
          padding: "40px 0px 40px 0px",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}>
        <Grid
          container
          xs={12}
          sx={{ maxWidth: "1522px", margin: "auto" }}
          spacing={2}>
          {Array(8)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export { SkeletonCards, SkeletonCardForth };
