import React, { useState } from "react";
import { useResource } from "hooks/useResource";

import {
  Box,
  Typography,
  Stack,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";

import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { TestResultQuestionDto } from "dto/course/testresultquestion.dto";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TestResultAnswerList } from "./TestResultAnswerList";
import { CommonTools } from "tools/utils/commontools";

type Props = {
  obj: TestResultQuestionDto;
};

const TestResultItemQuestion: React.FC<Props> = ({ obj }) => {
  const { LL } = useResource();

  const [expend, setExpend] = useState(false);
  const handleExpend = (event: React.SyntheticEvent, expanded: boolean) => {
    setExpend(expanded);
  };

  const processQuestionInfo = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("question")) return null;
    if (!obj.question) return null;

    return (
      <Box>
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          {CommonTools.processObjectField(
            obj,
            ["question",
            "name"]
          )}
        </Typography>
        <Typography className="boulder" component={"div"}>
          {ComponentCommonTools.processTypesField(obj.question, "type", true)}
        </Typography>
      </Box>
    );
  };

  const processQuestionResultInfo = () => {
    if (!obj) return null;

    return (
      <Box>
        <Stack direction="row" spacing={2}>
          <Box>
            <Typography variant="caption" color="text.disabled">
              {LL("maxquestionscore")}
            </Typography>
            <Typography>
              {CommonTools.processObjectField(obj, ["maxquestionscore"])}
            </Typography>
          </Box>
          <Box>
            <Typography variant="caption" color="text.disabled">
              {LL("questiontotalscore_by_student")}
            </Typography>
            <Typography>
              {CommonTools.processObjectField(
                obj,
                ["questiontotalscore"]
              )}
            </Typography>
          </Box>
        </Stack>
      </Box>
    );
  };
  const processItem = () => {
    if (!obj) return null;

    return (
      <Accordion
        expanded={expend}
        className="accordionItem"
        onChange={handleExpend}
        sx={{ width: "100%" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Stack direction={"column"}>
            {processQuestionInfo()}
            {processQuestionResultInfo()}
          </Stack>
        </AccordionSummary>
        <AccordionDetails className="accordionDetailLesson">
          {processItemDetails()}
        </AccordionDetails>
      </Accordion>
    );
  };

  const processItemDetails = () => {
    if (!obj) return null;
    if (!expend) return null;
    if (!obj.hasOwnProperty("id")) return null;
    if (!obj.id) return null;
    if (!obj.hasOwnProperty("idtestresult")) return null;
    if (!obj.idtestresult) return null;
    return (
      <TestResultAnswerList
        idTestResult={obj.idtestresult}
        idTestResultQuestion={obj.id}
      />
    );
  };

  return processItem();
};

export { TestResultItemQuestion };
