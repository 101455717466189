import Idto from "interfaces/idto.interface";

export default class RequestSortCriteriaDTO implements Idto {
  field?: string;
  asc?: boolean;

  static prepareSortCriteria(
    field: string,
    asc: boolean
  ): RequestSortCriteriaDTO {
    const sortCriteria = new RequestSortCriteriaDTO();
    sortCriteria.field = field;
    sortCriteria.asc = asc;
    return sortCriteria;
  }
}
