import React, { useState } from "react";

import { LessonService } from "services/course/lesson.service";

import { LessonDto, LessonFormDto } from "dto/course/lesson.dto";

import { useResource } from "hooks/useResource";

import { Box, Divider } from "@mui/material";
import { CommonTools } from "tools/utils/commontools";

import ResultObjectDTO from "dto/app/resultobject.dto";
import { MyButton } from "components/elements/button/MyButton";

import { Skeleton, Stack } from "@mui/material";

import { AttachmentDialog } from "components/system/attachment/AttachmentDialog";
import { GalleryDialog } from "components/system/gallery/GalleryDialog";
import { VideoDialog } from "components/system/video/VideoDialog";

import { AddTestDialog } from "../test/inuse/AddTestDialog";
import { useMessage } from "hooks/useMessage";

import { FormLesson } from "./FormLesson";
const service = new LessonService();

type Props = {
  id: string;
  
  idCourse: string;
  defaultObj: LessonDto;
  updateList: (obj: LessonDto) => void;
  deleteFromList: (id: string) => void;
};

const EditLesson: React.FC<Props> = ({
  id,
  
  idCourse,
  updateList,
  deleteFromList,
  defaultObj,
}) => {
  const { registerDialog } = useMessage();
  const { LL } = useResource();
  const [openDialogVideo, setOpenDialogVideo] = useState(false);
  const [openDialogTest, setOpenDialogTest] = useState(false);
  const [openDialogGallery, setOpenDialogGallery] = useState(false);
  const [openDialogAttachment, setOpenDialogAttachment] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const handleOpenDialogAttachment = () => {
    setOpenDialogAttachment(true);
  };

  const handleOpenDialogGallery = () => {
    setOpenDialogGallery(true);
  };

  const handleOpenDialogVideo = () => {
    setOpenDialogVideo(true);
  };

  const handleOpenDialogTest = () => {
    setOpenDialogTest(true);
  };

  const onSubmit = (obj: LessonFormDto) => {
    if (!obj) return;
    if (!id) return;
    obj.idcourse = idCourse;
    setLoadingSubmit(true);
    updateList(obj);
    service.update(id, handleResult, {}, obj);
  };

  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    updateList(result.obj);
    setLoadingSubmit(false);
  };

  const handleActionDelete = () => {
    registerDialog(
      LL("Confirm_delete_lesson"),
      LL("Are_you_sure_you_want_to_delete_this_lesson"),
      { cb: onDelete },
      null
    );
  };

  const onDelete = () => {
    if (!id) return;
    setLoadingDelete(true);
    service.delete(id, handleDelete, { id });
  };
  const handleDelete = (result: ResultObjectDTO, cbParams?: any) => {
    if (!result) return;
    if (result.err) return;
    if (!cbParams) return;
    const id = CommonTools.processObjectField(cbParams, ["id"]);
    if (!id) return;
    deleteFromList(id);
    setLoadingDelete(false);
  };

  if (!defaultObj) return null;
  if (loadingDelete) return <Skeleton variant="rectangular" height={300} />;
  return (
    <Box>
      <Stack
        direction="row"
        sx={{ bgcolor: "#f5f5f5", borderRadius: 1, p: 1 }}
        spacing={2}
        alignItems="center"
        mb={2}
      >
        <Box mb={3}>
          <MyButton
            cb={handleOpenDialogAttachment}
            variant="outlined"
            sx={{ bgcolor: "#fff" }}
          >
            {LL("Attachments")}
          </MyButton>
          <AttachmentDialog
            open={openDialogAttachment}
            setOpen={setOpenDialogAttachment}
            idparent={id}
            parent="lesson"
          />
        </Box>
        <Box mb={3}>
          <MyButton
            cb={handleOpenDialogGallery}
            variant="outlined"
            sx={{ bgcolor: "#fff" }}
          >
            {LL("Gallery")}
          </MyButton>
          <GalleryDialog
            open={openDialogGallery}
            setOpen={setOpenDialogGallery}
            idparent={id}
            parent="lesson"
          />
        </Box>
        <Box mb={3}>
          <MyButton
            variant="outlined"
            sx={{ bgcolor: "#fff" }}
            cb={handleOpenDialogVideo}
          >
            {LL("Video")}
          </MyButton>
          <VideoDialog
            open={openDialogVideo}
            setOpen={setOpenDialogVideo}
            idparent={id}
            parent="lesson"
          />
        </Box>
        <Box mb={3}>
          <MyButton
            variant="outlined"
            sx={{ bgcolor: "#fff" }}
            cb={handleOpenDialogTest}
          >
            {LL("Test")}
          </MyButton>
          <AddTestDialog
            open={openDialogTest}
            setOpen={setOpenDialogTest}
            idLesson={id}
            id={idCourse}
            
          />
        </Box>
      </Stack>
      <Divider />
      <FormLesson
        defaultObj={LessonFormDto.parseFromLessonDto(defaultObj)}
        onSubmit={onSubmit}
        loading={loadingSubmit}
      />
      <Box my={4} textAlign="center">
        <MyButton
          tabIndex={3}
          color="error"
          className="btn"
          variant="contained"
          size="large"
          cb={handleActionDelete}
        >
          {LL("Bt_Delete")}
        </MyButton>
      </Box>
    </Box>
  );

  // const [loading, setLoading] = useState<boolean>(false);
  // const [obj, setObj] = useState<LessonDto | undefined>();
  // const [isFormDisabled, setIsFormDisabled] = useState(false);

  // const [validators, setValidators] = useState<any>();
  // const [vresults, setVResults] = useState<any>(false);

  // const validateAll = () => {
  //   if (!obj) return;
  //   if (!validators) return;

  //   obj.name = obj.name !== undefined ? obj.name : "";
  //   obj.description = obj.description !== undefined ? obj.description : "";
  //   obj.idtypelesson = obj.idtypelesson !== undefined ? obj.idtypelesson : "";
  //   obj.lessonduration =
  //     obj.lessonduration !== undefined ? obj.lessonduration : 0;
  //   obj.islive = obj.islive !== undefined ? obj.islive : false;
  //   obj.startdate = obj.startdate !== undefined ? obj.startdate : undefined;
  //   obj.enddate = obj.enddate !== undefined ? obj.enddate : undefined;
  //   obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;
  //   setObj(obj);

  //   validateField("name", obj.name);
  // };

  // const validateField = (field: any, value: any, v?: any) => {
  //   v = v ? v : JSON.parse(JSON.stringify(vresults));
  //   if (v === false) v = {};
  //   v[field] = ValidateTools.validate(field, value, validators, vresults);
  //   setVResults(v);
  //   setIsFormDisabled(ValidateTools.vForm(v, field));
  //   return v;
  // };

  // const checkLoading = () => {
  //   if (obj === undefined) return;
  //   if (!validators) return;
  //   setLoading(false);
  // };

  // const loadObject = (obj: ResultObjectDTO) => {
  //   if (!obj) return;
  //   if (!obj.err) {
  //     const object = obj.obj ? obj.obj : {};
  //     setObj(object);
  //   }
  // };

  // const getObject = () => {
  //   setLoading(true);
  //   if (id !== undefined) {
  //     service.get(id, loadObject, {});
  //   }
  // };

  // const checkIsFormDisabled = () => {
  //   let rez = ValidateTools.vForm(vresults, "name");
  //   if (!obj || !obj.hasOwnProperty("idtypelesson")) rez = true;
  //   if (
  //     obj &&
  //     obj.hasOwnProperty("idtypelesson") &&
  //     obj.idtypelesson === process.env.REACT_APP_ID_LESSON_TYPE_ONLINE
  //   ) {
  //     if (obj && obj.startdate !== undefined && obj.enddate !== undefined) {
  //       if (obj.startdate > obj.enddate) {
  //         rez = true;
  //       }
  //     } else {
  //       rez = true;
  //     }
  //   }
  //   setIsFormDisabled(rez);
  // };

  // useEffect(() => {
  //   checkLoading();
  //   validateAll();
  //   checkValidDefault();
  // }, [obj, validators]);

  // useEffect(() => {
  //   checkIsFormDisabled();
  // }, [vresults]);

  // const processValidators = () => {
  //   let v: any = {
  //     name: [],
  //   };

  //   v.name.push(new RequiredValidator("", ""));

  //   setValidators(v);
  // };
  // useEffect(() => {
  //   processValidators();
  //   getObject();
  // }, [id]);

  // const handleSubmit = (event: any) => {
  //   event.preventDefault();
  //   if (isFormDisabled) return false;
  //   if (id !== undefined) {
  //     setLoading(true);
  //     service.update(id, handleResult, {}, obj);
  //   }
  // };

  // const handleActionDelete = () => {
  //   registerDialog(
  //     LL("Confirm_delete_lesson"),
  //     LL("Are_you_sure_you_want_to_delete_this_lesson"),
  //     { cb: handleDelete },
  //     null
  //   );
  // };

  // const handleDelete = () => {
  //   if (!obj) return;
  //   if (!obj.id) return;
  //   service.delete(obj.id, handleResult, {});
  // };

  // const handleResult = (result: ResultObjectDTO, cbparams: any) => {
  //   if (!result) return;
  //   if (!result.err) {
  //     setObjectsHasChanged(CommonTools.generateRandomString(5));
  //     setLoading(false);
  //     setExpend(false);
  //   }
  // };

  // const setObjField = (field: any, value: any) => {
  //   let t = JSON.parse(JSON.stringify(obj));
  //   t[field] = value;
  //   setObj(t);
  //   validateField(field, value);
  // };

  // const checkValidDefault = () => {
  //   if (!obj) return;
  //   validateField("name", obj.name);
  // };
  // const setObjDateField = (field: any, value: any) => {
  //   let t = JSON.parse(JSON.stringify(obj));
  //   t[field] = DateTools.getDateTimeStamp(value);
  //   setObj(t);
  // };

  // const processFieldsLessonOnline = () => {
  //   if (!obj) return <></>;
  //   if (!obj.hasOwnProperty("idtypelesson")) return <></>;
  //   if (!obj.idtypelesson) return <></>;
  //   if (obj.idtypelesson !== process.env.REACT_APP_ID_LESSON_TYPE_ONLINE)
  //     return <></>;
  //   return (
  //     <Box>
  //       <Box sx={{ mt: 3 }}>
  //         <MyCheckboxField
  //           tabIndex={1}
  //           id="islive"
  //           name="islive"
  //           _label={LL("islive")}
  //           setObj={(value: any) =>
  //             CommonTools.handleCheckValue(obj, setObj, "islive", value)
  //           }
  //           checked={obj.islive}
  //           color="primary"
  //           size="small"
  //           _vresults={undefined}
  //         />
  //       </Box>
  //       <Box sx={{ mt: 3 }}>
  //         <Typography variant="h6" mb={1}>
  //           {LL("Seteaza data si ora lectiei")}
  //         </Typography>
  //         <Stack direction="row" spacing={2}>
  //           <MyDateTimePicker
  //             disablePast
  //             className="form-picker"
  //             field="startdate"
  //             _defaultValue={DateTools.convertTimestampToDate(obj.startdate)}
  //             LL={LL}
  //             setObjField={setObjDateField}
  //           />

  //           <MyDateTimePicker
  //             disablePast
  //             className="form-picker"
  //             field="enddate"
  //             _defaultValue={DateTools.convertTimestampToDate(obj.enddate)}
  //             LL={LL}
  //             setObjField={setObjDateField}
  //           />
  //         </Stack>
  //       </Box>
  //     </Box>
  //   );
  // };

  // return !loading && obj ? (
  //   <Box>
  //     <Stack
  //       direction="row"
  //       sx={{ bgcolor: "#f5f5f5", borderRadius: 1, p: 1 }}
  //       spacing={2}
  //       alignItems="center"
  //       mb={2}
  //     >
  //       <Box mb={3}>
  //         <MyButton
  //           cb={handleOpenDialogAttachment}
  //           variant="outlined"
  //           sx={{ bgcolor: "#fff" }}
  //         >
  //           {LL("Attachments")}
  //         </MyButton>
  //         <AttachmentDialog
  //           open={openDialogAttachment}
  //           setOpen={setOpenDialogAttachment}
  //           idparent={id}
  //           parent="lesson"
  //         />
  //       </Box>
  //       <Box mb={3}>
  //         <MyButton
  //           cb={handleOpenDialogGallery}
  //           variant="outlined"
  //           sx={{ bgcolor: "#fff" }}
  //         >
  //           {LL("Gallery")}
  //         </MyButton>
  //         <GalleryDialog
  //           open={openDialogGallery}
  //           setOpen={setOpenDialogGallery}
  //           idparent={id}
  //           parent="lesson"
  //         />
  //       </Box>
  //       <Box mb={3}>
  //         <MyButton
  //           variant="outlined"
  //           sx={{ bgcolor: "#fff" }}
  //           cb={handleOpenDialogVideo}
  //         >
  //           {LL("Video")}
  //         </MyButton>
  //         <VideoDialog
  //           open={openDialogVideo}
  //           setOpen={setOpenDialogVideo}
  //           idparent={id}
  //           parent="lesson"
  //         />
  //       </Box>
  //       <Box mb={3}>
  //         <MyButton
  //           variant="outlined"
  //           sx={{ bgcolor: "#fff" }}
  //           cb={handleOpenDialogTest}
  //         >
  //           {LL("Test")}
  //         </MyButton>
  //         <AddTestDialog
  //           open={openDialogTest}
  //           setOpen={setOpenDialogTest}
  //           idLesson={id}
  //           id={idCourse}
  //           setObjectWasChanged={setObjectsHasChanged}
  //         />
  //       </Box>
  //     </Stack>
  //     <Divider />
  //     <form onSubmit={handleSubmit}>
  //       <Typography variant="h6" mb={1} mt={3}>
  //         {" "}
  //         {LL("Tip Numele cursului")}
  //       </Typography>
  //       <MyTextField
  //         tabIndex={0}
  //         fullWidth
  //         id="name"
  //         name="name"
  //         variant="standard"
  //         className="form-input"
  //         color="primary"
  //         _label={LL("Name")}
  //         size="small"
  //         value={obj.name}
  //         setObj={setObjField}
  //         _vresults={vresults && vresults.name ? vresults.name : false}
  //       />

  //       <Box sx={{ mt: 3 }}>
  //         <Typography variant="h6" mb={1}>
  //           {LL("TypeLesson")}
  //         </Typography>
  //         <LessonTypeSelect
  //           value={obj.idtypelesson}
  //           field="idtypelesson"
  //           setObjectField={setObjField}
  //         />
  //       </Box>

  //       <Box sx={{ mt: 3 }}>
  //         <Typography variant="h6" mb={1}>
  //           {" "}
  //           {LL("Status")}
  //         </Typography>
  //         <StatusSelect
  //           tabIndex={2}
  //           id="status"
  //           name="status"
  //           variant="standard"
  //           className="form-select"
  //           color="primary"
  //           value={obj.status}
  //           field="status"
  //           size="small"
  //           setObj={setObjField}
  //           _defaultValue={obj.status}
  //         />
  //       </Box>

  //       {processFieldsLessonOnline()}
  //       <Box sx={{ mt: 3 }}>
  //         <Typography variant="h6" mb={1}>
  //           {LL("descriere lectie")}
  //         </Typography>
  //         <MyCKEditor
  //           data={obj.description ? obj.description : ""}
  //           setObj={setObjField}
  //           name="description"
  //         />
  //       </Box>
  //       <Box my={4} textAlign="center">
  //         <MyButton
  //           tabIndex={3}
  //           disabled={isFormDisabled}
  //           color="primary"
  //           className="btn"
  //           variant="contained"
  //           size="large"
  //           type="submit"
  //           children={LL("Bt_Submit Modification")}
  //         />
  //       </Box>
  //     </form>
  //     <Box my={4} textAlign="center">
  //       <MyButton
  //         tabIndex={3}
  //         color="error"
  //         className="btn"
  //         variant="contained"
  //         size="large"
  //         cb={handleActionDelete}
  //         children={LL("Bt_Delete")}
  //       />
  //     </Box>
  //   </Box>
  // ) : (
  //   <Skeleton variant="rectangular" height={300} />
  // );
};

export default EditLesson;
