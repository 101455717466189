import { Link } from "@mui/material";


import { usePage } from "hooks/usePage";
import { useResource } from "hooks/useResource";
import React from "react";
import { CommonTools } from "tools/utils/commontools";
import { Config } from "tools/utils/config";

const SignUpInfoBlock: React.FC = () => {
  const { LL } = useResource();

  return (
    <React.Fragment
    >
      {LL("term")}
      {" "}
      <TermsBlock />
      {" "}
      {LL("and")}
      {" "}
      <PrivacyPolicyBlock />
    </React.Fragment>
  );
};

const TermsBlock: React.FC = () => {
  const id = Config.ID_PAGE_TERMS;
  const { object } = usePage(id, true, "terms_block");

  if (!id) return null;
  if (!object) return null;
  return (
    <Link
      href={CommonTools.processObjectField(object, ["url"])}
      target="_blank"
    >
      {CommonTools.processObjectField(object, ["name"])}
    </Link>
  );
};

const PrivacyPolicyBlock: React.FC = () => {
  const id = Config.ID_PAGE_PRIVACY_POLICY;
  const { object } = usePage(id, true, "privacy_policy_block");

  if (!id) return null;
  if (!object) return null;
  return (
    <Link
      href={CommonTools.processObjectField(object, ["url"])}
      target="_blank"
    >
      {CommonTools.processObjectField(object, ["name"])}
    </Link>
  );
};
export { SignUpInfoBlock, TermsBlock, PrivacyPolicyBlock };
