import React, { useState } from "react";

import { TestimonialService } from "services/user/testimonial.service";

import {
  TestimonialDto,
  TestimonialResponseDto,
} from "dto/user/testimonial.dto";

import Box from "@mui/material/Box";

import { CommonTools } from "tools/utils/commontools";
import { FormTeacherInterfaceEditTestimonial } from "./FormTeacherInterfaceEditTestimonial";

const service = new TestimonialService();

interface Props {
  _obj: TestimonialDto;
  extended: boolean;
  setExtended: any;
  itemIndex: number;
  setObjects: any;
  objects: Array<TestimonialDto>;
}

const TeacherInterfaceEditTestimonial: React.FC<Props> = ({
  _obj,
  extended,
  setExtended,

  itemIndex,
  setObjects,
  objects,
  ...props
}) => {
  // const { LL } = useResource();
  const [loading, setLoading] = useState<boolean>(false);
  const [obj, setObj] = useState<TestimonialResponseDto>(
    new TestimonialResponseDto()
  );

  // const [isFormDisabled, setIsFormDisabled] = useState(false);

  // const [validators, setValidators] = useState<any>();
  // const [vresults, setVResults] = useState<any>(false);

  // const validateAll = () => {
  //   if (!obj) return;
  //   if (!validators) return;

  //   obj.teacherresponse =
  //     obj.teacherresponse !== undefined ? obj.teacherresponse : "";

  //   setObj(obj);
  //   setLoading(false);
  //   checkIsFormDisabled();
  // };

  // const validateField = (field: any, value: any, v?: any) => {
  //   v = v ? v : JSON.parse(JSON.stringify(vresults));
  //   if (v === false) v = {};
  //   v[field] = ValidateTools.validate(field, value, validators, vresults);
  //   setVResults(v);
  //   setIsFormDisabled(ValidateTools.vForm(v, field));
  //   return v;
  // };

  // const processValidators = () => {
  //   let v: any = {
  //     teacherresponse: [],
  //   };

  //   v.teacherresponse.push(new RequiredValidator("", ""));

  //   setValidators(v);
  // };

  // useEffect(() => {
  //   setLoading(true);
  //   processValidators();
  // }, [extended]);

  // useEffect(() => {
  //   validateAll();
  // }, [validators]);

  // const setObjField = (field: any, value: any) => {
  //   let t = JSON.parse(JSON.stringify(obj));
  //   t[field] = value;
  //   setObj(t);

  //   validateField(field, value);
  // };

  // const handleSubmit = (event: any) => {
  //   event.preventDefault();
  //   if (isFormDisabled) return false;
  //   if (!obj) return;
  //   if (!obj.id) return;
  //   setLoading(true);
  //   service.update(obj.id, handleResult, {}, obj);
  // };

  const onSubmit = (obj: TestimonialResponseDto) => {
    if (!_obj) return;
    const id = CommonTools.processObjectField(_obj, ["id"]);
    if (!id) return;
    setLoading(true);
    service.update(id, handleResult, {}, obj);
  };
  const handleResult = (result: any) => {
    if (!result) return;
    if (!result.err) {
      // setObjectWasChanged(CommonTools.generateRandomString(5))
      const obj = result.obj ? result.obj : {};
      updateObjectAtIndex(itemIndex, obj);
      setLoading(false);
      setObj(new TestimonialResponseDto());
    }
  };

  const updateObjectAtIndex = (index: number, obj: TestimonialDto) => {
    const updatedArray = [...objects];
    updatedArray.splice(index, 1, { ...updatedArray[index], ...obj });

    setObjects(updatedArray);
  };

  // const checkIsFormDisabled = () => {
  //   var rez = false;
  //   if (!obj) rez = true;
  //   if (!obj.teacherresponse) rez = true;
  //   setIsFormDisabled(rez);
  // };

  // useEffect(() => {
  //   if (!obj) return;
  //   if (loading) return;

  //   checkIsFormDisabled();
  // }, [obj.teacherresponse]);

  const handleClose = () => {
    setExtended(false);
  };
  // const processForm = () => {
  //   if (loading) return <Skeleton />;
  //   if (!obj) return <></>;
  //   if (!obj.id) return <></>;

  //   return (
  //     <Box>
  //       <form>
  //         <Box className="shadow-box" sx={{ mt: 3 }}>
  //           <MyTextField
  //             tabIndex={0}
  //             fullWidth
  //             multiline
  //             minRows={4}
  //             id="teacherresponse"
  //             name="teacherresponse"
  //             variant="standard"
  //             className="form-input"
  //             color="primary"
  //             _label={LL("teacherresponse")}
  //             size="small"
  //             value={obj.teacherresponse}
  //             setObj={setObjField}
  //             _vresults={
  //               vresults && vresults.teacherresponse
  //                 ? vresults.teacherresponse
  //                 : false
  //             }
  //           />
  //         </Box>
  //         <Stack direction="row" spacing={2} mt={3}>
  //           <MyButton
  //             tabIndex={3}
  //             cb={handleSubmit}
  //             disabled={isFormDisabled}
  //             color="primary"
  //             variant="contained"
  //             type="submit"
  //             children={LL("Bt_Submit")}
  //           />
  //           <MyButton cb={handleClose} className="cancel-response">
  //             {LL("Bt_Cancel")}
  //           </MyButton>
  //         </Stack>
  //       </form>
  //     </Box>
  //   );
  // };

  return (
    <Box>
      <FormTeacherInterfaceEditTestimonial
        defaultObj={obj}
        onSubmit={onSubmit}
        loading={loading}
        handleClose={handleClose}
      />
    </Box>
  );
};

export { TeacherInterfaceEditTestimonial };
