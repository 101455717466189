import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import { LinkCourseCategoryRepository } from "repositories/course/linkcoursecategory.repository";

export class LinkCourseCategoryService
  extends GeneralService<LinkCourseCategoryRepository>
  implements IService
{
  constructor() {
    super(new LinkCourseCategoryRepository(), [], "_id");
  }
  getLinkCourseByCourseId = async (
    id: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getCourseByCourseId(
      id,
      this.handleGet,
      cbparameters
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };
  addOne = async (cb?: any, cbparameters?: any, data?: any): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    const t = await this.repository.addOne(this.handleGet, cbparameters, data);

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };
  getByIdCourse = async (
    id: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getByIdCourse(
      id,
      this.handleGet,
      cbparameters
    );
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };
}

// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// import { LinkCourseCategoryDto } from "dto/course/linkcoursecategory.dto";

// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import {LinkCourseCategoryRepository} from "repositories/course/linkcoursecategory.repository";
// import { Status } from "tools//types/status";
// import GeneralService from "services/general.service";

// export default class LinkCourseCategoryService
//   extends GeneralService
//   implements IService
// {
//   linkCourseCategoryRepository: LinkCourseCategoryRepository =
//     new LinkCourseCategoryRepository();
//   constructor() {
//     super();
//     this.handleGetList = this.handleGetList.bind(this);
//     this.handleGet = this.handleGet.bind(this);
//   }
//   handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;

//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }
//   prepareObjToAdd(obj: any): LinkCourseCategoryDto {
//     const rez = new LinkCourseCategoryDto();

//     rez.idcoursecategory = obj.idcoursecategory;
//     rez.idcourse = obj.idcourse;

//     return rez;
//   }
//   async update(
//     id: string,
//     cb?: any,
//     cbparameters?: any,
//     data?: any
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.linkCourseCategoryRepository.update(
//       id,
//       this.handleUpdate,
//       data,
//       cbparameters
//     );
//   }

//   handleDelete(result?: any, cbparameters?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters);
//     }
//   }

//   async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.linkCourseCategoryRepository.delete(
//       id,
//       this.handleDelete,
//       cbparameters
//     );
//   }

//   async getLinkCourseByCourseId(
//     id: string,
//     cb?: any,
//     cbparameters?: any
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.linkCourseCategoryRepository.getCourseByCourseId(
//       id,
//       this.handleGet,
//       cbparameters
//     );
//   }

//   handleGet(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.linkCourseCategoryRepository.get(
//       id,
//       this.handleGet,
//       cbparameters
//     );
//     if (cb == undefined) {
//       return this.handleGet(t);
//     }
//   }

//   async getByIdCourse(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.linkCourseCategoryRepository.getByIdCourse(
//       id,
//       this.handleGet,
//       cbparameters
//     );
//     if (cb == undefined) {
//       return this.handleGet(t);
//     }
//   }

//   handleGetList(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async getList(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.linkCourseCategoryRepository.getList(
//       this.handleGetList,
//       cbparameters,
//       data
//     );
//     if (cb == undefined) {
//       return this.handleGetList(t);
//     }
//   }

//   handleAdd(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async add(cb?: any, cbparameters?: any, data?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.linkCourseCategoryRepository.add(
//       this.handleGetList,
//       cbparameters,
//       this.prepareObjToAdd(data)
//     );
//   }

//   async addOne(cb?: any, cbparameters?: any, data?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.linkCourseCategoryRepository.addOne(
//       this.handleAdd,
//       cbparameters,
//       data
//     );
//   }

//   // getDefaultRequestList(onPage?: number): RequestListDTO {
//   //     onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
//   //     if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
//   //     var defaultRequestList = new RequestListDTO();
//   //     defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
//   //     defaultRequestList.onpage = onPage;
//   //     // var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
//   //     // defaultRequestSortCriteria.asc = true;
//   //     // defaultRequestSortCriteria.field = 'value';
//   //     // defaultRequestList.sortcriteria = []
//   //     // defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
//   //     return defaultRequestList;
//   // }

//   getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
//     if (
//       !obj.sortcriteria ||
//       !Array.isArray(obj.sortcriteria) ||
//       !obj.sortcriteria.length
//     ) {
//       var sobj = new RequestSortCriteriaDTO();
//       sobj.asc = true;
//       sobj.field = "_id";

//       obj.sortcriteria = undefined;
//       // obj.sortcriteria?.push(sobj);
//     }

//     return obj;
//   }
// }
