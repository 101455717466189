import React, { useState } from "react";

import { AddressService } from "services/nomenclature/address.service";

import { AddressDto, AddressFormDto } from "dto/nomenclature/address.dto";

import { useResource } from "hooks/useResource";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { useAuth } from "hooks/useAuth";
import { AddressItem } from "./AddressItem";
import { CommonTools } from "tools/utils/commontools";
import { CallbackType } from "interfaces/commontypes.interface";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { useList } from "hooks/useList";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { Skeleton } from "@mui/material";
import { FormAddress } from "./FormAddress";

type Props = {
  currentRoute: any;
};

const service = new AddressService();

const AddUserAddress: React.FC<Props> = () => {
  const { LL } = useResource();
  const { user } = useAuth();
  const [loadingAdd, setLoadingAdd] = useState<boolean>(false);
  const [defaultObj, setDefaultObj] = useState<AddressFormDto>(
    new AddressFormDto()
  );

  const idUser = CommonTools.processObjectField(user, ["id"]);

  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    if (!idUser) return;
    service.getList(cb, cbParams, req);
  };

  const [loading, objects, , , setObjects] = useList<AddressDto>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("iduser", [idUser])],
      1,
      -1
    ),
    [idUser]
  );

  const onSubmit = (obj: AddressFormDto) => {
    const id = CommonTools.processObjectField(obj, ["id"]);
    obj.iduser = idUser;
    setLoadingAdd(true);
    if (!id) {
      service.add(handleResult, {}, obj);
    } else {
      service.update(id, handleResult, {}, obj);
    }
  };

  const handleResult = (result: ResultObjectDTO) => {
    if (!objects) return;
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;

    const id = CommonTools.processObjectField(result, ["obj", "id"]);
    const index = objects.findIndex((item: AddressDto) => item.id === id);
    if (index > -1) {
      objects[index] = result.obj;
      setObjects([...objects]);
    } else {
      setObjects([...objects, result.obj]);
    }
    setDefaultObj(new AddressFormDto());
    setLoadingAdd(false);
  };

  const handleUpdate = (obj: AddressDto) => {
    setDefaultObj(AddressFormDto.parseFromAddressDto(obj));
  };

  const onDelete = (obj: AddressDto) => {
    if (!obj) return;
    const id = CommonTools.processObjectField(obj, ["id"]);
    if (!id) return;
    setLoadingAdd(true);
    service.delete(id, handleDelete, { id });
  };

  const handleDelete = (result: ResultObjectDTO, cbParams?: any) => {
    if (!result) return;
    if (result.err) return;
    if (!cbParams) return;
    const id = CommonTools.processObjectField(cbParams, ["id"]);
    if (!id) return;
    if (!objects) return;
    setObjects(objects.filter((item: AddressDto) => item.id !== id));
    setLoadingAdd(false);
  };
  const processItem = (obj: AddressDto, index: number) => {
    if (!obj) return null;
    return (
      <AddressItem
        obj={obj}
        key={index}
        handleDelete={onDelete}
        handleUpdate={handleUpdate}
      />
    );
  };
  const processList = () => {
    if (loading) return <Skeleton />;
    if (!objects) return null;
    if (!objects.length) return null;
    return (
      <React.Fragment>
        {objects.map((item: AddressDto, index: number) =>
          processItem(item, index)
        )}
      </React.Fragment>
    );
  };

  if (!user) return null;
  return (
    <Box mt={3}>
      <Box>
        <FormAddress
          defaultObj={defaultObj}
          onSubmit={onSubmit}
          loading={loadingAdd || loading}
        />
      </Box>
      <Box px={2}>
        <Typography variant="h4" mt={4}>
          {LL("Address numbers list user")}
        </Typography>
        {processList()}
      </Box>
    </Box>
  );

  // const [loading, setLoading] = useState<boolean>(true);
  // const [obj, setObj] = useState<Array<AddressDto> | undefined>();
  // const [objAddress, setObjAddress] = useState<AddressDto | undefined>(
  //   new AddressDto()
  // );
  // const { user } = useAuth();
  // const _id = user !== undefined ? user.id : "";
  // const [id, setId] = useState<string>(_id);

  // const loadObject = (obj: any) => {
  //   if (!obj) return;
  //   if (!obj.err) {
  //     const object = obj.obj ? obj.obj : [];
  //     setObj(object);
  //   }
  // };

  // const getObject = () => {
  //   setLoading(true);
  //   if (id !== undefined) service.getAddressByUserId(id, loadObject, {});
  // };

  // const checkIsLoading = () => {
  //   let load: boolean = false;
  //   if (obj === undefined) load = true;
  //   setLoading(load);
  // };

  // useEffect(() => {
  //   getObject();
  // }, [currentRoute]);

  // useEffect(() => {
  //   checkIsLoading();
  // }, [obj]);

  // useEffect(() => {
  //   if (user !== undefined) {
  //     setId(user.id);
  //   }
  // }, [user]);

  // const [validators, setValidators] = useState<any>();
  // const [vresults, setVResults] = useState<any>(false);
  // const [isFormDisabled, setIsFormDisabled] = useState(false);

  // const validateAll = () => {
  //   if (!objAddress) return;
  //   if (!validators) return;
  //   // if (vresults) return;

  //   objAddress.street =
  //     objAddress.street !== undefined ? objAddress.street : "";
  //   objAddress.streetnumber =
  //     objAddress.streetnumber !== undefined ? objAddress.streetnumber : 0;

  //   objAddress.zipcode =
  //     objAddress.zipcode !== undefined ? objAddress.zipcode : "";
  //   objAddress.idlocality =
  //     objAddress.idlocality !== undefined ? objAddress.idlocality : "";

  //   setObjAddress(objAddress);

  //   let t = validateField("street", objAddress.street);
  //   t = validateField("streetnumber", objAddress.streetnumber, t);
  // };

  // const validateField = (field: any, value: any, v?: any) => {
  //   v = v ? v : JSON.parse(JSON.stringify(vresults));
  //   if (v === false) v = {};
  //   v[field] = ValidateTools.validate(field, value, validators, vresults);

  //   setIsFormDisabled(ValidateTools.vForm(v, field));
  //   setVResults(v);
  //   return v;
  // };

  // const checkIsFormDisabled = () => {
  //   var rez = ValidateTools.vForm(vresults, "street");
  //   rez = ValidateTools.vForm(vresults, "streetnumber", rez);

  //   setIsFormDisabled(rez);
  // };

  // useEffect(() => {
  //   checkIsFormDisabled();
  // }, [vresults]);

  // useEffect(() => {
  //   processValidators();
  // }, []);
  // useEffect(() => {
  //   validateAll();
  // }, [objAddress, validators]);

  // const processValidators = () => {
  //   let v: any = {
  //     street: [],
  //     streetnumber: [],
  //   };

  //   v.street.push(new RequiredValidator("", ""));
  //   v.streetnumber.push(new RequiredValidator("", ""));

  //   setValidators(v);
  // };

  // const setObjField = (field: any, value: any) => {
  //   let t = JSON.parse(JSON.stringify(objAddress));
  //   t[field] = value;
  //   setObjAddress(t);
  // };

  // const handleSubmit = (event: any) => {
  //   event.preventDefault();

  //   if (
  //     objAddress !== undefined &&
  //     id !== undefined &&
  //     objAddress.street !== undefined
  //   ) {
  //     objAddress.iduser = id;
  //     objAddress.status = Status.ACTIVE;

  //     if (!objAddress.hasOwnProperty("id"))
  //       service.add(getObject, {}, objAddress);
  //     else {
  //       if (objAddress.id)
  //         service.update(objAddress.id, getObject, {}, objAddress);
  //     }
  //   }

  //   setObjAddress(new AddressDto());
  // };

  // const handleDelete = (obj: AddressDto) => {
  //   if (obj.id) service.delete(obj.id, getObject, undefined);
  //   setObjAddress(new AddressDto());
  // };

  // const processAddressItem = (obj: AddressDto, index: number) => {
  //   if (!obj) return null;
  //   return (
  //     <AddressItem
  //       obj={obj}
  //       key={index}
  //       handleDelete={handleDelete}
  //       handleUpdate={setObjAddress}
  //     />
  //   );
  //   // return (
  //   //   <>
  //   //     <Box sx={{ flexGrow: 1 }}>
  //   //       <Grid container py={2} spacing={2}>
  //   //         <Grid item xs={10}>
  //   //           <Typography>
  //   //             {ComponentCommonTools.processObjectField(obj, "street")}{" "}
  //   //             {ComponentCommonTools.processObjectField(obj, "streetnumber")}{" "}
  //   //             {ComponentCommonTools.processObjectField(obj, "zipcode")}{" "}
  //   //             {ComponentCommonTools.processObjectFieldOfObject(
  //   //               obj,
  //   //               "locality",
  //   //               "name"
  //   //             )}
  //   //           </Typography>
  //   //         </Grid>

  //   //         <Grid item xs={2}>
  //   //           <MyButton
  //   //             type="submit"
  //   //             cb={() => {
  //   //               setObjAddress(obj);
  //   //             }}
  //   //             children={<EditIcon />}
  //   //             color="primary"
  //   //           />
  //   //           <MyButton
  //   //             color="secondary"
  //   //             type="submit"
  //   //             cb={() => {
  //   //               handleDelete(obj);
  //   //             }}
  //   //             children={<DeleteIcon />}
  //   //           />
  //   //         </Grid>
  //   //       </Grid>
  //   //       <Divider />
  //   //     </Box>
  //   //   </>
  //   // );
  // };

  // const processAddressList = () => {
  //   if (!obj) return <></>;
  //   if (obj.length === 0) return <></>;
  //   return (
  //     <>
  //       {obj.map((item: AddressDto, index: any) =>
  //         processAddressItem(item, index)
  //       )}
  //     </>
  //   );
  // };
  // return !loading && obj && objAddress ? (
  //   <Box>
  //     <form onSubmit={handleSubmit}>
  //       <Box sx={{ flexGrow: 1 }} maxWidth={"sm"} p={2} mx="auto">
  //         <Typography variant="h6">{LL("Strada")}</Typography>
  //         <MyTextField
  //           tabIndex={0}
  //           fullWidth
  //           id="street"
  //           name="street"
  //           variant="standard"
  //           className="form-input"
  //           _label={LL("Street")}
  //           size="small"
  //           value={objAddress.street}
  //           setObj={setObjField}
  //           _vresults={vresults && vresults.street ? vresults.street : false}
  //         />
  //         <Box sx={{ mt: 3 }}>
  //           <Typography variant="h6">{LL("Nr. stradă")}</Typography>
  //           <MyTextField
  //             tabIndex={1}
  //             fullWidth
  //             id="streetnumber"
  //             name="streetnumber"
  //             _label={LL("streetnumber")}
  //             variant="standard"
  //             className="form-input"
  //             value={objAddress.streetnumber}
  //             setObj={setObjField}
  //             _vresults={
  //               vresults && vresults.streetnumber
  //                 ? vresults.streetnumber
  //                 : false
  //             }
  //           />
  //         </Box>
  //         <Box sx={{ mt: 3 }}>
  //           <Typography variant="h6">{LL("Cod poștal")}</Typography>
  //           <MyTextField
  //             tabIndex={1}
  //             fullWidth
  //             id="zipcode"
  //             name="zipcode"
  //             _label={LL("zipcode")}
  //             variant="standard"
  //             className="form-input"
  //             value={objAddress.zipcode}
  //             setObj={setObjField}
  //             _vresults={
  //               vresults && vresults.zipcode ? vresults.zipcode : false
  //             }
  //           />
  //         </Box>
  //         <Box sx={{ mt: 3 }}>
  //           <Typography variant="h6">{LL("Alege localitatea")}</Typography>

  //           <LocalityAutocompleteV2
  //             setObj={setObjAddress}
  //             obj={objAddress}
  //             _defaultValue={objAddress.locality}
  //           />
  //         </Box>
  //         <Box textAlign="center">
  //           <MyButton
  //             tabIndex={4}
  //             disabled={isFormDisabled}
  //             color="primary"
  //             className="btn"
  //             variant="contained"
  //             size="large"
  //             type="submit"
  //             sx={{ mt: 4 }}
  //             children={LL("Bt_Submit")}
  //           />
  //         </Box>
  //       </Box>
  //     </form>
  //     <Box p={2}>
  //       <Typography variant="h4">{LL("Adresele mele")}</Typography>
  //       {processAddressList()}
  //     </Box>
  //   </Box>
  // ) : (
  //   <CircularLoading />
  // );
};

export default AddUserAddress;
