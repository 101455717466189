import React from "react";

import { useEffect, useState } from "react";
import { useResource } from "hooks/useResource";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";

type Props = {
  open: boolean;
  setOpen: any;
  handleConfirmDelete: any;
  handleCancelDelete: any;
  showField: string | undefined;
  _obj: any;
};

const MessagesContainerPopUpConfirmDelete: React.FC<Props> = ({
  open,
  setOpen,
  handleConfirmDelete,
  handleCancelDelete,
  showField,
  _obj,
}) => {
  const { LL } = useResource();
  const [obj, setObj] = useState();
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (_obj === undefined) {
      setOpen(false);
      return;
    }
    setObj(_obj);
  }, [_obj]);

  useEffect(() => {
    if (obj === undefined) {
      setOpen(false);
      return;
    }
    handleSetMessage();
  }, [obj]);

  const handleClose = () => {
    if (handleCancelDelete === undefined) {
      setOpen(false);
      return;
    }

    handleCancelDelete();
    setMessage("");
  };

  const confirmDelete = () => {
    if (handleConfirmDelete === undefined) {
      setOpen(false);
      return;
    }
    handleConfirmDelete();
    setMessage("");
  };

  const handleSetMessage = () => {
    if (obj === undefined) {
      setOpen(false);
      return;
    }
    if (showField === undefined) {
      setOpen(false);
      return;
    }
    if (obj[showField] === undefined) {
      setMessage(LL("object"));
      return;
    }

    setMessage(obj[showField]);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{LL("Delete_confirm")}</DialogTitle>
      <DialogContent>
        <Typography variant="h6">
          {LL("Do you want to delete this object: ")}
        </Typography>
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            textAlign: "center",
          }}
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={confirmDelete} color="secondary">
          {LL("Delete")}
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          {LL("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { MessagesContainerPopUpConfirmDelete };
