import { Box, Link, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { SubcategoryBlockMenu } from "./SubcategoryBlockMenu";
import { CourseCategoryDto } from "dto/course/coursecategory.dto";
import { CommonTools } from "tools/utils/commontools";
import { RouteTools } from "tools/utils/routetools";

type Props = {
  defaultObjects: CourseCategoryDto[];
};

const SubcategoryList: React.FC<Props> = ({ defaultObjects }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [displayInlineObjects, setDisplayInlineObjects] = useState<
    CourseCategoryDto[]
  >([]);
  const [displayBlockObjects, setDisplayBlockObjects] =
    useState<CourseCategoryDto[]>(defaultObjects);

  const measureObjectWidth = (text: string) => {
    const span = document.createElement("div");
    span.style.visibility = "hidden";
    span.style.position = "absolute";
    span.style.whiteSpace = "nowrap";
    span.textContent = text;
    document.body.appendChild(span);
    const width = span.getBoundingClientRect().width;
    document.body.removeChild(span);
    return width + 20;
  };

  useEffect(() => {
    const updateDisplayObjects = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const inlineObjects: CourseCategoryDto[] = [];
        const blockObjects: CourseCategoryDto[] = [];
        let totalWidth = 0;
        for (let i = 0; i < defaultObjects.length; i++) {
          const objectWidth = measureObjectWidth(
            CommonTools.processObjectField(defaultObjects[i], ["name"])
          );
          if (totalWidth + objectWidth < containerWidth) {
            inlineObjects.push(defaultObjects[i]);
            totalWidth += objectWidth;
          } else {
            blockObjects.push(defaultObjects[i]);
          }
        }
        setDisplayInlineObjects(inlineObjects);
        setDisplayBlockObjects(blockObjects);
      }
    };

    updateDisplayObjects();
    window.addEventListener("resize", updateDisplayObjects);
    return () => {
      window.removeEventListener("resize", updateDisplayObjects);
    };
  }, [defaultObjects]);

  const onClick = (object: CourseCategoryDto) => {
    if (!object) return;
    if (!CommonTools.processObjectField(object, ["fullurl"])) return;
    RouteTools.setHistory(
      CommonTools.processObjectField(object, ["fullurl"]),
      {}
    );
  };

  return (
    <Box ref={containerRef} className="subCTGList">
      {displayInlineObjects.map((object, index) => (
        <Link key={index} onClick={onClick.bind(this, object)} variant="button">
          {CommonTools.processObjectField(object, ["name"])}
        </Link>
      ))}
      {displayBlockObjects.length > 0 && (
        <Box>
          <SubcategoryBlockMenu
            objects={displayBlockObjects}
            action={onClick}
          />
        </Box>
      )}
    </Box>
  );
};

export { SubcategoryList };
