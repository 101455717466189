import React, { useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import Box from "@mui/material/Box";

import { TeacherService } from "services/user/teacher.service";
import {
  TeacherMediaFilesDto,
  TeacherMediaVideoDto,
} from "dto/user/teacher.dto";

import { CircularLoading } from "components/elements/loading/CircularLoading";

import { useTeacherInfo } from "context/teacherinfo.context";
import ResultObjectDTO from "dto/app/resultobject.dto";

import { TeacherVideo } from "../teachermedia/TeacherVideo";
import { useResource } from "hooks/useResource";
import { CommonTools } from "tools/utils/commontools";
import { TeacherAttachment } from "../teachermedia/TeacherAttachment";

const service = new TeacherService();

const EditTeacherMedia: React.FC<PageComponentProps> = ({ currentRoute }) => {
  // const { user } = useAuth();

  // const [loading, setLoading] = useState<boolean>(true);
  // const [obj, setObj] = useState<TeacherDto | undefined>();

  // const [id, setId] = useState<string>(
  //   CommonTools.processObjectField(user, ["id"])
  // );
  // const [objectWasChanged, setObjectWasChanged] = useState<string>("");

  // const loadObject = (obj: any) => {
  //   if (!obj) return;
  //   if (!obj.err) {
  //     const object = obj.obj ? obj.obj : [];
  //     setObj(object);
  //   }
  // };

  // const getObject = () => {
  //   setLoading(true);
  //   if (id !== undefined && id !== "")
  //     teacherService.getByUserId(id, loadObject, {});
  // };

  // const checkIsLoading = () => {
  //   let load: boolean = false;
  //   if (obj === undefined) load = true;
  //   setLoading(load);
  // };

  // useEffect(() => {
  //   getObject();
  // }, [currentRoute, objectWasChanged]);

  // useEffect(() => {
  //   checkIsLoading();
  // }, [obj]);

  // useEffect(() => {
  //   if (user !== undefined) {
  //     if (!user || !user.id) return;
  //     setId(user.id);
  //   }
  // }, [user]);

  // return user !== undefined && !loading && obj ? (
  //   <>
  //     <Box maxWidth="sm" mx="auto" mt={3}>
  //       <AddTeacherMediaComponent
  //         setLoading={setLoading}
  //         currentRoute={currentRoute}
  //         objTeacher={obj}
  //         loading={loading}
  //         setObjectWasChanged={setObjectWasChanged}
  //       />
  //     </Box>
  //   </>
  // ) : (
  //   <CircularLoading />
  // );

  const [loadingAction, setLoadingAction] = useState<boolean>(false);
  const { teacherInfo, setTeacherInfo, loading } = useTeacherInfo();
  const { LL } = useResource();
  const onSubmitLessonExample = (obj: TeacherMediaVideoDto) => {
    if (!teacherInfo) return;
    setLoadingAction(true);

    if (teacherInfo.videolessonexample)
      service.updateMedia(
        handleResult,
        {},
        TeacherMediaVideoDto.parseForSubmitLessonExample(obj)
      );
    else
      service.addMedia(
        handleResult,
        {},
        TeacherMediaVideoDto.parseForSubmitLessonExample(obj)
      );
  };
  const onDeleteLessonExample = () => {
    if (!teacherInfo) return;
    setLoadingAction(true);
    if (!teacherInfo.videolessonexample) return;
    service.updateMedia(
      handleResult,
      {},
      TeacherMediaVideoDto.deleteVideoLessonExample(
        CommonTools.processObjectField(teacherInfo, ["id"])
      )
    );
  };
  const onSubmitPresentation = (obj: TeacherMediaVideoDto) => {
    if (!teacherInfo) return;
    setLoadingAction(true);
    if (teacherInfo.videopresentation)
      service.updateMedia(
        handleResult,
        {},
        TeacherMediaVideoDto.parseForSubmitPresentation(obj)
      );
    else
      service.addMedia(
        handleResult,
        {},
        TeacherMediaVideoDto.parseForSubmitPresentation(obj)
      );
  };

  const onDeletePresentation = () => {
    if (!teacherInfo) return;
    setLoadingAction(true);
    if (!teacherInfo.videopresentation) return;
    service.updateMedia(
      handleResult,
      {},
      TeacherMediaVideoDto.deletePresentation(
        CommonTools.processObjectField(teacherInfo, ["id"])
      )
    );
  };

  const onSubmitCV = (obj: TeacherMediaFilesDto) => {
    if (!teacherInfo) return;
    setLoadingAction(true);

    if (teacherInfo.attachmentcv)
      service.updateMedia(
        handleResult,
        {},
        TeacherMediaFilesDto.parseForSubmitCV(obj)
      );
    else
      service.addMedia(
        handleResult,
        {},
        TeacherMediaFilesDto.parseForSubmitCV(obj)
      );
  };

  const onDeleteCV = () => {
    if (!teacherInfo) return;
    setLoadingAction(true);
    if (!teacherInfo.attachmentcv) return;
    service.updateMedia(
      handleResult,
      {},
      TeacherMediaFilesDto.deleteCV(
        CommonTools.processObjectField(teacherInfo, ["id"])
      )
    );
  };
  const handleResult = (result: ResultObjectDTO) => {
    setLoadingAction(false);
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    setTeacherInfo(result.obj);
  };
  if (loading) return <CircularLoading />;
  if (!teacherInfo) return null;
  return (
    <Box
      p={3}
      maxWidth="sm"
      mx={"auto"}
      justifyContent="center"
      display="flex"
      flexDirection="column">
      <Box py={2}>
        <TeacherVideo
          video={teacherInfo.videolessonexample ?? null}
          onSubmit={onSubmitLessonExample}
          loading={loading || loadingAction}
          label={LL("videolessonexample")}
          onDelete={onDeleteLessonExample}
        />
      </Box>
      <Box py={2}>
        <TeacherVideo
          video={teacherInfo.videopresentation ?? null}
          onSubmit={onSubmitPresentation}
          loading={loading || loadingAction}
          label={LL("videopresentation")}
          onDelete={onDeletePresentation}
        />
      </Box>
      <Box py={2}>
        <TeacherAttachment
          attachment={teacherInfo.attachmentcv ?? null}
          onSubmit={onSubmitCV}
          loading={loading || loadingAction}
          label={LL("cv")}
          onDelete={onDeleteCV}
          messageTitle={LL("delete_cv")}
          messageContent={LL("are_you_want_to_delete_cv")}
        />
      </Box>
    </Box>
  );
};

export { EditTeacherMedia };
