import React from "react";
import { useResource } from "hooks/useResource";

import { CourseService } from "services/course/course.service";

import { CourseDto } from "dto/course/course.dto";

import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools//types/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";

import Skeleton from "@mui/material/Skeleton";
import { Box } from "@mui/material";

import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

import { CourseCardForStudent } from "./CourseCardForStudent";
import { useList } from "hooks/useList";

const service = new CourseService();

type Props = {
  idStudent: string;
};

const RecommendedCourseForStudent: React.FC<Props> = ({ idStudent }) => {
  const { CC } = useResource();

  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    if (!idStudent) return;
    service.getListRecommendedStudent(cb, cbParams, req);
  };
  const [loading, objects] = useList<CourseDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
        RequestFilterDTO.prepareFilter("notidstudent", [idStudent]),
      ],
      1,
      parseInt(CC("DefaultOnPageCourseRecommended", "1")),
      [RequestSortCriteriaDTO.prepareSortCriteria("popularidentifier", false)]
    ),
    [idStudent],
    true,
    `recommended_course_for_student_${idStudent}`
  );

  const processCourseItem = (obj: CourseDto, index: number) => {
    return (
      <Box key={index}>
        <CourseCardForStudent obj={obj} loading={loading} />
      </Box>
    );
  };

  const processCourseList = () => {
    if (!idStudent) return null;
    if (!objects) return null;
    if (!objects.length) return null;

    return objects.map((item: CourseDto, i: number) => {
      return processCourseItem(item, i);
    });
  };

  return loading ? <Skeleton /> : <Box>{processCourseList()}</Box>;
};

export { RecommendedCourseForStudent };
