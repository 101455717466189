import React from "react";

import { HtmlTag } from "components/form/HtmlTag";
import { Box, Typography, Grid } from "@mui/material";
import { MainObjectImage } from "components/elements/media/MainObjectImage";
import { SkeletonCardRight } from "components/elements/Skeleton/SkeletonSection";
import { usePage } from "hooks/usePage";
import { CommonTools } from "tools/utils/commontools";


type Props = {
  index: number;
  idParam: string;
};
const BecomeTeacherStepPage: React.FC<Props> = ({
  idParam,
  index,
}) => {
  const cacheKey = "become_teacher_step_page" + index;
  const { object, loading } = usePage(idParam ?? "", true, cacheKey);
  // const { saveCache, getCache } = useResource();

  // const [isLoading, setIsLoading] = useState(true);
  // const [object, setObject] = useState<PageDto | null>(null);

  // const loadObject = (_object: any) => {
  //   setObject(_object.object);
  //   setIsLoading(false);
  //   const cacheName = "BecomeTeacherStepPage" + index;
  //   saveCache(_object.object, cacheName);
  // };

  // const getObject = async () => {
  //   const id = idParam ?? "-1";

  //   const cacheName = "BecomeTeacherStepPage" + index;
  //   const cd = getCache(cacheName);
  //   if (cd !== false) {
  //     setObject(cd);
  //     setIsLoading(false);
  //     return;
  //   }

  //   setIsLoading(true);

  //   pageService.get(id, loadObject);
  // };

  // useEffect(() => {
  //   getObject();
  // }, [idParam]);

  const processMainImage = () => {
    if (!object) return null;
    if (!object.hasOwnProperty("gallery")) return null;

    const _gallery = object.gallery ? object.gallery : undefined;
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }} className="image">
        <MainObjectImage gallery={_gallery} w={420} h={250} />
      </Box>
    );
  };

  const processHead = () => {
    if (!object) return null;
    if (!CommonTools.processObjectField(object, ["name"])) return null;

    return (
      <Typography variant="h5">
        {CommonTools.processObjectField(object, ["name"])}
      </Typography>
    );
  };

  const processContent = () => {
    if (!object) return null;
    if (!CommonTools.processObjectField(object, ["content"])) return null;

    return (
      <HtmlTag content={CommonTools.processObjectField(object, ["content"])} />
    );
  };

  const processPage = () => {
    if (!object) return null;
    return (
      <Grid container spacing={2} py={5}>
        <Grid item xs={12} lg={7}>
          {processHead()}
          {processContent()}
        </Grid>
        <Grid item xs={12} lg={5}>
          {processMainImage()}
        </Grid>
      </Grid>
    );
  };

  return loading ? <SkeletonCardRight /> : processPage();
};

export { BecomeTeacherStepPage };
