import React, { useContext } from "react";
import { Box, Skeleton,Typography } from "@mui/material";
import { CourseSearchContext } from "context/coursesearch.context";

import { useResource } from "hooks/useResource";

const CourseSearchTopBlock: React.FC = () => {
  const {
    state: { searchValue, loading, total },
  } = useContext(CourseSearchContext);
  const { LL } = useResource();
  if (!searchValue) return null;
  if (loading) return <Box sx={{display:"flex", justifyContent:"start", alignItems:"center", width:"100%"}}><Skeleton variant="text" sx={{width:"60%", fontSize:"2rem"}} /></Box>;
  return (
    <Box>
      <Typography variant="h4">
        {total}{" "}{LL("search_result_for")} {" "} "{searchValue}"
      </Typography>
    </Box>
  );
};

export { CourseSearchTopBlock };
 