import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CourseDto } from "dto/course/course.dto";
import { CourseEnrollmentDto } from "dto/course/courseenrollment.dto";
import { LessonDto } from "dto/course/lesson.dto";
import { LessonEnrollmentDto } from "dto/course/lessonenrollment.dto";
import { useAuth } from "hooks/useAuth";
import React, { useReducer, useCallback } from "react";
import { CourseService } from "services/course/course.service";
import { CourseEnrollmentService } from "services/course/courseenrollment.service";
import { LessonService } from "services/course/lesson.service";
import { LessonEnrollmentService } from "services/course/lessonenrollment.service";
import { Status } from "tools//types/status";
import { useResource } from "hooks/useResource";

import { CommonTools } from "tools/utils/commontools";
import { RouteTools } from "tools/utils/routetools";
import { VideoDto } from "dto/system/video.dto";

interface StudentCourseState {
  lessonObjects: Array<LessonDto> | null;
  currentLessonObject: LessonDto | null;
  selectedLessonObject: LessonDto | null;
  courseEnrollmentObject: CourseEnrollmentDto | null;
  lessonEnrollmentObject: LessonEnrollmentDto | null;
  idCurrentLesson: string;
  idCourseEnrollment: string;
  course: CourseDto | null;
  idCourse: string;
  courseEnrollmentStatus: number;
  courseEnrollmentPaymentStatus: number;
  isSelectedLessonTheCurrentLesson: boolean;
  loadingLessonArea: boolean;
  reloadCourseEnrollment: string;
  reloadLessonEnrollment: string;
  loadingCourseEnrollmentArea: boolean;
  needReloadAfterLessonTest: string;
  videoSelected: VideoDto | null;
  idSelectedVideo: string;
}

interface StudentCourseActions {
  getLessonList: () => void;
  getCourseEnrollment: (id: string) => void;
  getCourse: (id: string) => void;
  setCurrentPath: (idCurrentLesson: string, idCourseEnrollment: string) => void;
  resetState: () => void;
  setCourseEnrollmentObject: (
    courseEnrollmentObject: CourseEnrollmentDto
  ) => void;
  getCurrentLesson: () => void;
  enableLesson: () => void;
  processDisabledInteraction: () => boolean;
  selectLesson: (id: string) => void;
  getCurrentLessonSelection: () => void;
  getLessonEnrollmentByLesson: (idLesson: string) => void;
  getCurrentLessonEnrollmentWhenIdCurrentLessonIsSet: () => void;
  setLoadingArea: (loadingLessonArea: boolean) => void;
  finishLessonAndGetNext: (idLessonEnrollment: string) => void;
  triggerReloadCourseEnrollment: (value: string) => void;
  triggerReloadLessonEnrollment: (value: string) => void;
  triggerReloadLessonEnrollmentAfterTest: (value: string) => void;
  getCurrentLessonAfterTest: () => void;
  selectVideo: (videoSelected: VideoDto) => void;
  setIdVideoSelected: (idSelectedVideo: string) => void;
  getDefaultVideo: (currentRoute: any) => void;
}

export const StudentCourseContext = React.createContext({
  state: {} as StudentCourseState,
  actions: {} as StudentCourseActions,
});

interface ProviderProps {
  children: React.ReactNode;
}

interface Action {
  type: string;
  payload?: any;
}

const processStatus = (courseEnrollment: CourseEnrollmentDto) => {
  if (!courseEnrollment) return 0;
  if (!courseEnrollment.hasOwnProperty("status")) return 0;
  if (!courseEnrollment.status) return 0;
  return courseEnrollment.status;
};

const processStatusPayment = (courseEnrollment: CourseEnrollmentDto) => {
  if (!courseEnrollment) return 0;
  if (!courseEnrollment.hasOwnProperty("paymentstatus")) return 0;
  if (!courseEnrollment.paymentstatus) return 0;
  return courseEnrollment.paymentstatus;
};

const reducer = (state: StudentCourseState, action: Action) => {
  switch (action.type) {
    case "get_lesson_list": {
      return { ...state, lessonObjects: action.payload.lessonObjects };
    }
    case "get_course_enrollment": {
      return {
        ...state,
        courseEnrollmentObject: action.payload.courseEnrollmentObject,
        courseEnrollmentStatus: processStatus(
          action.payload.courseEnrollmentObject
        ),
        courseEnrollmentPaymentStatus: processStatusPayment(
          action.payload.courseEnrollmentObject
        ),
        idCourse: action.payload.idCourse,
        loadingCourseEnrollmentArea: false,
      };
    }
    case "get_course": {
      return {
        ...state,
        course: action.payload.course,
      };
    }
    case "set_current_path": {
      return {
        ...state,
        idCurrentLesson: action.payload.idCurrentLesson,
        idCourseEnrollment: action.payload.idCourseEnrollment,
      };
    }
    case "set_course_enrollment": {
      return {
        ...state,
        courseEnrollmentObject: action.payload.courseEnrollmentObject,
        courseEnrollmentStatus: processStatus(
          action.payload.courseEnrollmentObject
        ),
        courseEnrollmentPaymentStatus: processStatusPayment(
          action.payload.courseEnrollmentObject
        ),
        loadingCourseEnrollmentArea: false,
      };
    }
    case "set_current_lesson_enrollment": {
      return {
        ...state,
        lessonEnrollmentObject: action.payload.lessonEnrollmentObject,
        courseEnrollmentObject: action.payload.courseEnrollmentObject,
        idCurrentLesson: action.payload.idCurrentLesson,
        loadingLessonArea: false,
      };
    }
    case "set_current_lesson_enrollment_without_course_enrollment": {
      return {
        ...state,
        lessonEnrollmentObject: action.payload.lessonEnrollmentObject,
        idCurrentLesson: action.payload.idCurrentLesson,
        loadingLessonArea: false,
      };
    }
    case "set_lesson": {
      return {
        ...state,
        idCurrentLesson: action.payload.idCurrentLesson,
        selectedLessonObject: action.payload.lesson,
      };
    }
    case "get_current_lesson": {
      return {
        ...state,
        selectedLessonObject: action.payload.lesson,
      };
    }
    case "get_lessen_enrollment_by_lesson": {
      return {
        ...state,
        lessonEnrollmentObject: action.payload.lessonEnrollmentObject,
        currentLessonObject: action.payload.lesson,
        isSelectedLessonTheCurrentLesson:
          action.payload.isSelectedLessonTheCurrentLesson,
        loadingLessonArea: false,
        loadingCourseEnrollmentArea: false,
      };
    }
    case "finish_lesson_and_get_next": {
      return {
        ...state,
        lessonEnrollmentObject: action.payload.lessonEnrollmentObject,
        loadingLessonArea: false,
      };
    }
    case "loading_lesson_area": {
      return {
        ...state,
        loadingLessonArea: action.payload.loadingLessonArea,
      };
    }
    case "reload_course_enrollment": {
      return {
        ...state,
        reloadCourseEnrollment: action.payload.value,
        loadingCourseEnrollmentArea: true,
      };
    }
    case "reload_lesson_enrollment": {
      return {
        ...state,
        reloadLessonEnrollment: action.payload.value,
        loadingLessonArea: true,
      };
    }
    case "reset_lesson_enrollment_after_test": {
      return {
        ...state,
        needReloadAfterLessonTest: action.payload.value,
      };
    }
    case "select_video": {
      return {
        ...state,
        videoSelected: action.payload.videoSelected,
        idSelectedVideo: action.payload.idSelectedVideo,
      };
    }
    case "set_video_id": {
      return {
        ...state,
        idSelectedVideo: action.payload.idSelectedVideo,
      };
    }
    case "reset_state": {
      return {
        ...state,
        lessonObjects: null,
        currentLessonObject: null,
        selectedLessonObject: null,
        courseEnrollmentObject: null,
        lessonEnrollmentObject: null,
        idCurrentLesson: "",
        idCourseEnrollment: "",
        course: null,
        idCourse: "",
        courseEnrollmentStatus: 0,
        courseEnrollmentPaymentStatus: 0,
        isSelectedLessonTheCurrentLesson: true,
        loadingLessonArea: false,
        reloadCourseEnrollment: "",
        reloadLessonEnrollment: "",
        loadingCourseEnrollmentArea: false,
        needReloadAfterLessonTest: "",
        videoSelected: null,
        idSelectedVideo: "",
      };
    }

    default:
      return state;
  }
};

const lessonService = new LessonService();
const courseEnrollmentService = new CourseEnrollmentService();
const courseService = new CourseService();
const lessonEnrollmentService = new LessonEnrollmentService();

export const StudentCourseProvider: React.FC<ProviderProps> = ({
  children,
}) => {
  const { student } = useAuth();
  const { CC } = useResource();
  const [state, dispatch] = useReducer(reducer, {
    lessonObjects: null,
    currentLessonObject: null,
    selectedLessonObject: null,
    courseEnrollmentObject: null,
    lessonEnrollmentObject: null,
    idCurrentLesson: "",
    idCourseEnrollment: "",
    course: null,
    idCourse: "",
    courseEnrollmentStatus: 0,
    courseEnrollmentPaymentStatus: 0,
    isSelectedLessonTheCurrentLesson: true,
    loadingLessonArea: false,
    reloadCourseEnrollment: "",
    reloadLessonEnrollment: "",
    loadingCourseEnrollmentArea: false,
    needReloadAfterLessonTest: "",
    videoSelected: null,
    idSelectedVideo: "",
  });

  const getDefaultVideo = (currentRoute: any) => {
    if (!state.selectedLessonObject) {
      dispatch({
        type: "select_video",
        payload: { videoSelected: null, idSelectedVideo: "" },
      });
      return;
    }
    if (!state.selectedLessonObject.video) {
      dispatch({
        type: "select_video",
        payload: { videoSelected: null, idSelectedVideo: "" },
      });
      return;
    }
    if (!state.selectedLessonObject.video.length) {
      dispatch({
        type: "select_video",
        payload: { videoSelected: null, idSelectedVideo: "" },
      });
      return;
    }
    if (!state.selectedLessonObject.video[0]) {
      dispatch({
        type: "select_video",
        payload: { videoSelected: null, idSelectedVideo: "" },
      });
      return;
    }
    let video = null;
    if (state.idSelectedVideo) {
      video = state.selectedLessonObject.video.find(
        (x: VideoDto) => x.id === state.idSelectedVideo
      );
    }
    if (!video) video = state.selectedLessonObject.video[0];
    dispatch({
      type: "select_video",
      payload: { videoSelected: video, idSelectedVideo: video.id },
    });

    if (video) {
      const url = CommonTools.generateUrlResource(
        CommonTools.getCurseEnrollmentUrl(
          state.courseEnrollmentObject,
          state.selectedLessonObject.id
        ),
        state.selectedLessonObject.id,
        "video",
        video.id
      );
      RouteTools.setHistory(url, {});
    }
  };

  const setIdVideoSelected = (idSelectedVideo: string) => {
    dispatch({ type: "set_video_id", payload: { idSelectedVideo } });
  };

  const selectVideo = (videoSelected: VideoDto) => {
    dispatch({ type: "select_video", payload: { videoSelected } });
  };

  const triggerReloadLessonEnrollmentAfterTest = (value: string) => {
    dispatch({
      type: "reset_lesson_enrollment_after_test",
      payload: { value },
    });
  };

  const triggerReloadCourseEnrollment = (value: string) => {
    dispatch({ type: "reload_course_enrollment", payload: { value } });
  };
  const triggerReloadLessonEnrollment = (value: string) => {
    dispatch({ type: "reload_lesson_enrollment", payload: { value } });
  };

  const handleFinishLessonAndGetNext = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    const object: LessonEnrollmentDto = result.obj ? result.obj : {};
    if (!object.hasOwnProperty("idlesson")) return;
    if (!object.idlesson) return;
    selectLesson(object.idlesson);
    if (
      !object.hasOwnProperty("courseenrollment") &&
      !object.courseenrollment
    ) {
      dispatch({
        type: "finish_lesson_and_get_next",
        payload: { lessonEnrollmentObject: object },
      });
    } else {
      dispatch({
        type: "set_current_lesson_enrollment",
        payload: {
          lessonEnrollmentObject: object,
          courseEnrollmentObject: object.courseenrollment,
          idCurrentLesson: object.idlesson,
        },
      });
    }
  };
  const finishLessonAndGetNext = (idLessonEnrollment: string) => {
    setLoadingArea(true);
    lessonEnrollmentService.finishLessonAndGetNext(
      idLessonEnrollment,
      handleFinishLessonAndGetNext,
      {}
    );
  };
  const setLoadingArea = (loadingLessonArea: boolean) => {
    dispatch({ type: "loading_lesson_area", payload: { loadingLessonArea } });
  };

  const handleGetLessonEnrollmentByLesson = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    const object: LessonEnrollmentDto = result.obj ? result.obj : {};
    const lesson: LessonDto = object.lesson ? object.lesson : null;
    let isSelectedLessonTheCurrentLesson = true;
    if (!lesson) isSelectedLessonTheCurrentLesson = true;
    else isSelectedLessonTheCurrentLesson = state.idCurrentLesson === lesson.id;

    dispatch({
      type: "get_lessen_enrollment_by_lesson",
      payload: {
        lessonEnrollmentObject: object,
        lesson,
        isSelectedLessonTheCurrentLesson,
      },
    });
  };

  const getLessonEnrollmentByLesson = (idLesson: string) => {
    if (!state.idCourseEnrollment) return;
    if (!state.idCourse) return;
    if (!student) return;
    if(!student.id) return;
    setLoadingArea(true);
    lessonEnrollmentService.getByIdLesson(
      idLesson,
      student.id,
      state.idCourse,
      state.idCourseEnrollment,
      handleGetLessonEnrollmentByLesson,
      {}
    );
  };

  const getCurrentLessonSelection = useCallback(() => {
    if (!state.lessonObjects) return;
    if (!state.idCurrentLesson) return;

    const lesson = state.lessonObjects.find(
      (x: LessonDto) => x.id === state.idCurrentLesson
    );
    if (!lesson) return;
    dispatch({
      type: "get_current_lesson",
      payload: { lesson },
    });
  }, [state.lessonObjects, state.idCurrentLesson]);

  const selectLesson = (id: string) => {
    if (!state.lessonObjects) return;
    if (!state.courseEnrollmentObject) return;
    const lesson = state.lessonObjects.find((x: LessonDto) => x.id === id);
    if (!lesson) return;
    dispatch({
      type: "set_lesson",
      payload: { idCurrentLesson: lesson.id, lesson },
    });
    const url =
      CommonTools.getCurseEnrollmentUrl(state.courseEnrollmentObject, id) +
      "/" +
      id;
    RouteTools.setHistory(url, {});
  };

  const handleGetLessonList = (result: ResultListDTO) => {
    if (!result) return;
    if (result.err) return;
    const objects = result.objects as Array<LessonDto>;
    dispatch({ type: "get_lesson_list", payload: { lessonObjects: objects } });
  };
  const getLessonList = () => {
    if (!state.idCourse) return;

    const requestList: RequestListDTO = new RequestListDTO();
    requestList.page = 1;
    requestList.onpage = parseInt(
      CC("DefaultOnPageStudentCourseLessonsList", "1000")
    );
    requestList.filters = [];
    const sortCriteria = new RequestSortCriteriaDTO();
    sortCriteria.field = "ordercriteria";
    sortCriteria.asc = true;
    requestList.sortcriteria = [sortCriteria];

    let f: RequestFilterDTO;

    f = new RequestFilterDTO();
    f.field = "status";
    f.values = [];
    f.values.push(Status.ACTIVE.toString());
    requestList.filters.push(f);

    f = new RequestFilterDTO();
    f.field = "idcourse";
    f.values = [state.idCourse];
    requestList.filters.push(f);

    lessonService.getList(handleGetLessonList, {}, requestList);
  };

  const handleGetCourseEnrollment = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    const object = result.obj as CourseEnrollmentDto;

    const idCourse = object.idcourse ? object.idcourse : "";
    dispatch({
      type: "get_course_enrollment",
      payload: { courseEnrollmentObject: object, idCourse },
    });
  };

  const getCourseEnrollment = (id: string) => {
    courseEnrollmentService.get(id, handleGetCourseEnrollment, {});
  };

  const handleGetCourse = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    const object = result.obj as CourseDto;
    dispatch({ type: "get_course", payload: { course: object } });
  };
  const getCourse = (id: string) => {
    courseService.get(id, handleGetCourse, {});
  };

  const setCurrentPath = (
    idCurrentLesson: string,
    idCourseEnrollment: string
  ) => {
    dispatch({
      type: "set_current_path",
      payload: { idCurrentLesson, idCourseEnrollment },
    });
  };

  const setCourseEnrollmentObject = (
    courseEnrollmentObject: CourseEnrollmentDto
  ) => {
    dispatch({
      type: "set_course_enrollment",
      payload: { courseEnrollmentObject },
    });
  };

  const handleGetCurrentLesson = (result: ResultObjectDTO) => {
    // logger("handleGetCurrentLesson", result);
    if (!result) return;
    if (result.err) return;
    const object: LessonEnrollmentDto = result.obj ? result.obj : {};
    if (
      object.hasOwnProperty("courseenrollment") &&
      object.courseenrollment &&
      object.hasOwnProperty("idlesson") &&
      object.idlesson
    ) {
      dispatch({
        type: "set_current_lesson_enrollment",
        payload: {
          lessonEnrollmentObject: object,
          courseEnrollmentObject: object.courseenrollment,
          idCurrentLesson: object.idlesson,
        },
      });
    } else if (object.hasOwnProperty("idlesson") && object.idlesson) {
      dispatch({
        type: "set_current_lesson_enrollment_without_course_enrollment",
        payload: {
          lessonEnrollmentObject: object,
          idCurrentLesson: object.idlesson,
        },
      });
    }
    if (object.hasOwnProperty("idlesson") && object.idlesson) {
      const url =
        CommonTools.getCurseEnrollmentUrl(
          state.courseEnrollmentObject,
          object.idlesson
        ) +
        "/" +
        object.idlesson;
      RouteTools.setHistory(url, {});
    }
  };

  const processDisabledInteraction = () => {
    if (!state.courseEnrollmentPaymentStatus) return true;
    if (state.courseEnrollmentPaymentStatus === Status.PAYMENT_UNPAID)
      return true;
    return false;
  };

  const enableLesson = (): boolean => {
    switch (state.courseEnrollmentStatus) {
      case Status.ENR_COURSE_READY: {
        return true;
      }
      case Status.ENR_NEED_END_TEST: {
        return true;
      }
      case Status.ENR_NEED_CHECKOUT: {
        return false;
      }
      case Status.ENR_NEED_START_TEST: {
        return false;
      }
      case Status.ENR_COURSE_FINISHED: {
        return true;
      }
      case Status.ENR_NEED_CERTIFICATE: {
        return true;
      }
      case Status.ENR_ARCHIVED: {
        return true;
      }
      case Status.ENR_CANCELED: {
        return false;
      }
      case Status.ENR_UN_FINISHED: {
        return false;
      }
      case Status.ENR_WITH_CERTIFICATE: {
        return true;
      }
      default: {
        return false;
      }
    }
  };

  const getCurrentLesson = useCallback(() => {
    if (state.idCurrentLesson) return;
    if (!state.courseEnrollmentStatus) return;
    if (!enableLesson()) return;
    if (!student) return;
    if (!state.idCourseEnrollment) return;
    if (!state.idCourse) return;
    if(!student.id) return;
    setLoadingArea(true);
    lessonEnrollmentService.getCurrentLesson(
      student.id,
      state.idCourse,
      state.idCourseEnrollment,
      handleGetCurrentLesson,
      {}
    );
  }, [
    state.courseEnrollmentStatus,
    state.idCourse,
    state.idCourseEnrollment,
    state.idCurrentLesson,
    student,
  ]);

  const getCurrentLessonAfterTest = useCallback(() => {
    if (!state.needReloadAfterLessonTest) return;
    if (!student) return;
    if (!state.idCourseEnrollment) return;
    if (!state.idCourse) return;
    if(!student.id) return;
    lessonEnrollmentService.getCurrentLesson(
      student.id,
      state.idCourse,
      state.idCourseEnrollment,
      handleGetCurrentLesson,
      {}
    );
  }, [state.needReloadAfterLessonTest]);

  const getCurrentLessonEnrollmentWhenIdCurrentLessonIsSet = useCallback(() => {
    if (!state.courseEnrollmentStatus) return;
    if (!enableLesson()) return;
    if (!state.idCurrentLesson) return;
    if (!student) return;
    if(!student.id) return;
    if (!state.idCourseEnrollment) return;
    if (!state.idCourse) return;
    setLoadingArea(true);
    lessonEnrollmentService.getByIdLesson(
      state.idCurrentLesson,
      student.id,
      state.idCourse,
      state.idCourseEnrollment,
      handleGetLessonEnrollmentByLesson,
      {}
    );
  }, [
    state.idCourse,
    state.idCourseEnrollment,
    state.idCurrentLesson,
    student,
    state.courseEnrollmentStatus,
  ]);

  const resetState = () => {
    dispatch({ type: "reset_state" });
  };

  return (
    <StudentCourseContext.Provider
      value={{
        state: state,
        actions: {
          getLessonList,
          getCourseEnrollment,
          getCourse,
          setCurrentPath,
          resetState,
          setCourseEnrollmentObject,
          getCurrentLesson,
          enableLesson,
          processDisabledInteraction,
          selectLesson,
          getCurrentLessonSelection,
          getLessonEnrollmentByLesson,
          getCurrentLessonEnrollmentWhenIdCurrentLessonIsSet,
          setLoadingArea,
          finishLessonAndGetNext,
          triggerReloadCourseEnrollment,
          triggerReloadLessonEnrollment,
          triggerReloadLessonEnrollmentAfterTest,
          getCurrentLessonAfterTest,
          selectVideo,
          setIdVideoSelected,
          getDefaultVideo,
        },
      }}
    >
      {children}
    </StudentCourseContext.Provider>
  );
};
