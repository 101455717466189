import Idto from "interfaces/idto.interface";

export default class MessageDto implements Idto {
  id?: string;
  code?: string;
  message?: string;
  mestype?: number;

  static prepareMessage(
    code: string,
    message: string,
    mestype: number
  ): MessageDto {
    var m = new MessageDto();
    m.code = code;
    m.message = message;
    m.mestype = mestype;
    return m;
  }
}
