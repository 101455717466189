import React from "react";

import { Box, Grid } from "@mui/material";
import { CourseDto } from "dto/course/course.dto";

import { TeacherFAQBlock } from "components/user/teacher/TeacherFAQBlock";

type Props = {
  obj: CourseDto;
};
const FAQTabBlock: React.FC<Props> = ({ obj }) => {
  //   const { LL } = useResource();

  //   const processCourseTestimonials = () => {
  //     if (!obj) return null
  //     if (!obj.hasOwnProperty("id")) return null
  //     if (!obj.id) return null

  //     return (
  //       <Box mt={5}>
  //         <Typography variant="h3">{LL("Testimonials")}</Typography>
  //         <CourseTestimonials idCourse={obj.id} />
  //       </Box>
  //     );
  //   };

  const processTeacherFAQ = () => {
    if (!obj) return null;
    if (!obj.id) return null;
    if (!obj.hasOwnProperty("idteacher")) return null;
    if (!obj.idteacher) return null;

    return <TeacherFAQBlock idCourse={obj.id} idTeacher={obj.idteacher} />;
  };

  return obj ? (
    <Box className="detailCourse">
      <Box className="container">
        <Grid container>
          <Grid item lg={9} sm={12}>
            {/* {processCourseTestimonials()} */}
            {processTeacherFAQ()}
          </Grid>
        </Grid>
      </Box>
    </Box>
  ) : null;
};

export { FAQTabBlock };
