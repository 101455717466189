import SelectOptions from 'dto/app/selectoptions.dto';
import Idto from 'interfaces/idto.interface';
import { CommonTools } from 'tools/utils/commontools';

export class FilterDto implements Idto {
    
    id?: string;
    idlanguage?: string;
    status?: number;
    ordercriteria?: number;
    name?: string;
    type?: number;
    idfilter?: string;

    static parseToSelectOptions(data: Array<FilterDto>): Array<SelectOptions> {
      if (!data) return [];
      if (!Array.isArray(data)) return [];
      if (!data.length) return [];
      let result: Array<SelectOptions> = [];
      data.forEach((element) => {
        result.push(FilterDto.parseOption(element));
      });
      return result;
    }
  
    static parseOption = (obj: FilterDto): SelectOptions => {
      return new SelectOptions(
        CommonTools.processObjectField(obj, ["id"]),
        CommonTools.processObjectField(obj, ["name"])
      );
    };
  
  }

  export class PostFilterDto implements Idto {
    
    idlanguage?: string;
    status?: number;
    ordercriteria?: number;
    name?: string;
    type?: number;
    idfilter?: string;
  }