import React from "react";
import { TestimonialDto } from "dto/user/testimonial.dto";
import { MyNavBt } from "components/elements/button/MyNavBt";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import Typography from "@mui/material/Typography";

import { MyRatingReadOnly } from "components/elements/MyRating/MyRatingReadOnly";
import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";
import { CommonTools } from "tools/utils/commontools";

type Props = {
  obj: TestimonialDto;
};

const TestimonialCard: React.FC<Props> = ({ obj, ...props }) => {
  const processRating = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("rating")) return null;
    if (!obj.rating) return null;
    return (
      <MyRatingReadOnly rating={obj.rating} count={0} showDetails={false} />
    );
  };

  const processTeacher = () => {
    if (!obj) return null;
    if (!obj.teacher) return null;
    if (!obj.teacher_fullname) return null;
    let fullUrl = "";
    if (obj.teacher.hasOwnProperty("fullurl") && obj.teacher.fullurl)
      fullUrl = obj.teacher.fullurl;
    if (!fullUrl) {
      return (
        <Typography className="teacher">
          {ComponentCommonTools.processObjectField(obj, "teacher_fullname")}
        </Typography>
      );
    }
    return (
      <MyNavBt href={fullUrl}>
        <Typography>
          {ComponentCommonTools.processObjectField(obj, "teacher_fullname")}
        </Typography>
      </MyNavBt>
    );
  };

  const processUserName = () => {
    if (!obj) return null;
    let fullUrl = "";
    if (obj.hasOwnProperty("user_fullurl") && obj.user_fullurl)
      fullUrl = obj.user_fullurl;

    if (!fullUrl) {
      return <Typography className="name">{processUserFullName()}</Typography>;
    }
    return (
      <MyNavBt href={fullUrl}>
        <Typography className="name">{processUserFullName()}</Typography>
      </MyNavBt>
    );
  };
  const processCourse = () => {
    if (!obj) return null;
    return (
      <Typography className="data">
        {CommonTools.processObjectField(obj, ["course", "name"])}
      </Typography>
    );
  };

  const processUserFullName = () => {
    if (!obj) return "";
    if (!obj.user_name) return "";
    if (!obj.user_surname) return obj.user_name;

    return obj.user_name + " " + obj.user_surname;
  };
  const processAvatar = () => {
    if (!obj) return null;
    let name = "";
    let surname = "";
    if (obj.hasOwnProperty("user_name") && obj.user_name) name = obj.user_name;
    if (obj.hasOwnProperty("user_surname") && obj.user_surname)
      surname = obj.user_surname;

    return <MyAvatar obj={obj} name={name} surname={surname} sx={{ mr: 1 }} />;
  };
  const processItem = () => {
    if (!obj) return null;

    return (
      <Box p={2} className="testimonials-home">
        <Card className="shadow-element">
          <CardContent sx={{ textAlign: "center" }}>
            <Box className="icon-quotes" />
            <Box
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "150px",
              }}
            >
              <Typography my={2} className="message">
                {CommonTools.processObjectField(obj, ["message"])}
              </Typography>
            </Box>
            <Box>{processRating()}</Box>
          </CardContent>
        </Card>
        <Box sx={{ display: "flex", justifyContent: "space-between" }} mt={3}>
          <Box sx={{ display: "flex" }}>
            <Box mr={1}>{processAvatar()}</Box>
            <Box>
              {processUserName()}
              <Typography className="data">
                {ComponentCommonTools.processObjectField(obj, "date_name")}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ textAlign: "right" }}>
            {processCourse()}
            {processTeacher()}
          </Box>
        </Box>
      </Box>
    );
  };

  return processItem();
};

export { TestimonialCard };
