import { Link, Skeleton } from "@mui/material";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CertificateDto } from "dto/course/certificate.dto";
import { useResource } from "hooks/useResource";
import React, { useState, useEffect, useContext } from "react";
import { StudentCourseContext } from "context/studentcourse.context";
import { CertificateService } from "services/course/certificate.service";
import { Status } from "tools//types/status";

const service = new CertificateService();
const PreviewCertificate: React.FC = () => {
  const {
    state: { idCourseEnrollment, courseEnrollmentStatus },
  } = useContext(StudentCourseContext);

  const { LL } = useResource();

  const [obj, setObj] = useState<CertificateDto | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  const getObject = () => {
    if (!idCourseEnrollment) return;
    if (!courseEnrollmentStatus) return;
    if (
      courseEnrollmentStatus === Status.ENR_ARCHIVED ||
      courseEnrollmentStatus === Status.ENR_WITH_CERTIFICATE
    ) {
      setLoading(true);
      service.getByCourseEnrollment(
        idCourseEnrollment,
        loadObject,
        {}
      );
    }
  };
  const loadObject = (obj: ResultObjectDTO) => {
    if (!obj) return;
    if (!obj.err) {
      const object: CertificateDto = obj.obj ? obj.obj : {};
      setObj(object);
    }
  };

  useEffect(() => {
    getObject();
  }, [idCourseEnrollment, courseEnrollmentStatus]);

  const checkIsLoading = () => {
    let load: boolean = false;
    if (obj === undefined) load = true;
    setLoading(load);
  };

  useEffect(() => {
    checkIsLoading();
  }, [obj]);

  const processComponent = () => {
    if (!idCourseEnrollment) return null
    if (!courseEnrollmentStatus) return null
    if (loading) return <Skeleton variant="rectangular" height={50} />;
    if (!obj) return null
    let url = "";
    if (!obj.hasOwnProperty("validationurl") || !obj.validationurl) url = "";
    else url = obj.validationurl;
    if (
      courseEnrollmentStatus === Status.ENR_ARCHIVED ||
      courseEnrollmentStatus === Status.ENR_WITH_CERTIFICATE
    ) {
      return (
        <Link href={url} rel="noreferrer" target="_blank">
          {LL("Preview_certificate")}
        </Link>
      );
    } else return null
  };
  return processComponent();
};

export { PreviewCertificate };
