import React from "react";

import { AdvertisementDto } from "dto/static/advertisement.dto";

import { Types } from "tools/types/types";

import { AdvertisementBannerItemTypeBanner } from "components/static/advertisement/AdvertisementBannerItemTypeBanner";
import { AdvertisementBannerItemSpecialHomeSection2 } from "components/static/advertisement/AdvertisementBannerItemSpecialHomeSection2";
import { AdvertisementBannerItemSpecialHomeSection1 } from "components/static/advertisement/AdvertisementBannerItemSpecialHomeSection1";
import { AdvertisementBannerItemTypeImageRight } from "components/static/advertisement/AdvertisementBannerItemTypeImageRight";
import { AdvertisementBannerItemTypeImageLeft } from "components/static/advertisement/AdvertisementBannerItemTypeImageLeft";
import { AdvertisementBannerItemHomeBenefits } from "components/static/advertisement/AdvertisementBannerItemHomeBenefits";
import { AdvertisementBannerItemHomeSuggestions } from "components/static/advertisement/AdvertisementBannerItemHomeSuggestions";
import { AdvertisementBannerItemBecomeTeacherSection1 } from "./AdvertisementBannerItemBecomeTeacherSection1";
import { AdvertisementBannerItemBecomeTeacherSection5 } from "./AdvertisementBannerItemBecomeTeacherSection5";

type AdvertisementBannerItemProps = {
  obj: AdvertisementDto;
};
const AdvertisementBannerItem: React.FC<AdvertisementBannerItemProps> = ({
  obj,
  ...props
}) => {
  const processItem = () => {
    if (!obj) return null

    if (obj.type === Types.ADVERTISEMENT_TYPE_BANNER) {
      return <AdvertisementBannerItemTypeBanner obj={obj} />;
    }

    if (
      obj.type === Types.ADVERTISEMENT_TYPE_SPECIAL &&
      obj.section === Types.SECTION_HOME_1
    ) {
      return <AdvertisementBannerItemSpecialHomeSection1 obj={obj} />;
    }

    if (
      obj.type === Types.ADVERTISEMENT_TYPE_SPECIAL &&
      obj.section === Types.SECTION_HOME_2
    ) {
      return <AdvertisementBannerItemSpecialHomeSection2 obj={obj} />;
    }

    if (
      obj.type === Types.ADVERTISEMENT_TYPE_SPECIAL &&
      obj.section === Types.SECTION_BENEFITS
    ) {
      return <AdvertisementBannerItemHomeBenefits obj={obj} />;
    }

    if (
      obj.type === Types.ADVERTISEMENT_TYPE_SPECIAL &&
      obj.section === Types.SECTION_SUGGESTIONS
    ) {
      return <AdvertisementBannerItemHomeSuggestions obj={obj} />;
    }

    if (obj.type === Types.ADVERTISEMENT_TYPE_BANNER_IMAGE_RIGHT) {
      return <AdvertisementBannerItemTypeImageRight obj={obj} />;
    }

    if (obj.type === Types.ADVERTISEMENT_TYPE_BANNER_IMAGE_LEFT) {
      return <AdvertisementBannerItemTypeImageLeft obj={obj} />;
    }

    if (
      obj.type === Types.ADVERTISEMENT_TYPE_BECOME_TEACHER &&
      obj.section === Types.SECTION_BECOME_TEACHER_1
    ) {
      return <AdvertisementBannerItemBecomeTeacherSection1 obj={obj} />;
    }

    if (
      obj.type === Types.ADVERTISEMENT_TYPE_BECOME_TEACHER &&
      obj.section === Types.SECTION_BECOME_TEACHER_2
    ) {
      return <AdvertisementBannerItemHomeSuggestions obj={obj} />;
    }

    if (
      obj.type === Types.ADVERTISEMENT_TYPE_BECOME_TEACHER &&
      obj.section === Types.SECTION_BECOME_TEACHER_3
    ) {
      return <AdvertisementBannerItemHomeBenefits obj={obj} />;
    }

    if (
      obj.type === Types.ADVERTISEMENT_TYPE_BECOME_TEACHER &&
      obj.section === Types.SECTION_BECOME_TEACHER_4
    ) {
      return <AdvertisementBannerItemTypeBanner obj={obj} />;
    }

    if (
      obj.type === Types.ADVERTISEMENT_TYPE_BECOME_TEACHER &&
      obj.section === Types.SECTION_BECOME_TEACHER_5
    ) {
      return <AdvertisementBannerItemBecomeTeacherSection5 obj={obj} />;
    }

    if (
      obj.type === Types.ADVERTISEMENT_TYPE_SPECIAL &&
      obj.section === Types.SECTION_CREATE_NEW_COURSE
    ) {
      return <AdvertisementBannerItemTypeImageLeft obj={obj} />;
    }

    return null
  };

  return processItem();
};

export { AdvertisementBannerItem };
