import React, { useEffect, useState } from "react";


import { AdvertisementDto } from "dto/static/advertisement.dto";

import { ComponentCommonTools } from "tools/utils/componentcommontools";

import { Box } from "@mui/material";

import { MyNavBt } from "components/elements/button/MyNavBt";
import { CommonTools } from "tools/utils/commontools";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

type BannerItemSpecialHomeSection1Props = {
  obj: AdvertisementDto;
};
const AdvertisementBannerItemHomeBenefits: React.FC<
  BannerItemSpecialHomeSection1Props
> = ({ obj, ...props }) => {
  const [imgUrl, setImgUrl] = useState<string>("");
  const [fullUrl, setFullUrl] = useState<string>("");
  const width = 50;
  const height = 50;

  const prepareGallery = () => {
    if (!obj) return;

    if (!obj.hasOwnProperty("gallery")) return;
    if (!obj.gallery) return;
    if (obj.gallery.length === 0) return;
    const galleryObj = CommonTools.getGalleryObjectLogo(obj.gallery);
    if (!galleryObj) return;
    CommonTools.setFileUrlsGallery(
      galleryObj,
      setImgUrl,
      setFullUrl,
      width,
      height
    );
  };

  useEffect(() => {
    prepareGallery();
  }, [obj]);

  const processImage = () => {
    if (!imgUrl) return null;
    return ComponentCommonTools.getFileBlock(imgUrl, width, height);
  };

  // if (obj.type == Types.ADVERTISEMENT_TYPE_SPECIAL) {
  //     return (
  //         <Box className="container">
  //             <Grid container spacing={2}>
  //                 <Grid item md={4} sm={12}>{processImage()}</Grid>
  //                 <Grid item md={6} sm={12}>
  //                     {processDetail(obj)}
  //                     {processButton()}
  //                 </Grid>
  //             </Grid>
  //         </Box>
  //     );
  // }

  const processDetail = (obj: AdvertisementDto) => {
    if (!obj) return null;
    return (
      <Box>
        <Typography variant="h5">
          {ComponentCommonTools.processObjectField(obj, "name")}
        </Typography>
        <Typography>
          {ComponentCommonTools.processObjectField(obj, "description")}
        </Typography>
      </Box>
    );
  };

  const processWithFullUrl = (obj: AdvertisementDto) => {
    if (!obj) return null;

    if (!imgUrl) {
      return (
        <Box className="bennefitsItem">
          <MyNavBt href={obj.fullurl}>{processDetail(obj)}</MyNavBt>
        </Box>
      );
    }

    return (
      <Stack
        direction="row"
        spacing={2}
        className="bennefitsItem"
        sx={{ alignItems: "center" }}
      >
        {processImage()}
        <Box>{processDetail(obj)}</Box>
      </Stack>
    );
  };

  const processItem = () => {
    if (!obj) return null;

    // if (obj.hasOwnProperty("fullurl") && obj.fullurl) {
    //   return <MyNavBt href={obj.fullurl}>{processWithFullUrl(obj)}</MyNavBt>;
    // }

    return processWithFullUrl(obj);
  };

  return processItem();
};

export { AdvertisementBannerItemHomeBenefits };
