import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hooks/useResource";

import { MyNavBt } from "components/elements/button/MyNavBt";
import { IdCardIcon } from "components/elements/icon/IdCardIcon";
import Box from "@mui/material/Box";
import { useAuth } from "hooks/useAuth";

const SignUpTeacherBlock: React.FC<PageComponentProps> = () => {
  const { LL } = useResource();
  const { teacher } = useAuth();
  return teacher ? null : (
    <MyNavBt href={"/becometeacherpage"} className="btnTeachTop">
      <IdCardIcon />
      <Box sx={{ display: "flex", flexDirection: "column", ml: 1 }}>
        <div>{LL("bt_top_teach")}</div>
        <div>{LL("bt_top_liveclass")}</div>
      </Box>
    </MyNavBt>
  );
};

export { SignUpTeacherBlock };
