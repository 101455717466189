import React, { useEffect, useState } from "react";

import { CourseCategoryDto } from "dto/course/coursecategory.dto";

import { MyNavBt } from "components/elements/button/MyNavBt";

import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import Typography from "@mui/material/Typography";
import { CommonTools } from "tools/utils/commontools";
import CardMedia from "@mui/material/CardMedia";

type InfoCourseCategoryCardProps = {
  obj: CourseCategoryDto;
  withImage?: boolean;
};

const InfoCourseCategoryCard: React.FC<InfoCourseCategoryCardProps> = ({
  obj,
  withImage = true,
  ...props
}) => {
  const [imgUrl, setImgUrl] = useState<string>("");
  const [fullUrl, setFullUrl] = useState<string>("");

  const prepareGallery = () => {
    if (!obj) return;

    if (!obj.hasOwnProperty("gallery")) return;
    if (!obj.gallery) return;
    if (obj.gallery.length === 0) return;
    const galleryObj = CommonTools.getGalleryObjectLogo(obj.gallery);
    if (!galleryObj) return;
    CommonTools.setFileUrlsGallery(galleryObj, setImgUrl, setFullUrl, 200, 150);
  };

  useEffect(() => {
    prepareGallery();
  }, [obj]);

  const processImage = () => {
    if (!obj) return <></>;
    if (!withImage) return <></>;
    return <>{ComponentCommonTools.getFileBlock(imgUrl, 310, 390)}</>;
  };

  const processDetail = () => {
    if (!obj) return null;

    return (
      <Card className="card">
        <CardMedia>{processImage()}</CardMedia>
        <CardContent>
          <Typography component="div" className="name" variant="h5">
            {ComponentCommonTools.processObjectField(obj, "name")}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const processItem = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("fullurl")) return processDetail();

    return obj.fullurl ? (
      <MyNavBt href={obj.fullurl}>{processDetail()}</MyNavBt>
    ) : (
      processDetail()
    );
  };

  return processItem();
};

export { InfoCourseCategoryCard };
