import React from "react";
import { MyNavBt } from "components/elements/button/MyNavBt";
import { useResource } from "hooks/useResource";
import { Alert } from "@mui/material";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

const NeedCheckout: React.FC<PageComponentProps> = () => {
  const { LL } = useResource();
  return (
    <Alert severity="error" className="alertZone">
      <MyNavBt href={"/checkout"} className="btnAlert">
        {LL("Go_Checkout")}
      </MyNavBt>
    </Alert>
  );
};

export { NeedCheckout };
