import { Box } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { ClassroomContext } from "context/classroom.context";
export const ClassroomVideoPlayer: React.FC<PageComponentProps> = ({
  currentRoute,
}) => {
  const {
    state: { videoSelected, selectedLessonObject },
    actions: { getDefaultVideo },
  } = useContext(ClassroomContext);

  useEffect(() => {
    if (!selectedLessonObject) return;

    getDefaultVideo();
  }, [selectedLessonObject]);

  const processComponent = () => {
    if (!videoSelected) return null;
    return (
      <Box>
        {ComponentCommonTools.getFileBlockVideo(
          videoSelected,
          "100%",
          600,
          true
        )}
      </Box>
    );
  };

  return processComponent();
};
