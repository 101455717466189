import { Box, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import MyTextField from "components/form/mytextfield";
import { PostTeacherRequestDto } from "dto/user/teacherrequest.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React from "react";
import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObj: PostTeacherRequestDto;
  onSubmit: (obj: PostTeacherRequestDto) => void;
  loading: boolean;
};

const TeacherRequestForm: React.FC<Props> = ({
  defaultObj,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField] = useForm<PostTeacherRequestDto>(
    defaultObj,
    RequiredValidator.getValidators(["idteacher", "teachermessage"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <Typography variant="h6" mb={1}>
            {LL("Message")}
          </Typography>
          <MyTextField
            multiline
            minRows={4}
            tabIndex={0}
            fullWidth
            id="teachermessage"
            name="teachermessage"
            variant="standard"
            color="primary"
            className="form-input"
            _label={LL("teachermessage")}
            size="small"
            value={obj.teachermessage}
            setObj={setObjField}
            _vresults={undefined}
          />
        </Box>
        <Box mt={3} sx={{ textAlign: "center" }}>
          <MyButton
            disabled={disabled || loading}
            color="primary"
            variant="contained"
            size="large"
            type="submit"
            className="btn"
          >
            {LL("Bt_send_request")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { TeacherRequestForm };
