import Idto from "interfaces/idto.interface";

import { CourseCategoryDto } from "./coursecategory.dto";
import { CourseDto } from "./course.dto";

export class LinkCourseCategoryDto implements Idto {
  id?: string;
  idcourse?: string;
  idcoursecategory?: string;

  course?: any;
  coursecategory?: any;
}

export class LinkCourseCategoryFormDto implements Idto {
  id?: string;
  idcourse?: string;
  idcoursecategory?: string;

  course?: CourseDto;
  coursecategory?: CourseCategoryDto;

  constructor(
    id?: string,
    idcourse?: string,
    idcoursecategory?: string,
    coursecategory?: CourseCategoryDto,
    course?: CourseDto
  ) {
    this.id = id || "";
    this.idcourse = idcourse || "";
    this.idcoursecategory = idcoursecategory || "";
    this.coursecategory = coursecategory;
    this.course = course;
  }

  static parseFromLinkCourseCategoryForm = (
    obj: LinkCourseCategoryDto
  ): LinkCourseCategoryFormDto => {
    return new LinkCourseCategoryFormDto(
      obj.id,
      obj.idcourse,
      obj.idcoursecategory,
      obj.coursecategory,
      obj.course
    );
  };
}

export class PostLinkCourseCategoryDto implements Idto {
  idcourse?: string;
  idcoursecategory?: string;
}
