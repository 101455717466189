import React, { useState } from "react";

import { useResource } from "hooks/useResource";

import { CourseDto, EditGeneralInfoCourseDto } from "dto/course/course.dto";
import { CourseService } from "services/course/course.service";
import { useMessage } from "hooks/useMessage";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { Box } from "@mui/material";
import { FormGeneralInfo } from "./FormGeneralInfo";


const service = new CourseService();

type Props = {
  currentRoute: any;
  defaultObj: CourseDto;
  setObjectDefault: (obj: CourseDto) => void;
};
const EditGeneralInfo: React.FC<Props> = ({
  currentRoute,
  defaultObj,
  setObjectDefault,
}) => {
  const { LL } = useResource();

  const { registerDialog } = useMessage();
  const id = currentRoute._paths !== undefined ? currentRoute._paths[2] : "";
  const [loading, setLoading] = useState<boolean>(false);
  const [object, setObject] = useState<EditGeneralInfoCourseDto>(
    EditGeneralInfoCourseDto.fromCourseDto(defaultObj)
  );

  // const [isFormDisabled, setIsFormDisabled] = useState(true);

  // const [validators, setValidators] = useState(false);
  // const [vresults, setVResults] = useState<any>(false);

  // const validateAll = () => {
  //     if (!obj) return;
  //     // if (!validators) return;
  //     // if (vresults) return;
  //     // logger('validateAllEditGeneral', obj);
  //     obj.name = obj.name !== undefined ? obj.name : '';
  //     obj.description = obj.description !== undefined ? obj.description : '';
  //     obj.requirementstext = obj.requirementstext !== undefined ? obj.requirementstext : '';
  //     obj.whatwillyoulearntext = obj.whatwillyoulearntext !== undefined ? obj.whatwillyoulearntext : '';
  //     obj.targetaudiencetext = obj.targetaudiencetext !== undefined ? obj.targetaudiencetext : '';
  //     obj.shortdescription = obj.shortdescription !== undefined ? obj.shortdescription : '';
  //     obj.idlanguage = obj.idlanguage !== undefined ? obj.idlanguage : '';

  //     setObj(obj);

  //     validateField("name", obj.name);
  //     // t = validateField("description", obj.description, t);
  // };

  // const validateField = (field: any, value: any, v?: any) => {
  //     v = (v) ? v : JSON.parse(JSON.stringify(vresults));
  //     if (v === false) v = {};
  //     v[field] = ValidateTools.validate(field, value, validators, vresults);
  //     setVResults(v);
  //     return v;
  // };

  // const processValidators = () => {
  //     let v: any = {
  //         name: [],
  //         // description: [],
  //     };

  //     v.name.push(new RequiredValidator('', ''));
  //     // v.description.push(new RequiredValidator(LL('Required field description', LabelTypes.LABEL_VALIDATION), ''));
  //     setValidators(v);
  // };

  // const checkLoading = () => {
  //     if (!loading) return;
  //     if (!obj) return;
  //     if (!validators) return;
  //     validateAll();
  //     setLoading(false);
  // };

  // useEffect(() => {
  //     processValidators();
  //     validateAll();
  // }, [objectWasChanged]);

  // useEffect(() => {
  //     checkLoading();
  // }, [obj, validators]);

  // useEffect(() => {
  //     checkIsFormDisabled();
  // }, [vresults]);

  // const setObjField = (field: any, value: any) => {
  //     let t = JSON.parse(JSON.stringify(obj));
  //     t[field] = value;
  //     setObj(t);

  //     validateField(field, value);
  // };

  // const checkIsFormDisabled = () => {
  //     var rez = ValidateTools.vForm(vresults, 'name');
  //     if (obj.name !== undefined) rez = false;
  //     setIsFormDisabled(rez);
  // };

  const onSubmit = (obj: EditGeneralInfoCourseDto) => {
    if (id) {
      obj.id = id;

      registerDialog(
        LL("Confirm_edit_course"),
        LL(
          "Are_you_sure_you_want_to_edit_this_course_editing_will_change_the_status"
        ),
        { cb: handleConfirmEdit.bind(null, obj) },
        null
      );
    }
  };

  const handleConfirmEdit = (object: EditGeneralInfoCourseDto) => {
    if (!object) return;
    if (!object.id) return;
    setLoading(true);
    service.updateGeneralInfo(handleResult, {}, object);
  };

  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    setObject(EditGeneralInfoCourseDto.fromCourseDto(result.obj));
    setObjectDefault(result.obj);
    setLoading(false);
  };

  if (!defaultObj) return null;
  if (!object) return null;
  return (
    <Box>
      <FormGeneralInfo
        defaultObj={object}
        onSubmit={onSubmit}
        loading={loading}
      />
    </Box>
  );

  // return !loading && obj ? (
  //     <>

  //             <form onSubmit={handleSubmit}>
  //                 <Typography variant="h6" mb={1}>
  //                         {LL('Numele cursului')}
  //                     </Typography>
  //                 <MyTextField
  //                     tabIndex={0}
  //                     fullWidth
  //                     id="name"
  //                     name="name"
  //                     variant="standard"
  //                     color="primary"
  //                     className='form-input'
  //                     _label={LL("Name")}

  //                     value={obj.name}
  //                     setObj={setObjField}
  //                     _vresults={
  //                         vresults && vresults.name ? vresults.name : false
  //                     }
  //                 />
  //                 <Box mt={3} className="myCk">
  //                     <Typography variant="h6" mb={1}>
  //                         {LL('Descrierea cursului')}
  //                     </Typography>
  //                     <MyCKEditor
  //                         data={obj.description ? obj.description : ''}
  //                         setObj={setObjField}
  //                         name="description"
  //                     />
  //                 </Box>
  //                 <Box mt={3}>
  //                     <Typography variant="h6" mb={1}>
  //                         {LL('requirementstext')}
  //                     </Typography>
  //                     <MyCKEditor
  //                         data={obj.requirementstext ? obj.requirementstext : ''}
  //                         setObj={setObjField}
  //                         name="requirementstext"
  //                     />
  //                 </Box>
  //                 <Box mt={3}>
  //                     <Typography variant="h6" mb={1}>
  //                         {LL('whatwillyoulearntext')}
  //                     </Typography>
  //                     <MyCKEditor
  //                         data={obj.whatwillyoulearntext ? obj.whatwillyoulearntext : ''}
  //                         setObj={setObjField}
  //                         name="whatwillyoulearntext"
  //                     />
  //                 </Box>
  //                 <Box mt={3}>
  //                     <Typography variant="h6" mb={1}>
  //                         {LL('targetaudiencetext')}
  //                     </Typography>
  //                     <MyCKEditor
  //                         data={obj.targetaudiencetext ? obj.targetaudiencetext : ''}
  //                         setObj={setObjField}
  //                         name="targetaudiencetext"
  //                     />
  //                 </Box>
  //                 <Box mt={3} className="myCk">
  //                     <Typography variant="h6" mb={1}>
  //                         {LL("Descriere scurta")}
  //                     </Typography>
  //                     <MyCKEditor
  //                         data={obj.shortdescription ? obj.shortdescription : ''}
  //                         setObj={setObjField}
  //                         name="shortdescription"
  //                     />
  //                 </Box>
  //                 <Box sx={{ mt: 3 }}>
  //                  <Typography variant="h6" mb={1}>
  //                         {LL("Selecteaza limba cursului")}
  //                     </Typography>
  //                     <LanguageSelect
  //                         tabIndex={4}
  //                         id="idlanguage"
  //                         name="idlanguage"
  //                         variant="standard"
  //                         className='form-select'
  //                         color="primary"
  //                         value={obj.idlanguage}
  //                         field="idlanguage"
  //                         setObj={setObjField}
  //                         obj={obj}
  //                         _defaultValue={obj.idlanguage}
  //                     />
  //                 </Box>
  //                 <Box sx={{ mt: 3 }} textAlign='center'>
  //                 <MyButton
  //                     tabIndex={5}
  //                     disabled={isFormDisabled}
  //                     color="primary"
  //                     variant="contained"
  //                     size="large"
  //                     className="btn"
  //                     type="submit"
  //                     sx={{ mt: 4 }}
  //                     children={LL("Bt_Submit")}
  //                 />
  //                 </Box>
  //             </form>

  //     </>
  // ) : (
  //     <CircularLoading />
  // );
};

export { EditGeneralInfo };
