// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// import { PopulateTypeDto } from "dto/nomenclature/populatetype.dto";
// import { PostNomenclatureDto } from "dto/nomenclature/postnomenclature.dto";
// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import { PhoneRepository } from "repositories/user/phone.repository";
// import { Status } from "tools//types/status";

// import GeneralService from "services/general.service";
// export default class PhoneService extends GeneralService implements IService {
//   phoneRepository: PhoneRepository = new PhoneRepository();
//   constructor() {
//     super();
//     this.handleGetList = this.handleGetList.bind(this);
//     this.handleGet = this.handleGet.bind(this);
//     this.handleGetSpecial = this.handleGetSpecial.bind(this);
//   }

//   handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;

//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async update(
//     id: string,
//     cb?: any,
//     cbparameters?: any,
//     data?: any
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.phoneRepository.update(id, this.handleUpdate, data, cbparameters);
//   }

//   handleDelete(result?: any, cbparameters?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters);
//     }
//   }

//   async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.phoneRepository.delete(id, this.handleDelete, cbparameters);
//   }

//   handleGet(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;
//     this.populateObject(rez.obj, result.obj, [
//       { key: "status", type: "status" },
//     ]);
//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.phoneRepository.get(id, this.handleGet, cbparameters);
//     if (cb == undefined) {
//       return this.handleGet(t);
//     }
//   }

//   async getPhonesByUserId(
//     id: string,
//     cb?: any,
//     cbparameters?: any
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.phoneRepository.getPhonesByUserId(
//       id,
//       this.handleGetSpecial,
//       cbparameters
//     );
//   }

//   handleGetSpecial(result?: any, cbparameters?: any, data?: any): any {
//     let rez: any;
//     rez = result;
//     if (result.err) return;

//     if (result.obj && rez.obj) {
//       for (let index = 0; index < result.obj.length; index++) {
//         const obj = result.obj[index];
//         if (rez.obj[index]) {
//           this.populateObject(rez.obj[index], obj, [
//             { key: "status", type: "status" },
//           ]);
//         }
//       }
//     }
//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   handleGetList(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();

//     rez = result;
//     if (result.err) return;
//     this.parseResultPopulate(result, rez, [{ key: "status", type: "status" }]);

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async getList(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.phoneRepository.getList(
//       this.handleGetList,
//       cbparameters,
//       data
//     );
//     if (cb == undefined) {
//       return this.handleGetList(t);
//     }
//   }

//   handleAdd(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async add(cb?: any, cbparameters?: any, data?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.phoneRepository.add(this.handleGetList, cbparameters, data);
//   }

//   getDefaultRequestListSelect(onPage?: number): RequestListDTO {
//     onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
//     if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
//     var defaultRequestList = new RequestListDTO();
//     defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
//     defaultRequestList.onpage = onPage;
//     // var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
//     // defaultRequestSortCriteria.asc = true;
//     // defaultRequestSortCriteria.field = 'name';
//     // defaultRequestList.sortcriteria = []
//     // defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
//     return defaultRequestList;
//   }
//   getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
//     if (
//       !obj.sortcriteria ||
//       !Array.isArray(obj.sortcriteria) ||
//       !obj.sortcriteria.length
//     ) {
//       var sobj = new RequestSortCriteriaDTO();
//       sobj.asc = true;
//       sobj.field = "phonenumber";

//       obj.sortcriteria = [];
//       obj.sortcriteria?.push(sobj);
//     }

//     return obj;
//   }
// }

import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import { PhoneRepository } from "repositories/user/phone.repository";
import RequestListDTO from "dto/app/requestlist.dto";

export class PhoneService
  extends GeneralService<PhoneRepository>
  implements IService
{
  constructor() {
    super(
      new PhoneRepository(),
      [{ key: "status", type: "status" }],
      "phonenumber"
    );
  }
  getPhonesByUserId = async (
    id: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getPhonesByUserId(
      id,
      this.handleGet,
      cbparameters
    );
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  getDefaultRequestListSelect(onPage?: number): RequestListDTO {
    onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    var defaultRequestList = new RequestListDTO();
    defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    defaultRequestList.onpage = onPage;
    return defaultRequestList;
  }
}
