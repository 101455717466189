import Idto from "interfaces/idto.interface";
import MessageDto from "dto/app/message.dto";
import ResultMetaDTO from "./resultmeta.dto";

export default class ResultObjectDTO<T = Idto> implements Idto {
  err?: boolean;
  messages?: MessageDto[];
  obj?: T;
  meta?: ResultMetaDTO;
}
