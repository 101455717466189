import React from "react";
import { useResource } from "hooks/useResource";

import Stack from "@mui/material/Stack";

import Box from "@mui/material/Box";

import { MyButton } from "components/elements/button/MyButton";
import { RouteTools } from "tools/utils/routetools";

import { MyGoogleLoginButton } from "./openids/MyGoogleLoginButton";
import { MyFaceBookLoginButton } from "./openids/MyFaceBookLoginButton";
import { Typography } from "@mui/material";

type Props = {
  isLoginPage: boolean;
};

const AuthMethodsBlock: React.FC<Props> = ({ isLoginPage }) => {
  const { LL } = useResource();

  const goToSignUp = () => {
    RouteTools.setHistory("/signup", {});
  };

  const goToLogin = () => {
    RouteTools.setHistory("/login", {});
  };

  const processButtons = () => {
    return isLoginPage ? (
      <MyButton
        type="button"
        children={LL("Bt_Register")}
        cb={goToSignUp}
        className="btn"
        color="primary"
        variant="outlined"
        fullWidth
      />
    ) : (
      <MyButton
        type="button"
        children={LL("Bt_Login")}
        cb={goToLogin}
        variant="outlined"
        className="btn"
        color="primary"
        fullWidth
      />
    );
  };
  return (
    <Box className="registerButtonBox">
      {processButtons()}
      <Typography className="orDifferentOption">
        {LL("orDifferentOption")}
      </Typography>

      <Stack
        spacing={2}
        alignItems="center"
        direction="row"
        sx={{ my: 0, mb: 4 }}
        justifyContent="center">
        <MyFaceBookLoginButton />
        <MyGoogleLoginButton />
      </Stack>
    </Box>
  );
};

export { AuthMethodsBlock };
