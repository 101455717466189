import React from "react";

import Box from "@mui/material/Box";
import RequestListDTO from "dto/app/requestlist.dto";
import { TestimonialDto } from "dto/user/testimonial.dto";
import { TestimonialService } from "services/user/testimonial.service";
import { Status } from "tools//types/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { useResource } from "hooks/useResource";

import Skeleton from "@mui/material/Skeleton";

import { CourseDetailTestimonialCard } from "components/user/testimonial/CourseDetailTestimonialCard";
import { CourseDto } from "dto/course/course.dto";
import { useList } from "hooks/useList";

import { CallbackType } from "interfaces/commontypes.interface";

const service = new TestimonialService();

interface Props {
  idCourse: string;
}

const CourseTestimonials: React.FC<Props> = ({ idCourse, ...props }) => {
  const { CC } = useResource();
  // const [isLoading, setIsLoading] = useState(true);
  // const [objects, setObjects] = useState<Array<TestimonialDto>>([]);

  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    if (!idCourse) return;
    service.getList(cb, cbParams, req);
  };

  const [loading, objects] = useList<CourseDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("idcourse", [idCourse]),
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
      ],
      1,
      parseInt(CC("CourseTestimonial_OnPage_Show", "10"))
    ),
    [idCourse]
  );

  // const getList = () => {
  //   const reqList = new RequestListDTO();
  //   reqList.onpage = parseInt(CC("CourseTestimonial_OnPage_Show", "10"));
  //   reqList.page = 1;
  //   reqList.filters = [];

  //   let f: RequestFilterDTO;

  //   f = new RequestFilterDTO();
  //   f.field = "status";
  //   f.values = [];
  //   f.values.push(Status.ACTIVE.toString());
  //   reqList.filters.push(f);

  //   f = new RequestFilterDTO();
  //   f.field = "idcourse";
  //   f.values = [idCourse];
  //   reqList.filters.push(f);

  //   setIsLoading(true);

  //   service.getList(loadObjects, {}, reqList);
  // };
  // const loadObjects = (result: ResultListDTO) => {
  //   if (!result) return;
  //   if (!result.err) {
  //     const objects = result.objects ? result.objects : [];
  //     // const total = result.total !== undefined ? result.total : -1;
  //     // const totalPage = result.totalpages !== undefined ? result.totalpages : -1;
  //     // const reqInfo = result.requestinfo ? result.requestinfo : {};
  //     setObjects(objects);
  //     // setTotal(total);
  //     // setTotalPage(totalPage);
  //     // setResponseParams(reqInfo);
  //   }
  // };

  // const checkLoading = () => {
  //   let load = false;

  //   // if (total === -1) return true;
  //   // if (totalPage === -1) return true;
  //   if (!objects) load = true;
  //   // if (objects.length === 0) load = true;
  //   // if (Object.keys(responseParams).length === 0) return true;

  //   setIsLoading(load);
  // };

  // useEffect(() => {
  //   if (!idCourse) return;
  //   getList();
  // }, [idCourse]);

  // useEffect(() => {
  //   checkLoading();
  // }, [objects]);

  const processTestimonialItem = (obj: TestimonialDto, index: number) => {
    if (!obj) return null;

    return (
      <Box key={index}>
        <CourseDetailTestimonialCard obj={obj} />
      </Box>
    );
  };

  const processTestimonialList = () => {
    if (!objects) return null;
    if (!objects.length) return null;

    return (
      <Box className="courseReview">
        {objects.map((item: TestimonialDto, i: number) => {
          return processTestimonialItem(item, i);
        })}
      </Box>
    );
  };

  return loading ? <Skeleton /> : processTestimonialList();
};

export { CourseTestimonials };
