import { Box, Typography } from "@mui/material";
import { useResource } from "hooks/useResource";
import React from "react";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

type NoResultProps = {
  identifier: string;
};

const NoResult: React.FC<NoResultProps> = ({ identifier, ...props }) => {
  const { LL, CF } = useResource();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      mt={2}>
      <Box>
        {ComponentCommonTools.getFileBlock(
          CF("no_result_" + identifier),
          200,
          200
        )}
      </Box>
      <Box>
        <Typography variant="h6" textAlign="center">
          {LL("no_result_" + identifier)}
        </Typography>
      </Box>
    </Box>
  );
};

export { NoResult };
