import React, { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { useReactPath } from "hooks/useReactPath";

import { RouteTools } from "tools/utils/routetools";

import { useAuth } from "hooks/useAuth";

import { useResource } from "hooks/useResource";
import { Login } from "pages/authentication/Login";
import { TestBlockLayout } from "components/general/TestBlockLayout";
import { NotFound } from "pages/general/NotFound";
import { Forbidden } from "pages/general/Forbidden";
import { HomePage } from "pages/general/HomePage";
import { Header } from "components/general/Header";
import { Footer } from "components/general/Footer";
import { TeacherSignUp } from "pages/authentication/TeacherSignUp";
import { SignUp } from "pages/authentication/SignUp";

import FAQPage from "components/static/faq/FAQPage";
import { ForgotPassword } from "pages/authentication/ForgotPassword";
import { EditUserProfile } from "components/user/user/user/EditUserProfile";
import { ChangeTemporaryPassword } from "components/auth/ChangeTemporaryPassword";
import { CourseCategoryPage } from "pages/course/CourseCategoryPage";
import { CoursePage } from "pages/course/CoursePage";
import { PublicTeacherProfile } from "components/user/teacher/PublicTeacherProfile";

import { PageStatic } from "components/static/page/content/PageStatic";
import { PublicStudentProfile } from "components/user/student/PublicStudentProfile";
import { BlogBlock } from "components/static/blog/BlogBlock";
import { BlogStatic } from "components/static/blog/BlogStatic";

import { UserWishlist } from "components/user/wishlist/UserWishlist";
import { Loading } from "components/elements/loading/Loading";
import { TeacherDashboard } from "components/user/teacher/TeacherDashboard";
import { Types } from "tools/types/types";
import { BecomeTeacherStaticPage } from "pages/general/BecomeTeacherStaticPage";
import { TeacherCoursePage } from "components/user/teacherinterface/TeacherCoursePage";
import { CommonTools } from "tools/utils/commontools";
import { TeacherDrawer } from "components/elements/Drawer/TeacherDrawer";
import { Box, useMediaQuery } from "@mui/material";

import { EditTeacherProfile } from "components/user/teacherinterface/EditTeacherProfile";
import { CreateCourseFirstStep } from "pages/course/CreateCourseFirstStep";
import { TeacherEditCourse } from "pages/course/TeacherEditCourse";
import { TeacherDrawerMenuItems } from "dto/user/teacherdrawermenuitems";
import { StudentDrawerMenuItems } from "dto/user/studentdrawermenuitems";
import { TeacherList } from "components/user/teacher/TeacherList";
import { TeacherInterfaceFAQBlock } from "components/user/teacherinterface/TeacherInterfaceFAQBlock";
import { TeacherInterfaceTestimonialBlock } from "components/user/teacherinterface/TeacherInterfaceTestimonialBlock";
import { StudentCoursePage } from "components/user/student/StudentCoursePage";
import { Checkout } from "pages/general/Checkout";

import { HeaderPrint } from "components/layoutprint/HeaderPrint";
import { FooterPrint } from "components/layoutprint/FooterPrint";
import { Certificate } from "components/course/certificate/Certificate";
import { StudentInterfaceFAQBlock } from "components/user/studentinterface/StudentInterfaceFAQBlock";
import { StudentInterfaceTestimonialBlock } from "components/user/studentinterface/StudentInterfaceTestimonialBlock";
import { TeacherInterfaceStudentBlock } from "components/user/teacherinterface/TeacherInterfaceStudentBlock";

import { TestResultBlock } from "components/course/testresult/TestResultBlock";
import { TeacherNotificationBlock } from "components/user/teachernotification/TeacherNotificationBlock";
import { StudentNotificationBlock } from "components/user/studentnotification/StudentNotificationBlock";
import { TeacherMessageBlock } from "components/user/teachermessage/TeacherMessageBlock";
import { TeacherInterfaceRequestBlock } from "components/user/teacherrequest/TeacherInterfaceRequestBlock";
import { CourseTeacherInterfaceRequestBlock } from "components/course/courseteacherrequest/CourseTeacherInterfaceRequestBlock";
import { SupportBlockStudent } from "components/support/SupportBlockStudent";
import { SupportBlockTeacher } from "components/support/SupportBlockTeacher";
import { StudentCoursePage as StudentCoursePageOwn } from "components/user/studentcourse/StudentCoursePage";
import { Classroom } from "components/user/teacherclassroom/classroom/Classroom";
import { CourseSearchBlock } from "components/course/search/CourseSearchBlock";

import { ConfirmEmailBoundary } from "components/user/user/user/ConfirmEmailBoundary";
import { ResetPassword } from "pages/authentication/ResetPassword";
import { ActivateEmail } from "pages/authentication/ActivateEmail";
import { TeacherInfoProvider } from "context/teacherinfo.context";
import { Config } from "tools/utils/config";

const MainLayout = () => {
  const { currentRoute, setCurrentRoute } = useResource();
  const location = useLocation();
  const pathObject = useReactPath();

  const { user, userInterface } = useAuth();
  const [_currentpath, setCurrentPath] = React.useState("");
  const [_mainobject, setMainObject] = React.useState("");
  const matchesCustom = useMediaQuery("(max-width:1300px)");

  const processPathObject = async () => {
    // var h = MD5(pathObject.path).toString();
    // var hr = MD5(pathObject.path).toString();

    var routeobj = await RouteTools.parseLocation(location);

    routeobj.historystate = pathObject.historystate;

    if (routeobj.url === _currentpath) return;
    setCurrentPath(routeobj.url);

    setCurrentRoute(routeobj);
  };

  useEffect(() => {
    processPathObject();
  }, [pathObject]);

  useEffect(() => {
    if (currentRoute && currentRoute._mainobject) {
      setMainObject(currentRoute._mainobject);
    }
  }, [currentRoute]);

  // ----------------------------------

  const processStandard = () => {
    if (!_mainobject) return false;

    switch (_mainobject) {
      case "test":
        return <TestBlockLayout currentRoute={currentRoute} />;
      case "homepage":
        return <HomePage currentRoute={currentRoute} />;
      case "login":
        return <Login currentRoute={currentRoute} />;
      case "resetpassword":
        return <ResetPassword currentRoute={currentRoute} />;
      case "activateemail":
        return <ActivateEmail currentRoute={currentRoute} />;
      case "signup":
        return <SignUp currentRoute={currentRoute} />;
      case "teachersignup":
        return <TeacherSignUp currentRoute={currentRoute} />;
      case "becometeacherpage":
        return <BecomeTeacherStaticPage currentRoute={currentRoute} />;
      case "changetemporarypassword":
        return <ChangeTemporaryPassword currentRoute={currentRoute} />;
      case "faq":
        return <FAQPage currentRoute={currentRoute} />;
      case "checkout":
        return <Checkout currentRoute={currentRoute} />;
      case "wishlist":
        return <UserWishlist currentRoute={currentRoute} />;
      case "forgotpassword":
        return <ForgotPassword currentRoute={currentRoute} />;
      case "coursesearch":
        return <CourseSearchBlock currentRoute={currentRoute} />;
      case "forbidden":
        return <Forbidden currentRoute={currentRoute} />;
      case "notfound":
        return <NotFound currentRoute={currentRoute} />;
      default:
        return false;
    }

    // if (_mainobject === "test")
    //   return <TestBlockLayout currentRoute={currentRoute} />;

    // if (_mainobject === "homepage")
    //   return <HomePage currentRoute={currentRoute} />;

    // if (_mainobject === "login") return <Login currentRoute={currentRoute} />;

    // if (_mainobject === "signup") return <SignUp currentRoute={currentRoute} />;

    // if (_mainobject === "teachersignup")
    //   return <TeacherSignUp currentRoute={currentRoute} />;

    // if (_mainobject === "becometeacherpage")
    //   return <BecomeTeacherStaticPage currentRoute={currentRoute} />;

    // if (_mainobject === "changetemporarypassword")
    //   return <ChangeTemporaryPassword currentRoute={currentRoute} />;

    // if (_mainobject === "faq") return <FAQPage currentRoute={currentRoute} />;

    // if (_mainobject === "checkout")
    //   return <Checkout currentRoute={currentRoute} />;

    // if (_mainobject === "wishlist")
    //   return <UserWishlist currentRoute={currentRoute} />;

    // if (_mainobject === "forgotpassword")
    //   return <ForgotPassword currentRoute={currentRoute} />;

    // if (_mainobject === "coursesearch")
    //   return <CourseSearchBlock currentRoute={currentRoute} />;

    // if (_mainobject === "forbidden")
    //   return <Forbidden currentRoute={currentRoute} />;

    // if (_mainobject === "notfound")
    //   return <NotFound currentRoute={currentRoute} />;

    // return false;
  };

  const processUser = () => {
    if (!_mainobject) return false;
    const path = currentRoute._paths;

    if (_mainobject === "profile") {
      switch (path[1]) {
        case "edit":
          return (
            <ConfirmEmailBoundary>
              <EditUserProfile currentRoute={currentRoute} />
            </ConfirmEmailBoundary>
          );
        case "course":
          return <StudentCoursePage currentRoute={currentRoute} />;
        case "courseenrollment":
          return <StudentCoursePageOwn currentRoute={currentRoute} />;
        case "faq":
          return <StudentInterfaceFAQBlock currentRoute={currentRoute} />;
        case "ticket":
          return <SupportBlockStudent currentRoute={currentRoute} />;
        case "testimonial":
          return (
            <StudentInterfaceTestimonialBlock currentRoute={currentRoute} />
          );
        case "notification":
          return <StudentNotificationBlock currentRoute={currentRoute} />;
        default:
          return false;
      }
    }
    //   if (path[1] === "edit")
    //     return <EditUserProfile currentRoute={currentRoute} />;
    //   if (path[1] === "course")
    //     return <StudentCoursePage currentRoute={currentRoute} />;
    //   if (path[1] === "courseenrollment")
    //     return <StudentCoursePageOwn currentRoute={currentRoute} />;
    //   if (path[1] === "faq")
    //     return <StudentInterfaceFAQBlock currentRoute={currentRoute} />;
    //   if (path[1] === "ticket")
    //     return <SupportBlockStudent currentRoute={currentRoute} />;
    //   if (path[1] === "testimonial")
    //     return <StudentInterfaceTestimonialBlock currentRoute={currentRoute} />;
    //   if (path[1] === "notification")
    //     return <StudentNotificationBlock currentRoute={currentRoute} />;
    // }

    return false;
  };

  const processCourse = () => {
    if (!_mainobject) return false;

    switch (_mainobject) {
      case "course":
        return <CoursePage currentRoute={currentRoute} />;
      case "coursecategory":
        return <CourseCategoryPage currentRoute={currentRoute} />;
      default:
        return false;
    }
    // if (_mainobject === "coursecategory") {
    //   return <CourseCategoryPage currentRoute={currentRoute} />;
    // }
    // if (_mainobject === "course") {
    //   return <CoursePage currentRoute={currentRoute} />;
    // }

    // return false;
  };

  const processPage = () => {
    if (!_mainobject) return false;

    switch (_mainobject) {
      case "page":
        return <PageStatic currentRoute={currentRoute} />;
      default:
        return false;
    }
    // if (_mainobject === "page") {
    //   return <PageStatic currentRoute={currentRoute} />;
    // }

    // return false;
  };

  const processBlog = () => {
    if (!_mainobject) return false;

    switch (_mainobject) {
      case "blog":
        return <BlogBlock currentRoute={currentRoute} />;
      case "blogpage":
        return <BlogStatic currentRoute={currentRoute} />;
      default:
        return false;
    }

    // if (_mainobject === "blog") {
    //   return <BlogBlock currentRoute={currentRoute} />;
    // }

    // if (_mainobject === "blogpage") {
    //   return <BlogStatic currentRoute={currentRoute} />;
    // }

    // return false;
  };

  const processTeacher = () => {
    if (!_mainobject) return false;

    switch (_mainobject) {
      case "teacherpage":
        return <PublicTeacherProfile currentRoute={currentRoute} />;
      case "teacher":
        return <TeacherList currentRoute={currentRoute} />;
      default:
        return false;
    }

    // if (_mainobject === "teacherpage") {
    //   return <PublicTeacherProfile currentRoute={currentRoute} />;
    // }

    // if (_mainobject === "teacher") {
    //   return <TeacherList currentRoute={currentRoute} />;
    // }

    // return false;
  };

  const processTeacherInterface = () => {
    if (!_mainobject) return false;
    const path = currentRoute._paths;

    if (_mainobject !== "teacherinterface") return false;

    if (!user) return false;
    if (userInterface !== Types.TEACHER_INTERFACE) return false;
    if (path.length === 1)
      return <TeacherDashboard currentRoute={currentRoute} />;
    if (path.length === 2) {
      switch (path[1]) {
        case "course":
          return <TeacherCoursePage currentRoute={currentRoute} />;
        case "addcourse":
          return <CreateCourseFirstStep currentRoute={currentRoute} />;
        case "testresult":
          return <TestResultBlock currentRoute={currentRoute} />;
        case "editprofile":
          return (
            <TeacherInfoProvider>
              <ConfirmEmailBoundary>
                <EditTeacherProfile currentRoute={currentRoute} />
              </ConfirmEmailBoundary>
            </TeacherInfoProvider>
          );
        case "faq":
          return <TeacherInterfaceFAQBlock currentRoute={currentRoute} />;
        case "ticket":
          return <SupportBlockTeacher currentRoute={currentRoute} />;
        case "testimonial":
          return (
            <TeacherInterfaceTestimonialBlock currentRoute={currentRoute} />
          );
        case "student":
          return <TeacherInterfaceStudentBlock currentRoute={currentRoute} />;
        case "notification":
          return <TeacherNotificationBlock currentRoute={currentRoute} />;
        case "teacherrequest":
          return <TeacherInterfaceRequestBlock currentRoute={currentRoute} />;
        case "courseteacherrequest":
          return (
            <CourseTeacherInterfaceRequestBlock currentRoute={currentRoute} />
          );
        case "message":
          return <TeacherMessageBlock currentRoute={currentRoute} />;
        default:
          return false;
      }

      // if (path[1] === "course")
      //   return <TeacherCoursePage currentRoute={currentRoute} />;
      // if (path[1] === "addcourse")
      //   return <CreateCourseFirstStep currentRoute={currentRoute} />;
      // if (path[1] === "testresult")
      //   return <TestResultBlock currentRoute={currentRoute} />;
      // if (path[1] === "editprofile")
      //   return <EditTeacherProfile currentRoute={currentRoute} />;
      // if (path[1] === "faq")
      //   return <TeacherInterfaceFAQBlock currentRoute={currentRoute} />;
      // if (path[1] === "ticket")
      //   return <SupportBlockTeacher currentRoute={currentRoute} />;

      // if (path[1] === "testimonial")
      //   return <TeacherInterfaceTestimonialBlock currentRoute={currentRoute} />;
      // if (path[1] === "student")
      //   return <TeacherInterfaceStudentBlock currentRoute={currentRoute} />;
      // if (path[1] === "notification")
      //   return <TeacherNotificationBlock currentRoute={currentRoute} />;
      // if (path[1] === "teacherrequest")
      //   return <TeacherInterfaceRequestBlock currentRoute={currentRoute} />;
      // if (path[1] === "courseteacherrequest")
      //   return (
      //     <CourseTeacherInterfaceRequestBlock currentRoute={currentRoute} />
      //   );
      // if (path[1] === "message")
      //   return <TeacherMessageBlock currentRoute={currentRoute} />;
    }
    if (path.length === 3) {
      switch (path[1]) {
        case "editcourse":
          return <TeacherEditCourse currentRoute={currentRoute} />;
        case "classroom":
          return <Classroom currentRoute={currentRoute} />;
        default:
          return false;
      }
      // if (path[1] === "editcourse")
      //   return <TeacherEditCourse currentRoute={currentRoute} />;
      // if (path[1] === "classroom")
      //   return <Classroom currentRoute={currentRoute} />;
    }
    if (path.length >= 3) {
      if (path[1] === "classroom")
        return <Classroom currentRoute={currentRoute} />;
    }

    return false;
  };

  const processStudent = () => {
    if (!_mainobject) return false;

    if (_mainobject === "student") {
      return <PublicStudentProfile currentRoute={currentRoute} />;
    }

    return false;
  };

  // ----------------------------------

  const processMainObject = () => {
    let t: any = false;

    t = processStandard();
    if (t) return t;

    t = processUser();
    if (t) return t;

    t = processCourse();
    if (t) return t;

    t = processTeacher();
    if (t) return t;

    t = processPage();
    if (t) return t;

    t = processBlog();
    if (t) return t;

    t = processStudent();
    if (t) return t;

    t = processTeacherInterface();
    if (t) return t;

    return <Loading />;
  };

  // const processUserWithDrawer = () => {
  //   if (!_mainobject) return false;
  //   if (_mainobject === "profile") {
  //     return (
  //       <Box sx={{ display: "flex" }}>
  //         <TeacherDrawer
  //           currentRoute={currentRoute}
  //           source={StudentDrawerMenuItems} />
  //         <Box sx={{ flexGrow: 1, }}>
  //           {layout()}
  //         </Box>
  //       </Box>
  //     )
  //   }

  //   return false;
  // }

  // const layout = () => {
  //   return (<>
  //     <header>
  //       <div className="container">
  //         <Header currentRoute={currentRoute} />
  //       </div>
  //     </header>
  //     <main>
  //       {processMainObject()}
  //     </main>
  //     <footer><Footer currentRoute={currentRoute} /></footer>
  //   </>)
  // }

  // const teacherLayout = () => {
  //   return (
  //     <Box sx={{ display: "flex" }}>
  //       <TeacherDrawer currentRoute={currentRoute} source={TeacherDrawerMenuItems} />
  //       <Box sx={{ flexGrow: 1, }}>
  //         {layout()}
  //       </Box>
  //     </Box>
  //   )
  // }

  // const processMainLayout = () => {
  //   if (CommonTools.isUserInterface(user, userInterface)) {
  //     return teacherLayout();
  //   } else if (_mainobject === "profile") {
  //     return processUserWithDrawer();
  //   }
  //   else {
  //     return layout();
  //   }
  // }

  const processPrint = () => {
    if (!_mainobject) return false;

    if (_mainobject === "certificate")
      return <Certificate currentRoute={currentRoute} />;

    return false;
  };

  const processPrintObjects = () => {
    let t: any = false;
    t = processPrint();
    if (t) return t;
    return <Loading />;
  };

  const processPrintComponents = () => {
    if (_mainobject === "certificate") return true;
    return false;
  };

  const processPrintLayout = () => {
    if (!_mainobject) return null;
    return (
      <>
        <header>
          <HeaderPrint currentRoute={currentRoute} />
        </header>
        <main>{processPrintObjects()}</main>
        <footer>
          <FooterPrint currentRoute={currentRoute} />
        </footer>
      </>
    );
  };

  const mainContent = (showLogo: boolean, showHeader?: boolean) => {
    if (showHeader === undefined) showHeader = true;
    const classNameContent = showLogo ? "container" : "container-admin";
    return (
      <>
        {showHeader && (
          <header>
            <div className={classNameContent}>
              <Header showLogo={showLogo} currentRoute={currentRoute} />
            </div>
          </header>
        )}
        <main style={{ minHeight: "495px" }}>{processMainObject()}</main>
        {showLogo && <Footer currentRoute={currentRoute} />}
      </>
    );
  };

  const processMainLayout = () => {
    if (processPrintComponents()) return processPrintLayout();

    const isTeacher = CommonTools.isUserInterface(user, userInterface);
    const path = currentRoute._paths;

    const drawerComponent = isTeacher ? (
      <TeacherDrawer
        currentRoute={currentRoute}
        source={TeacherDrawerMenuItems}
      />
    ) : _mainobject === "profile" && path[1] !== "courseenrollment" ? (
      <TeacherDrawer
        currentRoute={currentRoute}
        source={StudentDrawerMenuItems}
      />
    ) : null;

    const displayComponent =
      isTeacher || _mainobject === "profile" ? "flex" : "block";
    let showLogo = true;
    if (_mainobject === "profile") {
      showLogo = false;
    } else if (isTeacher) {
      showLogo = false;
    }

    let displayDrawer = "block";
    if (matchesCustom) {
      displayDrawer = "none";
    }
    const showHeader =
      _mainobject === "profile" && path[1] === "courseenrollment"
        ? false
        : true;
    return (
      // <Box>
      //   <Box sx={{ display: displayComponent, background: "green" }}>
      //     <Box
      //       sx={{
      //         display: displayDrawer,
      //       }}>
      //       {drawerComponent}
      //     </Box>
      //     <Box
      //       sx={{
      //         flexGrow: 1,
      //         // minHeight: `calc(100vh - ${Config.FOOTER_HEIGHT}px)`,
      //       }}>
      //       {mainContent(showLogo, showHeader)}
      //     </Box>
      //   </Box>
      //   <Box
      //     className="footerBoxMainLay"
      //     sx={{ zIndex: 2000, position: "relative", width: "100%" }}>
      //     <Footer currentRoute={currentRoute} />
      //   </Box>
      // </Box>

      // <Box className="mainBox">
      //   <Box className="drawerBox" sx={{ display: displayDrawer }}></Box>
      //   <Box className="contentBox">{mainContent(showLogo, showHeader)}</Box>
      //   <Box className="footerBox">
      //     <Footer currentRoute={currentRoute} />
      //   </Box>
      // </Box>
      <Box sx={{ display: displayComponent }}>
        <Box sx={{ display: displayDrawer }}>{drawerComponent}</Box>
        <Box sx={{ flexGrow: 1 }}>{mainContent(showLogo, showHeader)}</Box>
      </Box>
    );
  };

  // return (
  //   <>

  //     {CommonTools.isUserInterface(user, userInterface) ? (
  //       <Box sx={{ display: "flex" }}>
  //         <TeacherDrawer currentRoute={currentRoute} />
  //         <Box sx={{ flexGrow: 1, }}>
  //           {layout()}
  //         </Box>
  //       </Box>
  //     )
  //       : (layout())
  //     }
  //   </>

  // );
  return processMainLayout();
};

export { MainLayout };
