import Idto from "interfaces/idto.interface";
import { Status } from "tools//types/status";

export class CourseTeacherRequestDto implements Idto {
  id?: string;
  idteacher?: string;
  idmanager?: string;
  idcourse?: string;
  date?: number;
  status?: number;
  changedate?: number;
  type?: number;
  managermessage?: string;
  teachermessage?: string;

  manager?: any;
  teacher?: any;
  course?: any;
}

export class PostCourseTeacherRequestDto implements Idto {
  idteacher?: string;
  idcourse?: string;
  teachermessage?: string;

  constructor(idteacher?: string, idcourse?: string, teachermessage?: string) {
    this.idteacher = idteacher || "";
    this.idcourse = idcourse || "";
    this.teachermessage = teachermessage || "";
  }
}

export class CourseTeacherRequestProcessedDto implements Idto {
  processed?: boolean;
}
