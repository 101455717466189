import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { StudentTeacherFAQList } from "components/user/studentinterface/StudentTeacherFAQList";
import Typography from "@mui/material/Typography";
import { useResource } from "hooks/useResource";

const StudentInterfaceFAQBlock: React.FC<PageComponentProps> = ({
  currentRoute,
}) => {
  const { LL } = useResource();
  return (
    <React.Fragment>
      <Typography variant="h3" textAlign="center" my={3}>
        {LL("FAQ student")}
      </Typography>
      <StudentTeacherFAQList currentRoute={currentRoute} />
    </React.Fragment>
  );
};

export { StudentInterfaceFAQBlock };
