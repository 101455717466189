import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";

import DateTools from "tools/utils/date.tools";


export class NewsletterDto implements Idto {
  id: string;
  type: number;
  date: number;
  status: number;
  email: string;

  constructor(
    email?: string,
    id?: string,
    type?: number,
    date?: number,
    status?: number
  ) {
    this.id = id || "";
    this.type = type || Types.TYPE_GENERAL_NEWSLETTER;
    this.date = date || DateTools.getTimeStamp();
    this.status = status || Status.ACTIVE;
    this.email = email || "";
  }

  
}
