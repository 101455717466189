import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";

import { NotificationDto } from "dto/user/notification.dto";

import { useResource } from "hooks/useResource";
import React, { useState } from "react";
import { NotificationService } from "services/user/notification.service";
import DateTools from "tools/utils/date.tools";
import { Status } from "tools//types/status";

import CloseIcon from "@mui/icons-material/Close";
import ResultObjectDTO from "dto/app/resultobject.dto";

import { NotificationParamsList } from "components/notification/NotificationParamsList";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import DeleteIcon from "@mui/icons-material/Delete";

type Props = {
  obj: NotificationDto;
  setLoading: any;
  itemIndex: number;
  objects: Array<NotificationDto>;
  setObjects: any;
};

const service = new NotificationService();

const StudentNotificationItem: React.FC<Props> = ({
  obj,
  itemIndex,
  objects,
  setObjects,
}) => {
  const { LL } = useResource();

  const processAgoTime = () => {
    if (!obj) return null
    if (!obj.hasOwnProperty("date")) return null
    if (!obj.date) return null
    return DateTools.timeAgo(obj.date, LL);
  };

  const [areButtonsDisabled, setAreButtonsDisabled] = useState(false);

  const handleMarkAsRead = () => {
    if (!obj) return;
    if (!obj.id) return;
    setAreButtonsDisabled(true);
    service.markAsRead(obj.id, handleResult, {});
  };

  const updateObjectAtIndex = (index: number, newObj: NotificationDto) => {
    const updatedArray = [...objects];
    updatedArray.splice(index, 1, { ...updatedArray[index], ...newObj });

    setObjects(updatedArray);
  };

  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    if (!result.err) {
      const newObj: NotificationDto = result.obj ? result.obj : {};
      updateObjectAtIndex(itemIndex, newObj);
      setAreButtonsDisabled(false);
    }
  };

  const removeObjectAtIndex = (index: number) => {
    const updatedArray = [...objects];
    updatedArray.splice(index, 1);
    setObjects(updatedArray);
  };

  const handleDelete = () => {
    if (!obj) return;
    if (!obj.id) return;
    setAreButtonsDisabled(true);
    service.delete(obj.id, handleDeleteResult, {});
  };

  const handleDeleteResult = (result: ResultObjectDTO) => {
    if (!result) return;
    if (!result.err) {
      removeObjectAtIndex(itemIndex);
      setAreButtonsDisabled(false);
    }
  };

  const processUnRead = () => {
    if (!obj) return null
    if (!obj.hasOwnProperty("status")) return null
    if (!obj.status) return null
    if (obj.status === Status.NOTIFICATION_NEW) {
      return <FiberManualRecordIcon color={"primary"} />;
    }
    return null
  };

  const processUnReadButton = () => {
    if (!obj) return null
    if (!obj.hasOwnProperty("status")) return null
    if (!obj.status) return null
    if (obj.status === Status.NOTIFICATION_NEW) {
      return (
        <IconButton disabled={areButtonsDisabled} onClick={handleMarkAsRead}>
          <CloseIcon />
        </IconButton>
      );
    }
    return null
  };

  const processDeleteButton = () => {
    if (!obj) return null
    return (
      <IconButton onClick={handleDelete} disabled={areButtonsDisabled}>
        <DeleteIcon color="error" />
      </IconButton>
    );
  };

  const processItem = () => {
    if (!obj) return null
    if (!obj.hasOwnProperty("id")) return null
    if (!obj.id) return null

    return (
      <Box className="notification-tmp">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          my={2}
          sx={{ width: "100%" }}
        >
          <NotificationParamsList idNotification={obj.id} />
          <Stack direction="row">
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              {processUnRead()}
              <Typography className="data" mx={2}>
                {processAgoTime()}
              </Typography>
            </Box>
            <Stack direction={"row"} spacing={2}>
              {processUnReadButton()}
              {processDeleteButton()}
            </Stack>
          </Stack>
        </Stack>
        <Divider />
      </Box>
    );
  };

  return processItem();
};

export { StudentNotificationItem };
