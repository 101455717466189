import { MailOutline } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import MyTextField from "components/form/mytextfield";
import ForgotPasswordDto from "dto/auth/forgotpassword.dto";
import { useForm } from "hooks/useForm";
import { useGoogleRecaptcha } from "hooks/useGoogleRecaptcha";
import { useResource } from "hooks/useResource";
import React from "react";
import RequiredValidator from "validators/required.validator";

type Props = {
  onSubmit: (data: ForgotPasswordDto) => void;
};
const FormForgotPassword: React.FC<Props> = ({ onSubmit }) => {
  const { LL } = useResource();
  const { executeRecaptcha } = useGoogleRecaptcha();

  const [obj, disabled, setObjField] = useForm<ForgotPasswordDto>(
    new ForgotPasswordDto(),
    RequiredValidator.getValidators(["email"])
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    await executeRecaptcha();
    onSubmit(obj);
  };
  
  return (
    <form onSubmit={handleSubmit}>
      <Box className="box-form-input-icon" sx={{ mb: 4 }}>
        <MailOutline />
        <MyTextField
          fullWidth
          id="email"
          name="email"
          className="form-input"
          variant="standard"
          _label={LL("Email")}
          value={obj.email}
          setObj={setObjField}
          _vresults={undefined}
        />
      </Box>
      <Box mt={2}>
        <Button
          disabled={disabled}
          color="primary"
          fullWidth
          variant="contained"
          type="submit"
          className="btn"
        >
          {LL("Bt_Forgot_Submit")}
        </Button>
      </Box>
    </form>
  );
};

export { FormForgotPassword };
