import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { AdvertisementDto } from "dto/static/advertisement.dto";
import { AdvertisementService } from "services/static/advertisement.service";
import { Status } from "tools/types/status";
import { useList } from "./useList";
import { useMemo } from "react";

type UseAdvertisement = (
  section: number,
  type: number,
  useCache?: boolean,
  cacheIdentifier?: string,
  extendedTypes?: string[]
) => {
  objects: AdvertisementDto[] | null;
  loading: boolean;
  total: number;
  totalPages: number;
};

const service = new AdvertisementService();
export const useAdvertisementList: UseAdvertisement = (
  section,
  type,
  useCache = false,
  cacheIdentifier = "",
  extendedTypes = []
) => {
  const getList = (cb?: any, cbParams?: any, reqList?: RequestListDTO) => {
    service.getList(cb, cbParams, reqList);
  };
  const [loading, objects, total, totalPages] = useList<AdvertisementDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("section", [section.toString()]),
        RequestFilterDTO.prepareFilter("type", [type.toString(), ...extendedTypes]),
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
      ],
      1,
      -1,
      [RequestSortCriteriaDTO.prepareSortCriteria("ordercriteria", true)]
    ),
    [section],
    useCache,
    cacheIdentifier
  );

  return useMemo(
    () => ({
      objects,
      loading,
      total,
      totalPages,
    }),
    [objects, loading, total, totalPages]
  );
};
