import { Box, Typography } from "@mui/material";
import { CourseFilterFormDto } from "dto/course/coursefilter.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useEffect } from "react";
import { SelectFilter } from "../filter/SelectFilter";
import { SelectFilterDictionary } from "../filterdictionary/SelectFilterDictionary";
import { MyButton } from "components/elements/button/MyButton";
import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObj: CourseFilterFormDto;
  onSubmit: (obj: CourseFilterFormDto) => void;
  loading: boolean;
};

const FormCourseFilter: React.FC<Props> = ({
  defaultObj,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField, setObject] = useForm<CourseFilterFormDto>(
    defaultObj,
    RequiredValidator.getValidators(["idfilterdictionary", "idfilter"])
  );

  const setFilter = (field: string, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    t["idfilterdictionary"] = "";
    setObject(t);
  };

  useEffect(() => {
    if (!defaultObj) return;
    setObject(defaultObj);
  }, [defaultObj]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled || loading) return;
    onSubmit(obj);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <Typography variant="h6" mb={1}>
            {LL("Select filter")}
          </Typography>
          <SelectFilter setObjectField={setFilter} value={obj.idfilter} />
        </Box>
        {obj.idfilter && (
          <Box mt={3}>
            <Typography variant="h6" mb={1}>
              {LL("Select dictionary")}
            </Typography>
            <SelectFilterDictionary
              setObjectField={setObjField}
              value={obj.idfilterdictionary}
              idFilter={obj.idfilter}
            />
          </Box>
        )}
        <Box textAlign="center" mt={4}>
          <MyButton
            tabIndex={1}
            disabled={disabled || loading}
            color="primary"
            variant="contained"
            size="large"
            className="btn"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormCourseFilter };
