import React, { useState, useEffect, useContext, useCallback } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CommonTools } from "tools/utils/commontools";

import { Skeleton, Box, Grid, Typography, Collapse } from "@mui/material";

import { MyButton } from "components/elements/button/MyButton";
import { useResource } from "hooks/useResource";

import { ClassroomContext } from "context/classroom.context";
import { ClassroomTopBar } from "./ClassroomTopBar";
import { ClassroomLessonList } from "./ClassroomLessonList";
import { InteractionArea } from "./InteractionArea";

import { StudentList } from "./StudentList";

const Classroom: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { LL } = useResource();
  const {
    state: { course, idCourse, lessonObjects, idSelectedLesson },
    actions: {
      getCourse,
      getLessonList,
      setInitialPaths,
      resetContext,
      setIdVideoSelected,
      getLessonWhenNoSelectedId,
      getLessonWhenSelectedId,
      getStudentList,
    },
  } = useContext(ClassroomContext);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setInitialPaths(
      CommonTools.getIdFromPath(currentRoute, "classroom", 1, 2),
      CommonTools.getIdFromPath(currentRoute, "classroom", 1, 3)
    );
    setIdVideoSelected(CommonTools.getIdFromPath(currentRoute, "video", 4, 5));
    return () => {
      resetContext();
    };
  }, []);

  const getCourseData = useCallback(() => {
    if (!idCourse) return;
    getCourse(idCourse);
  }, [idCourse]);

  const getDataRelatedToIdCourse = useCallback(() => {
    if (!idCourse) return;
    getLessonList();
    getStudentList();
  }, [idCourse]);

  useEffect(() => {
    getCourseData();
  }, [getCourseData]);

  useEffect(() => {
    getDataRelatedToIdCourse();
  }, [getDataRelatedToIdCourse]);

  const checkLoading = useCallback(() => {
    let loading = false;
    if (!idCourse) loading = true;
    if (course === null) loading = true;
    if (lessonObjects === null) loading = true;
    setLoading(loading);
  }, [idCourse, course, lessonObjects]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  useEffect(() => {
    if (!lessonObjects) return;
    if (!idSelectedLesson) {
      getLessonWhenNoSelectedId();
    } else {
      getLessonWhenSelectedId();
    }
  }, [lessonObjects]);

  const [extended, setExtended] = useState<boolean>(false);
  const handleCollapse = () => {
    setExtended(!extended);
  };

  const processClassroomStudentList = () => {
    return (
      <Box>
        <MyButton cb={handleCollapse}>{LL("Students")}</MyButton>
        <Collapse in={extended} timeout="auto" unmountOnExit>
          <StudentList />
        </Collapse>
      </Box>
    );
  };

  const processComponent = () => {
    if (loading) return <Skeleton variant="rectangular" height={"100%"} />;
    if (!course) return <></>;

    return (
      <Box className=" student-course-detail">
        <ClassroomTopBar currentRoute={currentRoute} />
        <Grid container className="container">
          <Grid item lg={9} xs={12}>
            <InteractionArea currentRoute={currentRoute} />
          </Grid>
          <Grid item lg={3} xs={12} className="right-student-course">
            <Typography variant="h5" className="title-right">
              {LL("Continutul cursului")}
            </Typography>
            <ClassroomLessonList currentRoute={currentRoute} />
            {processClassroomStudentList()}
          </Grid>
        </Grid>
      </Box>
    );
  };

  return processComponent();
};

export { Classroom };
