import React, { useCallback, useLayoutEffect } from "react";

import { useEffect, useState } from "react";

import { CookiesProvider } from "react-cookie";

import { ResourceProvider } from "providers/ResourceProvider";

import { HeadProvider } from "providers/HeadProvider";
import theme from "assets/themes/theme";

import { ThemeProvider } from "@mui/material/styles";
import { MessageProvider } from "providers/MessageProvider";
import { AppRoutes } from "providers/AppRoutes";
import { MyGoogleOAuthProvider } from "providers/MyGoogleOAuthProvider";
import { StudentCourseProvider } from "context/studentcourse.context";
import { ClassroomProvider } from "context/classroom.context";
import { CourseSearchProvider } from "context/coursesearch.context";
import GeneralRepository from "repositories/general.repository";
import { ServerError } from "components/general/ServerError";
import { DownloadProvider } from "providers/DownloadProvider";
import { JWTProvider } from "providers/JWTProvider";
import { UserProvider } from "providers/UserProvider";
import { RouteTools } from "tools/utils/routetools";
import { LocalStorageApi } from "api/localstorage.api";
import { SocketProvider } from "providers/SocketProvider";

const App: React.FC = () => {
  const [_theme, setTheme] = useState({});
  const [serverError, setServerError] = useState<boolean>(false);

  useEffect(() => {
    setTheme(theme);
  }, []);

  const isServerError = useCallback(() => {
    return serverError;
  }, [serverError]);

  const processServerError = () => {
    setServerError(true);
  };

  const handleReload = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const handleUnauthorized = () => {
    handleReload();
    LocalStorageApi.saveValue("token", "");
    LocalStorageApi.saveValue("remember_token", "");
    RouteTools.setHistory("/login", {});
  };

  const handleForbidden = () => {
    handleReload();
    RouteTools.setHistory("/forbidden", {});
  };

  useLayoutEffect(() => {
    GeneralRepository.setReloadFunction(handleReload);
    GeneralRepository.setServerError(processServerError);
    GeneralRepository.setHandleUnauthorized(handleUnauthorized);
    GeneralRepository.setForbiddenFunction(handleForbidden);
  }, []);

  useEffect(() => {
    GeneralRepository.setIsServerError(isServerError);
  }, [isServerError]);

  if (serverError)
    return (
      <ThemeProvider theme={_theme}>
        <ServerError />
      </ThemeProvider>
    );

  return (
    <MyGoogleOAuthProvider>
      <ThemeProvider theme={_theme}>
        <CookiesProvider>
          <HeadProvider>
            <JWTProvider>
              <SocketProvider>
                <UserProvider>
                  <ResourceProvider>
                    <MessageProvider>
                      <CourseSearchProvider>
                        <StudentCourseProvider>
                          <ClassroomProvider>
                            <DownloadProvider>
                              <AppRoutes />
                            </DownloadProvider>
                          </ClassroomProvider>
                        </StudentCourseProvider>
                      </CourseSearchProvider>
                    </MessageProvider>
                  </ResourceProvider>
                </UserProvider>
              </SocketProvider>
            </JWTProvider>
          </HeadProvider>
        </CookiesProvider>
      </ThemeProvider>
    </MyGoogleOAuthProvider>
  );
};

export default App;
