import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";
import { SkeletonCoursesCtg } from "./SkeletonCategory";

interface SkeletonProps {
  lines?: number;
  width?: number;
}

const SkeletonReview: React.FC<SkeletonProps> = ({ lines }) => {
  const gridItem = (
    <Grid xs={12}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          // marginX: "auto",
          flexDirection: "row",
          height: "100%",
          gap: 2,
        }}>
        {" "}
        <Box
          sx={{
            width: { xs: "60%", sm: "80%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Skeleton
            variant="text"
            sx={{ fontSize: "0.35rem", width: "100%" }}
          />
        </Box>
        {/* <Skeleton variant="rectangular" sx={{ width:{xs:"60%", sm:"80%"},}}  /> */}
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem", width: { xs: "40%", sm: "20%" } }}
        />
      </Box>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", height: "auto" }} className="">
      <Box
        sx={{
          width: "90%",
          display: "flex",
          justifyContent: "start",
          alignContent: "center",
          margin: "auto",
          padding: "20px 15px 20px 15px",
        }}>
        <Grid
          container
          xs={12}
          sx={{ maxWidth: "1522px", margin: "auto" }}
          spacing={2}>
          {Array(lines)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

const SkeletonSocial: React.FC<SkeletonProps> = ({ lines }) => {
  const gridItem = (
    <Grid container xs={12} sx={{ mt: 2 }}>
      <Grid
        xs={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Skeleton
          variant="text"
          sx={{
            minWidth: "100px",
            fontSize: "1rem",
            width: { xs: "30%", sm: "40%" },
          }}
        />
      </Grid>
      <Grid
        xs={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Skeleton
          variant="text"
          sx={{
            minWidth: "100px",
            fontSize: "1rem",
            width: { xs: "30%", sm: "40%" },
          }}
        />
      </Grid>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", height: "auto" }} className="">
      <Box
        sx={{
          width: "90%",
          display: "flex",
          justifyContent: "start",
          alignContent: "center",
          margin: "auto",
          padding: "20px 15px 20px 15px",
        }}>
        <Grid
          container
          xs={12}
          sx={{ maxWidth: "1522px", margin: "auto" }}
          spacing={2}>
          {Array(lines)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

const SkeletonCourseList: React.FC<SkeletonProps> = ({ lines }) => {
  const gridItem = (
    <Grid container xs={12} sx={{}}>
      <SkeletonCoursesCtg lines={lines} />
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", height: "auto" }} className="">
      <Box
        sx={{
          width: "90%",
          display: "flex",
          justifyContent: "start",
          alignContent: "center",
          margin: "auto",
          padding: "20px 15px 20px 15px",
          flexDirection: "column",
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "1522px",
            py: "20px",
            margin: "auto",
          }}>
          <Skeleton
            variant="text"
            sx={{ fontSize: "2.5rem", width: "20%", minWidth: "300px" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            flexDirection: "row",
            width: "100%",
            maxWidth: "1522px",
            gap: 2,
            margin: "auto",
            mb: 2,
          }}>
          <Skeleton
            variant="text"
            sx={{ fontSize: "1.5rem", width: "20%", minWidth: "300px" }}
          />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1.5rem", width: "20%", minWidth: "300px" }}
          />
        </Box>
        <Grid
          container
          xs={12}
          sx={{ maxWidth: "1522px", margin: "auto" }}
          spacing={2}>
          {Array(1)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Skeleton sx={{ width: "40px", height: "40px" }} />
        </Box>
      </Box>
    </Box>
  );
};

const SkeletonFAQList: React.FC<SkeletonProps> = ({ lines }) => {
  const gridItem = (
    <Grid container xs={12} sx={{ mb: 6 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
          width: "100%",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            width: "100%",
            gap: 2,
          }}>
          <Skeleton
            variant="circular"
            sx={{ width: "50px", height: "50px", minWidth: "50px" }}
          />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1.25rem", width: "40%", minWidth: "300px" }}
          />
        </Box>
        <Skeleton
          variant="text"
          sx={{ fontSize: "0.75rem", width: "100px", minWidth: "100px", mt: 2 }}
        />
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem", width: "80%", minWidth: "300px" }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
            width: "100%",
            ml: 4,
            mt: 1,
          }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              width: "90%",
              gap: 2,
            }}>
            <Skeleton
              variant="circular"
              sx={{ width: "50px", height: "50px", minWidth: "50px" }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1.25rem", width: "20%", minWidth: "150px" }}
            />
          </Box>
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem", width: "75%", minWidth: "300px" }}
          />
        </Box>
      </Box>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", height: "auto" }} className="">
      <Box
        sx={{
          width: "90%",
          display: "flex",
          justifyContent: "start",
          alignContent: "center",
          margin: "auto",
          padding: "20px 15px 20px 15px",
          flexDirection: "column",
        }}>
        <Grid
          container
          xs={12}
          sx={{ maxWidth: "1522px", margin: "auto" }}
          spacing={2}>
          {Array(lines)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

const SkeletonReviewsList: React.FC<SkeletonProps> = ({ lines }) => {
  const gridItem = (
    <Grid container xs={12} sx={{ mb: 6 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
          width: "100%",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            width: "100%",
            gap: 2,
          }}>
          <Skeleton
            variant="circular"
            sx={{ width: "50px", height: "50px", minWidth: "50px" }}
          />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1.25rem", width: "40%", minWidth: "300px" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            width: "100%",
            gap: 2,
            mt: 2,
          }}>
          <Skeleton
            variant="text"
            sx={{ fontSize: "1.25rem", width: "120px", minWidth: "120px" }}
          />
          <Skeleton
            variant="text"
            sx={{ fontSize: "0.75rem", width: "100px", minWidth: "100px" }}
          />
        </Box>
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem", width: "80%", minWidth: "300px" }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
            width: "100%",
            ml: 4,
            mt: 1,
          }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              width: "90%",
              gap: 2,
            }}>
            <Skeleton
              variant="circular"
              sx={{ width: "50px", height: "50px", minWidth: "50px" }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1.25rem", width: "20%", minWidth: "150px" }}
            />
          </Box>
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem", width: "75%", minWidth: "300px" }}
          />
        </Box>
      </Box>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", height: "auto" }} className="">
      <Box
        sx={{
          width: "90%",
          display: "flex",
          justifyContent: "start",
          alignContent: "center",
          margin: "auto",
          padding: "20px 15px 20px 15px",
          flexDirection: "column",
        }}>
        <Grid
          container
          xs={12}
          sx={{ maxWidth: "1522px", margin: "auto" }}
          spacing={2}>
          {Array(lines)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export {
  SkeletonReview,
  SkeletonSocial,
  SkeletonCourseList,
  SkeletonFAQList,
  SkeletonReviewsList,
};
