import React, { useContext } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { StudentCourseContext } from "context/studentcourse.context";
import { Status } from "tools//types/status";
import { Alert, Box, Typography } from "@mui/material";
import { LessonArea } from "./LessonArea";
import { NeedCheckout } from "./NeedCheckout";
import { FinalTest } from "./FinalTest";
import { StartTest } from "./StartTest";
import { RequestCertificate } from "./RequestCertificate";
import { useResource } from "hooks/useResource";

export const InteractionArea: React.FC<PageComponentProps> = ({
  currentRoute,
}) => {
  const {
    state: { courseEnrollmentStatus },
  } = useContext(StudentCourseContext);
  const { LL } = useResource();

  const processLessonArea = () => {
    return <LessonArea currentRoute={currentRoute} />;
  };

  switch (courseEnrollmentStatus) {
    case Status.ENR_COURSE_READY: {
      return processLessonArea();
    }
    case Status.ENR_NEED_END_TEST: {
      return (
        <Box>
          <FinalTest />
          {processLessonArea()}
        </Box>
      );
    }
    case Status.ENR_NEED_CHECKOUT: {
      return <NeedCheckout currentRoute={currentRoute} />;
    }
    case Status.ENR_NEED_START_TEST: {
      return (
        <Box>
          <StartTest currentRoute={currentRoute} />
        </Box>
      );
    }
    case Status.ENR_COURSE_FINISHED: {
      return (
        <Box>
          <Box>
            <Alert severity="success">
              <Typography>{LL("Course_Finished_Congratulations")}</Typography>
            </Alert>
          </Box>
          <RequestCertificate />
          {processLessonArea()}
        </Box>
      );
    }
    case Status.ENR_NEED_CERTIFICATE: {
      return (
        <Box>
          <Box>
            <Alert severity="info">
              <Typography>{LL("Course_Certificate_was_requested")}</Typography>
            </Alert>
          </Box>
        </Box>
      );
    }
    case Status.ENR_ARCHIVED: {
      return <Box>{processLessonArea()}</Box>;
    }
    case Status.ENR_CANCELED: {
      return (
        <Box>
          <Alert severity="error">
            <Typography>{LL("Course_Canceled")}</Typography>
          </Alert>
        </Box>
      );
    }
    case Status.ENR_UN_FINISHED: {
      return (
        <Box>
          <Alert severity="error">
            <Typography>{LL("ENR_UN_FINISHED")}</Typography>
          </Alert>
        </Box>
      );
    }
    case Status.ENR_WITH_CERTIFICATE: {
      return <Box>{processLessonArea()}</Box>;
    }
    default: {
      return null;
    }
  }
};
