import React, { useState } from "react";
import { useResource } from "hooks/useResource";

import { WishlistService } from "services/user/wishlist.service";

import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { WishlistDto } from "dto/user/wishlist.dto";

import RequestListDTO from "dto/app/requestlist.dto";

import RequestFilterDTO from "dto/app/requestfilter.dto";

import { Box, Grid, Stack, Typography } from "@mui/material";

import { useAuth } from "hooks/useAuth";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { CourseCard } from "components/course/course/CourseCard";
import { MyButton } from "components/elements/button/MyButton";
import { LoadMoreButton } from "components/elements/MyButton/LoadMoreButton";
import { NoResult } from "components/general/NoResult";
import { SkeletonCourseBlock } from "components/elements/Skeleton/Skeleton";
import { CallbackType } from "interfaces/commontypes.interface";
import { useList } from "hooks/useList";
import { CommonTools } from "tools/utils/commontools";
const service = new WishlistService();

const UserWishlist: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { LL, CC } = useResource();
  // const [isLoading, setIsLoading] = useState(true);
  // const [objects, setObjects] = useState<Array<WishlistDto>>([]);

  const { user } = useAuth();
  const idUser = CommonTools.processObjectField(user, ["id"]);
  // const [total, setTotal] = useState<number>(-1);
  // const [totalPage, setTotalPage] = useState<number>(-1);

  const [onPage, setOnPage] = useState<number>(
    parseInt(CC("DefaultOnPageWishlist", "4"))
  );

  const [refresh, setRefresh] = useState<string>("");

  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    if (!user) return;
    service.getList(cb, cbParams, req);
  };

  const [loading, objects, total] = useList<WishlistDto>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("iduser", [idUser])],
      1,
      onPage
    ),
    [idUser, onPage, refresh],true,`wishlist_${idUser}_onpage_${onPage}`
  );
  // const getList = async () => {
  //   if (!user) return;
  //   const reqList = new RequestListDTO();
  //   reqList.onpage = onPage;
  //   reqList.page = 1;
  //   reqList.filters = [];

  //   let f: RequestFilterDTO;

  //   f = new RequestFilterDTO();
  //   f.field = "iduser";
  //   f.values = [user.id];
  //   reqList.filters.push(f);

  //   setIsLoading(true);

  //   service.getList(loadObjects, {}, reqList);
  // };

  // useEffect(() => {
  //   getList();
  // }, [refresh, onPage]);

  // const loadObjects = (result: ResultListDTO) => {
  //   if (!result) return;
  //   if (!result.err) {
  //     const objects = result.objects ? result.objects : [];
  //     const total = result.total !== undefined ? result.total : -1;
  //     const totalPage =
  //       result.totalpages !== undefined ? result.totalpages : -1;
  //     setObjects(objects);
  //     setTotal(total);
  //     setTotalPage(totalPage);
  //   }
  // };

  // const checkLoading = () => {
  //   let load = false;

  //   if (total === -1) return true;
  //   if (totalPage === -1) return true;
  //   if (!objects) load = true;

  //   setIsLoading(load);
  // };

  // useEffect(() => {
  //   checkLoading();
  // }, [objects]);

  const processWishlistItem = (obj: WishlistDto, index: number) => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("course")) return null;
    if (!obj.course) return null;
    return (
      <Grid item sm={12} lg={3} md={4} key={index}>
        <CourseCard
          obj={obj.course}
          loading={loading}
          withWishlist={true}
          onChangeSubscribe={setRefresh}
        />
      </Grid>
    );
  };

  const processLoadMoreObjects = () => {
    if (!objects || !objects.length) return null;
    return (
      <LoadMoreButton
        objects={objects}
        total={total}
        onPage={onPage}
        setOnPage={setOnPage}
        step={parseInt(CC("DefaultOnPageWishlist", "4"))}
        className="loadMorebtn"
      />
    );
  };

  const handleDeleteWishlist = () => {
    if (!objects) return;
    if (!objects.length) return;
    if (!user) return;
    if (!user.id) return;

    service.deleteAllByIdUser(user.id, getList, {});
  };

  const processDeleteWishlist = () => {
    if (loading) return null;
    if (!objects) return null;
    if (!objects.length) return null;

    return (
      <MyButton cb={handleDeleteWishlist} className="btn btnDelete">
        <DeleteOutlineIcon />
        {LL("Delete_All_From_Wishlist")}
      </MyButton>
    );
  };

  const processWishlistList = () => {
    if (!objects) return null;
    if (!objects.length) return <NoResult identifier="wishlist" />;

    return (
      <Grid container spacing={2} mb={4}>
        {objects.map((obj, index) => processWishlistItem(obj, index))}
      </Grid>
    );
  };

  if (!user) return null;
  return loading ? (
    <SkeletonCourseBlock />
  ) : (
    <Box className="container pageWish">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2" my={5}>
          {LL("Wishlist")}
        </Typography>
        {processDeleteWishlist()}
      </Stack>
      {processWishlistList()}
      <Box mb={5}>{processLoadMoreObjects()}</Box>
    </Box>
  );
};

export { UserWishlist };
