import React, { useState, useEffect } from "react";
import { CheckCourseInWishlist } from "dto/user/wishlist.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { WishlistService } from "services/user/wishlist.service";

import { useResource } from "hooks/useResource";
import { useAuth } from "hooks/useAuth";
import { Skeleton, IconButton, Box } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { PostWishlistDto } from "dto/user/wishlist.dto";

import { CommonTools } from "tools/utils/commontools";

type Props = {
  idCourse: string;
  onChangeSubscribe?: any;
  isForCard?: boolean;
};
const service = new WishlistService();

const WishlistIconChecker: React.FC<Props> = ({
  idCourse,
  onChangeSubscribe,
  isForCard = false,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [obj, setObj] = useState<CheckCourseInWishlist | undefined>();
  const [subscribe, setSubscribe] = useState<boolean>(false);
  const [id, setId] = useState<string>("");
  const { user } = useAuth();
  const { LL } = useResource();

  const loadObject = (obj: ResultObjectDTO) => {
    // logger('WishlistIconChecker obj', obj)
    if (!obj) return;
    if (!obj.err) {
      const object = obj.obj ? obj.obj : {};
      setObj(object);
      // logger('WishlistIconChecker', object)
    }
  };

  const getObject = () => {
    if (!user) return;
    if (!idCourse) return;
    if (!user.id) return;
    setLoading(true);
    service.checkInWishlist(user.id, idCourse, loadObject, {});
  };

  const checkIsLoading = () => {
    let load: boolean = false;
    if (obj === undefined) load = true;
    if (obj) {
      processObject();
    }
    setLoading(load);
  };

  useEffect(() => {
    checkIsLoading();
  }, [obj]);

  const processObject = () => {
    if (!obj) return;
    if (!obj.isinwishlist) {
      setSubscribe(false);
      setId("");
    } else {
      setSubscribe(true);
      if (obj.hasOwnProperty("id") && obj.id) setId(obj.id);
    }
  };

  useEffect(() => {
    getObject();
  }, [idCourse, user]);

  const handleSubscribe = () => {
    if (!user) return;
    if (!idCourse) return;
    if (!user.id) return;
    const obj = new PostWishlistDto();
    obj.idcourse = idCourse;
    obj.iduser = user.id;

    service.add(getObject, {}, obj);
  };
  const handleUnSubscribe = () => {
    if (!user) return;
    if (!idCourse) return;
    if (!id) return;
    service.delete(id, getObject, {});
    handleOnChangeSubscribe();
  };

  const handleOnChangeSubscribe = () => {
    if (onChangeSubscribe) {
      const localRefresh = CommonTools.generateRandomString(10);
      onChangeSubscribe(localRefresh);
    }
  };

  const processElement = () => {
    if (!obj) return null;
    if (!user) return null;
    // logger('WishlistIconChecker', subscribe)
    if (!subscribe) {
      return (
        <IconButton onClick={handleSubscribe}>
          <FavoriteBorderIcon color="error" />
        </IconButton>
      );
    }

    return (
      <IconButton onClick={handleUnSubscribe}>
        <FavoriteIcon color="error" />
      </IconButton>
    );
  };

  const processElementNotForCard = () => {
    if (!obj) return null;
    if (!user) return null;
    if (loading) return <Skeleton width={125.75} height={46} />;
    if (!subscribe) {
      return (
        <Box onClick={handleSubscribe} className="btnWish">
          <FavoriteBorderIcon color="error" sx={{ mr: 1 }} />
          {LL("Wishlist")}
        </Box>
      );
    }

    return (
      <Box onClick={handleUnSubscribe} className="btnWish">
        <FavoriteIcon color="error" sx={{ mr: 1 }} />
        {LL("Wishlist")}
      </Box>
    );
  };

  const processComponent = () => {
    if (!user) return null;

    if (isForCard) return processElement();
    return processElementNotForCard();
  };

  return processComponent();
};

export { WishlistIconChecker };
