import React, { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { BlogDto } from 'dto/static/blog.dto'
import { MyButton } from "components/elements/button/MyButton";
import { CommonTools } from 'tools/utils/commontools';
import { ComponentCommonTools } from 'tools/utils/componentcommontools'
import { useResource } from "hooks/useResource";
import { RouteTools } from "tools/utils/routetools";
import { Box, Stack, Divider } from "@mui/material";

import { logger } from "tools/utils/logger";

import { MyNavBt } from "components/elements/button/MyNavBt";
import { HtmlTag } from "components/form/HtmlTag";

type BlogCardProps = {
    obj: BlogDto;
    loading: boolean;
}

const BlogCard: React.FC<BlogCardProps> = ({ obj, loading, ...props }) => {

    const { LL } = useResource();
    const [imgUrl, setImgUrl] = useState<string>("");
    const [fullUrl, setFullUrl] = useState<string>("");

    const goBlogDetails = () => {
        if (!obj) return;
        if (!obj.id) return;
        if (!obj.hasOwnProperty('fullurl')) return;
        if (!obj.fullurl) return;
        const url = obj.fullurl;
        RouteTools.setHistory(url, {})
    }

    const prepareGallery = () => {
        if (!obj) return;
        if (!obj.hasOwnProperty('gallery')) return;
        if (!obj.gallery) return;
        if (obj.gallery.length === 0) return;
        const galleryObj = CommonTools.getGalleryObjectLogo(obj.gallery);
        if (!galleryObj) return;
        CommonTools.setFileUrlsGallery(galleryObj, setImgUrl, setFullUrl, 310, 180);
    }

    useEffect(() => {
        prepareGallery();
    }, [obj])

    const processImage = () => {
        if (!obj) return <></>;
        if (!imgUrl) return <></>;

        return (
            <CardMedia
                sx={{ height: 180 }}
                image={imgUrl}
                title='' >
                
            </CardMedia>
        )
    }

    

    const processLinkCard = () => {

        if (!obj) return <></>;
        if (!obj.hasOwnProperty('fullurl')) return processBlogCard();
        if (!obj.fullurl) return processBlogCard();
        const url = obj.fullurl;

        return (
            <MyNavBt href={url} >
                {processBlogCard()}
            </MyNavBt>
        )
    }

    const processName = () => {

        if (!obj) return <></>;
        return (<Typography className="name">
            {ComponentCommonTools.processObjectField(obj, 'name')}
        </Typography>)
    }

    const processContent = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('content')) return <></>;
        if (!obj.content) return <></>;

        return (<HtmlTag  content={obj.content} />)
    }

    const processBlogCard = () => {
        if (!obj) return <></>;

        return (
            <Box p={1}>
                <Card className="blogCard">
                    <Box className="imageZone">{processImage()}</Box>
                    <CardContent>
                        <Box className="name">{processName()}</Box>
                        <Box className="shortDescription">{processContent()}</Box>
                    </CardContent>
                </Card>
            </Box>
        )
    }

    return loading ? <Skeleton variant="rectangular" /> : processLinkCard();
};

export { BlogCard };
