import React, { SyntheticEvent, useEffect, useState } from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";

import Idto from "interfaces/idto.interface";
import SelectOptions from "dto/app/selectoptions.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { useList } from "hooks/useList";

import RequestFilterDTO from "dto/app/requestfilter.dto";

import { Status } from "tools/types/status";


type Props = {
  field: string;
  setObjectField: (field: string, value: string | number) => void;
  label?: string;
  filterField?: string;
  parseOptions: (options: Idto[]) => SelectOptions[];
  getListFunction: (cb?: any, cbParams?: any, req?: RequestListDTO) => void;
  defaultObject?: SelectOptions | null;
  dependencies?: any[];
  mainValue?: string | number;
};
const GeneralAutocomplete: React.FC<Props> = ({
  field,
  setObjectField,
  label = "",
  filterField = "search",
  getListFunction,
  parseOptions,
  defaultObject,
  dependencies = [],
  mainValue
}) => {
  if (!defaultObject) defaultObject = null;
  
  // if (!label) label = LL(field);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [searchServer, setSearchServer] = useState("");
  const [value, setValue] = useState<SelectOptions | null>(defaultObject);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOnChange = (
    event: React.SyntheticEvent,
    value: SelectOptions | null
  ) => {
    setValue(value);
    if (value) {
      setObjectField(field, value.value || "");
    } else {
      setObjectField(field, "");
    }
  };
  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    getListFunction(cb, cbParams, req);
  };

  const [loading, objects] = useList<Idto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter(filterField, [searchServer]),
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
      ],
      1,
      20
    ),
    [searchServer, ...dependencies],
    true
  );

  const isOptionEqualToValue = (
    option: SelectOptions,
    valueLocal: SelectOptions
  ) => {
    if (!option.value || !valueLocal) return false;
    return option.value === valueLocal.value;
  };
  const getOptionLabel = (option: SelectOptions) => {
    if (!option.label) return "";
    return option.label;
  };

  const handelOnSearch = (
    event: SyntheticEvent<Element, Event>,
    input: string
  ) => {
    setSearch(input);
  };

  useEffect(() => {
    if (!open) {
      setSearch("");
    }
  }, [open]);

  
  useEffect(() => {
    setTimeout(() => {
      setSearchServer(search);
    }, 500);
  }, [search]);

  useEffect(() => {
    if (!value) return;
    if (mainValue != value.value) {
      setValue(null);
    }
  }, [mainValue]);

  const options = parseOptions(objects || []);
  return (
    <Autocomplete
      id="asynchronous-demo"
      fullWidth
      size="small"
      className="form-autocomplete"
      open={open}
      value={value}
      onOpen={handleOpen}
      onClose={handleClose}
      options={options}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      onChange={handleOnChange}
      onInputChange={handelOnSearch}
      renderInput={(params) => (
        <TextField
          
          variant="outlined"
          color="primary"
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export { GeneralAutocomplete };
