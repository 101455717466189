import React from "react";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { useResource } from "hooks/useResource";

import { Box, Stack, Typography } from "@mui/material";

import { TicketDto } from "dto/support/ticket.dto";
import { TicketMessageList } from "./TicketMessageList";

type Props = {
  obj?: TicketDto;
  updateArray: (object: TicketDto) => void;
};

const SupportSelectedTicket: React.FC<Props> = ({ obj,updateArray }) => {
  const { LL } = useResource();

  const processMessageList = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("id")) return null;
    if (!obj.id) return null;

    return (
      <Box sx={{ width: "100%", display: "flex" }}>
        <TicketMessageList idTicket={obj.id} ticket={obj} updateArray={updateArray}/>
      </Box>
    );
  };

  const processItem = () => {
    if (!obj) return <>{LL("Select_a_ticket")}</>;
    return (
      <Box>
        <Box>
          <Stack direction="row" spacing={1}>
            <Typography>
              {ComponentCommonTools.processObjectField(obj, "status_name")}
            </Typography>
            <Typography>
              {ComponentCommonTools.processObjectFieldOfObject(
                obj,
                "ticketcategory",
                "name"
              )}
            </Typography>
            <Typography>
              {ComponentCommonTools.processObjectField(obj, "date_name")}
            </Typography>
          </Stack>
        </Box>
        <Box mt={1}>{processMessageList()}</Box>
      </Box>
    );
  };

  return processItem();
};

export { SupportSelectedTicket };
