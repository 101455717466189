import React, { useContext } from "react";
import { useResource } from "hooks/useResource";
import { Alert, Box,  Stack } from "@mui/material";
import { TestDialog } from "components/course/test/TestDialog";
import { Types } from "tools/types/types";
import { StudentCourseContext } from "context/studentcourse.context";
import { Status } from "tools//types/status";
import { NewTestDialog } from "components/course/test/new/NewTestDialog";

const LessonTest: React.FC = () => {
  const { LL } = useResource();

  const {
    state: {
      idCourse,
      courseEnrollmentObject,
      course,
      lessonEnrollmentObject,
      selectedLessonObject,
    },
    actions: {
      triggerReloadCourseEnrollment,
      triggerReloadLessonEnrollmentAfterTest,
    },
  } = useContext(StudentCourseContext);

  const processComponent = () => {
    if (!courseEnrollmentObject) return null;
    if (!selectedLessonObject) return null;
    if (!lessonEnrollmentObject) return null;
    if (!lessonEnrollmentObject.hasOwnProperty("status")) return null;
    if (!lessonEnrollmentObject.status) return null;
    if (lessonEnrollmentObject.status !== Status.LESSON_ERR_NEED_TEST)
      return null;
    if (!course) return null;
    if (!selectedLessonObject.hasOwnProperty("id")) return null;
    if (!selectedLessonObject.id) return null;
    if (!lessonEnrollmentObject.hasOwnProperty("idlesson")) return null;
    if (!lessonEnrollmentObject.idlesson) return null;
    if (selectedLessonObject.id !== lessonEnrollmentObject.idlesson)
      return null;

    return (
      <Box p={5}>
        <Stack direction={"row"}>
          <Alert severity="error" sx={{ border: 1 }}>
            {LL("To_finish_the_finish_you_need_to_pass_the_test")}
          </Alert>

          <NewTestDialog
            courseEnrollmentObject={courseEnrollmentObject}
            idCourse={idCourse}
            idLesson={selectedLessonObject.id}
            type={Types.TYPE_TEST_LESSON}
            course={course}
            lesson={selectedLessonObject}
            showTitleLesson={true}
            lessonEnrollment={lessonEnrollmentObject}
            setVerifyLessonEnrChanged={triggerReloadLessonEnrollmentAfterTest}
            setCheckObjectWasChanged={triggerReloadCourseEnrollment}
          />
        </Stack>
      </Box>
    );
  };
  return processComponent();
};

export { LessonTest };
