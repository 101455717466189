import React from "react";

import { RatingRangesDto } from "dto/static/ratingranges.dto";
import { RatingService } from "services/static/rating.service";
import { RatingProgress } from "components/elements/Statistic/RatingProgress";
import { SkeletonReview } from "components/elements/Skeleton/SkeletonTeacherPage";
import useObject from "hooks/useObject";
import { CallbackType } from "interfaces/commontypes.interface";

const service = new RatingService();

type Props = {
  idTeacher: string;
};

const TeacherStatistic: React.FC<Props> = ({ idTeacher }) => {
  // const [loading, setLoading] = useState<boolean>(true);
  // const [obj, setObj] = useState<RatingRangesDto | undefined>();

  // const loadObject = (obj: any) => {
  //   if (!obj) return;
  //   if (!obj.err) {
  //     const object = obj.obj ? obj.obj : [];
  //     setObj(object);
  //   }
  // };

  // const getObject = () => {
  //   if (idTeacher) service.getTeacherStatistic(idTeacher, loadObject, {});
  // };

  // const checkIsLoading = () => {
  //   let load: boolean = false;
  //   if (obj === undefined) load = true;
  //   setLoading(load);
  // };

  // useEffect(() => {
  //   getObject();
  // }, [idTeacher]);

  // useEffect(() => {
  //   checkIsLoading();
  // }, [obj]);

  const get = (id: string, cb?: CallbackType, cbParams?: any) => {
    if (!id) return;
    service.getTeacherStatistic(id, cb, cbParams);
  };

  const [loading, obj] = useObject<RatingRangesDto>(get, idTeacher, [
    idTeacher,
  ]);

  const processStatistic = () => {
    if (!obj) return null;
    return <RatingProgress obj={obj} />;
  };

  return idTeacher && !loading ? (
    processStatistic()
  ) : (
    <SkeletonReview lines={5} />
  );
};

export { TeacherStatistic };
