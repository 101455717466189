import { Box } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import MyTextField from "components/form/mytextfield";
import {
  ResponseTicketStudentDto,
  ResponseTicketTeacherDto,
} from "dto/support/ticketmessage.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useEffect } from "react";
import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObj: ResponseTicketStudentDto | ResponseTicketTeacherDto;
  onSubmit: (obj: ResponseTicketStudentDto | ResponseTicketTeacherDto) => void;
  loading: boolean;
};

const FormTicketResponse: React.FC<Props> = ({
  defaultObj,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();
  const validators =
    defaultObj instanceof ResponseTicketStudentDto
      ? ["message", "idstudent", "idticket"]
      : ["message", "idteacher", "idticket"];
  const [obj, disabled, setObjField, setObject] = useForm<
    ResponseTicketStudentDto | ResponseTicketTeacherDto
  >(defaultObj, RequiredValidator.getValidators(validators));

  useEffect(() => {
    if (!defaultObj) return;
    setObject(defaultObj);
  }, [defaultObj]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled || loading) return;
    onSubmit(obj);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box mt={3}>
          <MyTextField
            tabIndex={2}
            fullWidth
            id="message"
            name="message"
            minRows={4}
            color="primary"
            _label={LL("message")}
            size="small"
            value={obj.message}
            setObj={setObjField}
            multiline
            _vresults={null}
          />
        </Box>

        <Box mt={3}>
          <MyButton
            disabled={disabled || loading}
            color="primary"
            variant="contained"
            size="large"
            type="submit"
            className="btn"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormTicketResponse };
