import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { useAuth } from "hooks/useAuth";
import SocialSignInDto from "dto/auth/socialsignin.dto";
import { SocialIdentifier } from "tools/types/socialidentifier";
import { Box } from "@mui/material";

const MyGoogleLoginButton: React.FC = () => {
  const { signInSocial } = useAuth();

  const responseMessage = (response?: any) => {
    const obj = new SocialSignInDto();
    obj.socialidentifier = SocialIdentifier.GOOGLE.toString();
    obj.socialtoken = response.credential;
    obj.requestObject = response;
    signInSocial(obj);
  };

  const errorMessage = (e?: any) => {
    // logger("error Google", e);
  };

  // theme={"outline"} // "outline" | "filled_blue" | "filled_black"
  // text={"signin"} // "signin_with" | "signup_with" | "continue_with" | "signin"
  // logo_alignment={"left"} // "left" | "center"
  // size={"small"} // "small" | "large" | "medium"
  // shape={"rectangular"} // "rectangular" | "pill" | "circle" | "square"
  // https://developers.google.com/identity/gsi/web/reference/js-reference
  // locale={"zh_CN"}

  return (
    <Box className="googleButton">
      <GoogleLogin
        onSuccess={responseMessage}
        onError={errorMessage}
        theme={"outline"}
        text={"signin"}
        logo_alignment={"left"}
        size={"medium"}
        // shape={"pill"}
        // width={"200px"}
      />
    </Box>
  );
};

export { MyGoogleLoginButton };
