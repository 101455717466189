import { PageDto } from "dto/static/page.dto";

import { PageService } from "services/static/page.service";
import useObject from "./useObject";
import { useMemo } from "react";
import { CallbackType } from "interfaces/commontypes.interface";

type UsePage = (
  id: string,
  useCache?: boolean,
  cacheIdentifier?: string
) => {
  object: PageDto | null;
  loading: boolean;
};

const service = new PageService();
export const usePage: UsePage = (
  id,
  useCache = false,
  cacheIdentifier = ""
) => {
  const get = (id: string, cb?: CallbackType, cbParams?: any) => {
    if (!id) return;
    service.get(id, cb, cbParams, undefined);
  };

  const [loading, object] = useObject<PageDto>(
    get,
    id,
    [id],
    useCache,
    cacheIdentifier
  );

  return useMemo(
    () => ({
      object,
      loading,
    }),
    [object, loading]
  );
};
