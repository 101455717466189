import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

import Box from "@mui/material/Box";

import { StudentService } from "services/user/student.service";
import { StudentDto, StudentNotificationDto } from "dto/user/student.dto";
import { useAuth } from "hooks/useAuth";

import { CommonTools } from "tools/utils/commontools";
import { CircularLoading } from "components/elements/loading/CircularLoading";

import useObject from "hooks/useObject";
import { FormStudentNotification } from "./FormStudentNotification";
import ResultObjectDTO from "dto/app/resultobject.dto";

const service = new StudentService();

const EditStudentNotification: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { user } = useAuth();

  const id = CommonTools.processObjectField(user, ["id"]);

  const get = (id: string, cb?: any, cbParams?: any) => {
    if (!id) return;
    service.getByUserId(id, cb, cbParams);
  };
  const [loading, obj, setObj, setLoading] = useObject<StudentDto>(get, id, [
    id,
  ]);

  // const [obj, setObj] = useState<StudentDto | undefined>();
  // const _id = user !== undefined ? user.id : "";
  // const [id, setId] = useState<string>(_id);
  // const [isFormDisabled, setIsFormDisabled] = useState(false);

  // const [validators, setValidators] = useState<any>();
  // const [vresults, setVResults] = useState<any>(false);

  // const validateField = (field: any, value: any, v?: any) => {
  //   v = v ? v : JSON.parse(JSON.stringify(vresults));
  //   if (v === false) v = {};
  //   v[field] = ValidateTools.validate(field, value, validators, vresults);

  //   setVResults(v);
  //   setIsFormDisabled(ValidateTools.vForm(v, field));
  //   return v;
  // };

  // const loadObject = (obj: any) => {
  //   if (!obj) return;
  //   if (!obj.err) {
  //     const object = obj.obj ? obj.obj : [];
  //     setObj(object);
  //   }
  // };

  // const getObject = () => {
  //   setLoading(true);
  //   if (id !== undefined && id !== "")
  //     studentService.getByUserId(id, loadObject, {});
  // };

  // const checkIsLoading = () => {
  //   let load: boolean = false;
  //   if (obj === undefined) load = true;
  //   setLoading(load);
  // };

  // useEffect(() => {
  //   getObject();
  // }, [currentRoute]);

  // useEffect(() => {
  //   validateAll();
  //   checkIsLoading();
  // }, [obj]);

  // useEffect(() => {
  //   if (user !== undefined) {
  //     setId(user.id);
  //   }
  // }, [user]);

  // const validateAll = () => {
  //   if (!obj) return;
  //   // if (!validators) return;
  //   // if (vresults) return;

  //   obj.notificationtype1 =
  //     obj.notificationtype1 !== undefined ? obj.notificationtype1 : false;
  //   obj.notificationtype2 =
  //     obj.notificationtype2 !== undefined ? obj.notificationtype2 : false;
  //   obj.notificationtype3 =
  //     obj.notificationtype3 !== undefined ? obj.notificationtype3 : false;

  //   setObj(obj);
  // };

  // const setCheckNotificationType1 = (value: any) => {
  //   let t = JSON.parse(JSON.stringify(obj));
  //   t["notificationtype1"] = value;
  //   setObj(t);
  // };
  // const setCheckNotificationType2 = (value: any) => {
  //   let t = JSON.parse(JSON.stringify(obj));
  //   t["notificationtype2"] = value;
  //   setObj(t);
  // };
  // const setCheckNotificationType3 = (value: any) => {
  //   let t = JSON.parse(JSON.stringify(obj));
  //   t["notificationtype3"] = value;
  //   setObj(t);
  // };

  // const handleSubmit = (event: any) => {
  //   event.preventDefault();
  //   if (isFormDisabled) return false;
  //   if (id !== undefined && obj) {
  //     obj.iduser = id;
  //     setLoading(true);
  //     studentService.addNotification(
  //       CommonTools.handleSuccessAddAndEditStayOnPage,
  //       { setLoading },
  //       obj
  //     );
  //   }
  // };

  const onSubmit = (obj: StudentNotificationDto) => {
    setLoading(true);
    service.addNotification(handleResult, {}, obj);
  };

  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    setObj(result.obj);
    setLoading(false);
  };

  if (!user) return null;
  if (loading) return <CircularLoading />;
  if (!obj) return null;
  return (
    <Box
      p={3}
      maxWidth="md"
      mx={"auto"}
      justifyContent="center"
      display="flex"
      flexDirection="column">
      <FormStudentNotification
        onSubmit={onSubmit}
        loading={loading}
        defaultObject={StudentNotificationDto.fromStudentDto(id, obj)}
      />
    </Box>
  );
};

export { EditStudentNotification };
