import React from "react";

import { Box, Typography, Grid, Stack } from "@mui/material";
import { CourseDto } from "dto/course/course.dto";

import { useResource } from "hooks/useResource";
import { CourseLessonsList } from "components/course/lesson/CourseLessonsList";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import DateTools from "tools/utils/date.tools";

type Props = {
  obj: CourseDto;
};
const ResourcesTabBlock: React.FC<Props> = ({ obj }) => {
  const { LL } = useResource();

  const processLessonsList = () => {
    if (!obj) return null;
    if (!obj.id) return null;
    return (
      <Box>
        <Box>{processLessonsContentHeader()}</Box>
        <Box>
          <CourseLessonsList idCourse={obj.id} />
        </Box>
      </Box>
    );
  };

  const processLessonsContentHeader = () => {
    if (!obj) return null;
    if (!obj.id) return null;

    const lessonNumber = getNumberOfLessons();
    const courseDuration = getCourseDuration();

    return (
      <React.Fragment>
        <Typography variant="h3" mt={5}>
          {LL("CourseContent")}
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          my={3}
          alignItems="center"
          className="courseGeneralData"
        >
          <Typography>
            <span>{LL("LessonsNumber")}</span> {lessonNumber}
          </Typography>
          <FiberManualRecordIcon sx={{ fontSize: "10px" }} color="primary" />
          <Typography>
            <span>{LL("CourseDuration")}</span> {courseDuration}
          </Typography>
        </Stack>
      </React.Fragment>
    );
  };

  const getCourseDuration = () => {
    if (!obj) return 0;
    if (!obj.hasOwnProperty("courseduration")) return 0;
    if (!obj.courseduration) return 0;
    return DateTools.displayTimestampInHHMM(obj.courseduration);
  };
  const getNumberOfLessons = () => {
    if (!obj) return 0;
    if (!obj.hasOwnProperty("numberoflessons")) return 0;
    if (!obj.numberoflessons) return 0;
    return obj.numberoflessons;
  };

  return obj ? (
    <Box className="detailCourse">
      <Box className="container">
        <Grid container>
          <Grid item lg={9}>
            {processLessonsList()}
          </Grid>
        </Grid>
      </Box>
    </Box>
  ) : null;
};

export { ResourcesTabBlock };
