// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// import UserSettingsDto from "dto/user/usersettings.dto";
// import ResultListDTO from "dto/app/resultlist.dto";
// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import { UserSettingsRepository } from "repositories/user/usersettings.repository";
// import { Status } from "tools//types/status";
// import GeneralService from "services/general.service";

// export default class UserSettingsService
//   extends GeneralService
//   implements IService
// {
//   userSettingsRepository: UserSettingsRepository = new UserSettingsRepository();
//   constructor() {
//     super();
//     this.handleGetList = this.handleGetList.bind(this);
//     this.handleGet = this.handleGet.bind(this);
//   }
//   handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;

//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async update(
//     id: string,
//     cb?: any,
//     cbparameters?: any,
//     data?: any
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.userSettingsRepository.update(
//       id,
//       this.handleUpdate,
//       this.prepareObject(data),
//       cbparameters
//     );
//   }

//   handleDelete(result?: any, cbparameters?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters);
//     }
//   }

//   async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.userSettingsRepository.delete(id, this.handleDelete, cbparameters);
//   }

//   handleGet(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;
//     this.populateObject(rez.obj, result.obj, [
//       { key: "birthday", type: "date" },
//     ]);
//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.userSettingsRepository.get(
//       id,
//       this.handleGet,
//       cbparameters
//     );
//     if (cb == undefined) {
//       return this.handleGet(t);
//     }
//   }

//   async getByUserId(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.userSettingsRepository.getByUserId(id, this.handleGet, cbparameters);
//   }

//   handleGetList(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultListDTO();

//     rez = result;
//     if (result.err) return;

//     this.parseResultPopulate(result, rez, [{ key: "birthday", type: "date" }]);

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   prepareObject(obj: any): UserSettingsDto {
//     const rez = new UserSettingsDto();
//     if (obj.hasOwnProperty("id")) rez.id = obj.id;

//     if (obj.hasOwnProperty("idavatar") && obj.idavatar)
//       rez.idavatar = obj.idavatar;
//     if (obj.hasOwnProperty("idlanguage")) rez.idlanguage = obj.idlanguage;
//     if (obj.hasOwnProperty("name")) rez.name = obj.name;
//     if (obj.hasOwnProperty("surname")) rez.surname = obj.surname;
//     if (obj.hasOwnProperty("birthday")) rez.birthday = obj.birthday;
//     if (obj.hasOwnProperty("idtypegender")) rez.idtypegender = obj.idtypegender;
//     if (obj.hasOwnProperty("deleteavatar") && obj.deleteavatar)
//       rez.deleteavatar = obj.deleteavatar;
//     if (obj.hasOwnProperty("iduser")) rez.iduser = obj.iduser;
//     if (obj.hasOwnProperty("files")) rez.files = obj.files;
//     return rez;
//   }

//   async getList(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.userSettingsRepository.getList(
//       this.handleGetList,
//       cbparameters,
//       data
//     );
//     if (cb == undefined) {
//       return this.handleGetList(t);
//     }
//   }

//   handleAdd(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async add(cb?: any, cbparameters?: any, data?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.userSettingsRepository.add(
//       this.handleGetList,
//       cbparameters,
//       this.prepareObject(data)
//     );
//   }

//   getDefaultRequestList(onPage?: number): RequestListDTO {
//     onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
//     if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
//     var defaultRequestList = new RequestListDTO();
//     defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
//     defaultRequestList.onpage = onPage;
//     var defaultRequestSortCriteria = new RequestSortCriteriaDTO();
//     defaultRequestSortCriteria.asc = true;
//     defaultRequestSortCriteria.field = "name";
//     defaultRequestList.sortcriteria = [];
//     defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
//     return defaultRequestList;
//   }
// }

import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import { UserSettingsRepository } from "repositories/user/usersettings.repository";

export class UserSettingsService
  extends GeneralService<UserSettingsRepository>
  implements IService
{
  constructor() {
    super(
      new UserSettingsRepository(),
      [{ key: "birthday", type: "date" }],
      "name"
    );
  }
  getByUserId = async (
    id: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getByUserId(
      id,
      this.handleGet,
      cbparameters
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };
}
