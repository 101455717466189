import { useEffect } from "react";

import GeneralRepository from "repositories/general.repository";
import { Config } from "tools/utils/config";

declare global {
  interface Window {
    grecaptcha: any;
  }
}

const RE_CAPTCHA_SITE_KEY = Config.RECAPTCHA_SITE_KEY
var reCaptchaToken = "";

export function useGoogleRecaptcha() {
  useEffect(() => {
    GeneralRepository.setRecaptchaToken(getRecaptchaToken);

    const loadCaptchaScript = () => {
      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/api.js?render=${RE_CAPTCHA_SITE_KEY}`;
      script.async = true;
      document.body.appendChild(script);
    };

    loadCaptchaScript();

    return () => {
      const script = document.querySelector(
        'script[src="https://www.google.com/recaptcha/api.js"]'
      );
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  const getRecaptchaToken = () => {
    return reCaptchaToken;
  };

  const executeRecaptcha = async () => {
    const token = await new Promise((resolve) => {
      window.grecaptcha.ready(async () => {
        const response = await window.grecaptcha.execute(RE_CAPTCHA_SITE_KEY, {
          action: "submit",
        });
        resolve(response);
      });
    });
    reCaptchaToken = token as string;

    return token;
  };

//   const verifyRecaptcha = async () => {
//     if (!reCaptchaToken) {
//       throw new Error("Recaptcha token is not available.");
//     }

//     const response = await axios.post("YOUR_VERIFICATION_ENDPOINT", {
//       // Send the token to your backend for verification
//       token: reCaptchaToken,
//       // Add any additional data required for verification
//     });

//     // Handle the verification response from your backend
//     console.log(response.data);
//   };

  return { executeRecaptcha };
}
