import React from "react";
import { CourseCategoryService } from "services/course/coursecategory.service";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CourseCategoryDto } from "dto/course/coursecategory.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools//types/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Grid } from "@mui/material";
import { InfoCourseCategoryCard } from "./InfoCourseCategoryCard";
import { SkeletonCardForth } from "components/elements/Skeleton/SkeletonCards";
import { useList } from "hooks/useList";
const service = new CourseCategoryService();

const TopCourseCategory: React.FC<PageComponentProps> = () => {
  // const { saveCache, getCache } = useResource();

  // const [isLoading, setIsLoading] = useState(true);
  // const [objects, setObjects] = useState<Array<CourseCategoryDto>>([]);

  // const getList = async () => {
  //   const reqList = new RequestListDTO();
  //   reqList.onpage = 100;
  //   reqList.page = 1;
  //   reqList.filters = [];

  //   let f: RequestFilterDTO;

  //   f = new RequestFilterDTO();
  //   f.field = "status";
  //   f.values = [];
  //   f.values.push(Status.ACTIVE.toString());
  //   reqList.filters.push(f);

  //   f = new RequestFilterDTO();
  //   f.field = "istop";
  //   f.values = [];
  //   f.values.push("true");
  //   reqList.filters.push(f);

  //   const cd = getCache(JSON.stringify(reqList));

  //   if (cd !== false) {
  //     setObjects(cd);
  //     setIsLoading(false);
  //     return;
  //   }

  //   setIsLoading(true);

  //   const data = await courseCategoryService.getList(undefined, {}, reqList);

  //   const rez = !data || !data.objects ? [] : data.objects;

  //   setObjects(rez);
  //   setIsLoading(false);

  //   saveCache(rez, JSON.stringify(reqList));
  // };

  // useEffect(() => {
  //   getList();
  // }, []);

  const getList = (cb?: any, cbParams?: any, reqList?: RequestListDTO) => {
    service.getList(cb, cbParams, reqList);
  };

  const [loading, objects] = useList<CourseCategoryDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
        RequestFilterDTO.prepareFilter("istop", ["true"]),
      ],
      1,
      -1,
      []
    ),
    [],
    true,
    "home_course_category_block"
  );

  const processCourseCategoryItem = (obj: CourseCategoryDto, index: number) => {
    if (!obj) return null;
    return (
      <Grid sm={12} md={4} lg={3} item key={index}>
        <InfoCourseCategoryCard obj={obj} />
      </Grid>
    );
  };

  const processCourseCategoryList = () => {
    if (!objects) return null;
    if (!objects.length) return null;

    return (
      <Grid container spacing={2} className="topCategoryHomeCours">
        {objects.map((item: CourseCategoryDto, i: number) => {
          return processCourseCategoryItem(item, i);
        })}
      </Grid>
    );
  };

  return loading ? <SkeletonCardForth /> : processCourseCategoryList();
};

export { TopCourseCategory };
