import Idto from "interfaces/idto.interface";
import { Status } from "tools//types/status";

export class GalleryDto implements Idto {
  id?: string;
  status?: number;
  ordercriteria?: number;
  idfile?: string;
  parent?: string;
  idparent?: string;
  isdefault?: boolean;
  idlanguage?: string;
  language?: any;
  status_name?: string;
  idgallery?: string;
  name?: string;
  fileInfoObj?: any;
  constructor() {
    this.status = Status.ACTIVE;
    this.ordercriteria = 0;
    this.isdefault = false;
  }
}

export class PostGalleryForCourseDto implements Idto {
  status?: number;
  ordercriteria?: number;
  idparent?: string;
  isdefault?: boolean;
  idlanguage?: string;

  name?: string;

  files?: any;
  parent?: any;
  deletegallery?: boolean;
  constructor(idparent?: string, idlanguage?: string) {
    this.files = null;
    this.isdefault = true;
    this.status = Status.ACTIVE;
    this.ordercriteria = 0;
    this.name = "";
    this.parent = "course";
    this.deletegallery = false;
    this.idlanguage = idlanguage || "";
    this.idparent = idparent || "";
  }
}

export class PostGalleryDto implements Idto {
  status?: number;
  ordercriteria?: number;
  idfile?: string | null;
  parent?: string;
  idparent?: string;
  isdefault?: boolean;
  idlanguage?: string;
  language?: any;
  status_name?: string;
  idgallery?: string;
  name?: string;
  files?: any;
  fileInfoObj?: any;
  constructor() {
    this.status = Status.ACTIVE;
    this.ordercriteria = 0;
    this.isdefault = false;
    this.name = "";
  }
}
