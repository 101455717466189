import * as React from "react";
import { Box, Divider } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SkeletonCoursesCtg } from "./SkeletonCategory";
import { inherits } from "util";
import { useResponsive } from "hooks/useResponsive";

interface SkeletonProps {
  lines?: number;
  width?: number;
}

const SkeletonCourseMenu: React.FC<SkeletonProps> = ({ lines }) => {
  const { matchesXS, matchesSM, matchesMD, matchesLG } = useResponsive();

  let gridItemsCount;
  if (matchesSM) {
    gridItemsCount = 4;
  } else {
    gridItemsCount = 12;
  }

  const gridItem = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        // marginX: "auto",
        flexDirection: "column",
        width: "100%",
      }}>
      <Skeleton
        variant="text"
        sx={{ fontSize: "14px", width: "12%", minWidth: "90px", mb: 2 }}
      />
      <Skeleton
        variant="text"
        sx={{ fontSize: "14px", width: "14%", minWidth: "90px", mb: 2 }}
      />
    </Box>
  );

  return (
    <Box sx={{ width: "100%", height: "auto", mt: 0 }} className="">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          alignContent: "start",
          //   margin: "auto",
          padding: "0px 30px 0px 30px",
        }}>
        <Grid
          container
          xs={12}
          sx={{ maxWidth: "1522px", width: "100%" }}
          spacing={2}>
          {Array(lines)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export { SkeletonCourseMenu };
