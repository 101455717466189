import React, { useEffect, useState } from "react";
import { useResource } from "hooks/useResource";

import { AdvertisementDto } from "dto/static/advertisement.dto";

import { ComponentCommonTools } from "tools/utils/componentcommontools";

import { Box } from "@mui/material";

import { MyNavBt } from "components/elements/button/MyNavBt";
import { CommonTools } from "tools/utils/commontools";

import Typography from "@mui/material/Typography";

type BannerItemSpecialHomeSection1Props = {
  obj: AdvertisementDto;
};
const AdvertisementBannerItemHomeSuggestions: React.FC<
  BannerItemSpecialHomeSection1Props
> = ({ obj, ...props }) => {
  const [imgUrl, setImgUrl] = useState<string>("");
  const [fullUrl, setFullUrl] = useState<string>("");
  const width = 60;
  const height = 60;

  const { LL } = useResource();

  const prepareGallery = () => {
    if (!obj) return;

    if (!obj.hasOwnProperty("gallery")) return;
    if (!obj.gallery) return;
    if (obj.gallery.length === 0) return;
    const galleryObj = CommonTools.getGalleryObjectLogo(obj.gallery);
    if (!galleryObj) return;
    CommonTools.setFileUrlsGallery(
      galleryObj,
      setImgUrl,
      setFullUrl,
      width,
      height
    );
  };

  useEffect(() => {
    prepareGallery();
  }, [obj]);

  const processImage = () => {
    if (!imgUrl) return <></>;
    return <>{ComponentCommonTools.getFileBlock(imgUrl, width, height)}</>;
  };

  const processDetail = (obj: AdvertisementDto) => {
    if (!obj) return <></>;
    return (
      <Box>
        <Typography variant="h5" my={1}>
          {ComponentCommonTools.processObjectField(obj, "name")}
        </Typography>
        <Typography className="description">
          {ComponentCommonTools.processObjectField(obj, "description")}
        </Typography>
      </Box>
    );
  };

  const processWithoutFullUrl = (obj: AdvertisementDto) => {
    if (!obj) return <></>;

    if (!imgUrl) {
      return <MyNavBt href={obj.fullurl}>{processDetail(obj)}</MyNavBt>;
    }

    return (
      <Box className="sugestionItem" sx={{ width: "100%" }}>
        {processImage()}
        {processDetail(obj)}
      </Box>
    );
  };

  const processItem = () => {
    if (!obj) return <></>;

    if (obj.hasOwnProperty("fullurl") && obj.fullurl) {
      return <MyNavBt href={obj.fullurl}>{processWithoutFullUrl(obj)}</MyNavBt>;
    }

    return processWithoutFullUrl(obj);
  };

  return processItem();
};

export { AdvertisementBannerItemHomeSuggestions };
