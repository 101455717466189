import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CourseTeacherInterfaceRequestList } from "./CourseTeacherInterfaceRequestList";

const CourseTeacherInterfaceRequestBlock: React.FC<PageComponentProps> = ({
  currentRoute,
}) => {
  return <CourseTeacherInterfaceRequestList currentRoute={currentRoute} />;
};

export { CourseTeacherInterfaceRequestBlock };
