import React, { useContext, useEffect } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CourseSearchContext } from "context/coursesearch.context";
import { Box, Grid, Skeleton } from "@mui/material";
import { CourseSearchList } from "./CourseSearchList";
import { MyPagination } from "components/elements/MyPagination/MyPagination";
import { CourseSearchTopBlock } from "./CourseSearchTopBlock";
import { CourseSearchField } from "./CourseSearchField";
import { SkeletonPagination } from "components/elements/Skeleton/SkeletonPagination";
import { SkeletonCoursesCtg } from "components/elements/Skeleton/SkeletonCategory";
const CourseSearchBlock: React.FC<PageComponentProps> = ({ currentRoute }) => {
  const {
    state: { objects, page, totalPage, loading, total,reload },
    actions: { getList, processDefaultDataReload, setPage },
  } = useContext(CourseSearchContext);
  const mainUrl =
    currentRoute && currentRoute.url ? currentRoute.url.split("?")[0] : "";

  useEffect(() => {
    processDefaultDataReload(currentRoute);
  }, []);

  useEffect(() => {
    getList(mainUrl);
  }, [getList]);

  const processPagination = () => {
    if (totalPage === -1) return null
    if (total === -1) return null
    if (objects.length === 0) return null

    return (
      <Box
        className="container"
        py={4}
        display="flex"
        sx={{ justifyContent: "center" }}
      >
        <MyPagination
          page={page}
          total={total}
          totalPage={totalPage}
          setPage={setPage}
        />
      </Box>
    );
  };

  return (
    <Box className="courseLIST">
      <Box className="container" py={2}>
        <Grid container spacing={2} mt={2}>
          <Grid item sm={8}>
            <CourseSearchTopBlock />
          </Grid>
          <Grid item sm={4}>
            {reload ? <Box sx={{display:"flex", justifyContent:"center", alignItems:"center"}}><Skeleton /></Box> : <CourseSearchField />}
          </Grid>
          <Grid item sm={12}>
            {loading ? <SkeletonCoursesCtg lines={10} /> : <CourseSearchList />}
            {loading ? <SkeletonPagination /> : processPagination()}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export { CourseSearchBlock };
