import React, { useEffect, useState } from "react";

import { useResource } from "hooks/useResource";

import { Box, Typography } from "@mui/material";

import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import MyTextField from "components/form/mytextfield";
import { IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import RequiredValidator from "validators/required.validator";
import ValidateTools from "tools/utils/validate.tools";
import { useAuth } from "hooks/useAuth";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { MyButton } from "components/elements/button/MyButton";

import { CircularLoading } from "components/elements/loading/CircularLoading";

import { PostTestimonialDto } from "dto/user/testimonial.dto";
import { TestimonialService } from "services/user/testimonial.service";

import { CourseDto } from "dto/course/course.dto";
import { RatingInput } from "components/elements/MyRating/RatingInput";

const service = new TestimonialService();

type TestimonialDialogProps = {
  course: CourseDto;
};
const TestimonialDialog: React.FC<TestimonialDialogProps> = ({
  course,
  ...props
}) => {
  const { LL } = useResource();
  const { user } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [obj, setObj] = useState<PostTestimonialDto>(new PostTestimonialDto());
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [open, setOpen] = useState(false);

  const [validators, setValidators] = useState(false);
  const [vresults, setVResults] = useState<any>(false);
  const validateAll = () => {
    if (!obj) return;
    if (!validators) return;

    obj.message = obj.message !== undefined ? obj.message : "";
    obj.rating = obj.rating !== undefined ? obj.rating : 0;

    setObj(obj);

    validateField("message", obj.message);
  };

  const validateField = (field: any, value: any, v?: any) => {
    v = v ? v : JSON.parse(JSON.stringify(vresults));
    if (v === false) v = {};
    v[field] = ValidateTools.validate(field, value, validators, vresults);
    setVResults(v);
    setIsFormDisabled(ValidateTools.vForm(v, field));
    return v;
  };

  const processValidators = () => {
    let v: any = {
      message: [],
    };

    v.message.push(new RequiredValidator("", ""));

    setValidators(v);
  };

  const checkLoading = () => {
    if (!loading) return;
    if (!obj) return;

    validateAll();
    setLoading(false);
  };

  useEffect(() => {
    processValidators();
  }, []);

  useEffect(() => {
    checkLoading();
  }, [obj, validators]);

  useEffect(() => {
    checkIsFormDisabled();
  }, [vresults]);

  const setObjField = (field: any, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    setObj(t);

    validateField(field, value);
  };

  const checkIsFormDisabled = () => {
    var rez = ValidateTools.vForm(vresults, "message");

    setIsFormDisabled(rez);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (isFormDisabled) return false;

    if (!obj || !course || !user) return;

    setLoading(true);
    obj.idcourse = course.id;
    obj.idteacher = course.idteacher;
    obj.iduser = user.id;
    setLoading(true);
    service.add(handleResult, {}, obj);
  };

  const handleResult = (result: ResultObjectDTO, cbparams: any) => {
    if (!result) return;
    if (!result.err) {
      setOpen(false);
      setLoading(false);
      setObj(new PostTestimonialDto());
    }
  };

  const handleClose = () => {
    setOpen(false);
    setObj(new PostTestimonialDto());
  };

  const processForm = () => {
    if (!obj) return <></>;
    if (loading) return <CircularLoading />;

    return (
      <form onSubmit={handleSubmit}>
        <Box>
          <Typography variant="h6" mb={1}>
            {" "}
            {LL("Message")}
          </Typography>
          <MyTextField
            multiline
            minRows={4}
            tabIndex={0}
            fullWidth
            id="message"
            name="message"
            variant="standard"
            color="primary"
            className="form-input"
            _label={LL("message")}
            size="small"
            value={obj.message}
            setObj={setObjField}
            _vresults={vresults && vresults.message ? vresults.message : false}
          />
        </Box>
        <Box>
          <Typography variant="h6" mb={1}>
            {" "}
            {LL("Rating")}
          </Typography>
          <RatingInput
            tabIndex={1}
            field="rating"
            value={obj.rating}
            setObjField={setObjField}
          />
        </Box>
        <Box>
          <MyButton
            tabIndex={2}
            disabled={isFormDisabled}
            color="primary"
            variant="contained"
            size="large"
            type="submit"
            className="btn"
            children={LL("Bt_Submit")}
          />
        </Box>
      </form>
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <MyButton cb={handleClickOpen}>{LL("Add_testimonial")}</MyButton>

      <Dialog open={open} onClose={handleClose} fullWidth className="dialogAdd">
        <DialogTitle>{LL("Add_testimonial")}</DialogTitle>
        <IconButton
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>{processForm()}</DialogContent>
      </Dialog>
    </>
  );
};

export { TestimonialDialog };
