import React from "react";

import { TicketMessageDto } from "dto/support/ticketmessage.dto";

import { Types } from "tools/types/types";
import { TicketMessageItemAdmin } from "./TicketMessageItemAdmin";
import { TicketMessageItemTeacher } from "./TicketMessageItemTeacher";
import { TicketMessageItemStudent } from "./TicketMessageItemStudent";

type Props = {
  obj: TicketMessageDto;
};

const TicketMessageItem: React.FC<Props> = ({
  obj
}) => {

  const processItem = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("typesender")) return null;
    if (!obj.typesender) return null;

    if (obj.typesender === Types.TYPE_SENDER_ADMIN)
      return <TicketMessageItemAdmin obj={obj} />;
    if (obj.typesender === Types.TYPE_SENDER_TEACHER)
      return <TicketMessageItemTeacher obj={obj} />;
    if (obj.typesender === Types.TYPE_SENDER_STUDENT)
      return <TicketMessageItemStudent obj={obj} />;

    return null;
  };

  return processItem();
};

export { TicketMessageItem };
