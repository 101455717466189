import React from "react";
import { useResource } from "hooks/useResource";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { MyNavBt } from "components/elements/button/MyNavBt";

const TeacherLogo: React.FC = () => {
  const { CF } = useResource();

  return (
    <MyNavBt href={"/"}>
    <i className="teacher-logo">
      {ComponentCommonTools.getFileBlock(CF("teacher-logo"), 25, 25)}
    </i>
    </MyNavBt>
  );
};

export { TeacherLogo };
