import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

import { HtmlTag } from "components/form/HtmlTag";
import { Config } from "tools/utils/config";
import { usePage } from "hooks/usePage";
import { Skeleton } from "@mui/material";
import { CommonTools } from "tools/utils/commontools";

const CopyRightBlock: React.FC<PageComponentProps> = () => {
  const id = Config.ID_COPYRIGHT_PAGE;
  const { object, loading } = usePage(id, true, "copy_right_block");
  // const { saveCache, getCache } = useResource();

  // const [isLoading, setIsLoading] = useState(true);
  // const [obj, setObject] = useState<PageDto | null>(null);

  // const loadObject = (_obj: any) => {
  //   // logger("loadObjectloadObjectloadObjectloadObject", _obj.obj);

  //   setObject(_obj.obj);
  //   setIsLoading(false);
  //   saveCache(_obj.obj, "CopyRightBlock");
  // };

  // const getObject = async () => {
  //   const id = process.env.REACT_APP_IDPAGE_COPYRIGHT ?? "-1";

  //   const cd = getCache("CopyRightBlock");
  //   if (cd !== false) {
  //     setObject(cd);
  //     setIsLoading(false);
  //     return;
  //   }

  //   setIsLoading(true);

  //   pageService.get(id, loadObject);
  // };

  // useEffect(() => {
  //   getObject();
  // }, []);

  // return <TopMenuLevel currentRoute={currentRoute} level={1} idparent={""} />;
  if (loading) return <Skeleton />;
  if (!object) return null;
  return (
    <HtmlTag content={CommonTools.processObjectField(object, ["content"])} />
  );
};

export { CopyRightBlock };
