import React, { useEffect, useState } from "react";

import { useResource } from "hooks/useResource";

import { MySelect } from "components/elements/Select/MySelect";

import SelectOptions from "dto/app/selectoptions.dto";

import { LanguageDto } from 'dto/static/language.dto';

import { Skeleton } from "@mui/material";


type LanguageSelectProps = {
    setObj: ((field: any, value: any) => void);
    field: any;
    obj: any;
    _defaultValue?: any;

    [key: string]: any; // for the rest of the props which we do not have types for
}

const LanguageSelect: React.FC<LanguageSelectProps> = ({
    setObj,
    field,
    obj,
    _defaultValue,

    ...props
}) => {
    const { LL, langs, _idlanguage } = useResource();

    const label = "";
    const defaultValue = _defaultValue != undefined ? _defaultValue : _idlanguage;
    const [options, setOptions] = useState<Array<SelectOptions>>(new Array<SelectOptions>());
    const [loading, setLoading] = useState<boolean>(true);
    const [value, setValue] = useState<string>();
    const [data, setData] = useState<Array<LanguageDto> | undefined>();
    const onPage = 20;

    // const [reqList, setReqList] = useState<RequestListDTO>(langService.getDefaultRequestList(onPage));

    // const loadObjects = (data: ResultListDTO) => {
    //     setData(data.objects);
    // };



    const getList = () => {
        setLoading(true);
        // langService.getList(loadObjects, {}, reqList);
        setData(langs);
    };

    const processOptions = () => {

        if (data !== undefined) {
            const options: Array<SelectOptions> = data.map((item) => {
                const obj = {
                    label: item.name,
                    value: item.id
                }
                return obj;
            })
            setOptions(options);
        }

    }


    useEffect(() => {
        setLoading(true);
        getList();
    }, []);



    useEffect(() => {
        if (data !== undefined) processOptions()
    }, [data]);

    useEffect(() => {
        checkIsLoading();
    }, [options]);

    useEffect(() => {
        if (loading) return;

        setObj(field, value);

    }, [value]);


    const checkIsLoading = () => {
        let load: boolean = false;
        if (data === undefined) load = true;
        if (options === undefined) load = true;
        // if (value === undefined) load = true;

        setLoading(load);
        if(!load) setValue(defaultValue);
    };

    return !loading ? (
        <MySelect
            defaultValue={defaultValue}
            options={options}
            _label={label}
            setValue={setValue}
            value={value}
            {...props}
        />
    ) : (<Skeleton variant="rectangular" height={50} />);
};

export { LanguageSelect };
