import React, { useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CommonTools } from "tools/utils/commontools";
import { CourseCategoryTopBar } from "components/course/coursecategory/CourseCategoryTopBar";
import { CourseTabsPanelBlock } from "components/course/course/CourseTabsPanelBlock";
import { PopularCategory } from "components/course/coursecategory/PopularCategory";
import { CourseList } from "components/course/course/CourseList";
import { CourseCategoryDto } from "dto/course/coursecategory.dto";
import { SubcategoryList } from "components/course/coursecategory/SubcategoryList";

const CourseCategoryPage: React.FC<PageComponentProps> = ({ currentRoute }) => {
  // const [idCategory, setIdCategory] = useState("");
  const [categoryObject, setCategoryObject] = useState<
    CourseCategoryDto | undefined
  >();
  const idCategory = CommonTools.getIdFromPath(currentRoute, "coursecategory");
  // useEffect(() => {
  //   setIdCategory(CommonTools.getIdFromPath(currentRoute, "coursecategory"));
  // }, [currentRoute]);

  return idCategory ? (
    <React.Fragment>
      <CourseCategoryTopBar
        currentRoute={currentRoute}
        idCategory={idCategory}
        setCategoryObject={setCategoryObject}
      />

      <CourseTabsPanelBlock currentRoute={currentRoute} />
      <PopularCategory currentRoute={currentRoute} idCategory={idCategory} />
      <CourseList
        currentRoute={currentRoute}
        idCategory={idCategory}
        categoryObject={categoryObject}
      />
    </React.Fragment>
  ) : null;
};

export { CourseCategoryPage };
