import { Email } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { SendCodeDto } from "dto/user/user.dto";
import { useAuth } from "hooks/useAuth";

import { useResource } from "hooks/useResource";
import React from "react";
import { UserService } from "services/user/user.service";

import { CommonTools } from "tools/utils/commontools";

const service = new UserService();
const ConfirmEmailAction: React.FC = () => {
  const { needConfirmEmail, user } = useAuth();
  const { LL } = useResource();

  const onClick = () => {
    service.activateEmailSendCode(
      new SendCodeDto(CommonTools.processObjectField(user, ["email"]))
    );
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          flexDirection: "row",
          alignItems: "center",
          gap: "2px",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "2px",
          }}
        >
          <Email color={needConfirmEmail ? "error" : "success"} />
          <Typography>
            {needConfirmEmail
              ? LL("Please_confirm_your_email")
              : LL("email_confirmed")}
          </Typography>
        </Box>
        {needConfirmEmail && (
          <MyButton type="button" variant="text" cb={onClick}>
            {LL("Send_confirmation_email")}
          </MyButton>
        )}
      </Box>
    </Box>
  );
};

export { ConfirmEmailAction };
