import Idto from "interfaces/idto.interface";
import { LanguageDto } from "dto/static/language.dto";
import { TypeDto } from "dto/nomenclature/type.dto";
import { CommonTools } from "tools/utils/commontools";
import SelectOptions from "dto/app/selectoptions.dto";

export class PopulateTypeDto implements Idto {
  id?: string;
  language?: LanguageDto;
  typeobject?: TypeDto;
  // idtype?: TypeDto;
  name?: string;
  idlanguage?: string | undefined;
  status?: number | undefined;
  ordercriteria?: number | undefined;
  status_name?: string | undefined;
  attachment?: any;
  video?: any;
  gallery?: any;

  static parseToSelectOptions(
    data: Array<PopulateTypeDto>
  ): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(
        new SelectOptions(
          CommonTools.processObjectField(element, ["id"]),
          CommonTools.processObjectField(element, ["name"])
        )
      );
    });
    return result;
  }
}
