import { Avatar, Breadcrumbs, Typography } from "@mui/material";
import { MyNavBt } from "components/elements/button/MyNavBt";
import BreadcrumbChildrenDto from "dto/app/breadcrumbchildren.dto";
import { useResource } from "hooks/useResource";
import React from "react";


type MyBreadcrumbsCourseProps = {
    [x: string]: any;
    childs: BreadcrumbChildrenDto[];
}

const MyBreadcrumbsCourse: React.FC<MyBreadcrumbsCourseProps> = ({ childs, ...props }) => {

    const processItem = (item: BreadcrumbChildrenDto, i: any) => {
        var key = "breadcrumbs_" + i;
        return item.url ? (
            <MyNavBt
                href={item.url}
                underline="hover"
                key={key}
                color="text.secondary"

            >
                {item.label}
            </MyNavBt>
        ) : (


            <Typography key={key} >{item.label}</Typography>
        );
    };

    return (
        <Breadcrumbs
            separator="›"
            className="breadcrumbs"
            aria-label="breadcrumb"
            color="text.disabled"
        >
            {childs.map((item, i) => {
                return processItem(item, i);
            })}
        </Breadcrumbs>
    );
}

export { MyBreadcrumbsCourse };
