import React, { useEffect, useState } from "react";

import MenuItemProps from "interfaces/menuitemprops.interface";
import { MyNavBt } from "components/elements/button/MyNavBt";
import { TopMenuLevel } from "./TopMenuLevel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const TopMenuItem: React.FC<MenuItemProps> = ({
  currentRoute,
  level,
  obj,
  hasChildren,
  ...props
}) => {
  const [isChildsShowed, setIsChildsShowed] = useState<boolean>(false);
  const [nextLevel, setNextLevel] = useState<number>(1);
  const [maxLevel, setMaxLevel] = useState<number>(1);

  useEffect(() => {
    let ml: number = parseInt(process.env.REACT_APP_MAXLEVEL_TOPMENU ?? "1");
    ml = isNaN(ml) || ml == undefined ? 1 : ml;
    setMaxLevel(ml);
  }, []);

  useEffect(() => {
    setNextLevel(level + 1);
  }, [level]);

  useEffect(() => {
    setIsChildsShowed(false);
  }, [obj]);

  const processBT = () => {
    if (!obj) return;

    if (!obj.fullurl) {
      return <>{obj.name}</>;
    }

    return <MyNavBt href={obj.fullurl}>{obj.name}</MyNavBt>;
  };

  const processDetail = () => {
    if (!obj) return <></>;

    return <Box className="test2">{processBT()}</Box>;
  };

  const processBts = () => {
    if (nextLevel > maxLevel || !hasChildren) return <></>;

    if (isChildsShowed)
      return (
        <Button
        variant="outlined"
          onClick={() => {
            setIsChildsShowed(false);
          }}
        >
          -
        </Button>
      );
    if (!isChildsShowed)
      return (
        <Button
        variant="outlined"
          onClick={() => {
            setIsChildsShowed(true);
          }}
        >
          +
        </Button>
      );
  };

  const processChilds = () => {
    if(!hasChildren) return <></>;
    if (nextLevel > maxLevel) return <></>;
    if (!isChildsShowed) return <></>;
    if (!obj) return <></>;

    return (
      <Box className="test1">
        <TopMenuLevel
          currentRoute={currentRoute}
          level={nextLevel}
          idparent={obj.id ?? ""}
        />
      </Box>
    );
  };

  return (
    <Box
      onMouseOut={() => {
        setIsChildsShowed(false);
      }}
      onMouseOver={() => {
        setIsChildsShowed(true);
      }}
    >
      {processDetail()}
      {processBts()}
      {processChilds()}
    </Box>
  );
};

export { TopMenuItem };
