
import SelectOptions from 'dto/app/selectoptions.dto';
import Idto from 'interfaces/idto.interface';
import { CommonTools } from 'tools/utils/commontools';

export class PostLocalityDto implements Idto {

    status?: number;
    ordercriteria?: number;
    idlanguage?: string;
    idlocality?: string;
    idparent?: string;
    idtypelocality?: string;
    name?: string;
    idcountry?: string;
}

export class LocalityDto implements Idto {
    
    id?: string;
    status?: number;
    ordercriteria?: number;
    idlanguage?: string;
    idlocality?: string;
    idcountry?: string;
    language?: any;
    code?: string;
    level?: number;
    idparent?: string;
    idtypelocality?: string;
    name?: string;
    status_name?: string;
    country?: any;
    typelocality?: any;

    static parseToSelectOptions(data: Array<LocalityDto>): Array<SelectOptions> {
        if (!data) return [];
        if (!Array.isArray(data)) return [];
        if (!data.length) return [];
        let result: Array<SelectOptions> = [];
        data.forEach((element) => {
          result.push(LocalityDto.parseOption(element));
        });
        return result;
      }
    
      static parseOption = (obj: LocalityDto): SelectOptions => {
        return new SelectOptions(
          CommonTools.processObjectField(obj, ["id"]),
          CommonTools.processObjectField(obj, ["name"])
        );
      };
}