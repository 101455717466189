import { Delete, Save } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import MyTextField from "components/form/mytextfield";
import { PostVideoForCourseDto } from "dto/system/video.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useEffect } from "react";
import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObj: PostVideoForCourseDto;
  onSubmit: (obj: PostVideoForCourseDto) => void;
  loading: boolean;
  onDelete: () => void;
  canDelete: boolean;
};

const FormCourseVideo: React.FC<Props> = ({
  defaultObj,
  onSubmit,
  loading,
  onDelete,
  canDelete,
}) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField, setObject] =
    useForm<PostVideoForCourseDto>(
      defaultObj,
      RequiredValidator.getValidators(["videolocation", "idparent"])
    );

  useEffect(() => {
    if (!defaultObj) return;
    setObject(defaultObj);
  }, [defaultObj]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled || loading) return;
    onSubmit(obj);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box sx={{ minWidth: "350px" }}>
          <Stack direction="row" alignItems={"center"} justifyContent="center" >
            <MyTextField
              fullWidth
              id="videolocation"
              name="videolocation"
              variant="standard"
              className="form-input"
              color="primary"
              _label={LL("videolocation")}
              value={obj.videolocation}
              setObj={setObjField}
              _vresults={undefined}
            />

            <Box>
              <MyButton disabled={disabled || loading} type="submit">
                <Save />
              </MyButton>
            </Box>
            {canDelete && (
              <Box>
                <MyButton cb={onDelete}>
                  <Delete color="secondary" />
                </MyButton>
              </Box>
            )}
          </Stack>
        </Box>
      </form>
    </Box>
  );
};

export { FormCourseVideo };
