import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

import { AdvertisementBannerBottom } from "components/static/advertisement/AdvertisementBannerBottom";

const HomeBannersBottomBlock: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  return <AdvertisementBannerBottom currentRoute={currentRoute} />;
};

export { HomeBannersBottomBlock };
