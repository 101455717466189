// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// import { PostBlogCategoryDto } from "dto/blog/blogcategory.dto";
// import ResultListDTO from "dto/app/resultlist.dto";
// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import { BlogCategoryRepository } from "repositories/static/blogcategory.repository";
// import { Status } from "tools//types/status";
// import GeneralService from "services/general.service";
// import RequestFilterDTO from "dto/app/requestfilter.dto";

// export default class BlogCategoryService
//   extends GeneralService
//   implements IService
// {
//   blogCategoryRepository: BlogCategoryRepository = new BlogCategoryRepository();
//   constructor() {
//     super();
//     this.handleGetList = this.handleGetList.bind(this);
//     this.handleGet = this.handleGet.bind(this);
//   }
//   handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;

//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async update(
//     id: string,
//     cb?: any,
//     cbparameters?: any,
//     data?: any
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.blogCategoryRepository.update(
//       id,
//       this.handleUpdate,
//       this.prepareObjToUpdate(data),
//       cbparameters
//     );
//   }

//   handleDelete(result?: any, cbparameters?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters);
//     }
//   }

//   async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.blogCategoryRepository.delete(id, this.handleDelete, cbparameters);
//   }

//   handleGet(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;
//     this.populateObject(rez.obj, result.obj, [
//       { key: "status", type: "status" },
//     ]);
//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async get(
//     id: string,
//     cb?: any,
//     cbparameters?: any,
//     idLanguage?: string
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.blogCategoryRepository.get(
//       id,
//       this.handleGet,
//       cbparameters,
//       idLanguage
//     );
//     if (cb == undefined) {
//       return this.handleGet(t);
//     }
//   }

//   handleGetList(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultListDTO();

//     rez = result;
//     if (result.err) return;

//     if (result.objects && rez.objects) {
//       for (let index = 0; index < result.objects.length; index++) {
//         const obj = result.objects[index];
//         if (rez.objects[index]) {
//           this.populateObject(rez.objects[index], obj, [
//             { key: "status", type: "status" },
//           ]);
//         }
//       }
//     }

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async getList(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.blogCategoryRepository.getList(
//       this.handleGetList,
//       cbparameters,
//       data
//     );
//     if (cb == undefined) {
//       return this.handleGetList(t);
//     }
//   }

//   handleAdd(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   prepareObjToAdd(obj: any): PostBlogCategoryDto {
//     const rez = new PostBlogCategoryDto();

//     rez.idlanguage = obj.idlanguage;
//     rez.status = obj.status;
//     rez.ordercriteria = obj.ordercriteria;

//     rez.idparent = obj.idparent !== undefined ? obj.idparent : "";
//     rez.idtypeblogcategory = obj.idtypeblogcategory;
//     rez.name = obj.name;

//     return rez;
//   }

//   prepareObjToUpdate(obj: any): PostBlogCategoryDto {
//     const rez = new PostBlogCategoryDto();

//     rez.idlanguage = obj.idlanguage;
//     rez.status = obj.status;
//     rez.ordercriteria = obj.ordercriteria;
//     rez.idblogcategory = obj.idblogcategory;

//     rez.idparent = obj.idparent !== undefined ? obj.idparent : "";
//     rez.idtypeblogcategory = obj.idtypeblogcategory;
//     rez.name = obj.name;

//     return rez;
//   }

//   async add(cb?: any, cbparameters?: any, data?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.blogCategoryRepository.add(
//       this.handleGetList,
//       cbparameters,
//       this.prepareObjToAdd(data)
//     );
//   }

//   getDefaultRequestListSelect(onPage?: number): RequestListDTO {
//     onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
//     if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
//     var defaultRequestList = new RequestListDTO();
//     defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
//     defaultRequestList.onpage = onPage;
//     var defaultRequestSortCriteria = new RequestSortCriteriaDTO();
//     defaultRequestSortCriteria.asc = true;
//     defaultRequestSortCriteria.field = "name";
//     defaultRequestList.sortcriteria = [];
//     defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
//     return defaultRequestList;
//   }

//   getDefaultRequestList(obj: RequestListDTO, idParent: string): RequestListDTO {
//     if (
//       !obj.sortcriteria ||
//       !Array.isArray(obj.sortcriteria) ||
//       !obj.sortcriteria.length
//     ) {
//       var sobj = new RequestSortCriteriaDTO();
//       sobj.asc = true;
//       sobj.field = "name";

//       obj.sortcriteria = [];
//       obj.sortcriteria?.push(sobj);
//     }
//     const filters = new Array<RequestFilterDTO>();
//     const filter = new RequestFilterDTO();
//     filter.field = "idparent";
//     filter.values = [idParent];
//     filters.push(filter);
//     obj.filters = filters;

//     return obj;
//   }
// }

import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { BlogCategoryDto } from "dto/static/blogcategory.dto";

import IService from "interfaces/iservice.interface";
import {BlogRepository} from "repositories/static/blog.repository";
import {BlogCategoryRepository} from "repositories/static/blogcategory.repository";

import GeneralService from "services/globalgeneral.service";


export class BlogCategoryService
  extends GeneralService<BlogRepository>
  implements IService
{
  constructor() {
    super(
      new BlogCategoryRepository(),
      [{ key: "status", type: "status" }],
      "name"
    );
  }

  prepareObjToUpdate(obj: any): BlogCategoryDto {
    const rez = new BlogCategoryDto();

    rez.idlanguage = obj.idlanguage;
    rez.status = obj.status;
    rez.ordercriteria = obj.ordercriteria;
    rez.idblogcategory = obj.idblogcategory;

    rez.idparent = obj.idparent !== undefined ? obj.idparent : "";
    rez.idtypeblogcategory = obj.idtypeblogcategory;
    rez.name = obj.name;

    return rez;
  }

  getDefaultRequestListParent(
    obj: RequestListDTO,
    idParent: string
  ): RequestListDTO {
    if (
      !obj.sortcriteria ||
      !Array.isArray(obj.sortcriteria) ||
      !obj.sortcriteria.length
    ) {
      var sobj = new RequestSortCriteriaDTO();
      sobj.asc = true;
      sobj.field = "name";

      obj.sortcriteria = [sobj];
    }
    const filters = new Array<RequestFilterDTO>();
    const filter = new RequestFilterDTO();
    filter.field = "idparent";
    filter.values = [idParent];
    filters.push(filter);
    obj.filters = filters;

    return obj;
  }
  getDefaultRequestListSelect(onPage?: number): RequestListDTO {
    onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    var defaultRequestList = new RequestListDTO();
    defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    defaultRequestList.onpage = onPage;
    var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
    defaultRequestSortCriteria.asc = true;
    defaultRequestSortCriteria.field = 'name';
    defaultRequestList.sortcriteria = []
    defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
    return defaultRequestList;
}
}
