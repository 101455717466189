import React from "react";

import { BlogCategoryLevel } from "./BlogCategoryLevel";
import { Box } from "@mui/material";

type BlockBlogCategoryProps = {
  currentRoute: any;
  setBlogCategoryId: any;
};
const BlockBlogCategory: React.FC<BlockBlogCategoryProps> = ({
  setBlogCategoryId,
  currentRoute,
  ...props
}) => {
  return (
    <Box className="blogCategory">
      <BlogCategoryLevel
        currentRoute={currentRoute}
        level={1}
        idparent={""}
        setBlogCategoryId={setBlogCategoryId}
      />
    </Box>
  );
};

export { BlockBlogCategory };
