import React, { useState } from "react";
import { TicketMessageDto } from "dto/support/ticketmessage.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { TicketMessageService } from "services/support/ticketmessage.service";
import RequestFilterDTO from "dto/app/requestfilter.dto";

import { Box, List, ListItem, Skeleton } from "@mui/material";
import { TicketMessageItem } from "./TicketMessageItem";

import { Types } from "tools/types/types";
import { SkeletonTicketMessages } from "components/elements/Skeleton/SkeletonTicket";
import { useList } from "hooks/useList";
import { CallbackType } from "interfaces/commontypes.interface";
import { TicketDto } from "dto/support/ticket.dto";

import { TicketResponse } from "./TicketResponse";


const service = new TicketMessageService();

type Props = {
  idTicket: string;
  ticket: TicketDto;
  updateArray: (object: TicketDto) => void;
};
const TicketMessageList: React.FC<Props> = ({
  idTicket,
  ticket,
  updateArray,
}) => {
  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    if (!idTicket) return;
    service.getList(cb, cbParams, req);
  };

  const [loading, objects, , , setObjects] = useList<TicketMessageDto>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("idticket", [idTicket])],
      1,
      -1
    ),
    [idTicket]
  );

  const updateObjects = (newObj: TicketMessageDto) => {
    if (!objects) return;
    const updatedArray = [...objects, newObj];

    setObjects(updatedArray);
  };

  const processList = () => {
    if (!objects) return null;
    if (!objects.length) return null;

    return (
      <List sx={{ width: "100%" }}>
        {objects.map((item: TicketMessageDto, i: number) => {
          return processItem(item, i);
        })}
      </List>
    );
  };

  const justifyContentMap = {
    [Types.TYPE_SENDER_ADMIN]: "flex-start",
    [Types.TYPE_SENDER_TEACHER]: "flex-end",
    [Types.TYPE_SENDER_STUDENT]: "flex-end",
  };

  const processItem = (obj: TicketMessageDto, index: number) => {
    const justifyContent =
      obj.typesender !== undefined
        ? justifyContentMap[obj.typesender]
        : "center";
    return (
      <ListItem
        key={index}
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: justifyContent,
          alignItems: "center",
          pb: 0,
        }}
      >
        <TicketMessageItem obj={obj} />
      </ListItem>
    );
  };

  const [loadingAdd, setLoadingAdd] = useState<boolean>(false);
  const processComponent = () => {
    if (loading) return <SkeletonTicketMessages lines={1} />;
    if (!ticket) return null;
    if (!objects) return null;
    return (
      <Box sx={{ width: "100%" }}>
        {processList()}
        {loadingAdd && <Skeleton height={20} variant="rectangular" />}
        <TicketResponse
          ticket={ticket}
          type={ticket.type ?? 0}
          updateObjects={updateObjects}
          loading={loadingAdd}
          setLoading={setLoadingAdd}
          updateArray={updateArray}
        />
      </Box>
    );
  };

  return processComponent();
};

export { TicketMessageList };
