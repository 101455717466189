import React, { useContext } from "react";

import { Box, Typography } from "@mui/material";

import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { ClassroomContext } from "context/classroom.context";

import { ComponentCommonTools } from "tools/utils/componentcommontools";

import { ClassroomVideoPlayer } from "./ClassroomVideoPlayer";
import { ClassroomZoomBlock } from "../ClassroomZoomBlock";

export const InteractionArea: React.FC<PageComponentProps> = ({
  currentRoute,
}) => {
  const {
    state: { selectedLessonObject },
  } = useContext(ClassroomContext);

  const processZoomBlock = () => {
    if (!selectedLessonObject) return null;
    if (!selectedLessonObject.hasOwnProperty("islive")) return null;
    if (!selectedLessonObject.islive) return null;
    if (!selectedLessonObject.hasOwnProperty("id")) return null;
    if (!selectedLessonObject.id) return null;

    return (
      <ClassroomZoomBlock
        currentRoute={currentRoute}
        idLesson={selectedLessonObject.id}
        lesson={selectedLessonObject}
      />
    );
  };

  const processComponent = () => {
    if (!selectedLessonObject) return null;
    return (
      <Box>
        <Typography variant="h3" my={3}>
          {ComponentCommonTools.processObjectField(
            selectedLessonObject,
            "name"
          )}
        </Typography>
        {processZoomBlock()}
        <ClassroomVideoPlayer currentRoute={currentRoute} />
      </Box>
    );
  };

  return processComponent();
};
