import React, { useContext, useState } from "react";
import { useResource } from "hooks/useResource";

import { Box, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { CourseSearchContext } from "context/coursesearch.context";
import SearchIcon from "@mui/icons-material/Search";

const CourseSearchField: React.FC = () => {
  const { LL } = useResource();
  const {
    state: { searchValue },
    actions: { searchFunction },
  } = useContext(CourseSearchContext);

  const [search, setSearch] = useState(searchValue);
  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (!search) return;
    searchFunction(search);
    
  };

  const handleChange = (event: any) => {
    setSearch(event.target.value);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Stack direction={"row"} spacing={2}>
          <TextField
            label={LL("Ce vrei să înveți?")}
            onFocus={handleChange}
            onChange={handleChange}
            value={search}
            fullWidth
                        variant="outlined"
            className="input"
          />
          <Button type="submit">
            <SearchIcon />
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export { CourseSearchField };
