import { useAuth } from "hooks/useAuth";
import React, { useState } from "react";
import { Status } from "tools/types/status";
import { ConfirmEmail } from "./ConfirmEmail";

type Props = {
  children: React.ReactNode;
};
const ConfirmEmailBoundary: React.FC<Props> = ({ children }) => {
  const { user } = useAuth();

  const needConfirmEmail =
    user && user.statusemail === Status.INACTIVE ? true : false;
  const [open, setOpen] = useState(needConfirmEmail);
  const handleClose = () => setOpen(false);
  return (
    <React.Fragment>
      {needConfirmEmail && (
        <ConfirmEmail open={open} handleClose={handleClose} />
      )}
      {children}
    </React.Fragment>
  );
};

export { ConfirmEmailBoundary };
