import React from "react";
import { useResource } from "hooks/useResource";

import { Box, Typography, Stack, Link, Grid } from "@mui/material";

import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { TestResultDto } from "dto/course/testresult.dto";
import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";

import { TestResultQuestionDialog } from "./TestResultQuestionDialog";
import { StatusChip } from "components/elements/Chip/StatusChip";
import { CommonTools } from "tools/utils/commontools";

type Props = {
  obj: TestResultDto;
};

const TestResultItem: React.FC<Props> = ({ obj }) => {
  const { LL } = useResource();

  const processUserName = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("student")) return null;
    if (!obj.student) return null;
    if (!obj.student.hasOwnProperty("user")) return null;
    if (!obj.student.user) return null;
    let fullUrl = "";
    if (obj.student.user.hasOwnProperty("fullurl") && obj.student.user.fullurl)
      fullUrl = obj.student.user.fullurl;

    if (!fullUrl) {
      return <Typography className="name">{processUserFullName()}</Typography>;
    }
    return (
      <Link
        href={fullUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="name">
        <Typography>{processUserFullName()}</Typography>
      </Link>
    );
  };

  const processUserFullName = () => {
    if (!obj) return "";
    if (!obj.hasOwnProperty("student")) return "";
    if (!obj.student) return "";
    let name = "";
    let surname = "";
    if (obj.student.hasOwnProperty("name") && obj.student.name)
      name = obj.student.name;
    if (obj.student.hasOwnProperty("surname") && obj.student.surname)
      surname = obj.student.surname;

    return name + " " + surname;
  };
  const processAvatar = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("student")) return null;
    if (!obj.student) return null;
    let name = "";
    let surname = "";
    if (obj.student.hasOwnProperty("name") && obj.student.name)
      name = obj.student.name;
    if (obj.student.hasOwnProperty("surname") && obj.student.surname)
      surname = obj.student.surname;

    return (
      <MyAvatar
        obj={obj.student}
        name={name}
        surname={surname}
        sx={{ mr: 1 }}
      />
    );
  };
  const processUser = () => {
    if (!obj) return null;
    return (
      <Box mt={1}>
        <Stack direction="row" alignItems="center" spacing={1}>
          {processAvatar()}
          <Stack direction="column">
            {processUserName()}
            <Typography>
              {CommonTools.processObjectField(obj, ["student", "email"])}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    );
  };
  const processCourseName = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("course")) return null;
    if (!obj.course) return null;
    let fullUrl = "";
    if (obj.course.hasOwnProperty("fullurl") && obj.course.fullurl)
      fullUrl = obj.course.fullurl;
    if (!fullUrl) {
      return CommonTools.processObjectField(obj, ["course", "name"]);
    } else {
      return (
        <Link href={fullUrl} target="_blank" rel="noopener noreferrer">
          {CommonTools.processObjectField(obj, ["course", "name"])}
        </Link>
      );
    }
  };
  const processCourseInfo = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("course")) return null;
    if (!obj.course) return null;
    return (
      <Box>
        <Stack direction="row" spacing={1}>
          <Typography variant="h5" className="link" component={"div"}>
            {processCourseName()}
          </Typography>
        </Stack>
      </Box>
    );
  };

  const processTestInfo = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("test")) return null;
    if (!obj.test) return null;
    return (
      <Box>
        <Typography variant="h5">
          {CommonTools.processObjectField(obj, ["test", "name"])}
        </Typography>
        <Typography className="color-boulder" component={"div"}>
          {ComponentCommonTools.processTypesField(obj.test, "type", true)}
        </Typography>
        <Stack direction="row" spacing={2}>
          <Box>
            <Typography variant="caption" color="text.disabled">
              {LL("minscore")}
            </Typography>
            <Typography>
              {CommonTools.processObjectField(obj, ["minscore"])}
            </Typography>
          </Box>
          <Box>
            <Typography variant="caption" color="text.disabled">
              {LL("maxscore")}
            </Typography>
            <Typography>
              {CommonTools.processObjectField(obj, ["maxscore"])}
            </Typography>
          </Box>
        </Stack>
      </Box>
    );
  };
  const processTestResult = () => {
    if (!obj) return null;
    return (
      <Box mb={2}>
        <Stack direction="row" spacing={2}>
          <Box>
            <Typography variant="caption" color="text.disabled">
              {LL("date")}
            </Typography>
            <Typography>
              {CommonTools.processObjectField(obj, ["date_name"])}
            </Typography>
          </Box>
          <Box>
            <Typography variant="caption" color="text.disabled">
              {LL("totalscore_student")}
            </Typography>
            <Typography>
              {CommonTools.processObjectField(obj, ["totalscore"])}
            </Typography>
          </Box>
          <Box>
            <Typography variant="caption" color="text.disabled">
              {LL("percentage")}
            </Typography>
            <Typography>
              {CommonTools.processObjectField(obj, ["percentage"])}
            </Typography>
          </Box>
          <Typography component={"div"}>
            <StatusChip obj={obj} variant="outlined" />
          </Typography>
        </Stack>
      </Box>
    );
  };
  const processTestResultQuestions = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("id")) return null;
    if (!obj.id) return null;
    return <TestResultQuestionDialog idTestResult={obj.id} />;
  };
  const processItem = () => {
    if (!obj) return null;
    return (
      <Box className="testResultItem">
        <Grid container spacing={2} alignItems="center">
          <Grid item lg={3} md={3} sm={6} xs={12}>
            {processTestInfo()}
          </Grid>
          <Grid item lg={5} md={4} sm={6} xs={12}>
            {processCourseInfo()}
            {processUser()}
          </Grid>
          <Grid item lg={4} md={5} xs={12}>
            {processTestResult()}
            {processTestResultQuestions()}
          </Grid>
        </Grid>
      </Box>
    );
  };
  return processItem();
};

export { TestResultItem };
