import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

import { IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { SearchDialog } from "components/general/SearchDialog";

const SearchBlock: React.FC<PageComponentProps> = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false); // Close the dialog when handleClose is called
  };

  return (
    <React.Fragment>
      {!open ? (
        <IconButton sx={{ mx: 1 }} onClick={handleClickOpen}>
          <SearchIcon />
        </IconButton>
      ) : null}
      <SearchDialog open={open} setOpen={setOpen} />
    </React.Fragment>
  );
};

export { SearchBlock };
