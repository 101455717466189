import { Box } from "@mui/material";
import { CircularLoading } from "components/elements/loading/CircularLoading";
import { FromSocialUser } from "components/static/social/FromSocialUser";

import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { PopulateTypeDto } from "dto/nomenclature/populatetype.dto";
import { SocialNewDto } from "dto/user/social.dto";
import { useAuth } from "hooks/useAuth";
import { useList } from "hooks/useList";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import React from "react";
import { TypeSocialService } from "services/nomenclature/typesocial.service";
import { SocialService } from "services/user/social.service";
import { Status } from "tools/types/status";
import { CommonTools } from "tools/utils/commontools";

const serviceType = new TypeSocialService();
const service = new SocialService();
const EditSocialUser: React.FC<PageComponentProps> = () => {
  const { user } = useAuth();
  const idUser = CommonTools.processObjectField(user, ["id"]);
  const [loadingSubmit, setLoadingSubmit] = React.useState<boolean>(false);
  const getListType = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    serviceType.getList(cb, cbParams, req);
  };
  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    if (!idUser) return;
    service.getList(cb, cbParams, req);
  };

  const [loadingType, objectsType] = useList<PopulateTypeDto>(
    getListType,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()])],
      1,
      -1
    ),
    []
  );

  const [loading, objects, , , setObjects] = useList<SocialNewDto>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("iduser", [idUser])],
      1,
      -1
    ),
    [idUser]
  );

  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    const obj: any = result.obj ?? null;
    if (!obj) return;
    const objects = obj.objects ?? [];
    setObjects(objects);
    setLoadingSubmit(false);
  };
  const onSubmit = (objects: SocialNewDto[]) => {
    setLoadingSubmit(true);
    service.addArray(handleResult, {}, objects);
  };
  if (!idUser) return null;
  if (loadingType || loading) {
    return <CircularLoading />;
  }
  if (!objectsType || !objects) return null;
  return (
    <Box maxWidth="sm" mx="auto">
      <FromSocialUser
        idUser={idUser}
        setObjects={setObjects}
        objects={objects}
        objectsTypeSocial={objectsType}
        onSubmit={onSubmit}
        loading={loadingSubmit}
      />
    </Box>
  );
};

export { EditSocialUser };
