import React, { useState } from "react";

import { useResource } from "hooks/useResource";

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

import { CourseDto } from "dto/course/course.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

type CourseVideoDialogProps = {
  obj: CourseDto;
};

const CourseVideoDialog: React.FC<CourseVideoDialogProps> = ({
  obj,
  ...props
}) => {
  const { LL } = useResource();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const processVideoDisplay = () => {
    if (!open) return null;
    if (!obj) return null;
    if (!obj.hasOwnProperty("video")) return null;
    if (!obj.video) return null;
    if (!obj.video.length) return null;
    if (!obj.video[0]) return null;
    return ComponentCommonTools.getFileBlockVideo(obj.video[0], 550, 400, true);
  };

  const processComponent = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("video")) return null;
    if (!obj.video) return null;
    if (!obj.video.length) return null;
    if (!obj.video[0]) return null;

    return (
      <>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50%"
        >
          <IconButton onClick={handleClickOpen} size="large">
            <PlayCircleOutlineIcon
              fontSize="large"
              sx={{
                color: "white",
                fontSize: "60px",
              }}
            />
          </IconButton>
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          // className="dialogAdd"
        >
          <DialogTitle>{LL("Course_Video")}</DialogTitle>
          <IconButton
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>{processVideoDisplay()}</DialogContent>
        </Dialog>
      </>
    );
  };

  return processComponent();
};

export { CourseVideoDialog };
