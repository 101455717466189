import { Box } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { LessonDto } from "dto/course/lesson.dto";

import { useResource } from "hooks/useResource";
import React, { useState } from "react";

type Props = {
  currentRoute: any;
  idLesson: string;
  lesson: LessonDto;
};

const ClassroomZoomBlock: React.FC<Props> = ({
  currentRoute,
  idLesson,
  lesson,
}) => {
  const { LL } = useResource();

  const [open, setOpen] = useState(false);

  const processClassroomZoomBlock = () => {
    if (!open) return null;
    return <Box height={200}>ClassroomZoomBlock</Box>;
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <Box>
      {processClassroomZoomBlock()}
      <MyButton cb={handleToggle}>{LL("Zoom")}</MyButton>
    </Box>
  );
};

export { ClassroomZoomBlock };
