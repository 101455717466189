import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from "@mui/material";
import { TicketDto } from "dto/support/ticket.dto";
import React, { useState } from "react";
import { SupportSelectedTicket } from "./SupportSelectedTicket";
import { CommonTools } from "tools/utils/commontools";
import { ExpandMore } from "@mui/icons-material";

type Props = {
  obj: TicketDto;
  updateArray: (object: TicketDto) => void;
};

const TicketListMenuItem: React.FC<Props> = ({ obj,updateArray }) => {
  const [expanded, setExtended] = useState(false);

  const onChange = (event: React.SyntheticEvent, expanded: boolean) => {
    setExtended(expanded);
  };

  if (!obj) return null;
  const id = CommonTools.processObjectField(obj, ["id"]);
  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls={`panel${id}-content`}
        id={`panel${id}-header`}
      >
        <Typography component={"div"}>
          <Stack direction="column" spacing={1}>
            <Typography>
              {CommonTools.processObjectField(obj, ["status_name"])}
            </Typography>
            <Typography>
              {CommonTools.processObjectField(obj, ["ticketcategory", "name"])}
            </Typography>
            <Typography>
              {CommonTools.processObjectField(obj, ["date_name"])}
            </Typography>
          </Stack>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {expanded ? <SupportSelectedTicket obj={obj} updateArray={updateArray}/> : null}
      </AccordionDetails>
    </Accordion>
  );
};

export { TicketListMenuItem };
