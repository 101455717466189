import React, {  useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

import { LanguageBlockBt } from "./LanguageBlockBt";

import { LanguageBlockDetail } from "./LanguageBlockDetail";
import Menu from '@mui/material/Menu';

const BottomLanguageBlock: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const [isdetailopened, setIsDetailOpened] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const toggleDetail = (event?: React.MouseEvent<HTMLButtonElement>) => {
    const t: boolean = !isdetailopened;
    setIsDetailOpened(t);
    if (event !== undefined) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsDetailOpened(false);
    setAnchorEl(null);
  };

  const processDetail = () => {
    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isdetailopened}
        // onClose={toggleDetail}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <LanguageBlockDetail
          toggleDetail={toggleDetail}
          isopened={isdetailopened}

        />
      </Menu>
    );
  };

  return (
    <>
      <LanguageBlockBt  toggleDetail={toggleDetail} isdetailopened={isdetailopened} />
      {processDetail()}
    </>
  );
};

export { BottomLanguageBlock };
