import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";
import { useResponsive } from "hooks/useResponsive";

const SkeletonSection: React.FC = () => {
  const { matchesSM } = useResponsive();

  const skeletonHeight = matchesSM ? "365px" : "240px";

  const gridItem = (
    <Grid xs={12}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          marginX: "auto",
          flexDirection: "column",
          height: "200px",
        }}
      >
        <Skeleton
          variant="text"
          sx={{ fontSize: "2rem", width: "60%", marginX: "auto" }}
        />
        <Skeleton
          variant="text"
          sx={{ fontSize: "1.25rem", width: "40%", marginX: "auto" }}
        />
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem", width: "40%", marginX: "auto" }}
        />
        <Skeleton
          sx={{ height: "50px", width: "80px", marginX: "auto", mt: 2 }}
          variant="rounded"
        />
      </Box>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", height: skeletonHeight }}>
      <Box
        sx={{
          width: "90%",
          padding: "40px 15px 40px 15px",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          margin: "auto",
        }}
      >
        <Grid
          container
          xs={12}
          sx={{ maxWidth: "1522px", margin: "auto" }}
          spacing={2}
        >
          {Array(1)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

const SkeletonSection2: React.FC = () => {
  const { matchesSM, matchesMD } = useResponsive();

  let gridItemsCount;
  let gridScndRowItemsCount;
  if (matchesMD) {
    gridItemsCount = 5;
    gridScndRowItemsCount = 7;
  } else if (matchesSM) {
    gridItemsCount = 6;
    gridScndRowItemsCount = 6;
  } else {
    gridItemsCount = 12;
    gridScndRowItemsCount = 12;
  }
  const skeletonHeight = matchesSM ? "363px" : "440px";

  const gridItem = (
    <Grid xs={12}>
      <Grid container spacing={2}>
        <Grid
          xs={gridItemsCount}
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "end" },
            alignItems: "center",
            height: "200px",
          }}
        >
          <Skeleton
            sx={{ height: "90%", width: "50%", minWidth: "250px" }}
            variant="rounded"
          />
        </Grid>
        <Grid xs={gridScndRowItemsCount}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: { xs: "center", sm: "start" },
              flexDirection: "column",
              height: "200px",
            }}
          >
            <Skeleton
              variant="text"
              sx={{ fontSize: "2rem", width: "60%", minWidth: "200px" }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1.25rem", width: "40%", minWidth: "150px" }}
            />
            <Skeleton
              sx={{ height: "50px", width: "110px", mt: 2 }}
              variant="rounded"
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", height: skeletonHeight }}>
      <Box
        sx={{
          width: "90%",
          padding: "40px 15px 40px 15px",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          margin: "auto",
        }}
      >
        <Grid
          container
          xs={12}
          sx={{ maxWidth: "1522px", margin: "auto" }}
          spacing={2}
        >
          {Array(1)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

const SkeletonScnTeachers: React.FC = () => {
  const { matchesSM, matchesMD } = useResponsive();

  let gridItemsCount;
  if (matchesMD) {
    gridItemsCount = 3;
  } else if (matchesSM) {
    gridItemsCount = 6;
  } else {
    gridItemsCount = 12;
  }
  const skeletonHeight = matchesMD ? "240px" : matchesSM ? "400px" : "700px";

  const gridItem = (
    <Grid xs={gridItemsCount}>
      <Grid container spacing={2}>
        <Grid xs={4}>
          <Skeleton variant="circular" width={50} height={50} />
        </Grid>
        <Grid xs={8} sx={{ width: "60%" }}>
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "100%" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", height: skeletonHeight }}>
      <Box
        sx={{
          width: "90%",
          padding: "40px 15px 40px 15px",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          margin: "auto",
        }}
      >
        <Grid
          container
          xs={12}
          sx={{ maxWidth: "1522px", margin: "auto" }}
          spacing={2}
        >
          {Array(8)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

const SkeletonScnReviews: React.FC = () => {
  const { matchesSM, matchesMD } = useResponsive();

  let gridItemsCount;
  if (matchesMD) {
    gridItemsCount = 4;
  } else if (matchesSM) {
    gridItemsCount = 12;
  } else {
    gridItemsCount = 12;
  }
  const skeletonHeight = matchesMD ? "366px" : "830px";

  const gridItem = (
    <Grid
      xs={gridItemsCount}
      sx={{ mb: 4, maxWidth: "500px", minWidth: { xs: "310px", sm: "250px" } }}
    >
      <Grid container spacing={4}>
        <Grid
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "80%" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "90%" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "80%" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "90%" }} />
        </Grid>
        <Grid
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "40%" }} />
        </Grid>
        <Grid xs={2}>
          <Skeleton variant="circular" width={50} height={50} />
        </Grid>
        <Grid
          xs={5}
          sx={{
            width: "30%",
            display: "flex",
            justifyContent: "center",
            alignContent: "start",
            alignItems: "start",
            flexDirection: "column",
          }}
        >
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem", width: "70%", ml: 2 }}
          />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem", width: "90%", ml: 2 }}
          />
        </Grid>
        <Grid
          xs={5}
          sx={{
            width: "40%",
            display: "flex",
            justifyContent: "center",
            alignContent: "end",
            alignItems: "end",
            flexDirection: "column",
          }}
        >
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "70%" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "40%" }} />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", height: skeletonHeight }}>
      <Box
        sx={{
          width: "90%",
          height: skeletonHeight,
          padding: "0px 15px 0px 15px",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          margin: "auto",
        }}
      >
        <Grid
          container
          xs={12}
          sx={{
            maxWidth: "1522px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
          spacing={2}
        >
          {Array(3)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

const SkeletonSectionText: React.FC = () => {
  const { matchesSM } = useResponsive();

  const skeletonHeight = matchesSM ? "365px" : "380px";

  const gridItem = (
    <Grid xs={12}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          marginX: "auto",
          flexDirection: "column",
          height: "200px",
        }}
      >
        <Skeleton
          variant="text"
          sx={{ fontSize: "2rem", width: "60%", marginX: "auto" }}
        />
        <Skeleton
          variant="text"
          sx={{ fontSize: "1.25rem", width: "40%", marginX: "auto" }}
        />
        <Skeleton
          variant="text"
          sx={{ fontSize: "1.25rem", width: "50%", marginX: "auto" }}
        />
        <Skeleton
          variant="text"
          sx={{ fontSize: "1.25rem", width: "45%", marginX: "auto" }}
        />
        <Skeleton
          variant="text"
          sx={{ fontSize: "1.25rem", width: "58%", marginX: "auto" }}
        />
        <Skeleton
          variant="text"
          sx={{ fontSize: "1.25rem", width: "52%", marginX: "auto" }}
        />
        <Skeleton
          variant="text"
          sx={{ fontSize: "1.25rem", width: "29%", marginX: "auto" }}
        />
      </Box>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", height: skeletonHeight }}>
      <Box
        sx={{
          width: "90%",
          padding: "40px 15px 40px 15px",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          margin: "auto",
        }}
      >
        <Grid
          container
          xs={12}
          sx={{ maxWidth: "1522px", margin: "auto" }}
          spacing={2}
        >
          {Array(1)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

const SkeletonCardRight: React.FC = () => {
  const { matchesSM, matchesMD } = useResponsive();

  let gridItemsCount;
  let gridScndRowItemsCount;
  if (matchesMD) {
    gridItemsCount = 5;
    gridScndRowItemsCount = 7;
  } else if (matchesSM) {
    gridItemsCount = 6;
    gridScndRowItemsCount = 6;
  } else {
    gridItemsCount = 12;
    gridScndRowItemsCount = 12;
  }
  const skeletonHeight = matchesSM ? "400px" : "720px";

  const gridItem = (
    <Grid xs={12}>
      <Grid container spacing={2}>
        <Grid xs={gridScndRowItemsCount}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: { xs: "center", sm: "start" },
              flexDirection: "column",
              height: "320px",
            }}
          >
            <Skeleton
              variant="text"
              sx={{ fontSize: "1.5rem", width: "80%", minWidth: "200px" }}
            />
            <Skeleton
              variant="text"
              sx={{
                fontSize: "1.25rem",
                width: "78%",
                minWidth: "150px",
                mt: 2,
              }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1.25rem", width: "40%", minWidth: "150px" }}
            />
            <Skeleton
              variant="text"
              sx={{
                fontSize: "1.25rem",
                width: "75%",
                minWidth: "150px",
                mt: 2,
              }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1.25rem", width: "20%", minWidth: "150px" }}
            />
            <Skeleton
              variant="text"
              sx={{
                fontSize: "1.25rem",
                width: "69%",
                minWidth: "150px",
                mt: 2,
              }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1.25rem", width: "55%", minWidth: "150px" }}
            />
            <Skeleton
              variant="text"
              sx={{
                fontSize: "1.25rem",
                width: "78%",
                minWidth: "150px",
                mt: 2,
              }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1.25rem", width: "55%", minWidth: "150px" }}
            />
          </Box>
        </Grid>
        <Grid
          xs={gridItemsCount}
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "start" },
            alignItems: "center",
            height: "320px",
          }}
        >
          <Skeleton
            sx={{ height: "90%", width: "90%", minWidth: "250px" }}
            variant="rounded"
          />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", height: skeletonHeight }}>
      <Box
        sx={{
          width: "90%",
          padding: "40px 15px 40px 15px",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          margin: "auto",
        }}
      >
        <Grid
          container
          xs={12}
          sx={{ maxWidth: "1522px", margin: "auto" }}
          spacing={2}
        >
          {Array(1)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export {
  SkeletonSection,
  SkeletonSection2,
  SkeletonScnTeachers,
  SkeletonScnReviews,
  SkeletonSectionText,
  SkeletonCardRight,
};
