import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

import { InfoCourseCategoryLevel } from "./InfoCourseCategoryLevel";
import { Box } from "@mui/material";

const InfoCourseCategoryBlock: React.FC<PageComponentProps> = ({
  currentRoute,
}) => {
  return (
    <Box className="menuGeneral">
      <InfoCourseCategoryLevel
        currentRoute={currentRoute}
        level={1}
        idparent={""}
      />
    </Box>
  );
};

export { InfoCourseCategoryBlock };
