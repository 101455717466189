import React, { useEffect, useState } from "react";
import { useResource } from "hooks/useResource";

import { CourseCategoryService } from "services/course/coursecategory.service";
import MenuLevelProps from "interfaces/menulevelprops.interface";
import { CourseCategoryDto } from "dto/course/coursecategory.dto";

import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools//types/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";

import { InfoCourseCategoryItem } from "./InfoCourseCategoryItem";
import { SkeletonCourseMenu } from "components/elements/Skeleton/SkeletonCourseMenu";

import Skeleton from "@mui/material/Skeleton";
import { logger } from "tools/utils/logger";

const courseCategoryService = new CourseCategoryService();

const InfoCourseCategoryLevel: React.FC<MenuLevelProps> = ({
  currentRoute,
  level,
  idparent,
  ...props
}) => {
  const { saveCache, getCache, _idlanguage } = useResource();
  const [itemLoadingStatus, setItemLoadingStatus] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [objects, setObjects] = useState<Array<CourseCategoryDto>>([]);

  const getList = async () => {
    const reqList = new RequestListDTO();
    reqList.onpage = 100;
    reqList.page = 1;
    reqList.filters = [];

    let f: RequestFilterDTO;

    f = new RequestFilterDTO();
    f.field = "status";
    f.values = [];
    f.values.push(Status.ACTIVE.toString());
    reqList.filters.push(f);

    f = new RequestFilterDTO();
    f.field = "idparent";
    f.values = [];
    f.values.push(idparent);
    reqList.filters.push(f);

    if (_idlanguage) {
      f = new RequestFilterDTO();
      f.field = "idlanguage";
      f.values = [_idlanguage];
      reqList.filters.push(f);
    }

    const cd = getCache(JSON.stringify(reqList));

    if (cd !== false) {
      setObjects(cd);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const data = await courseCategoryService.getList(undefined, {}, reqList);

    const rez = !data || !data.objects ? [] : data.objects;

    setObjects(rez);
    setIsLoading(false);

    saveCache(rez, JSON.stringify(reqList));
  };

  useEffect(() => {
    getList();
  }, [idparent, _idlanguage]);

  const processCourseCategoryItem = (obj: CourseCategoryDto, index: number) => {
    if (isLoading) return <SkeletonCourseMenu lines={6} />;

    const hasChildren =
      obj.hasOwnProperty("haschildren") && obj.haschildren !== undefined
        ? obj.haschildren
        : false;

    return (
      <InfoCourseCategoryItem
        currentRoute={currentRoute}
        level={level}
        obj={obj}
        hasChildren={hasChildren}
        key={index}
      />
    );
  };

  const processCourseCategoryList = () => {
    if (!objects.length) return null;

    return (
      <React.Fragment>
        {objects.map((item: CourseCategoryDto, i: number) => {
          return processCourseCategoryItem(item, i);
        })}
      </React.Fragment>
    );
  };

  // return isLoading ? (
  //   <SkeletonCourseMenu lines={6} />
  // ) : (
  //   // <Skeleton variant="text" />
  //   processCourseCategoryList()
  // );

  return processCourseCategoryList();
};

export { InfoCourseCategoryLevel };
