import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

import { Box } from "@mui/material";

import { EditCourseGallery } from "./EditCourseGallery";
import { EditCourseVideo } from "./EditCourseVideo";

const EditCourseMedia: React.FC<PageComponentProps> = ({ currentRoute }) => {
  return (
    <Box mb={5}>
      <EditCourseGallery currentRoute={currentRoute} />
      <EditCourseVideo currentRoute={currentRoute} />
    </Box>
  );
};

export { EditCourseMedia };
