import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

import { Box } from "@mui/material";
import { TicketListMenu } from "./TicketListMenu";
import { useAuth } from "hooks/useAuth";
import { Types } from "tools/types/types";

const SupportBlockStudent: React.FC<PageComponentProps> = () => {
  const { student } = useAuth();

  const processComponent = () => {
    if (!student) return null;

    return (
      <Box sx={{ width: "auto", px: 6 }}>
        <TicketListMenu
          userObject={student}
          userType={Types.TYPE_TICKET_STUDENT}
          userObjectField="idstudent"
        />
      </Box>
    );
  };
  return processComponent();
};

export { SupportBlockStudent };
