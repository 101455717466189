import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useAuth } from "hooks/useAuth";
import { IconButton } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { RouteTools } from "tools/utils/routetools";
const WishlistIcon: React.FC<PageComponentProps> = () => {
  const { user } = useAuth();

  const goCourseDetails = () => {
    if (!user) return;

    RouteTools.setHistory("/wishlist", {});
  };
  const processIcon = () => {
    if (!user) return null;
    return (
      <IconButton onClick={goCourseDetails} sx={{ mx: 1 }}>
        <FavoriteBorderIcon />
      </IconButton>
    );
  };
  return processIcon();
};

export { WishlistIcon };
