import React, { useEffect, useState } from "react";
import { useResource } from "hooks/useResource";

import { AdvertisementDto } from "dto/static/advertisement.dto";

import { ComponentCommonTools } from "tools/utils/componentcommontools";

import { Box } from "@mui/material";

import { MyNavBt } from "components/elements/button/MyNavBt";
import { CommonTools } from "tools/utils/commontools";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

type AdvertisementBannerItemTypeImageLeftProps = {
  obj: AdvertisementDto;
};
const AdvertisementBannerItemTypeImageLeft: React.FC<
  AdvertisementBannerItemTypeImageLeftProps
> = ({ obj, ...props }) => {
  const [imgUrl, setImgUrl] = useState<string>("");
  const [fullUrl, setFullUrl] = useState<string>("");
  const width = 200;
  const height = 200;

  const { LL } = useResource();

  const prepareGallery = () => {
    if (!obj) return;

    if (!obj.hasOwnProperty("gallery")) return;
    if (!obj.gallery) return;
    if (obj.gallery.length === 0) return;
    const galleryObj = CommonTools.getGalleryObjectLogo(obj.gallery);
    if (!galleryObj) return;
    CommonTools.setFileUrlsGallery(
      galleryObj,
      setImgUrl,
      setFullUrl,
      width,
      height
    );
  };

  useEffect(() => {
    prepareGallery();
  }, [obj]);

  const processDetail = (obj: AdvertisementDto) => {
    if (!obj) return <></>;

    return (
      <>
        <Typography variant="h4">
          {ComponentCommonTools.processObjectField(obj, "name")}
        </Typography>
        <Typography my={2}>
          {ComponentCommonTools.processObjectField(obj, "description")}
        </Typography>
      </>
    );
  };

  const processButton = () => {
    if (!obj) return <></>;
    if (!obj.fullurl) return <></>;
    const label = obj.clabt ? LL(obj.clabt) : "";
    return (
      <MyNavBt href={obj.fullurl} className="btn out">
        {label}
      </MyNavBt>
    );
  };

  const processImage = () => {
    if (!imgUrl) return <></>;
    return <>{ComponentCommonTools.getFileBlock(imgUrl, width, height)}</>;
  };

  const processItem = () => {
    if (!obj) return <></>;

    return (
      <Box className="cardSpecial">
        <Grid container alignItems="center">
          <Grid item lg={4} sm={12} justifyContent="center" display="flex">
            {" "}
            {processImage()}
          </Grid>
          <Grid item lg={8} sm={12}>
            {processDetail(obj)}
            {processButton()}
          </Grid>
        </Grid>
      </Box>
    );
  };

  return processItem();
};

export { AdvertisementBannerItemTypeImageLeft };
