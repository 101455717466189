import Idto from "interfaces/idto.interface";
import { Status } from "tools//types/status";
import { Types } from "tools/types/types";

export class TestDto implements Idto {
  id?: string;

  mintestscore?: number;
  maxtestscore?: number;
  name?: string;
  type?: number;
  status?: number;
  idcourse?: string;
  idlesson?: string | null;
  question?: any;
}

export class TestFormDto implements Idto {
  id?: string;
  mintestscore?: number;
  maxtestscore?: number;
  name?: string;
  type?: number;
  status?: number;
  idcourse?: string;
  idlesson?: string | null;

  constructor(
    idcourse?: string,
    id?: string,
    idlesson?: string | null,
    mintestscore?: number,
    type?: number,
    status?: number,
    maxtestscore?: number,
    name?: string
  ) {
    this.id = id || "";
    this.idcourse = idcourse || "";
    this.idlesson = idlesson || null;
    this.mintestscore = mintestscore || 0;
    this.type = type || Types.TYPE_TEST_BEFORE_COURSE;
    this.status = status || Status.ACTIVE;
    this.maxtestscore = maxtestscore || undefined;
    this.name = name || "";
  }

  static parseFromTestDto = (obj: TestDto) => {
    if (!obj) return new TestFormDto();
    return new TestFormDto(
      obj.idcourse,
      obj.id,
      obj.idlesson,
      obj.mintestscore,
      obj.type,
      obj.status,
      obj.maxtestscore,
      obj.name
    );
  };
}

export class PostTestDto implements Idto {
  mintestscore?: number;
  maxtestscore?: number;
  name?: string;
  type?: number;
  status?: number;
  idcourse?: string;
  idlesson?: string | null;

  constructor() {
    this.status = Status.ACTIVE;
    this.mintestscore = 0;
  }
}
