import { Box, IconButton, Stack } from "@mui/material";

import { NotificationDto } from "dto/user/notification.dto";

import { useResource } from "hooks/useResource";
import React from "react";
import { NotificationService } from "services/user/notification.service";
import DateTools from "tools/utils/date.tools";

import { NotificationParamsList } from "./NotificationParamsList";
import CloseIcon from "@mui/icons-material/Close";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CommonTools } from "tools/utils/commontools";
type Props = {
  obj: NotificationDto;
  setLoading: any;
  setObjectWasChanged: any;
  setIsDisableMarkAllRead: any;
};

const service = new NotificationService();

const NotificationListItemTopMenu: React.FC<Props> = ({
  obj,
  setObjectWasChanged,
  setLoading,
  setIsDisableMarkAllRead,

  ...props
}) => {
  const { LL } = useResource();

  const processAgoTime = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("date")) return null;
    if (!obj.date) return null;
    return DateTools.timeAgo(obj.date, LL);
  };

  const handleMarkAsRead = () => {
    if (!obj) return;
    if (!obj.id) return;
    setLoading(true);
    setIsDisableMarkAllRead(true);
    service.markAsRead(obj.id, handleResult, {});
  };

  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    if (!result.err) {
      setObjectWasChanged(CommonTools.generateRandomString(5));
    }
  };

  const processItem = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("id")) return null;
    if (!obj.id) return null;

    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        sx={{ width: "100%" }}
      >
        <Box>
          <NotificationParamsList
            idNotification={obj.id}
            timeComponent={processAgoTime}
          />

          {/* <Typography className="data">
                            {processAgoTime()}
                        </Typography> */}
        </Box>

        <Box>
          <IconButton onClick={handleMarkAsRead}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Stack>
    );
  };

  return processItem();
};

export { NotificationListItemTopMenu };
