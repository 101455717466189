import { Stack, Typography } from "@mui/material";
import { TestResultDto } from "dto/course/testresult.dto";
import { useResource } from "hooks/useResource";
import React from "react";
import { Status } from "tools/types/status";
import { CommonTools } from "tools/utils/commontools";

type Props = {
  obj: TestResultDto;
};

const NewTestResult: React.FC<Props> = ({ obj }) => {
  const { LL } = useResource();
  const processTestResultSuccess = () => {
    if (!obj) return null;
    return (
      <Typography variant="h4">
        {LL("Congratulations_you_have_passed-the_test!")}
      </Typography>
    );
  };

  const processTestResultFail = () => {
    if (!obj) return null;
    return (
      <Typography variant="h4">
        {LL("Sorry_you_have_failed-the_test!")}
      </Typography>
    );
  };

  const processItem = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("status")) return null;
    if (!obj.status) return null;
    if (obj.status === Status.TEST_SUCCESS) return processTestResultSuccess();
    if (obj.status === Status.TEST_ERROR) return processTestResultFail();
    return null;
  };

  const processStatusColor = (obj: TestResultDto) => {
    if (!obj) return "black";
    if (!obj.hasOwnProperty("status")) return "black";
    if (!obj.status) return "black";
    if (obj.status === Status.TEST_SUCCESS) return "green";
    if (obj.status === Status.TEST_ERROR) return "red";
    return "black";
  };

  if (!obj) return null;

  return (
    <React.Fragment>
      {processItem()}

      <Stack
        direction="row"
        spacing={2}
        mt={3}
        className="testResult"
        alignItems="center"
      >
        <Typography>{LL("You_got")}</Typography>
        <Typography color={processStatusColor(obj)} className="nb">
          {`${CommonTools.processObjectField(obj, ["percentage"])} %`}
        </Typography>
        <Typography>{LL("of_correct_answers")}</Typography>
      </Stack>
    </React.Fragment>
  );
};

export { NewTestResult };
