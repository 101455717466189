import React, { useState } from "react";

import {
  CourseSettingsDto,
  CourseSettingsFormDto,
  CourseSettingsWithCourseDto,
} from "dto/course/coursesettings.dto";

import { useResource } from "hooks/useResource";

import Box from "@mui/material/Box";

import ResultObjectDTO from "dto/app/resultobject.dto";

import { CourseSettingsService } from "services/course/coursesettings.service";

import { CircularLoading } from "components/elements/loading/CircularLoading";
import { useMessage } from "hooks/useMessage";
import useObject from "hooks/useObject";
import { CourseDto } from "dto/course/course.dto";
import { FormCourseSettings } from "./FormCourseSettings";
import { CourseService } from "services/course/course.service";
const service = new CourseSettingsService();
const courseService = new CourseService();
type Props = {
  currentRoute: any;
  
  setObjectDefault: (obj: CourseDto) => void;
};

const EditCourseSettingsInfo: React.FC<Props> = ({
  currentRoute,
  
  setObjectDefault,
}) => {
  const { LL } = useResource();
  const { registerDialog } = useMessage();
  const id = currentRoute._paths !== undefined ? currentRoute._paths[2] : "";

  const get = (id: string, cb?: any, cbParams?: any) => {
    if (!id) return;
    service.getCourseSettingsByCourseId(id, cb, cbParams);
  };

  const [loading, obj, setObj] = useObject<CourseSettingsDto>(get, id, [id]);
  const [loadingAction, setLoadingAction] = useState<boolean>(false);

  // const [loading, setLoading] = useState<boolean>(false);
  // const [obj, setObj] = useState<CourseSettingsDto | undefined>();

  // const [isFormDisabled, setIsFormDisabled] = useState(false);
  // const [objectHasChanged, setObjectHasChanged] = useState<string>("");

  // const [validators, setValidators] = useState<any>();
  // const [vresults, setVResults] = useState<any>(false);

  // const validateAll = () => {
  //   if (!obj) return;
  //   if (!validators) return;
  //   // if (vresults) return;

  //   obj.id = obj.id ?? obj.id;
  //   obj.idtypecourse = obj.idtypecourse !== undefined ? obj.idtypecourse : "";
  //   obj.password = obj.password !== undefined ? obj.password : "";
  //   obj.maxnumberofstudents =
  //     obj.maxnumberofstudents !== undefined
  //       ? obj.maxnumberofstudents
  //       : undefined;

  //   setObj(obj);

  //   let t = validateField("password", obj.password);
  // };

  // const validateField = (field: any, value: any, v?: any) => {
  //   v = v ? v : JSON.parse(JSON.stringify(vresults));
  //   if (v === false) v = {};
  //   v[field] = ValidateTools.validate(field, value, validators, vresults);

  //   setVResults(v);
  //   setIsFormDisabled(ValidateTools.vForm(v, field));

  //   return v;
  // };

  // const processValidators = () => {
  //   let v: any = {
  //     password: [],
  //   };

  //   v.password.push(new RequiredValidator("", ""));

  //   setValidators(v);
  // };

  // const checkLoading = () => {
  //   if (!loading) return;
  //   if (obj === undefined) return;
  //   // if (!validators) return;
  //   validateAll();
  //   setLoading(false);
  // };

  // const loadObject = (obj: ResultObjectDTO) => {
  //   if (!obj) return;
  //   if (!obj.err) {
  //     const object = obj.obj ? obj.obj : {};
  //     setObj(object);
  //   }
  // };

  // const getObject = () => {
  //   setLoading(true);
  //   if (id !== undefined) {
  //     service.getCourseSettingsByCourseId(id, loadObject, {});
  //   }
  // };

  // useEffect(() => {
  //   processValidators();
  // }, []);
  // const checkIsFormDisabled = () => {
  //   // var rez = ValidateTools.vForm(vresults, 'password');
  //   setIsFormDisabled(false);
  // };

  // useEffect(() => {
  //   validateAll();
  //   checkLoading();
  // }, [obj, validators]);

  // useEffect(() => {
  //   checkIsFormDisabled();
  // }, [vresults]);

  // useEffect(() => {
  //   getObject();
  // }, [objectHasChanged]);

  const onSubmit = (obj: CourseSettingsDto) => {
    obj.idcourse = id;
    if (!obj.id) {
      registerDialog(
        LL("Confirm_edit_course"),
        LL(
          "Are_you_sure_you_want_to_edit_this_course_editing_will_change_the_status"
        ),
        { cb: handleConfirmAdd.bind(null, obj) },
        null
      );
    } else {
      registerDialog(
        LL("Confirm_edit_course"),
        LL(
          "Are_you_sure_you_want_to_edit_this_course_editing_will_change_the_status"
        ),
        { cb: handleConfirmEdit.bind(null, obj) },
        null
      );
    }
  };

  const handleConfirmAdd = (obj: CourseSettingsDto) => {
    if (!obj) return;
    setLoadingAction(true);
    service.addAndGetCourse(handleResult, {}, obj);
  };

  const handleConfirmEdit = (obj: CourseSettingsDto) => {
    if (!obj) return;
    if (!obj.id) return;
    setLoadingAction(true);
    service.updateAndGetCourse(obj.id, handleResult, {}, obj);
  };
  const handleResult = (
    result: ResultObjectDTO<CourseSettingsWithCourseDto>
  ) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;
    if (!result.obj.coursesettings) return;
    if (!result.obj.course) return;
    setObj(result.obj.coursesettings);
    setObjectDefault(courseService.populateDto(result.obj.course));
    setLoadingAction(false);
  };
  // const handleSubmit = (event: any) => {
  //   event.preventDefault();
  //   if (isFormDisabled) return false;
  //   if (!obj) return;
  //   obj.idcourse = id;
  //   if (obj.id !== undefined) {
  //     registerDialog(
  //       LL("Confirm_edit_course"),
  //       LL(
  //         "Are_you_sure_you_want_to_edit_this_course_editing_will_change_the_status"
  //       ),
  //       { cb: handleConfirmEdit },
  //       null
  //     );
  //   } else {
  //     registerDialog(
  //       LL("Confirm_edit_course"),
  //       LL(
  //         "Are_you_sure_you_want_to_edit_this_course_editing_will_change_the_status"
  //       ),
  //       { cb: handleConfirmAdd },
  //       null
  //     );
  //   }
  // };
  // const setObjField = (field: any, value: any) => {
  //   let t = JSON.parse(JSON.stringify(obj));
  //   t[field] = value;
  //   setObj(t);
  // };

  // const handleConfirmEdit = () => {
  //   if (obj && obj.id !== undefined) {
  //     service.update(
  //       obj.id,
  //       CommonTools.handleSuccessAddAndEditStayOnPage,
  //       {
  //         setObjectWasChanged: setObjectHasChanged,
  //         setObjectCourseHasChanged: setObjectCourseHasChanged,
  //       },
  //       obj
  //     );
  //   }
  // };

  // return !loading && obj ? (
  //   <>
  //     <form onSubmit={handleSubmit}>
  //       <Box sx={{ mt: 3, ".MuiFormLabel-root": { display: "none" } }}>
  //         <Typography variant="h6" mb={1}>
  //           {LL("Tip curs")}
  //         </Typography>
  //         <CourseTypeSelect
  //           value={obj.idtypecourse}
  //           field="idtypecourse"
  //           setObjectField={setObjField}
  //           label=""
  //         />
  //       </Box>
  //       <Box mt={3}>
  //         <Typography variant="h6" mb={1}>
  //           {LL("Parola curs")}
  //         </Typography>
  //         <MyTextField
  //           tabIndex={1}
  //           fullWidth
  //           id="password"
  //           name="password"
  //           variant="standard"
  //           className="form-input"
  //           color="primary"
  //           _label={LL("password")}
  //           size="small"
  //           value={obj.password}
  //           setObj={setObjField}
  //           _vresults={false}
  //         />
  //       </Box>
  //       <Box mt={3}>
  //         <Typography variant="h6" mb={1}>
  //           {LL("Numar maxim de studenti")}
  //         </Typography>
  //         <MyTextField
  //           tabIndex={1}
  //           fullWidth
  //           id="maxnumberofstudents"
  //           name="maxnumberofstudents"
  //           variant="standard"
  //           className="form-input"
  //           color="primary"
  //           _label={LL("Max_number_of_students")}
  //           size="small"
  //           value={obj.maxnumberofstudents}
  //           setObj={setObjField}
  //           _vresults={false}
  //         />
  //       </Box>
  //       <Box mt={4} textAlign="center">
  //         <MyButton
  //           tabIndex={2}
  //           disabled={isFormDisabled}
  //           color="primary"
  //           fullWidth
  //           variant="contained"
  //           size="large"
  //           type="submit"
  //           className="btn"
  //           children={LL("Bt_Submit")}
  //         />
  //       </Box>
  //     </form>
  //   </>
  // ) : (
  //   <CircularLoading />
  // );

  if (loading) return <CircularLoading />;
  if (!obj) return null;
  return (
    <Box>
      <FormCourseSettings
        defaultObj={CourseSettingsFormDto.fromCourseSettingsDto(obj)}
        onSubmit={onSubmit}
        loading={loadingAction || loading}
      />
    </Box>
  );
};

export default EditCourseSettingsInfo;
