import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import { LessonRepository } from "repositories/course/lesson.repository";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

export class LessonService
  extends GeneralService<LessonRepository>
  implements IService
{
  constructor() {
    super(
      new LessonRepository(),
      [
        { key: "status", type: "status" },
        { key: "istest", type: "booleans" },
        { key: "islive", type: "booleans" },
        { key: "enddate", type: "timestamp" },
        { key: "startdate", type: "timestamp" },
      ],
      "name"
    );
  }

  updatePassed = async (
    id: string,
    cb?: any,
    cbparameters?: any,
    data?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.updatePassed(
      id,
      this.handleGet,
      cbparameters
    );
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };
  getNextForTeacher = async (
    id: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getNextForTeacher(
      id,
      this.handleGet,
      cbparameters
    );
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };
  getListForEditCourse = async (
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getListForEditCourse(
      this.handleGet,
      cbparameters,
      data
    );
    if (cb === undefined) {
      return this.handleGetList(t);
    }
  };
  addByTeacher = async (
    cb?: any,
    cbparameters?: any,
    data?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    const t = await this.repository.addByTeacher(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  updateOrder = async (
    cb?: any,
    cbparameters?: any,
    data?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    const t = await this.repository.updateOrder(
      this.handleGet,
      cbparameters,
      data
    );

    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  getDefaultRequestListSelect(onPage?: number): RequestListDTO {
    onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    var defaultRequestList = new RequestListDTO();
    defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    defaultRequestList.onpage = onPage;
    var defaultRequestSortCriteria = new RequestSortCriteriaDTO();
    defaultRequestSortCriteria.asc = true;
    defaultRequestSortCriteria.field = "name";
    defaultRequestList.sortcriteria = [];
    defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
    return defaultRequestList;
  }
}

// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// // import {LessonDto} from "dto/course/lesson.dto";
// import ResultListDTO from "dto/app/resultlist.dto";
// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import {LessonRepository} from "repositories/course/lesson.repository";
// import { Status } from "tools//types/status";
// import GeneralService from "services/general.service";

// export default class LessonService extends GeneralService implements IService {
//     lessonRepository: LessonRepository = new LessonRepository();
//     constructor() {
//         super();
//         this.handleGetList = this.handleGetList.bind(this);
//         this.handleGet = this.handleGet.bind(this);
//       }
//     handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;

//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     async update(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {

//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         this.lessonRepository.update(id, this.handleUpdate, data, cbparameters);
//     }

//     async updatePassed(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {

//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         this.lessonRepository.updatePassed(id, this.handleUpdate,  cbparameters);
//     }

//     handleDelete(result?: any, cbparameters?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters);
//         }
//     }

//     async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.lessonRepository.delete(id, this.handleDelete, cbparameters);
//     }

//     handleGet(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;
//         this.populateObject(rez.obj, result.obj, [
//             { key: 'status', type: 'status' },
//         ]);
//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//     return rez;
//     }

//     async getNextForTeacher(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.lessonRepository.getNextForTeacher(id, this.handleGet, cbparameters);
//         if (cb == undefined)
//         {
//             return this.handleGet(t);
//         }
//     }

//     async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.lessonRepository.get(id, this.handleGet, cbparameters);
//         if (cb == undefined)
//         {
//             return this.handleGet(t);
//         }
//     }

//     handleGetList(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultListDTO();

//         rez = result;
//         if (result.err) return;

//         this.parseResultPopulate(result, rez, [
//             { key: 'status', type: 'status' },

//           ]);

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//         return rez;
//     }

//     async getList(
//         cb?: any,
//         cbparameters?: any,
//         data?: RequestListDTO
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.lessonRepository.getList(this.handleGetList, cbparameters, data);
//         if (cb == undefined)
//         {
//             return this.handleGetList(t);
//         }
//     }

//     async getListForEditCourse(
//         cb?: any,
//         cbparameters?: any,
//         data?: RequestListDTO
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.lessonRepository.getListForEditCourse(this.handleGetList, cbparameters, data);
//         if (cb == undefined)
//         {
//             return this.handleGetList(t);
//         }
//     }

//     handleAdd(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     async add(
//         cb?: any,
//         cbparameters?: any,
//         data?: any
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.lessonRepository.add(this.handleAdd, cbparameters, data);
//     }

//     async addByTeacher(
//         cb?: any,
//         cbparameters?: any,
//         data?: any
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.lessonRepository.addByTeacher(this.handleAdd, cbparameters, data);
//     }

//     async updateOrder(
//         cb?: any,
//         cbparameters?: any,
//         data?: any
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.lessonRepository.updateOrder(this.handleUpdate, cbparameters, data);
//     }

//     getDefaultRequestListSelect(onPage?: number): RequestListDTO {
//         onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
//         if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
//         var defaultRequestList = new RequestListDTO();
//         defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
//         defaultRequestList.onpage = onPage;
//         var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
//         defaultRequestSortCriteria.asc = true;
//         defaultRequestSortCriteria.field = 'name';
//         defaultRequestList.sortcriteria = []
//         defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
//         return defaultRequestList;
//     }

//     getDefaultRequestList(onPage?: number): RequestListDTO {
//         onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
//         if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
//         var defaultRequestList = new RequestListDTO();
//         defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
//         defaultRequestList.onpage = onPage;
//         // var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
//         // defaultRequestSortCriteria.asc = true;
//         // defaultRequestSortCriteria.field = 'value';
//         // defaultRequestList.sortcriteria = []
//         // defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
//         return defaultRequestList;
//     }
// }
