import SelectOptions from "dto/app/selectoptions.dto";
import { AttachmentDto } from "dto/system/attachment.dto";
import { GalleryDto } from "dto/system/gallery.dto";
import { VideoDto } from "dto/system/video.dto";
import Idto from "interfaces/idto.interface";
import { Status } from "tools//types/status";
import { CommonTools } from "tools/utils/commontools";

export class LessonDto implements Idto {
  id?: string;
  name?: string;
  description?: string;
  status?: number;
  ordercriteria?: number;
  idgallery?: string;
  idattachment?: string;
  idvideo?: string;
  istest?: boolean;
  idtest?: string | null;
  idlessonsettings?: string | null;
  lessonsettings?: any;
  idtypelesson?: string;
  idcourse?: string;
  lessonduration?: number;
  islive?: boolean;
  typelesson?: any;
  startdate_name?: string;
  enddate_name?: string;
  startdate?: number;
  enddate?: number;
  ispassed?: boolean;
  attachment?: Array<AttachmentDto>;
  video?: Array<VideoDto>;
  gallery?: Array<GalleryDto>;
  constructor() {
    this.status = Status.ACTIVE;
    this.ordercriteria = 0;
    this.islive = false;
    this.lessonduration = 0;
    this.istest = false;
  }

  static parseToSelectOptions(data: Array<LessonDto>): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(
        new SelectOptions(
          CommonTools.processObjectField(element, ["id"]),
          CommonTools.processObjectField(element, ["name"])
        )
      );
    });
    return result;
  }
}

export class LessonFormDto implements Idto {
  id?: string;
  name?: string;
  description?: string;
  status?: number;
  ordercriteria?: number;
  lessonduration?: number;
  idtypelesson?: string;
  idcourse?: string;
  islive?: boolean;
  startdate?: number;
  enddate?: number;
  istest?: boolean;

  constructor(
    id?: string,
    name?: string,
    description?: string,
    status?: number,
    ordercriteria?: number,
    lessonduration?: number,
    idtypelesson?: string,
    idcourse?: string,
    islive?: boolean,
    startdate?: number,
    enddate?: number,
    istest?: boolean
  ) {
    this.id = id || "";
    this.name = name || "";
    this.description = description || "";
    this.status = status || Status.ACTIVE;
    this.ordercriteria = ordercriteria || 0;
    this.lessonduration = lessonduration || 0;
    this.idtypelesson = idtypelesson || "";
    this.idcourse = idcourse || "";
    this.islive = islive || false;
    this.startdate = startdate || undefined;
    this.enddate = enddate || undefined;
    this.istest = istest || false;
  }

  static parseFromLessonDto = (obj: LessonDto) => {
    if (!obj) return new LessonFormDto();
    return new LessonFormDto(
      obj.id,
      obj.name,
      obj.description,
      obj.status,
      obj.ordercriteria,
      obj.lessonduration,
      obj.idtypelesson,
      obj.idcourse,
      obj.islive,
      obj.startdate,
      obj.enddate,
      obj.istest
    );
  };
}

export class EditLessonOrderDto implements Idto {
  id?: string;
  ordercriteria?: number;
}

export class PatchLessonOrderDto implements Idto {
  neworder?: EditLessonOrderDto[];
  idcourse?: string;
}

export class PostLessonDto implements Idto {
  name?: string;
  description?: string;
  status?: number;
  ordercriteria?: number;
  idgallery?: string;
  idattachment?: string;
  idvideo?: string;
  istest?: boolean;
  idtest?: string | null;
  idlessonsettings?: string | null;
  lessonsettings?: any;
  idtypelesson?: string;
  idcourse?: string;
  lessonduration?: number;
  islive?: boolean;
  startdate_name?: string;
  enddate_name?: string;
  startdate?: number;
  enddate?: number;
  constructor() {
    this.status = Status.ACTIVE;
    this.ordercriteria = 0;
    this.islive = false;
    this.lessonduration = 0;
    this.istest = false;
  }
}
