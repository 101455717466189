import React from "react";

import { useResource } from "hooks/useResource";

import {
  List,
  ListItem,
  ListItemText,
  Skeleton,
  ListItemIcon,
} from "@mui/material";

import { AttachmentDto } from "dto/system/attachment.dto";


import RequestListDTO from "dto/app/requestlist.dto";

import { AttachmentService } from "services/system/attachment.service";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { Status } from "tools//types/status";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { useList } from "hooks/useList";
import { CallbackType } from "interfaces/commontypes.interface";

const service = new AttachmentService();
type Props = {
  idparent: string;
  parent: string;
};
const AttachmentList: React.FC<Props> = ({ idparent, parent, ...props }) => {
  const { LL} = useResource();
  // const [loading, setLoading] = useState<boolean>(false);
  // const [objects, setObjects] = useState<Array<AttachmentDto>>([]);

  // const getList = () => {
  //   if (!idparent) return;
  //   const reqList = new RequestListDTO();
  //   reqList.onpage = parseInt(CC("DefaultOnPageAttachmentList", "1000"));
  //   reqList.page = 1;
  //   reqList.filters = [];
  //   reqList.sortcriteria = [];

  //   const sortCriteria = new RequestSortCriteriaDTO();
  //   sortCriteria.field = "ordercriteria";
  //   sortCriteria.asc = true;
  //   reqList.sortcriteria.push(sortCriteria);

  //   let f: RequestFilterDTO;

  //   f = new RequestFilterDTO();
  //   f.field = "status";
  //   f.values = [];
  //   f.values.push(Status.ACTIVE.toString());
  //   reqList.filters.push(f);

  //   f = new RequestFilterDTO();
  //   f.field = "idparent";
  //   f.values = [idparent];
  //   reqList.filters.push(f);
  //   setLoading(true);

  //   service.getList(loadObjects, {}, reqList);
  // };
  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    if (!idparent) return;
    service.getList(cb, cbParams, req);
  };

  const [loading, objects] = useList<AttachmentDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("idparent", [idparent]),
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
      ],
      1,
      -1,
      [RequestSortCriteriaDTO.prepareSortCriteria("ordercriteria", true)]
    ),
    [idparent]
  );
  // const loadObjects = (result: ResultListDTO) => {
  //   if (!result) return;
  //   if (!result.err) {
  //     const objects = result.objects ? result.objects : [];
  //     setObjects(objects);
  //   }
  // };

  // const checkLoading = () => {
  //   let load = false;
  //   if (!objects) load = true;
  //   setLoading(load);
  // };

  // useEffect(() => {
  //   checkLoading();
  // }, [objects]);

  // useEffect(() => {
  //   getList();
  // }, [idparent]);

  const processItem = (item: AttachmentDto, i: number) => {
    if (!item) return null;
    if (!item.hasOwnProperty("id")) return null;
    if (!item.id) return null;
    let name = "";
    if (item.hasOwnProperty("name") && item.name) name = item.name;
    else {
      const index = i + 1;
      name = LL("attachment") + " " + index.toString();
    }
    return (
      <ListItem key={i} className="list-item-info">
        <ListItemIcon>
          <FilePresentIcon />
        </ListItemIcon>
        <ListItemText primary={name} />
      </ListItem>
    );
  };
  const processLessonList = () => {
    if (loading) return <Skeleton variant="rectangular" height={50} />;
    if(!objects) return null;
    if (!objects.length) return null;

    return (
      <List>
        {objects.map((item: AttachmentDto, i: number) => {
          return processItem(item, i);
        })}
      </List>
    );
  };
  return processLessonList();
};

export { AttachmentList };
