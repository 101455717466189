import React, { useEffect } from "react";

import { CourseCategoryService } from "services/course/coursecategory.service";

import { CourseCategoryDto } from "dto/course/coursecategory.dto";

import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools//types/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";

import { Box, Stack, Typography } from "@mui/material";

import { SkeletonSlider } from "components/elements/Skeleton/SkeletonCategory";
import { CommonTools } from "tools/utils/commontools";
import { SubcategoryList } from "./SubcategoryList";
import { CallbackType } from "interfaces/commontypes.interface";
import useObject from "hooks/useObject";
import { useList } from "hooks/useList";

const service = new CourseCategoryService();

type Props = {
  currentRoute: any;
  idCategory: string;
  setCategoryObject: any;
};

const CourseCategoryTopBar: React.FC<Props> = ({
  idCategory,
  setCategoryObject,
}) => {
  const get = (id: string, cb?: CallbackType, cbParams?: any) => {
    if (!id) return;
    service.get(id, cb, cbParams);
  };

  const [loading, object] = useObject<CourseCategoryDto>(
    get,
    idCategory,
    [idCategory],
    true,
    `category_page_${idCategory}`
  );

  useEffect(() => {
    if (!object) return;
    setCategoryObject(object);
  }, [object]);

  if (loading) return <SkeletonSlider />;
  if (!object) return null;
  return <CategoryList object={object} />;
};

type ListProps = {
  object: CourseCategoryDto;
};
const CategoryList: React.FC<ListProps> = ({ object }) => {
  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };
  const code = CommonTools.processObjectField(object, ["code"]).substring(0, 4);
  const [loading, objects] = useList<CourseCategoryDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
        RequestFilterDTO.prepareFilter("code", [code]),
        RequestFilterDTO.prepareFilter("level", ["1"]),
      ],
      1,
      -1
    ),
    [code],
    true,
    `category_page_category_list_${code}`
  );

  const get = (code: string, cb?: CallbackType, cbParams?: any) => {
    if (!code) return;
    service.getByCode(code, cb, cbParams);
  };

  const [loadingMain, objectMain] = useObject<CourseCategoryDto>(
    get,
    code,
    [code],
    true,
    `category_page_main_${code}`
  );

  if (loading || loadingMain) return <SkeletonSlider />;
  if (!objects || !objectMain) return null;
  return (
    <Box>
      <Box className="categoryTopBar">
        <Box className="container">
          <Box className="courseCtgTopBar">
            <Box sx={{ width: "15%" }}>
              <Typography className="name" variant="h6" pl={2} pr={3} py={2}>
                {CommonTools.processObjectField(objectMain, ["name"])}
              </Typography>
            </Box>
            <Box sx={{ width: "85%" }}>
              <SubcategoryList defaultObjects={objects} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export { CourseCategoryTopBar };
