import React, { useState, useEffect } from "react";
import { useResource } from "hooks/useResource";

import { Box, Skeleton, Stack } from "@mui/material";

import MyTextField from "components/form/mytextfield";

import RequestCriteriaDTO from "dto/app/requestcriteria.dto";

type Props = {
  currentRoute: any;
  idFilter: string;
  setFiltersRange: any;
  filtersRange: Array<RequestCriteriaDTO>;
};

class RangeObject {
  from?: number | string;
  to?: number | string;
}

const FilterTypeRange: React.FC<Props> = ({
  currentRoute,
  idFilter,
  setFiltersRange,
  filtersRange,
}) => {
  const { LL } = useResource();

  const [isLoading, setIsLoading] = useState(true);
  const [obj, setObj] = useState<RangeObject>(new RangeObject());
  const [hasChanged, setHasChanged] = useState(false);

  const setObjField = (field: any, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    if (value === "") value = undefined;
    if (t[field] !== value) {
      t[field] = value;
      setObj(t);
      setHasChanged(true);
    }
  };

  useEffect(() => {
    if (!filtersRange) return;
    const matchingFilter = filtersRange.find(
      (filter) => filter.id === idFilter
    );

    if (matchingFilter && matchingFilter.values) {
      const [fromValue, toValue] = matchingFilter.values;

      setObj({
        from: fromValue !== "-1" ? fromValue : undefined,
        to: toValue !== "-1" ? toValue : undefined,
      });
    }
    setIsLoading(false);
  }, []);

  const isValidValue = (value: string | number | undefined) => {
    return value ? value.toString() : "-1";
  };

  const addFilter = () => {
    if (!filtersRange) return;

    const existingFilterIndex = filtersRange.findIndex(
      (f) => f.id === idFilter
    );

    const filterRange = new RequestCriteriaDTO();
    filterRange.id = idFilter;
    filterRange.values = [isValidValue(obj.from), isValidValue(obj.to)];

    if (existingFilterIndex !== -1) {
      if (filterRange.values[0] === "-1" && filterRange.values[1] === "-1") {
        setFiltersRange((prevFilters: Array<RequestCriteriaDTO>) => {
          const updatedFilters = [...prevFilters];
          updatedFilters.splice(existingFilterIndex, 1);
          return updatedFilters;
        });
      } else {
        setFiltersRange((prevFilters: Array<RequestCriteriaDTO>) => {
          const updatedFilters = [...prevFilters];
          updatedFilters[existingFilterIndex] = filterRange;
          return updatedFilters;
        });
      }
    } else {
      if (filterRange.values[0] !== "-1" || filterRange.values[1] !== "-1") {
        setFiltersRange((prevFilters: Array<RequestCriteriaDTO>) => [
          ...prevFilters,
          filterRange,
        ]);
      }
    }
  };

  useEffect(() => {
    if (hasChanged) {
      addFilter();
      setHasChanged(false);
    }
  }, [obj]);

  useEffect(() => {
    if (filtersRange && filtersRange.length === 0) {
      setObj(new RangeObject());
    }
  }, [filtersRange]);

  const processFieldsTypeRange = () => {
    return (
      <Box>
        <Stack direction="row" spacing={2}>
          <MyTextField
            tabIndex={0}
            fullWidth
            id="from"
            name="from"
            className="form-input"
            variant="standard"
            _label={LL("from")}
            size="small"
            type="number"
            value={obj.from || ""}
            setObj={setObjField}
            _vresults={undefined}
          />
          <MyTextField
            tabIndex={0}
            fullWidth
            id="to"
            name="to"
            className="form-input"
            variant="standard"
            _label={LL("to")}
            size="small"
            type="number"
            value={obj.to || ""}
            setObj={setObjField}
            _vresults={undefined}
          />
        </Stack>
      </Box>
    );
  };

  return isLoading ? <Skeleton /> : <>{processFieldsTypeRange()}</>;
};

export { FilterTypeRange };
