import RequestListDTO from "dto/app/requestlist.dto";
import SelectOptions from "dto/app/selectoptions.dto";
import { useList } from "hooks/useList";
import Idto from "interfaces/idto.interface";
import React from "react";
import { Skeleton } from "@mui/material";
import { NewMySelect } from "./NewMySelect";

type Props = {
  processOptions: (objects: Idto[]) => Array<SelectOptions>;
  getList: (cb?: any, cbParams?: any, data?: RequestListDTO) => void;
  field: string;
  setObjectField: (field: string, value: any) => void;
  value: any;
  label?: string;
  dependencies?: any[];
  className?: string;
};

const ServerSelect: React.FC<Props> = ({
  processOptions,
  getList,
  field,
  setObjectField,
  value,
  label = "",
  dependencies = [],
  className = "form-select",
}) => {
  const [loading, objects] = useList<Idto>(
    getList,
    new RequestListDTO([], 1, -1),
    dependencies
  );

  if (loading) return <Skeleton variant="rectangular" height={50} />;
  if (!objects) return null;
  return (
    <NewMySelect
      options={processOptions(objects)}
      _label={label}
      setObjectField={setObjectField}
      field={field}
      value={value}
      size="small"
      variant="standard"
      color="primary"
      className={className}
    />
  );
};

export { ServerSelect };
