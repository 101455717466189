import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  Divider,
  Link,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { MyNavBt } from "components/elements/button/MyNavBt";
import { useResource } from "hooks/useResource";
import { useAuth } from "hooks/useAuth";
import LogoutIcon from "@mui/icons-material/Logout";
import { Types } from "tools/types/types";
import { RouteTools } from "tools/utils/routetools";
import { StudentDrawerMenuItems } from "dto/user/studentdrawermenuitems";
import { TeacherDrawerMenuItems } from "dto/user/teacherdrawermenuitems";
const drawerWidth = 300;

type UserDrawerProps = {
  open: boolean;
  setOpen: any;
  setOpenMainDrawer: any;
  setOpenSecondDrawer: any;
};
const UserDrawer: React.FC<UserDrawerProps> = ({
  open,
  setOpen,
  setOpenMainDrawer,
  setOpenSecondDrawer,
  ...props
}) => {
  const { user, logout, usersettings, userInterface, setUserInterface } =
    useAuth();
  const { LL, isTeacher } = useResource();

  const handleClose = () => {
    setOpenMainDrawer(false);
    setOpenSecondDrawer(false);
    setOpen(false);
  };

  const handleCloseUserDrawer = () => {
    setOpen(false);
  };

  const processCloseButton = () => {
    return (
      <IconButton onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    );
  };

  const processUserInterface = () => {
    const interfaceLabel = Types.LL(userInterface);

    return <>{interfaceLabel}</>;
  };

  const processCloseButtonUserDrawer = () => {
    return (
      <IconButton onClick={handleCloseUserDrawer}>
        <ArrowBackIosNewIcon />
      </IconButton>
    );
  };

  const handleLogOut = () => {
    logout();
    handleClose();
  };

  const handleChangeInterface = () => {
    if (userInterface === Types.STUDENT_INTERFACE) {
      setUserInterface(Types.TEACHER_INTERFACE);
      RouteTools.setHistory("/teacherinterface", {});
    }
    if (userInterface === Types.TEACHER_INTERFACE) {
      setUserInterface(Types.STUDENT_INTERFACE);
      RouteTools.setHistory("/", {});
    }
    handleClose();
  };

  const processUserName = () => {
    if (!usersettings) return null;
    if (!usersettings.hasOwnProperty("name")) return null;
    if (!usersettings.hasOwnProperty("surname")) return null;
    if (!usersettings.name) return null;
    if (!usersettings.surname) return null;

    return (
      <Typography color="primary">
        {usersettings.name} {usersettings.surname}
      </Typography>
    );
  };
  const processListItemUserInterface = () => {
    if (!isTeacher()) return null;
    return (
      <ListItem sx={{ p: 0, m: 0 }}>
        <ListItemButton onClick={handleChangeInterface}>
          {processUserInterface()}
        </ListItemButton>
      </ListItem>
    );
  };

  const onClick = (url: string) => {
    RouteTools.setHistory(url, {});
    handleClose();
  };
  const processUserOptions = () => {
    const options =
      userInterface === Types.TEACHER_INTERFACE
        ? TeacherDrawerMenuItems
        : StudentDrawerMenuItems;

    return (
      <List sx={{ p: 0, m: 0 }}>
        {options.map((option, index) => (
          <List key={index} sx={{ p: 0, m: 0 }}>
            <Divider />
            {option.options.map((option, index) => (
              <ListItem
                key={index}
                onClick={onClick.bind(this, processHref(option))}
                sx={{ p: 0, m: 0 }}
                className="linkSecond"
                
              >
                <ListItemButton>{processName(option)}</ListItemButton>
              </ListItem>
            ))}
          </List>
        ))}
      </List>
    );
  };

  const processName = (obj: any) => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("name")) return null;
    if (!obj.name) return null;
    return <>{LL(obj.name)}</>;
  };

  const processHref = (obj: any) => {
    if (!obj) return "/";
    if (!obj.hasOwnProperty("href")) return "/";
    if (!obj.href) return "/";
    return obj.href;
  };
  const processUserActions = () => {
    if (user) {
      return (
        <Box role="presentation" sx={{ width: drawerWidth }}>
          <List sx={{ p: 0, m: 0 }}>
            <ListItem>
              <ListItemText>{processUserName()}</ListItemText>
            </ListItem>

            <Divider />
            {processListItemUserInterface()}
          </List>

          {processUserOptions()}

          <Divider sx={{ my: 1 }} />
          <List>
            <ListItem onClick={handleLogOut} sx={{ p: 0, m: 0 }}>
              <ListItemButton>
                <ListItemIcon sx={{ minWidth: "15px", mr: 1 }}>
                  <LogoutIcon className="iconRight" />
                </ListItemIcon>
                {LL("Logout")}
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      );
    }
    return null;
  };

  return (
    <Box>
      <Drawer open={open} onClose={handleClose}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={1}
        >
          {processCloseButtonUserDrawer()}
          {processCloseButton()}
        </Stack>
        <Divider />
        {processUserActions()}
      </Drawer>
    </Box>
  );
};

export { UserDrawer };
