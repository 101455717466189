import React from "react";
import { MyNavBt } from "../elements/button/MyNavBt";
import { LogoComp } from "./LogoComp";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

const TopLogoBlock: React.FC<PageComponentProps> = ({ currentRoute }) => {
  return (
    <MyNavBt href={"/"}>
      <LogoComp />
    </MyNavBt>
  );
};

export { TopLogoBlock };
