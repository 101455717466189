import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

const SkeletonFilterBox: React.FC = () => {
  // let gridItemsCount;
  // if (matchesSM) {
  //   gridItemsCount = 2;
  // } else {
  //   gridItemsCount = 2;
  // }
  // const skeletonHeight = matchesSM ? "130px" : "130px";

  const gridItem = (
    <Skeleton
      variant="text"
      sx={{
        fontSize: "1.5rem",
        width: "100%",
      }}
    />
  );

  return (
    <Box sx={{ width: "100%" }} className="">
      <Box
        sx={{
          width: "100%",
          display: { xs: "none", md: "flex" },
          justifyContent: "center",
          alignContent: "center",
          margin: "auto",
          flexDirection: "column",
        }}>
        {Array(4)
          .fill(null)
          .map((_, index) => (
            <React.Fragment key={index}>{gridItem}</React.Fragment>
          ))}
      </Box>
    </Box>
  );
};

export { SkeletonFilterBox };
