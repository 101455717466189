import { Box, Typography } from "@mui/material";

import { MyButton } from "components/elements/button/MyButton";

import MyTextField from "components/form/mytextfield";
import { AddressFormDto } from "dto/nomenclature/address.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useEffect } from "react";

import RequiredValidator from "validators/required.validator";
import { AutocompleteLocality } from "./AutocompleteLocality";

type Props = {
  defaultObj: AddressFormDto;
  onSubmit: (obj: AddressFormDto) => void;
  loading: boolean;
};

const FormInlineAddress: React.FC<Props> = ({ defaultObj, onSubmit, loading }) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField, setObject] = useForm<AddressFormDto>(
    defaultObj,
    RequiredValidator.getValidators([
      "street",
      "streetnumber",
      "zipcode",
      "idlocality",
    ])
  );

  useEffect(() => {
    if (!defaultObj) return;
    setObject(AddressFormDto.parseFromAddressDto(defaultObj));
  }, [defaultObj]);

  const handleSubmit = (e: React.FormEvent<any>) => {
    
    e.preventDefault();
    e.stopPropagation();
    if (disabled || loading) return;
    onSubmit(obj);
  };
  return (
    
      <Box sx={{ flexGrow: 1 }} maxWidth={"sm"} p={2} mx="auto">
        <Typography variant="h6">{LL("Strada")}</Typography>
        <MyTextField
          tabIndex={0}
          fullWidth
          id="street"
          name="street"
          variant="standard"
          className="form-input"
          _label={LL("Street")}
          size="small"
          value={obj.street}
          setObj={setObjField}
          _vresults={undefined}
        />
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6">{LL("Nr. stradă")}</Typography>
          <MyTextField
            tabIndex={1}
            fullWidth
            id="streetnumber"
            name="streetnumber"
            _label={LL("streetnumber")}
            variant="standard"
            className="form-input"
            value={obj.streetnumber}
            setObj={setObjField}
            _vresults={undefined}
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6">{LL("Cod poștal")}</Typography>
          <MyTextField
            tabIndex={1}
            fullWidth
            id="zipcode"
            name="zipcode"
            _label={LL("zipcode")}
            variant="standard"
            className="form-input"
            value={obj.zipcode}
            setObj={setObjField}
            _vresults={undefined}
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6">{LL("Alege localitatea")}</Typography>

          <AutocompleteLocality
            setObjField={setObjField}
            value={obj.idlocality}
            defaultObject={obj.locality}
          />
        </Box>
        <Box textAlign="center">
          <MyButton
            tabIndex={4}
            disabled={disabled || loading}
            color="primary"
            variant="contained"
            size="large"
            
            sx={{ mt: 4 }}
            className="btn"
            onClick={handleSubmit}

          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </Box>
    
  );
};

export { FormInlineAddress };
