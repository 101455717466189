import { Box, Stack, Typography, Grid } from "@mui/material";

import { TeacherRequestDto } from "dto/user/teacherrequest.dto";

import { useResource } from "hooks/useResource";
import React from "react";

import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { StatusChip } from "components/elements/Chip/StatusChip";

type Props = {
  obj: TeacherRequestDto;
};

const TeacherInterfaceRequestItem: React.FC<Props> = ({ obj }) => {
  const { LL } = useResource();

  const processItem = () => {
    if (!obj) return null;

    return (
      <Box className="teacherRequest" my={2}>
        <Box mb={1}>
          <Typography variant="h5">
            {ComponentCommonTools.processObjectField(obj, "type_name")}
            <StatusChip sx={{ ml: 1, color: "#fff" }} obj={obj} />
          </Typography>
          {/* {ComponentCommonTools.processObjectField(obj, "status_name")} */}
        </Box>
        <Grid container>
          <Grid item lg={6} sm={12}>
            <Stack direction="row" spacing={2}>
              <Typography color="text.disabled" variant="caption">
                {LL("Date")}
              </Typography>
              <Typography>
                {ComponentCommonTools.processObjectField(obj, "date_name")}
              </Typography>
              <Typography color="text.disabled" variant="caption">
                {LL("changedate")}
              </Typography>
              <Typography>
                {ComponentCommonTools.processObjectField(
                  obj,
                  "changedate_name"
                )}
              </Typography>
            </Stack>
            <Typography color="text.disabled" variant="caption">
              {LL("teachermessage")}
            </Typography>
            <Typography>
              {ComponentCommonTools.processObjectField(obj, "teachermessage")}
            </Typography>
          </Grid>
          <Grid item lg={6} sm={12}>
            <Typography color="text.disabled" variant="caption">
              {LL("managerfullname")}
            </Typography>
            <Typography>
              {ComponentCommonTools.processObjectField(obj, "managerfullname")}
            </Typography>
            <Typography color="text.disabled" variant="caption">
              {LL("managermessage")}
            </Typography>
            <Typography>
              {ComponentCommonTools.processObjectField(obj, "managermessage")}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return processItem();
};

export { TeacherInterfaceRequestItem };
