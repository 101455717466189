import React from "react";

type Props = {
  content?: string | undefined | null;
};

const HtmlTag: React.FC<Props> = ({ content }) => {
  if (!content) return null;
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  );
};

export { HtmlTag };
