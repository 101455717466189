import React from "react";

import { CourseCategoryService } from "services/course/coursecategory.service";

import { CourseCategoryDto } from "dto/course/coursecategory.dto";

import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools//types/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";

import Skeleton from "@mui/material/Skeleton";
import {
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemIcon,
} from "@mui/material";

import { ComponentCommonTools } from "tools/utils/componentcommontools";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { RouteTools } from "tools/utils/routetools";
import { useList } from "hooks/useList";
import { CallbackType } from "interfaces/commontypes.interface";

const service = new CourseCategoryService();

type Props = {
  handleClose: any;
  setOpenSecondDrawer: any;
  setCourseCategoryObject: any;
  setCourseCategoryId: any;
  idparent: string;
};
const CourseCategoryMainDrawer: React.FC<Props> = ({
  handleClose,
  setOpenSecondDrawer,
  setCourseCategoryObject,
  setCourseCategoryId,
  idparent,
}) => {
  // const { saveCache, getCache } = useResource();

  // const [isLoading, setIsLoading] = useState(true);
  // const [objects, setObjects] = useState<Array<CourseCategoryDto>>([]);

  // const getList = async () => {
  //   const reqList = new RequestListDTO();
  //   reqList.onpage = 100;
  //   reqList.page = 1;
  //   reqList.filters = [];

  //   let f: RequestFilterDTO;

  //   f = new RequestFilterDTO();
  //   f.field = "status";
  //   f.values = [];
  //   f.values.push(Status.ACTIVE.toString());
  //   reqList.filters.push(f);

  //   f = new RequestFilterDTO();
  //   f.field = "idparent";
  //   f.values = [idparent];
  //   reqList.filters.push(f);

  //   const cd = getCache(JSON.stringify(reqList));

  //   if (cd !== false) {
  //     setObjects(cd);
  //     setIsLoading(false);
  //     return;
  //   }

  //   setIsLoading(true);

  //   const data = await service.getList(undefined, {}, reqList);

  //   const rez = !data || !data.objects ? [] : data.objects;

  //   setObjects(rez);
  //   setIsLoading(false);

  //   saveCache(rez, JSON.stringify(reqList));
  // };

  // useEffect(() => {
  //   getList();
  // }, [idparent]);

  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };

  const [loading, objects] = useList<CourseCategoryDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
        RequestFilterDTO.prepareFilter("idparent", [idparent]),
      ],
      1,
      -1
    ),
    [idparent],
    true,
    `course_category_main_drawer_${idparent}`
  );

  const processIcon = (obj: CourseCategoryDto) => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("icon")) return null;
    if (!obj.icon) return null;
    return (
      <ListItemIcon className="iconCate">
        {ComponentCommonTools.getFileBlockSVG(obj.icon)}
      </ListItemIcon>
    );
  };

  const onClick = (url: string) => {
    RouteTools.setHistory(url, {});
    handleClose();
  };
  const processCourseCategoryItem = (obj: CourseCategoryDto, index: number) => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("id")) return null;
    let id = "";
    if (!obj.id) return null;
    id = obj.id;
    let fullurl = "";
    if (!obj.hasOwnProperty("fullurl")) fullurl = "";
    if (!obj.fullurl) fullurl = "";
    else fullurl = obj.fullurl;
    const hasChildren =
      obj.hasOwnProperty("haschildren") && obj.haschildren !== undefined
        ? obj.haschildren
        : false;

    return (
      <ListItem key={index} onClick={onClick.bind(this, fullurl)} sx={{ p: 0 }}>
        <ListItemButton className="linkSecond">
          {processIcon(obj)}
          {ComponentCommonTools.processObjectField(obj, "name")}
        </ListItemButton>
        <ListItemSecondaryAction
          onClick={() => {
            handleOpenSecondDrawer(obj, id);
          }}
        >
          {processSecondaryActions(hasChildren)}
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const handleOpenSecondDrawer = (obj: CourseCategoryDto, id: string) => {
    setCourseCategoryObject(obj);
    setCourseCategoryId(id);
    setOpenSecondDrawer(true);
  };

  const processSecondaryActions = (display: boolean) => {
    if (!display) return null;
    return (
      <IconButton edge="end" aria-label="action">
        <ArrowForwardIosIcon className="iconRight" />
      </IconButton>
    );
  };

  const processCourseCategoryList = () => {
    if (!objects) return null;
    if (!objects.length) return null;

    return (
      <List>
        {objects.map((item: CourseCategoryDto, i: number) => {
          return processCourseCategoryItem(item, i);
        })}
      </List>
    );
  };

  return loading ? (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        flexDirection: "column",
        paddingX: 4,
      }}
    >
      <Skeleton variant="text" sx={{ width: "30%", mt: 2, minWidth: "60px" }} />
      <Skeleton variant="text" sx={{ width: "40%", mt: 2, minWidth: "60px" }} />
      <Skeleton variant="text" sx={{ width: "30%", mt: 2, minWidth: "60px" }} />
      <Skeleton variant="text" sx={{ width: "40%", mt: 2, minWidth: "60px" }} />
    </Box>
  ) : (
    processCourseCategoryList()
  );
};

export { CourseCategoryMainDrawer };
