import { Box } from "@mui/material";
import { SocialNewDto } from "dto/user/social.dto";
import React from "react";
import { FormSocialUserItem } from "./FormSocialUserItem";

type Props = {
  objects: SocialNewDto[];
  setObjects: React.Dispatch<React.SetStateAction<SocialNewDto[] | null>>;
};

const FromSocialUserList: React.FC<Props> = ({ objects, setObjects }) => {
  const handleChange = (index: number, value: string) => {
    const newObjects = [...objects];
    newObjects[index].link = value;
    setObjects(newObjects);
  };

  const processItem = (item: SocialNewDto, index: number) => {
    if (!item) return null;
    return (
      <FormSocialUserItem
        item={item}
        index={index}
        key={index}
        handleChange={handleChange}
      />
    );
  };

  return <Box>{objects.map((item, index) => processItem(item, index))}</Box>;
};

export { FromSocialUserList };
