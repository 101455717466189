import React from "react";
import { TeacherDto } from "dto/user/teacher.dto";

import { MyNavBt } from "components/elements/button/MyNavBt";

import Box from "@mui/material/Box";

import { Stack, Typography, Divider } from "@mui/material";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { TeacherTypeIcon } from "./TeacherTypeIcon";
import { MyRatingReadOnly } from "components/elements/MyRating/MyRatingReadOnly";
type Props = {
  obj: TeacherDto;
};

const TeacherProcessNameCourse: React.FC<Props> = ({
  obj
}) => {
  const processTeacherIcon = () => {
    if (!obj) return null
    if (!obj.hasOwnProperty("status")) return null
    if (!obj.status) return null

    return <TeacherTypeIcon obj={obj} />;
  };

  const processTypeDiscipline = () => {
    if (!obj) return null
    if (!obj.hasOwnProperty("typediscipline")) return null
    if (!obj.typediscipline) return null

    return (
      <Typography>
        {ComponentCommonTools.processObjectFieldOfObject(
          obj,
          "typediscipline",
          "name"
        )}
      </Typography>
    );
  };
  const processRating = () => {
    if (!obj) return null
    if (
      !obj.hasOwnProperty("rating") ||
      !obj.hasOwnProperty("numberoftestimonials")
    )
      return <MyRatingReadOnly rating={0} count={0} />;
    if (!obj.rating || !obj.numberoftestimonials)
      return <MyRatingReadOnly rating={0} count={0} />;
    return (
      <MyRatingReadOnly rating={obj.rating} count={obj.numberoftestimonials} />
    );
  };

  const processFullName = () => {
    if (!obj) return null
    if (!obj.hasOwnProperty("fullname")) return null
    if (!obj.fullname) return null
    return (
      <Typography>
        {ComponentCommonTools.processObjectField(obj, "fullname")}
      </Typography>
    );
  };
  const processItem = () => {
    if (!obj) return null

    return (
      <Stack direction="column">
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-start"
          alignItems="center"
          className="teacherName"
        >
          <Box className="icon-teacher">{processTeacherIcon()}</Box>
          {processFullName()}
          <Divider orientation="vertical" />
          {processTypeDiscipline()}
        </Stack>
        {processRating()}
      </Stack>
    );
  };

  const processLink = () => {
    if (!obj) return null
    if (!obj.hasOwnProperty("fullurl")) return processItem();
    if (!obj.fullurl) return processItem();
    return (
      <MyNavBt color="inherit" underline="none" href={obj.fullurl}>
        {processItem()}
      </MyNavBt>
    );
  };

  return processLink();
};

export { TeacherProcessNameCourse };
