import { Box, Grid, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import AvatarCropper from "components/elements/MyAvatar/AvatarCropper";
import MyDatePicker from "components/elements/MyDatePicker/MyDatePicker";
import { TypeGenderSelect } from "components/elements/Select/GenderTypeSelect";
import MyTextField from "components/form/mytextfield";
import { NewLanguageSelect } from "components/static/language/NewLanguageSelect";
import { UserSettingsFormDto } from "dto/user/usersettings.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useEffect } from "react";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { ConfirmEmailAction } from "./ConfirmEmailAction";
import { useAuth } from "hooks/useAuth";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

type Props = {
  defaultObj: UserSettingsFormDto;
  onSubmit: (obj: UserSettingsFormDto) => void;
  loading: boolean;
};

const FormUserSettings: React.FC<Props> = ({
  defaultObj,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();
  const { user } = useAuth();
  const [obj, disabled, setObjField, setObject] = useForm<UserSettingsFormDto>(
    defaultObj,
    []
  );

  useEffect(() => {
    if (!defaultObj) return;
    setObject(
      UserSettingsFormDto.parseFromUserSettingsDto(
        CommonTools.isEmpty(defaultObj) ? undefined : defaultObj
      )
    );
  }, [defaultObj]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled || loading) return;
    onSubmit(obj);
  };

  const setAvatar = (value: any) => {
    const newObj = { ...obj };
    newObj["files"] = value;
    setObject(newObj);
  };
  const setCheckDeleteAvatar = (value: any) => {
    CommonTools.handleCheckValue(obj, setObject, "deleteavatar", value);
  };

  const processAvatarDelete = () => {
    if (!obj) return null;
    return (
      <Box sx={{ mt: 3 }}>
        {ComponentCommonTools.processCheckboxDelete(
          obj,
          obj,
          "avatar",
          "deleteavatar",
          "name",
          setCheckDeleteAvatar,
          LL
        )}
      </Box>
    );
  };
  if (!user) return null;
  return (
    <form onSubmit={handleSubmit}>
      <Box className="generalInfoBox">
        <Grid container className="generalInfoGrid" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{LL("Email")}</Typography>
            <MyTextField
              fullWidth
              id="name"
              name="name"
              className="form-input"
              variant="standard"
              _label={LL("email")}
              value={user.email}
              setObj={() => {}}
              disabled
              _vresults={undefined}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">{LL("Name")}</Typography>
            <MyTextField
              tabIndex={0}
              fullWidth
              id="name"
              name="name"
              className="form-input"
              variant="standard"
              _label={LL("Name")}
              value={obj.name}
              setObj={setObjField}
              _vresults={undefined}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">{LL("Surname")}</Typography>
            <MyTextField
              tabIndex={1}
              fullWidth
              id="surname"
              name="surname"
              className="form-input"
              variant="standard"
              _label={LL("Surname")}
              value={obj.surname}
              setObj={setObjField}
              _vresults={undefined}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">{LL("Birthday")}</Typography>
            <MyDatePicker
              fullWidth
              tabIndex={3}
              field="birthday"
              _defaultValue={obj.birthday}
              LL={LL}
              variant="standard"
              setObjField={setObjField}
              className="form-picker"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}>
            <ConfirmEmailAction />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">{LL("Language")}</Typography>
            <NewLanguageSelect
              value={obj.idlanguage}
              setObjectField={setObjField}
              label=""
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">{LL("Gender")}</Typography>
            <TypeGenderSelect
              value={obj.idtypegender}
              setObjectField={setObjField}
              label=""
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <Box>
              <AvatarCropper setFile={setAvatar} avatar={obj.avatar} />
            </Box>
            {processAvatarDelete()}
          </Grid>
          <Grid item xs={12} textAlign="center">
            <MyButton
              disabled={disabled || loading}
              color="primary"
              variant="contained"
              className="btn"
              type="submit"
              sx={{ mt: 4, gap: "4px" }}>
              <SaveOutlinedIcon />

              {LL("Bt_Submit_changes")}
            </MyButton>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export { FormUserSettings };
