import SelectOptions from 'dto/app/selectoptions.dto';
import Idto from 'interfaces/idto.interface';
import { CommonTools } from 'tools/utils/commontools';

export class CourseCategoryDto implements Idto {

  id?: string;
  idlanguage?: string;
  status?: number;
  ordercriteria?: number;
  name?: string;
  idparent?: string;
  idcoursecategory?: string;
  keymeta?: string;
  descriptionmeta?: string;
  url?: string;
  idgallery?: string;
  idattachment?: string;
  idvideo?: string;
  haschildren?: boolean;
  fullurl?: string;
  attachment?: any;
  video?: any;
  gallery?: any;
  icon?: any;


  static parseToSelectOptions(data: Array<CourseCategoryDto>): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(CourseCategoryDto.parseOption(element));
    });
    return result;
  }

  static parseOption = (obj: CourseCategoryDto): SelectOptions => {
    return new SelectOptions(
      CommonTools.processObjectField(obj, ["id"]),
      CommonTools.processObjectField(obj, ["fullname"])
    );
  };
}

export class PostCourseCategoryDto implements Idto {

  idlanguage?: string;
  status?: number;
  ordercriteria?: number;
  name?: string;
  idparent?: string;
  idcoursecategory?: string;
  keymeta?: string;
  descriptionmeta?: string;
  url?: string;
  idgallery?: string;
  idattachment?: string;
  idvideo?: string;
  haschildren?: boolean;
  fullurl?: string;
}