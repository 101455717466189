import { Box, Skeleton, Grid, Typography } from "@mui/material";
import { MyPagination } from "components/elements/MyPagination/MyPagination";
import { MySortSelect } from "components/elements/Select/MySortSelect";
import { NoResult } from "components/general/NoResult";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { NotificationDto } from "dto/user/notification.dto";
import { useAuth } from "hooks/useAuth";
import { useResource } from "hooks/useResource";
import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { NotificationService } from "services/user/notification.service";
import { RouteTools } from "tools/utils/routetools";

import { Types } from "tools/types/types";
import { TeacherNotificationItem } from "./TeacherNotificationItem";

type Props = {
  currentRoute: any;
};

const service = new NotificationService();

const TeacherNotificationList: React.FC<Props> = ({
  currentRoute,

}) => {
  const { LL, CC } = useResource();
  const { teacher } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [objects, setObjects] = useState<Array<NotificationDto>>([]);
  const [sortCriteria, setSortCriteria] = useState<
    Array<RequestSortCriteriaDTO>
  >(new Array<RequestSortCriteriaDTO>());
  const mainUrl =
    currentRoute && currentRoute.url ? currentRoute.url.split("?") : "";

  const [total, setTotal] = useState<number>(-1);
  const [totalPage, setTotalPage] = useState<number>(-1);
  const [responseParams, setResponseParams] = useState<any>({});
  const [onPage, setOnPage] = useState<number>(
    parseInt(CC("OnPageTeacherNotificationList", "10"))
  );
  const [page, setPage] = useState<number>(1);
  const [cookies, setCookies] = useCookies();

  const getList = () => {
    if (!teacher) return;
    if (!teacher.id) return;

    const reqList = new RequestListDTO();
    reqList.onpage = onPage;
    reqList.page = page;
    reqList.filters = [];
    reqList.sortcriteria = sortCriteria;

    let f: RequestFilterDTO;

    f = new RequestFilterDTO();
    f.field = "type";
    f.values = [Types.TYPE_NOTIFICATION_PROFILE.toString()];
    reqList.filters.push(f);

    f = new RequestFilterDTO();
    f.field = "typerecipient";
    f.values = [Types.TYPE_NOTIFICATION_RECIPIENT_TEACHER.toString()];
    reqList.filters.push(f);

    f = new RequestFilterDTO();
    f.field = "idteacher";
    f.values = [teacher.id];
    reqList.filters.push(f);

    const urlPart = RouteTools.prepareListLocation(reqList);

    RouteTools.setHistory(mainUrl[0] + urlPart, {});
    setLoading(true);
    service.getList(loadObjects, {}, reqList);
  };

  const loadObjects = (result: ResultListDTO) => {
    if (!result) return;
    if (!result.err) {
      const objects = result.objects ? result.objects : [];
      const total = result.total !== undefined ? result.total : -1;
      const totalPage =
        result.totalpages !== undefined ? result.totalpages : -1;
      const reqInfo = result.requestinfo ? result.requestinfo : {};
      setObjects(objects);
      setTotal(total);
      setTotalPage(totalPage);
      setResponseParams(reqInfo);
    }
  };

  const checkLoading = () => {
    let load = false;

    if (total === -1) return true;
    if (totalPage === -1) return true;
    if (!objects) load = true;
    if (Object.keys(responseParams).length === 0) return true;

    setLoading(load);
  };
  const defaultSortCriteria = () => {
    if (!sortCriteria || sortCriteria.length === 0) {
      const sortCriteria = new RequestSortCriteriaDTO();
      sortCriteria.field = "date";
      sortCriteria.asc = false;

      setSortCriteria([sortCriteria]);
    }
  };

  useEffect(() => {
    if (sortCriteria && sortCriteria.length > 0) getList();
  }, [sortCriteria, onPage, page]);

  useEffect(() => {
    const reqListRoute = RouteTools.prepareListRequest(currentRoute, cookies);
    if (reqListRoute.page) setPage(reqListRoute.page);

    if (reqListRoute.sortcriteria && reqListRoute.sortcriteria.length > 0) {
      setSortCriteria(reqListRoute.sortcriteria);
    } else {
      defaultSortCriteria();
    }
  }, []);

  useEffect(() => {
    checkLoading();
  }, [objects]);

  const processItem = (obj: NotificationDto, index: number) => {
    if (!obj) return <></>;

    return (
      <Box key={index}>
        <TeacherNotificationItem
          obj={obj}
          setLoading={setLoading}
          itemIndex={index}
          objects={objects}
          setObjects={setObjects}
        />
      </Box>
    );
  };

  const processSortSelect = () => {
    const objects = ["date_asc", "date_desc"];
    return (
      <Box
        my={4}
        sx={{
          maxWidth: "50%",
          "& label": {
            display: "none",
          },
        }}
      >
        <MySortSelect
          objects={objects}
          setSortCriteria={setSortCriteria}
          sortCriteria={sortCriteria}
          variant="standard"
          className="form-select"
        />
      </Box>
    );
  };

  const processPagination = () => {
    if (totalPage === -1) return <></>;
    if (total === -1) return <></>;
    if (objects.length === 0) return <></>;

    return (
      <Box
        className="container"
        py={4}
        display="flex"
        sx={{ justifyContent: "center" }}
      >
        <MyPagination
          page={page}
          total={total}
          totalPage={totalPage}
          setPage={setPage}
        />
      </Box>
    );
  };

  const processList = () => {
    if (!objects.length) return <NoResult identifier="notification" />;

    return (
      <Box>
        {objects.map((item: NotificationDto, i: number) => {
          return processItem(item, i);
        })}
      </Box>
    );
  };

  return (
    <Box className="container" justifyContent="center" display="flex">
      <Grid item container lg={6} direction="column">
        <Typography variant="h3" textAlign="center" my={3}>
          {LL("All notification")}
        </Typography>
        {processSortSelect()}
        {loading ? <Skeleton /> : processList()}
        {loading ? <Skeleton /> : processPagination()}
      </Grid>
    </Box>
  );
};

export { TeacherNotificationList };
