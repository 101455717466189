import React, {  useState } from "react";

import { useResource } from "hooks/useResource";

import {
  
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { useAuth } from "hooks/useAuth";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CircularLoading } from "components/elements/loading/CircularLoading";
import { PostCourseTeacherRequestDto } from "dto/course/courseteacherrequest.dto";
import { CourseTeacherRequestService } from "services/course/courseteacherrequest.service";

import { CommonTools } from "tools/utils/commontools";
import { CourseVerifyButton } from "./CourseVerifyButton";
import { CourseDto } from "dto/course/course.dto";
import { FormCourseTeacherRequest } from "./FormCourseTeacherRequest";

type Props = {
  currentRoute: any;
  course: CourseDto;
};

const service = new CourseTeacherRequestService();
const CourseVerifyDialog: React.FC<Props> = ({
  currentRoute,
  course,
}) => {
  const { LL } = useResource();
  const { teacher } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const idCourse: string = CommonTools.getIdFromPath(
    currentRoute,
    "editcourse",
    1,
    2
  );
  const [obj, setObj] = useState<PostCourseTeacherRequestDto>(
    new PostCourseTeacherRequestDto(
      CommonTools.processObjectField(teacher, ["id"]),
      idCourse
    )
  );
  //   const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [open, setOpen] = useState(false);
  const [objectWasChanged, setObjectWasChanged] = useState<string>("");
  //   const [idCourse, setIdCourse] = useState<string>("");

  //   const [validators, setValidators] = useState(false);
  //   const [vresults, setVResults] = useState<any>(false);

  //   const validateAll = () => {
  //     if (!obj) return;
  //     if (!validators) return;
  //     obj.teachermessage =
  //       obj.teachermessage !== undefined ? obj.teachermessage : "";
  //     setObj(obj);
  //     validateField("teachermessage", obj.teachermessage);
  //   };

  //   const validateField = (field: any, value: any, v?: any) => {
  //     v = v ? v : JSON.parse(JSON.stringify(vresults));
  //     if (v === false) v = {};
  //     v[field] = ValidateTools.validate(field, value, validators, vresults);
  //     setVResults(v);
  //     setIsFormDisabled(ValidateTools.vForm(v, field));
  //     return v;
  //   };

  //   const processValidators = () => {
  //     let v: any = {
  //       teachermessage: [],
  //     };

  //     v.teachermessage.push(new RequiredValidator("", ""));

  //     setValidators(v);
  //   };
  //   const checkLoading = () => {
  //     if (!loading) return;
  //     if (!obj) return;

  //     setLoading(false);
  //   };

  //   useEffect(() => {
  //     setIdCourse(CommonTools.getIdFromPath(currentRoute, "editcourse", 1, 2));
  //     processValidators();
  //     validateAll();
  //   }, [currentRoute]);

  //   useEffect(() => {
  //     checkLoading();
  //   }, [obj, validators]);

  //   useEffect(() => {
  //     checkIsFormDisabled();
  //   }, [vresults]);

  //   const setObjField = (field: any, value: any) => {
  //     let t = JSON.parse(JSON.stringify(obj));
  //     t[field] = value;
  //     setObj(t);

  //     validateField(field, value);
  //   };

  //   const checkIsFormDisabled = () => {
  //     var rez = ValidateTools.vForm(vresults, "teachermessage");

  //     setIsFormDisabled(rez);
  //   };

  //   const handleSubmit = (event: any) => {
  //     event.preventDefault();
  //     if (isFormDisabled) return false;

  //     if (!obj || !teacher || !idCourse) return;

  //     obj.idteacher = teacher.id;
  //     obj.idcourse = idCourse;

  //     setLoading(true);
  //     courseTeacherRequestService.verify(handleResult, {}, obj);
  //   };

  const onSubmit = (obj: PostCourseTeacherRequestDto) => {
    setLoading(true);
    service.verify(handleResult, {}, obj);
  };

  const handleResult = (result: ResultObjectDTO, cbparams: any) => {
    if (!result) return;
    if (!result.err) {
      setObjectWasChanged(CommonTools.generateRandomString(5));
      setOpen(false);
      setLoading(false);
      setObj(new PostCourseTeacherRequestDto(
        CommonTools.processObjectField(teacher, ["id"]),
        idCourse
      ));
    }
  };

  const handleClose = () => {
    setOpen(false);
    setObj(new PostCourseTeacherRequestDto(
        CommonTools.processObjectField(teacher, ["id"]),
        idCourse
    ));
  };

  //   const processForm = () => {
  //     if (!obj) return null
  //     if (loading) return <CircularLoading />;

  //     return (
  //       <form onSubmit={handleSubmit}>
  //         <Box>
  //           <Typography variant="h6" mb={1}>
  //             {" "}
  //             {LL("Message")}
  //           </Typography>
  //           <MyTextField
  //             multiline
  //             minRows={4}
  //             tabIndex={0}
  //             fullWidth
  //             id="teachermessage"
  //             name="teachermessage"
  //             variant="standard"
  //             color="primary"
  //             className="form-input"
  //             _label={LL("teachermessage")}
  //             size="small"
  //             value={obj.teachermessage}
  //             setObj={setObjField}
  //             _vresults={
  //               vresults && vresults.teachermessage
  //                 ? vresults.teachermessage
  //                 : false
  //             }
  //           />
  //         </Box>

  //         <Box>
  //           <MyButton
  //             tabIndex={1}
  //             disabled={isFormDisabled}
  //             color="primary"
  //             variant="contained"
  //             size="large"
  //             type="submit"
  //             className="btn"
  //             children={LL("Bt_Submit")}
  //           />
  //         </Box>
  //       </form>
  //     );
  //   };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const processComponent = () => {
    if (!teacher) return null
    if (!idCourse) return null

    return (
      <React.Fragment>
        <CourseVerifyButton
          cb={handleClickOpen}
          objectWasChanged={objectWasChanged}
          idCourse={idCourse}
          course={course}
        />
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          className="dialogAdd"
        >
          <DialogTitle>{LL("verify_course")}</DialogTitle>
          <IconButton
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            {loading ? (
              <CircularLoading />
            ) : (
              <FormCourseTeacherRequest
                loading={loading}
                defaultObject={obj}
                onSubmit={onSubmit}
              />
            )}
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  };

  return processComponent();
};

export { CourseVerifyDialog };
