import { Box } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { StudentCourseContext } from "context/studentcourse.context";
import { useResource } from "hooks/useResource";
import React, { useState, useContext } from "react";

export const StudentCourseZoomBlock: React.FC<PageComponentProps> = () => {
  const { LL } = useResource();

  const [open, setOpen] = useState(false);
  const {
    state: {
      selectedLessonObject,
      courseEnrollmentObject,
      lessonEnrollmentObject,
    },
  } = useContext(StudentCourseContext);

  const processZoomBlock = () => {
    if (!open) return null;
    return <Box height={200}>ZoomBlock</Box>;
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const processComponent = () => {
    if (!courseEnrollmentObject) return null;
    if (!lessonEnrollmentObject) return null;
    if (!selectedLessonObject) return null;
    if (!selectedLessonObject.hasOwnProperty("islive")) return null;
    if (!selectedLessonObject.islive) return null;

    return (
      <Box>
        {processZoomBlock()}
        <MyButton cb={handleToggle}>{LL("Zoom")}</MyButton>
      </Box>
    );
  };

  return processComponent();
};
